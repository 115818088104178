import React, { useState, useEffect } from 'react';
import '../../css/components/v2/topbarv2.css';
import { Sling as Hamburger } from 'hamburger-react'
import axios from 'axios';
import * as Constants from '../../data/constants';
import { CircularProgress } from '@mui/material';
import '../../css/components/v2/topbarv2.css';
import { getLocalStorageValue, removeLocalStorageValue, setLocalStorageValue } from '../../util/util';
import NotificationsIcon from '@mui/icons-material/Notifications';
// import BarChartIcon from '@mui/icons-material/BarChart';
// import AttachmentIcon from '@mui/icons-material/Attachment';
import AddShoppingCartIcon from '@mui/icons-material/Attachment';

const TopbarV2 = (props) => {

    const [top, setTop] = React.useState(0)
    // const [up, setUp] = React.useState(false)
    // const [prevScrollPosition, setPrevScrollPosition] = React.useState(0)


    const controlNavbar = (e) => {
        var tempScrollPosition = 0
        if(window.scrollY>60){
            tempScrollPosition = 60 - window.scrollY
        }
        else if(window.scrollY<60){
            tempScrollPosition = 0
        }
        else{

        }
        // if(prevScrollPosition - window.scrollY > 0){
        //     if(window.location.pathname!=='/offers'){
        //         tempScrollPosition = 0
        //     }
        //     // setUp(true)
        // }
        // else{
        //     // setUp(false)
        // }
        setTop(tempScrollPosition)
        // setPrevScrollPosition(window.scrollY)
    }

    React.useEffect(() => {
        window.addEventListener('scroll', controlNavbar)
        return () => {
            window.removeEventListener('scroll', controlNavbar)
        }
    })

    const [logoutApiLoader, setLogoutApiLoader] = useState(false)
    async function logoutFromDevice(credentials) {
        setLogoutApiLoader(true)
        await axios({
            method: 'POST',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'logout'),
            headers: {
                'Content-Type': "application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
            data: {
                "all_device": 0,
                }
        })
        .then(async(resp) => {
            console.log(resp);
        })
        .catch(error => {
            if (error.response) {
                console.log(error)
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.error(error);
        })
        localStorage.removeItem('adminloginTime');
        localStorage.removeItem('username');
        localStorage.removeItem('adminPanelLoginData');
        removeLocalStorageValue('adminPanelLoginData');
        localStorage.removeItem('statsAffiliate');
        localStorage.removeItem('statsOfferId');
        localStorage.removeItem('statsAdvertiser');
        localStorage.removeItem('statsAffiliateManager');
        localStorage.removeItem('statsAdvertiserManager');
        localStorage.removeItem('statsDateRange');

        sessionStorage.clear();
        setLogoutApiLoader(false);
        window.location.reload();
    }
    var tempNotificationsDict = [
        // {label:'Smartlink Offer Distribution now available',icon:AttachmentIcon,visited:false},
        // {label:'Smartlink statistics now available',icon:BarChartIcon,visited:false},
        {label:'Added Billing Section for generating invoices.',icon:AddShoppingCartIcon,visited:false},
    ]
    const [manualUseState, setManualUseState] = useState(false)
    const [notificationsList, setNotificationsList] = useState([])
    const [unvisitedNotifications,setUnvisitedNotifications] = useState(9)
    const [showNotificationMenu,setShowNotificationMenu] = useState(false)

    if(!manualUseState){
        var visitedNotifications = getLocalStorageValue('visitedNotifications')
        if(!visitedNotifications){
            visitedNotifications = []
        }
        var tempUnvisitedNotifications = 0
        for(var index in tempNotificationsDict){
            if(visitedNotifications.includes(tempNotificationsDict[index].label)){
                tempNotificationsDict[index].visited = true
            }
            else{
                tempUnvisitedNotifications += 1
            }
        }
        if(tempUnvisitedNotifications>9){
            tempUnvisitedNotifications = '9+'
        }
        setUnvisitedNotifications(tempUnvisitedNotifications)
        setNotificationsList(tempNotificationsDict)
        setManualUseState(true)
    }
    const showNotification = (item,index) => {
        if(unvisitedNotifications>0){
            setUnvisitedNotifications(unvisitedNotifications - 1)
        }
        var visitedNotifications = getLocalStorageValue('visitedNotifications')
        if(!visitedNotifications){
            visitedNotifications = []
        }
        visitedNotifications.push(item.label)
        var tempNotificationsDict = notificationsList
        tempNotificationsDict[index].visited = true
        setNotificationsList(tempNotificationsDict)
        setLocalStorageValue('visitedNotifications',visitedNotifications)
    }

    
    useEffect(() => {
        document.addEventListener('click',()=>{
            setShowNotificationMenu(false);
        })
        
        return () => {
            document.removeEventListener('click',()=>{
                setShowNotificationMenu(false);
            })
        }
    }, [])

    return (
        <div className='topbarContainerV2' style={{top,justifyContent:(window.innerWidth<600 ? 'space-between' : 'flex-end'),marginLeft:(window.innerWidth<600 ? 0 : (props.sidebarOpen ? '220px' : '60px')),width:(window.innerWidth<600 ? "100%" : 'calc(100vw - ' + (props.sidebarOpen ? '220px' : '60px') + ")"),transition:'margin 0.7s ease-in-out, width 0.7s ease-in-out'}}>
            
            {window.innerWidth<600 && <div className='hamburgerIcon' style={{marginLeft:(props.sidebarOpen ? "8px" : "8px")}}><Hamburger toggled={props.sidebarOpen} toggle={props.menuBarClicked} size={20} duration={0.8}/></div>}
            {(!props.sidebarOpen || window.innerWidth<600)
            &&
            <div>
                <div className={"companyNameV2"} style={{cursor:'pointer',paddingRight:'15px'}} onClick={()=>{sessionStorage.clear();window.location.href='/dashboard'}}>{process.env.REACT_APP_COMPANY_NAME}</div>
            </div>
            }
            <div style={{marginRight:"15px",marginLeft:'5px',marginTop:'5px',position:'relative'}} onClick={(e)=>{e.stopPropagation();setShowNotificationMenu(!showNotificationMenu);}}>
                <NotificationsIcon sx={{color:'white'}}/>
                {
                    unvisitedNotifications!==0 && 
                    <div style={{width:'12px',height:'12px',borderRadius:'50%',fontSize:'8px',fontWeight:'500',display:'flex',alignItems:'center',justifyContent:'center',background:'red',color:'white',position:'absolute',top:0,right:0}}>{unvisitedNotifications}</div>
                }
                
                <div className={showNotificationMenu ? 'notificationMenu' :  'hideNotificationMenu'} onClick={(e)=>{e.stopPropagation();}}>
                    {notificationsList.map((item,index)=>{
                        return <div className='notificationItemWrapper' key={index} onClick={()=>{showNotification(item,index)}}>
                                    <div style={{marginTop:'5px',width:'15%'}}>
                                        <item.icon sx={{background:localStorage.getItem('ambientColor'),color:"white",border:'2px solid '+localStorage.getItem('ambientColor'),borderRadius:'50%'}}/>
                                    </div>
                                    <div style={{width:'85%'}}>
                                        {item.label}
                                    </div>
                                    {!item.visited && 
                                        <div style={{position:'absolute',right:'10px',top:'-30px',fontSize:'60px',color:'green'}}>
                                            .
                                        </div>
                                    }
                                </div>
                    })}
                </div>
                
            </div>
            
            <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',marginRight:'10px',}}>
                <div className='logoutButtonV2' style={{minWidth:'70px',textAlign:'center'}} onClick={()=>{logoutFromDevice()}}>
                    {logoutApiLoader && <CircularProgress size={'18px'} sx={{color:'white'}}/>}
                    {!logoutApiLoader && 'Logout'}
                </div>
            </div>
        </div>
    );
};

export default TopbarV2;