import React from 'react'
import { useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { useScrollDirection } from 'react-use-scroll-direction';

import * as Constants from '../../../data/constants';
import { capitalize, sorting, getLocalStorageValue } from '../../../util/util';

// import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import NativeSelect from '@mui/material/NativeSelect';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
// import DeleteIcon from '@mui/icons-material/Delete';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Select from 'react-select';

import '../../../css/pages/demandsource.css';

// import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import NewDemandSource from './newDemandSource';
import { increase_brightness } from '../../../components/customization/customization';
import { Store } from 'react-notifications-component';

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    if(false){
        console.log(value)
    }
    return () => setValue(value => value + 1); // update the state to force render
  }

export const DemandSource = () => {
    document.title = "Demand Source - Admin"
    const refreshComponent = useForceUpdate()
    // window.history.replaceState("/demandsource", "demandsource add", "/demandsource")

    const [manualUseState, setManualUseState] = React.useState(false);

    const [totalResults,setTotalResults] = useState("-");
    const [demandsourceDict,setDemandSourceDict] = useState({loaded:false,data:[]})

    const [showLoader, setShowLoader] = React.useState(true);

    const [showPopupNewDemandSource, setShowPopupNewDemandSource] = React.useState(false);
    
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(50);
    // const [offset, setOffset] = React.useState(1);
   
    // const [advNameSearch, setAdvNameSearch] = React.useState("");
    // const [platformSearch, setPlatformSearch] = React.useState("");
    const [sourceStatusSelected, setSourceStatusSelected] = React.useState({label:'All',value:null});
    
    const [threeDotMenuTop, setThreeDotMenuTop] = React.useState(0);
    const [threeDotMenuLeft, setThreeDotMenuLeft] = React.useState(0);
    const [showThreeDotMenu, setShowThreeDotMenu] = React.useState(false);
    
    const [editDemandSourceID, setEditDemandSourceID] = React.useState(false);
    const [demandSourceIndex, setDemandSourceIndex] = React.useState(false);
    const [changeStatusRequest, setChangeStatusRequest] = React.useState(false);
    
    const [sortingFactor, setSortingFactor] = React.useState("id");
    const [sortingOrder, setSortingOrder] = React.useState(true); //true for asc and false for desc
    const [sortingFactorType, setSortingFactorType] = React.useState(true); //true for int and false for string

    const [direction, setDirection] = React.useState(String)
    const { isScrollingUp, isScrollingDown } = useScrollDirection()
    
    React.useEffect(() => {
      isScrollingDown && setDirection('down')
      isScrollingUp && setDirection('up')
    }, [isScrollingDown, isScrollingUp])
    if(direction){
    }   
    if (isScrollingDown || isScrollingUp){
        if (showThreeDotMenu){
            setShowThreeDotMenu(false);
        }
    }

    var tableRows = []

    const handleLimitChange = (e) =>{
        setLimit(parseInt(e.target.value))
        setPage(1);
        showDemandSourceRequest(1,e.target.value);
    };
    const handlePageChange = (event, value) => {
        setPage(value);
        // setOffset((value-1)*limit +1);
        showDemandSourceRequest(value,limit);

    };

    const togglePopup = (closeButton=false) => {
        setShowPopupNewDemandSource(!showPopupNewDemandSource);
        }

    const handleCheckSortingKey = (e) => {
        if (["id"].includes(e.target.id)){
            if (sortingFactor===e.target.id){
                setSortingOrder(!sortingOrder);
            }
            else{
                setSortingOrder(true);
            }
            setSortingFactor(e.target.id);
            setSortingFactorType(true);
        }
        else if (["advertiser_name","platform","created_at"].includes(e.target.id)){
            if (sortingFactor===e.target.id){
                setSortingOrder(!sortingOrder);
            }
            else{
                setSortingOrder(true);
            }
            setSortingFactor(e.target.id);
            setSortingFactorType(false);       
            }
        else{
            setSortingFactor("id");
            setSortingOrder(true);
            setSortingFactorType(true);   
        }
    }
    
    const showDemandSourceRequest = async(manual_page=false,manual_limit=false,searchButton=false) => {
        setShowLoader(true)
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.DEMANDSOURCE),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                limit:manual_limit ? manual_limit : limit,
                page:manual_page ? manual_page : page,
                advertiser_id : advertiserSelected ? advertiserSelected.value : null,
                platform : platformSelected ? platformSelected.value : null,
                source_status : sourceStatusSelected ? sourceStatusSelected.value : null,
            }
        }
        )
        .then((resp) => {
            if(searchButton){
                setPage(1);
            }
            setDemandSourceDict({loaded:true,data:resp})
            setTotalResults(resp.data.count)
            setShowLoader(false);
        })
        .catch(err => {
            console.error(err);
        })
    };

    const [advertisersList,setAdvertisersList] = React.useState([])
    const [advertiserSelected,setAdvertiserSelected] = React.useState({label:"All",value:null})

    const getAdvertisers = async() => {
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.ADVERTISER,"/","details"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        })
        .then(resp => {
            let advertiserlist = []
            advertiserlist.push({label:"All",value:null})
            for (var item in resp.data.result){
                advertiserlist.push({label:resp.data.result[item][Object.keys(resp.data.result[item])[0]] + " - " + Object.keys(resp.data.result[item])[0],value:Object.keys(resp.data.result[item])[0]})
            }
            sorting(advertiserlist,'label',false,true)
            setAdvertisersList(advertiserlist)
        })
        .catch(err => {
            console.error(err);
        })
    };

    const changeDemandSourceStatus = async(e,status) => {
        if(changeStatusRequest){
            Store.addNotification({
                title: "Warning!",
                message: "Status change request already in process. Please wait for some time.",
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2000,
                  onScreen: true
                }
              });
        }
        else{
            setChangeStatusRequest(true)
        }
        await axios({
            method: 'POST',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"demandsource/status/change"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-type" : 'application/json',
            },
            data:{
                "source_status": status,
                "source_id": editDemandSourceID
            }
        })
        .then(resp => {
            setChangeStatusRequest(false)
            Store.addNotification({
                title: "Success!",
                message: resp.data.result,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2000,
                  onScreen: true
                }
              });
            if(resp.data.result === 'Demand source status successfully updated'){
                var tempDemandSourceDict = demandsourceDict
                tempDemandSourceDict.data.data.result[demandSourceIndex].status = status
                setDemandSourceDict(tempDemandSourceDict);
                refreshComponent(e);
            }
            setShowThreeDotMenu(false);
        })
        .catch(error => {
            setChangeStatusRequest(false)
            setShowThreeDotMenu(false);
            if (error.response) {
                // Request made and server responded
                Store.addNotification({
                    title: "Error!",
                    message: typeof error.response.data.result === "string" ? error.response.data.result : "Some error occured",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                }); 
                console.log(error.response.status);
                console.log(error.response.headers);
              } else {
                // Something happened in setting up the request that triggered an Error
                Store.addNotification({
                    title: "Error!",
                    message: typeof error.message === "string" ? error.message : "Some error occured",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                }); 
                console.log('Error', error.message);
              }
        })
    };

    const [platformList,setPlatformList] = React.useState([]);
    const [platformSelected,setPlatformSelected] = React.useState({label:"All",value:null});

    const getPlatforms = async() => {
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"platform","/","details"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        })
        .then(resp => {
          let platformlist = []
          platformlist.push({label:"All",value:null})
          for (var item in resp.data.result){
                platformlist.push({label:resp.data.result[item][Object.keys(resp.data.result[item])[0]],value:Object.keys(resp.data.result[item])[0]})
              }
            sorting(platformlist,'label',false,true)
            setPlatformList(platformlist)
        })
        .catch(err => {
            console.error(err);
        })
    };
    
    if (!manualUseState){
        setManualUseState(true);
        getAdvertisers();
        getPlatforms();
        showDemandSourceRequest();
    }

    const threeDotButtonClicked = (e,item) => {
        if (e.target.id){
            if (showThreeDotMenu){
                setShowThreeDotMenu(false)
            }
            else{
                setShowThreeDotMenu(true)
            }
            var offsets = document.getElementById(e.target.id).getBoundingClientRect();
            var top = offsets.top-10;
            var left = offsets.left-160;
            setThreeDotMenuTop(top)
            setThreeDotMenuLeft(left)
            }
                    
    }
    var statusDropDown = {0:'Paused',1:'Active'}
    const showData = () => {
        if (demandsourceDict.loaded!==false){
            
            var demandsourceData =  demandsourceDict.data.data.result
            
            sorting(demandsourceData,sortingFactor,sortingFactorType,sortingOrder);

            demandsourceData.map((item,index)=>{
                if(true){
                        var options = { year: 'numeric', month: 'short', day: 'numeric' ,hour:'numeric',minute:'numeric',second:'numeric'};
                        tableRows.push(<tr key={index} className="dataRow" style={{backgroundColor:"rgb(255,255,255)" }}>
                                    <td className="dataColumn">{item.advertiser_name.slice(1,item.advertiser_name.indexOf(')') + 0)}</td>
                                    <td className="dataColumn">
                                    <NavLink style={{textDecoration:"none",color:"blue"}} to={"/demandsource/management/edit/"+item.id}>
                                        {item.advertiser_name.slice(item.advertiser_name.indexOf(')') + 1)}
                                    </NavLink>
                                    </td>
                                    <td className="dataColumn">{item.platform}</td>
                                    {/* <td className="dataColumn">{item.api_key}</td> */}
                                    <td className="dataColumn">{(item.status || item.status===0) ? 
                                                                <div style={{ 
                                                                    color:(item.status===1 ? 'green' : 'orange'),
                                                                    height:"1%",
                                                                    background:(item.status===1 ? 'rgb(176, 239, 176)' :'rgb(255, 234, 195)' ),
                                                                    border: "1px solid "+(item.status===1 ? 'green' : 'orange'),
                                                                    borderRadius:"5px",
                                                                    padding:'3px',
                                                                    paddingLeft:"20%",
                                                                    paddingRight:"20%",
                                                                    }}>
                                                                        {statusDropDown[item.status]}
                                                                </div> 
                                                             : "-" }</td>
                                    <td className="dataColumn">{item.created_at ? new Date(item.created_at).toLocaleDateString("en-US", options) : "-"}</td>
                                    <td className="dataColumn">{item.last_updated ? new Date(item.last_updated).toLocaleDateString("en-US", options) : "-"}</td>
                                    <td className="dataColumn">{item.last_response ? 
                                                                        <Tooltip sx={{zIndex:0}} style={{zIndex:0}} title={JSON.stringify(item.last_response)}>
                                                                                <div style={{color:item.last_status==='active' ? 'green' : 'red'}}>{item.last_status==="active" ? capitalize("Success") :capitalize(item.last_status)}</div>
                                                                        </Tooltip>
                                                                    : "-"}</td>
                                    <td id={"threeDotButton"+index} className="dataColumn threeDotButton" onClick={(e)=>{setEditDemandSourceID(item.id);threeDotButtonClicked(e);setDemandSourceIndex(index);}}>
                                        <MoreVertIcon id={"threeDotButton"+index} onClick={(e)=>{setEditDemandSourceID(item.id);threeDotButtonClicked(e);setDemandSourceIndex(index);}}/>
                                    </td>
                                </tr>)
            }
            return 1;
        })
            return  <table className="dataTable">
                        <tbody>
                            <tr className="dataRow" style={{backgroundColor:"rgb(250,250,250)"}}>
                                <td className="dataColumnHead" style={{width:"3%"}}>ID</td>
                                <td className="dataColumnHead" id="advertiser_name" onClick={handleCheckSortingKey} style={{width:"10%"}}>Advertiser</td>
                                <td className="dataColumnHead" id="platform" onClick={handleCheckSortingKey} style={{width:"10%"}}>Platform</td>
                                <td className="dataColumnHead" id="status"  style={{width:"10%"}}>Status</td>
                                <td className="dataColumnHead" id="created_at"  onClick={handleCheckSortingKey} style={{width:"13%"}}>Created At</td>
                                <td className="dataColumnHead" id="last_updated" onClick={handleCheckSortingKey} style={{width:"13%"}}>Last Sync</td>
                                <td className="dataColumnHead" id="response" style={{width:"10%"}}>Sync Status</td>
                                <td className="dataColumnHead" style={{width:"2%",background:"transparent"}}></td>
                            </tr>
                            {tableRows}
                            
                        </tbody>
                    </table>
            }
    }

    var sourceStatusOptions = [{label:'All',value:null},{label:'Active',value:1},{label:'Paused',value:0}]
    return (
        <div className="row">
            <div className="columnData" style={{width:"95%",height:"auto"}}>
                {
                    showThreeDotMenu && editDemandSourceID && 
                    <div className="threeDotMenu" style={{top:threeDotMenuTop,left:threeDotMenuLeft}}>
                        <NavLink style={{textDecoration:"none",color:"black"}} to={"/demandsource/management/edit/" + editDemandSourceID}>
                            <div className="threeDotMenuItems">
                                <ModeEditOutlineIcon style={{color:"rgb(0, 119, 255)",transform:"scale(0.8)"}}/>
                                Edit
                            </div>
                        </NavLink>  

                            {demandsourceDict.data.data.result[demandSourceIndex].status === 1 && 
                            <div className="threeDotMenuItems" onClick={(e)=>{changeDemandSourceStatus(e,0)}}>
                                <PauseIcon style={{color:"orange",transform:"scale(0.8)"}}/>
                                Pause
                            </div>
                            }

                            {demandsourceDict.data.data.result[demandSourceIndex].status === 0 && 
                            <div className="threeDotMenuItems" onClick={(e)=>{changeDemandSourceStatus(e,1)}}>
                                <PlayArrowIcon style={{color:"green",transform:"scale(0.8)"}}/>
                                Active
                            </div>
                            }
                    </div>
                }
                
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <div style={{marginLeft:"2%",marginTop:"2%",fontSize:"12px"}}>Found {totalResults} items</div>
                    <div style={{marginRight:"2%",marginTop:"2%",fontSize:"12px",display:'flex',alignItems:'center'}}>
                        <div style={{marginRight:"15px",cursor:'pointer'}} onClick={()=>{localStorage.setItem('newDemandOfferPage',true);window.location.reload();}}>Switch to New Design</div>
                        <NavLink style={{textDecoration:"none",color:"black"}} to={"/demandsource/management/add"}>
                            <Button style={{fontSize:'13px',fontWeight:500,paddingLeft:'10px',paddingRight:'10px'}} sx={Constants.OUTLINEDBUTTON}>New Demand Source</Button>
                        </NavLink>
                    </div>
                </div>
                <div style={{display:"flex",marginTop:"1%",width:"100%"}}>
                    <div style={{width:"17%",marginLeft:"2%"}}>
                        <div style={{fontSize:"12px",fontWeight:"500",color:"grey",paddingBottom:"2px"}}>Advertiser</div>
                        <Select 
                            options={advertisersList}
                            value={advertiserSelected}
                            onChange={(e)=>{setAdvertiserSelected(e)}}
                            menuPortalTarget={document.body} 
                            styles={{ 
                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                                control: (base,state) => ({
                                    ...base,
                                    // maxHeight: 100,
                                    minHeight: 30,
                                    transform:'scale(0.9) translateX(-6.5%)',
                                    overflowY: "auto",
                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                    boxShadow: 0,
                                    '&:hover': {
                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                        boxShadow: 0,
                                    }
                                }),
                                option: (styles, {isFocused, isSelected}) => ({
                                    ...styles,
                                    fontSize:'11px',
                                    background: isFocused
                                        ? localStorage.getItem('ambientColor')
                                        : isSelected
                                            ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                            : undefined,
                                    zIndex: 1
                                }),
                            }}/>
                    </div>
                    <div style={{width:"17%",marginLeft:"2%"}}>
                        <div style={{fontSize:"12px",fontWeight:"500",color:"grey",paddingBottom:"2px"}}>Platform</div>
                        <Select 
                            options={platformList}
                            value={platformSelected}
                            onChange={(e)=>{setPlatformSelected(e)}}
                            menuPortalTarget={document.body} 
                            styles={{ 
                                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                                    control: (base,state) => ({
                                        ...base,
                                        // maxHeight: 100,
                                        minHeight: 30,
                                        transform:'scale(0.9) translateX(-6.5%)',
                                        overflowY: "auto",
                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                        boxShadow: 0,
                                        '&:hover': {
                                            border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                            boxShadow: 0,
                                        }
                                    }),
                                    option: (styles, {isFocused, isSelected}) => ({
                                        ...styles,
                                        fontSize:'11px',
                                        background: isFocused
                                            ? localStorage.getItem('ambientColor')
                                            : isSelected
                                                ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                : undefined,
                                        zIndex: 1
                                    }),
                                }}
                            />
                    </div>

                    <div style={{width:"17%",marginLeft:"2%"}}>
                        <div style={{fontSize:"12px",fontWeight:"500",color:"grey",paddingBottom:"2px"}}>Status</div>
                        <Select 
                            options={sourceStatusOptions}
                            value={sourceStatusSelected}
                            onChange={(e)=>{setSourceStatusSelected(e)}}
                            menuPortalTarget={document.body} 
                            styles={{ 
                                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                                    control: (base,state) => ({
                                        ...base,
                                        // maxHeight: 100,
                                        minHeight: 30,
                                        transform:'scale(0.9) translateX(-6.5%)',
                                        overflowY: "auto",
                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                        boxShadow: 0,
                                        '&:hover': {
                                            border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                            boxShadow: 0,
                                        }
                                    }),
                                    option: (styles, {isFocused, isSelected}) => ({
                                        ...styles,
                                        fontSize:'11px',
                                        background: isFocused
                                            ? localStorage.getItem('ambientColor')
                                            : isSelected
                                                ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                : undefined,
                                        zIndex: 1
                                    }),
                                }}
                            />
                    </div>
                </div>
                <div style={{display:'flex',marginTop:"5%",marginLeft:'2%'}}>
                    <div style={{transform:'scale(0.9)'}}><Button style={{fontSize:'13px',fontWeight:500,paddingLeft:'10px',paddingRight:'10px'}} sx={Constants.CONTAINEDBUTTON} onClick={()=>{showDemandSourceRequest(1,limit,true)}}>Search</Button></div>
                    <div style={{transform:'scale(0.9)'}}><Button style={{fontSize:'13px',fontWeight:500,marginLeft:'20px',paddingLeft:'10px',paddingRight:'10px'}} sx={Constants.OUTLINEDBUTTON} onClick={(e)=>{setPlatformSelected({label:"All",value:null});setAdvertiserSelected({label:"All",value:null});setSourceStatusSelected({label:"All",value:null});refreshComponent(e);}}>Reset</Button></div>
                </div>
                
                <div style={{borderBottom:"0.001px solid rgb(120,120,120)",display:'flex',marginLeft:"2%",marginRight:"2%",marginTop:"2%"}}></div>
                <div style={{display:"block",margin:"2%",marginTop:'1%',height:"75%"}}> 
                        {showLoader && 
                            <div className="loaderWrapper">  
                                <div className="loader">  
                                    <CircularProgress sx={{
                                                            color: localStorage.getItem('ambientColor'),
                                                            }}/> 
                                </div>
                            </div>
                        }
                        {!showLoader && 
                            <div style={{textAlign: "right"}}>
                                <label style={{paddingLeft:"20px",paddingRight:"20px"}}>LIMIT</label>
                                <NativeSelect
                                    inputProps={{
                                        name: 'LIMIT',
                                        id: 'uncontrolled-native',
                                    }}
                                    color="warning"
                                    value={limit}
                                    onChange={handleLimitChange}
                                    >
                                    <option>50</option>
                                    <option>100</option>
                                </NativeSelect>
                            </div>}
                        {!showLoader && showData()}
                        {!showLoader &&
                            <div className="row">
                                <Pagination count={Math.ceil(totalResults/limit)} page={page} onChange={handlePageChange} />
                            </div>
                            }
                </div>

                {showPopupNewDemandSource && 
                    <NewDemandSource
                        handleClose={togglePopup}
                        />
                }
                
            </div>            
        </div>
    )
}

export default DemandSource;
