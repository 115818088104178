import React from 'react'
import { useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { useScrollDirection } from 'react-use-scroll-direction';

import * as Constants from '../../data/constants';
import { getLocalStorageValue, sorting } from '../../util/util';

import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import NativeSelect from '@mui/material/NativeSelect';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
// import DeleteIcon from '@mui/icons-material/Delete';
// import LoginIcon from '@mui/icons-material/Login';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AssessmentIcon from '@mui/icons-material/Assessment';
import '../../css/pages/employess.css';


export const EmployeePage = () => {
    document.title = "Employees - Admin"

    const [manualUseState, setManualUseState] = React.useState(false);

    const [totalResults,setTotalResults] = useState("-");
    const [employeeDict,setEmployeeDict] = useState({loaded:false,data:[]})

    const [showLoader, setShowLoader] = React.useState(true);
        
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(50);
    // const [offset, setOffset] = React.useState(0);
    
    const [sortingFactor, setSortingFactor] = React.useState("id");
    const [sortingOrder, setSortingOrder] = React.useState(false); //true for asc and false for desc
    const [sortingFactorType, setSortingFactorType] = React.useState(true); //true for int and false for string

    const [threeDotMenuTop, setThreeDotMenuTop] = React.useState(0);
    const [threeDotMenuLeft, setThreeDotMenuLeft] = React.useState(0);
    const [showThreeDotMenu, setShowThreeDotMenu] = React.useState(false);
    const [editEmployee, setEditEmployee] = React.useState(false);
    const [statsEmployee, setStatsEmployee] = React.useState(false);
    
    const [searchRecord, setSearchRecord] = React.useState("");

    const [showResponsePopup, setShowResponsePopup] = React.useState(false);
    const [responsePopup, setResponsePopup] = React.useState(false);
    
    const [direction, setDirection] = React.useState(String)
    const { isScrollingUp, isScrollingDown } = useScrollDirection()
    
    React.useEffect(() => {

      isScrollingDown && setDirection('down')
      isScrollingUp && setDirection('up')
    }, [isScrollingDown, isScrollingUp])
    if(direction){
    }  
    if (isScrollingDown || isScrollingUp){
        if (showThreeDotMenu){
            setShowThreeDotMenu(false);
        }
    }

    var tableRows = []

    const handleLimitChange = (e) =>{
        setLimit(parseInt(e.target.value))
        setPage(1);
        getEmployees(1,e.target.value);
    };
    const handlePageChange = (event, value) => {
        setPage(value);
        // setOffset((value-1)*limit);
        getEmployees(value,limit);
    };


    const handleCheckSortingKey = (e) => {
        if (["id"].includes(e.target.id)){
            if (sortingFactor===e.target.id){
                setSortingOrder(!sortingOrder);
            }
            else{
                setSortingOrder(true);
            }
            setSortingFactor(e.target.id);
            setSortingFactorType(true);
        }
        else if (["name","contact","employee_type","skype_id","date_joined"].includes(e.target.id)){
            if (sortingFactor===e.target.id){
                setSortingOrder(!sortingOrder);
            }
            else{
                setSortingOrder(true);
            }
            setSortingFactor(e.target.id);
            setSortingFactorType(false);       
            }
        else{
            setSortingFactor("id");
            setSortingOrder(true);
            setSortingFactorType(true);   
        }
    }
    
    const getEmployees = async(manual_page=false,manual_limit=false) => {
        setShowLoader(true)
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"employees"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                limit:manual_limit ? manual_limit : limit,
                page:manual_page ? manual_page : page
            }
        })
        .then(resp => {
            setEmployeeDict({loaded:true,data:resp})
            setTotalResults(resp.data.count)
            setShowLoader(false);
        })
        .catch(function (error) {
            if (error.response) {
              // Request made and server responded
              setShowResponsePopup(true)
              setResponsePopup(error.response.data.result);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else {
              // Something happened in setting up the request that triggered an Error
              setResponsePopup(JSON.stringify({error:error.message}));
              console.log('Error', error.message);
            }
        
          });
    };

    if (!manualUseState){
        setManualUseState(true);
        getEmployees();
    }
    
    const threeDotButtonClicked = (e,item) => {
        if (e.target.id){
            if (showThreeDotMenu){
                setShowThreeDotMenu(false)
            }
            else{
                setShowThreeDotMenu(true)
            }
            var offsets = document.getElementById(e.target.id).getBoundingClientRect();
            var top = offsets.top-10;
            var left = offsets.left-220;
            setThreeDotMenuTop(top)
            setThreeDotMenuLeft(left)
            }         
    }

    const showData = () => {
        if (employeeDict.loaded!==false){
            
            var employeeData =  employeeDict.data.data.result
            sorting(employeeData,sortingFactor,sortingFactorType,sortingOrder);
            
            employeeData.map((item,index)=>{
                if (searchRecord==="" 
                    || item.id.toString().includes(searchRecord) 
                    || (item.name ? item.name.toLowerCase().includes(searchRecord.toLowerCase()) : false)){
                        var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
                        var timeoptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric',hour:'numeric',minute:'numeric' };
                        var registrationDate = new Date(item.date_joined)
                        var lastLogin = new Date(item.last_login)

                tableRows.push(
                        <tr key={index} className="dataRow" style={{backgroundColor:"rgb(255,255,255)" }}>
                            <td className="dataColumn">{item.id}</td>
                            <td className="dataColumn">
                                <NavLink style={{textDecoration:"none",color:"blue"}} to={"/account/employees/view/"+item.id}>
                                    {item.first_name + " " + item.last_name}
                                </NavLink>
                            </td>
                            <td className="dataColumn">{item.contact || "-"}</td>
                            <td className="dataColumn">{item.employee_type || "-"}</td>
                            <td className="dataColumn">{item.skype_id || "-"}</td>
                            <td className="dataColumn">{registrationDate.toLocaleDateString("en-US", options)}</td>
                            <td className="dataColumn">{item.last_login ? lastLogin.toLocaleDateString("en-US", timeoptions) : '-'}</td>
                            <td id={"threeDotButton"+index} className="dataColumn threeDotButton" onClick={(e)=>{setEditEmployee(item.id);setStatsEmployee(item.id+"_"+item.email);threeDotButtonClicked(e);}}>
                                <MoreVertIcon id={"threeDotButton"+index} onClick={(e)=>{setEditEmployee(item.id);threeDotButtonClicked(e);}}/>
                            </td>
                        </tr>
                )
                    }
                return 1;
            })
            return  <table className="dataTable">
                        <tbody>
                            <tr className="dataRow" style={{backgroundColor:"rgb(250,250,250)"}}>
                                <td className="dataColumnHead" style={{width:"4%"}} id="id" onClick={handleCheckSortingKey}>Id</td>
                                <td className="dataColumnHead" style={{width:"15%"}} id="name" onClick={handleCheckSortingKey}>Employee Name</td>
                                <td className="dataColumnHead" style={{width:"7%"}} id="contact" onClick={handleCheckSortingKey}>Contact</td>
                                <td className="dataColumnHead" style={{width:"7%"}} id="employee_type" onClick={handleCheckSortingKey}>Employee Type</td>
                                <td className="dataColumnHead" style={{width:"10%"}} id="skype_id" onClick={handleCheckSortingKey}> Skype Id</td>
                                <td className="dataColumnHead" style={{width:"10%"}} id="date_joined" onClick={handleCheckSortingKey}> Registration date</td>
                                <td className="dataColumnHead" style={{width:"10%"}} id="last_login" onClick={handleCheckSortingKey}> Last Login</td>
                                <td className="dataColumnHead" style={{width:"2%",background:"transparent"}}></td>
                            </tr>
                            {tableRows}
                            
                        </tbody>
                    </table>
            }
    }

    return (
        
            <div className="row">
                {true && 
                <div className="columnData" style={{width:"95%",height:"auto"}}>
                    {
                        showThreeDotMenu && editEmployee && 
                        <div className="threeDotMenu" style={{top:threeDotMenuTop,left:threeDotMenuLeft,height:"150px",width:"200px",zIndex:"100000"}}>
                        {/* 
                            <div style={{height:"50px"}} className="threeDotMenuItems" onClick={()=>{loginEmployee(editEmployee)}}>
                                <LoginIcon style={{color:"rgb(255, 87, 87)",transform:"scale(0.8)"}}/>
                                Login
                            </div> */}
                            <NavLink style={{textDecoration:"none",color:"black"}} to={"/account/employees/edit/" + editEmployee}>
                                <div style={{height:"50px",width:"90%",paddingLeft:"10%"}} className="threeDotMenuItems">
                                    <ModeEditOutlineIcon style={{color:"rgb(0, 119, 255)",transform:"scale(0.8)"}}/>
                                    Edit
                                    </div>
                            </NavLink>  
                            <NavLink style={{textDecoration:"none",color:"black"}} to={"/statistics/daily?advertiser_manager=" + statsEmployee} target="_blank" onClick={()=>{setShowThreeDotMenu(false)}}>
                                <div style={{height:"50px",width:"90%",paddingLeft:"10%"}} className="threeDotMenuItems">
                                    <AnalyticsIcon style={{color:"rgb(255, 100, 120)",transform:"scale(0.8)"}}/>
                                    View Advertiser Stats
                                    </div>
                            </NavLink>  
                            <NavLink style={{textDecoration:"none",color:"black"}} to={"/statistics/daily?affiliate_manager=" + statsEmployee} target="_blank" onClick={()=>{setShowThreeDotMenu(false)}}>
                                <div style={{height:"50px",width:"90%",paddingLeft:"10%"}} className="threeDotMenuItems">
                                    <AssessmentIcon style={{color:"rgb(120, 119, 255)",transform:"scale(0.8)"}}/>
                                    View Affiliate Stats
                                    </div>
                            </NavLink>  
                            {/* <div style={{border:"1px solid grey"}}></div>

                            <div className="threeDotMenuItems" onClick={()=>{setShowResponsePopup(true);setResponsePopup("Feature not available right now!")}}>
                                <DeleteIcon style={{color:"rgb(255, 87, 87)",transform:"scale(0.8)"}}/>
                                Delete
                            </div> */}
                        </div>
                    }
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div style={{marginLeft:"2%",marginTop:"2%",fontSize:"12px"}}>Found {totalResults} Employees</div>
                        <div style={{marginRight:"2%",marginTop:"2%",fontSize:"12px",display:'flex',alignItems:'center'}}>
                            <div style={{marginRight:'15px',fontSize:'12px',cursor:'pointer',color:localStorage.getItem('ambientColor')}} onClick={()=>{localStorage.removeItem('oldEmployeePage');window.location.reload();}}>
                                Switch to New Design
                            </div>
                            <NavLink style={{textDecoration:"none",color:"black"}} to={"/account/employees/add"}>
                                <Button variant="outlined" color="warning">New Employee</Button>
                            </NavLink>
                        </div>
                    </div>
                    <div style={{display:"flex",marginTop:"2%"}}>
                        <div style={{width:"22%",margin:"10px",fontSize:"6px",transform:"scale(0.8)"}}>
                            <TextField fullWidth size="small" placeholder='Search Records' onChange={(e)=>{setSearchRecord(e.target.value)}}/>
                        </div>
                    </div>

                
                    <div style={{display:"block",margin:"2%",height:"75%"}}> 
                            {showLoader && 
                                <div className="loaderWrapper">  
                                    <div className="loader">  
                                        <CircularProgress color="warning"/> 
                                    </div>
                                </div>
                            }
                            {!showLoader && 
                                <div style={{textAlign: "right"}}>
                                    <label style={{paddingLeft:"20px",paddingRight:"20px"}}>LIMIT</label>
                                    <NativeSelect
                                        value={limit}
                                        inputProps={{
                                            name: 'LIMIT',
                                            id: 'uncontrolled-native',
                                        }}
                                        color="warning"
                                        onChange={handleLimitChange}
                                        >
                                        <option>50</option>
                                        <option>100</option>
                                    </NativeSelect>
                                </div>}
                            {!showLoader && showData()}
                            {!showLoader &&
                                <div className="row">
                                    <Pagination count={Math.ceil(totalResults/limit)} page={page} onChange={handlePageChange} />
                                </div>
                                }
                    </div>

                    {showResponsePopup && 
                        <div className="popup-box">
                            <div className="box-response">
                            <div className="popupHeader" >{"Error"}</div>
                            <div style={{borderBottom:"2px solid orange"}}></div>
                                <div className="popupData">
                                {responsePopup}
                                </div>
                            <div className="close-icon-response" onClick={()=>{setShowResponsePopup(false)}}>X</div>
                            </div>
                        </div>
                    }
                    
                </div>          
                }  
                {false && 
                    <div className="columnData" style={{width:"95%",height:"auto"}}>
                        <div style={{width:'100%',height:"50vh",display:"flex",alignItems:"center",justifyContent:"center"}}> Page under construction</div>
                    </div>
                }
            </div>
            
    )
}

export default EmployeePage;
