import React from 'react';
import '../../css/pages/viewPage.css';
import axios from 'axios';
import { NavLink } from "react-router-dom";
import parse from 'html-react-parser';

import * as Constants from '../../data/constants';

import Button from '@mui/material/Button';
import { Store } from 'react-notifications-component';
import { getLocalStorageValue } from '../../util/util';


export const ViewAffiliatePage = () => {

    const affiliateId = window.location.href.split("/")[window.location.href.split("/").length-1];
    const [manualUseState, setManualUseState] = React.useState(false);

    const [email, setEmail] = React.useState("");
    // const [newPassword, setNewPassword] = React.useState("");
    // const [username, setUsername] = React.useState("");
    const [companyName, setCompanyName] = React.useState("");
    // const [deviceID, setDeviceId] = React.useState("");
    const [contactPerson, setContactPerson] = React.useState("");
    const [contactMobile, setContactMobile] = React.useState("");
    // const [contactEmail, setContactEmail] = React.useState("");
    const [managerID, setManagerID] = React.useState({id:"",name:""});
    const [affiliateStatus, setAffiliateStatus] = React.useState("Active");
    const [skype, setSkype] = React.useState("");
    const [site, setSite] = React.useState("");
    const [note, setNote] = React.useState("");
    // const [tags, setTags] = React.useState("");
    const [address, setAddress] = React.useState({country:"India"});
    
    const [localPostback, setLocalPostback] = React.useState(false);
    const [globalPostback, setGlobalPostback] = React.useState(false);
    const [apiKey, setApiKey] = React.useState(false);

    const [showPopup, setShowPopup] = React.useState(false);

    const showAdvertiserRequest = async() => {
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.AFFILIATES),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
              id:affiliateId,
            }
        }
        )
        .then((resp) => {
            var responseResult = resp.data.result

            for (var item in responseResult){
                if (responseResult[item].id.toString() === affiliateId){
                    setEmail(responseResult[item].email)
                    setCompanyName(responseResult[item].company)
                    setContactMobile(responseResult[item].contact_number)
                    setContactPerson(responseResult[item].contact_person)
                    setManagerID({id:responseResult[item].manager_name ? responseResult[item].manager_name.split("(")[1].split(")")[0] : "",name:responseResult[item].manager_name ? responseResult[item].manager_name.split("(")[1].split(")")[1] : ""})
                    setSkype(responseResult[item].skype_id)
                    setSite(responseResult[item].website)
                    setEmail(responseResult[item].email)
                    setAddress(responseResult[item].address)
                    setAffiliateStatus(responseResult[item].affiliate_status)
                    setNote(responseResult[item].note)
                }}
        })
        .catch(err => {
            console.error(err);
            if(err.response){
                if(err.response.data && err.response.data.result){
                    setShowPopup(err.response.data.result)
                }
            }
        })
    };

    async function loginAffiliate(id) {
        return await axios({
          method: 'GET',
          url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"login/direct"),
          headers: {
            Authorization:getLocalStorageValue('adminPanelLoginData').token
          },
          
          params: {
            "affiliate_id":id
              }
          })
      .then(resp => {
          var token_dict = JSON.stringify({token:"Token "+resp.data.token})
          window.open("http://"+Constants.AFFILIATE_LOGIN_HOST+"/dashboard/?email="+resp.data.user.email+"&aff_id="+resp.data.user.id+"&companyname="+resp.data.user.company+"&token="+token_dict+"&manager_email="+resp.data.user.manager_email+"&manager="+resp.data.user.manager_name)
        })
      .catch(error => {
            if (error.response) {
                // Request made and server responded
                Store.addNotification({
                    title: "Error!",
                    message: typeof error.response.data.result === "string" ? error.response.data.result : "Some error occured",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                }); 
                console.log(error.response.status);
                console.log(error.response.headers);
            } 
            else {
                // Something happened in setting up the request that triggered an Error
                Store.addNotification({
                    title: "Error!",
                    message: typeof error.message === "string" ? error.message : "Some error occured",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                }); 
                console.log('Error', error.message);
            }
        })
      }
    const getPostback = async() => {
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.POSTBACKS),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
              affiliate_id : affiliateId
            }
        })
        .then(resp => {
          setGlobalPostback(resp.data.result[0].global_postback[0])
          setLocalPostback(resp.data.result[0].local_postback[0])
        })
        .catch(err => {
            console.error(err);
        })
      };

    const getApiKey = async() => {
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"apikey","/",affiliateId,"/","view"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        })
        .then(resp => {
            setApiKey(resp.data.result)
        })
        .catch(err => {
            console.error(err);
        })
      };
      
    if (!manualUseState){
        setManualUseState(true);
        showAdvertiserRequest();
        getPostback();
        getApiKey();
    }

    
    const showAddress = () => {
        var addressString = ""
        if (address.address1){
            addressString += address.address1 + " , <br/>"
        }
        if (address.address2){
            addressString += address.address2 + " , <br/>"
        }
        if (address.city){
            addressString += address.city + " , <br/>"
        }
        if (address.country){
            addressString += address.country + " , <br/>"
        }
        if (address.zipcode){
            addressString += address.zipcode + " ,<br/>"
        }
        return addressString.substring(0,addressString.length-7)
    }
    return(
        <div style={{height:"100%",minHeight:"80vh",paddingTop:"2%"}}>
            <div style={{display:"flex"}}>
                {showPopup && 
                    <div className="popup-box">
                        <div className="box-response">
                        <div className="popupHeader" >{"Error"}</div>
                        <div style={{borderBottom:"2px solid orange"}}></div>
                            <div className="popupData">
                            {showPopup}
                            </div>
                            <NavLink style={{textDecoration:"none",color:"black"}} to={"/affiliates/management/"}>
                                <div className="close-icon-response" onClick={()=>{setShowPopup(false)}}>X</div>
                            </NavLink>
                        </div>
                    </div>
                }
                <div style={{paddingLeft:"2%",width:"48%",minHeight:"400px"}}>
                    <div className="columnData" style={{width:"100%",minHeight:"400px",height:"auto",marginBottom:"1px",paddingBottom:"3%"}}>
                        <div style={{display:'flex',padding:"5%",paddingTop:"2%",paddingBottom:"0%",justifyContent:"space-between",alignItems:"center"}}>
                            <div style={{width:"40%",display:'flex',alignItems:'center'}}><div style={{paddingLeft:"2%",fontSize:"15px",fontWeight:"700",width:'30%'}}>Affiliate:</div><div style={{width:'70%',paddingLeft:"5px",fontSize:"14px",fontWeight:"700"}}>{companyName }</div></div>
                            <div style={{width:"60%",display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                                <div style={{transform:"scale(0.8)"}}><Button color="success" variant="outlined" onClick={()=>{loginAffiliate(affiliateId)}}>Login</Button></div>
                                <div style={{transform:"scale(0.8)"}}><NavLink style={{textDecoration:"none",color:"black"}} target="_blank" to={"/statistics/daily?affiliate="+companyName+" - "+ affiliateId}><Button variant="outlined" color="warning">VIEW STATS</Button></NavLink></div>
                                <div style={{transform:"scale(0.8)"}}><NavLink style={{textDecoration:"none",color:"black",transform:"scale(0.8)"}} to={"/affiliates/management/edit/"+affiliateId}><Button variant="contained" color="warning">EDIT</Button></NavLink></div>
                            </div>
                        </div>
                        <div style={{borderBottom:"1px solid black",margin:"2%",marginBottom:"0%"}}></div>
                        <div className="container" style={{paddingTop:"2%",marginTop:"0%"}}>
                            <table width={"100%"}>
                                <tbody>
                                    <tr>
                                        <td className='leftSideColumn' style={{width:"30%"}}>
                                            ID
                                        </td>
                                        <td className='rightSideColumn'>
                                            {affiliateId ? affiliateId : "Not available"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='leftSideColumn'>
                                            Email
                                        </td>
                                        <td className='rightSideColumn'>
                                            {email ? email : "Not available"}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='leftSideColumn'>
                                            Affiliate Status
                                        </td>
                                        <td className='rightSideColumn'>
                                            {affiliateStatus ? affiliateStatus : "Not available"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='leftSideColumn'>
                                            Company Name
                                        </td>
                                        <td className='rightSideColumn'>
                                            {companyName ? companyName : "Not available"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='leftSideColumn'>
                                            Contact Person
                                        </td>
                                        <td className='rightSideColumn'>
                                            {contactPerson ? contactPerson : "Not available"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='leftSideColumn'>
                                            Contact Person Mobile
                                        </td>
                                        <td className='rightSideColumn'>
                                            {contactMobile ? contactMobile : "Not available"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='leftSideColumn'>
                                            Manager
                                        </td>
                                        <td className='rightSideColumn'>
                                            {managerID.name ? managerID.name : "Not available"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='leftSideColumn'>
                                            Skype Id
                                        </td>
                                        <td className='rightSideColumn'>
                                            {skype ? skype : "Not available"}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='leftSideColumn'>
                                            Note
                                        </td>
                                        <td className='rightSideColumn'>
                                            {note ? note : "Not available"}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='leftSideColumn'>
                                            Website
                                        </td>
                                        <td className='rightSideColumn'>
                                            {site ? site : "Not available"}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='leftSideColumn'>
                                            Address
                                        </td>
                                        <td className='rightSideColumn'>
                                            { address!=={} && parse(showAddress())
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* <div className="leftSideDiv">
                                <label className='leftSideLabel'>ID</label>
                                <label className='leftSideLabel'>Company Name</label>
                                <label className='leftSideLabel'>Contact Person</label>
                                <label className='leftSideLabel'>Email</label>
                                <label className='leftSideLabel'>Skype Id</label>
                            </div>
                            <div className="rightSideDiv">
                                
                                <label className='rightSideLabel'>{advertiserId ? advertiserId : ""}</label>
                                <label className='rightSideLabel' >{companyName ? companyName : ""}</label>
                                <label className='rightSideLabel' >{contactPerson ? contactPerson : ""}</label>
                                <label className='rightSideLabel' >{email ? email : 'Not available'}</label>
                                <label className='rightSideLabel' >{skype ? skype : "Not available"}</label>
                            </div> */}
                        </div>
                        {/* <div style={{marginLeft:"30%",marginTop:"5%"}}>
                            <NavLink style={{marginLeft:"2%",textDecoration:"none",color:"black"}} to={"/affiliates"}><Button variant="text">Cancel</Button></NavLink>
                        </div> */}
                    </div>
                </div>
                <div style={{paddingLeft:"2%",width:"48%",minHeight:"200px"}}>
                    <div className="columnData" style={{width:"100%",minHeight:"200px",height:"auto",marginBottom:"1px",paddingBottom:"3%"}}>
                        <div style={{paddingLeft:"2%",marginTop:"2%",fontSize:"15px",fontWeight:"700"}}>Global postback and Local postback</div>
                        <div className="container">
                            <table width={"100%"}>
                                <tbody >
                                    <tr style={{minHeight:"50%"}}>
                                        <td className='leftSideColumn' style={{fontSize:"12px",minHeight:"50%"}}>
                                            Global Postback
                                        </td>
                                        <td className='rightSideColumn' style={{fontSize:"12px",minHeight:"50%"}}>
                                            {globalPostback ? globalPostback : "Not available"}
                                        </td>
                                    </tr>
                                    <tr style={{minHeight:"50%"}}>
                                        <td className='leftSideColumn' style={{fontSize:"12px",minHeight:"50%"}}>
                                            Local Postback
                                        </td>
                                        <td className='rightSideColumn' style={{fontSize:"12px",minHeight:"50%"}}>
                                            {localPostback ? localPostback : "Not available"}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                    <div className="columnData" style={{width:"100%",minHeight:"100px",height:"auto",marginBottom:"1px",paddingBottom:"3%"}}>
                        <div style={{paddingLeft:"2%",marginTop:"2%",fontSize:"15px",fontWeight:"700"}}>API KEY</div>
                        <div className="container">
                            <table width={"100%"}>
                                <tbody >
                                    <tr style={{minHeight:"50%"}}>
                                        <td className='leftSideColumn' style={{fontSize:"12px",minHeight:"50%"}}>
                                            API Key
                                        </td>
                                        <td className='rightSideColumn' style={{fontSize:"12px",minHeight:"50%"}}>
                                            {apiKey ? apiKey : "Not available"}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>
    )
    };

export default ViewAffiliatePage;