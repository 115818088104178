import React, { useState } from 'react'
import '../../../../css/pages/v2/demandoffers.css';

import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

import { getAdvertisers, getDemandSourcePlatforms} from '../../../../apis/dropdown/dropdowns';
import * as Constants from '../../../../data/constants';
import { increase_brightness } from '../../../../components/customization/customization';
import axios from "axios";

import Select from 'react-select';
import { Store } from 'react-notifications-component';
import { NavLink } from 'react-router-dom';
import { getLocalStorageValue } from '../../../../util/util';
// import FiberNewIcon from '@mui/icons-material/FiberNew';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: localStorage.getItem('ambientColor'),
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: localStorage.getItem('ambientColor'),
    },
    '& .MuiOutlinedInput-root': {
        backgroundColor: "white !important",
        fontSize: "14px !important",
      '& fieldset': {
        borderColor: 'rgb(200,200,200)',
        // backgroundColor:'white',
        // zIndex:'0',
      },
      '&:hover fieldset': {
        borderColor: localStorage.getItem('ambientColor'),
      },
      '&.Mui-focused fieldset': {
        borderColor: localStorage.getItem('ambientColor'),
      },
    },
  });
  
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function useForceUpdate(){
  const [value, setValue] = React.useState(0); // integer state
  if(false){
    console.log(value)
  }
  return () => setValue(value => value + 1); // update the state to force render
}

const getRequests = async(method,setMethod) => {
    var resp = await method;
    setMethod(resp)
    return resp;
}

const AddEditDemandSource = () => {
    
    const refreshComponent = useForceUpdate();
    const [tabValue, setTabValue] = useState(window.location.href.includes("demandsource/management/edit/") ? 1 : 0);
    const [manualUseState, setManualUseState] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [showLoader, setShowLoader] = useState(window.location.href.includes("demandsource/management/edit/") ? true : false);

    const [demandSourceId, setDemandSourceId] = useState("");
    const [platformList, setPlatformList] = useState([]);
    const [advertiserList, setAdvertiserList] = useState([]);

    const [platformSelected, setPlatformSelected] = useState(false);
    const [platformSearched, setPlatformSearched] = useState("");
    
    const [advertiserSelected, setAdvertiserSelected] = useState(null);
    const [payoutPercentageSelected, setPayoutPercentageSelected] = useState(70);
    const [apikeySelected, setApikeySelected] = useState("");
    const [extraDetailsSelected, setExtraDetailsSelected] = useState({0:{key:'',value:''}});
    const [trackingMacrosSelected, setTrackingMacrosSelected] = useState({0:{key:'',value:''}});
    const [baseurlSelected, setBaseurlSelected] = useState("");
    const [showBaseUrlCheck, setShowBaseUrlCheck] = useState(true);
    const [showApiKeyCheck, setShowApiKeyCheck] = useState(true);
    const [showIp, setShowIp] = useState(false);
        
    const APICHECK = {
        accordmobi:{
            baseurl:"https://api.accordmobi.com",
            extraDetails:{0:{key: "username",value:"",default:true}},
            trackingMacros:{0:{key:'aff_sub',value:'{click_id}'},
                            1:{key:'aff_sub2',value:'{sub2}'},
                            }
        },
        acemediaplustech:{
            baseurl:"http://oapi.acemediaplustech.com",
            extraDetails:"Do not show",
        },
        adinall:{
            baseurl:"http://g.cpi.adinall.com/",
            extraDetails:{0:{key: "aff_id",value:"",default:true,pseudoKey:"Affiliate ID"}},
            trackingMacros:{0:{key:'aff_sub1',value:'{click_id}'},
                            1:{key:'source_id',value:'{sub2}'},
                            }
        },
        adosiz:{
            baseurl:"https://adminapi.adosiz.com/",
            extraDetails:{0:{key: "client_id",value:"",default:true},
                          1:{key: "pub_id",value:"",default:true}
                        },
        },
        admuxer:{
            baseurl:"http://feed.admuxer.com",
            extraDetails:"Do not show",
            trackingMacros:{0:{key:'sub_param1',value:'{click_id}'},
                            1:{key:'sub_aff',value:'{sub2}'},
                            }
        },
        adnextmedia:{
            baseurl:"https://partners.adnextmedia.com",
            extraDetails:{0:{key: "type",value:"findAll",default:true,defaultValue:true},
                        1:{key: "api_aid",value:"",default:true}},
            trackingMacros:{0:{key:'subid1',value:'{click_id}'},
                            1:{key:'subid2',value:'{sub2}'},
                            }
        },
        adobrain:{
            baseurl:"Need from user",
            extraDetails:"Do not show",
        },
        adsile:{
            baseurl:"Need from user",
            extraDetails:"Do not show",
        },
        adsplay:{
            baseurl:"http://affiliates.adsplay.in",
            extraDetails:{0:{key: "api_aid",value:"",default:true,pseudoKey:"Affiliate ID"}},
            trackingMacros:{0:{key:'subid1',value:'{click_id}'},
                            1:{key:'siteid',value:'{sub2}'},
                            }
        },
        adwool:{
            baseurl:"http://api.adwool.com",
            extraDetails:{0:{key: "username",value:"",default:true,pseudoKey:"Username"}},
            trackingMacros:{0:{key:'aff_sub',value:'{click_id}'},
                            1:{key:'aff_sub5',value:'{sub2}'},
                            }
        },
        aduclick:{
            baseurl:"https://panel.aduclick.io/",
            extraDetails:{0:{key: "username",value:"",default:true,pseudoKey:"Username"}},
            trackingMacros:{0:{key:'aff_sub1',value:'{click_id}'},
                            1:{key:'aff_sub2',value:'{sub2}'},
                            }
        },
        adsvigor:{
            baseurl:"http://api.adsvigor.com",
            extraDetails:"Do not show",
        },
        affise:{
            baseurl:"Need from user",
            extraDetails:"Do not show",
            trackingMacros:{0:{key:'sub1',value:'{click_id}'},
                            1:{key:'sub2',value:'{sub2}'},}
        },
        affle:{
            baseurl:"https://mapi.affle.co",
            extraDetails:{0:{key: "cId",value:"all",default:true,defaultValue:true}},
            trackingMacros:{0:{key:'cid',value:'{click_id}'},
                            1:{key:'pubid',value:'{sub2}'},
                            }
        },
        appflood:{
            baseurl:"http://api.appflood.com",
            extraDetails:"Do not show",
            trackingMacros:{0:{key:'aff_sub',value:'{click_id}'},
                            1:{key:'aff_sub6',value:'{sub2}'},
                            }
        },
        appsflower:{
            baseurl:"http://api2.appsflower.com",
            extraDetails:{0:{key: "a",value:"",default:true}},
        },
        appicmedia:{
            baseurl:"https://api.appicmedia.com",
            extraDetails:{0:{key: "status",value:"active",default:true,defaultValue:true},
                          1:{key: "affid",value:"",default:true}},
            trackingMacros:{0:{key:'aff_sub',value:'{click_id}'},
                          1:{key:'aff_sub2',value:'{sub2}'},
                          }
        },
        appier:{
            baseurl:"Need from user",
            extraDetails:"Do not show",
            trackingMacros:{0:{key: "aff_sub",value:"{click_id}"},
                          1:{key: "aff_sub2",value:"{sub2}"}},
        },
        appleads:{
            baseurl:"http://api.appleadstech.com",
            extraDetails:"Do not show",
        },
        appone:{
            baseurl:"Need from user",
            extraDetails:"Do not show",
            trackingMacros:{0:{key:'clickid',value:'{click_id}'},
                            1:{key:'pubid',value:'{sub2}'},
                            }
        },
        appsilon:{
            baseurl:"https://offerapi.appsilon.kr",
            extraDetails:"Do not show",
        },
        attrimob:{
            baseurl:"Need from user",
            extraDetails:"Do not show",
        },
        avazu:{
            baseurl:"http://api.c.avazunativeads.com",
            extraDetails:"Do not show",
            trackingMacros:{0:{key:'dv1',value:'{click_id}'},
                            1:{key:'nw_sub_aff',value:'{sub2}'},
                             2:{key:'device_id',value:'{sub4}'},
                            }
        },
        axismobi:{
            baseurl:"http://api.axismobi.live",
            extraDetails:{0:{key: "devapp_id",value:"",default:true}},
        },
        batmobi:{
            baseurl:"http://bulk3.batmobi.net",
            extraDetails:"Do not show",
            trackingMacros:{0:{key:'aff_sub',value:'{click_id}'},
                            1:{key:'aff_site_id',value:'{sub2}'},
                             2:{key:'adv_id',value:'{sub3}'},
                            }
        },
        bikiko:{
            baseurl:"http://offer.bikiko.com",
            extraDetails:"Do not show",
            trackingMacros:{0:{key:'sid',value:'{click_id}'},
                            1:{key:'subid',value:'{sub2}'},
                            }
        },
        bingomob:{
            extraDetails:"Do not show",
        },
        clicks9:{
            baseurl:"http://affiliates.clicks9.com",
            extraDetails:"Do not show",
            trackingMacros:{0:{key:'clickid',value:'{click_id}'},
                            1:{key:'source',value:'{sub2}'},
                            }
        },
        clickdealer:{
            baseurl:"https://partners.clickdealer.com",
            extraDetails:{0:{key: "affiliate_id",value:"",default:true}},
        },
        collectcent:{
            baseurl:"Need from user",
            extraDetails:"Do not show",
            trackingMacros:{0:{key:'aff_sub',value:'{click_id}'},
                            1:{key:'aff_sub2',value:'{sub2}'},
                            }
        },
        creativeclicks:{
            label:'Creative Clicks',
            baseurl:"Need from user",
            extraDetails:"Do not show",
            trackingMacros:{0:{key: "s1",value:"{click_id}"},
                          1:{key: "s2",value:"{sub2}"}},
        },
        datamaths:{
            baseurl:"Need from user",
            extraDetails:{0:{key: "Username",value:"",default:true}},
        },
        dooad:{
            baseurl:"Need from user",
            extraDetails:"Do not show",
        },
        doraads:{
            baseurl:"http://api.doraads.com ",
            extraDetails:{0:{key: "id",value:"",default:true}},
        },
        everflow:{
            baseurl:"https://api.eflow.team",
            extraDetails:"Do not show",
            trackingMacros:{0:{key:'sub1',value:'{click_id}'},
                            1:{key:'source_id',value:'{sub2}'},
                            }
        },
        filexmedia:{
            baseurl:"http://mgmt.filexmedia.com",
            extraDetails:{0:{key: "a",value:"",default:true}},
        },
        fizzylabs:{
            baseurl:"http://fizzylabs.afftrack.com",
            extraDetails:"Do not show",
        },
        fuseclick:{
            baseurl:"Need from user",
            extraDetails:{0:{key: "a",value:"",default:true}},
            ip:process.env.REACT_APP_FUSECLICK_IPS,
            trackingMacros:{0:{key:'aff_click_id',value:'{click_id}'},
                            1:{key:'sub_affid',value:'{sub2}'},
                            }
        },
        gadmobe:{
            baseurl:"http://ad.gadmobe.com",
            extraDetails:{0:{key: "username",value:"",default:true}},
            trackingMacros:{0:{key:'click_id',value:'{click_id}'},
                            1:{key:'pub_id',value:'{sub2}'},
                            }
        },
        gazetm:{
            baseurl:"https://api.gazetm.com",
            extraDetails:{0:{key: "client_id",value:"",default:true}},
            trackingMacros:{0:{key:'tx',value:'{click_id}'},
                            1:{key:'pd',value:'{sub2}'},
                            }
        },
        gitee:{
            baseurl:"http://eco-system.group",
            extraDetails:{0:{key: "email",value:"",default:true}}
        },
        gourdmobi:{
            baseurl:"http://api.gourdmobi.com",
            extraDetails:"Do not show",
            trackingMacros:{0:{key:'click_id',value:'{click_id}'},
                            1:{key:'aff_sub',value:'{sub2}'},
                            }
        },
        gxmedia:{
            baseurl:"http://api.gxmedia-ads.com",
            extraDetails:{0:{key: "clientdevapp_id_id",value:"",default:true}},
            trackingMacros:{0:{key:'aff_sub',value:'{click_id}'},
                            1:{key:'affiliate_id',value:'{sub2}'},
                            }
        },
        hasoffers:{
            baseurl:"Need from user",
            extraDetails:"Do not show",
            trackingMacros:{0:{key:'aff_sub',value:'{click_id}'},
            1:{key:'sub_aff_id',value:'{sub2}'},
            }
        },
        hehemobi:{
            baseurl:"http://apihh.hehemobi.com",
            extraDetails:"Do not show",
        },
        hopemobi:{
            baseurl:"Need from user",
            extraDetails:"Do not show",
            trackingMacros:{0:{key:'click_id',value:'{click_id}'},
                            1:{key:'sub_aff',value:'{sub2}'},
                            }
        },
        hugoffers:{
            baseurl:"Need from user",
            extraDetails:{0:{key: "cid",value:"",default:true}},
            trackingMacros:{0:{key:'aff_sub',value:'{click_id}'},
                            1:{key:'aff_pub',value:'{sub2}'},
                            }
        },
        huntdigitalmedia:{
            baseurl:"https://marketplace.huntdigitalmedia.com",
            extraDetails:"Do not show",
        },
        ichestnut:{
            baseurl:"http://api.ichestnut.net",
            extraDetails:"Do not show",
        },
        icubeswire:{
            baseurl:"https://api.icubeswire.co",
            extraDetails:"Do not show",
        },
        inmobi:{
            baseurl:"Need from user",
            extraDetails:{0:{key: "target",value:"affiliate_offer",default:true,defaultValue:true},
                          1:{key: "method",value:"findMyApprovedOffers",default:true,defaultValue:true},
                          2:{key: "userName",value:"",default:true},
                          3:{key: "secretKey",value:"",default:true}},
            trackingMacros:{0:{key:'aff_sub',value:'{click_id}'},
                          1:{key:'aff_sub2',value:'{sub2}'},
                          }
        },
        innermobi:{
            baseurl:"http://api.innermobi.com",
            extraDetails:"Do not show",
        },
        inplayable:{
            baseurl:"http://apipull.inplayable.com",
            extraDetails:{0:{key: "sid",value:"",default:true}},
        },
        instal:{
            baseurl:"Need from user",
            extraDetails:"Do not show",
            trackingMacros:{0:{key:'custom1',value:'{click_id}'},
                            1:{key:'aff_sub_id',value:'{sub2}'},
                            }
        },
        ioger:{
            baseurl:"http://api.ioger.com",
            extraDetails:"Do not show",
        },
        ioger_v2:{
            baseurl:"http://cpiapi.ioger.com",
            extraDetails:{0:{key: "publisher_id",value:"",default:true}},
        },
        jinglemobi:{
            baseurl:"Need from user",
            extraDetails:"Do not show",
            trackingMacros:{0:{key: "aff_sub",value:"{click_id}"},
                            1:{key: "aff_sub2",value:"{sub2}"},},
        },
        laikad:{
            baseurl:"https://api.laikad.com",
            extraDetails:"Do not show",
        },
        linkgoads:{
            baseurl:"http://www.linkgoads.com",
            extraDetails:{0:{key: "aff_id",value:"",default:true}},
            trackingMacros:{0:{key:'sub1',value:'{click_id}'},
                            1:{key:'child_id',value:'{sub2}'},
                            }
        },
        linkrall:{
            baseurl:"Need from user",
            extraDetails:"Do not show",
        },
        litchiads:{
            baseurl:"http://litchiads.api.newseed.pro/",
            extraDetails:"Do not show",
            trackingMacros:{0:{key:'aff_sub1',value:'{click_id}'},
                            1:{key:'source_id',value:'{sub2}'},
                            }
        },
        macannative:{
            baseurl:"https://macan-native.com",
            extraDetails:"Do not show",
            trackingMacros:{0:{key:'sid',value:'{click_id}'},
                            1:{key:'gid',value:'{sub2}'},
                            2:{key:'devad_id',value:'{sub3}'},
                            }
        },
        marversal:{
            baseurl:"Need from user",
            extraDetails:{0:{key: "Username",value:"",default:true}},
        },
        mediasurfer:{
            baseurl:"https://api.surfer.media",
            extraDetails:"Do not show",
        },
        mediumin:{
            baseurl:"http://api.mediumin.com",
            extraDetails:{0:{key: "aff_id",value:"",default:true}},
            trackingMacros:{0:{key:'aff_sub',value:'{click_id}'},
                            1:{key:'sub_channel',value:'{sub2}'},
                            }
        },
        megable:{
            baseurl:"Need from user",
            extraDetails:{0:{key: "request_uri",value:"/offer/Getoffer/getOffer",default:true,defaultValue:true},
                          1:{key: "app_id",value:"",default:true}},
            trackingMacros:{0:{key:'aff_sub',value:'{click_id}'},
                          1:{key:'channel',value:'{sub2}'},
                          }
        },
        melodong:{
            baseurl:"http://cpiapi.melodong.com",
            extraDetails:{0:{key: "publisher_id",value:"",default:true}},
            trackingMacros:{0:{key:'clickid',value:'{click_id}'},
                            1:{key:'channel',value:'{sub2}'},
                            }
        },
        merithutmedia:{
            baseurl:"https://partners.merithutmedia.com/",
            extraDetails:"Do not show",
        },
        mobihunter:{
            baseurl:"https://offer.mobihunteraff.com",
            extraDetails:{0:{key: "aff_id",value:"",default:true}},
            trackingMacros:{0:{key:'aff_sub2',value:'{click_id}'},
                            1:{key:'aff_sub5',value:'{sub2}'},
                            }
        },
        mobimax:{
            baseurl:"http://api.mobimax.cn",
            apiKey:"Do not show",
            extraDetails:{0:{key: "os",value:"",default:true},
                          1:{key: "sign",value:"",default:true},
                          2:{key: "time",value:"",default:true},
                          3:{key: "appid",value:"",default:true},
                        },
        },
        mobisummer:{
            baseurl:"Need from user",
            extraDetails:"Do not show",
            trackingMacros:{0:{key:'aff_sub2',value:'{click_id}'},
                            1:{key:'aff_sub5',value:'{sub2}'},
                            }
        },
        mobikok:{
            baseurl:"http://ssp.mobikok.com/inter/apps!get.action",
            extraDetails:"Do not show",
        },
        mobinetad:{
            baseurl:"https://api.mobinetad.net",
            extraDetails:{0:{key: "aff_id",value:"",default:true},
                        },
        },
        mobioinc:{
            baseurl:"https://api.mobioinc.com",
            extraDetails:"Do not show",
        },

        mobpower:{
            baseurl:"http://affiliate.mobpowertech.com",
            extraDetails:{0:{key: "appid",value:"",default:true},
                          1:{key: "appidios",value:"",default:true},
                        },
        },
        
        mobvista:{
            baseurl:"Need from user",
            extraDetails:{0:{key: "client_key",value:"",default:true}},
        },
        mobrain:{
            baseurl:"https://api.mobra.in",
            extraDetails:{0:{key: "username",value:"",default:true}},
            trackingMacros:{0:{key:'sid',value:'{click_id}'},
                            1:{key:'p',value:'{sub2}'},
                            }
        },
        mobsource:{
            baseurl:"http://offer.mobsource.top/",
            extraDetails:"Do not show",
        },
        mobrand:{
            baseurl:"https://api.mobrand.net",
            extraDetails:{0:{key: "source_id",value:"",default:true},
                          1:{key: "publisher_id",value:"",default:true}},
            trackingMacros:{0:{key:'aff_sub',value:'{click_id}'},
                          1:{key:'source',value:'{sub2}'},
                          }
        },
        mobsuccess:{
            baseurl:"https://www.farly.io/",
            extraDetails:{0:{key: "pubid",value:"",default:true},}
        },
        mocatech:{
            baseurl:"http://s2s.moca-tech.net/",
            extraDetails:{0:{key: "aff_id",value:"",default:true}},
            trackingMacros:{0:{key:'aff_sub',value:'{click_id}'},
                            1:{key:'aff_pub',value:'{sub2}'},
                            }
        },
        novabeyond:{
            baseurl:"http://api.novabeyond.net/",
            extraDetails:"Do not show",
        },
        occuad:{
            baseurl:"http://pub.occuad.net/",
            extraDetails:"Do not show",
        },
        offer18:{
            baseurl:"https://api.offer18.com",
            extraDetails:{0:{key: "authorized",value:"1",default:true,defaultValue:true},
                        1:{key: "aid",value:"",default:true},
                        2:{key: "mid",value:"",default:true},},
            trackingMacros:{0:{key:'aff_sub1',value:'{click_id}'},
                        1:{key:'aff_sub2',value:'{sub2}'},
                        }
        },
        offerslook:{
            baseurl:"Need from user",
            extraDetails:{0:{key: "Username",value:"",default:true}},
            ip:process.env.REACT_APP_OFFERLOOK_IPS,
            trackingMacros:{0:{key:'aff_sub1',value:'{click_id}'},
                            1:{key:'source_id',value:'{sub2}'},
                            }       
        },
        orangear:{
            baseurl:"Need from user",
            extraDetails:"Do not show",
            trackingMacros:{0:{key:'aff_sub',value:'{click_id}'},
                            1:{key:'aff_sub2',value:'{sub2}'},
                            }
        },
        paddlewaver:{
            baseurl:"http://api.campaign.paddlewaver.com",
            extraDetails:{0:{key: "publisher_id",value:"",default:true}},
            trackingMacros:{0:{key:'clickid',value:'{click_id}'},
                            1:{key:'channel',value:'{sub2}'},
                            }
        },
        pia:{
            baseurl:"Need from user",
            extraDetails:{0:{key: "apiClientId",value:"",default:true}},
        },

        proffcus:{
            baseurl:"Need from user",
            extraDetails:{0:{key: "secretkey",value:"",default:true}},
        },
        promoadx:{
            baseurl:"http://sdk.promoadx.com/",
            extraDetails:{0:{key: "username",value:"",default:true}},
        },
        quantifi:{
            baseurl:"Need from user",
            extraDetails:{0:{key: "username",value:"",default:true}},
        },
        qverse:{
            baseurl:"https://pub-api.qverseads.com",
            extraDetails:{0:{key: "apiClientId",value:"",default:true}},
            trackingMacros:{0:{key:'aff_sub',value:'{click_id}'},
                            1:{key:'aff_sub2',value:'{sub2}'},
                            }
        },
        recomob:{
            baseurl:"Need from user",
            extraDetails:{0:{key: "a",value:"",default:true,pseudoKey:'Affiliate'}},
            trackingMacros:{0:{key:'aff_sub1',value:'{click_id}'},
            1:{key:'source_id',value:'{sub2}'},
            }
        },
        rozmob:{
            baseurl:"https://ads.rozcloud.com/",
            extraDetails:{0:{key: "aid",value:"",default:true,pseudoKey:'Affiliate ID'}},
        },
        rtb:{
            baseurl:"http://cpi.rtbdem.com",
            apiKey:"Do not show",
            extraDetails:{0:{key: "affid",value:"",default:true,pseudoKey:'Affiliate'}},
            trackingMacros:{0:{key:'aff_sub',value:'{click_id}'},
                            1:{key:'aff_sub2',value:'{sub2}'},
                            }
        },
        thinkbigmediax:{
            label:'Think Big Media X',
            baseurl:"http://pub.thinkbigmediax.com/",
            extraDetails:"Do not show",
        },
        sapphyre:{
            baseurl:"Need from user",
            extraDetails:{0:{key: "affiliate_id",value:"",default:true}},
        },
        seanear:{
            baseurl:"http://api.seanear.com",
            extraDetails:{0:{key: "publisher_id",value:"",default:true}},
            trackingMacros:{0:{key:'clickid',value:'{click_id}'},
                            1:{key:'sub_channel',value:'{sub2}'},
                            }
        },
        sparkmob:{
            extraDetails:"Do not show",
        },
        superads:{
            baseurl:"http://offer.superads.cn",
            extraDetails:{0:{key: "u",value:"",default:true}},
            trackingMacros:{0:{key:'click_id',value:'{click_id}'},
                            1:{key:'ch_subid',value:'{sub2}'},
                            }
        },
        swaarm:{
            baseurl:"Need from user",
            trackingMacros:{0:{key:'pub_click_id',value:'{click_id}'},
                            1:{key:'pub_sub_id',value:'{sub2}'},
                            }
        },
        starmob:{
            baseurl:"https://api.starmobmedia.com",
            extraDetails:{0:{key: "affid",value:"",default:true},
                          1:{key: "status",value:"active",default:true},
                        },
        },
        starxline:{
            baseurl:"http://ads.starxline.com",
            extraDetails:"Do not show",
        },
        taptica:{
            baseurl:"https://api.taptica.com",
            extraDetails:"Do not show",
            trackingMacros:{0:{key:'tt_aff_clickid',value:'{click_id}'},
                            1:{key:'tt_sub_aff',value:'{sub2}'},
                            }
        },
        tenmmads:{
            baseurl:"http://offer.tenmmads.com",
            extraDetails:{0:{key: "affId",value:"",default:true}},
        },
        totoroadx:{
            baseurl:"Need from user",
            extraDetails:{0:{key: "id",value:"",default:true}},
            trackingMacros:{0:{key:'aff_sub',value:'{click_id}'},
                            1:{key:'aff_sub2',value:'{sub2}'},
                            }
        },
        touchpointcorp:{
            baseurl:"http://api.touchpointcorp.com",
            extraDetails:"Do not show",
        },
        trackier:{
            baseurl:"https://api.trackier.com",
            extraDetails:"Do not show",
            trackingMacros:{0:{key:'p1',value:'{click_id}'},
                            1:{key:'source',value:'{sub2}'},
                            }
        },
        trackrc:{
            baseurl:"http://www.trackrc.com",
            extraDetails:"Do not show",
        },
        upyield:{
            baseurl:"Need from user",
            extraDetails:"Do not show",
        },
        valista:{
            baseurl:"http://api.valista.io",
            extraDetails:{0:{key: "ptn_code",value:"",default:true}},
        },
        vistamob:{
            baseurl:"http://partner.vistamob.com",
            extraDetails:{0:{key: "pub_id",value:"",default:true}},
        },
        vikingmedia:{
            baseurl:"Need from user",
            extraDetails:{0:{key: "pub_id",value:"",default:true}},
        },
        yomolab:{
            baseurl:"Need from user",
            extraDetails:"Do not show",
        },
        youmi:{
            baseurl:"http://ad.api.yyapi.net",
            extraDetails:"Do not show",
            trackingMacros:{0:{key:'aff_sub',value:'{click_id}'},
                            1:{key:'source',value:'{sub2}'},
                            }
        },

    }
    
    const showDemandSourceRequest = async(id,platformsList) => {
        setShowLoader(true);
        setTabValue(1)
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.DEMANDSOURCE),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                id,
            }
        })
        .then((resp) => {
            setShowLoader(false);
            if(resp.data.result.length>0){
                var responseResult = resp.data.result[0]
                var platform = {label:responseResult.platform,value:responseResult.platform}
                for(var index in platformsList){
                    if(platformsList[index].value === responseResult.platform){
                        platform = platformsList[index]
                        break;
                    }
                }
                setPlatformSearched(platform.label)
                selectPlaform(platform,responseResult.extra_details,responseResult.macros_details)
                setPlatformSelected(platform)
                if(responseResult.advertiser_name.includes("(") && responseResult.advertiser_name.includes(")")){
                    var advertiserId = responseResult.advertiser_name.split(")")[0].split("(")[1]
                    var advertiserName = responseResult.advertiser_name.split(")")[1]
                    setAdvertiserSelected({label:advertiserName +" - "+advertiserId,value:advertiserId})
                }
                setBaseurlSelected(responseResult.base_url)
                setApikeySelected(responseResult.api_key)
                setPayoutPercentageSelected(responseResult.payout_percentage)
            }
        })

        .catch(error => {
            setShowLoader(false);
            if (error.response) {
                // Request made and server responded
                Store.addNotification({
                    title: "Error!",
                    message: typeof error.response.data.result === "string" ? error.response.data.result : "Some error occured",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                }); 
                console.log(error.response.status);
                console.log(error.response.headers);
            } 
            else {
                // Something happened in setting up the request that triggered an Error
                Store.addNotification({
                    title: "Error!",
                    message: typeof error.message === "string" ? error.message : "Some error occured",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                }); 
                console.log('Error', error.message);
            }
        })
    }

    if(!manualUseState){
        var newPlatforms = ['datamaths','vikingmedia','touchpointcorp','hehemobi']
        setManualUseState(true);
        getRequests(getAdvertisers(false),setAdvertiserList);
        getRequests(getDemandSourcePlatforms(false,newPlatforms),setPlatformList).then(platforms=>{
        if(window.location.href.includes("demandsource/management/edit/")){
            document.title = 'Edit Demand Source'
            setIsEdit(true);
            setTabValue(1);
            setDemandSourceId(window.location.href.split("demandsource/management/edit/")[1])
            showDemandSourceRequest(window.location.href.split("demandsource/management/edit/")[1],platforms)
        }
        else{
            document.title = "Add Demand Source"
        }
        });
    }

    const handleTabValue = (event, newValue) => {
        setTabValue(newValue);
    };

    const showImage = (item) => {
        try{
            try{
                return <img src={require('../../../../img/platforms/'+item.value.toLowerCase()+'.png')} alt='' width={"60%"} height={["mocatech","seanear","youmi"].includes(item.value.toLowerCase()) ? "80%" : ""} ></img>
            }
            catch{
                return <img src={require('../../../../img/platforms/'+item.label.toLowerCase()+'.png')} alt='' width={"60%"} height={["mocatech","seanear","youmi"].includes(item.label.toLowerCase()) ? "80%" : ""} ></img>
            }
        }
        catch{
            return <label style={platformSelected.value===item.value ? {color:localStorage.getItem('ambientColor')} : {color:'grey'}}>{item.label ? item.label[0] : ""}</label>
        }
    }

    const selectPlaform = (item,extradetails,trackingmacros) => {
        setPlatformSelected(item);
        if(APICHECK[item.value]){
            if(APICHECK[item.value].ip){
                setShowIp(APICHECK[item.value].ip)
            }
            else{
                setShowIp(false)
            }
            if(APICHECK[item.value].baseurl!=="Need from user"){
                setBaseurlSelected(APICHECK[item.value].baseurl)
                setShowBaseUrlCheck(false);
            }
            else{
                setBaseurlSelected("")
            }
            if(APICHECK[item.value].apiKey==="Do not show"){
                setShowApiKeyCheck(false);
            }
            else{
                setShowApiKeyCheck(true)
            }
            
            if(APICHECK[item.value].extraDetails){
                if(APICHECK[item.value].extraDetails==="Do not show"){
                    setExtraDetailsSelected("Do not show")
                }
                else{
                    if(!extradetails){
                        setExtraDetailsSelected(APICHECK[item.value].extraDetails)
                    }
                    else{
                        var tempextradetails = {}
                        var keyIndex = 0
                        for(var extradetailKey in extradetails){
                            var foundflag = false;
                            for(var index in APICHECK[item.value].extraDetails){
                                if(APICHECK[item.value].extraDetails[index].key===extradetailKey){
                                    foundflag = true
                                    break;
                                }
                            }
                            if(foundflag){
                                tempextradetails[keyIndex] = APICHECK[item.value].extraDetails[index]
                                tempextradetails[keyIndex].value = extradetails[extradetailKey]
                            }
                            else{
                                tempextradetails[keyIndex] = {key:extradetailKey,value:extradetails[extradetailKey]}
                            }
                            keyIndex +=1
                        }
                        setExtraDetailsSelected(tempextradetails)
                    }
                }
            }
            else{
                if(!extradetails || (extradetails && Object.keys(extradetails).length===0)){
                    setExtraDetailsSelected({0:{key:'',value:''}})
                }
                else{
                    tempextradetails = {}
                    keyIndex = 0
                    for(index in extradetails){
                        tempextradetails[keyIndex] = {key:index,value:extradetails[index]}
                        keyIndex+=1;
                    }
                    setExtraDetailsSelected(tempextradetails)
                }
            }
            if(APICHECK[item.value].trackingMacros){
                if(!trackingmacros){
                    setTrackingMacrosSelected(APICHECK[item.value].trackingMacros)
                }
                else{
                    var tempmacros = {}
                    keyIndex = 0
                    for(var trackingMacroKey in trackingmacros){
                        foundflag = false;
                        for(index in APICHECK[item.value].trackingMacros){
                            if(APICHECK[item.value].trackingMacros[index].key === trackingMacroKey){
                                foundflag = true
                                break;
                            }
                        }
                        if(foundflag){
                            tempmacros[keyIndex] = APICHECK[item.value].trackingMacros[index]
                            tempmacros[keyIndex].value = trackingmacros[trackingMacroKey]
                        }
                        else{
                            tempmacros[keyIndex] = {key:trackingMacroKey,value:trackingmacros[trackingMacroKey]}
                        }
                        keyIndex+=1
                    }
                    setTrackingMacrosSelected(tempmacros)
                }
            }
            else{
                if(!trackingmacros || (trackingmacros && Object.keys(trackingmacros).length===0)){
                    setTrackingMacrosSelected({0:{key:'',value:''}})
                }
                else{
                    var temptrackingMacros = {}
                    keyIndex = 0
                    for(index in trackingmacros){
                        temptrackingMacros[keyIndex] = {key:index,value:trackingmacros[index]}
                        keyIndex+=1;
                    }
                    setTrackingMacrosSelected(temptrackingMacros)
                }
            }
        }
        else{
            setBaseurlSelected("");
            setShowBaseUrlCheck(true);
            setShowApiKeyCheck(true);
            if(extradetails){
                var list = []
                var indexInt = 0
                for(var index2 in extradetails){
                    list[indexInt]={key:index2,value:extradetails[index2]}
                    indexInt+=1
                }
                setExtraDetailsSelected(list);
            }
            else{
                setExtraDetailsSelected({0:{key:'',value:''}});
            }
            if(trackingmacros){
                list = {}
                indexInt = 0
                for(index2 in trackingmacros){
                    list[indexInt]={key:index2,value:trackingmacros[index2]}
                    indexInt+=1
                }
                setTrackingMacrosSelected(list);
            }
            else{
                setTrackingMacrosSelected({0:{key:'',value:''}});
            }
        }
    }

    const showPlatforms = () => {
        return <div>
            <div style={{transform:'scale(0.8)',background:'white',marginTop:'20px'}}>
                <CssTextField fullWidth defaultValue={platformSearched} label='Search Platform' placeholder='Search Platform' size='small' onChange={(e)=>{setPlatformSearched(e.target.value);refreshComponent(e);}}/>
            </div>
            <div className='demandSourceAddPlatformContainer' >

                {platformList.map((item,index)=>{
                    if(item.value && (!platformSearched || item.label.toLowerCase().includes(platformSearched.toLowerCase()))){
                        return <div key={index} className={platformSelected.value===item.value ? 'demandSourceAddPlatformWrapper demandSourceAddPlatformWrapperSelected' : 'demandSourceAddPlatformWrapper'} onClick={()=>{selectPlaform(item)}}>
                            
                            {/* {item.isNew && 
                                <div style={{position:'absolute',right:0}}> 
                                    <FiberNewIcon sx={{fontSize:'30px',color:'rgb(0, 0, 42)'}}/>
                                </div>} */}
                            <div style={{display:'flex',height:"80%",width:'100%',fontSize:"48px",justifyContent:'center',alignItems:'center'}}>
                                {showImage(item)}
                            </div>
                            <div style={{display:'flex',height:"20%",justifyContent:'center',fontSize:"16px",alignItems:'center',color:'grey',fontWeight:'500'}}>
                                {APICHECK[item.value] && APICHECK[item.value].label ? APICHECK[item.value].label : item.label}
                            </div>
                        </div>
                    }
                    else{
                        return null;
                    }
                })}
            </div>
        </div>

    }
    
    var number =  /^-?\d+\.?\d*$/;


    const addTrackingMacrosRows = () => {
        setTrackingMacrosSelected({...trackingMacrosSelected,[Object.keys(trackingMacrosSelected).length]:{key:"",value:""}})
    }
    const removeTrackingMacros = (key) => {
        var tempTrackingMacros = trackingMacrosSelected;
        delete tempTrackingMacros[key];
        setTrackingMacrosSelected(tempTrackingMacros);
    }

    const showTrackingMacros = () => {
        return Object.keys(trackingMacrosSelected).map((key,keyIndex)=>{
            return <div key={keyIndex} style={{display:'flex',alignItems:'center'}}>
                <CssTextField size='small' style={{width:'50%',transform:'scale(0.8)'}} value={trackingMacrosSelected[key].key} placeholder={'Key'} onChange={(e)=>{var tempTrackingMacros = trackingMacrosSelected; tempTrackingMacros[key].key = e.target.value; setTrackingMacrosSelected(tempTrackingMacros);refreshComponent(e);}}/>
                <CssTextField size='small' style={{width:'50%',transform:'scale(0.8)'}} value={trackingMacrosSelected[key].value} placeholder={'Value'} onChange={(e)=>{var tempTrackingMacros = trackingMacrosSelected; tempTrackingMacros[key].value = e.target.value; setTrackingMacrosSelected(tempTrackingMacros);refreshComponent(e);}}/>
                <div style={{height:'18px',width:'18px',display:'flex',alignItems:'center',justifyContent:'center',border:'1px solid red',color:'red',userSelect:'none',cursor:'pointer',fontWeight:'500',fontSize:'15px',paddingBottom:'2px'}} 
                    onClick={(e)=>{removeTrackingMacros(key);refreshComponent(e)}}> 
                    -
                </div>
            </div>
        })
    }

    const addExtraDetailsRows = () => {
        setExtraDetailsSelected({...extraDetailsSelected,[Object.keys(extraDetailsSelected).length]:{key:"",value:""}})
    }
    const removeExtraDetail = (key) => {
        var tempExtraDetails = extraDetailsSelected;
        delete tempExtraDetails[key];
        setExtraDetailsSelected(tempExtraDetails);
    }

    const showExtraDetails = () => {
        return Object.keys(extraDetailsSelected).map((key,keyIndex)=>{
            return <div key={keyIndex} style={{display:'flex',alignItems:'center'}}>
                <CssTextField size='small' disabled={extraDetailsSelected[key].default} style={{width:'45%',transform:'scale(0.8)'}} value={extraDetailsSelected[key].pseudoKey ? extraDetailsSelected[key].pseudoKey : extraDetailsSelected[key].key} placeholder={'Key'} onChange={(e)=>{var tempExtraDetails = extraDetailsSelected; tempExtraDetails[key].key = e.target.value; setExtraDetailsSelected(tempExtraDetails);refreshComponent(e);}}/>
                <CssTextField size='small' disabled={extraDetailsSelected[key].defaultValue} style={{width:'45%',transform:'scale(0.8)'}} value={extraDetailsSelected[key].value} placeholder={'Value'} onChange={(e)=>{var tempExtraDetails = extraDetailsSelected; tempExtraDetails[key].value = e.target.value; setExtraDetailsSelected(tempExtraDetails);refreshComponent(e);}}/>
                {!extraDetailsSelected[key].default && 
                    <div style={{height:'18px',width:'18px',display:'flex',alignItems:'center',justifyContent:'center',border:'1px solid red',color:'red',userSelect:'none',cursor:'pointer',fontWeight:'500',fontSize:'15px',paddingBottom:'2px'}} 
                        onClick={(e)=>{removeExtraDetail(key);refreshComponent(e)}}> 
                        -
                    </div>
                }
            </div>
        })
    }

    const platformDetails = () => {
        return <div className='demandSourceDetailsContainer'>
            <div style={{minHeight:'50px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    {showImage(platformSelected)}
                </div>
                <div style={{fontSize:'20px',color:'rgb(150,150,150)',fontWeight:'600'}}>
                    {APICHECK[platformSelected.value] && APICHECK[platformSelected.value].label ? APICHECK[platformSelected.value].label : platformSelected.label}
                </div>
            </div>

            {showIp && 
            <div style={{display:'flex',alignItems:'center',justifyContent:'flex-start',background:'rgb(255, 255, 191)',padding:"5px",marginTop:'10px'}}>
                <div style={{fontSize:'15px'}}>
                    Please Update our IPs: <b>{JSON.stringify(showIp).replaceAll('"','').replaceAll("'","").replaceAll(","," , ")}</b>
                </div>
            </div>
                }
            <div style={{display:'flex',flexWrap:'wrap',justifyContent:'space-evenly'}}>
                <div style={{width:'48%'}}>
                    <div className='demandSourceDataContainer'>
                        <div className='demandSourceDataContainerHeading'>
                            General Details
                        </div>
                        <div className='demandSourceDataContainerData'>
                            <div style={{display:'flex',alignItems:'center',marginTop:'10px'}}>
                                <div className='demandSourceDataContainerDataLabel'>
                                    Advertiser
                                </div>
                                <div style={{width:'80%',transform:'scale(0.8) translate(-4.5%)'}}>
                                    <Select
                                        options={advertiserList}
                                        value={advertiserSelected}
                                        onChange={(e)=>{setAdvertiserSelected(e)}}
                                        menuPortalTarget={document.body} 
                                        placeholder={"Select Advertiser"}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                    control: (base,state) => ({
                                                        ...base,
                                                        // maxHeight: 100,
                                                        minHeight: 30,
                                                        overflowY: "auto",
                                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid grey',
                                                        boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                                        '&:hover': {
                                                            border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                            boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                                        }
                                                    }),
                                                    option: (styles, {isFocused, isSelected}) => ({
                                                        ...styles,
                                                        fontSize:'11px',
                                                        background: isFocused
                                                            ? localStorage.getItem('ambientColor')
                                                            : isSelected
                                                                ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                : undefined,
                                                        zIndex: 1
                                                    }),
                                                }}
                                        />
                                </div>
                                
                            </div>
                            <div style={{display:'flex',alignItems:'center',marginTop:'10px',marginBottom:'20px'}}>
                                <div className='demandSourceDataContainerDataLabel'>
                                    Payout
                                </div>
                                <div style={{width:'80%',transform:'scale(0.8) translate(-4.5%)',fontSize:'12px'}}>
                                    <CssTextField value={payoutPercentageSelected} fullWidth size='small' onChange={(e)=>{setPayoutPercentageSelected(e.target.value)}}/>
                                    {(!payoutPercentageSelected.toString().match(number) || !parseFloat(payoutPercentageSelected) || payoutPercentageSelected<0 || payoutPercentageSelected>100)
                                        &&
                                        <div style={{position:"absolute",color:'red'}}> Invalid Payout </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='demandSourceDataContainer'>
                        <div className='demandSourceDataContainerHeading'>
                            Tracking Macros
                        </div>
                        <div className='demandSourceDataContainerData'>
                                
                            <div style={{width:'90%',padding:'10px'}}>
                                {showTrackingMacros()}
                                <div style={{height:'18px',width:'18px',display:'flex',alignItems:'center',justifyContent:'center',userSelect:'none',cursor:'pointer',border:'1px solid '+localStorage.getItem('ambientColor'),color:localStorage.getItem('ambientColor'),fontWeight:'500',fontSize:'15px',marginLeft:'4%',marginTop:'3%',paddingBottom:'2px'}} onClick={addTrackingMacrosRows}> + </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{width:'48%'}}>
                    <div className='demandSourceDataContainer' style={{paddingBottom:'20px'}}>
                        <div className='demandSourceDataContainerHeading'>
                            Api Details
                        </div>
                        <div className='demandSourceDataContainerData'>
                            {   showBaseUrlCheck &&
                                <div style={{display:'flex',alignItems:'center',marginTop:'10px'}}>
                                    <div className='demandSourceDataContainerDataLabel'>
                                        Base Url
                                    </div>
                                    <div style={{width:'80%',transform:'scale(0.8) translate(-4.5%)',fontSize:'12px'}}>
                                        <CssTextField value={baseurlSelected==="Need from user" ? "" : baseurlSelected} fullWidth size='small' onChange={(e)=>{setBaseurlSelected(e.target.value)}}/>
                                    </div>
                                </div>
                            }
                            {   showApiKeyCheck &&
                                <div style={{display:'flex',alignItems:'center',marginTop:'10px'}}>
                                    <div className='demandSourceDataContainerDataLabel'>
                                        Api Key
                                    </div>
                                    <div style={{width:'80%',transform:'scale(0.8) translate(-4.5%)',fontSize:'12px'}}>
                                        <CssTextField value={apikeySelected} fullWidth size='small' onChange={(e)=>{setApikeySelected(e.target.value)}}/>
                                    </div>
                                </div>
                            }
                            {   extraDetailsSelected!=="Do not show" && 
                                <div style={{display:'flex',alignItems:'flex-start',marginTop:'10px'}}>
                                    <div className='demandSourceDataContainerDataLabel' style={{paddingTop:'20px'}}>
                                        Api Parameters
                                    </div>
                                    <div style={{width:'70%',padding:'10px'}}>
                                        {showExtraDetails()}
                                        <div style={{height:'18px',width:'18px',display:'flex',alignItems:'center',justifyContent:'center',userSelect:'none',cursor:'pointer',border:'1px solid '+localStorage.getItem('ambientColor'),color:localStorage.getItem('ambientColor'),fontWeight:'500',fontSize:'15px',marginLeft:'4%',marginTop:'3%',paddingBottom:'2px'}} onClick={addExtraDetailsRows}> + </div>
                                    </div>
                                </div>
                            }
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
    }

    const addEditDemandSourceRequest = async() => {
        setShowLoader(true);
        var tempExtraDetails = {}
        if(extraDetailsSelected!=="Do not show"){
            for(var index in extraDetailsSelected){
                if(extraDetailsSelected[index].key){
                    tempExtraDetails[extraDetailsSelected[index].key] = extraDetailsSelected[index].value
                }
            }
        }
        var tempMacros = {}
        for(index in trackingMacrosSelected){
            if(trackingMacrosSelected[index].key){
                tempMacros[trackingMacrosSelected[index].key] = trackingMacrosSelected[index].value
           }
        }
        var urlEnd = "add"
        if(isEdit){
            urlEnd = demandSourceId + "/change"
        }
        await axios({
            method: 'POST',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"demandsource/",urlEnd),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":"application/json",
            },
            data:{
                platform:platformSelected.value,
                api_key:apikeySelected.replaceAll(" ",""),
                base_url:baseurlSelected.replaceAll(" ",""),
                extra_details:tempExtraDetails,
                advertiser_id:advertiserSelected.value,
                macros_details:tempMacros,
                payout_percentage:parseFloat(payoutPercentageSelected),
                }
            })
        .then((resp)=>{
            if(resp.data.result['demand source']){
                var successString = isEdit ? "Demand Source edited" : "Demand Source Added."
                if(showIp){
                    successString += ' Also please update our IP: ' + JSON.stringify(showIp).replaceAll('"','').replaceAll("'","").replaceAll(","," , ") + ' on advertiser panel in order to get offers on this panel.'
                }
                Store.addNotification({
                    title: "Success!",
                    message: successString,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    },
                    onRemoval: (id, removedBy) => {
                        window.location.href = '/demandsource/management/'
                    }
                }); 
                }
            else{
                Store.addNotification({
                    title: "Warning!",
                    message: resp.data.result,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    },
                    onRemoval: (id, removedBy) => {
                        // window.location.href = '/demandsource/management/'
                    }
                }); 
                setShowLoader(false);
            }
            })
        .catch(error => {
            setShowLoader(false);
            if (error.response) {
                // Request made and server responded
                Store.addNotification({
                    title: "Error!",
                    message: typeof error.response.data.result === "string" ? error.response.data.result : "Some error occured",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                }); 
                console.log(error.response.status);
                console.log(error.response.headers);
            } 
            else {
                // Something happened in setting up the request that triggered an Error
                Store.addNotification({
                    title: "Error!",
                    message: typeof error.message === "string" ? error.message : "Some error occured",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                }); 
                console.log('Error', error.message);
                }
        })
      }
    
    const checkForEmptyExtraDetails = () => {
        for(var index in extraDetailsSelected){
            if((!extraDetailsSelected[index].key || !extraDetailsSelected[index].value) && !(!extraDetailsSelected[index].key && !extraDetailsSelected[index].value)){
                return true;
            }
        }
        return false;
    }
    return (
        <div className='mainContainerV2' id="outside" style={{background:'rgb(253,253,253)'}}>
        
            <div style={{position:'sticky',alignItems:'center',top:0,display:'flex',background:'rgb(253,253,253)',fontSize:'13px',zIndex:100,height:'50px'}}>   
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs 
                                TabIndicatorProps={{style: {background:localStorage.getItem('ambientColor'),backgroundColor:localStorage.getItem('ambientColor')}}}
                                value={tabValue} 
                                onChange={handleTabValue} 
                                aria-label="basic tabs example">
                            {<Tab label={<span className={tabValue===0? 'OfferTabActive' : 'OfferTabInactive'}>Platform</span>}/>}                        
                            {(platformSelected || window.location.href.includes('/demandsource/management/edit')) && <Tab label={<span className={tabValue===1? 'OfferTabActive' : 'OfferTabInactive'}>General</span>}/> }                       
                        </Tabs>
                    </Box>
                </Box>
            </div>
            {showLoader && 
            <div style={{position:'absolute',display:'flex',alignItems:'center',justifyContent:'center',width:'95%',minHeight:'calc(72vh)',background:'rgb(255,255,255,0.1)',zIndex:10,backdropFilter:'blur(2px)'}}>
                <CircularProgress sx={{color:localStorage.getItem('ambientColor')}}/>
            </div>
            }
            <div style={{minHeight:'calc(72vh)'}}>
                <TabPanel value={tabValue} index={0}>
                    {showPlatforms()}
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    {platformDetails()}
                </TabPanel>
            </div>
        
            <div style={{height:'45px',position:'sticky',bottom:0,background:'rgb(253,253,253)',borderTop:'1px solid rgb(240,240,240)',paddingBottom:'20px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                <Button 
                    sx={{...Constants.CONTAINEDBUTTON,height:"30px",width:'max-content',fontWeight:'600',fontSize:10,marginRight:'20px'}}
                    disabled={
                        showLoader
                        ||
                        (tabValue===0 && !platformSelected.value)
                        || 
                        (tabValue===1 && (
                            !advertiserSelected 
                                ||
                            (!payoutPercentageSelected.toString().match(number) || !parseFloat(payoutPercentageSelected) || payoutPercentageSelected<0 || payoutPercentageSelected>100)
                                ||
                            baseurlSelected===""
                                ||
                            (showApiKeyCheck && apikeySelected==="")
                                ||
                            checkForEmptyExtraDetails()
                        )) 
                    }
                    onClick={()=>{
                        if(tabValue===0){
                            setTabValue(1)
                        }
                        if(tabValue===1){
                            addEditDemandSourceRequest()
                        }
                    }}
                    >
                        {showLoader && <CircularProgress size={12} sx={{color:'white'}}/>}
                        {!showLoader && "Save"}
                </Button>
                <NavLink to={'/demandsource/management/'} style={{textDecoration:'none'}}>
                    <Button 
                        sx={{...Constants.OUTLINEDBUTTON,height:"30px",width:'max-content',fontWeight:'600',fontSize:10,marginRight:'20px'}}
                        >
                            Cancel
                    </Button>
                </NavLink>
            </div>
        </div>
    )
}

export default AddEditDemandSource