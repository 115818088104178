import React, { useEffect, useState } from 'react'
import '../../css/pages/billing.css';
import * as Constants from '../../data/constants';
import { DateRangePicker } from 'react-date-range';
import ReactSelect from 'react-select';
import { getLocalStorageValue, useForceUpdate } from '../../util/util';
import { Button, Tooltip } from '@mui/material';
import { HelpCenter } from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const AddAdvertiserBilling = () => {
    const refreshComponent = useForceUpdate()
    const [showDatePicker, setShowDatePicker] = useState(false)
    const [dateRange, setDateRange] = useState([new Date(new Date().getTime()-86400000*6),new Date()])
    const [dateRangeState, setDateRangeState] = useState([{
        startDate: new Date(dateRange[0]),
        endDate: new Date(dateRange[1]),
        key: 'selection'
    }])
    const dateRangePickedMethod = (item) => {
        setDateRangeState([item.selection]);
        if (item.selection.endDate !== item.selection.startDate){
            // setShowDatePicker(false)
            var value = [item.selection.startDate,item.selection.endDate]
            if (value[0] && value[1]){
                setDateRange(value)
                // setShowDatePicker(false)
                }
        }
    }
    
    var dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric'};

    useEffect(() => {
        document.addEventListener('click',(e)=>{
            setShowDatePicker(false);
        })
        
        return () => {
            document.removeEventListener('click',(e)=>{
                setShowDatePicker(false);
            })
        }
    }, [])
    
    const [generatedDetails, setGeneratedDetails] = useState([])
    
    return (
        <div className='advertiserPageV2' style={{position:'relative',paddingLeft:'30px',paddingTop:'25px',paddingRight:'30px'}}>
            <div style={{fontSize:'25px',fontWeight:'300'}}>
                Create Invoice
            </div>
            <div style={{display:'flex',alignItems:'center',marginTop:'30px',flexWrap:'wrap'}}>
                <div style={{minWidth:'200px',transform:'scale(0.779) translateX(-14%) translateY(-1.5%)'}}>               
                    <label style={{fontWeight:'500',fontSize:'13px'}}>Advertiser:</label>
                    <ReactSelect 
                            isClearable
                            options={[{label:'All',value:'all'},{label:'Paid',value:'paid'},{label:'Unpaid',value:'unpaid'}]}
                            // value={filterItem.value}
                            // isMulti={filterItem.isSingle ? false : true}
                            // onChange={(e)=>{filterItem.setMethod(e)}}
                            placeholder={'Select Advertiser'}
                            menuPortalTarget={document.body} 
                            styles={{...Constants.ReactSelectStyles}}
                        />
                </div>
                <div style={{position:'relative'}} onClick={(e)=>{e.stopPropagation();setShowDatePicker(true)}}>
                    <div style={{fontWeight:'500',fontSize:'10px',marginTop:'5px',marginBottom:'-2px',display:'flex'}}>
                        Period:
                    </div>
                    <div style={{position:'absolute',top:0,right:'20%'}}>
                        <Tooltip title={"Period's start date will be dependent on last invoice end date or statistics start date."}>
                            <div>
                                <HelpCenter sx={{fontSize:'12px'}}/>
                            </div>
                        </Tooltip>
                    </div>
                    <Constants.CssTextField size='small' value={dateRange[0].toLocaleDateString('sv-SE',dateOptions) + " -> " + dateRange[1].toLocaleDateString('sv-SE',dateOptions)} style={{transform:'scale(0.8) translateX(-13%)',width:'200px'}}/>
                    {showDatePicker 
                    && 
                    <div style={{position:'absolute',top:'40px',left:0}}>
                        <DateRangePicker
                            onChange={item => dateRangePickedMethod(item)}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            preventSnapRefocus={true}
                            rangeColors={[localStorage.getItem('ambientColor')]}
                            ranges={dateRangeState}
                            showDateDisplay={false}
                            scroll={{ enabled: window.innerWidth<800 ? true : false }}
                            direction={window.innerWidth<800 ? "vertical" : "horizontal"}
                            calendarFocus="backwards"
                            />
                    </div>
                    }
                </div>
                <div style={{minWidth:'200px',transform:'scale(0.779) translateX(-14%) translateY(-1.5%)'}}>               
                    <label style={{fontWeight:'500',fontSize:'13px'}}>Status:</label>
                    <ReactSelect 
                            isClearable
                            options={[{label:'Paid',value:'paid'},{label:'Unpaid',value:'unpaid'}]}
                            // value={filterItem.value}
                            // isMulti={filterItem.isSingle ? false : true}
                            // onChange={(e)=>{filterItem.setMethod(e)}}
                            placeholder={'Select Status'}
                            menuPortalTarget={document.body} 
                            styles={{...Constants.ReactSelectStyles}}
                        />
                </div>
                <div style={{minWidth:'200px',transform:'scale(0.779) translateX(-14%) translateY(-1.5%)'}}>               
                    <label style={{fontWeight:'500',fontSize:'13px'}}>Currency:</label>
                    <ReactSelect 
                            isClearable
                            options={getLocalStorageValue('adminPanelLoginData').panelSettings && getLocalStorageValue('adminPanelLoginData').panelSettings.currency ? Object.keys(getLocalStorageValue('adminPanelLoginData').panelSettings.currency).map((item,index)=>{return {label:item,value:item}}) : []}
                            // value={filterItem.value}
                            // isMulti={filterItem.isSingle ? false : true}
                            // onChange={(e)=>{filterItem.setMethod(e)}}
                            placeholder={'Select Currency'}
                            menuPortalTarget={document.body} 
                            styles={{...Constants.ReactSelectStyles}}
                        />
                </div>
                <Button sx={{...Constants.OUTLINEDBUTTON,height:'30px',fontSize:'12px',textTransform:'capitalize',marginTop:'10px'}}> Generate Details</Button>
            </div>
            <div style={{marginTop:'20px'}}>
                <label style={{fontWeight:'500',fontSize:'13px'}}>Details:</label>
                <table style={{tableLayout:'fixed',width:'100%',borderSpacing:'0px'}}>
                    <thead>
                        <tr>
                            <td width={'30%'} className='billingTableHeadData'>Offer *</td>
                            <td width={'15%'} className='billingTableHeadData'>Payout type</td>
                            <td width={'15%'} className='billingTableHeadData'>Conversion Count *</td>
                            <td width={'15%'} className='billingTableHeadData'>Amount *</td>
                            <td width={'20%'} className='billingTableHeadData'>Note</td>
                            <td width={'5%'}className='billingTableHeadData'></td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            generatedDetails.map((item,index)=>{
                                return <tr key={index}>
                                    <td className='billingTableValueData'>
                                        {item.offer}
                                    </td>
                                    <td className='billingTableValueData'>
                                        {item.payout_type}
                                    </td>
                                    <td className='billingTableValueData'>
                                        {item.conversions}
                                    </td>
                                    <td className='billingTableValueData'>
                                        {item.amount}
                                    </td>
                                    <td className='billingTableValueData'>
                                        {item.note}
                                    </td>
                                    <td className='billingTableValueData' style={{textAlign:'center'}}>
                                        <DeleteForeverIcon sx={{fontSize:'18px',color:'red',cursor:'pointer'}} onClick={()=>{var tempGeneratedDetails = generatedDetails; tempGeneratedDetails.splice(index,1); setGeneratedDetails(tempGeneratedDetails);refreshComponent(123);}}/>
                                    </td>
                                </tr>
                            })
                        }
                        <tr>
                            <td colSpan={6} style={{borderTop:'1px solid rgb(220,220,220)'}}>
                                <div className='billingTableAddRow' onClick={()=>{setGeneratedDetails([...generatedDetails,{offer:'',payout_type:'',conversions:'',amount:'',note:''}])}}>
                                    + Add Row
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{position:'absolute',bottom:0,background:'white',display:'flex',paddingTop:'10px',paddingBottom:'10px'}}>
                <Button sx={{...Constants.CONTAINEDBUTTON,height:'30px',fontSize:'12px',textTransform:'capitalize',marginTop:'10px'}}> 
                    Save Invoice
                </Button>
                <Button sx={{...Constants.OUTLINEDBUTTON,marginLeft:'20px',height:'30px',fontSize:'12px',textTransform:'capitalize',marginTop:'10px'}}> 
                    Cancel
                </Button>

            </div>
        </div>
    )
}

export default AddAdvertiserBilling