import React, { useState } from 'react'
import '../../css/pages/v2/profile.css';
import CustomizationPage, { increase_brightness } from '../../components/customization/customization';
import Logo from '../../components/logoUpdater/logo';

import * as Constants from '../../data/constants';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { Button, CircularProgress } from '@mui/material';
import { Store } from 'react-notifications-component';
// import CheckIcon from '@mui/icons-material/Check';
// import ClearIcon from '@mui/icons-material/Clear';
// import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { capitalize, getLocalStorageValue, setLocalStorageValue, sorting, useForceUpdate } from '../../util/util';
import axios from 'axios';
import UnderConstruction from '../../components/underConstruction';
import ReactSelect from 'react-select';

export const ProfilePage = () => {
    const refreshComponent = useForceUpdate()
    window.location.title = "Profile page"
    
    var IndexMapping = {            
        Account : 0,
        Notifications : 1,
        Security : 2,
        Appearance : 3,
        Panel : 4,
    }

    var indexTab = []

    if(window.location.href.includes('/profile')){
        var secondTab = window.location.href.split('/profile')[1]
        if(secondTab.includes('/')){
            secondTab = secondTab.split('/')
            for(var index in secondTab){
                if(secondTab[index]!==''){
                    indexTab.push(capitalize(secondTab[index]).replaceAll('%20'," "))
                }
            }
        }
    }

    const [selectedProfileTab, setselectedProfileTab] = useState(indexTab.length>0 ? IndexMapping[indexTab[0]] : 0)
    const [showPanelSubTab, setShowPanelSubTab] = useState(indexTab.length>1 ? indexTab[1] : false)
    const [showAddPaymentSystemPopup, setShowAddPaymentSystemPopup] = useState(false)

    const [showSettingsBar, setShowSettingsBar] = useState(false)
    const [userData, setUserData] = useState(getLocalStorageValue('adminPanelLoginData').userdata ?getLocalStorageValue('adminPanelLoginData').userdata : {})
    const [panelSettings, setPanelSettings] = useState(getLocalStorageValue('adminPanelLoginData').panelSettings ? getLocalStorageValue('adminPanelLoginData').panelSettings : {})
    const [buttonLoader, setButtonLoader] = useState(false)
    const [manualUseState, setManualUseState] = useState(false)
    
    const viewPanelCurrenciesOptions = async() => {
        setButtonLoader(true)
        var url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"currency/min/amount/view")
        await axios({
            method: 'GET',
            url,
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":"application/json",
            }
        })
        .then(async (resp)=>{
            var tempPanelSettings = panelSettings
            if(!Object.keys(resp.data.result).includes('USD')){
                resp.data.result['USD'] = 0
            }
            tempPanelSettings['currency'] = resp.data.result
            setPanelSettings(tempPanelSettings)
            var previousUserData = getLocalStorageValue('adminPanelLoginData')
            previousUserData['panelSettings'] = tempPanelSettings
            setLocalStorageValue('adminPanelLoginData',previousUserData)
        })
        .catch(function (error) {
            if (error.response) {
                if(error.response.data){
                    Store.addNotification({
                        title: "Error!",
                        message: typeof error.response.data.result === "string" ? error.response.data.result : "Some error occured",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true
                        }
                    });
                }
                // Request made and server responded
                console.log(error.response.status);
                console.log(error.response.headers);
            } else {
                // Something happened in setting up the request that triggered an Error
                Store.addNotification({
                    title: "Error!",
                    message: typeof error.message === "string" ? error.message : "Some error occured",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                });
            }
        });
        setButtonLoader(false);
    }
    const viewPanelPaymentOptions = async() => {
        setButtonLoader(true)
        var url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"payment/options/view")
        await axios({
            method: 'GET',
            url,
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":"application/json",
            }
        })
        .then(async (resp)=>{
            var tempPanelSettings = panelSettings
            tempPanelSettings['paymentOptions'] = resp.data.result
            setPanelSettings(tempPanelSettings)
        })
        .catch(function (error) {
            if (error.response) {
                if(error.response.data){
                    Store.addNotification({
                        title: "Error!",
                        message: typeof error.response.data.result === "string" ? error.response.data.result : "Some error occured",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true
                        }
                    });
                }
                // Request made and server responded
                console.log(error.response.status);
                console.log(error.response.headers);
            } else {
                // Something happened in setting up the request that triggered an Error
                Store.addNotification({
                    title: "Error!",
                    message: typeof error.message === "string" ? error.message : "Some error occured",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                });
            }
        });
        setButtonLoader(false);
    }

    if(!manualUseState){
        setManualUseState(true)
        viewPanelPaymentOptions();
        viewPanelCurrenciesOptions();
    }
    const addEditAdvertiserRequest = async(employeeId) => {
        setButtonLoader(true)
        if(employeeId){
            var url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"employee/",employeeId,"/",Constants.CHANGE)
        }
        await axios({
            method: 'POST',
            url,
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":"application/json",
            },
            data:{
                "email":userData.email,        
                "first_name" : userData.first_name,
                "last_name" : userData.last_name,
                "skype_id" : userData.skype_id ? userData.skype_id : null,
                "contact" : userData.contact ? userData.contact : null,
                "permissions": [],
                "groups" : [],
                "user_type" : userData.employee_type,
            }})
            .then(async (resp)=>{
                if(typeof resp.data.result === 'string'){
                    Store.addNotification({
                        title: "Warning!",
                        message: resp.data.result,
                        type: "warning",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true
                        }
                    });
                }
                else{
                    var previousUserData = getLocalStorageValue('adminPanelLoginData')
                    previousUserData['user'] = userData
                    setLocalStorageValue('adminPanelLoginData',previousUserData)
                    Store.addNotification({
                        title: "Success!",
                        message: 'User edited successfully.',
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true
                        }
                    });
                }
                console.log(resp)
            })
            .catch(function (error) {
                if (error.response) {
                    if(error.response.data){
                        Store.addNotification({
                            title: "Error!",
                            message: typeof error.response.data.result === "string" ? error.response.data.result : "Some error occured",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 3000,
                                onScreen: true
                            }
                        });
                    }
                    // Request made and server responded
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    Store.addNotification({
                        title: "Error!",
                        message: typeof error.message === "string" ? error.message : "Some error occured",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true
                        }
                    });
                }
        });
        setButtonLoader(false);
    };

    const updatePanelCurrencies = async(curencyremove) => {
        var requestAllow = true
        if(curencyremove){
            setRemoveCurrency([]);
            var data = {
                        currency:removeCurrency
                        }
        }
        else{
            for(var index in panelSettings.currency){
                if(!parseFloat(panelSettings.currency[index]) && panelSettings.currency[index]!=='0' && panelSettings.currency[index]!==0){
                    Store.addNotification({
                        title: "Warning!",
                        message: 'Invalid minimum value for '+index.toUpperCase()+' currency.',
                        type: "warning",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true
                        }
                    });
                    requestAllow = false
                    break;
                }
            }
            data = {
                        currency_dict:panelSettings.currency
                    }
        }
        if(requestAllow){
            setButtonLoader(true)
            var url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"currency/min/amount/edit")
            await axios({
                method: 'POST',
                url,
                headers:{
                    Authorization:getLocalStorageValue('adminPanelLoginData').token,
                    "Content-Type":"application/json",
                },
                data
            })
            .then(async (resp)=>{
                if(removeCurrency.length>0 && !curencyremove){
                    updatePanelCurrencies(removeCurrency)
                }
                else{
                    var previousUserData = getLocalStorageValue('adminPanelLoginData')
                    previousUserData['panelSettings'] = panelSettings
                    setLocalStorageValue('adminPanelLoginData',previousUserData)
                    Store.addNotification({
                        title: "Success!",
                        message: 'Currencies updated successfully.',
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true
                        }
                    });
                }
            })
            .catch(function (error) {
                if (error.response) {
                    if(error.response.data){
                        Store.addNotification({
                            title: "Error!",
                            message: typeof error.response.data.result === "string" ? error.response.data.result : "Some error occured",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 3000,
                                onScreen: true
                            }
                        });
                    }
                    // Request made and server responded
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    Store.addNotification({
                        title: "Error!",
                        message: typeof error.message === "string" ? error.message : "Some error occured",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true
                        }
                    });
                }
            });
            setButtonLoader(false);
        }
    }

    const updatePanelPaymentOptions = async(tempPanelSettings,isDelete,item) => {
        if(newPaymentOption && !isDelete){
            if(!newPaymentOption.title){
                var error = 'No Title to Payment System'
            }  
            else{
                for(var index in newPaymentOption.fields){
                    var fieldItem = newPaymentOption.fields[index]
                    if(!fieldItem.title){
                        error = 'No Title to Payment System field -> '+(parseInt(index)+1)
                    }
                }
            }
            if(error){
                Store.addNotification({
                    title: "Warning!",
                    message: error,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                });
                return false;
            }
        }
        setButtonLoader(true)
        var url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"payment/options/edit")
        if(isDelete){
            var data = {
                row_id : [item.id]
            }
        }
        else{
            
            data = {
                payment_data_list:tempPanelSettings.paymentOptions
            }
        }
        await axios({
            method: 'POST',
            url,
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":"application/json",
            },
            data
        })
        .then(async (resp)=>{
            var previousUserData = getLocalStorageValue('adminPanelLoginData')
            previousUserData['panelSettings'] = tempPanelSettings
            setLocalStorageValue('adminPanelLoginData',previousUserData)
            setNewPaymentOption({})
            setShowAddPaymentSystemPopup(false)
            await viewPanelPaymentOptions()
            Store.addNotification({
                title: "Success!",
                message: 'Payment options updated successfully.',
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
        })
        .catch(function (error) {
            if (error.response) {
                if(error.response.data){
                    Store.addNotification({
                        title: "Error!",
                        message: typeof error.response.data.result === "string" ? error.response.data.result : "Some error occured",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true
                        }
                    });
                }
                // Request made and server responded
                console.log(error.response.status);
                console.log(error.response.headers);
            } else {
                // Something happened in setting up the request that triggered an Error
                Store.addNotification({
                    title: "Error!",
                    message: typeof error.message === "string" ? error.message : "Some error occured",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                });
            }
        });
        setButtonLoader(false);
    }
    const AccountComponent = () => {
        return <div style={{margin:'10px'}}>
            {/* <div className='profilePageSubheading'>
                Profile
            </div>
            <div className='profilePageSubsubheading'>
                This information will be shared with your affiliates and advertisers.
            </div> */}

            <div className='profilePageFormItem' style={{marginTop:'20px'}}>
                <div className='profilePageFormLabel'>Photo</div>
                <div style={{width:'40%',padding:'5%',paddingTop:'3%'}}>
                    <Logo page='manager' addText={'photo'} id={'10'}/>
                </div>
            </div>
            <div style={{display:'flex',flexWrap:'wrap'}}>
                <div className='profilePageFormItem'>
                    <div className='profilePageFormLabel'>First Name</div>
                    <Constants.CssTextField value={userData.first_name ? userData.first_name : ''} onChange={(e)=>{setUserData({...userData,first_name:e.target.value})}} fullWidth size='small'/>
                </div>
                <div className='profilePageFormItem'>
                    <div className='profilePageFormLabel'>Last Name</div>
                    <Constants.CssTextField value={userData.last_name ? userData.last_name : ''} onChange={(e)=>{setUserData({...userData,last_name:e.target.value})}} fullWidth size='small'/>
                </div>
            </div>
            <div className='profilePageFormItem' style={{marginBottom:'20px'}}>
                <div className='profilePageFormLabel'>Email</div>
                <Constants.CssTextField disabled value={userData.email ? userData.email : ''} fullWidth size='small'/>
            </div>

            <div className='profilePageFormItem' style={{marginBottom:'20px'}}>
                <div className='profilePageFormLabel'>Contact Number</div>
                <Constants.CssTextField value={userData.contact ? userData.contact : ''} fullWidth onChange={(e)=>{setUserData({...userData,contact:e.target.value})}}  size='small'/>
            </div>
            <div className='profilePageFormItem'>
                <div className='profilePageFormLabel'>Skype</div>
                <Constants.CssTextField value={userData.skype_id ? userData.skype_id : ''} onChange={(e)=>{setUserData({...userData,skype_id:e.target.value})}} fullWidth size='small'/>
            </div>
            <div style={{display:'flex',justifyContent:'flex-start',marginTop:'40px'}}>
                <Button sx={{...Constants.CONTAINEDBUTTON,minWidth:'80px',textTransform:'capitalize',marginLeft:'10px',marginRight:'10px'}} onClick={()=>{addEditAdvertiserRequest(userData.id)}}>
                    {buttonLoader && <CircularProgress size={'16px'} sx={{color:'white'}}/>}
                    {!buttonLoader && "Save"}
                </Button>
                {/* <Button sx={{...Constants.OUTLINEDBUTTON,minWidth:'80px',textTransform:'capitalize',marginRight:'10px'}}>Cancel</Button> */}
            </div>
        </div>
    }

    const NotificationsComponent = () => {
        return <div>
            <UnderConstruction/>
        </div>
    }

    const SecurityComponent = () => {
        return <div>
            <UnderConstruction/>
        </div>
    }

    const AppearanceComponent = () => {
        return <div>
            <CustomizationPage/>
        </div>
    }
    
    const [newPaymentOption, setNewPaymentOption] = useState({})
    const [panelTimezone, setPanelTimezone] = useState(getLocalStorageValue('panelTimezone') ? getLocalStorageValue('panelTimezone') : false)
    const [removeCurrency, setRemoveCurrency] = useState([])
    const PanelComponent = () => {   
        const handleCurrencyAddRemove = (currencyItem,minValue) => {
            var tempPanelSettings = panelSettings
            if(!tempPanelSettings.currency){
                tempPanelSettings['currency'] = {}
            }
            if(!minValue && Object.keys(tempPanelSettings.currency).includes(currencyItem)){
                setRemoveCurrency([...removeCurrency,currencyItem])
                delete tempPanelSettings.currency[currencyItem]
            }
            else{
                if(minValue){
                    if(parseFloat(minValue) && minValue.toString().length<=8){
                        tempPanelSettings.currency[currencyItem] = parseFloat(minValue) ? parseFloat(minValue) : 0
                    }
                }
                else{
                    tempPanelSettings.currency[currencyItem] = 0
                }
            }
            setPanelSettings(tempPanelSettings)
            refreshComponent(213)
        }

        const handleAddPaymentOption = (index) => {
            if(showAddPaymentSystemPopup!==true && showAddPaymentSystemPopup){
                var isEdit = true
            }
            else{
                isEdit = false
            }
            var tempPanelSettings = panelSettings
            if(!tempPanelSettings.paymentOptions){
                tempPanelSettings['paymentOptions'] = []
            }
            if(isEdit){
                tempPanelSettings['paymentOptions'][parseInt(showAddPaymentSystemPopup)] = newPaymentOption
            }
            if(!isEdit){
                tempPanelSettings['paymentOptions'].push(newPaymentOption)
            }
            var previousUserData = getLocalStorageValue('adminPanelLoginData')
            previousUserData['panelSettings'] = tempPanelSettings
            setLocalStorageValue('adminPanelLoginData',previousUserData)
            updatePanelPaymentOptions(tempPanelSettings)
            refreshComponent(213)
        }
        var panelSettingOptions = [
            {label:'Currency',description:'Allowed currencies which can be used in panel.'},
            {label:'Payment options',description:'Different payment options you can customize for your panel.'},
        ]

        var panelOtherSettingOptions = [
            {label:'Timezone',description:'Single Timezone for your panel.'},
        ]
        return <div style={{marginLeft:'15px',margin:'20px'}}>
            {
                showPanelSubTab &&
                <div style={{display:'flex',alignItems:'center',fontSize:'12px',marginTop:'-10px',marginBottom:'10px'}}>
                    <div className='profilePageNavigationTabs' onClick={()=>{setShowPanelSubTab(false);addToUrl('Panel')}}>Panel &nbsp;</div>
                    <div> /&nbsp;</div>
                    <div className='profilePageNavigationTabs' style={{color:localStorage.getItem('ambientColor')}}>&nbsp;{showPanelSubTab}</div>
                </div>
            }
            {   
                !showPanelSubTab &&
                <div style={{position:'relative',border:'1px solid rgb(220,220,220)',padding:'10px',paddingLeft:'20px',paddingRight:'20px',borderRadius:'20px'}}>
                    <div style={{position:"absolute",top:'-10px',left:'15px',background:'rgb(250,250,250)',paddingLeft:'5px',paddingRight:'5px'}}>Billing</div>
                    {
                        panelSettingOptions.map((panelOptionItem,panelOptionIndex)=>{
                            return <div key={panelOptionIndex} style={{position:'relative',cursor:'pointer',paddingTop:'10px',paddingBottom:'10px',borderBottom:'1px solid rgb(230,230,230)'}} onClick={()=>{setShowPanelSubTab(panelOptionItem.label);addToUrl(panelOptionItem.label,'Panel/')}}>
                                        <div className='profilePageSubheading'>
                                            {panelOptionItem.label}
                                        </div>
                                        <div className='profilePageSubsubheading'>
                                            {panelOptionItem.description}
                                        </div>
                                    </div>
                        })
                    }
                </div>
            }
            {   
                !showPanelSubTab &&
                <div style={{position:'relative',border:'1px solid rgb(220,220,220)',padding:'10px',marginTop:'40px',paddingLeft:'20px',paddingRight:'20px',borderRadius:'20px'}}>
                    <div style={{position:"absolute",top:'-10px',left:'15px',background:'rgb(250,250,250)',paddingLeft:'5px',paddingRight:'5px'}}>Other Settings</div>
                    {
                        panelOtherSettingOptions.map((panelOptionItem,panelOptionIndex)=>{
                            return <div key={panelOptionIndex} style={{position:'relative',cursor:'pointer',paddingTop:'10px',paddingBottom:'10px',borderBottom:'1px solid rgb(230,230,230)'}} onClick={()=>{setShowPanelSubTab(panelOptionItem.label);addToUrl(panelOptionItem.label,'Panel/')}}>
                                        <div className='profilePageSubheading'>
                                            {panelOptionItem.label}
                                        </div>
                                        <div className='profilePageSubsubheading'>
                                            {panelOptionItem.description}
                                        </div>
                                    </div>
                        })
                    }
                </div>
            }
            {
                showPanelSubTab==='Currency'
                &&
                <div style={{height:'70vh',overflow:'auto',position:'relative'}}>
                    <table style={{tableLayout:'fixed',width:'100%',borderSpacing:'0 0 '}}>
                        <thead style={{fontSize:'14px',position:'sticky',top:'0px',background:'rgb(248,248,248)'}}>
                            <tr>
                                <td style={{padding:'5px',paddingTop:'10px'}}>
                                    Currency Code
                                </td>
                                <td style={{padding:'5px',paddingTop:'10px'}}>
                                    Currency Symbol
                                </td>
                                <td style={{padding:'5px',paddingTop:'10px'}}>
                                    Min Payment
                                </td>
                                <td style={{padding:'5px',paddingTop:'10px'}}>
                                    Available
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={4} style={{padding:'2px',borderBottom:'1px solid rgb(220,220,220)'}}></td>
                            </tr>
                        </thead>
                        <tbody>
                            {panelSettings && panelSettings.currency && Object.keys(panelSettings.currency).map((item,index)=>{
                                if(item==='NULL'){
                                    return null;
                                }
                                return <tr key={index} style={{fontSize:'13px'}}>
                                            <td style={{padding:'3px',paddingLeft:'6px'}}>
                                                {item}
                                            </td>
                                            <td style={{padding:'3px',paddingLeft:'6px'}}>
                                                {Constants.CURRENCY_LIST_SYMBOLS[item]}
                                            </td>
                                            <td style={{padding:'3px',paddingLeft:'6px'}}>
                                                <input value={panelSettings.currency[item] ? panelSettings.currency[item] : '0'} style={{outline:'0',border:'0',background:'rgb(248,248,248)'}} onChange={(e)=>{handleCurrencyAddRemove(item,e.target.value)}}/>
                                            </td>
                                            <td style={{padding:'3px',paddingLeft:'6px'}}>
                                                <div style={{display:'flex',alignItems:'center',fontSize:'12px'}}>
                                                    <input disabled={item.toLowerCase() === 'usd'} checked={panelSettings.currency && Object.keys(panelSettings.currency).includes(item)} type='checkbox' onChange={()=>{handleCurrencyAddRemove(item);}}/>
                                                </div>
                                            </td>
                                        </tr>
                            })}
                            {Object.keys(Constants.CURRENCY_LIST_SYMBOLS).sort().map((item,index)=>{
                                if(item==='NULL' || (panelSettings.currency && Object.keys(panelSettings.currency).includes(item))){
                                    return null;
                                }
                                return <tr key={index} style={{fontSize:'13px'}}>
                                            <td style={{padding:'3px',paddingLeft:'6px'}}>
                                                {item}
                                            </td>
                                            <td style={{padding:'3px',paddingLeft:'6px'}}>
                                                {Constants.CURRENCY_LIST_SYMBOLS[item]}
                                            </td>
                                            <td style={{padding:'3px',paddingLeft:'6px'}}>
                                                <input value={panelSettings.currency[item] ? panelSettings.currency[item] : '0'} style={{outline:'0',border:'0',background:'rgb(248,248,248)'}} onChange={(e)=>{handleCurrencyAddRemove(item,e.target.value)}}/>
                                            </td>
                                            <td style={{padding:'3px',paddingLeft:'6px'}}>
                                                <div style={{display:'flex',alignItems:'center',fontSize:'12px'}}>
                                                    <input checked={panelSettings.currency && Object.keys(panelSettings.currency).includes(item)} type='checkbox' onChange={()=>{handleCurrencyAddRemove(item);}}/>
                                                </div>
                                            </td>
                                        </tr>
                            })}
                        </tbody>
                    </table>
                    <div style={{display:'flex',alignItems:'center',position:'sticky',zIndex:0,bottom:0,background:'rgb(248,248,248)',paddingTop:'10px',paddingBottom:'10px',width:'100%'}}>
                        <Button sx={{...Constants.CONTAINEDBUTTON,textTransform:'capitalize',transform:'scale(0.8)'}} onClick={()=>{updatePanelCurrencies()}}> Save</Button>
                        {/* <Button sx={{...Constants.OUTLINEDBUTTON,textTransform:'capitalize',transform:'scale(0.8)'}}> Cancel</Button> */}
                    </div>
                </div>
            }
            {
                showPanelSubTab==='Payment options'
                &&
                <div style={{display:'relative'}}>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:'20px'}}>
                        <div>Payment Systems</div>
                        <div onClick={(e)=>{e.stopPropagation();setShowAddPaymentSystemPopup(true);}} style={{background:localStorage.getItem('ambientColor'),fontSize:'13px',color:'white',padding:'6px',borderRadius:'10%',cursor:'pointer'}}>
                            Add
                            <div className='addPaymentSystemPopup' onClick={(e)=>{e.stopPropagation();}} style={showAddPaymentSystemPopup ? {zIndex:1} : {transform:'scale(0)',top:'',left:''}}>
                                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',paddingTop:'10px',padding:'15px',borderBottom:'1px solid rgb(240,240,240)'}}>
                                    <div>New Payment System</div>
                                    <div onClick={(e)=>{e.stopPropagation();setShowAddPaymentSystemPopup(false);setNewPaymentOption({});}}>X</div>
                                </div>
                                <div style={{display:'flex',alignItems:'center',marginLeft:'10%',marginTop:'2%'}}>
                                    <div style={{fontSize:'13px',width:'10%',fontWeight:'500',marginRight:'5%'}}>Title*</div>
                                    <div style={{width:'180px'}}><Constants.CssTextField value={newPaymentOption.title ? newPaymentOption.title : ''} fullWidth onChange={(e)=>{setNewPaymentOption({...newPaymentOption,title:e.target.value})}} size='small'/></div>
                                </div>
                                <div style={{display:'flex',alignItems:'center',marginLeft:'10%',marginTop:'2%'}}>
                                    <div style={{fontSize:'13px',width:'10%',fontWeight:'500',marginRight:'5%'}}>Currency*</div>
                                    <div style={{width:'180px'}}>
                                        <ReactSelect
                                            options={panelSettings ? panelSettings.currency && Object.keys(panelSettings.currency).map((item,index)=>{return {label:item,value:item}}) : []}
                                            value={newPaymentOption ? {label:newPaymentOption.currency,value:newPaymentOption.currency} : false}
                                            onChange={(e)=>{setNewPaymentOption({...newPaymentOption,currency:e.value})}}
                                            menuPortalTarget={document.body} 
                                            placeholder={"Select currency"}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 1000000 }),
                                                        control: (base,state) => ({
                                                            ...base,
                                                            // maxHeight: 100,
                                                            minHeight: 30,
                                                            color:'rgb(200,200,200)',
                                                            overflowY: "auto",
                                                            fontSize:14,
                                                            border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                            boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                                            '&:hover': {
                                                                border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                                boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                                            }
                                                        }),
                                                        placeholder: (defaultStyles) => {
                                                            return {
                                                                ...defaultStyles,
                                                                color: 'rgb(150,150,150)',
                                                                marginTop:'-2px'
                                                            }
                                                        },
                                                        option: (styles, {isFocused, isSelected}) => ({
                                                            ...styles,
                                                            fontSize:'11px',
                                                            background: isFocused
                                                                ? localStorage.getItem('ambientColor')
                                                                : isSelected
                                                                    ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                    : undefined,
                                                            zIndex: 1
                                                        }),
                                                    }}
                                            />
                                    </div>
                                </div>
                                <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start',marginLeft:'10%',marginRight:'10%',marginTop:'2%'}}>
                                    <div style={{fontSize:'13px',width:'10%',fontWeight:'500',marginRight:'5%',marginTop:'10px'}}>Fields</div>
                                    <div style={{maxHeight:'30vh',overflow:'auto',position:'relative',marginTop:'-30px'}}>
                                        <table style={{tableLayout:'fixed',width:'83.5%',marginLeft:'16.5%',fontSize:'14px',marginTop:'10px'}}>
                                            <thead>
                                                <tr style={{background:'rgb(240,240,240)',position:'sticky',top:0,zIndex:1}}>
                                                    <td width={'70%'} style={{padding:'4px'}}>Title</td>
                                                    <td width={'20%'} style={{padding:'4px'}}>Required</td>
                                                    <td width={'10%'} style={{padding:'4px'}}></td>
                                                </tr>
                                            </thead>
                                            <tbody style={{fontSize:'12px'}}>
                                                {newPaymentOption.fields && newPaymentOption.fields.map((item,index)=>{
                                                    return <tr key={index}>
                                                                <td style={{padding:'4px'}}>
                                                                    <Constants.CssTextField value={item.title} sx={{transform:'scale(0.9)',transformOrigin:'left'}} fullWidth size='small' onChange={(e)=>{var tempFields = newPaymentOption.fields; tempFields[index]['title']= e.target.value; setNewPaymentOption({...newPaymentOption,fields:tempFields})}}/>
                                                                </td>
                                                                <td style={{padding:'4px'}}>
                                                                    <input type='checkbox' checked={item.required} onChange={(e)=>{var tempFields = newPaymentOption.fields; tempFields[index]['required']= e.target.checked; setNewPaymentOption({...newPaymentOption,fields:tempFields})}}/>
                                                                </td>
                                                                <td style={{padding:'4px'}} onClick={()=>{var tempFields = newPaymentOption.fields; tempFields.splice(index,1); setNewPaymentOption({...newPaymentOption,fields:tempFields})}}>
                                                                    <div style={{color:'red',border:'1px solid red',width:'10px',padding:'5px',textAlign:'center',fontWeight:'500'}}> - </div>
                                                                </td>
                                                            </tr>
                                                })}
                                                <tr style={{cursor:'pointer',position:'sticky',bottom:0,background:'white'}}>
                                                    <td colSpan={3} style={{padding:'10px',fontSize:'13px'}} onClick={()=>{if(newPaymentOption.fields){var tempFields = newPaymentOption.fields}else{tempFields = [];} tempFields.push({title:'',required:false}); setNewPaymentOption({...newPaymentOption,fields:tempFields})}}> + Add</td>
                                                </tr>   
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div style={{bottom:20,position:'absolute',background:'white',display:'flex',justifyContent:'center',width:'100%'}}>
                                    <Button disabled={!newPaymentOption.title || !newPaymentOption.currency || !newPaymentOption.fields || (!newPaymentOption.fields && newPaymentOption.fields.length===0)} sx={{...Constants.CONTAINEDBUTTON,transform:'scale(0.8)',minWidth:'80px'}} onClick={()=>{handleAddPaymentOption()}}>
                                        {buttonLoader && <CircularProgress size={'18px'} sx={{color:'white'}}/>}
                                        {!buttonLoader && 'Save'}
                                    </Button>
                                </div>
                            </div>  
                        </div>
                    </div>
                    {
                        (!panelSettings.paymentOptions || (panelSettings.paymentOptions && panelSettings.paymentOptions.length===0))
                        && 
                        <div style={{fontSize:'13px',fontWeight:500,color:'rgb(100,100,100)'}}>
                            No Payment Systems Added.
                        </div>
                    }
                    {panelSettings.paymentOptions && panelSettings.paymentOptions.length>0 &&
                        <table style={{tableLayout:'fixed',width:'100%',borderSpacing:'0px'}}>
                            <thead>
                                <tr style={{background:'rgb(248,248,248)',fontSize:'14px'}}>
                                    <td width={'30%'} style={{border:'1px solid rgb(200,200,200)',padding:'10px',fontWeight:'500'}}>
                                        Title
                                    </td>
                                    <td width={'15%'} style={{border:'1px solid rgb(200,200,200)',padding:'10px',fontWeight:'500'}}>
                                        Currency
                                    </td>
                                    <td width={'50%'} style={{border:'1px solid rgb(200,200,200)',padding:'10px',fontWeight:'500'}}>
                                        Fields
                                    </td>
                                    <td width={'5%'} style={{border:'1px solid rgb(200,200,200)',padding:'10px',fontWeight:'500'}}>
                                    </td>
                                </tr>
                            </thead>
                            <tbody style={{fontSize:'13px'}}>
                                {sorting(panelSettings.paymentOptions,'title', false ,true)}
                                {panelSettings.paymentOptions && panelSettings.paymentOptions.sort().map((item,index)=>{
                                    return <tr style={{background:'rgb(253,253,253)'}}>
                                        <td style={{padding:'6px',paddingLeft:'15px',border:'1px solid rgb(220,220,220)',wordBreak:'break-all'}}>
                                            {item.title}
                                        </td>
                                        <td style={{padding:'6px',paddingLeft:'15px',border:'1px solid rgb(220,220,220)',}}>
                                            {item.currency}
                                        </td>
                                        <td style={{padding:'6px',paddingLeft:'15px',border:'1px solid rgb(220,220,220)',wordBreak:'break-all'}}>
                                            {item.fields && item.fields.map((fieldItem,fieldIndex)=>{
                                                return <div key={fieldIndex}>
                                                    {fieldItem.title + (fieldItem.required ? '*' : '')}
                                                </div>
                                            })}
                                        </td>
                                        <td style={{border:'1px solid rgb(220,220,220)',padding:'6px',paddingLeft:'15px',}}>
                                            <div style={{display:'flex',justifyContent:'space-around'}}>
                                                <EditIcon sx={{fontSize:'16px',color:localStorage.getItem('ambientColor')}} onClick={(e)=>{e.stopPropagation();setNewPaymentOption(item);setShowAddPaymentSystemPopup(index.toString());}}/>
                                                <DeleteIcon  sx={{fontSize:'16px',color:'red'}} onClick={()=>{var tempPaymemtOptions = panelSettings.paymentOptions; tempPaymemtOptions.splice(index,1); setPanelSettings({...panelSettings,paymentOptions:tempPaymemtOptions}); updatePanelPaymentOptions({...panelSettings,paymentOptions:tempPaymemtOptions},true,item);refreshComponent(123);}}/>
                                            </div>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    }
                    {/* <div style={{display:'flex',alignItems:'center',position:'sticky',zIndex:0,bottom:0,background:'rgb(248,248,248)',paddingTop:'10px',paddingBottom:'10px',width:'100%'}}>
                        <Button sx={{...Constants.CONTAINEDBUTTON,textTransform:'capitalize',transform:'scale(0.8)'}} onClick={()=>{updatePanelPaymentOptions()}}> Save</Button>
                        <Button sx={{...Constants.OUTLINEDBUTTON,textTransform:'capitalize',transform:'scale(0.8)'}}> Cancel</Button>
                    </div> */}
                </div>
            }
            
            {
                showPanelSubTab==='Timezone'
                &&
                <div style={{height:'70vh',overflow:'auto',position:'relative'}}>
                    <div style={{padding:'10px'}}>
                        <div style={{fontSize:'14px',fontWeight:'500',color:'rgb(100,100,100)'}}>
                            Overall Timezone
                        </div>
                        <div style={{paddingTop:'2px',transform:'scale(0.9)',transformOrigin:'left'}}>
                            <ReactSelect
                                options={Constants.timezoneList}
                                value={panelTimezone}
                                onChange={(e)=>{setPanelTimezone(e)}}
                                menuPortalTarget={document.body} 
                                placeholder={"Select Timezone"}
                                styles={{...Constants.ReactSelectStyles}}
                            />
                        </div>
                    </div>
                    <div style={{display:'flex',alignItems:'center',position:'sticky',zIndex:0,bottom:0,background:'rgb(248,248,248)',paddingTop:'10px',paddingBottom:'10px',width:'100%'}}>
                        <Button sx={{...Constants.CONTAINEDBUTTON,textTransform:'capitalize',transform:'scale(0.8)'}} onClick={()=>{setLocalStorageValue('panelTimezone',panelTimezone)}}> Save</Button>
                        {/* <Button sx={{...Constants.OUTLINEDBUTTON,textTransform:'capitalize',transform:'scale(0.8)'}}> Cancel</Button> */}
                    </div>
                </div>
            }
        </div>
    }

    const options = [
        {label:'Account',component:AccountComponent(),icon:<SettingsOutlinedIcon style={{fontSize:'20px',color:'rgb(100,100,100)'}}/>,description:'Change your profile details'},
        {label:'Notifications',component:NotificationsComponent(),icon:<NotificationsNoneOutlinedIcon style={{fontSize:'20px',color:'rgb(100,100,100)'}}/>,description:'What all notifications you want to see?'},
        {label:'Security',component:SecurityComponent(),icon:<VpnKeyOutlinedIcon style={{fontSize:'20px',color:'rgb(100,100,100)'}}/>,description:'Change password and other security related things.'},
        {label:'Appearance',component:AppearanceComponent(),icon:<BrokenImageOutlinedIcon style={{fontSize:'20px',color:'rgb(100,100,100)'}}/>,description:'Customize your panel how you want it to look.'},
        {label:'Panel',component:PanelComponent(),icon:<AdminPanelSettingsOutlinedIcon style={{fontSize:'20px',color:'rgb(100,100,100)'}}/>,description:'Change panel default settings.'},
    ]

    const addToUrl = (label,previousLabel='') => {
        var url = '/profile/'+previousLabel
        url+= label
        window.history.pushState('Advertisers', 'Advertisers', url);    
    }

    return (
        <div className='profilePageContainer' onClick={()=>{setShowSettingsBar(false);setShowAddPaymentSystemPopup(false);setNewPaymentOption({});}}>
            <div className='profilePageThreeDotButton' onClick={(e)=>{e.stopPropagation();setShowSettingsBar(true);}}>
                <MoreHorizOutlinedIcon/>
            </div>
            <div className='profilePageSidebar' style={{marginLeft:(window.innerWidth<900 ? showSettingsBar ? '0px' : '-70%' : '0px')}}>
                <div style={{height:'22px',padding:'10px',paddingLeft:'15px',fontWeight:600,fontSize:'18px',borderBottom:'1px solid rgb(220,220,220)'}}>Settings</div>
                {options.map((item,index)=>{
                    return <div key={index} className={'profilePageSidebarOptions'} style={{backgroundColor:(selectedProfileTab === index ? increase_brightness(localStorage.getItem('ambientColor'),90) : 'white')}} onClick={()=>{setselectedProfileTab(index);setShowPanelSubTab(false);addToUrl(item.label)}}>
                            <div>{item.icon}</div>
                            <div style={{fontSize:'15px',paddingLeft:'10px',fontWeight:'500'}}>
                                {item.label}
                                <br/>
                                <label style={{fontSize:'12px',color:'rgb(150,150,150)',fontWeight:400}}>{item.description}</label>
                            </div>
                        </div>
                })}
            </div>
            <div className='profilePageContainer234'>
                <div style={{height:'22px',padding:'10px',fontWeight:600,fontSize:'26px'}}>{options[selectedProfileTab].label}</div>
                {options[selectedProfileTab].component}
            </div>
        </div>
    )
}

export default ProfilePage;
