import React, { useEffect, useState } from 'react'
import axios from 'axios';
import * as Constants from '../../data/constants';
import { Button } from '@mui/material';
import { DateRangePicker } from 'react-date-range';

import '../../css/pages/billing.css';
import ReactSelect from 'react-select';
import { NavLink } from 'react-router-dom';
import { getLocalStorageValue, getRequests } from '../../util/util';
// import { Store } from 'react-notifications-component';
import { getAdvertisers, getAffiliates } from '../../apis/dropdown/dropdowns';
import TableModal from '../../components/tableModal/tableModal';
import PaginationComponent from '../../components/pagination/pagination';
// import Tooltip from '@mui/material/Tooltip';
import GenerateAffiliateInvoice from './generateAffiliateInvoice';


const AdvertiserBilling = () => {
    if(window.location.pathname.includes('billing/advertiser')){
        var isAdvertiser = true;
    }
    document.title = 'Payments'
    var dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric'};
    const [showDatePicker, setShowDatePicker] = useState(false)
    const [loader, setLoader] = useState(false)
    const [limit, setLimit] = useState(50)
    const [page, setPage] = useState(1)
    const [billingData, setBillingData] = useState([])
    const [billingDataCount, setBillingDataCount] = useState(0)
    const [invoiceStatusSelected, setInvoiceStatusSelected] = useState(false)
    const [affiliateAdvertiserSelected, setAffiliateAdvertiserSelected] = useState(false)
    const [paymentSystemSelected, setPaymentSystemSelected] = useState(false)
    const [dateRange, setDateRange] = useState([new Date(new Date().getTime()-86400000*30),new Date()])
    const [dateRangeState, setDateRangeState] = useState([{
        startDate: new Date(dateRange[0]),
        endDate: new Date(dateRange[1]),
        key: 'selection'
    }])
    const dateRangePickedMethod = (item) => {
        setDateRangeState([item.selection]);
        if (item.selection.endDate !== item.selection.startDate){
            // setShowDatePicker(false)
            var value = [item.selection.startDate,item.selection.endDate]
            if (value[0] && value[1]){
                setDateRange(value)
                // setShowDatePicker(false)
                }
        }
    }
    
    const getInvoiceList = async(manualLimit,manualPage) => {
        setLoader(true)
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"affiliate/invoice/list"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                startDate:dateRange[0].toLocaleDateString('sv-SE',dateOptions),
                endDate:dateRange[1].toLocaleDateString('sv-SE',dateOptions),
                affiliate_id:affiliateAdvertiserSelected ? affiliateAdvertiserSelected.value : undefined,
                status:invoiceStatusSelected  ? invoiceStatusSelected.value : undefined,
                payment_system:paymentSystemSelected ? paymentSystemSelected.value : undefined,
                limit:manualLimit ? manualLimit : limit,
                page:manualPage ? manualPage : page,
            }
        })
        .then(resp => {
            setBillingData(resp.data.result)
            setBillingDataCount(resp.data.count)
        })
        .catch(error => {
            console.error(error);
            if(error.response.data){
                setBillingData("errorFromAxios"+error.response.data.result)
            }
        })
        setLoader(false)
    };
    
    const [manualUseState, setManualUseState] = useState(false)
    const [affiliateAdvertiserOptions, setAffiliateAdvertiserOptions] = useState([])
    if(!manualUseState){
        setManualUseState(true);
        getInvoiceList();
        if(isAdvertiser){
            getRequests(getAdvertisers(),setAffiliateAdvertiserOptions)
        }
        else{
            getRequests(getAffiliates(),setAffiliateAdvertiserOptions)
        }

    }

    useEffect(() => {
        document.addEventListener('click',(e)=>{
            setShowDatePicker(false);
            setShowGenerateInvoiceAffiliate(false);
        })
        
        return () => {
            document.removeEventListener('click',(e)=>{
                setShowDatePicker(false);
                setShowGenerateInvoiceAffiliate(false);
            })
        }
    }, [])

    var statusOptions = [
        {label:'Waiting',value:'waiting'},
        // {label:'Waiting',value:'waiting'},
        {label:'Partly Paid',value:'partly paid'},
        {label:'Completed',value:'completed'},
        {label:'Declined',value:'declined'},
    ]

    const viewAndEditComponent = (item) =>{
        if(parseInt(item.generated)===1){
            return <NavLink style={{color:'black',textDecoration:'none'}} to={'/billing/affiliate/view/'+item.id}>
                        <div className='threeDotMenuOption' style={{cursor:'pointer',fontSize:'13px'}}>
                            View And Edit 
                        </div>
                    </NavLink>
        }
        return <div className='threeDotMenuOption' style={{cursor:'not-allowed',fontSize:'13px'}}>
                    Wait for generation.
                </div>
    }
    const paymentStatusComponent = (showValue) => {
        return <div style={{textTransform:'capitalize'}}>
            {showValue}
        </div>
    }
    const idComponent = (showValue) => {
        return <NavLink style={{color:localStorage.getItem('ambientColor'),textDecoration:'none'}} to={'/billing/affiliate/view/'+showValue}>
                    {showValue}
                </NavLink>
    }
    const affiliateComponent = (showValue,tableHeaderIndex,item) => {
        return <NavLink style={{color:localStorage.getItem('ambientColor'),textDecoration:'none'}} to={'/billing/affiliate/view/'+item.id}>
                    {"("+item.affiliate_id+") "+ item.affiliate_name}
                </NavLink>
    }
    const generationStatusComponent = (showValue) => {
        var generationStatusComponent = {
            0: 'Started',
            1: 'Finished',
            2: 'In Progress',
            3: 'Aborted',
        }
        return <div>
            {generationStatusComponent[showValue]}
        </div>
    }
    var tempTableHeaders = [
        {label:'ID',value:'id',show:true,width:'4%',minWidth:60,customComponent:idComponent},
        {label:'Affiliate',value:'affiliate_id',show:true,width:'9%',minWidth:100,customComponent:affiliateComponent},
        {label:'Start Date',value:'start_date',show:true,width:'7%',minWidth:80,alignHead:'left',alignValue:'left'},
        {label:'End Date',value:'end_date',show:true,width:'7%',minWidth:50,alignHead:'left',alignValue:'left'},
        {label:'Generated Status',value:'generated',customComponent:generationStatusComponent,show:false,alignHead:'left',alignValue:'left',width:'12%',minWidth:50},
        {label:'Payment Status',value:'status',customComponent:paymentStatusComponent,show:false,alignHead:'left',alignValue:'left',width:'10%',minWidth:50},
        {label:'Payout',value:'payout',show:true,width:'7%',minWidth:50},
        {label:'Currency',value:'currency',show:true,width:'10%',minWidth:50},
        {label:'Payment System',value:'payment_system',show:true,width:'12%',minWidth:50},
        {label:'',value:'threeDotMenu',show:true,width:'5%',minWidth:50,threeDotMenu:true,menuOptions:[{label:'View And Edit',value:'viewandedit',component:viewAndEditComponent}],height:45},
    ]

    if(getLocalStorageValue(window.location.pathname)){
        try{
            var hideShowColumns = getLocalStorageValue(window.location.pathname)
            for(var index in tempTableHeaders){
                tempTableHeaders[index].hide = hideShowColumns[index].hide
            }
        }
        catch{

        }
    }
    const [tableHeaders, setTableHeaders] = useState(tempTableHeaders)
    const [showGenerateInvoiceAffiliate, setShowGenerateInvoiceAffiliate] = useState(false)
    
    // var currenciesList = () => {
    //     if(getLocalStorageValue('adminPanelLoginData').panelSettings && getLocalStorageValue('adminPanelLoginData').panelSettings.currency){
    //         return Object.keys(getLocalStorageValue('adminPanelLoginData').panelSettings.currency).map((item,index)=>{return {label:item,value:item}})
    //     }
    //     return []
    // }
    return (
        <div className='advertiserPageV2' style={{minHeight:'40vh'}}>
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',fontSize:'13px',height:'40px'}}>
                <div style={{paddingLeft:'10px'}}>Found {billingDataCount} Invoices</div>
                {isAdvertiser &&
                    <div>
                        <NavLink to={'/billing/advertiser/new'} style={{textDecoration:'none'}}>
                            <Button sx={{...Constants.OUTLINEDBUTTON, textTransform:'capitalize',transform:'scale(0.8)',textDecoration:'none'}}>
                                New Invoice
                            </Button>
                        </NavLink>
                    </div>
                }
                {!isAdvertiser &&
                    <div>
                        <Button sx={{...Constants.OUTLINEDBUTTON, textTransform:'capitalize',transform:'scale(0.8)',textDecoration:'none'}} onClick={(e)=>{e.stopPropagation();setShowGenerateInvoiceAffiliate(true);}}>
                            New Invoice
                        </Button>
                    </div>
                }
            </div>
            {
                showGenerateInvoiceAffiliate
                &&
                <GenerateAffiliateInvoice getInvoiceList={getInvoiceList} affiliateAdvertiserOptions={affiliateAdvertiserOptions} setShowGenerateInvoiceAffiliate={setShowGenerateInvoiceAffiliate}/>
            }
            <div style={{display:'flex',alignItems:'center',margin:'20px',marginTop:'10px',flexWrap:'wrap'}}>
                <div style={{position:'relative',marginBottom:'20px'}} onClick={(e)=>{e.stopPropagation();setShowDatePicker(true)}}>
                    <Constants.CssTextField size='small' value={dateRange[0].toLocaleDateString('sv-SE',dateOptions) + " -> " + dateRange[1].toLocaleDateString('sv-SE',dateOptions)} style={{transform:'scale(0.8) translateX(-13%)',width:'200px'}}/>
                    {showDatePicker
                    && 
                    <div style={{position:'absolute',top:'40px',left:0,zIndex:2000}}>
                        <DateRangePicker
                            onChange={item => dateRangePickedMethod(item)}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            preventSnapRefocus={true}
                            rangeColors={[localStorage.getItem('ambientColor')]}
                            ranges={dateRangeState}
                            showDateDisplay={false}
                            scroll={{ enabled: window.innerWidth<800 ? true : false }}
                            direction={window.innerWidth<800 ? "vertical" : "horizontal"}
                            calendarFocus="backwards"
                            />
                    </div>
                    }
                </div>
                <div style={{minWidth:'200px',transform:'scale(0.779) translateX(-14%) translateY(-1.5%)',marginBottom:'20px'}}>               
                   <ReactSelect 
                        isClearable
                        options={statusOptions}
                        value={invoiceStatusSelected}
                        // isMulti={filterItem.isSingle ? false : true}
                        onChange={(e)=>{setInvoiceStatusSelected(e)}}
                        placeholder={'Select Payment Status'}
                        menuPortalTarget={document.body} 
                        styles={{...Constants.ReactSelectStyles}}
                    />
                </div>
                <div style={{minWidth:'200px',transform:'scale(0.779) translateX(-14%) translateY(-1.5%)',marginBottom:'20px'}}>               
                   <ReactSelect 
                        isClearable
                        options={affiliateAdvertiserOptions}
                        value={affiliateAdvertiserSelected}
                        // isMulti={filterItem.isSingle ? false : true}
                        onChange={(e)=>{setAffiliateAdvertiserSelected(e)}}
                        placeholder={ isAdvertiser ? 'Select Advertiser' : 'Select Affiliate'}
                        menuPortalTarget={document.body} 
                        styles={{...Constants.ReactSelectStyles}}
                    />
                </div>
                <div style={{minWidth:'200px',transform:'scale(0.779) translateX(-14%) translateY(-1.5%)',marginBottom:'20px'}}>               
                   <ReactSelect 
                        isClearable
                        options={getLocalStorageValue('adminPanelLoginData').panelSettings && getLocalStorageValue('adminPanelLoginData').panelSettings.paymentOptions ? getLocalStorageValue('adminPanelLoginData').panelSettings.paymentOptions.map((item,index)=>{return {label:item.title + ' ('+item.currency+')',value:item.title}}) : []}
                        value={paymentSystemSelected}
                        // isMulti={filterItem.isSingle ? false : true}
                        onChange={(e)=>{setPaymentSystemSelected(e)}}
                        placeholder={ 'Select Payment System'}
                        menuPortalTarget={document.body} 
                        styles={{...Constants.ReactSelectStyles}}
                    />
                </div>
                {/* {
                    !isAdvertiser
                    &&
                    (!affiliateAdvertiserSelected 
                    ||
                    !paymentSystemSelected)
                    &&
                        <Tooltip title={'Please select affiliate, payment system and date range for generating invoice.'}>
                            <div style={{marginRight:'20px',marginBottom:'20px'}}>
                                <Button disabled={!affiliateAdvertiserSelected || !paymentSystemSelected} sx={{...Constants.CONTAINEDBUTTON,textTransform:'capitalize',fontSize:'12px',minWidth:'90px',minHeight:'33px'}} onClick={generateInvoice}>
                                    {generateInvoiceLoader && <CircularProgress size={'14px'} sx={{color:'white'}}/>}
                                    {!generateInvoiceLoader && "Generate"}
                                </Button>
                            </div>
                        </Tooltip>
                }
                {
                    !isAdvertiser
                    &&
                    affiliateAdvertiserSelected 
                    &&
                    paymentSystemSelected
                    &&
                    <div style={{marginRight:'20px',marginBottom:'20px'}}>
                        <Button disabled={!affiliateAdvertiserSelected || !paymentSystemSelected} sx={{...Constants.CONTAINEDBUTTON,textTransform:'capitalize',fontSize:'12px',minWidth:'90px',minHeight:'33px'}} onClick={generateInvoice}>
                        {generateInvoiceLoader && <CircularProgress size={'14px'} sx={{color:'white'}}/>}
                        {!generateInvoiceLoader && "Generate"}
                        </Button>
                    </div>
                } */}
                <Button sx={{...Constants.CONTAINEDBUTTON,textTransform:'capitalize',fontSize:'12px',marginBottom:'20px'}} onClick={()=>{getInvoiceList(limit,1)}}>View</Button>
            </div>

            <TableModal 
                stickyColumn={[0,1,2]} 
                rightStickyColumnList={[tableHeaders.length-1]} 
                showLoader={loader} 
                tableHeaders={tableHeaders} 
                setTableHeaders={setTableHeaders} 
                tableValues={billingData} 
                setTableValues={setBillingData} 
                hideTooltip={true} 
                showCustomization={true}
                // tableSelectAll={selectAll} 
                // setTableSelectAll={setSelectAll} 
                // setSelectedElements={setSelectedElements} 
                // selectedElements={selectedElements}
                // selectedElementsComponent = {selectedElementsComponent}
            />
            <PaginationComponent page={page} setPage={setPage} limit={limit} setLimit={setLimit} totalResults={billingDataCount} apiRequest={getInvoiceList} onClick={()=>{}}/>

        </div>
    )
}

export default AdvertiserBilling