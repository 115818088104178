import React, { useState } from 'react';
import ReactSelect from 'react-select';
import { NavLink } from "react-router-dom";
import axios from 'axios';
import { Button, CircularProgress } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

import { increase_brightness } from '../../components/customization/customization';
import TableModal from '../../components/tableModal/tableModal';
import '../../css/pages/v2/advertiser.css';   //// PLEASE USE THIS CSS ONLY FOR AFFILIATE PAGE ALSO
import * as Constants from '../../data/constants';
import { getLocalStorageValue } from '../../util/util';
import PaginationComponent from '../../components/pagination/pagination';

const UsersPage = () => {
    /// note -----  all advertiser variables are equivalent to affiliate variables, if you have no work please help me replace them with affiliate.
    document.title = 'Employees'
    var tempLimit = 50
    var tempPage = 1
    var tempAdvId = ''

    if(window.location.href.includes("?")){
        var newUrl = new URL(window.location.href)
        if(newUrl.searchParams.get('limit')){
            tempLimit = parseInt(newUrl.searchParams.get('limit'))
        }
        if(newUrl.searchParams.get('page')){
            tempPage = parseInt(newUrl.searchParams.get('page'))
        }
        if(newUrl.searchParams.get('id')){
            tempAdvId = newUrl.searchParams.get('id')
        }
    }

    const [manualUseEffect, setManualUseEffect] = useState(false)
    const [filterSearch, setFilterSearch] = useState({id:tempAdvId})
    const [advertiserData, setAdvertiserData] = useState([])
    const [advertisersTotalCount, setAdvertisersTotalCount] = useState(0)
    const [loader, setLoader] = useState(false)

    const [page, setPage] = useState(tempPage)
    const [limit, setLimit] = useState(tempLimit)

    var changeUrlParams = (obj) => {
        var str = [];
        for(var p in obj){
            if(obj[p]){
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        }
        var paramString = str.join("&");
        var url = window.location.pathname
        url+= '?'+paramString
        window.history.pushState('Smartlinks', 'Smartlinks', url);
    }
    const showAdvertiserRequest = async(manualLimit,manualPage) => {
        /// FOR URL 
        var urlParams = {
            limit:manualLimit ? manualLimit : limit,
            page:manualPage ? manualPage : page,
        }
        changeUrlParams(urlParams)
        
        var params = {
            limit:manualLimit ? manualLimit : limit,
            page:manualPage ? manualPage : page,
            //Search
        }
        setLoader(true)
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'employees'),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params
        }
        )
        .then((resp) => {
            setAdvertiserData(resp.data.result)
            setAdvertisersTotalCount(resp.data.count)
            setLoader(false)
        })
        .catch(function (error) {
            setLoader(false)
            if(error.response.data){
                setAdvertiserData("errorFromAxios"+error.response.data.result)
            }
            setAdvertisersTotalCount('0');
        });
        setLoader(false)
    };

    if(!manualUseEffect){
        setManualUseEffect(true)
        showAdvertiserRequest()
    }
    var filterOptions = [
        // {id:'id',label:'Search With Id',type:'textfield',value:filterSearch['id'],setValue:setFilterSearch,minWidth:220},
        // {id:'name',label:'Name',type:'textfield',value:filterSearch['name'],setValue:setFilterSearch,minWidth:220,},
    ]

    const companyName = (value,tableHeadIndex,item) => {
        return <NavLink style={{textDecoration:'none',color:localStorage.getItem('ambientColor')}} to={'/account/employees/edit/'+item.id}>
            {item.first_name + " " + item.last_name}
        </NavLink>
    }

    const employeeTypeComponent = (value) => {
        var employeeTypeDict = {
            'account_manager' : 'Account Manager',
            'affiliate_manager' : 'Affiliate Manager',
            'general_manager' : 'General Manager'
        }
        return employeeTypeDict[value]
    }
    const dateComponent = (value) => {
        if(value){
            var dateoptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
            return new Date(value).toLocaleDateString("en-US", dateoptions)
        }
        else{
            return '-'
        }

    }
    const datetimeComponent = (value) => {
        if(value){
            var timeoptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric',hour:'numeric',minute:'numeric' };
            return new Date(value).toLocaleDateString("en-US", timeoptions)
        }
        else{
            return '-'
        }
    }

    const editComponent = (item) =>{
        return <NavLink className='threeDotMenuOption'  to={"/account/employees/edit/"+item.id} >
                <EditIcon sx={{color:localStorage.getItem('ambientColor'),fontSize:'16px',marginRight:'5px'}}/>
                Edit
            </NavLink>
    }
    const viewStatsComponent = (item) =>{
        return <NavLink className='threeDotMenuOption' to={"/statistics/daily?advertiserManager="+item.first_name + " " +item.last_name+" -"+item.id}>
                <QueryStatsIcon sx={{color:localStorage.getItem('ambientColor'),fontSize:'16px',marginRight:'5px'}}/>
                View Stats
            </NavLink>
    }

    var tempTableHeaders = [
        // {id:'checkbox',label:'',value:'checkbox',show:true,width:'2%',minWidth:30,whatToSelect:'id'},
        {label:'ID',value:'id',show:true,width:'3%',minWidth:60,},
        {label:'Name',value:'first_name',show:true,width:'10%',minWidth:80,alignHead:'left',alignValue:'left',color:localStorage.getItem('ambientColor'),customComponent:companyName},
        {label:'User Type',value:'employee_type',alignHead:'left',alignValue:'left',show:true,width:'10%',minWidth:50,customComponent:employeeTypeComponent},
        {label:'Email',value:'email',show:true,width:'13%',minWidth:80,alignHead:'left',alignValue:'left'},
        {label:'Contact Number',value:'contact',show:true,width:'8%',minWidth:80,alignHead:'left',alignValue:'left'},
        {label:'Skype ID',value:'skype_id',show:true,width:'8%',minWidth:50,alignHead:'left',alignValue:'left'},
        {label:'Last Login',value:'last_login',show:true,width:'13%',minWidth:50,alignHead:'left',alignValue:'left',customComponent:datetimeComponent},
        {label:'Joining Date',value:'date_joined',show:true,width:'9%',minWidth:50,alignHead:'left',alignValue:'left',customComponent:dateComponent},
        {id:'threeDotMenu',label:'...',value:'threeDotMenu',show:true,width:'5%',minWidth:50,threeDotMenu:true,hideSorting:true,menuOptions:[{label:'Edit',value:'edit',component:editComponent},{label:'View Statistics',value:'viewStatistics',component:viewStatsComponent}],height:60},
    ]

    if(getLocalStorageValue(window.location.pathname)){
        try{
            var hideShowColumns = getLocalStorageValue(window.location.pathname)
            for(var index in tempTableHeaders){
                tempTableHeaders[index].hide = hideShowColumns[index].hide
            }
        }
        catch{

        }
    }
    const [tableHeaders, setTableHeaders] = useState(tempTableHeaders)
    
    // const gridComponent = () => {
    //     return advertiserData.map((item,index)=>{
    //         return <div className='gridComponentAdvertiser'>

    //         </div>
    //     })
    // }

    return (
        <div className='advertiserPageV2' style={{paddingBottom:'30px'}}>
            <div className='containerFirstRow'>
                <div> Found {advertisersTotalCount ? advertisersTotalCount : <CircularProgress sx={{color:localStorage.getItem('ambientColor'),paddingLeft:'5px',paddingRight:'5px'}} size={13}/>} Employees</div>
                <div className='containerFirstRowColTwo'>
                    <div style={{marginRight:'15px',fontSize:'12px',cursor:'pointer'}} onClick={()=>{localStorage.setItem('oldEmployeePage',true);window.location.reload();}}>
                        Switch to Old Design
                    </div>
                    <NavLink to={'/account/employees/add'} style={{textDecoration:'none'}}>
                        <Button sx={{...Constants.OUTLINEDBUTTON,height:'30px',fontSize:'15px',textTransform:'none',fontWeight:500}}> Add</Button>
                    </NavLink>
                </div>
            </div>
            <div className='containerSecondRow'>
                {filterOptions.map((filterItem,filterIndex)=>{
                    if(filterItem.type==='textfield'){
                        return <div key={filterIndex} style={{width:(filterItem.minWidth ? filterItem.minWidth : 'max-content'),marginRight:'15px',marginTop:20}}>
                                <Constants.CssTextField 
                                    size='small' 
                                    disabled={filterItem.disable} 
                                    fullWidth 
                                    value={filterSearch[filterItem.id]}
                                    placeholder={filterItem.label}
                                    onChange={(e)=>{setFilterSearch({...filterSearch,[filterItem.id]:e.target.value})}} 
                                    onKeyDown={(e)=>{if(e.key==='Enter' && !loader){showAdvertiserRequest(limit,1)}}}
                                    />
                            </div>
                    }
                    else if(filterItem.type==='select'){
                        return <div key={filterIndex} style={{width:(filterItem.minWidth ? filterItem.minWidth : 'max-content'),marginRight:'15px',marginTop:20}}>
                            <ReactSelect
                                isDisabled={filterItem.disable}
                                key={filterIndex} 
                                options={filterItem.options}
                                value={filterSearch[filterItem.id]}
                                onChange={(e)=>{setFilterSearch({...filterSearch,[filterItem.id]:e})}}
                                menuPortalTarget={document.body} 
                                placeholder={"Select "+filterItem.label}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 1000000 }),
                                            control: (base,state) => ({
                                                ...base,
                                                // maxHeight: 100,
                                                minHeight: 30,
                                                color:'rgb(200,200,200)',
                                                overflowY: "auto",
                                                fontSize:14,
                                                border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                                '&:hover': {
                                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                    boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                                }
                                            }),
                                            placeholder: (defaultStyles) => {
                                                return {
                                                    ...defaultStyles,
                                                    color: 'rgb(150,150,150)',
                                                    marginTop:'-2px'
                                                }
                                            },
                                            option: (styles, {isFocused, isSelected}) => ({
                                                ...styles,
                                                fontSize:'11px',
                                                background: isFocused
                                                    ? localStorage.getItem('ambientColor')
                                                    : isSelected
                                                        ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                        : undefined,
                                                zIndex: 1
                                            }),
                                        }}
                            />
                        </div>
                    }
                    return null;
                })}
                {/* <Button disabled={loader ? true : false} sx={{...Constants.CONTAINEDBUTTON,minHeight:'40px',fontSize:'15px',minWidth:'80px',paddingLeft:'15px',paddingRight:'15px',marginTop:'20px',textTransform:'none',fontWeight:500,marginLeft:'15px'}} onClick={()=>{showAdvertiserRequest(limit,1)}}>
                    {loader && 
                    <CircularProgress sx={{color:'white'}} size={20}/>
                    }
                    {!loader && "Search"}
                </Button>
                <Button sx={{...Constants.OUTLINEDBUTTON,minHeight:'40px',fontSize:'15px',paddingLeft:'15px',paddingRight:'15px',marginTop:'20px',textTransform:'none',fontWeight:500,marginLeft:'15px'}} onClick={()=>{setFilterSearch({id:'',adv_name:'',adv_email:'',manager:'',tags:''})}}>Reset</Button> */}
           </div>

            <TableModal 
                stickyColumn={[0,1,2]} 
                rightStickyColumnList={[tableHeaders.length-1]} 
                showLoader={loader} tableHeaders={tableHeaders} 
                setTableHeaders={setTableHeaders} 
                tableValues={advertiserData} 
                setTableValues={setAdvertiserData} 
                hideTooltip={true} 
                showCustomization={true}
            />
            <PaginationComponent page={page} setPage={setPage} limit={limit} setLimit={setLimit} totalResults={advertisersTotalCount} apiRequest={showAdvertiserRequest}/>
            
        </div>
    )
}

export default UsersPage;