import React from 'react';
import {NavLink} from 'react-router-dom';
import '../css/sidebar.css'
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    if(false){
        console.log(value)
    }
    return () => setValue(value => value + 1); // update the state to force render
  }

export const SubMenu = (props) => {
    const updateComponent = useForceUpdate()
    var item = props.item
    var sideBarClose = !props.props.sidebarOpen
    var mouseHoverSidebar = props.mouseHoverSidebar
    const [subnav,setSubNav] = useState(false); //variable for showing sub nav menu
    var [lineheight,setLineHeight] = useState(0);

    const showSubNav = (e) => {  //switch for showing sub nav menu
        setSubNav(!subnav);
        if (subnav===false ){
            setLineHeight(item.line);
        }
        if (subnav===true){
            setLineHeight(0);
        }
        updateComponent(e)
    }
    return (
        <>      
            <div className={!sideBarClose || mouseHoverSidebar ? "openTabList" : "openTabListClosed"} onClick={item.subNav && showSubNav} >
                {!sideBarClose || mouseHoverSidebar ? item.subNav && subnav
                ? <KeyboardArrowDownIcon style={{transform:"rotate(180deg)",transition:"all 0.3s ease-in-out"}}/>
                : item.subNav
                ? <KeyboardArrowDownIcon style={{transform:"rotate(0deg)",transition: "all 0.3s ease-in-out"}}/>
                : null
                    : null
                } 
            </div>
            {item.subNav && 
                <NavLink 
                        id={"submenu"+item.title}
                        activeStyle={{color:"orange"}}
                        className="tabLink" 
                        to={item.subNav ? item.subNav[0].link : item.link}   
                        isActive={(match,location) => {
                            if (item && location){
                                    if(location.pathname.includes(item.link)){
                                    return true;
                                }}

                            return false
                        }}           
                        onClick={(e)=>{e.preventDefault();item.subNav && showSubNav(e);}}
                        >
                        <div className="tabIcon">{item.icon}</div>
                        <div className={!sideBarClose || mouseHoverSidebar ? "tabName" : "tabNameClosed"}> {item.title} </div>
                </NavLink>
            }
            {!item.subNav &&
                <NavLink 
                        id={"submenu"+item.title}
                        activeStyle={{color:"orange"}}
                        className="tabLink" 
                        to={item.subNav ? item.subNav[0].link : item.link}   
                        isActive={(match,location) => {
                            if (item && location){
                                    if(location.pathname.includes(item.link)){
                                    return true;
                                }}

                            return false
                        }}           
                        >
                        <div className="tabIcon">{item.icon}</div>
                        <div className={!sideBarClose || mouseHoverSidebar ? "tabName" : "tabNameClosed"}> {item.title} </div>
                </NavLink>
            }
            {   // SubNav Menu Line which is on left side (dark grey color)
                (!sideBarClose || mouseHoverSidebar) &&
                <div className={subnav ? "line" : "lineclosed"} style={{height:"calc("+lineheight+"px - 8vh)"}}>
                </div>
            }
            {   // SubNav menu div
                (!sideBarClose || mouseHoverSidebar) && item.subNav && item.subNav.map((subnavItem,index)=>{
                    if (subnavItem.title){
                    return (
                    <NavLink 
                        id={"submenu"+subnavItem.title}
                        activeStyle={{ "color": 'orange',transition:"0.5s"}} 
                        className={subnav ? "sideBarDropDownLink" : "sideBarDropDownLinkClosed"} 
                        key = {index}
                        to={{
                            pathname:subnavItem.link,
                          }}                        
                        isActive={(match, location) => {
                            if (match && location){
                                    if(location.pathname.includes(match.url)){
                                    return true;
                                }}

                            return false
                        }}
                        >
                            <div style={{width:"75%",textAlign:"left",fontSize:"13px"}}>
                                {subnavItem.title}
                            </div>
                        
                    </NavLink>
                    )
                    }
                    return null;
                })
            }
            

        </>
    )
}
