import React,{useState} from 'react';
import axios from 'axios';

import { Resizable } from "re-resizable";
import CreatableSelect from 'react-select/creatable';
import ReactSelect from 'react-select';
import { DateRangePicker } from 'react-date-range';
import { Button, CircularProgress} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ShareIcon from '@mui/icons-material/Share';
// import SettingsIcon from '@mui/icons-material/Settings';
// import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { NavLink } from "react-router-dom";

import Zoom from '@mui/material/Zoom';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

import { CssTextField, ReactSelectStyles } from '../../../data/constants';
import * as Constants from '../../../data/constants';
import StatisticsRouter from '../statisticsRouter';
import '../../../css/pages/v2/statistics.css';
import { useForceUpdate, getDateFromDateRange, sorting, getRequests, getLocalStorageValue, sortingV2 } from '../../../util/util';
import ShowLoaderComponent from '../../../components/loader';
import ArrangeColumns from '../../../components/arrangeColumns/arrangeColumns';
import NoResultsFound from '../../../components/noResultFound';
import SomeErrorOccured from '../../../components/someErrorOccured';
import { getAdvertisers, getAffiliates, getManager } from '../../../apis/dropdown/dropdowns';
import DownloadStats from '../downloadStats';
const DayWiseOfferStats = () => {
    const updateComponent = useForceUpdate()
    var statsType = window.location.href.split("statistics/")[1]
    if(statsType.includes("?")){
        statsType = statsType.split("?")[0]
    }
    
    var panelCurrencies = [{label:'ALL/USD',value:null}]
    if(getLocalStorageValue('adminPanelLoginData').panelSettings && getLocalStorageValue('adminPanelLoginData').panelSettings.currency){
        for(var index in getLocalStorageValue('adminPanelLoginData').panelSettings.currency){
            panelCurrencies.push({label:index,value:index})
        }
    }
    var listedCurrencies = panelCurrencies

    var url = new URL(window.location.href)
    
    if(url.searchParams.get('dateRange')){
        var startDate = new Date(url.searchParams.get('dateRange').split("_")[0])
        var endDate = new Date(url.searchParams.get('dateRange').split("_")[1])
    }
    const [dateRange, setDateRange] = useState(url.searchParams.get('dateRange') ? [startDate,endDate] : [new Date(new Date().getTime()-86400000*6),new Date()])

    var searchParamsList = [
        {label:'offerId',setValueList:[],},
        {label:'advertiser',setValueList:[],},
        {label:'affiliate',setValueList:[],},
        {label:'advertiserManager',setValueList:[],},
        {label:'affiliateManager',setValueList:[],},
        {label:'timezone',setValueList:getLocalStorageValue('panelTimezone') ? getLocalStorageValue('panelTimezone') : {label : "UTC (UTC+00:00)" , value : "UTCUTC+00:00"},},
        {label:'externalOfferId',setValueList:[],},
    ]
    if(url.searchParams.get('offerId') || url.searchParams.get('externalOfferId') || url.searchParams.get('advertiser') || url.searchParams.get('affiliate') || url.searchParams.get('affiliate_manager') || url.searchParams.get('advertiser_manager')){
        for(var searchParamsListIndex in searchParamsList){
            var elem = searchParamsList[searchParamsListIndex]
            if(url.searchParams.get(elem.label)){
                var selectList = []
                var queryTextList = url.searchParams.get(elem.label).split(",")
                for(index in queryTextList){
                    if(elem.label!=='offerId' && elem.label!=='externalOfferId'){
                        selectList.push({
                            label: queryTextList[index],
                            value: queryTextList[index].substring(queryTextList[index].lastIndexOf("-") + 1, queryTextList[index].length)
                        })
                    }
                    else{
                        selectList.push({
                            label: queryTextList[index],
                            value: queryTextList[index],
                        })
                    }
                }
                elem.setValueList = selectList
            }
        }
    }
    const [optionsAffiliateList, setOptionsAffiliateList] = useState([])
    const [optionsAdvertiserList, setOptionsAdvertiserList] = useState([])
    const [optionsManagerList, setOptionsManagerList] = useState([])
    
    const [searchExternalOfferIdList, setSearchExternalOfferIdList] = useState(searchParamsList[6].setValueList)
    const [searchOfferList, setSearchOfferList] = useState(searchParamsList[0].setValueList)
    const [searchAdvertiserList, setSearchAdvertiserList] = useState(searchParamsList[1].setValueList)
    const [searchAffiliateList, setSearchAffiliateList] = useState(searchParamsList[2].setValueList)
    const [searchAdvertiserManagerList, setSearchAdvertiserManagerList] = useState(searchParamsList[3].setValueList)
    const [searchAffiliateManagerList, setSearchAffiliateManagerList] = useState(searchParamsList[4].setValueList)
    const [timezone, setTimezone] = useState(searchParamsList[5].setValueList)

    const [dateRangeState, setDateRangeState] = useState([{
                                                                startDate: new Date(dateRange[0]),
                                                                endDate: new Date(dateRange[1]),
                                                                key: 'selection'
                                                            }])
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState('usd');
    const [showTooltip, setShowToolTip] = useState(false)

    const dateRangePickedMethod = (item) => {
        setDateRangeState([item.selection]);
        if (item.selection.endDate !== item.selection.startDate){
            // setShowDatePicker(false)
            var value = [item.selection.startDate,item.selection.endDate]
            if (value[0] && value[1]){
                setDateRange(value)
                setShowDatePicker(false)
                }
        }
    }
    var timezoneList = Constants.timezoneList
    const filtersList = [
        {label:'Date Range',value:getDateFromDateRange(dateRange),setMethod:dateRangePickedMethod,show:true},
        {label:'Timezone',value:timezone,isSingle:true,setMethod:setTimezone,options:timezoneList,show:(statsType==='failed/postback' || statsType==='conversions' || statsType==='goals' || statsType==='trafficback' ? false : true)},
        {label:'Offer ID',mandatory:(statsType==='goals' ? true : false),value:searchOfferList,setMethod:setSearchOfferList,show:(statsType==='failed/postback' ? false : true)},
        {label:'External Offer Id',value:searchExternalOfferIdList,setMethod:setSearchExternalOfferIdList,show:(statsType==='failed/postback' || statsType==='conversions' || statsType==='goals' || statsType==='trafficback' ? false : true)},
        {label:'Advertiser',value:searchAdvertiserList,setMethod:setSearchAdvertiserList,options:optionsAdvertiserList,show:(statsType==='failed/postback' || statsType==='conversions' ? false : true)},
        {label:'Affiliate',value:searchAffiliateList,setMethod:setSearchAffiliateList,options:optionsAffiliateList,show:(statsType==='failed/postback' || statsType==='conversions' ? false : true)},
        {label:'Advertiser Manager',value:searchAdvertiserManagerList,setMethod:setSearchAdvertiserManagerList,options:optionsManagerList,show:(statsType==='failed/postback' || statsType==='conversions' || statsType==='trafficback' ? false : true)},
        {label:'Affiliate Manager',value:searchAffiliateManagerList,setMethod:setSearchAffiliateManagerList,options:optionsManagerList,show:(statsType==='failed/postback' || statsType==='conversions' || statsType==='trafficback' ? false : true)},
    ]
    
    const resetAllFields = (e) => {
        for(var index in filtersList){
            var filterItem = filtersList[index]
            if(filterItem.show){
                if(filterItem.label==='Date Range'){
                    var resetSelection = {selection : {
                                                            startDate: new Date(new Date().getTime()-86400000*6),
                                                            endDate: new Date(),
                                                            key: 'selection'
                                                        }}
                    filterItem.setMethod(resetSelection)
                }
                else{
                    filterItem.setMethod([])
                }
            }
        }
        var url = '/statistics/'+statsType
        window.history.pushState('Statistics - Conversions', 'Statistics -  Conversions', url);
        updateComponent(e);
    }
    var fixTableHeadersMapping = {
        'day-wise-offers' : [
                {show:true,label:'Offer',value:'key',minWidth:60,width:70},
                {show:true,label:'Offer Title',value:'title',minWidth:100,width:120},
                {show:true,label:'Advertiser ID',value:'advertiser_id',minWidth:90,width:120},
                {show:true,label:'External Offer ID',value:'external_offer_id',minWidth:60,width:120},
            ],
    }
    var fixTableHeaders = fixTableHeadersMapping[statsType]
    const [fixedTableHeaders, setFixedTableHeaders] = useState(fixTableHeaders)

    const [variableTableHeaders, setVariableTableHeaders] = useState([])
    const [mobileComponent, setMobileComponent] = useState(false)
    if(window.innerHeight > window.innerWidth && !mobileComponent){
        setMobileComponent(true)
        var fixedHeadersTemp = []
        fixedHeadersTemp.push(fixedTableHeaders[0])
        setFixedTableHeaders(fixedHeadersTemp)
    }
    const [editColumnsPopup, setEditColumnsPopup] = React.useState(false);

    const [sortingField, setSortingField] = useState({label:'Delivered Revenue',value:'approved_revenue'})
    const [sortingOrderBy, setSortingOrderBy] = useState(false) //false for DESC
    
    const closeEditColumnsPopup = (e) => {
        setEditColumnsPopup(false);
        localStorage.setItem(statsType+'tableContents',JSON.stringify(variableTableHeaders))
    }

    const [showLoader, setShowLoader] = useState(false)
    const [response, setResponse] = useState([])
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);
    const [totalResults, setTotalResults] = useState(50);
    const [offset, setOffset] = useState(1);
    const [totalValue, setTotalValue] = useState({})
    const [shareApiData, setShareApiData] = useState('')
    const [shareApiDataLoader, setShareApiDataLoader] = useState(false)

    const getStatsData = async(e,manual_limit,manual_page,shareApi) => {
        if(shareApi){
            setShareApiDataLoader(true)
        }
        else{
            setVariableTableHeaders([])
            setShowLoader(true);
        }
        setShareApiData(false)
        var filterData = {
            offer_id:{value:null,data:searchOfferList},
            external_offer_id:{value:null,data:searchExternalOfferIdList},
            advertiser_id:{value:null,data:searchAdvertiserList},
            advertiser_manager_id:{value:null,data:searchAdvertiserManagerList},
            affiliate_id:{value:null,data:searchAffiliateList},
            affiliate_manager_id:{value:null,data:searchAffiliateManagerList},
        }
        for(var filterItem in filterData){
            var searchString = ""
            for(var index in filterData[filterItem].data){
                searchString += filterData[filterItem].data[index].value+","
            }
            searchString = searchString.slice(0,-1)
            if(searchString){
                filterData[filterItem].value = searchString
            }
        }

        var dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric'};
        var startDate = new Date(dateRange[0]).toLocaleDateString("sv-SE", dateOptions)
        var endDate = new Date(dateRange[1]).toLocaleDateString("sv-SE", dateOptions)
        var requestPage = manual_page ? manual_page : page
        var requestLimit = manual_limit ? manual_limit : limit
        var url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"statistics/offer/date")
        if(shareApi){
            url = "".concat(Constants.PROTOCOL,Constants.HOST,"/api/report/v1/share/offer/date")
        }
        return await axios({
            method: 'GET',
            url,
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params: {
                startDate,
                endDate,
                offer_id:filterData.offer_id.value,
                external_offer_id:filterData.external_offer_id.value,
                advertiser_id:filterData.advertiser_id.value,
                advertiser_manager_id: filterData.advertiser_manager_id.value,
                affiliate_id:filterData.affiliate_id.value,
                affiliate_manager_id : filterData.affiliate_manager_id.value,
                
                //pagination and orderby
                limit:requestLimit,
                page:requestPage,
                order_by : sortingOrderBy ? "ASC" : "DESC",
                order_field : sortingField.value,
                utc_offset : timezone.value ? timezone.value.split(timezone.label.split(" (UTC")[0])[timezone.value.split(timezone.label.split(" (UTC")[0]).length-1].split("UTC")[1] : "+0:00",
                // currency:selectedCurrency
              }
        })
        .then(resp => {

            if(shareApi){
                setShareApiData(resp.data.result)
            }
            else{
                var response = resp.data.result.data
                var responseList = []
                var totalResponseList = []
                for(var responseIndex in response){
                    if(responseIndex!=='date_wise_total'){
                        responseList.push({
                            ...response[responseIndex],
                            key:parseInt(responseIndex)
                        })
                    }
                    if(responseIndex==='date_wise_total'){
                        var tempTotalTotal = {approved_revenue:0,conversions:0}
                        for(var totalIndex in response[responseIndex]){
                            tempTotalTotal['approved_revenue'] += response[responseIndex][totalIndex].approved_revenue
                            tempTotalTotal['conversions'] += response[responseIndex][totalIndex].conversions
                        }
                        totalResponseList.push({
                            ...response[responseIndex],
                            key:'Total',
                            external_offer_id:'-',
                            title:'-',
                            advertiser_id:'-',
                            total:tempTotalTotal
                        })
                    }
                }
                if(sortingField.value==='offer_id'){
                    sortingV2(responseList,'key',sortingOrderBy)

                }
                else{
                    sortingV2(responseList,'total',sortingOrderBy,sortingField.value)
                }
                setResponse(responseList)
                setTotalValue(totalResponseList)
                var tempVarTableHeaders = []
                
                for(var index in response[Object.keys(response)[0]]){
                    if(!['title','advertiser_id','external_offer_id','total'].includes(index)){
                        tempVarTableHeaders.push(
                            {show:true,label:index,value:index,minWidth:150,width:170,childItems:[
                                {label:'Delivered Revenue',value:'approved_revenue'},
                                {label:'Delivered QTY',value:'conversions'},
                            ]
                            },
                        )
                    }
                }
                sorting(tempVarTableHeaders,'value',false,true)
                if(response[Object.keys(response)[0]].total){
                    tempVarTableHeaders.push(
                        {show:true,label:'Total',value:'total',minWidth:150,width:170,childItems:[
                            {label:'Delivered Revenue',value:'approved_revenue'},
                            {label:'Delivered QTY',value:'conversions'},
                        ]
                        },
                    )
                }
                if(window.innerHeight > window.innerWidth){
                    tempVarTableHeaders = [ ...tempVarTableHeaders,
                                            {show:true,label:'External Offer ID',value:'external_offer_id',minWidth:60,width:120},
                                            {show:true,label:'Advertiser ID',value:'advertiser_id',minWidth:90,width:120},
                                            {show:true,label:'Offer Title',value:'title',minWidth:100,width:120},
                                            ]
                }
               
                setVariableTableHeaders(tempVarTableHeaders.reverse())
                setTotalResults(resp.data.result.count)
                setOffset(requestPage*requestLimit - requestLimit + 1)
            }
            setShowLoader(false);
            setShareApiDataLoader(false);
        })
        .catch((error) => {
            setShareApiDataLoader(false);
            setShowLoader(false);
            setResponse([]);
            setTotalResults(0);
            setOffset(0);
            if (error.response) {
                console.log(error.response.data);
                if(error.response.data.result){
                    setResponse(error.response.data.result);
                }
                else{
                    setResponse(error.response.data.detail);
                }
                console.log(error.response.status);
                console.log(error.response.headers);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        })
    };
    

    const [manualUseState, setManualUseState] = useState(false)
    if(!manualUseState){
        setManualUseState(true);
        document.title = 'Statistics - Day Wise Offer Statistics'
        getStatsData();
        getRequests(getAffiliates(),setOptionsAffiliateList)
        getRequests(getAdvertisers(),setOptionsAdvertiserList)
        getRequests(getManager(),setOptionsManagerList)
    }   

    const [sortingOrder, setSortingOrder] = useState(false)
    const [sortingOrderField, setSortingOrderField] = useState('key');
    const [selectAllConversions, setSelectAllConversions] = useState(false);
    const [selectedConversions, setSelectedConversions] = useState([]);

    const setSorting = (e,value,intValue=false) => {
        var unsortedResponse = response
        if(sortingOrderField===value){
            sorting(response,value,intValue,!sortingOrder)
            setSortingOrder(!sortingOrder)
        }
        else{
            sorting(response,value,intValue,false)
            setSortingOrder(false)
            setSortingOrderField(value)
        }
        setResponse(unsortedResponse)
        updateComponent(e)
    }

    const handleSearchButton = (e) => {
        getStatsData(e,limit,1);

        var url = '/statistics/'+statsType+"?"
        url+= (dateRange  ? "dateRange=" + getDateFromDateRange(dateRange).replaceAll('/',"-").replaceAll(" ","").replace("->","_") +"&" : "")
        var searchFieldList = [
            {label:'offerId',value:searchOfferList},
            {label:'externalOfferId',value:searchExternalOfferIdList},
            {label:'advertiser',value:searchAdvertiserList},
            {label:'affiliate',value:searchAffiliateList},
            {label:'affiliateManager',value:searchAffiliateManagerList},
            {label:'advertiserManager',value:searchAdvertiserManagerList},
        ]


        for(var searchFieldIndex in searchFieldList){
            if(searchFieldList[searchFieldIndex].value.length>0){
                url+=searchFieldList[searchFieldIndex].label+"="
                for(var index in searchFieldList[searchFieldIndex].value){
                    if(searchFieldList[searchFieldIndex].label==='offerId'){
                        url+= searchFieldList[searchFieldIndex].value[index].value
                    }
                    else{
                        url+= searchFieldList[searchFieldIndex].value[index].label.split("-")[0]+"-"+searchFieldList[searchFieldIndex].value[index].value
                    }
                    if(parseInt(index)!==searchFieldList[searchFieldIndex].value.length-1){
                        url+=","
                    }
                }
                url+="&"
            }
        }        
        url = url.slice(0,url.length-1)
        window.history.pushState('Statistics - Conversion', 'Statistics - Conversions', url);
    }

    const showValues = (data) => {
        if(Object.keys(data).length>0){
            var rows = Object.keys(data).map((keyItem,keyIndex)=>{
                var height = 40
                if(data[keyItem].payouts && data[keyItem].payouts.length>1){
                    height = 40 + data[keyItem].payouts.length*10
                }
                return <div id={'variableColumns'+keyIndex} style={{display:'flex',height}} key={keyIndex}>
                        {
                            variableTableHeaders.map((tableHeaderItem,tableHeaderIndex)=>{
                                if(!tableHeaderItem.disable && tableHeaderItem.show){    
                                    // var showValue = ""           
                                    // showValue = JSON.stringify(data[keyItem][tableHeaderItem.value])
                                    // if(tableHeaderItem.value==='payout' || tableHeaderItem.value==='revenue'){
                                    //     if(data[keyItem]['currency'].toUpperCase() && Constants.CURRENCY_LIST_SYMBOLS[data[keyItem]['currency'].toUpperCase()]){
                                    //         showValue +=  " "+Constants.CURRENCY_LIST_SYMBOLS[data[keyItem]['currency'].toUpperCase()]
                                    //     }
                                    //     else{
                                    //         showValue += " "+ data[keyItem]['currency'].toUpperCase()
                                    //     }
                                    // }
                                    return <div className='statsV2TableValueData' style={{justifyContent:(tableHeaderItem.textAlign ? tableHeaderItem.textAlign : tableHeaderItem.value==='declined_reason' ? 'flex-start' : 'center'),minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width}} key={tableHeaderIndex}>
                                            <div style={{display:'flex',alignItems:'center',height:'40px',width:'100%'}}>
                                                {tableHeaderItem.childItems && tableHeaderItem.childItems.map((childItem,childIndex)=>{
                                                    return <div key={childIndex} style={{minWidth:'50%',width:'50%',maxWidth:'50%',textAlign:'center'}}>
                                                        {childItem.value === 'approved_revenue' ? data[keyItem][tableHeaderItem.value][childItem.value].toFixed(4) : data[keyItem][tableHeaderItem.value][childItem.value]}
                                                    </div>
                                                })}
                                                {!tableHeaderItem.childItems &&
                                                    <div>
                                                        {data[keyItem][tableHeaderItem.value]}
                                                    </div>
                                                    }
                                            </div>
                                        </div>
                                }
                                return null;
                            })
                        }
                    </div>
            })
        }
        return rows;
    }

    const handleCopyContent = () => {
        navigator.clipboard.writeText(window.location.host+'/shared/'+shareApiData)
        setShowToolTip(true)
    }
    
    const [showExportPopup, setShowExportPopup] = useState(false)

    const getSortingOptions = () => {
        var sortingOptions =[]
        sortingOptions.push({label:'Offer ID',value:'offer_id'})
        sortingOptions.push({label:'Delivered Revenue',value:'approved_revenue'})
        sortingOptions.push({label:'Delivered QTY',value:'conversions'})
        return sortingOptions
    }
    return (
        <div className='mainContainerV2' onClick={()=>{if(showDatePicker){setShowDatePicker(false)};}}>
            <StatisticsRouter/>
            {showExportPopup && 
                <DownloadStats 
                    handleClose={()=>{setShowExportPopup(false)}}
                    type={'DaywiseOffer'}
                    datelist={dateRange}
                    // order_field={requestOrder}
                    // order_by={requestOrderBy}
                    // currency={selectedCurrency}
                    timezone={timezone}
                    offer_id={searchOfferList.map((item,index)=>{return item.value}).toString()}
                    advertiser_id={searchAdvertiserList}
                    advertiser_manager_id={searchAdvertiserManagerList}
                    affiliate_id={searchAffiliateList}
                    affiliate_manager_id={searchAffiliateManagerList}
                    external_offer_id={searchExternalOfferIdList}

                /> }
            {editColumnsPopup && <ArrangeColumns handleClose={closeEditColumnsPopup} editColumnsList={variableTableHeaders} setEditColumnsList={setVariableTableHeaders}/>}
            <div className='statsV2FilterContainer'>
                {filtersList.map((filterItem,filterIndex)=>{
                    if(filterItem.show){
                        if(filterItem.label==="Date Range"){
                            return <div key={filterIndex} className='statsV2DateRangeContainer'>
                                        <div className='statsV2FilterLabel'>{filterItem.label}</div>
                                        <CssTextField size='small' value={filterItem.value} style={{transform:'scale(0.8) translateX(-13%)',width:'200px'}} onClick={()=>{setShowDatePicker(true)}}/>
                                        <div style={{position:'absolute',zIndex:2,transform:'scale(1)'}} onClick={(e)=>{e.stopPropagation()}}>
                                            {showDatePicker && <DateRangePicker
                                                onChange={item => dateRangePickedMethod(item)}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                preventSnapRefocus={true}
                                                months={2}
                                                rangeColors={[localStorage.getItem('ambientColor')]}
                                                ranges={dateRangeState}
                                                showDateDisplay={false}
                                                scroll={{ enabled: window.innerWidth<800 ? true : false }}
                                                direction={window.innerWidth<800 ? "vertical" : "horizontal"}
                                                calendarFocus="backwards"
                                                />
                                            }
                                        </div>
                                    </div>
                        }
                        else if(filterItem.label==='Offer ID' || filterItem.label==='External Offer Id'){
                            return <div key={filterIndex} className='statsV2DateRangeContainer'>
                                        <div className='statsV2FilterLabel'>{filterItem.label}</div>
                                        <div style={{minWidth:'200px',transform:'scale(0.779) translateX(-14%) translateY(-1.5%)'}}>
                                            <CreatableSelect
                                                isClearable
                                                isMulti
                                                value={filterItem.value}
                                                onChange={(e)=>{filterItem.setMethod(e)}}
                                                menuPortalTarget={document.body} 
                                                styles={{...ReactSelectStyles}}
                                                />
                                        </div>
                                    </div>
                        }
                        else{
                            return <div key={filterIndex} className='statsV2DateRangeContainer'>
                                        <div className='statsV2FilterLabel'>{filterItem.label}</div>
                                        <div style={{minWidth:'200px',transform:'scale(0.779) translateX(-14%) translateY(-1.5%)'}}>               
                                            <ReactSelect 
                                                options={filterItem.options}
                                                value={filterItem.value}
                                                isMulti = {filterItem.label==="Timezone" ? false : true}
                                                onChange={(e)=>{filterItem.setMethod(e)}}
                                                menuPortalTarget={document.body} 
                                                styles={{...ReactSelectStyles}}/>
                                        </div>
                                    </div>
                        }
                    }
                    return null;
                })}
            </div>
            <div className='statsV2ButtonContainer'>
                <div style={{width:'50%',display:'flex',alignItems:'center'}}>
                    <Tooltip title="Share Data">
                        <IconButton className='settingsButton' size={'small'} onClick={(e)=>{getStatsData(e,limit,page,true)}}>
                            {shareApiDataLoader && <CircularProgress sx={{color:localStorage.getItem('ambientColor')}} size={15}/>}
                            {!shareApiDataLoader && <ShareIcon sx={{fontSize:'17px'}}/>}
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Export Data">
                        <IconButton className='settingsButton' size={'small'} onClick={()=>{setShowExportPopup(true)}}>
                            <FileDownloadIcon sx={{fontSize:'17px'}}/>
                        </IconButton>
                    </Tooltip>
                    
                    {shareApiData &&
                        <div style={{display:'flex',width:'200px',height:'25px',fontSize:'12px',border:'1px solid rgb(200,200,200)',alignItems:'center',paddingLeft:'5px'}}>
                            <a className='noscrollbar' href={'/shared/'+shareApiData} target='_blank' rel='noreferrer' style={{textDecoration:'none',color:'rgb(150,150,150)',maxWidth:'90%',overflow:'auto',scrollbarColor:'white'}}>
                                {window.location.host+'/shared/'+shareApiData}
                            </a>            
                            <IconButton aria-label="delete" onClick={handleCopyContent} onMouseLeave={()=>{setShowToolTip(false);}} >
                                <Tooltip  TransitionComponent={Zoom} title={showTooltip ? 'Copied' : 'Copy'}>
                                    <ContentCopyRoundedIcon sx={{fontSize:'12px'}}/>
                                </Tooltip>
                            </IconButton>
                        </div>
                    }
                </div>
                <div style={{display:"flex",width:'50%',justifyContent:'flex-end',paddingLeft:"2%",paddingRight:"2%"}}>     
                    <Button sx={{...Constants.CONTAINEDBUTTON,height:'25px',fontSize:'12px',textTransform:'none'}} onClick={handleSearchButton}>Search</Button>
                    <Button sx={{...Constants.OUTLINEDBUTTON,marginLeft:'20px',height:'25px',fontSize:'12px',textTransform:'none'}} onClick={resetAllFields}>Reset</Button>
                </div>
            </div>
            {
                false &&
                <div style={{height:'25px',paddingLeft:'2%',paddingRight:'2%',marginBottom:'10px',fontSize:'12px',display:'flex',alignItems:'center',transform:'translateY(0%)',opacity:'1',transition:'all 0.5s linear'}}>
                    <div style={{display:'flex',background:'rgb(255,255,255)',zIndex:'1'}}>Currency:</div>
                    <div  style={{display:'flex',alignItems:'center',transform:'translateX(0%)',opacity:'1',transition:'all 0.5s linear'}}>
                        {listedCurrencies.map((currencyItem,currencyIndex)=>{
                            return <div style={{display:'flex',cursor:'pointer',color:(selectedCurrency === currencyItem.value ? localStorage.getItem('ambientColor') : 'grey'),paddingLeft:'5px',paddingRight:'5px'}} onClick={()=>{setSelectedCurrency(currencyItem.value)}} key={currencyIndex}>
                                    {currencyItem.label}
                                </div>
                        })}
                    </div>
                </div>
            }

            <div style={{paddingLeft:'2%',paddingRight:'2%',marginBottom:'10px',fontSize:'12px',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                <div>
                    {!showLoader && "Showing "+offset+" - "+(offset+limit-1 < totalResults ? offset+limit-1 : totalResults)+" out of "+totalResults}
                </div>
                <div style={{display:'flex',alignItems:'center',transform:'scale(0.8) translateY(-8%) translateX(15%)'}}>
                    <div>
                        <label>Sort by:</label>
                        <div style={{width:'120px'}}>
                            <ReactSelect 
                                options={getSortingOptions()}
                                value={sortingField}
                                onChange={(e)=>{setSortingField(e)}}
                                placeholder={'Sort By'}
                                menuPortalTarget={document.body} 
                                styles={{...ReactSelectStyles}}/>
                        </div>
                    </div>
                    <div className='sortingOrder' style={{transform:'scale(1.5) translateX(18%) translateY(10%)'}} onClick={()=>{setSortingOrderBy(!sortingOrderBy)}}>
                        {!sortingOrderBy ? <div>&darr;</div> : <div>&uarr;</div>}
                    </div>
                </div>
            </div>
            {showLoader && 
                <ShowLoaderComponent/>
            }
            {
                !showLoader && 
                <>
                    {typeof response === 'object' && response.length>0 &&
                        <>
                            {/* Table HEADERS */}
                            <div className='statsV2TableHeader' style={selectedConversions.length>0 ? {top:50,transition:'all 0.5s linear'} : {top:0,transition:'all 0s linear'}}>
                                <div style={{display:'flex',justifyContent:'center',paddingRight:'0%',position:'sticky',left:0}}>           
                                    {
                                        fixedTableHeaders.map((tableHeaderItem,tableHeaderIndex)=>{
                                            if(!tableHeaderItem.disable && tableHeaderItem.show){
                                                if(tableHeaderItem.value==='checkbox'){
                                                    return <div className='statsV2TableHeaderData' style={{justifyContent:'center',minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width}} key={tableHeaderIndex} >
                                                                <input type='checkbox' checked={selectAllConversions} onChange={(e)=>{if(selectAllConversions){setSelectedConversions([])};setSelectAllConversions(e.target.checked);updateComponent(e);}}/>
                                                            </div>
                                                }
                                                return <div className='statsV2TableHeaderData' style={{minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width,height:'80px'}} key={tableHeaderIndex} onClick={(e)=>{setSorting(e,tableHeaderItem.value)}}>
                                                            <Resizable
                                                                id={tableHeaderItem.value}
                                                                style={{zIndex:(fixedTableHeaders.length-parseInt(tableHeaderIndex))}}
                                                                size={{width:"100%"}}
                                                                enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                                                                onResizeStop={(e, direction, ref, d) => {
                                                                    var tempTableHeaders = fixedTableHeaders;
                                                                    if(tempTableHeaders[tableHeaderIndex].minWidth + d.width >= fixTableHeaders[tableHeaderIndex].minWidth){
                                                                        tempTableHeaders[tableHeaderIndex].minWidth += d.width
                                                                    }
                                                                    else{
                                                                        tempTableHeaders[tableHeaderIndex].minWidth = fixTableHeaders[tableHeaderIndex].minWidth
                                                                    }
                                                                    setFixedTableHeaders(tempTableHeaders)
                                                                    updateComponent(e);
                                                                    }}
                                                                    >
                                                                    <div className='overflowText' style={{display:'flex',justifyContent:'center'}}>
                                                                        {tableHeaderItem.label}
                                                                    </div>
                                                            </Resizable>
                                                        </div>
                                            }
                                            return null;
                                        })
                                    }
                                </div>
                                <div id = "statsV2TableHeader" style={{paddingLeft:'0%',display:'flex',overflowX:'auto'}} onScroll={(e)=>{document.getElementById('statsV2TableValue').scrollLeft = e.target.scrollLeft;}}>
                                    {
                                        variableTableHeaders.map((tableHeaderItem,tableHeaderIndex)=>{
                                            if(!tableHeaderItem.disable && tableHeaderItem.show){
                                                return <div className='statsV2TableHeaderData' style={{minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width,minHeight:'80px'}} key={tableHeaderIndex} onClick={(e)=>{setSorting(e,tableHeaderItem.value,true)}}>
                                                        <Resizable
                                                            id={tableHeaderItem.value}
                                                            style={{zIndex:(variableTableHeaders.length-parseInt(tableHeaderIndex))}}
                                                            size={{width:"95%"}}
                                                            enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                                                            onResizeStop={(e, direction, ref, d) => {
                                                                var tempTableHeaders = variableTableHeaders;
                                                                if(tempTableHeaders[tableHeaderIndex].minWidth + d.width >= 200){
                                                                    tempTableHeaders[tableHeaderIndex].minWidth += d.width
                                                                }
                                                                setVariableTableHeaders(tempTableHeaders)
                                                                updateComponent(e);
                                                                }}
                                                                >
                                                                <div style={{display:'flex',textAlign:'center',flexDirection:'column',justifyContent:'center',alignItems:'center',width:'100%'}}>                                                        
                                                                    <div style={{height:(tableHeaderItem.childItems ? '40px' : '80px'),display:'flex',alignItems:'center'}}>{tableHeaderItem.label}</div>
                                                                    {tableHeaderItem.childItems && 
                                                                        <div style={{display:'flex',alignItems:'center',height:'40px',width:'100%'}}>
                                                                            {tableHeaderItem.childItems.map((childItem,childIndex)=>{
                                                                                return <div key={childIndex} style={{minWidth:'50%',width:'50%',maxWidth:'50%'}}>
                                                                                    {childItem.label}
                                                                                </div>
                                                                            })}
                                                                        </div>
                                                                    }
                                                                </div>
                                                        </Resizable>
                                                    </div>
                                            }
                                            return null;
                                        })
                                    }
                                </div>
                            </div>
                            {/* Table VALUES */}
                            <div className='statsV2TableValue'>
                                <div>
                                    {   
                                        Object.keys(response).map((keyItem,keyIndex)=>{
                                            var height = 40
                                            if(response[keyItem].payouts && response[keyItem].payouts.length>1){
                                                height = 40 + (response[keyItem].payouts.length*10)
                                            }
                                            if(selectAllConversions){
                                                var tempSelectedConversion = selectedConversions
                                                if(!tempSelectedConversion.includes(response[keyItem].id)){
                                                    tempSelectedConversion.push(response[keyItem].id)
                                                    setSelectedConversions(tempSelectedConversion)
                                                }
                                            }
                                            return <div id={'fixedColums'+keyIndex} style={{display:'flex',height}} key={keyIndex}>           
                                                {
                                                    fixedTableHeaders.map((tableHeaderItem,tableHeaderIndex)=>{
                                                        var dateOptions = { year: 'numeric', month: 'short', day: 'numeric' ,hour:'numeric',minute:'numeric',second:'numeric'};
                                                        if(!tableHeaderItem.disable && tableHeaderItem.show){
                                                            if(tableHeaderItem.value==='checkbox'){
                                                                return <div className='statsV2TableValueData' style={{minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width,maxWidth:tableHeaderItem.width}} key={tableHeaderIndex}>
                                                                        <input checked={selectedConversions.includes(response[keyItem].id)} type='checkbox' onChange={(e)=>{
                                                                                var tempSelectedConversion = selectedConversions
                                                                                if(!tempSelectedConversion.includes(response[keyItem].id)){
                                                                                    tempSelectedConversion.push(response[keyItem].id)
                                                                                }
                                                                                else{
                                                                                    setSelectAllConversions(false);
                                                                                    tempSelectedConversion = tempSelectedConversion.filter(e=>e!==response[keyItem].id)
                                                                                }
                                                                                setSelectedConversions(tempSelectedConversion)
                                                                                updateComponent(e);
                                                                            }}/>
                                                                    </div>
                                                            }
                                                            if(tableHeaderItem.value==='key'){        
                                                                return <div className='statsV2TableValueData' style={{minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width,maxWidth:tableHeaderItem.width}} key={tableHeaderIndex}>
                                                                            {response[keyItem][tableHeaderItem.value]}
                                                                        </div>
                                                            }

                                                            if(tableHeaderItem.value==='title'){        
                                                                return <NavLink to={'/offers/view/'+response[keyItem]['key']} className='statsV2TableValueData overflowText' style={{minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width,maxWidth:tableHeaderItem.width,justifyContent:'flex-start',textDecoration:'none',color:localStorage.getItem('ambientColor')}} key={tableHeaderIndex}>
                                                                            {response[keyItem][tableHeaderItem.value]}
                                                                        </NavLink>
                                                            }
                                                            if(tableHeaderItem.value==='created_at'){        
                                                                return <div className='statsV2TableValueData' style={{textAlign:'center',minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width,maxWidth:tableHeaderItem.width}} key={tableHeaderIndex}>
                                                                            {new Date(response[keyItem][tableHeaderItem.value]).toLocaleDateString("en-US", dateOptions)}
                                                                        </div>
                                                            }
                                                            return <div className='statsV2TableValueData' style={{justifyContent:'flex-start',minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width}} key={tableHeaderIndex}>
                                                                <div className='overflowText' style={{paddingLeft:'3%',paddingRight:'3%'}}>
                                                                    {response[keyItem][tableHeaderItem.value] ? response[keyItem][tableHeaderItem.value] : '-'}
                                                                </div>
                                                            </div>
                                                        }
                                                        return null;
                                                    })
                                                }
                                            </div>
                                        })   
                                    }
                                </div>
                                <div id='statsV2TableValue' className='noscrollbar' style={{display:'flex',flexDirection:'column',overflow:'auto'}} onScroll={(e)=>{document.getElementById('statsV2TableHeader').scrollLeft = e.target.scrollLeft;document.getElementById('statsV2TableValue2').scrollLeft = e.target.scrollLeft;}}>
                                    {showValues(response)}
                                </div>
                            </div>
                            <div className='statsV2TableValue' >
                                <div style={{background:'rgb(200,200,200)'}}>
                                    {   
                                        Object.keys(totalValue).map((keyItem,keyIndex)=>{
                                            var height = 40
                                            if(totalValue[keyItem].payouts && totalValue[keyItem].payouts.length>1){
                                                height = 40 + (totalValue[keyItem].payouts.length*10)
                                            }
                                            if(selectAllConversions){
                                                var tempSelectedConversion = selectedConversions
                                                if(!tempSelectedConversion.includes(totalValue[keyItem].id)){
                                                    tempSelectedConversion.push(totalValue[keyItem].id)
                                                    setSelectedConversions(tempSelectedConversion)
                                                }
                                            }
                                            return <div id={'fixedColums'+keyIndex} style={{display:'flex',height}} key={keyIndex}>           
                                                {
                                                    fixedTableHeaders.map((tableHeaderItem,tableHeaderIndex)=>{
                                                        var dateOptions = { year: 'numeric', month: 'short', day: 'numeric' ,hour:'numeric',minute:'numeric',second:'numeric'};
                                                        if(!tableHeaderItem.disable && tableHeaderItem.show){
                                                            if(tableHeaderItem.value==='checkbox'){
                                                                return <div className='statsV2TableValueData' style={{minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width,maxWidth:tableHeaderItem.width}} key={tableHeaderIndex}>
                                                                        <input checked={selectedConversions.includes(totalValue[keyItem].id)} type='checkbox' onChange={(e)=>{
                                                                                var tempSelectedConversion = selectedConversions
                                                                                if(!tempSelectedConversion.includes(totalValue[keyItem].id)){
                                                                                    tempSelectedConversion.push(totalValue[keyItem].id)
                                                                                }
                                                                                else{
                                                                                    setSelectAllConversions(false);
                                                                                    tempSelectedConversion = tempSelectedConversion.filter(e=>e!==totalValue[keyItem].id)
                                                                                }
                                                                                setSelectedConversions(tempSelectedConversion)
                                                                                updateComponent(e);
                                                                            }}/>
                                                                    </div>
                                                            }
                                                            if(tableHeaderItem.value==='key'){        
                                                                return <div className='statsV2TableValueData' style={{minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width,maxWidth:tableHeaderItem.width}} key={tableHeaderIndex}>
                                                                            {totalValue[keyItem][tableHeaderItem.value]}
                                                                        </div>
                                                            }

                                                            if(tableHeaderItem.value==='title'){        
                                                                return <NavLink to={'/offers/view/'+totalValue[keyItem]['key']} className='statsV2TableValueData overflowText' style={{minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width,maxWidth:tableHeaderItem.width,justifyContent:'flex-start',textDecoration:'none',color:localStorage.getItem('ambientColor')}} key={tableHeaderIndex}>
                                                                            {totalValue[keyItem][tableHeaderItem.value]}
                                                                        </NavLink>
                                                            }
                                                            if(tableHeaderItem.value==='created_at'){        
                                                                return <div className='statsV2TableValueData' style={{textAlign:'center',minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width,maxWidth:tableHeaderItem.width}} key={tableHeaderIndex}>
                                                                            {new Date(totalValue[keyItem][tableHeaderItem.value]).toLocaleDateString("en-US", dateOptions)}
                                                                        </div>
                                                            }
                                                            return <div className='statsV2TableValueData' style={{justifyContent:'flex-start',minWidth:tableHeaderItem.minWidth,width:tableHeaderItem.width}} key={tableHeaderIndex}>
                                                                <div className='overflowText' style={{paddingLeft:'3%',paddingRight:'3%'}}>
                                                                    {totalValue[keyItem][tableHeaderItem.value] ? totalValue[keyItem][tableHeaderItem.value] : '-'}
                                                                </div>
                                                            </div>
                                                        }
                                                        return null;
                                                    })
                                                }
                                            </div>
                                        })   
                                    }
                                </div>
                                <div id='statsV2TableValue2' className='noscrollbar' style={{display:'flex',flexDirection:'column',overflow:'auto',background:'rgb(200,200,200)'}} onScroll={(e)=>{document.getElementById('statsV2TableHeader').scrollLeft = e.target.scrollLeft;document.getElementById('statsV2TableValue').scrollLeft = e.target.scrollLeft;}}>
                                    {showValues(totalValue)}
                                </div>
                            </div>
                            {/* Table PAGINATION */}
                            <div style={{display:'flex',alignItems:'center',justifyContent:"space-between",paddingLeft:'20px',paddingRight:'20px',paddingBottom:'20px',position:'sticky',bottom:0,background:'white'}}>
                                <div>
                                    <label style={{paddingRight:"5px",fontSize:'14px'}}>Limit</label>
                                    <select value={limit} style={{outline:'none',border:'none'}} onChange={(e)=>{getStatsData(e,e.target.value,1);setLimit(parseInt(e.target.value));setPage(1);}}>
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                        <option value={75}>75</option>
                                        <option value={100}>100</option>
                                    </select>
                                </div>
                                <Pagination count={Math.ceil(totalResults/limit)} page={page} onChange={(event,value)=>{getStatsData(event,limit,value);setPage(value);updateComponent(event);window.scrollTo({top: 0,left: 0,behavior: 'smooth'});}} />
                            </div>
                        </>
                    }
                    {typeof response === 'object' && 
                        response.length===0
                        &&
                        <NoResultsFound/>
                    }
                    {
                        typeof response === 'string' &&
                        <SomeErrorOccured error={response}/>
                    }
                </>
            }
           
        </div>
    )
}

export default DayWiseOfferStats