import React, { useEffect, useState } from 'react'
import axios from 'axios';
import * as Constants from '../../data/constants';
import { capitalize, getLocalStorageValue, useForceUpdate } from '../../util/util';
import '../../css/pages/billing.css';
import { Button, CircularProgress } from '@mui/material';
import ReactSelect from 'react-select';
import { Store } from 'react-notifications-component';
import PrintIcon from '@mui/icons-material/Print';
import Tooltip from '@mui/material/Tooltip';
// import DownloadIcon from '@mui/icons-material/Download';

const InvoiceDetails = () => {
    const refreshComponent = useForceUpdate();
    var invoiceId = window.location.href.split('/billing/affiliate/view/')[1]
    const [manualUseState, setManualUseState] = useState(false)
    const [loader, setShowLoader] = useState(true)
    const [invoiceOfferData, setInvoiceOfferData] = useState({})
    const [invoiceStatusSelected, setInvoiceStatusSelected] = useState(false)
    const [updateStatusChanged, setUpdateStatusChanged] = useState(false)

    document.title = 'Payment (Affiliate) #'+invoiceId
    const getInvoiceDetailData = async() => {
        return await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"affiliate/invoice/list"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                row_id:invoiceId
            }
        })
        .then(resp => {
            return resp.data.result
        })
        .catch(error => {
            console.error(error);
            if(error.response.data){
            }
        })
    };
    
    const getInvoiceDetail = async() => {
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"affiliate/invoice/"+invoiceId),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        })
        .then(async resp => {
            var data = await getInvoiceDetailData()
            setInvoiceOfferData({...resp.data.result,...data[0]})
        })
        .catch(error => {
            console.error(error);
            if(error.response.data){
            }
        })
        setShowLoader(false);
    };

    const updatePaymentStatusApi = async() => {
        await axios({
            method: 'POST',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"change/invoice/status"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
            data:{
                "invoice_id": invoiceId,
                "new_status": invoiceStatusSelected.value
            }
        })
        .then(async resp => {
            setUpdateStatusChanged(false);
            Store.addNotification({
                title: "Success!",
                message: resp.data.result,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
        })
        .catch(error => {
            console.error(error);
            if(error.response && error.response.data){
                Store.addNotification({
                    title: "Error!",
                    message: typeof error.response.data.result === "string" ? error.response.data.result : "Some error occured",
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                });
            }
        })
        setShowLoader(false);
    }
    if(!manualUseState){
        setManualUseState(true)
        getInvoiceDetail()
    }
    
    const getTotal = (key) => {
        var total = 0
        for(var index in invoiceOfferData.data){
            total += invoiceOfferData.data[index][key]
        }
        return total
    }

    var statusOptions = [
        {label:'Waiting',value:'waiting'},
        // {label:'Pending',value:'pending'},
        {label:'Partly Paid',value:'partly paid'},
        {label:'Declined',value:'declined'},
        {label:'Completed',value:'completed'},
    ]

    const [companyLogoBase64, setCompanyLogoBase64] = useState(false)
    
    useEffect(() => {
        if(!companyLogoBase64){
            try{
                var file = require('../../img/companies/'+process.env.REACT_APP_COMPANY_NAME.replaceAll(" ","").toLowerCase()+'.png')
                function toDataURL(url, callback) {
                    var xhr = new XMLHttpRequest();
                    xhr.onload = function() {
                        var reader = new FileReader();
                        reader.onloadend = function() {
                            callback(reader.result);
                        }
                        reader.readAsDataURL(xhr.response);
                    };
                    xhr.open('GET', file);
                    xhr.responseType = 'blob';
                    xhr.send();
                }
                toDataURL(file,(result)=>{
                    setCompanyLogoBase64(result)
                })
            }
            catch{
                setCompanyLogoBase64('tried')
            }
        }
        return () => {
            
        }
    }, [companyLogoBase64])
    
    const showImage = async() => {
        try{
            if(companyLogoBase64 && companyLogoBase64!=='tried'){
                return "<img src="+companyLogoBase64+" alt='' width='150px'></img>"
            }
            else{
                return '<label>'+process.env.REACT_APP_COMPANY_NAME+'</label>'

            }
        }
        catch{
            return '<label>'+process.env.REACT_APP_COMPANY_NAME+'</label>'
        }
    }

    const printInvoice = async() => {
        var page = document.getElementById('invoicegenerated').innerHTML;
        if(page.includes('statusInvoiceGenerated')){
            var pageNew = ''
            pageNew += page.split('<div id="statusInvoiceGenerated"')[0]
            pageNew += capitalize(invoiceOfferData.status)+'</div>'
            pageNew += page.split('<div id="statusInvoiceBreak"></div>')[1]
        }
        let mywindow = window.open('Invoice', 'PRINT', 'height=650,width=900,top=100,left=150');
        var image = await showImage()
        mywindow.document.write('<body  onload=window.print()>')
        mywindow.document.write('<div style="display:flex; align-items:center;justify-content:space-between"><h1>Affiliate Invoice</h1>')
        mywindow.document.write(image)
        mywindow.document.write('</div>')
        mywindow.document.write(pageNew);
        mywindow.document.write(document.getElementById('generatedinvoiceTable').innerHTML.replaceAll('bottom: 20px;','').replaceAll('(Remove Correction)',''));
        mywindow.document.write('</body>')
        mywindow.focus(); // necessary for IE >= 10*/
        mywindow.print();
        mywindow.close();
    }

    const [generateSumValue, setGenerateSumValue] = useState('')
    const [comment, setComment] = useState('')
    const [addSubtractLoader, setAddSubtractLoader] = useState(false)
    var editInvoiceData = async(tempInvoiceData) => {
        setAddSubtractLoader(true)
        await axios({
            method: 'POST',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"affiliate/invoice/edit"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
            },
            data:{
                "row_id" : tempInvoiceData.id,
                "corrections": tempInvoiceData.corrections,
                // "comment": "",
                "updated_payout": tempInvoiceData.payout,
                // "payment_system" : "Bank Transfer",
                // "payment_account": {
                //             "ifsc_code": "IFSC2021",
                //             "account_no": "1234567899"
                //         }
                
            }
        })
        .then(async resp => {
        })
        .catch(error => {
            console.error(error);
        })
        setAddSubtractLoader(false);
    }
    const addSubtractCorrection = async(type) => {
        var tempInvoiceData = invoiceOfferData;
        if(!tempInvoiceData.corrections){
            tempInvoiceData.corrections = []
        }
        if(type==='add'){
            tempInvoiceData.payout += parseFloat(generateSumValue)
        }
        if(type==='subtract'){
            tempInvoiceData.payout -= parseFloat(generateSumValue)
        }
        tempInvoiceData.corrections.push({comment,amount:(type==='subtract' ? '-'+generateSumValue : generateSumValue)})
        setInvoiceOfferData(tempInvoiceData)
        editInvoiceData(tempInvoiceData)
        setGenerateSumValue('')
        setComment('')
        refreshComponent(12)
    }

    const removeCorrection = (item,index) => {
        var tempInvoiceData = invoiceOfferData;
        tempInvoiceData.payout -= parseFloat(item.amount)
        tempInvoiceData.corrections.splice(index,1)
        setInvoiceOfferData(tempInvoiceData)
        editInvoiceData(tempInvoiceData)
        refreshComponent(12)
    }
    return (
        <div className='advertiserPageV2' style={{minHeight:'80vh'}}>
            <div style={{display:'flex',justifyContent:'space-between'}}>
                <div>
                    Payment #{invoiceId}
                </div>
                <div style={{marginRight:'30px',display:'flex',alignItems:'center'}}>
                    <Tooltip title='Print Invoice'>
                        <div onClick={printInvoice}>
                            <PrintIcon sx={{fontSize:'18px',color:'rgb(100,100,100)'}}/>
                        </div>
                    </Tooltip>

                    {/* <Tooltip title='Download Invoice'>
                        <div style={{marginLeft:'10px'}}>
                            <DownloadIcon sx={{fontSize:'18px',color:'rgb(100,100,100)'}}/>
                        </div>
                    </Tooltip> */}
                </div>
                
            </div>
            {
                loader
                &&
                <div style={{display:'flex',alignItems:'center',justifyContent:'center',minHeight:'72vh'}}>
                    <CircularProgress size={'40px'} sx={{color:localStorage.getItem('ambientColor')}}/>
                </div>
            }
            {
                !loader
                &&
                <div id='invoicegenerated' style={{width:'100%',borderTop:'1px solid rgb(220,220,220)',marginTop:'10px',paddingTop:'10px'}}>
                    <div className='invoiceFlex' style={{paddingTop:'10px',paddingLeft:'20px',paddingRight:'20px',display:'flex',alignItems:'center',flexWrap:'wrap',fontSize:'14px'}}>
                        <div className='invoiceLabel' style={{minWidth:'200px',fontWeight:500}}>
                            Affiliate:
                        </div>
                        <div className='invoiceValue'>
                            {invoiceOfferData.affiliate_name + " ("+ invoiceOfferData.affiliate_id + ")" }
                        </div>
                    </div>
                    <div className='invoiceFlex' style={{paddingTop:'10px',paddingLeft:'20px',paddingRight:'20px',display:'flex',alignItems:'center',flexWrap:'wrap',fontSize:'14px'}}>
                        <div className='invoiceLabel' style={{minWidth:'200px',fontWeight:500}}>
                            Date Range:
                        </div>
                        <div className='invoiceValue'>
                            {invoiceOfferData.start_date + " -> "+ invoiceOfferData.end_date}
                        </div>
                    </div>
                    <div className='invoiceFlex' style={{paddingTop:'10px',paddingLeft:'20px',paddingRight:'20px',display:'flex',alignItems:'center',flexWrap:'wrap',fontSize:'14px'}}>
                        <div className='invoiceLabel' style={{minWidth:'200px',fontWeight:500}}>
                            Sum:
                        </div>
                        <div className='invoiceValue'>
                            {invoiceOfferData.payout_sum + " " + invoiceOfferData.currency} 
                        </div>
                    </div>
                    <div className='invoiceFlex' style={{paddingTop:'10px',paddingLeft:'20px',paddingRight:'20px',display:'flex',alignItems:'center',flexWrap:'wrap',fontSize:'14px'}}>
                        <div className='invoiceLabel' style={{minWidth:'200px',fontWeight:500}}>
                            Total Conversion:
                        </div>
                        <div className='invoiceValue'>
                            {invoiceOfferData.conversion_sum}
                        </div>
                    </div>

                    <div className='invoiceFlex' style={{paddingTop:'10px',paddingLeft:'20px',paddingRight:'20px',display:'flex',alignItems:'center',flexWrap:'wrap',fontSize:'14px'}}>
                        <div className='invoiceLabel' style={{minWidth:'200px',fontWeight:500}}>
                            Payment Status:
                        </div>
                        <div id='statusInvoiceGenerated'>
                            <div className='invoiceValue' style={{paddingBottom:'0px',textTransform:'capitalize',transform:'scale(0.8)',transformOrigin:'left',minWidth:'150px'}}>
                                <ReactSelect 
                                    options={statusOptions}
                                    value={invoiceStatusSelected ? invoiceStatusSelected : {label:capitalize(invoiceOfferData.status),value:invoiceOfferData.status}}
                                    // isMulti={filterItem.isSingle ? false : true}
                                    onChange={(e)=>{setInvoiceStatusSelected(e);setUpdateStatusChanged(true)}}
                                    placeholder={'Select Payment Status'}
                                    menuPortalTarget={document.body} 
                                    styles={{...Constants.ReactSelectStyles}}
                                />
                            </div>
                        </div>
                        {
                            updateStatusChanged
                            &&
                            <div style={{paddingBottom:'0px',transform:'scale(0.8)',transformOrigin:'left'}}>
                                <Button sx={{...Constants.OUTLINEDBUTTON,textTransform:'capitalize'}} onClick={updatePaymentStatusApi}>Update Status</Button>
                            </div>
                        }
                    </div>
                    <div id='statusInvoiceBreak'></div>
                    <div className='invoiceFlex' style={{paddingTop:'10px',paddingLeft:'20px',paddingRight:'20px',display:'flex',alignItems:'center',flexWrap:'wrap',fontSize:'14px'}}>
                        <div className='invoiceLabel' style={{minWidth:'200px',fontWeight:500}}>
                            Payment System:
                        </div>
                        <div className='invoiceValue'>
                            {invoiceOfferData.payment_system}
                        </div>
                    </div>
                    <div className='invoiceFlex' style={{paddingTop:'10px',paddingLeft:'20px',paddingRight:'20px',display:'flex',alignItems:'center',flexWrap:'wrap',fontSize:'14px'}}>
                        <div className='invoiceLabel' style={{minWidth:'200px',fontWeight:500}}>
                            Payment Details:
                        </div>
                        <div className='invoiceValue'>
                            <table>
                                <tbody>
                                    {invoiceOfferData.payment_account && Object.keys(invoiceOfferData.payment_account).sort().map((item,index)=>{
                                        return <tr key={index}>
                                            <td style={{paddingRight:'20px'}}>{item}</td>
                                            <td>{invoiceOfferData.payment_account[item]}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
            {
                !loader
                    &&
                Object.keys(invoiceOfferData).length>0
                &&
                <div id='generatedinvoiceTable'>
                    <table style={{tableLayout:'fixed',width:'94%',marginTop:'30px',marginLeft:'3%',marginRight:'3%',borderSpacing:'0px'}}>
                        <thead>
                            <tr style={{background:'rgb(250,250,250)',position:'sticky',top:0}}>
                                <td style={{padding:'10px'}} className='billingTableHeadData'>Offer ID</td>
                                <td style={{padding:'10px'}} className='billingTableHeadData'>Offer Title</td>
                                <td style={{padding:'10px'}} className='billingTableHeadData'>Conversions</td>
                                <td style={{padding:'10px'}} className='billingTableHeadData'>Total Payout</td>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(invoiceOfferData.data).map((item,index)=>{
                                return <tr key={index}>
                                    <td style={{padding:'10px'}} className='billingTableValueData'>{item}</td>
                                    <td style={{padding:'10px'}} className='billingTableValueData'>{invoiceOfferData.data[item].title}</td>
                                    <td style={{padding:'10px'}} className='billingTableValueData'>{invoiceOfferData.data[item].conversions}</td>
                                    <td style={{padding:'10px'}} className='billingTableValueData'>{invoiceOfferData.data[item].payout}</td>
                                </tr>
                            })}
                            {invoiceOfferData.corrections && invoiceOfferData.corrections.map((item,index)=>{
                                return  <tr key={index}>
                                    <td colSpan={3} style={{padding:'10px'}} className='billingTableValueData'>
                                        <div style={{display:'flex',alignItems:'center'}}>
                                            {item.comment}&nbsp;
                                            <div style={{fontSize:'12px',color:'red',cursor:'pointer'}} onClick={()=>{removeCorrection(item,index)}}>
                                                {'(Remove Correction)'}
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{padding:'10px',color:(item.amount.includes('-') ? 'red' : 'green')}} className='billingTableValueData'>{item.amount}</td>
                                </tr>
                            })}
                            <tr style={{fontWeight:'500',position:'sticky',bottom:20,background:'rgb(253,253,253)'}}>
                                <td colSpan={2} className='billingTableValueData' style={{borderBottom:'1px solid rgb(220,220,220)',padding:'10px'}}>Total</td>
                                <td className='billingTableValueData' style={{borderBottom:'1px solid rgb(220,220,220)',padding:'10px'}}>
                                    {getTotal('conversions')}
                                </td>
                                <td className='billingTableValueData' style={{borderBottom:'1px solid rgb(220,220,220)',padding:'10px'}}>
                                    {invoiceOfferData.payout}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
            {
                !loader
                &&
                <div style={{margin:'3%',height:'180px',background:'rgb(248,248,250)',width:'94%',borderRadius:'10px',border:'1px solid rgb(220,220,220)'}}>
                    <div style={{padding:'20px'}}>
                        <div style={{fontSize:'20px'}}>Correction:</div>
                        <div style={{display:'flex',alignItems:'center',flexWrap:'wrap',marginTop:'20px'}}>
                            <div style={{width:'160px',minHeight:'30px',background:'rgb(248,248,250)',border:'1px solid rgb(220,220,220)',borderRadius:'5px'}}>
                                <input placeholder='Value' value={generateSumValue} onChange={(e)=>{if(parseFloat(e.target.value) || e.target.value===''){setGenerateSumValue(e.target.value)}; refreshComponent(2);}} style={{minHeight:'30px',borderTopLeftRadius:'5px',borderBottomLeftRadius:'5px',outline:'none',border:'none',width:'100px',paddingLeft:'10px'}}/>
                                <label style={{marginLeft:'10px',fontSize:'14px',color:'rgb(100,100,100)'}}>{invoiceOfferData.currency}</label>
                            </div>
                            <input value={comment} placeholder={'Comment'} onChange={(e)=>{setComment(e.target.value)}} style={{minHeight:'30px',borderRadius:'5px',marginLeft:'20px',outline:'none',border:'1px solid rgb(220,220,220)',width:'150px',paddingLeft:'10px'}}/>
                            <Button disabled={!comment || !generateSumValue} sx={{...Constants.CONTAINEDBUTTON,width:'90px',textTransform:'capitalize',marginLeft:'20px',transform:'scale(0.85)'}} onClick={()=>{addSubtractCorrection('add')}}>
                                {addSubtractLoader && <CircularProgress size={'18px'} sx={{color:'white'}}/>}
                                {!addSubtractLoader && 'Add'}
                            </Button>
                            <Button disabled={!comment || !generateSumValue} sx={{...Constants.OUTLINEDBUTTON,width:'90px',textTransform:'capitalize',marginLeft:'10px',transform:'scale(0.85)'}} onClick={()=>{addSubtractCorrection('subtract')}}>
                                {addSubtractLoader && <CircularProgress size={'18px'} sx={{color:localStorage.getItem('ambientColor')}}/>}
                                {!addSubtractLoader && 'Subtract'}
                            </Button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default InvoiceDetails