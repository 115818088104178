import axios from "axios";
import * as Constants from '../../data/constants';
import { getLocalStorageValue, sorting, sortingV2 } from "../../util/util";

export const getAdvertisers = async(allRequired,dictRequired) => {
    return await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.ADVERTISER,"/","details"),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
    })
    .then(resp => {
       
        let advertiserlist = []
        let advertiserDict = {}
        if(allRequired){
            advertiserlist.push({label:"All",value:null})
        }
        for (var item in resp.data.result){
            advertiserDict[Object.keys(resp.data.result[item])[0]] = resp.data.result[item][Object.keys(resp.data.result[item])[0]] + " - " + Object.keys(resp.data.result[item])[0]
            advertiserlist.push({label:resp.data.result[item][Object.keys(resp.data.result[item])[0]] + " - " + Object.keys(resp.data.result[item])[0],value:Object.keys(resp.data.result[item])[0]})
        }
        sorting(advertiserlist,'label',false,true)
        if(dictRequired===true){
            return advertiserDict
        }
        if(dictRequired==='both'){
            return {advertiserDict,advertiserlist}
        }
        return advertiserlist;
    })
    .catch(err => {
        console.error(err);
        return []
    })
};

export const getAffiliates = async(allRequired) => {
    return await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"affiliate/details"),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
    })
    .then(resp => {
        let affiliatelist = []
        if(allRequired){
            affiliatelist.push({label:"All",value:null})
        }
        for (var item in resp.data.result){
            affiliatelist.push({label:resp.data.result[item][Object.keys(resp.data.result[item])[0]] + " - " + Object.keys(resp.data.result[item])[0],value:Object.keys(resp.data.result[item])[0]})
        }
        sorting(affiliatelist,'label',false,true)
        return affiliatelist
    })
    .catch(err => {
        console.error(err);
        return []
    })
};

export const getAffiliatesTags = async(allRequired) => {
    return await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"affiliate/tags/details"),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
    })
    .then(resp => {
        let affiliatelist = []
        if(allRequired){
            affiliatelist.push({label:"All",value:null})
        }
        for (var item in resp.data.result){
            affiliatelist.push({label:resp.data.result[item],value:resp.data.result[item]})
        }
        sorting(affiliatelist,'label',false,true)
        return affiliatelist
    })
    .catch(err => {
        console.error(err);
        return []
    })
};
export const getManager = async(allRequired) => {
    return await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"manager/details"),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
    })
    .then(resp => {
        let managerlist = []
        if(allRequired){
            managerlist.push({label:"All",value:null})
        }
        for (var item in resp.data.result){
            managerlist.push({label:resp.data.result[item][Object.keys(resp.data.result[item])[0]] + " - " + Object.keys(resp.data.result[item])[0],value:Object.keys(resp.data.result[item])[0]})
        }
        sorting(managerlist,'label',false,true)
        return managerlist
    })
    .catch(err => {
        console.error(err);
        return []
    })
};

export const getDemandSourcePlatforms = async(allRequired,newPlatforms=[]) => {
    return await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"platform","/","details"),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
    })
    .then(resp => {
        
        let platformlist = []
        if(allRequired){
            platformlist.push({label:"All",value:null})
            }
        for (var item in resp.data.result){
            var isNew = false
            if(newPlatforms.includes(Object.keys(resp.data.result[item])[0])){
                isNew = '1'
            }
            platformlist.push({label:resp.data.result[item][Object.keys(resp.data.result[item])[0]],value:Object.keys(resp.data.result[item])[0],isNew})
        }
        sorting(platformlist,'label',false,true)
        // sorting(platformlist,'isNew',false,false)
        return platformlist
    })
    .catch(err => {
        console.error(err);
        return []
    })
};


export const getSmartlinks = async(allRequired) => {
    return await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"smartlinks/details"),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
    })
    .then(resp => {
        let managerlist = []
        if(allRequired){
            managerlist.push({label:"All",value:null})
        }
        for (var item in resp.data.result){
            managerlist.push({label:resp.data.result[item],value:item})
        }
        sorting(managerlist,'label',false,true)
        return managerlist
    })
    .catch(err => {
        console.error(err);
        return []
    })
};


export const getUserLogsTypes = async(allRequired) => {
    return await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"object/type/details"),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
    })
    .then(resp => {
        let managerlist = []
        if(allRequired){
            managerlist.push({label:"All",value:null})
        }
        for (var item in resp.data.result){
            managerlist.push({label:resp.data.result[item],value:item})
        }
        sorting(managerlist,'label',false,true)
        return managerlist
    })
    .catch(err => {
        console.error(err);
        return []
    })
};


export const getBillingAdvertisersTag = async() => {
    return await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'genericoptions?key=billing_advertiser_tags'),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
    })
    .then(resp => {
        var list_ = resp.data.result.map((item,index)=>{ return {label:item,value:item}})
        sortingV2(list_,'label',true)
        return list_;
    })
    .catch(err => {
        console.error(err);
        return []
    })
};