import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import axios from 'axios';
import { Button, CircularProgress, Tooltip } from '@mui/material';

import { getManager, getUserLogsTypes } from '../../apis/dropdown/dropdowns';
import { increase_brightness } from '../../components/customization/customization';

import { DateRangePicker } from 'react-date-range';
import TableModal from '../../components/tableModal/tableModal';
import '../../css/pages/v2/advertiser.css';
import * as Constants from '../../data/constants';
import { getLocalStorageValue, getRequests } from '../../util/util';
import PaginationComponent from '../../components/pagination/pagination';
import '../../css/pages/v2/userlogs.css';

const UserLogs = () => {
    document.title = 'Manager Logs'
    var tempLimit = 50
    var tempPage = 1
    var tempAdvId = ''
    var tempContactName = ''
    var tempManager = ''

    var newUrl = new URL(window.location.href)
    if(window.location.href.includes("?")){
        if(newUrl.searchParams.get('limit')){
            tempLimit = parseInt(newUrl.searchParams.get('limit'))
        }
        if(newUrl.searchParams.get('page')){
            tempPage = parseInt(newUrl.searchParams.get('page'))
        }
        if(newUrl.searchParams.get('id')){
            tempAdvId = newUrl.searchParams.get('id')
            tempAdvId = {label:tempAdvId.split(" - ")[0],value:tempAdvId.split(" - ")[1]}
        }
        if(newUrl.searchParams.get('name')){
            tempContactName = newUrl.searchParams.get('name')
        }
        if(newUrl.searchParams.get('manager')){
            tempManager = newUrl.searchParams.get('manager')
            tempManager = {label:tempManager.split(" - ")[0] + " - " + tempManager.split(" - ")[1],value:tempManager.split(" - ")[1]}
        }
    }

    const [manualUseEffect, setManualUseEffect] = useState(false)
    const [managerOptions, setManagerOptions] = useState([])
    const [logTypes, setTypes] = useState([])
    const [advertiserData, setAdvertiserData] = useState([])
    const [advertisersTotalCount, setAdvertisersTotalCount] = useState(0)
    const [loader, setLoader] = useState(false)
    
    const [page, setPage] = useState(tempPage)
    const [limit, setLimit] = useState(tempLimit)
    
    var resetSelection = {
        "startDate": new Date(new Date().getTime()-86400000*6),
        "endDate": new Date(),
        "key": "selection"
    }
    
    if(newUrl.searchParams.get('dateRange')){
        var startDate = new Date(newUrl.searchParams.get('dateRange').split("_")[0])
        var endDate = new Date(newUrl.searchParams.get('dateRange').split("_")[1])
    }
    else{
        startDate = resetSelection.startDate
        endDate = resetSelection.endDate
    }
    const [dateRange, setDateRange] = useState([startDate,endDate])
    const [dateRangeState, setDateRangeState] = useState([{
        startDate: new Date(dateRange[0]),
        endDate: new Date(dateRange[1]),
        key: 'selection'
    }])
    var dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric'};

    const [filterSearch, setFilterSearch] = useState({id:'',
                                                    logs_type:tempAdvId ? tempAdvId : '',
                                                    adv_email:tempContactName,
                                                    manager:tempManager ? tempManager : '',
                                                    daterange:startDate.toLocaleDateString("sv-SE", dateOptions) +" -> "+endDate.toLocaleDateString("sv-SE", dateOptions)
                                                })
    const [showDatePicker, setShowDatePicker] = useState(false)

    const dateRangePickedMethod = (item) => {
        setDateRangeState([item.selection]);
        if (item.selection.endDate !== item.selection.startDate){
            // setShowDatePicker(false)
            var value = [item.selection.startDate,item.selection.endDate]
            if (value[0] && value[1]){
                setDateRange(value)

                startDate = new Date(value[0]).toLocaleDateString("sv-SE", dateOptions)
                endDate = new Date(value[1]).toLocaleDateString("sv-SE", dateOptions)
                setFilterSearch({...filterSearch,daterange:startDate+" -> "+endDate})
                setShowDatePicker(false)
                }
        }
    }

    var changeUrlParams = (obj) => {
        var str = [];
        for(var p in obj){
            if(obj[p]){
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        }
        var paramString = str.join("&");
        var url = window.location.pathname
        url+= '?'+paramString
        window.history.pushState('Advertisers', 'Advertisers', url);
    }
    const showAdvertiserRequest = async(manualLimit,manualPage) => {
        /// FOR URL 

        var dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric'};
        var startDate = new Date(dateRange[0]).toLocaleDateString("sv-SE", dateOptions)
        var endDate = new Date(dateRange[1]).toLocaleDateString("sv-SE", dateOptions)

        var urlParams = {
            limit:manualLimit ? manualLimit : limit,
            page:manualPage ? manualPage : page,
            id : filterSearch.logs_type ? filterSearch.logs_type.label +' - '+filterSearch.logs_type.value : null,  //Search
            name : filterSearch.adv_email ? filterSearch.adv_email.toLowerCase() : null,  //Search
            manager : filterSearch.manager ? filterSearch.manager.label +' - '+filterSearch.manager.value : null,  //Search
        }
        changeUrlParams(urlParams)

        var params = {
            employee_id : filterSearch.manager ? filterSearch.manager.value : null,  //Search
            type : filterSearch.logs_type ? filterSearch.logs_type.value : null,  //Search
            startDate,
            endDate,
            limit:manualLimit ? manualLimit : limit,
            page:manualPage ? manualPage : page,
        }
        setLoader(true)
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'logs/view'),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params
        }
        )
        .then((resp) => {
            setAdvertiserData(resp.data.result)
            setAdvertisersTotalCount(resp.data.count)
            setLoader(false)
        })
        .catch(function (error) {
            setLoader(false)
            if(error.response.data){
                setAdvertiserData("errorFromAxios"+error.response.data.result)
            }
        });
    };


    if(!manualUseEffect){
        setManualUseEffect(true)
        getRequests(getManager(),setManagerOptions)
        getRequests(getUserLogsTypes(),setTypes)
        showAdvertiserRequest()
    }
    var filterOptions = [
        // {id:'id',label:'Id',type:'textfield',value:filterSearch['id'],setValue:setFilterSearch,minWidth:'70px'},
        {id:'daterange',label:'Date Range',type:'daterange',value:filterSearch['daterange'],setValue:setFilterSearch,options:managerOptions,minWidth:170},
        {id:'manager',label:'User',type:'select',value:filterSearch['manager'],setValue:setFilterSearch,options:managerOptions,minWidth:170},
        {id:'logs_type',label:'Type',type:'select',value:filterSearch['logs_type'],setValue:setFilterSearch,options:logTypes,minWidth:220,},
    ]
    useEffect(() => {
        document.addEventListener('keydown',(e)=>{
            if(e.key==='Escape'){
                setShowDetailState(false);
            }
        })
        
        return () => {
            document.removeEventListener('keydown',(e)=>{
                if(e.key==='Escape'){
                    setShowDetailState(false);
                }
            })
        }
    }, [])
    
    const [showDetailState, setShowDetailState] = useState(false)
    const detailsComponent = (value,tableHeadIndex,item,index,childItems) => {
        return <Tooltip title={'Click to show more details'}>
                    <div className='overflowText' style={{cursor:'pointer'}} onClick={()=>{childItems.setShowDetailState(index);}}>
                        {Object.keys(value).toString()}
                    </div>
               </Tooltip>
    }


    var tempTableHeaders = [
        {id:'created_at',label:'Date',value:'created_at',width:'20%',minWidth:100},
        {id:'employee_id',label:'User',value:'employee_id',width:'8%',minWidth:60,},
        {id:'object_title',label:'Changed Component',value:'object_title',width:'15%',minWidth:60,},
        {id:'object_id',label:'Component ID',value:'object_id',width:'10%',minWidth:60,},
        {id:'type',label:'Type',value:'type',width:'15%',minWidth:60,},
        {id:'updated_value',label:'Details Change',value:'updated_value',width:'27%',minWidth:80,alignHead:'left',alignValue:'left',customComponent:detailsComponent},
        {id:'user_type',label:'Manager Type',value:'user_type',hide:false,width:'10%',minWidth:100},
        {id:'ip',label:'Manager IP',value:'ip',hide:false,width:'10%',minWidth:100},
        {id:'user_agent',label:'Manager User Agent',value:'user_agent',hide:false,width:'10%',minWidth:100},
    ]

    if(getLocalStorageValue(window.location.pathname)){
        try{
            var hideShowColumns = getLocalStorageValue(window.location.pathname)
            for(var index in tempTableHeaders){
                tempTableHeaders[index].hide = hideShowColumns[index].hide
            }
        }
        catch{

        }
    }
    const [tableHeaders, setTableHeaders] = useState(tempTableHeaders)
    
    // const gridComponent = () => {
    //     return advertiserData.map((item,index)=>{
    //         return <div className='gridComponentAdvertiser'>

    //         </div>
    //     })
    // }

    return (
        <div className='advertiserPageV2' style={{position:'relative'}} onClick={()=>{setShowDatePicker(false)}}>
            <div className='containerFirstRow'>
                <div> Found {advertisersTotalCount || advertisersTotalCount===0 ? advertisersTotalCount : <CircularProgress sx={{color:localStorage.getItem('ambientColor'),paddingLeft:'5px',paddingRight:'5px'}} size={13}/>} </div>
            </div>
            <div className='containerSecondRow'  style={{position:'relative',zIndex:10}}>
                {filterOptions.map((filterItem,filterIndex)=>{
                    if(filterItem.type==="daterange"){
                        return <div key={filterIndex} className='statsV2DateRangeContainer' style={{marginRight:'15px',zIndex:1}}  onClick={(e)=>{e.stopPropagation()}}>
                                    <div className='statsV2FilterLabel' style={{marginTop:'6px'}}>{filterItem.label}</div>
                                    <Constants.CssTextField size='small' value={filterItem.value} style={{width:'200px'}} onClick={()=>{setShowDatePicker(true)}}/>
                                    <div style={{position:'absolute'}}>
                                        {showDatePicker && <DateRangePicker
                                            onChange={item => dateRangePickedMethod(item)}
                                            showSelectionPreview={true}
                                            moveRangeOnFirstSelection={false}
                                            months={2}
                                            preventSnapRefocus={true}
                                            rangeColors={[localStorage.getItem('ambientColor')]}
                                            ranges={dateRangeState}
                                            showDateDisplay={false}
                                            scroll={{ enabled: window.innerWidth<800 ? true : false }}
                                            direction={window.innerWidth<800 ? "vertical" : "horizontal"}
                                            calendarFocus="backwards"
                                            />
                                        }
                                    </div>
                                </div>
                    }
                    if(filterItem.type==='textfield'){
                        return <div key={filterIndex} style={{width:(filterItem.minWidth ? filterItem.minWidth : 'max-content'),zIndex:0,marginRight:'15px',marginTop:20}}>
                                <Constants.CssTextField 
                                    size='small' 
                                    disabled={filterItem.disable} 
                                    fullWidth 
                                    value={filterSearch[filterItem.id]} 
                                    placeholder={filterItem.label}
                                    onChange={(e)=>{setFilterSearch({...filterSearch,[filterItem.id]:e.target.value})}} 
                                    onKeyDown={(e)=>{if(e.key==='Enter' && !loader){showAdvertiserRequest(limit,1)}}}
                                />
                            </div>
                    }
                    else if(filterItem.type==='select'){
                        return <div key={filterIndex} style={{width:(filterItem.minWidth ? filterItem.minWidth : 'max-content'),zIndex:0,marginRight:'15px',marginTop:20}}>
                            <ReactSelect
                                isClearable
                                isDisabled={filterItem.disable}
                                key={filterIndex} 
                                options={filterItem.options}
                                value={filterSearch[filterItem.id]}
                                onChange={(e)=>{setFilterSearch({...filterSearch,[filterItem.id]:e})}}
                                menuPortalTarget={document.body} 
                                placeholder={"Select "+filterItem.label}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 1000000 }),
                                            control: (base,state) => ({
                                                ...base,
                                                // maxHeight: 100,
                                                minHeight: 30,
                                                color:'rgb(200,200,200)',
                                                overflowY: "auto",
                                                fontSize:14,
                                                border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                                '&:hover': {
                                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                    boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                                }
                                            }),
                                            placeholder: (defaultStyles) => {
                                                return {
                                                    ...defaultStyles,
                                                    color: 'rgb(150,150,150)',
                                                    marginTop:'-2px'
                                                }
                                            },
                                            option: (styles, {isFocused, isSelected}) => ({
                                                ...styles,
                                                fontSize:'11px',
                                                background: isFocused
                                                    ? localStorage.getItem('ambientColor')
                                                    : isSelected
                                                        ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                        : undefined,
                                                zIndex: 1
                                            }),
                                        }}
                            />
                        </div>
                    }
                    return null;
                })}
                <Button disabled={loader ? true : false} sx={{...Constants.CONTAINEDBUTTON,minHeight:'40px',fontSize:'15px',minWidth:'80px',paddingLeft:'15px',paddingRight:'15px',marginTop:'20px',textTransform:'none',fontWeight:500,marginLeft:'15px'}} onClick={()=>{showAdvertiserRequest(limit,1)}}>
                    {loader && 
                    <CircularProgress sx={{color:'white'}} size={20}/>
                    }
                    {!loader && "Search"}
                </Button>
                <Button sx={{...Constants.OUTLINEDBUTTON,minHeight:'40px',fontSize:'15px',paddingLeft:'15px',paddingRight:'15px',marginTop:'20px',textTransform:'none',fontWeight:500,marginLeft:'15px'}} 
                    onClick={()=>{
                        var value = [resetSelection.startDate,resetSelection.endDate]
                        if (value[0] && value[1]){
                            setDateRange(value)
                            startDate = new Date(value[0]).toLocaleDateString("sv-SE", dateOptions)
                            endDate = new Date(value[1]).toLocaleDateString("sv-SE", dateOptions)
                            setFilterSearch({id:'',logs_type:'',adv_email:'',manager:'',daterange:startDate+" -> "+endDate})
                        }
                    }}>
                        Reset
            </Button>
            </div>
            <div style={{position:'relative',zIndex:9}}>
                
                <TableModal 
                    stickyColumn={[0]} 
                    // rightStickyColumnList={[tableHeaders.length-1]} 
                    showLoader={loader} tableHeaders={tableHeaders} 
                    setTableHeaders={setTableHeaders} 
                    tableValues={advertiserData} 
                    setTableValues={setAdvertiserData} 
                    hideTooltip={true} 
                    showCustomization={false} 
                    childItems={{showDetailState,
                        setShowDetailState
                    }}
                    // gridComponent={gridComponent}
                />

            </div>
            <PaginationComponent page={page} setPage={setPage} limit={limit} setLimit={setLimit} totalResults={advertisersTotalCount} apiRequest={showAdvertiserRequest} onClick={()=>{}}/>
            {(showDetailState || showDetailState===0) &&
                <div className='userlogsDetailPopupContainer' onClick={()=>{setShowDetailState(false);}}>
                    <div className='userlogsDetailPopupWrapper'  onClick={(e)=>{e.stopPropagation()}}>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',paddingTop:'10px',paddingBottom:'10px'}}>
                            <div style={{fontWeight:500,fontSize:'16px'}}>Detail Changes</div>
                            <div style={{cursor:'pointer'}} onClick={()=>{setShowDetailState(false);}}>X</div>
                        </div>
                        <div style={{maxHeight:'70vh',overflow:'auto'}}>
                            <table style={{tableLayout:'fixed',width:'100%'}}>
                                <thead style={{position:"sticky",top:0}}>
                                    <tr>
                                        <td style={{minWidth:'20%'}} className='userlogTableHeaders'>
                                            Key
                                        </td>
                                        <td width={'40%'} className='userlogTableHeaders'>
                                            Previous Value
                                        </td>
                                        <td width={'40%'} className='userlogTableHeaders'>
                                            Updated Value
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(advertiserData[showDetailState].prev_value).map((keyItem,keyIndex)=>{
                                        var previousValue = advertiserData[showDetailState].prev_value[keyItem]
                                        if(typeof previousValue === 'object'){
                                            previousValue = JSON.stringify(previousValue, null, 2)
                                        }
                                        var updatedValue = advertiserData[showDetailState].updated_value[keyItem]
                                        if(typeof updatedValue === 'object'){
                                            updatedValue = JSON.stringify(updatedValue, null, 2)
                                        }
                                        return <tr key={keyIndex} className='userlogTableValueRow'>
                                            <td className='userlogTableValues' style={{wordBreak:'break-all'}}>{keyItem}</td>
                                            <td className='userlogTableValues'>
                                                <pre className='updatedValuesPreviousValues'>
                                                    {previousValue}
                                                </pre>
                                            </td>
                                            <td className='userlogTableValues' style={{wordBreak:'break-all'}}>
                                                <pre className='updatedValuesPreviousValues'>
                                                    {updatedValue}
                                                </pre>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>

                            </table>
                        </div>

                        
                    </div>
                </div>
            }
        </div>
    )
}

export default UserLogs