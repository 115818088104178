import ReactApexChart  from 'react-apexcharts';

export const graphStatsBoxes = (label,type,data,graphType='line',showTooltip,color) => {
    var dates = data.dates
    var state = {
        
        series: [{
            name: label,
            data: data[type]
          }],
        options: {
          chart: {
            type: graphType,
            stacked: false,
            height: 350,
            zoom: {
              type: 'x',
              enabled: false,
              autoScaleYaxis: true
            },
            toolbar: {
              show: false
            }
          },
          dataLabels: {
            enabled: false
          },
          grid:{
              show:false
          },
          colors: [color],
          markers: {
            size: 0,
          },

        yaxis: {
            show:false,
            min:Math.min(0),
            max:Math.max(...data[type]),
            labels:{
              formatter: (value) => { return value.toFixed(type==='clicks' || type==='conversions' ? 0 : 2) },
            },
        },
          xaxis: {
            crosshairs:{
                show:false,
            },  
            tooltip:{
                enabled:false
            },
            labels:{
                  show:false,
            },
            axisBorder:{
                show:false,
            },
            axisTicks:{
                show:false,
            },
            categories: dates,
            type: 'datetime',
          },
          tooltip: {
            enabled:showTooltip,
            shared: false,
          },
        },
      
      
    };

    return(
        <ReactApexChart className="graphs" options={state.options} series={state.series} type={graphType} height={"100%"} width={"100%"}/>
    )
}


export const summaryGraph = (data) => {
    
    var dates = data.dates
    
    var state = {
        
        series: [
            
            {
                name: 'Conversions',
                type:"line",
                data: data.conversions
            },
            {
                name: 'Clicks',
                type:"line",
                data: data.clicks
            },
            {
                name: 'Delivered Revenue',
                type:"column",
                data: data.revenue
            },
            {
              name: 'Declined Revenue',
              type:"line",
              data: data.declined
            },
            {
                name: 'Payout',
                type:"column",
                data: data.payout
            },
            {
                name: 'Earnings',
                type:"column",
                data: data.earnings
            },
        ],
        options: {
            plotOptions: {
                bar: {
                  columnWidth: "50%"
                }
              },
              chart: {
                height: 350,
                width: 850,
                type: 'line',
                  dropShadow: {
                    enabled: false,
                    color: '#000',
                    top: 18,
                    left: 7,
                    blur: 0,
                    opacity: 1
                  },
                  toolbar: {
                    show: false
                  },
                  zoom: {
                    enabled: false,
                  }
                },
              colors: ['rgb(206, 134, 0)','#0b1d78','#0069c0','rgb(255,100,100)',"#00a9b5","#1fe074"],
              dataLabels: {
                enabled: false,
              },
              grid:{
                show:true
              },
              markers: {
                  size: 4
                },
    
              stroke:{
                width:3,
                curve: 'smooth',
              },
            yaxis: [
                { 
                    min:0,
                    max:Math.max(...data.conversions),
                    show:false,
                    tickAmount: 5,
                    opposite: true,
                    axisBorder:{
                        show:false,
                    },
                    axisTicks:{
                        show:false,
                    },
                    labels:{
                        formatter: (value) => { return parseInt(value) },
                    },
                    // logarithmic: true,
                    // logBase:2,
                    seriesName: "Conversions",
                    // labels: {
                    //   formatter: labelFormatter
                    // }
                },
                { 
                    min:0,
                    max:Math.max(...data.clicks),
                    show:false,
                    tickAmount: 5,
                    axisBorder:{
                        show:false,
                    },
                    axisTicks:{
                        show:false,
                    },
                    labels:{
                        formatter: (value) => { return parseInt(value) },
                    },
                    opposite: false,
                    logarithmic: false,
                    // logBase:10,
                    seriesName: "Clicks",
                    // labels: {
                    //   formatter: labelFormatter,
                    // }
                },
                { 
                    min:0,
                    max:Math.max(...data.revenue),
                    show:false,
                    opposite: true,
                    tickAmount: 5,
                    axisBorder:{
                        show:false,
                    },
                    axisTicks:{
                        show:false,
                    },
                    // logarithmic: true,
                    // logBase:2,
                    seriesName: "Revenue",
                    labels: {
                        formatter: (value) => { return parseFloat(value).toFixed(2) },
                    }
                },
                { 
                  min:0,
                  max:Math.max(...data.revenue),
                  show:false,
                  opposite: true,
                  tickAmount: 5,
                  axisBorder:{
                      show:false,
                  },
                  axisTicks:{
                      show:false,
                  },
                  // logarithmic: true,
                  // logBase:2,
                  seriesName: "Payout",
                  labels: {
                      formatter: (value,index) => { return parseFloat(value).toFixed(2) },
                  }
                },
                { 
                  min:0,
                  max:Math.max(...data.revenue),
                  show:false,
                  opposite: true,
                  tickAmount: 5,
                  axisBorder:{
                      show:false,
                  },
                  axisTicks:{
                      show:false,
                  },
                  // logarithmic: true,
                  // logBase:2,
                  seriesName: "Payout",
                  labels: {
                      formatter: (value,index) => { return parseFloat(value).toFixed(2) },
                  }
                },
                { 
                    min:0,
                    max:Math.max(...data.revenue),
                    show:false,
                    opposite: true,
                    tickAmount: 5,
                    axisBorder:{
                        show:false,
                    },
                    axisTicks:{
                        show:false,
                    },
                    // logarithmic: true,
                    // logBase:2,
                    seriesName: "Earnings",
                    labels: {
                        formatter: (value) => { return parseFloat(value).toFixed(2) },
                    }
                },
              ],
            xaxis: {
                crosshairs:{
                    show:false,
                },  
                labels: {
                formatter: function(value, timestamp, opts) {
                    var dateOptions = { month: 'short', day: 'numeric'};
                    return new Date(value).toLocaleDateString('en-US',dateOptions)
                    // return opts.dateFormatter(new Date(value)).format("dd MMM")
                }
                },
                // type: ',
                tickAmount: 'dataPoints',
                categories:dates,
                forceNiceScale:true,
            },
            tooltip: {
                enabled:true,
                shared: true,
            },
            legend: {
              formatter: function(seriesName, opts) {
                  
                  return [seriesName, " (",  opts.w.globals.series[opts.seriesIndex].reduce((a, b) => a + b, 0).toFixed(opts.seriesIndex===0 || opts.seriesIndex===1 ? 0 : 2),")"]
              }
            },
        },
      
      
    };

    return(
        <ReactApexChart className="graphs" options={state.options} series={state.series} height={"100%"} width={"100%"}/>
    )
}