import React from 'react';
import '../../css/dashboard.css';
import axios from 'axios';
import { useState } from 'react';
import Button from '@mui/material/Button';
import * as Constants from '../../data/constants';
import Select, { components} from 'react-select';
import {getLocalStorageValue, sorting} from '../../util/util';
import { increase_brightness } from '../customization/customization';
import { loginAffiliateApi } from '../../apis/dropdown/loginAffiliate';

export const LoginAffiliate = () => {
    const [manualUseEffect,setManualUseEffect] = React.useState(false);
    const [error,setError] = useState("");
    const [affiliateId,setAffiliateId] = useState("");
    const [affiliateList,setAffiliateList] = useState([]);

    const loginUserButtonClicked = () => {
      loginAffiliateApi(affiliateId.value)
      setError("")
    }

    const getAffiliates = async() => {
      if (affiliateList.length===0){
        axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"affiliate/details"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        })
        .then(resp => {
            var affiliatelist = [];
            for (var affiliate in resp.data.result){
                affiliatelist.push({label:resp.data.result[affiliate][Object.keys(resp.data.result[affiliate])[0]] +" - " + Object.keys(resp.data.result[affiliate])[0] ,value:Object.keys(resp.data.result[affiliate])[0]});
            }
            sorting(affiliatelist,'label',false,true)
            setAffiliateList(affiliatelist);
        })
        .catch(err => {
            console.error(err);
        }) 
      }
  };   
  
    if(error !== "" ){
      console.log(error)
    }

    if (!manualUseEffect){
        setManualUseEffect(true);
        getAffiliates();
    }

    const NoOptionsMessage = props => {
      return (
        <components.NoOptionsMessage {...props}>
          <span>No Affiliate Found.</span> 
        </components.NoOptionsMessage>
      );
    };

    return (
        <div style={{display:"flex",alignItems:"center",width:'80%',transform:'scale(0.9)',justifyContent:"center",marginLeft:"5%",marginRight:"5%"}}>  
            <div style={{fontSize:"12px",minWidth:"80%"}}>
              <Select 
                    // defaultValue={}
                    placeholder={'Affiliate Login'}
                    components={{ NoOptionsMessage }}
                    onChange={(e)=>{setAffiliateId(e)}}
                    options={affiliateList}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 20000000 }),
                                                    control: (base,state) => ({
                                                        ...base,
                                                        // maxHeight: 100,
                                                        minHeight: 30,
                                                        overflowY: "auto",
                                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid grey',
                                                        boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                                        '&:hover': {
                                                            border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                            boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                                        }
                                                    }),
                                                    option: (styles, {isFocused, isSelected}) => ({
                                                        ...styles,
                                                        fontSize:'11px',
                                                        background: isFocused
                                                            ? localStorage.getItem('ambientColor')
                                                            : isSelected
                                                                ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                : undefined,
                                                        zIndex: 1
                                                    }),
                                                }}
                      />
              </div>
                &nbsp;
                
            <Button variant="contained" sx={{...Constants.CONTAINEDBUTTON,fontSize:'12px'}} onClick={loginUserButtonClicked}  onTouchStart={loginUserButtonClicked} disabled={affiliateId==="" ? true : false} color="warning">Login</Button>
        </div>
    )
}

export default LoginAffiliate;

