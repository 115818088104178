import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DateRangePicker } from 'react-date-range';
import { createGlobalStyle } from 'styled-components';

import { Button } from '@mui/material';
import TableModal from '../../components/tableModal/tableModal';
import * as Constants from '../../data/constants';

import '../../css/pages/v2/manualRecheck.css';
import '../../css/pages/v2/advertiser.css';
import { capitalize, getLocalStorageValue, getRequests } from '../../util/util';
import CreatableSelect from 'react-select/creatable';
import PaginationComponent from '../../components/pagination/pagination';
import ReactSelect from 'react-select';
import { getManager } from '../../apis/dropdown/dropdowns';

const DatePickerWrapperStyles = createGlobalStyle`
    .rdrDefinedRangesWrapper {
        display: none;
    }
`;
const ManualRecheck = () => {
    
    document.title = 'Manual Recheck'

    const [manualUseEffect, setManualUseEffect] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [advertiserData, setAdvertiserData] = useState([])
    const [showRedirectionStatus, setShowRedirectionStatus] = useState(false)
    const [offerIdsSelected, setOfferIdsSelected] = useState([])
    const [response, setResponse] = useState([])

    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const [totalCount, setTotalCount] = useState(0)


    const [dateRangeState, setDateRangeState] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }])
    const [showDatePicker, setShowDatePicker] = useState(false)
    const [managerSelected, setManagerSelected] = useState('')

    const dateRangePickedMethod = (item) => {
        setDateRangeState([item.selection]);
        setShowDatePicker(false)
    }
    const getManualRecheck = async(manual_limit,manual_page) => {
        var offerIdString = ''
        for(var index in offerIdsSelected){
            offerIdString += offerIdsSelected[index].value + ","
        }
        if(offerIdString!==''){
            offerIdString = offerIdString.slice(0,-1)
        }
        setShowLoader(true);
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'recheckstatus'),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                date:dateRangeState[0].startDate.toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
                offer_id : offerIdString ? offerIdString : null,
                page : manual_page ? manual_page : page,
                limit : manual_limit ? manual_limit : limit,
                employee_id : managerSelected ? managerSelected.value : null
            }
        }
        )
        .then((resp) => {        
            setAdvertiserData(resp.data.result)
            setShowLoader(false);
            setTotalCount(resp.data.count)

        })
        .catch(function (error) {
            setShowLoader(false);
            if(error.response.data){
                console.log(error.response.data)
                setAdvertiserData("errorFromAxios"+error.response.data.result)
            }
            if (error.response) {
              // Request made and server responded
              console.log(error.response.status);
              console.log(error.response.headers);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
        
          });
    };

    const getRedirectionStatus = async(item) => {
        var offer_id = item.offer_id
        var country = item.country
        var dateRanges = []
        for(var index in item.status){
            var date = new Date(item.status[index].time).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })
            if(!dateRanges.includes(date)){
                dateRanges.push(date)
            }
        }
        dateRanges.reverse()
        setShowRedirectionStatus({item,loader:true})
        var responseList = []
        for(index in dateRanges){
            
            var response = await axios({
                method: 'GET',
                url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'redirectionstatus'),
                headers:{
                    Authorization:getLocalStorageValue('adminPanelLoginData').token
                },
                params:{
                    date:dateRanges[index],
                    offer_id,
                    country
                }
                }
                )
                .then((resp) => {       
                    return resp.data.result
                })
                .catch(function (error) {
                    if (error.response) {
                    // Request made and server responded
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    }
                
                })
            responseList = [...responseList,...response]
        }
        setResponse(responseList)
        setShowRedirectionStatus({item,loader:false,response:responseList})
    };
    var isSuperUser = getLocalStorageValue('adminPanelLoginData').superuser
    const [managerOptions, setManagerOptions] = useState([])
    if(!manualUseEffect){
        setManualUseEffect(true)
        getManualRecheck();
        if(isSuperUser){
            getRequests(getManager(),setManagerOptions)
        }
    }
    var offerId = (value) => {
        return <a href={'/offers/view/'+value}
                    target='_blank'
                    rel='noreferrer'
                    style={{
                            color:localStorage.getItem('ambientColor'),
                            textDecoration:'none'
                            }}
                    >
            {value}
        </a> 
    }
    var offerStatus = (value) => {
        return <div className='manualRecheckOfferStatusTable'
                    style={{
                            color:(value==='active' ? 'green' : value === 'paused' ? 'orange' : 'red'),
                            }}
                    >
            {capitalize(value)}
        </div> 
    }

    var offerType = (value) => {
        return <div className='manualRecheckOfferStatusTable'>
            {capitalize(value)}
        </div> 
    }
    var manualRecheckData = (value,colIndex,item) => {
        var dateOptions = { hourCycle: 'h23', month: 'short', day: 'numeric' ,hour:'numeric',minute:'numeric',second:'numeric'};
        var divideChunks = []
        const chunkSize = 3;
        for (let i = 0; i < Object.keys(value).length; i += chunkSize) {
            const chunk = Object.keys(value).slice(i, i + chunkSize);
            divideChunks.push(
                <td style={{textAlign:'left'}}>
                    {chunk.map((item,index)=>{
                        var timevalue = new Date(value[item].time).toLocaleDateString("en-US", dateOptions)
                        return <div key={index} className='manualRecheckDataTable' style={{color:(value[item].status==='working' ? 'green' : 'red'),background:(value[item].status==='working' ? 'rgb(226, 255, 226)' : 'rgb(255, 229, 229)'),border:(value[item].status==='working' ? '1px solid green' : '1px solid red')}}> 
                            {timevalue} - {value[item].status==='working' ? 'W' : 'NW'}
                        </div>
                    })}
                </td>
            )
        }
        return <div style={{marginBottom:'10px',display:'flex'}}>
            <table>
                <tbody>
                    <tr>
                        {divideChunks}
                    </tr>
                </tbody>
            </table>
        </div>
    }
    var tempTableHeaders = [
        {label:'Offer ID', value:'offer_id',width:'6%',customComponent:offerId},
        {label:'Offer Title', value:'title',width:'15%'},
        {label:'Advertiser', value:'advertiser',width:'8%'},
        {label:'Status', value:'offer_status',width:'8%',customComponent:offerStatus},
        {label:'Type', value:'offer_type',width:'6%',customComponent:offerType},
        {label:'Country', value:'country',width:'8%'},
        {label:'Started At', value:'created_at',width:'10%'},
        {label:'Data', value:'status',width:'100%',alignHead:'left',alignValue:'left',customComponent:manualRecheckData,onClickEvent : getRedirectionStatus},
    ]

    const showRedirectionDateAndTime = (value) => {
        var dateOptions = { hourCycle: 'h23', month: 'short', day: 'numeric' ,hour:'numeric',minute:'numeric',second:'numeric'};
        var timevalue = new Date(new Date(value).getTime()-19800000).toLocaleDateString("en-US", dateOptions)

        return <div>{timevalue}</div>
    }

    const showRecheckStatus = (value) => {
        return <div style={{color:value === 'not_working' ? 'red' : 'green'}}>{value==='not_working' ? "Not Working" : value === 'working' ? 'Working' : '-'}</div>
    }
    const showRedirectionUrlComponent = (value) => {
        return <table>
            <tbody>
            {value.map((item,index)=>{
                return <tr key={index}>
                    <td style={{textAlign:'left',marginBottom:'5px'}}>
                        {item[1]}
                    </td>
                    <td style={{textAlign:'left',whiteSpace:'pre-wrap',wordBreak:'break-all',wordWrap:'break-word',marginBottom:'5px'}}>
                        -
                    </td>
                    <td style={{textAlign:'left',whiteSpace:'pre-wrap',wordBreak:'break-all',wordWrap:'break-word',marginBottom:'5px'}}>
                        {item[0]}
                    </td>
                </tr>
            })}
            </tbody>
        </table>
    }

    var tempRedirectionTableHeaders = [
        {label:'Date And Time', value:'created_at',width:'10%',hour:24,customComponent:showRedirectionDateAndTime},
        {label:'Status', value:'recheck_status',width:'7%',hour:24,customComponent:showRecheckStatus},
        {label:'Redirection URL', value:'redirection_url',width:'70.9%',customComponent:showRedirectionUrlComponent},
    ]
    const [tableHeaders, setTableHeaders] = useState(tempTableHeaders)
    const [redirectionTableHeaders, setRedirectionTableHeaders] = useState(tempRedirectionTableHeaders)
    
    useEffect(() => {
        document.addEventListener('keydown',(e)=>{
            if(e.key==='Escape'){
                setShowRedirectionStatus(false)
            }
        })
        
    }, [setShowRedirectionStatus])
    
    return (
        <div className='mainContainerV2 advertiserPageV2'>
            
            <div style={{fontSize:'20px',marginLeft:'10px',marginTop:'10px',marginBottom:'20px',fontWeight:500}}>Manual Offer Recheck</div>
            <div className='containerSecondRow manualRecheckfirstRow'>
                <Constants.CssTextField label='Select Date' size='small' value={dateRangeState[0].startDate ? dateRangeState[0].startDate.toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }) : ''} style={{width:'200px'}} onClick={()=>{setShowDatePicker(true)}}/>
                <div style={{position:'absolute',zIndex:2,transform:'scale(1)',marginTop:'32%'}} onClick={(e)=>{e.stopPropagation()}}>
                    {showDatePicker && <DateRangePicker
                        onChange={item => dateRangePickedMethod(item)}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        preventSnapRefocus={true}
                        rangeColors={[localStorage.getItem('ambientColor')]}
                        ranges={dateRangeState}
                        showDateDisplay={false}
                        staticRanges={[]}
                        inputRanges={[]}
                        scroll={{ enabled: window.innerWidth<800 ? true : false }}
                        direction={window.innerWidth<800 ? "vertical" : "horizontal"}
                        calendarFocus="backwards"
                        />
                    }
                    <DatePickerWrapperStyles/>
                </div>
                <div style={{minWidth:'200px',marginLeft:'10px'}}>
                    <CreatableSelect
                        isClearable
                        isMulti
                        value={offerIdsSelected}
                        placeholder={'Offer Ids'}
                        onChange={(e)=>{setOfferIdsSelected(e)}}
                        menuPortalTarget={document.body} 
                        styles={{...Constants.ReactSelectStyles}}
                        />
                </div>
                {isSuperUser &&
                <div style={{minWidth:'200px',marginLeft:'10px'}}>
                    <ReactSelect
                        isClearable
                        options={managerOptions}
                        value={managerSelected}
                        placeholder={'Select Manager'}
                        onChange={(e)=>{setManagerSelected(e)}}
                        menuPortalTarget={document.body} 
                        styles={{...Constants.ReactSelectStyles}}
                        />
                </div>
                }
                <Button sx={{...Constants.CONTAINEDBUTTON,fontSize:'15px',paddingLeft:'15px',paddingRight:'15px',textTransform:'none',fontWeight:500,marginLeft:'15px'}} onClick={()=>{getManualRecheck(limit,1)}}>Search</Button>
                <Button sx={{...Constants.OUTLINEDBUTTON,fontSize:'15px',paddingLeft:'15px',paddingRight:'15px',textTransform:'none',fontWeight:500,marginLeft:'15px'}} onClick={()=>{setOfferIdsSelected([]);setManagerSelected('');}}>Reset</Button>
            </div>
            <TableModal showLoader={showLoader} hideTooltip={true} stickyColumn={[0,1,2]} tableHeaders={tableHeaders} setTableHeaders={setTableHeaders} tableValues={advertiserData} setTableValues={setAdvertiserData}/>
            <PaginationComponent page={page} setPage={setPage} limit={limit} setLimit={setLimit} totalResults={totalCount} apiRequest={getManualRecheck}/>
            {showRedirectionStatus && 
                <div className='redirectionDataPopup' onClick={(e)=>{e.stopPropagation();if(showRedirectionStatus){setShowRedirectionStatus(false);}}}>
                    <div className='redirectionDataPopupWrapper'onClick={(e)=>{e.stopPropagation();if(showRedirectionStatus){setShowRedirectionStatus(showRedirectionStatus);}}}>
                        <div className='redirectionDataPopupHeader'>
                            <div><b>{showRedirectionStatus.item ? showRedirectionStatus.item.offer_id + " - " + showRedirectionStatus.item.title : ""}</b></div>
                            <div style={{cursor:'pointer'}} onClick={(e)=>{e.stopPropagation();setShowRedirectionStatus(false);}}>X</div>
                        </div>
                        <div className='redirectionDataTable'>
                            <TableModal id='2' showLoader={showRedirectionStatus.loader} hideTooltip={true} stickyColumn={[0]} tableHeaders={redirectionTableHeaders} setTableHeaders={setRedirectionTableHeaders} tableValues={response} setTableValues={setResponse}/>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ManualRecheck