import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material'
import { NavLink } from 'react-router-dom';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: localStorage.getItem('ambientColor'),
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: localStorage.getItem('ambientColor'),
    },
    '& .MuiOutlinedInput-root': {
        backgroundColor: "white !important",
        fontSize: "14px !important",
      '& fieldset': {
        borderColor: 'rgb(200,200,200)',
        // backgroundColor:'white',
        // zIndex:'0',
      },
      '&:hover fieldset': {
        borderColor: localStorage.getItem('ambientColor'),
      },
      '&.Mui-focused fieldset': {
        borderColor: localStorage.getItem('ambientColor'),
      },
    },
  });

const QuickLinks = () => {
    var quickLinksDict = { 
        "Stats Daily" : "/statistics/daily",
        "Stats Offer" : "/statistics/offer",
        "Stats Advertiser" : "/statistics/adv",
        "Stats Adv Manager" : "/statistics/adv-manager",
        "Stats Affiliate" : "/statistics/aff",
        "Stats Aff Manager" : "/statistics/aff-manager",
        "Stats Conversion" : "/statistics/conversions",
        "Stats Failed Postback" : "/statistics/failed/postback",
        "Stats Trafficback" : "/statistics/trafficback",
        "Add an Offer" :  "/offers/add",
        "View Offer": "/offers",
        "View Advertiser" : "/advertiser",
        "Add an Advertiser" : "/advertiser/add",
        "View an Affiliate" : "/affiliates/management",
        "Add an Affiliate" : "/affiliates/management/add",
        "Testing Link" : "/affiliates/testinglink",
        "View Demand Source" : "/demandsource/management",
        "Add a Demand Source" : "/demandsource/management/add",
        "View Employee" : "/account/employees",
        "Add an Employee" : "/account/employees/add",
        "Export Download" : "/exportData",
    }
    var topPriorityQuickLinks = {
        "View Daily Stats" : "/statistics/daily",
        "View Offer Wise Stats" : "/statistics/offer",
        "Add an Offer" : '/offers/add',
        "View Offers" : '/offers',
        "Add an Advertiser" : '/advertiser/add',
        "Add a Demand Source" : '/demandsource/management/add',
    }
    const [quickLinks, setQuickLinks] = useState(topPriorityQuickLinks)

    const quickLinksSearched = (e) => {
        var tempSearchedQuickLinks = {}
        for(var index in quickLinksDict){
            if(index.toLowerCase().includes(e.target.value.toLowerCase())){
                tempSearchedQuickLinks[index] = quickLinksDict[index]
            }
        }
        if(e.target.value===""){
            tempSearchedQuickLinks = topPriorityQuickLinks
        }
        setQuickLinks(tempSearchedQuickLinks)
    }

    return (
        <div style={{paddingTop:'10px',width:'90%'}}>
            <CssTextField size='small' placeholder='Quick Link' fullWidth sx={{marginBottom:'20px'}} onChange={(e)=>{quickLinksSearched(e)}}/>
            {Object.keys(quickLinks).map((item,index)=>{
                return <div key={index}>
                        <NavLink
                            to={quickLinks[item]}
                            style={{fontSize:'14px',paddingTop:'4px',textDecoration:'none',color:'blue'}}
                        >
                            {item}
                        </NavLink>
                    </div>
            })}
            {Object.keys(quickLinks).length===0 && 
            <div style={{fontSize:'14px',paddingTop:'4px'}}>
                No Result Found.
            </div>}
        </div>
    )
}

export default QuickLinks