import React from 'react'

import Zoom from '@mui/material/Zoom';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

const CopyButton = (props) => {
    const [showTooltip,setShowToolTip] = React.useState(false);

    const handleCopyContent = () => {
        var value = ''
        if(props.idToCopy){
            if(document.getElementById(props.idToCopy).value){
                value = document.getElementById(props.idToCopy).value
            }
            else if(document.getElementById(props.idToCopy).innerText){
                value = document.getElementById(props.idToCopy).innerText
            }
        }
        if(props.valueToCopy){
            value = props.valueToCopy
        }
        navigator.clipboard.writeText(value)
        setShowToolTip(true)
    }
    return (
        <IconButton aria-label="delete" onClick={handleCopyContent} onMouseLeave={()=>{setShowToolTip(false);}} >
            
            <Tooltip  TransitionComponent={Zoom} title={showTooltip ? 'Copied' : 'Copy'}>
                <ContentCopyRoundedIcon/>
            </Tooltip>

        </IconButton>
    )
}

export default CopyButton