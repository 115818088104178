import React, { useEffect, useState } from 'react'
import './advertiserBilling.css';
import ReactSelect from 'react-select';
import { CssTextField, ReactSelectStyles } from '../../data/constants';
import { checkForPermission, getLocalStorageValue, getRequests, isSuperUser, setLocalStorageValue, sortingV2, useForceUpdate, zeroPad } from '../../util/util';
import { getAdvertisers, getManager } from '../../apis/dropdown/dropdowns';
import { CONTAINEDBUTTON } from '../../data/constants';
import { Button, IconButton, Pagination } from '@mui/material';
import TableModel from '../../components/tableModel/tablemodel';
import axios from 'axios';
import * as Constants from '../../data/constants';
import Tooltip from '@mui/material/Tooltip';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import ShowLoaderComponent from '../../components/loader';
import { CircularProgress } from '@mui/material'
import NoResultsFound from '../../components/noResultFound';
import SomeErrorOccured from '../../components/someErrorOccured';
import { Store } from 'react-notifications-component';
import { removeLocalStorageValue } from '../../util/util';
import { Close } from '@mui/icons-material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PeopleIcon from '@mui/icons-material/People';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import MissingOfferComponent from './missingOffer';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const AdvertiserBillingV2 = () => {
    

    document.title = 'Advertiser Billing'
    const refreshComponent = useForceUpdate();
    var todayDate = new Date()
    todayDate.setMonth(todayDate.getMonth() - 1);
    var lastMonthList = todayDate.toISOString().split('T')[0].split('-')
    var lastMonth = lastMonthList[0]+'-'+lastMonthList[1]
    lastMonth = [lastMonth,null]
    if(document.getElementsByClassName('mainWindowSidebarClose').length>0){
        document.getElementsByClassName('mainWindowSidebarClose')[0].style.background = 'white'
    }

    const queryParameters = new URLSearchParams(window.location.search)
    var tempMonthSelected = queryParameters.get('monthSelected')
    if(tempMonthSelected){
        if(tempMonthSelected.includes('_')){
            var tempMonthSelectedList = tempMonthSelected.split('_')
            if(tempMonthSelectedList[1]!=='undefined' && tempMonthSelectedList[1]!=='null'){
                tempMonthSelected = [new Date(tempMonthSelectedList[0]),new Date(tempMonthSelectedList[1])]
            }
            else{
                tempMonthSelected = [new Date(tempMonthSelectedList[0]),null]
            }
        }
        else{
            tempMonthSelected = new Date(tempMonthSelected)
            tempMonthSelected = [tempMonthSelected,null]
        }
    }

    var tempAdvertiserId = queryParameters.get('advertiser_id')
    var tempAdvertiserName = queryParameters.get('advertiser_name')
    var tempPackageName = queryParameters.get('package_name')
    var tempTeamName = queryParameters.get('team')
    var tempFilterData = {}

    var subteamMembers = {
        Team1:['Pushkal','Pawan','Nitesh'],
        Team2:['Udit','Umair','Divyanshu'],
        Team3:['Shikha','Harsh','Aniket'],
    }
    var isClearableTeam = true
    var teamOptions = [{label:'Team1',value:'Team1'},{label:'Team2',value:'Team2'},{label:'Team3',value:'Team3'}]
    if(checkForPermission('can_view_team1') && !isSuperUser()){
        subteamMembers = {
            Team1:['Pushkal','Pawan','Nitesh'],
        }
        teamOptions=[{label:'Team1',value:'Team1'}]
        tempTeamName = 'Team1'
        isClearableTeam = false
    }
    if(checkForPermission('can_view_team2') && !isSuperUser()){
        subteamMembers = {
            Team2:['Udit','Umair','Divyanshu'],
        }
        teamOptions=[{label:'Team2',value:'Team2'}]
        tempTeamName = 'Team2'
        isClearableTeam = false
    }
    if(checkForPermission('can_view_team3') && !isSuperUser()){
        subteamMembers = {
            Team3:['Shikha','Harsh','Aniket'],
        }
        teamOptions=[{label:'Team3',value:'Team3'}]
        tempTeamName = 'Team3'
        isClearableTeam = false
    }
    if(tempTeamName){
        tempFilterData['team'] = {value:tempTeamName,label:tempTeamName}
    }
    if(tempPackageName){
        tempFilterData['package_name'] = tempPackageName
    }
    if(tempAdvertiserId){
        tempFilterData['advertiser'] = {value:tempAdvertiserId,label:tempAdvertiserName}
    }

    const [monthSelected, setMonthSelected] = useState(tempMonthSelected ? tempMonthSelected : getLocalStorageValue('billingFilters') && getLocalStorageValue('billingFilters').monthSelected ? getLocalStorageValue('billingFilters').monthSelected : lastMonth)
    const [loader, setLoader] = useState({main:false})
    const [manualUseEffect, setManualUseEffect] = useState(false)
    const [optionsAdvertiserList, setOptionsAdvertiserList] = useState([])
    const [filterData, setFilterData] = useState(Object.keys(tempFilterData).length>0 ? tempFilterData : getLocalStorageValue('billingFilters') ? getLocalStorageValue('billingFilters') : {})
    const [optionsManagerList, setOptionsManagerList] = useState([])
    const [tableValues, setTableValues] = useState([])
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [totalResults, setTotalResults] = useState(50);
    const [totalRow, setTotalRow] = useState([]);
    const [tableValuesSelected,setTableValueSelected] = useState()
    const [tableValueSelectedUpdatedData,setTableValueSelectedUpdatedData] = useState({})
    const [updateDataLoader,setUpdateDataLoader] = useState(false)
    const [compareWithManual,setCompareWithManual] = useState(getLocalStorageValue('billingFilters') ? getLocalStorageValue('billingFilters').compare_with_manual ? true : false : false)
    const [noteMenuClicked,setNoteMenuClicked] = useState(false)
    const [noteMenuReadOnlyClicked,setNoteMenuReadOnlyClicked] = useState(false)
    const [showOnlyMissingsOffers,setShowOnlyMissingOffers] = useState(false)
    
    const [noteData,setNoteData] = useState({})
    const [sortingData,setSortingData] = useState(false)
    const [addMissingOfferPopup,setAddMissingOfferPopup] = useState(false)

    if(!manualUseEffect){
        setManualUseEffect(true)
        getRequests(getAdvertisers(),setOptionsAdvertiserList)
        getRequests(getManager(),setOptionsManagerList)
    }

    const getFromToMonth = () => {
        var date_temp = new Date(monthSelected[0]).toLocaleString('en-US',{year:'numeric',month:'numeric'}).split(',')[0].split('/')
        var fromMonth = date_temp[1]+'-'+zeroPad(date_temp[0],2)
        if(monthSelected[1]){
            date_temp = new Date(monthSelected[1]).toLocaleString('en-US',{year:'numeric',month:'numeric'}).split(',')[0].split('/')
            var toMonth = date_temp[1]+'-'+zeroPad(date_temp[0],2)
        }
        return {fromMonth,toMonth}
    }
    const getRevenueData = async(e,manual_limit=null,manual_page=null,sortingKeyword=null,sortingOrder=null,exportReport=null) => {
        if(!exportReport){
            setLoader({...loader,main:true})
        }
        setTotalRow(false);
        setOffset((manual_limit ? manual_limit : limit)*(manual_page ? (manual_page-1) : (page-1)))

        var {fromMonth,toMonth} = getFromToMonth()
        await axios({
            method: 'GET',
            url:"".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"invoice/adv-data"),
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params: { 
                fromMonth:fromMonth,
                toMonth:toMonth,
                limit: manual_limit ? manual_limit : limit,
                page: manual_page ? manual_page : page,
                adv_id:filterData.advertiser ? filterData.advertiser.value : undefined,
                app_name:filterData.app_name ? filterData.app_name : undefined,
                offer_id:filterData.offer_id ? filterData.offer_id : undefined,
                external_offer_id:filterData.external_offer_id ? filterData.external_offer_id : undefined,
                package_name:filterData.package_name ? filterData.package_name : undefined,
                total_row_required:true,
                calculate_with_manual_revenue:compareWithManual ? compareWithManual : false,
                show_missing_offers:showOnlyMissingsOffers ? showOnlyMissingsOffers : false,
                sorting_key: sortingKeyword ? sortingKeyword : sortingData.sortingKeyword ? sortingData.sortingKeyword : 'total_revenue',
                sorting_order:sortingOrder ? sortingOrder : sortingData.sortingOrder ? sortingData.sortingOrder : false,
                export:exportReport ? exportReport : null,
                team:filterData.team ? filterData.team.value : undefined,
                subteam:filterData.subteam ? filterData.subteam.value : undefined,
            }
        })
        .then(async(resp) => {
            if(exportReport){
                const FileDownload = require('js-file-download');
                FileDownload(resp.data, 'offer_revenue_summary.csv')
                return
            }
            setTotalResults(resp.data.count)
            var items = resp.data.result
            sortingV2(items,sortingKeyword ? sortingKeyword : sortingData.sortingKeyword ? sortingData.sortingKeyword : 'total_revenue',sortingOrder ? sortingOrder : sortingData.sortingOrder ? sortingData.sortingOrder : false)
            setTableValues(items);
            if(resp.data.total_row){
                var total_data = resp.data.total_row
                if(total_data && !total_data.pending_revenue){
                    total_data.pending_revenue = 0
                }
                if(total_data && !total_data.deduction_revenue){
                    total_data.deduction_revenue = 0
                }
                setTotalRow([total_data])
            }
        })
        .catch((error) => {
            setTableValues([])
            if (error.response) {
                console.log(error.response.data);
                if(error.response.data){
                    setTableValues(error.response.data.result);
                }
                console.log(error.response.status);
                console.log(error.response.headers);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        })
        if(!exportReport){
            setLoader({...loader,main:false})
        }
    }
    const sortingFunction = (keyword,sortingOrder) => {
        getRevenueData(null,null,null,keyword,sortingOrder)
        setSortingData({sortingKeyword:keyword,sortingOrder})
    }

    if(!manualUseEffect){
        setManualUseEffect(true)
        getRequests(getAdvertisers(false,'both'),setOptionsAdvertiserList)
        getRequests(getManager(),setOptionsManagerList)
        getRevenueData(1)
    }
    const updateTotalRow = (tableValues) => {
        var tempTotalRow = totalRow[0]
        var validated_offers = 0
        var total_offers = tableValues.length
        var deduction_revenue = 0
        var deduction_revenue_manual = 0
        var pending_revenue_manual = 0
        var pending_revenue = 0
        var conversions_verified = 0
        var manager_approved_revenue = 0
        for(var tableValueIndex in tableValues){
            var tableValueItem = tableValues[tableValueIndex]
            if(tableValueItem.validated){
                validated_offers+=1
            }
            deduction_revenue+=tableValueItem.deduction_revenue
            deduction_revenue_manual+=tableValueItem.deduction_revenue_manual
            pending_revenue_manual+=tableValueItem.pending_revenue_manual
            pending_revenue+=tableValueItem.pending_revenue
            conversions_verified+=parseFloat(tableValueItem.conversions_verified ? tableValueItem.conversions_verified : 0)
            manager_approved_revenue+=parseFloat(tableValueItem.manager_approved_revenue ? tableValueItem.manager_approved_revenue : 0)
            tableValueItem.validation_percentage = (tableValueItem.manager_approved_revenue*100)/tableValueItem.total_revenue
        }

        tempTotalRow.validated = validated_offers+'/'+total_offers
        tempTotalRow = {...tempTotalRow,conversions_verified,manager_approved_revenue,pending_revenue,deduction_revenue,deduction_revenue_manual,pending_revenue_manual}
        var validation_percentage = (tempTotalRow.manager_approved_revenue*100)/tempTotalRow.total_revenue
        tempTotalRow={...tempTotalRow,validation_percentage}
        setTotalRow([tempTotalRow])
    }

    const updateRevenueData = async(e,valueIndex,type='manager_approved_revenue') => {
        if(type!=='both' && type!=='reset'){
            setLoader({...loader,[valueIndex]:true})
        }
        var tempTableValues = tableValues
        if(tempTableValues[valueIndex][type]){
            var old_type = tempTableValues[valueIndex][type]
        }
        if(type==='manager_approved_revenue'){
            var manager_approved_revenue = 0 
            if(approvedAmount[valueIndex] && (approvedAmount[valueIndex][type] || approvedAmount[valueIndex][type]==="0" || approvedAmount[valueIndex][type]===0)){
                manager_approved_revenue = parseFloat(approvedAmount[valueIndex][type])
            }
            else if(old_type){
                manager_approved_revenue = old_type
            }
            else{
                manager_approved_revenue = 0
            }
            if(manager_approved_revenue || e.shiftKey){
                tempTableValues[valueIndex].manager_approved_revenue = manager_approved_revenue
                tempTableValues[valueIndex].deduction_revenue = tempTableValues[valueIndex].total_revenue - manager_approved_revenue
                tempTableValues[valueIndex].deduction_revenue_manual = tempTableValues[valueIndex].conversions_verified - manager_approved_revenue
                tempTableValues[valueIndex].pending_revenue = 0
                var validated = false
                try{
                    if(manager_approved_revenue || e.shiftKey){
                        validated = true
                        tempTableValues[valueIndex].validated = true
                    }
                }
                catch{
        
                }
                await axios({
                    method: 'POST',
                    url:"".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"invoice/adv-data"),
                    headers: {
                        "Content-Type":"application/json",
                        Authorization:getLocalStorageValue('adminPanelLoginData').token
                    },
                    data:{
                        manager_approved_revenue:manager_approved_revenue,
                        offer_id:tempTableValues[valueIndex].offer_id,
                        payment_status:validated,
                        month:tempTableValues[valueIndex].month,
                        advertiser_id:tempTableValues[valueIndex].advertiser ? tempTableValues[valueIndex].advertiser : ''
                    }
                })
                .then(async(resp) => {
                    if(validated && totalRow.length>0){
                        updateTotalRow(tempTableValues)
                    }
                    setTableValues(tempTableValues);
                    refreshComponent(2);
                })
            }
            else if(tempTableValues[valueIndex] && tempTableValues[valueIndex].manager_approved_revenue){
                manager_approved_revenue = tempTableValues[valueIndex].manager_approved_revenue
            }
            else{
                manager_approved_revenue = 0
            }
        }
        if(type==='package_name' || type==='app_name' || type==='note'){
            var dict = {[type]:''}
            if(approvedAmount[valueIndex] && (approvedAmount[valueIndex][type])){
                dict[type] = approvedAmount[valueIndex][type]
            }
            if(type==='note'){
                if(noteData && noteData[valueIndex]){
                    dict[type] = noteData[valueIndex]
                }
            }
            if(dict[type] || e.shiftKey){
                tempTableValues[valueIndex][type] = dict[type]
                await axios({
                    method: 'POST',
                    url:"".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"invoice/adv-data"),
                    headers: {
                        "Content-Type":"application/json",
                        Authorization:getLocalStorageValue('adminPanelLoginData').token
                    },
                    data:{
                        ...dict,
                        offer_id:tempTableValues[valueIndex].offer_id,
                        month:tempTableValues[valueIndex].month,
                        update_entry:true
                    }
                })
                .then(async(resp) => {
                    // updateTotalRow(tempTableValues)
                    setTableValues(tempTableValues);
                    refreshComponent(2);
                })
            }
        }
        if(type==='conversions_verified'){
            var conversions_verified = 0 
            if(approvedAmount[valueIndex] && (approvedAmount[valueIndex][type] || approvedAmount[valueIndex][type]==="0" || approvedAmount[valueIndex][type]===0)){
                conversions_verified = parseFloat(approvedAmount[valueIndex][type])
            }
            else{
                conversions_verified = 0
            }
            if(e.ctrlKey){
                conversions_verified = parseFloat(tempTableValues[valueIndex].total_revenue.toFixed(2))
                var ctrlKeyPressed = true
            }
            if(conversions_verified || e.shiftKey){
                tempTableValues[valueIndex].conversions_verified = conversions_verified
                await axios({
                    method: 'POST',
                    url:"".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"invoice/adv-data"),
                    headers: {
                        "Content-Type":"application/json",
                        Authorization:getLocalStorageValue('adminPanelLoginData').token
                    },
                    data:{
                        conversions_verified:conversions_verified,
                        offer_id:tempTableValues[valueIndex].offer_id,
                        month:tempTableValues[valueIndex].month,
                        // advertiser_id:tempTableValues[valueIndex].advertiser ? tempTableValues[valueIndex].advertiser : '',
                        payment_status:tempTableValues[valueIndex].validated ? tempTableValues[valueIndex].validated : false,
                        lumpsum:tempTableValues[valueIndex].lumpsum ? tempTableValues[valueIndex].lumpsum : false,
                        manager_approved_revenue:tempTableValues[valueIndex].manager_approved_revenue ? tempTableValues[valueIndex].manager_approved_revenue : 0,
                    }
                })
                .then(async(resp) => {
                    updateTotalRow(tempTableValues)
                    setTableValues(tempTableValues);
                    refreshComponent(2);
                })
            }
        }
        if(type==='both'){
            
            setUpdateDataLoader(true);
            var tempTableValuesSelected = tableValuesSelected
            tempTableValuesSelected.sort()
            for(var index in tempTableValuesSelected){
                var item = tempTableValuesSelected[index]
                setLoader({...loader,[item]:true})
                await axios({
                    method: 'POST',
                    url:"".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"invoice/adv-data"),
                    headers: {
                        "Content-Type":"application/json",
                        Authorization:getLocalStorageValue('adminPanelLoginData').token
                    },
                    data:{
                        conversions_verified:tableValueSelectedUpdatedData.conversions_verified ? tableValueSelectedUpdatedData.conversions_verified/tableValuesSelected.length : undefined,
                        manager_approved_revenue:tableValueSelectedUpdatedData.manager_approved_revenue ? tableValueSelectedUpdatedData.manager_approved_revenue/tableValuesSelected.length : undefined,
                        offer_id:tempTableValues[item].offer_id,
                        month:tempTableValues[valueIndex].month,
                        payment_status:true,
                        lumpsum:true,
                    }
                })
                .then(async(resp) => {
                    tempTableValues[item].manager_approved_revenue = tableValueSelectedUpdatedData.manager_approved_revenue/tableValuesSelected.length
                    tempTableValues[item].conversions_verified = tableValueSelectedUpdatedData.conversions_verified/tableValuesSelected.length
                    tempTableValues[item].validated = true
                    tempTableValues[item].lumpsum = true
                    tempTableValues[item].deduction_revenue = tempTableValues[item].total_revenue - tempTableValues[item].manager_approved_revenue
                    tempTableValues[item].pending_revenue = 0
                    updateTotalRow(tempTableValues)
                    setTableValues(tempTableValues);
                    refreshComponent(2);
                })
                setLoader({...loader,[item]:false})

            }
            setUpdateDataLoader(false);
        }
        if(type==='reset'){
            var tempTableValuesSelected = tableValuesSelected
            tempTableValuesSelected.sort()
            for(var index in tempTableValuesSelected){
                var item = tempTableValuesSelected[index]
                setLoader({...loader,[item]:true})
                await axios({
                    method: 'POST',
                    url:"".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"invoice/adv-data"),
                    headers: {
                        "Content-Type":"application/json",
                        Authorization:getLocalStorageValue('adminPanelLoginData').token
                    },
                    data:{
                        conversions_verified:0,
                        manager_approved_revenue:0,
                        offer_id:tempTableValues[item].offer_id,
                        month:tempTableValues[valueIndex].month,
                        payment_status:false,
                        lumpsum:false,
                    }
                })
                .then(async(resp) => {
                    tempTableValues[item].manager_approved_revenue = 0
                    tempTableValues[item].conversions_verified = 0
                    tempTableValues[item].validated = false
                    tempTableValues[item].lumpsum = false
                    tempTableValues[item].deduction_revenue = 0
                    tempTableValues[item].pending_revenue = tempTableValues[item].total_revenue 
                    updateTotalRow(tempTableValues)
                    setTableValues(tempTableValues);
                    refreshComponent(2);
                })
                setLoader({...loader,[item]:false})
            }
        }

        setTableValueSelected([]);
        if(type==='both'){
            if(document.getElementById('editSelectedRows')){
                document.getElementById('editSelectedRows').style.display='none';
            }
        }
        
        if(valueIndex<tableValues.length-1 && type!=='both' && type!=='reset' && type!=='note'){

            // setEditPaymentPopup({[valueIndex+1]:{'manager_approved_revenue':2}})
            document.getElementById('approvedRevenueTextFieldContainer'+type+(valueIndex+1)).click();
            // if(document.getElementById('approvedRevenueTextField'+type+(valueIndex+1)).value === 0){
            //     document.getElementById('approvedRevenueTextField'+type+(valueIndex+1)).value = ''
            // };
            setTimeout(function(){ document.getElementById('approvedRevenueTextField'+type+(valueIndex+1)).focus(); }, 100);
        }
        else{
            setEditPaymentPopup(false)
        }

       
        setLoader({...loader,[valueIndex]:false})

    }
    const [editPaymentPopup, setEditPaymentPopup] = useState(false)
    const [approvedAmount, setApprovedAmount] = useState({})

    const updateFilterData = (value,item) => {
        setFilterData({...filterData,[item.value]:value})
        var oldSessionFilterStorage = getLocalStorageValue('billingFilters') ? getLocalStorageValue('billingFilters') : {}
        setLocalStorageValue("billingFilters",{...oldSessionFilterStorage,[item.value]:value})
    }
    const resetFilters = () => {
        removeLocalStorageValue('billingFilters');
        setFilterData({})
        setMonthSelected(lastMonth);
        refreshComponent(123);
    }

    var subteamoptions = () => {
        if(filterData && filterData.team){
            if(filterData.team.value){
                return subteamMembers[filterData.team.value].map((item)=>{return {label:item,value:item}})
            }
        }
    }
    const filters = [
        {label:'Month', value:'month',type:'monthpicker'},
        {label:'Offer ID', value:'offer_id',type:'textfield',setMethod:updateFilterData},
        {label:'External Offer ID', value:'external_offer_id',type:'textfield',setMethod:updateFilterData},
        {label:'Offer Package', value:'package_name',type:'textfield',setMethod:updateFilterData},
        // {label:'App Name', value:'app_name',type:'textfield',setMethod:updateFilterData},
        {label:'Tech Team',isSingle:true, value:'team',type:'select',options:teamOptions,setMethod:updateFilterData},
        {label:'Tech Sub Team',isSingle:true, value:'subteam',type:'select',options:subteamoptions(),setMethod:updateFilterData},
        {label:'Advertiser',isSingle:true, value:'advertiser',type:'select',options:optionsAdvertiserList.advertiserlist,setMethod:updateFilterData},
        {label:'Advertiser Manager',isSingle:true, value:'adv_manager',type:'select',options:optionsManagerList,setMethod:updateFilterData},
        {label:'Submit', disabled:loader.main,value:'submitButton',type:'button',onSubmit:getRevenueData},
        {label:'Reset', value:'resetButton',variant:'outlined',type:'button',onSubmit:resetFilters},
    ]

    const totalRowOfferIdComponent = (valueItem,item,index) => {
        return <div style={{marginLeft:'5px'}}>
           
        </div>
    }

    const offerIdComponent = (valueItem,item,index) => {
        if(!valueItem.offer_id || valueItem.offer_id===parseInt(process.env.REACT_APP_FEATURE_MONITORING_OFFER_ID)){
            return 'Missing Offer'
        }
        return valueItem.offer_id
    }

    const externalOfferIdComponent = (valueItem,item,index) => {
        if(valueItem.external_offer_id || valueItem.offer_id==='Total'){
            if(valueItem.offer_id==='Total'){
                return totalRowOfferIdComponent(valueItem,item,index)
            }
            return valueItem.external_offer_id
        }
        else{
            return 'Manual Offer'
        }
    }

    const totalRowPaymentStatusComponent = (valueItem,item,index,valueIndex,tableValues) => {
        return <div style={{marginLeft:'5px'}}>
            {valueItem.validated + ' Validated'}
        </div>
    }

    const paymentStatusComponent = (valueItem,item,index,valueIndex,tableValues) => {
        if(valueItem.offer_id==='Total'){
            return totalRowPaymentStatusComponent(valueItem,item,index,valueIndex,tableValues)
        }
        const paymentStatus = (valueItem) => {
            var approved_amount = valueItem.manager_approved_revenue ? valueItem.manager_approved_revenue  : 0 
            var delivered_amount = valueItem.delivered_revenue
            var percentage = ((approved_amount/delivered_amount)*100).toFixed(2)
        
            if(item.loader[valueIndex]){
                return <CircularProgress size={'14px'} sx={{color:getLocalStorageValue('ambientColor'),marginRight:'10px'}}/>
            }
            else if(valueItem.validated){
                return <Tooltip title={'Offer Payment Validated with '+percentage+'% confirmation.'}>
                            <div style={{display:'flex',alignItems:'center'}}>
                                <FiberManualRecordIcon sx={{color:'green',fontSize:'12px'}}/>
                                <div style={{paddingLeft:'3px',fontSize:'13px'}}>Validated</div>
                            </div>
                        </Tooltip>
            }
            else{
                return <Tooltip title={'You need to update the Validation for this offer.'}>
                            <div style={{display:'flex',alignItems:'center'}}>
                                <FiberManualRecordIcon sx={{color:'orange',fontSize:'12px'}}/>
                                <div style={{paddingLeft:'3px',fontSize:'13px'}}>Pending</div>
                            </div>
                        </Tooltip>
            }
        }
        return paymentStatus(valueItem)
    }
    const [totalApprovedRevenueEdit, setTotalApprovedRevenueEdit] = useState({clicked:false})
    const updateTotalApprovedRowApi = async(payment_status,manual_approved_per_offer=0,type='manager_approved_revenue') => {
        setLoader({...loader,totalRow:true})
        var {fromMonth,toMonth} = getFromToMonth()
        if(fromMonth===toMonth){
            if(payment_status || tableValuesSelected.length === tableValues.length){
                var tempTableValues = tableValues
                var total_row_apporved_revenue = parseFloat(totalApprovedRevenueEdit[type+'value'])
                var value_approved_per_offer = manual_approved_per_offer || manual_approved_per_offer === 0 ? manual_approved_per_offer : ( total_row_apporved_revenue/tableValues.length).toFixed(2)
                var conversions_verified = tempTableValues[0].conversions_verified ? tempTableValues[0].conversions_verified : 0
                var manager_approved_revenue = tempTableValues[0].manager_approved_revenue ? tempTableValues[0].manager_approved_revenue : 0
                
                if(type==='conversions_verified'){
                    conversions_verified = value_approved_per_offer
                }
                if(type==='manager_approved_revenue'){
                    manager_approved_revenue = value_approved_per_offer
                }
                if(payment_status!==0){
                    payment_status = totalRow[0].validated.split('/')[0] === totalRow[0].validated.split('/')[1] ? true : type==='manager_approved_revenue' && payment_status ? true : false
                }
                else{
                    conversions_verified = 0
                    manager_approved_revenue = 0
                }
                if(manager_approved_revenue===0 && totalRow[0].manager_approved_revenue && totalRow[0].manager_approved_revenue>0){
                    manager_approved_revenue = totalRow[0].manager_approved_revenue/tableValues.length
                }
                await axios({
                    method: 'POST',
                    url:"".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"invoice/adv-data"),
                    headers: {
                        "Content-Type":"application/json",
                        Authorization:getLocalStorageValue('adminPanelLoginData').token
                    },
                    data:{
                        manager_approved_revenue:type === 'reset' ?  manual_approved_per_offer : manual_approved_per_offer ? manual_approved_per_offer : manager_approved_revenue,
                        conversions_verified,
                        lumpsum: true,
                        payment_status,
                        month:fromMonth,
                        advertiser_id:filterData.advertiser ? filterData.advertiser.value : undefined
                    }
                })
                .then(async(resp) => {
                    for(var tableValueIndex in tempTableValues){
                        var tableValueItem = tempTableValues[tableValueIndex]
                        tableValueItem[type] = value_approved_per_offer
                        tableValueItem.lumpsum = true
                        tableValueItem.validated = payment_status
                        tableValueItem.validation_percentage = (manager_approved_revenue/tableValueItem.total_revenue)*100
                        if(!payment_status){
                            tableValueItem.pending_revenue = tableValueItem.total_revenue
                            tableValueItem.deduction_revenue = 0
                        }
                        else{
                            tableValueItem.pending_revenue = 0
                            tableValueItem.deduction_revenue = tableValueItem.total_revenue - parseFloat(manager_approved_revenue)
                        }
                    }
                })
                var tempTotalRow = totalRow;
                if(payment_status){
                    tempTotalRow[0].validated = tempTotalRow[0].validated.split('/')[1]+'/'+tempTotalRow[0].validated.split('/')[1]
                    tempTotalRow[0].deduction_revenue = tempTotalRow[0].total_revenue - (manager_approved_revenue*tempTableValues.length)
                    tempTotalRow[0].pending_revenue = 0
                    tempTotalRow[0].validation_percentage = ((manager_approved_revenue*tempTableValues.length)/tempTotalRow[0].total_revenue)*100
                }
                tempTotalRow[0][type] = manual_approved_per_offer ? manual_approved_per_offer : total_row_apporved_revenue
                setTotalRow(tempTotalRow)
                setTableValues(tempTableValues)
                refreshComponent(12);
                setLoader({...loader,totalRow:false})
            }
            else{
                Store.addNotification({
                    title: "Warning!",
                    message: "Single row payment status cannot be changed.",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 3000,
                      onScreen: true
                    }
                });
            }
        }
        else{
            Store.addNotification({
                title: "Warning!",
                message: "You cannot update when you have selected different month range.",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true
                }
            });
        }

        setTotalApprovedRevenueEdit({clicked:false});
    }
    const totalApprovedRevenueComponent = (valueItem,item,index,valueIndex,tableValues) => {
        if(!['package_name','app_name'].includes(item.value)){
            if(!filterData.advertiser){
                return <div style={{marginLeft:'2px'}}>
                    {Math.round(valueItem[item.value])}
                </div>
            }
            const handleDoubleClick = (e,item) => {
                item.setTotalApprovedRevenueEdit({[item.value]:true})
                item.setEditPaymentPopup(false)
            }
            return <div onKeyDown={e=>{if(e.key==='Enter'){item.updateTotalApprovedRowApi(1,null,item.value);}else if(e.key==='Escape'){item.setTotalApprovedRevenueEdit({[item.value]:false});}}} onClick={(e)=>{e.stopPropagation();handleDoubleClick(e,item);}} className='approvedRevenueTextFieldContainer' >
                    <input disabled={item.totalApprovedRevenueEdit && !item.totalApprovedRevenueEdit[item.value]}  
                            className='totalApprovedRevenueTextField' 
                            value={item.totalApprovedRevenueEdit && (item.totalApprovedRevenueEdit[item.value+'value'] || item.totalApprovedRevenueEdit[item.value+'value']==='' || item.totalApprovedRevenueEdit[item.value+'value']==='0') ? Math.round(item.totalApprovedRevenueEdit[item.value+'value']) : Math.round(valueItem[item.value])} 
                            onChange={e=>{if(e.target.value.match(/^\d+\.?\d*$/) || e.target.value===''){item.setTotalApprovedRevenueEdit({[item.value]:true,[item.value+'value']:e.target.value})};refreshComponent(123);}}/>
                    {item.totalApprovedRevenueEdit && item.totalApprovedRevenueEdit[item.value]
                    &&
                    <div style={{position:'absolute',right:5,display:'flex',alignItems:'center'}}>
                        {item.loader.totalRow
                        &&
                        <CircularProgress size={'14px'} sx={{color:getLocalStorageValue('ambientColor'),marginRight:'10px'}}/>
                        }
                        {!item.loader['totalRow']
                        &&
                        <>
                            <div onClick={(e)=>{e.stopPropagation();item.updateTotalApprovedRowApi(1,null,item.value);}}>
                                <Tooltip title={'Save'}>
                                    <DoneIcon sx={{color:'green',fontSize:'16px',marginRight:'5px'}}/>
                                </Tooltip>
                            </div>
                            <div onClick={(e)=>{e.stopPropagation();item.setTotalApprovedRevenueEdit({[item.value]:false});}}>
                                <Tooltip title={'Save'}>
                                    <CloseIcon sx={{color:'red',fontSize:'16px'}}/>
                                </Tooltip>
                            </div>
                        </>
                        }
                    </div>
                    }
                </div>
        }
    }
    const approvedRevenueComponent = (valueItem,item,index,valueIndex,tableValues) => {
        
        if(valueItem.offer_id==='Total'){
            return totalApprovedRevenueComponent(valueItem,item,index,valueIndex,tableValues)
        }
        if(valueItem.offer_id===parseInt(process.env.REACT_APP_FEATURE_MONITORING_OFFER_ID)){
            return valueItem[item.value]
        }
        if(checkForPermission('can_view_billing_only') && !isSuperUser()){
            if(Math.round(valueItem[item.value])){
                return Math.round(valueItem[item.value])
            }
            return valueItem[item.value]
        }
        const handleDoubleClick = (e,item) => {
            item.setTotalApprovedRevenueEdit({clicked:false})

            if(!item.editPaymentPopup || !item.editPaymentPopup[valueIndex]){
                item.setEditPaymentPopup({[valueIndex]:{[item.value]:2}})
            }
            else if(item.editPaymentPopup && item.editPaymentPopup[valueIndex][item.value]===1){
                item.setEditPaymentPopup({[valueIndex]:{[item.value]:2}})
            }
            else if(item.editPaymentPopup && item.editPaymentPopup[valueIndex][item.value]===2){
                item.setEditPaymentPopup({[valueIndex]:{[item.value]:2}})
            }
            else{
                item.setEditPaymentPopup({[valueIndex]:{[item.value]:1}})
            }
        }
        var approved_amount = valueItem[item.value] ? valueItem[item.value] : ['package_name','app_name'].includes(item.value) ? '' : 0
        var disabled_input = !item.editPaymentPopup || !(item.editPaymentPopup && item.editPaymentPopup[valueIndex] && item.editPaymentPopup[valueIndex][item.value]===2)
        return <div id={'approvedRevenueTextFieldContainer'+[item.value]+valueIndex} onKeyDown={e=>{if(e.key==='Enter'){item.updateRevenueData(e,valueIndex,item.value);item.setApprovedAmount(false);}else if(e.key==='Escape'){item.setApprovedAmount(false);item.setEditPaymentPopup(false);}}} 
        onClick={(e)=>{e.stopPropagation();if(!(valueItem.lumpsum && item.value!=='conversions_verified') || (['app_name','package_name'].includes(item.value))){
            var allowed = true;
            if(['package_name'].includes(item.value) && valueItem[item.value]){
                if(!checkForPermission('can_view_app_name')){
                    allowed = false;
                }
            }
            if(['app_name'].includes(item.value) && valueItem[item.value]){
                allowed = false;
                if(e.ctrlKey || e.metaKey){
                    window.open('/billing/package?monthSelected='+monthSelected+"&package_name="+valueItem.package_name+'&showYTD=True','_blank')
                }
                else{
                    window.open('/billing/summary/advertiser?monthSelected='+monthSelected+"&package_name="+valueItem.package_name,'_blank')
                }
            }
            if(allowed){
                handleDoubleClick(e,item);
            }
        }}} className='approvedRevenueTextFieldContainer' >
                
                <input id={'approvedRevenueTextField'+[item.value]+valueIndex} disabled={disabled_input}  
                        className='approvedRevenueTextField' 
                        style={{pointerEvents:disabled_input ? 'none' : 'all'}}
                        value={['package_name','app_name'].includes(item.value) ? (approvedAmount && approvedAmount[valueIndex] && (approvedAmount[valueIndex][item.value] || approvedAmount[valueIndex][item.value]==='')) ? approvedAmount[valueIndex][item.value] : valueItem[item.value] : valueItem.lumpsum && item.value!=='conversions_verified' ? 'Lumpsum Payment' : approvedAmount && (approvedAmount[valueIndex] && (approvedAmount[valueIndex][item.value] || approvedAmount[valueIndex][item.value]==='' || approvedAmount[valueIndex][item.value]===0)) ? approvedAmount[valueIndex][item.value] : ((item.editPaymentPopup &&  item.editPaymentPopup[valueIndex] && item.editPaymentPopup[valueIndex][item.value]===2) || (item.value==='manager_approved_revenue' ? valueItem.validated : true )) ? Math.round(approved_amount) : 'Pending'} 
                        onChange={e=>{if(['package_name','app_name'].includes(item.value) || (e.target.value.match(/^\d+\.?\d*$/) || e.target.value==='')){item.setApprovedAmount({[valueIndex]:{[item.value]:e.target.value}})};refreshComponent(123);}}/>
                {item.editPaymentPopup && item.editPaymentPopup[valueIndex] && item.editPaymentPopup[valueIndex][item.value]===2
                &&
                <div style={{position:'absolute',right:5,display:'flex',alignItems:'center'}}>
                    {loader[valueIndex]
                    &&
                    <CircularProgress size={'14px'} sx={{color:getLocalStorageValue('ambientColor'),marginRight:'10px'}}/>
                    }
                    {!loader[valueIndex]
                    &&
                    <>
                        <div onClick={(e)=>{e.stopPropagation();item.updateRevenueData(e,valueIndex,item.value);item.setApprovedAmount(false);}}>
                            <Tooltip title={'Save'}>
                                <DoneIcon sx={{color:'green',fontSize:'16px',marginRight:'5px'}}/>
                            </Tooltip>
                        </div>
                        <div onClick={(e)=>{e.stopPropagation();item.setApprovedAmount(false);item.setEditPaymentPopup(false);}}>
                            <Tooltip title={'Save'}>
                                <CloseIcon sx={{color:'red',fontSize:'16px'}}/>
                            </Tooltip>
                        </div>
                    </>
                    }
                </div>
                }
            </div>
    }

    const getParseNote = (note,key) => {
        try{
            var note_dict = JSON.parse(note)
            if(note_dict[key]){
                note = note_dict[key]
            }
            else{
                note = false
            }
        }
        catch{
            note = false
        }
        return note
    }
    const notesComponent = (valueItem,item,index,valueIndex) => {
        var value = Math.round(valueItem[item.value])

        var note = getParseNote(valueItem.note,item.label)
                
        return <Tooltip title={note ? note : 'Alt + Click to add a note.'}>
            <div style={{marginLeft:(valueItem.offer_id === 'Total' ? '5px' : '0px')}} onClick={(e)=>{
                e.stopPropagation();
                if(e.altKey){
                    e.preventDefault();
                    item.setNoteMenuClicked({valueIndex,label:item.label})
                }
            }}>
                {note
                &&
                <div style={{position:'absolute',bottom:0,right:0}}>
                    <div style={{fontSize:'8px',color:'grey'}}> &#9698;</div>
                </div>
                }
                {value}
            </div>
        </Tooltip>
    }
    const roundOffComponent = (valueItem,item,index,valueIndex) => {
        var value = Math.round(valueItem[item.value])
        return <div style={{marginLeft:(valueItem.offer_id === 'Total' ? '5px' : '0px')}}>
            {value}
        </div>
    }
    const advertiserComponent = (valueItem,item) => {
        if(valueItem.offer_id==='Total'){
            return 
        }
        return <div style={{textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap'}}>
            {(optionsAdvertiserList.advertiserDict ? optionsAdvertiserList.advertiserDict[valueItem[item.value]] : '')}
        </div>
    }

    const validationPercentageComponent = (valueItem,item,index,valueIndex,tableValues) => {
        var percent = valueItem[item.value] ? parseInt(valueItem[item.value]) : 0.00
        if (percent<25){
            var color = '#ff0000'
            var highlightColor = '#ffcccc'
        }
        else if (percent<50){
            color = '#ff6200'
            highlightColor = '#ffe6cc'
        }
        else if (percent<75){
            color = '#6f9400'
            highlightColor = '#ebffc2'
        }
        else{
            color = '#0f8500'
            highlightColor = '#d2ffcc'
        }
        var note = getParseNote(valueItem.note,item.label)

        return <Tooltip title={note ? note : 'Alt + Click to add a note.'}>
            <div style={{color,fontWeight:500,background:highlightColor,marginLeft:(valueItem.advertiser_id==='Total' ? '5px' : '0px'),padding:'5px',width:'max-content',borderRadius:'10px'}}  onClick={(e)=>{
                e.stopPropagation();
                if(e.altKey){
                    e.preventDefault();
                    item.setNoteMenuClicked({valueIndex,label:item.label})
                }
            }}>
                {note
                &&
                <div style={{position:'absolute',bottom:0,right:0}}>
                    <div style={{fontSize:'8px',color:'grey'}}> &#9698;</div>
                </div>
                }
                {valueItem.validated ? percent.toString()+'%' : 'Pending'}
            </div>
        </Tooltip>
    }

    const diffManualPanelPercent = (valueItem,item,index,valueIndex,tableValues) => {
        var percent = valueItem[item.value] ? parseInt(valueItem[item.value]) : 0.00
        var color = 'rgb(255,0,0)'
        var highlightColor = 'rgb(255, 166, 166)'
        if (percent>=90 && percent<=110){
            color = 'rgb(23, 100, 0)'
            highlightColor = 'rgb(152, 192, 140)'
        }
        var note = getParseNote(valueItem.note,item.label)

        return <Tooltip title={note ? note : 'Alt + Click to add a note.'}>
            <div style={{color,marginLeft:(valueItem.advertiser_id==='Total' ? '5px' : '0px'),padding:'5px',width:'max-content',borderRadius:'20px'}} onClick={(e)=>{
                e.stopPropagation();
                if(e.altKey){
                    e.preventDefault();
                    item.setNoteMenuClicked({valueIndex,label:item.label})
                }
            }}>
                {note
                    &&
                <div style={{position:'absolute',bottom:0,right:0}}>
                    <div style={{fontSize:'8px',color:'grey'}}> &#9698;</div>
                </div>
                }
                {percent.toString()+'%'}
            </div>
        </Tooltip> 
    }
    const menuComponent = (valueItem,item,index,valueIndex,tableValues) => {
        if(valueItem.offer_id === 'Total'){
            return 
        }
        var {fromMonth,toMonth} = getFromToMonth()
        var app_advertiser_summary_url = '/billing/summary/advertiser?monthSelected='+fromMonth+'_'+toMonth+"&package_name="+valueItem.package_name
        var app_summary_url = '/billing/package?monthSelected='+fromMonth+'_'+toMonth+"&package_name="+valueItem.package_name+'&showYTD=True'
        return <div style={{display:'flex',marginTop:'2px',alignItems:'center',justifyContent:'space-between'}}>
            <Tooltip title={'Edit Note'}>
                <IconButton size='small' onClick={()=>{item.setNoteMenuReadOnlyClicked(valueIndex)}}>
                    <EditNoteIcon sx={{fontSize:'15px'}}/>
                </IconButton>
            </Tooltip>
            <Tooltip title={'Get App Advertiser Summary'}>
                <IconButton size='small' onClick={()=>{window.open(app_advertiser_summary_url,'_blank')}}>
                    <PeopleIcon sx={{fontSize:'15px'}}/>
                </IconButton>
            </Tooltip>
            <Tooltip title={'Get App Summary'}>
                <IconButton size='small'  onClick={()=>{window.open(app_summary_url,'_blank')}}>
                    <AppShortcutIcon sx={{fontSize:'15px'}}/>
                </IconButton>
            </Tooltip>
        </div>
    }
    const tempTableHeaders = [
        {label:'Offer ID',value:'offer_id',width:90,locked:true,showTooltip:true,hide:false,customComponent:offerIdComponent},
        {label:'External Offer ID',value:'external_offer_id',locked:true,width:90,customComponent:externalOfferIdComponent},
        {label:'Offer Name',value:'offer_name',width:140,hide:false},
        {label:'Package Name',value:'package_name',width:140,hide:false,customComponent:approvedRevenueComponent,editPaymentPopup,setEditPaymentPopup,approvedAmount,setApprovedAmount,updateRevenueData,loader,totalApprovedRevenueEdit,setTotalApprovedRevenueEdit,updateTotalApprovedRowApi},
        {label:'App Name',value:'app_name',width:140,hide:false,customComponent:approvedRevenueComponent,editPaymentPopup,setEditPaymentPopup,approvedAmount,setApprovedAmount,updateRevenueData,loader,totalApprovedRevenueEdit,setTotalApprovedRevenueEdit,updateTotalApprovedRowApi},
        {label:'Advertiser',value:'advertiser',width:120,hide:false,customComponent:advertiserComponent},
        {label:'Validation',value:'validated',width:120,hide:false,customComponent:paymentStatusComponent,loader,style:{position:'relative'}},
        {label:'Delivered Qty',value:'conversions',width:100,hide:true},
        {label:'Delivered Revenue',value:'delivered_revenue',width:100,customComponent:roundOffComponent,hide:true},
        {label:'Month',value:'month',width:100,hide:true},
        {label:'Declined Revenue',value:'declined_revenue',width:100,customComponent:roundOffComponent,hide:true},
        {label:'Panel Revenue',value:'total_revenue',width:100,hide:false,customComponent:roundOffComponent},
        {label:'Manual Revenue',value:'conversions_verified',width:100,hide:false,customComponent:approvedRevenueComponent,editPaymentPopup,setEditPaymentPopup,approvedAmount,setApprovedAmount,updateRevenueData,loader,totalApprovedRevenueEdit,setTotalApprovedRevenueEdit,updateTotalApprovedRowApi},
        {label:'Revenue Difference',value:'diff_manual_panel',width:100,hide:false,customComponent:roundOffComponent,editPaymentPopup,setEditPaymentPopup,approvedAmount,setApprovedAmount,updateRevenueData,loader,totalApprovedRevenueEdit,setTotalApprovedRevenueEdit,updateTotalApprovedRowApi},
        {label:'Panel Revenue Accuracy',value:'diff_manual_panel_percent',width:100,hide:false,customComponent:diffManualPanelPercent,editPaymentPopup,setEditPaymentPopup,approvedAmount,setApprovedAmount,updateRevenueData,loader,totalApprovedRevenueEdit,setTotalApprovedRevenueEdit,updateTotalApprovedRowApi,noteMenuClicked,setNoteMenuClicked},
        {label:'Approved Revenue',value:'manager_approved_revenue',width:100,hide:false,customComponent:approvedRevenueComponent,editPaymentPopup,setEditPaymentPopup,approvedAmount,setApprovedAmount,updateRevenueData,loader,totalApprovedRevenueEdit,setTotalApprovedRevenueEdit,updateTotalApprovedRowApi},
        {label:'Validation Percentage',value:'validation_percentage'+(compareWithManual ? '_manual' : ''),width:100,hide:false,customComponent:validationPercentageComponent,noteMenuClicked,setNoteMenuClicked},
        {label:'Deduction',value:'deduction_revenue'+(compareWithManual ? '_manual' : ''),width:100,hide:false,customComponent:notesComponent,noteMenuClicked,setNoteMenuClicked},
        {label:'Pending',value:'pending_revenue'+(compareWithManual ? '_manual' : ''),width:100,hide:false,customComponent:notesComponent,noteMenuClicked,setNoteMenuClicked},
        {label:'...',value:'',hide:false,width:100,hideSorting:true,customComponent:menuComponent,noteMenuReadOnlyClicked,setNoteMenuReadOnlyClicked}
    ]
    // const [tableHeaders, setTableHeaders] = useState(tempTableHeaders)
    useEffect(() => {
        document.addEventListener('click',e=>{
            setEditPaymentPopup(false);
            setApprovedAmount(false);
            setTotalApprovedRevenueEdit({clicked:false});
        })
        return () => {
            document.removeEventListener('click',e=>{
                setEditPaymentPopup(false);
                setApprovedAmount(false);
                setTotalApprovedRevenueEdit({clicked:false});
            })
        }
    }, [])
    
    const getNoteValue= (label=null,valueIndex= null) => {
        if(!label){
            label = noteMenuClicked.label
        }
        if(!valueIndex && valueIndex!==0){
            valueIndex = noteMenuClicked.valueIndex
        }

        if(noteData && ((noteData[valueIndex] || noteData[valueIndex]===""))){
            var note = noteData[valueIndex]
            try{
                var note_dict = JSON.parse(note)
                return note_dict[label ? label : noteMenuClicked.label]
            }
            catch{
                return note
            }
        }
        else if(tableValues[valueIndex] && tableValues[valueIndex].note){
            var note = getParseNote(tableValues[valueIndex].note,label ? label : noteMenuClicked.label)
            if(note){
                return  note
            }
        }
        else{
            return ''
        }
    }
    const changeNoteValue = (e) => {
        var note = tableValues[noteMenuClicked.valueIndex].note ? tableValues[noteMenuClicked.valueIndex].note : noteData[noteMenuClicked.valueIndex] ? noteData[noteMenuClicked.valueIndex] : ''
        if(!note){
            note = ''
        }
        try{
            var note_dict = JSON.parse(note)
            note_dict[noteMenuClicked.label] = e.target.value
        }
        catch{
            var note_dict = {}
            if(note){
                note_dict['old_note'] = note
            }
            note_dict[noteMenuClicked.label] = e.target.value
        }
        note = JSON.stringify(note_dict)
        setNoteData({...noteData,
            [noteMenuClicked.valueIndex]:note
        })
        // (e)=>{setNoteData({...noteData,[noteMenuClicked.valueIndex]:{[noteMenuClicked.label]:e.target.value}})}
    }
    return (
        <div className='advertiserBillingContainer'>
            <br/>
            <div style={{position:'sticky',left:0,width:'100%',zIndex:100}}>
                <div>
                    <div className='advertiserLabel' style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                        <div>Offer Summary</div>
                        <Button sx={{...Constants.OUTLINEDBUTTON,marginRight:'30px'}} variant='outlined' color='warning' onClick={()=>{setAddMissingOfferPopup(true)}}>Add Missing Offer</Button>
                    </div>
                    
                    <div className='advertiserFilters'>
                            
                        {filters.map((filterItem,filterIndex)=>{
                            return <div className='filterItem' key={filterIndex}>
                                    {filterItem.type !=='button' && <div className='filterItemLabel'>{filterItem.label}</div>}
                                    {filterItem.type === 'monthpicker'
                                    &&
                                    <div style={{borderBottom:'1px solid rgb(200,200,200)'}}>
                                        <DatePicker
                                            // selected={new Date(monthSelected)}
                                            customInput={<input className='monthPicker'/>}
                                            selectsRange
                                            startDate={new Date(monthSelected[0])}
                                            endDate={monthSelected[1] ? new Date(monthSelected[1]) : null}
                                            onChange={(date)=>{
                                                // var date_temp = date[0].toLocaleString('en-US',{year:'numeric',month:'numeric'}).split(',')[0].split('/')
                                                // date[0] = date_temp[1]+'-'+zeroPad(date_temp[0],2)
                                                // if(date[1]){
                                                //     date_temp = date[1].toLocaleString('en-US',{year:'numeric',month:'numeric'}).split(',')[0].split('/')
                                                //     date[1] = date_temp[1]+'-'+zeroPad(date_temp[0],2)
                                                // }
                                                setMonthSelected(date);
                                                var oldSessionFilterStorage = getLocalStorageValue('billingFilters') ? getLocalStorageValue('billingFilters') : {}
                                                setLocalStorageValue('billingFilters',{...oldSessionFilterStorage,monthSelected:date});}}
                                            showMonthYearPicker={true}
                                            dateFormat={'MMMM yyyy'}
                                        />
                                    </div>
                                    }
                                    {filterItem.type === 'textfield'
                                    &&
                                    <CssTextField variant='standard' value={filterData[filterItem.value] ? filterData[filterItem.value] : ''} onChange={e=>{filterItem.setMethod(e.target.value,filterItem);}}/>}
                                    {filterItem.type === 'select'
                                    &&
                                    <div style={{minWidth:200}}>
                                        <ReactSelect 
                                            isClearable={filterItem.value==='team' ? isClearableTeam : true}
                                            options={filterItem.options}
                                            value={filterData[filterItem.value] ? filterData[filterItem.value] : ''}
                                            isMulti={filterItem.isSingle ? false : true}
                                            onChange={(e)=>{filterItem.setMethod(e,filterItem)}}
                                            menuPortalTarget={document.body} 
                                            styles={{...ReactSelectStyles}}/>
                                    </div>
                                    }
                                    {
                                        filterItem.type === 'button'
                                        &&
                                        <div style={{marginTop:'21px'}}>
                                            <Button id='testbutton' disabled={filterItem.disabled} onClick={filterItem.onSubmit} sx={{...(filterItem.variant && filterItem.variant==='outlined' ? Constants.OUTLINEDBUTTON : Constants.CONTAINEDBUTTON),fontSize:'14px',minWidth:'102px',minHeight:'40px'}}>
                                                {filterItem.disabled
                                                &&
                                                <CircularProgress size={'15px'} sx={{color:'white'}}/>
                                                }
                                                {!filterItem.disabled && filterItem.label}
                                            </Button>
                                        </div>
                                    }
                                </div>
                        })}
                    </div>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <div style={{paddingLeft:'20px'}}>
                            <input checked={compareWithManual} id='compareWithManual' type='checkbox' onChange={(e)=>{setCompareWithManual(e.target.checked);updateFilterData(e.target.checked,{value:'compare_with_manual'});}}/>
                            <label style={{fontSize:'14px'}} htmlFor='compareWithManual' onChange={(e)=>{setCompareWithManual(e.target.checked);updateFilterData(e.target.checked,{value:'compare_with_manual'})}}>Calculation with Manual Revenue</label>
                        </div>
                        <div style={{paddingLeft:'20px'}}>
                            <input checked={showOnlyMissingsOffers} id='showOnlyMissingsOffers' type='checkbox' onChange={(e)=>{setShowOnlyMissingOffers(e.target.checked);}}/>
                            <label style={{fontSize:'14px'}} htmlFor='showOnlyMissingsOffers' onChange={(e)=>{setShowOnlyMissingOffers(e.target.checked);}}>Show Only Missing Offers</label>
                        </div>
                    </div>
                </div>
                {   tableValuesSelected
                    &&
                    tableValuesSelected.length>0
                    &&
                    <div className='valueSelectedChangesWrapper'>
                        <div style={{margin:'20px'}}>
                            {tableValuesSelected.length +' rows selected.'}
                        </div>
                        <div style={{margin:'20px',cursor:'pointer',display:'flex',alignItems:'center'}}>
                            <div style={{marginRight:'20px'}} onClick={()=>{document.getElementById('editSelectedRows').style.display='flex';}}>
                                Edit Selected Offers
                            </div>
                            <div onClick={async(e)=>{if(tableValuesSelected.length!==tableValues.length){await updateRevenueData(e,0,'reset');}else{await updateTotalApprovedRowApi(0,0,'reset');getRevenueData();setTableValueSelected([]);}}}>
                                Reset
                            </div>
                        </div>
                    </div>
                }
            </div>
            {loader.main
            &&
            <ShowLoaderComponent/>
            }
            {!loader.main
            &&
            typeof tableValues === 'object' && 
            tableValues.length>0 
            &&
            <TableModel
                showCheckbox={filterData.advertiser && (!checkForPermission('can_view_billing_only') || isSuperUser()) && totalRow && totalRow.length>0 ? true : false}
                serialOffset={offset}
                valuesCheckbox={tableValuesSelected}
                setValuesCheckbox={setTableValueSelected}
                tableHeaders={tempTableHeaders}
                // setTableHeaders={setTableHeaders}
                tableValues={tableValues}
                setTableValues={setTableValues}
                style={{background:'rgb(245,245,245)'}}
                tableValueRow={'rest'}
                setTableValueRow={()=>{console.log('test')}}
                totalRow={totalRow.length>0 ? totalRow : undefined}
                setTotalRow={setTotalRow}
                sortingFunction={sortingFunction}
                exportFunction={()=>{getRevenueData(null,null,null,null,null,true)}}
                />
            }
            {
                addMissingOfferPopup
                &&
                <MissingOfferComponent
                    addMissingOfferPopup={addMissingOfferPopup}
                    setAddMissingOfferPopup={setAddMissingOfferPopup}
                    advertiserOptions={optionsAdvertiserList}
                    monthSelected={monthSelected}
                />
            }
            {
                (noteMenuReadOnlyClicked || noteMenuReadOnlyClicked===0) &&
                <div style={{position:'fixed',top:0,left:0,height:'100vh',width:'100vw',background:'rgba(0,0,0,0.1)',backdropFilter:'blur(2px)',zIndex:'900',display:'flex',alignItems:'center',justifyContent:'center'}} onClick={()=>{setNoteMenuReadOnlyClicked(false);}}>
                    <div style={{minHeight:'200px',minWidth:'300px',width:'50vw',background:'white'}} onClick={(e)=>{e.stopPropagation();}}>
                        <div style={{padding:'5px',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <label>Note - Offer ID{"("+tableValues[noteMenuReadOnlyClicked].offer_id+")"}</label>
                            <div style={{cursor:'pointer'}} onClick={()=>{setNoteMenuReadOnlyClicked(false);}}><CloseIcon/></div>
                        </div>
                        <div style={{marginLeft:'10px',marginRight:'10px',borderBottom:'1px solid rgb(220,220,220)'}}/>
                        <div style={{margin:'15px',padding:'5px'}}>
                            <table style={{tableLayout:'fixed',borderSpacing:'0px'}}>
                                <tbody>
                                    {['Panel Revenue Accuracy','Validation Percentage','Deduction','Pending'].map((item,index)=>{
                                        return <tr style={{fontSize:'14px'}}>
                                            <td width={'30%'} style={{fontWeight:'500',padding:'3px'}}>{item}</td>
                                            <td style={{padding:'3px'}}>{
                                                <CssTextField
                                                    disabled
                                                    multiline
                                                    size='small'
                                                    fullWidth
                                                    value={getNoteValue(item,noteMenuReadOnlyClicked)}
                                                />
                                                
                                            }</td>
                                        </tr>
                                    })}
                                
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
            {
                noteMenuClicked && (noteMenuClicked.valueIndex || noteMenuClicked.valueIndex===0) &&
                <div style={{position:'fixed',top:0,left:0,height:'100vh',width:'100vw',background:'rgba(0,0,0,0.1)',backdropFilter:'blur(2px)',zIndex:'900',display:'flex',alignItems:'center',justifyContent:'center'}} onClick={()=>{setNoteMenuClicked(false);setNoteData({...noteData,[noteMenuClicked]:false});}}>
                    <div style={{minHeight:'200px',minWidth:'300px',width:'50vw',background:'white'}} onClick={(e)=>{e.stopPropagation();}}>
                        <div style={{padding:'5px',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <label>Insert Note - Offer ID{"("+tableValues[noteMenuClicked.valueIndex].offer_id+")"}</label>
                            <div style={{cursor:'pointer'}} onClick={()=>{setNoteMenuClicked(false);setNoteData({...noteData,[noteMenuClicked]:false});}}><CloseIcon/></div>
                        </div>
                        <div style={{marginLeft:'10px',marginRight:'10px',borderBottom:'1px solid rgb(220,220,220)'}}/>
                        <div style={{margin:'15px'}}>
                            <CssTextField
                            multiline
                            minRows={4}
                            maxRows={100}
                            fullWidth
                            value={getNoteValue()}
                            onChange={changeNoteValue}

                            // value={noteData && (noteData[noteMenuClicked.valueIndex] || noteData[noteMenuClicked.valueIndex]==="")  ? noteData[noteMenuClicked.valueIndex] : tableValues[noteMenuClicked.valueIndex].note ? tableValues[noteMenuClicked.valueIndex].note : ''}
                            // onChange={(e)=>{setNoteData({...noteData,[noteMenuClicked.valueIndex]:{[noteMenuClicked.label]:e.target.value}})}}
                            />
                        </div>
                        <div style={{margin:'15px',display:'flex',alignItems:'center'}}>
                            <div onClick={(e)=>{updateRevenueData(e,noteMenuClicked.valueIndex,'note');setNoteMenuClicked(false);}} style={{height:'30px',width:'30px',background:'#e8e8e8',border:'1px solid rgb(200,200,200)',cursor:'pointer',display:'flex',alignItems:'center',justifyContent:'center',color:'green'}}>
                                <CheckIcon/>
                            </div>
                            <div onClick={()=>{setNoteData({...noteData,[noteMenuClicked]:false});setNoteMenuClicked(false);}} style={{height:'30px',width:'30px',marginLeft:'10px',border:'1px solid rgb(200,200,200)',cursor:'pointer',background:'#e8e8e8',display:'flex',alignItems:'center',justifyContent:'center',color:'red'}}>
                                <ClearIcon/>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {!loader.main && totalResults/limit>1 && typeof tableValues === 'object' && tableValues.length>0 && 
                <div style={{display:'flex',alignItems:'center',justifyContent:"space-between",paddingLeft:'20px',paddingRight:'20px',paddingBottom:'20px',position:'sticky',bottom:0,left:0,background:'white',zIndex:899}}>
                    <div>
                        <label style={{paddingRight:"5px",fontSize:'14px'}}>Limit</label>
                        <select value={limit} style={{outline:'none',border:'none'}} onChange={(e)=>{getRevenueData(e,e.target.value,1);setLimit(parseInt(e.target.value));setPage(1);}}>
                            {[5,16,10,50,100,200,300,500,1000].map((item,index)=>{
                                return <option key={index} value={item}>{item}</option>
                            })}
                            {/* {statsType!=='offer' && [25,50,75,100].map((item,index)=>{
                                return <option key={index} value={item}>{item}</option>
                            })} */}
                        </select>
                    </div>
                    <Pagination count={Math.ceil(totalResults/limit)} page={page} onChange={(event,value)=>{getRevenueData(event,limit,value);setPage(value);refreshComponent(event);window.scrollTo({top: 0,left: 0,behavior: 'smooth'});}} />
                </div>
            }
            {
                tableValuesSelected && tableValuesSelected.length>0
                &&
                <div id='editSelectedRows' style={{display:'none',height:'100vh',width:'100vw',position:'fixed',top:0,left:0,justifyContent:'center',alignItems:'center',zIndex:'100000'}}>
                    <div style={{minHeight:'300px',minWidth:'300px',height:'40vh',width:'40vw',background:'white',border:'1px solid rgb(200,200,200)'}}>
                        <div style={{width:'calc(100% - 20px)',display:'flex',padding:'10px',alignItems:'center',justifyContent:'space-between',borderBottom:'1px solid rgb(200,200,200)'}}>
                            <div>Edit Offer Data </div>
                            <Tooltip title={'Close'}>
                                <Close sx={{color:'grey'}} onClick={()=>{document.getElementById('editSelectedRows').style.display='none';}}/>
                            </Tooltip>
                        </div>
                        <div style={{minHeight:'250px',width:'100%',display:'flex',justifyContent:'center',flexDirection:'column'}}>
                            <div style={{display:'flex',width:'100%',justifyContent:"center",alignItems:'center'}}>
                                <div style={{width:'calc(50% - 20px)',textAlign:'right',marginRight:'20px'}}>Manual Revenue : </div>
                                <div style={{width:'50%'}}><CssTextField size='small'  onChange={e=>{if(e.target.value.match(/^\d+\.?\d*$/) || e.target.value===''){setTableValueSelectedUpdatedData({...tableValueSelectedUpdatedData,conversions_verified:e.target.value})}}}/></div>
                            </div>
                            <div style={{display:'flex',marginTop:'20px',width:'100%',justifyContent:"center",alignItems:'center'}}>
                                <div style={{width:'calc(50% - 20px)',textAlign:'right',marginRight:'20px'}}>Approved Revenue : </div>
                                <div style={{width:'50%'}}><CssTextField size='small' onChange={e=>{if(e.target.value.match(/^\d+\.?\d*$/) || e.target.value===''){setTableValueSelectedUpdatedData({...tableValueSelectedUpdatedData,manager_approved_revenue:e.target.value})}}}/></div>
                            </div>
                            <div style={{display:'flex',marginTop:'30px',width:'100%',justifyContent:"center",alignItems:'center'}}>
                                <Button id='testbutton' disabled={updateDataLoader} size='small' onClick={(e)=>{updateRevenueData(e,0,'both')}} sx={{...Constants.OUTLINEDBUTTON,fontSize:'14px',transform:'scale(0.8)',width:'102px',minHeight:'40px'}}>
                                    {updateDataLoader && 
                                        <CircularProgress size={'15px'} sx={{color:localStorage.getItem('ambientColor')}}/>
                                    }
                                    {!updateDataLoader && 'Submit'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {!loader.main
                &&typeof tableValues === 'object' && 
                tableValues.length===0 &&
                <NoResultsFound/>
            }
            {!loader.main
                &&
                typeof tableValues === 'string' &&
                <SomeErrorOccured error={tableValues}/>
            }
        </div>
    )
}

export default AdvertiserBillingV2