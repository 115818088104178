import { CircularProgress } from '@mui/material'
import React from 'react'

const ShowLoaderComponent = () => {
  return (
    <div style={{minHeight:'60vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
        <CircularProgress sx={{color:localStorage.getItem('ambientColor')}}/>
    </div>
  )
}

export default ShowLoaderComponent