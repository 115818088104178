import React, { useState } from 'react';
import ReactSelect from 'react-select';
import { NavLink } from "react-router-dom";
import axios from 'axios';
import { Button, CircularProgress } from '@mui/material';

import { increase_brightness } from '../../components/customization/customization';
import TableModal from '../../components/tableModal/tableModal';
import '../../css/pages/v2/advertiser.css';   //// PLEASE USE THIS CSS ONLY FOR AFFILIATE PAGE ALSO
import * as Constants from '../../data/constants';
import { getLocalStorageValue } from '../../util/util';
import PaginationComponent from '../../components/pagination/pagination';

const SmartLinks = () => {
    /// note -----  all advertiser variables are equivalent to affiliate variables, if you have no work please help me replace them with affiliate.
    document.title = 'Smartlinks'
    var tempLimit = 50
    var tempPage = 1
    var tempAdvId = ''

    if(window.location.href.includes("?")){
        var newUrl = new URL(window.location.href)
        if(newUrl.searchParams.get('limit')){
            tempLimit = parseInt(newUrl.searchParams.get('limit'))
        }
        if(newUrl.searchParams.get('page')){
            tempPage = parseInt(newUrl.searchParams.get('page'))
        }
        if(newUrl.searchParams.get('id')){
            tempAdvId = newUrl.searchParams.get('id')
        }
    }

    const [manualUseEffect, setManualUseEffect] = useState(false)
    const [filterSearch, setFilterSearch] = useState({id:tempAdvId})
    const [advertiserData, setAdvertiserData] = useState([])
    const [advertisersTotalCount, setAdvertisersTotalCount] = useState(0)
    const [loader, setLoader] = useState(false)

    const [page, setPage] = useState(tempPage)
    const [limit, setLimit] = useState(tempLimit)

    var changeUrlParams = (obj) => {
        var str = [];
        for(var p in obj){
            if(obj[p]){
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        }
        var paramString = str.join("&");
        var url = window.location.pathname
        url+= '?'+paramString
        window.history.pushState('Smartlinks', 'Smartlinks', url);
    }
    const showAdvertiserRequest = async(manualLimit,manualPage) => {
        /// FOR URL 
        var urlParams = {
            limit:manualLimit ? manualLimit : limit,
            page:manualPage ? manualPage : page,
            id : filterSearch.id ? filterSearch.id : null,  //Search
        }
        changeUrlParams(urlParams)
        
        var filter = 'ids'
        var value = filterSearch.id
        var params = {
            limit:manualLimit ? manualLimit : limit,
            page:manualPage ? manualPage : page,
            filter : value ? filter : null,
            value : value ? value : null
            //Search
        }
        setLoader(true)
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'smartlinks'),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params
        }
        )
        .then((resp) => {
            setAdvertiserData(resp.data.result)
            setAdvertisersTotalCount(resp.data.count)
            setLoader(false)
        })
        .catch(function (error) {
            setLoader(false)
            if(error.response.data){
                setAdvertiserData("errorFromAxios"+error.response.data.result)
            }
            setAdvertisersTotalCount('0');
        });
        setLoader(false)
    };

    if(!manualUseEffect){
        setManualUseEffect(true)
        showAdvertiserRequest()
    }
    var filterOptions = [
        {id:'id',label:'Search With Id',type:'textfield',value:filterSearch['id'],setValue:setFilterSearch,minWidth:220},
        // {id:'name',label:'Name',type:'textfield',value:filterSearch['name'],setValue:setFilterSearch,minWidth:220,},
    ]

    const companyName = (value,tableHeadIndex,item) => {
        return <NavLink style={{textDecoration:'none',color:localStorage.getItem('ambientColor')}} to={'/smartlinks/edit/'+item.id}>
            {value}
        </NavLink>
    }

    var tempTableHeaders = [
        // {id:'checkbox',label:'',value:'checkbox',show:true,width:'2%',minWidth:30,whatToSelect:'id'},
        {label:'ID',value:'id',show:true,width:'6%',minWidth:60,},
        {label:'Name',value:'name',show:true,width:'13%',minWidth:80,alignHead:'left',alignValue:'left',color:localStorage.getItem('ambientColor'),customComponent:companyName},
        {label:'Description',value:'description',alignHead:'left',alignValue:'left',show:true,width:'10%',minWidth:50},
        {label:'Privacy',value:'privacy',show:true,width:'10%',minWidth:50},
        {label:'Offers',value:'offer_count',show:true,width:'10%',minWidth:50},
        // {label:'',value:'',show:true,width:'2%',hideSorting:true,minWidth:50,customComponent:editButtonComponent},
    ]

    if(getLocalStorageValue(window.location.pathname)){
        try{
            var hideShowColumns = getLocalStorageValue(window.location.pathname)
            for(var index in tempTableHeaders){
                tempTableHeaders[index].hide = hideShowColumns[index].hide
            }
        }
        catch{

        }
    }
    const [tableHeaders, setTableHeaders] = useState(tempTableHeaders)
    
    // const gridComponent = () => {
    //     return advertiserData.map((item,index)=>{
    //         return <div className='gridComponentAdvertiser'>

    //         </div>
    //     })
    // }

    return (
        <div className='advertiserPageV2'>
            <div className='containerFirstRow'>
                <div> Found {advertisersTotalCount || advertisersTotalCount===0 ? advertisersTotalCount : <CircularProgress sx={{color:localStorage.getItem('ambientColor'),paddingLeft:'5px',paddingRight:'5px'}} size={13}/>} Smarlinks</div>
                <div className='containerFirstRowColTwo'>
                    <NavLink to={'/smartlinks/add'} style={{textDecoration:'none'}}>
                        <Button sx={{...Constants.OUTLINEDBUTTON,height:'30px',fontSize:'15px',textTransform:'none',fontWeight:500}}> Add Smartlink</Button>
                    </NavLink>
                </div>
            </div>
            <div className='containerSecondRow'>
                {filterOptions.map((filterItem,filterIndex)=>{
                    if(filterItem.type==='textfield'){
                        return <div key={filterIndex} style={{width:(filterItem.minWidth ? filterItem.minWidth : 'max-content'),marginRight:'15px',marginTop:20}}>
                                <Constants.CssTextField 
                                    size='small' 
                                    disabled={filterItem.disable} 
                                    fullWidth 
                                    value={filterSearch[filterItem.id]}
                                    placeholder={filterItem.label}
                                    onChange={(e)=>{setFilterSearch({...filterSearch,[filterItem.id]:e.target.value})}} 
                                    onKeyDown={(e)=>{if(e.key==='Enter' && !loader){showAdvertiserRequest(limit,1)}}}
                                    />
                            </div>
                    }
                    else if(filterItem.type==='select'){
                        return <div key={filterIndex} style={{width:(filterItem.minWidth ? filterItem.minWidth : 'max-content'),marginRight:'15px',marginTop:20}}>
                            <ReactSelect
                                isDisabled={filterItem.disable}
                                key={filterIndex} 
                                options={filterItem.options}
                                value={filterSearch[filterItem.id]}
                                onChange={(e)=>{setFilterSearch({...filterSearch,[filterItem.id]:e})}}
                                menuPortalTarget={document.body} 
                                placeholder={"Select "+filterItem.label}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 1000000 }),
                                            control: (base,state) => ({
                                                ...base,
                                                // maxHeight: 100,
                                                minHeight: 30,
                                                color:'rgb(200,200,200)',
                                                overflowY: "auto",
                                                fontSize:14,
                                                border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                                '&:hover': {
                                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                    boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                                }
                                            }),
                                            placeholder: (defaultStyles) => {
                                                return {
                                                    ...defaultStyles,
                                                    color: 'rgb(150,150,150)',
                                                    marginTop:'-2px'
                                                }
                                            },
                                            option: (styles, {isFocused, isSelected}) => ({
                                                ...styles,
                                                fontSize:'11px',
                                                background: isFocused
                                                    ? localStorage.getItem('ambientColor')
                                                    : isSelected
                                                        ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                        : undefined,
                                                zIndex: 1
                                            }),
                                        }}
                            />
                        </div>
                    }
                    return null;
                })}
                <Button disabled={loader ? true : false} sx={{...Constants.CONTAINEDBUTTON,minHeight:'40px',fontSize:'15px',minWidth:'80px',paddingLeft:'15px',paddingRight:'15px',marginTop:'20px',textTransform:'none',fontWeight:500,marginLeft:'15px'}} onClick={()=>{showAdvertiserRequest(limit,1)}}>
                    {loader && 
                    <CircularProgress sx={{color:'white'}} size={20}/>
                    }
                    {!loader && "Search"}
                </Button>
                <Button sx={{...Constants.OUTLINEDBUTTON,minHeight:'40px',fontSize:'15px',paddingLeft:'15px',paddingRight:'15px',marginTop:'20px',textTransform:'none',fontWeight:500,marginLeft:'15px'}} onClick={()=>{setFilterSearch({id:'',adv_name:'',adv_email:'',manager:'',tags:''})}}>Reset</Button>
           </div>

            <TableModal 
                stickyColumn={[0,1,2]} 
                rightStickyColumnList={[tableHeaders.length-1]} 
                showLoader={loader} tableHeaders={tableHeaders} 
                setTableHeaders={setTableHeaders} 
                tableValues={advertiserData} 
                setTableValues={setAdvertiserData} 
                hideTooltip={true} 
                showCustomization={true}
            />
            <PaginationComponent page={page} setPage={setPage} limit={limit} setLimit={setLimit} totalResults={advertisersTotalCount} apiRequest={showAdvertiserRequest}/>
            
        </div>
    )
}

export default SmartLinks;