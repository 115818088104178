import React from 'react';
import axios from 'axios';
import { DateRangePicker } from 'react-date-range';
import { useState } from 'react';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import * as Constants from '../../data/constants';
import CircularProgress from '@mui/material/CircularProgress';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CreatableSelect from 'react-select/creatable';

// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';

// import Popup from '../../../components/afterlogincomponents/popup';
import NativeSelect from '@mui/material/NativeSelect';
import '../../css/pages/v2/stats.css';
import StatisticsRouter from './statisticsRouter';
import { getLocalStorageValue, sorting } from '../../util/util';
// import id from 'date-fns/locale/id';
// import { NavLink } from 'react-router-dom';
import Select from 'react-select';
import { Button } from '@mui/material';
import ArrangeColumns from '../../components/arrangeColumns/arrangeColumns';
// import RestartAltIcon from '@mui/icons-material/RestartAlt';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// import SettingsIcon from '@mui/icons-material/Settings';
import Cookies from 'universal-cookie';
import { Resizable } from "re-resizable";
import DownloadStats from './downloadStats';
import { Store } from 'react-notifications-component';

function useForceUpdate(){
    const [value, setValue] = React.useState(0); // integer state
    if(false){
        console.log(value)
    }
    return () => setValue(value => value + 1); // update the state to force render
}

export const CustomStats = () => {
    const cookies = new Cookies();
    const refreshComponent = useForceUpdate()

    var searchParamsList = [
        {label:'offerId',setValueList:[],},
        {label:'advertiser',setValueList:[],},
        {label:'affiliate',setValueList:[],},
        {label:'advertiserManager',setValueList:[],},
        {label:'affiliateManager',setValueList:[],},
        {label:'timezone',setValueList:getLocalStorageValue('panelTimezone') ? getLocalStorageValue('panelTimezone') : {label : "UTC (UTC+00:00)" , value : "UTCUTC+00:00"},},
        {label:'externalOfferId',setValueList:[],},
    ]
    var url = new URL(window.location.href)
    if(url.searchParams.get('offerId') || url.searchParams.get('externalOfferId') || url.searchParams.get('advertiser') || url.searchParams.get('affiliate') || url.searchParams.get('affiliateManager') || url.searchParams.get('advertiserManager')){
        for(var searchParamsListIndex in searchParamsList){
            var elem = searchParamsList[searchParamsListIndex]
            if(url.searchParams.get(elem.label)){
                var selectList = []
                var queryTextList = url.searchParams.get(elem.label).split(",")
                for(var index in queryTextList){
                    if(elem.label!=='offerId'){
                        selectList.push({
                            label: queryTextList[index],
                            value: queryTextList[index].substring(queryTextList[index].lastIndexOf("-") + 1, queryTextList[index].length)
                        })
                    }
                    else{
                        selectList.push({
                            label: queryTextList[index],
                            value: queryTextList[index],
                        })
                    }
                }
                elem.setValueList = selectList
            }
        }
    }
    
    const [value, setValue] = React.useState("");
    const [requestDone, setRequestDone] = React.useState(false);
    // const [timezoneDifference,setTimezoneDifference] =  React.useState(19800000);
    const [clickConversionRatioResponse,setclickConversionRatioResponse] = React.useState({loaded:false,data:{}});
    const [page, setPage] = React.useState(1);
    const [showLoader, setShowLoader] = React.useState(true);
    const [showDatePicker, setShowDatePicker] = React.useState(false);
    const [useEffectVar, setUseEffectVar] = React.useState(false);
    const [popupOpen, setPopupOpen] = React.useState(false);
    const [error, setError] = React.useState({data:"",status:""});
    const [limit, setLimit] = React.useState(25);
    const [dateRangeValue, setDateRangeValue] = React.useState([new Date(new Date().getTime()-86400000*6),new Date()]); //true for int and false for string
    const [currency, setCurrency] = React.useState("USD"); //true for int and false for string
    
    const [showExportPopup, setShowExportPopup] = React.useState(false);

    const [affiliateList,setAffiliateList] = React.useState([]);
    const [advertisersList,setAdvertisersList] = React.useState([])
    const [managersList,setManagersList] = React.useState([])
    const [affiliateSelected,setAffiliateSelected] = React.useState(searchParamsList[2].setValueList)
    const [advertiserSelected,setAdvertiserSelected] = React.useState(searchParamsList[1].setValueList)
    const [affiliateManagerSelected,setAffiliateManagerSelected] = React.useState(searchParamsList[4].setValueList)
    const [advertiserManagerSelected,setAdvertiserManagerSelected] = React.useState(searchParamsList[3].setValueList)
    const [goalValueSelected,setGoalValueSelected] = React.useState()
    const [searchedOffer,setSearchedOffer] = React.useState("")

    const [requestOrder,setRequestOrder] = React.useState("date")
    const [requestOrderBy,setRequestOrderBy] = React.useState("DESC")

    const [searchedOffersList, setSearchedOffersList] = React.useState(searchParamsList[0].setValueList)
    const [requestColumns, setRequestedColumns] = React.useState([])
    const [dataItemsAdded, setDataItemsAdded] = React.useState(0)
    const [timezoneSelected, setTimezoneSelected] = React.useState(getLocalStorageValue('panelTimezone') ? getLocalStorageValue('panelTimezone') : false)

    const [customStatsData, setCustomStatsData] = useState({
        Data:[
                {label:'Year',value:'year',checked:false,width:50},
                {label:'Month',value:'month',checked:false,width:50},
                {label:'Day',value:'day',checked:false,width:50},
                {label:'Hour',value:'hour',checked:false,width:50},
                // {label:'Currency',value:'currency',checked:false,width:60},
                {label:'Affiliate ID',value:'affiliate_id',checked:false,width:70,
                    children:[
                        {label:'Affiliate Name',value:'affiliate',checked:false,width:130},
                    ]    
                },
                {label:'Affiliate Manager',value:'affiliate_manager_id',checked:false,width:120,
                    // children:[
                    //     {label:'Affiliate Manager',value:'affiliate_manager',checked:false,width:120},
                    // ]
                },
                
                
                {label:'Advertiser ID',value:'advertiser_id',checked:false,width:80,
                    children:[
                        {label:'Advertiser Name',value:'advertiser',checked:false,width:120},
                    ]},
                {label:'Advertiser Manager',value:'advertiser_manager_id',checked:false,width:120,
                // children:[
                    // {label:'Advertiser Manager',value:'advertiser_manager',checked:false,width:120},
                // ]
                },
                {label:'Offer',value:'offer',checked:false,width:180,children:[
                    {label:'Offer ID',value:'offer_id',checked:false,width:50}, 
                ]},
            ],
        "Traffic":[
            {label:'Clicks',value:'clicks',checked:false,width:70},
        ],
        "Extra Data":[
                {label:'Goal Value',value:'goal_value',checked:false,width:70},
                {label:'Sub1',value:'sub1',checked:false,width:70},
                {label:'Sub2',value:'sub2',checked:false,width:70},
                {label:'Sub3',value:'sub3',checked:false,width:70},
                {label:'Sub4',value:'sub4',checked:false,width:70},
                {label:'Sub5',value:'sub5',checked:false,width:70},
                {label:'Sub6',value:'sub6',checked:false,width:70},
                {label:'Sub7',value:'sub7',checked:false,width:70},
                {label:'Sub8',value:'sub8',checked:false,width:70},
            ],
        Statistics:[
                // {label:'Impressions',value:'impressions',checked:false,width:120},
                // {label:'Clicks',value:'clicks',checked:false,width:120},
                // {label:'Trafficback',value:'trafficback',checked:false,width:120},
                {label:'Payouts',value:'payout',checked:false,width:70},
                {label:'Revenue',value:'revenue',checked:false,width:70},
                {label:'Earnings',value:'earnings',checked:false,width:70},
        ],
        Conversions:[
                {label:'Delivered Conversions',value:'approved',checked:false,width:140},
                {label:'Declined Conversions',value:'declined',checked:false,width:140},
                {label:'Total Conversions',value:'total_conversion',checked:false,width:120},
        ],
        // Calculations:[
        //     {label:'CR',value:'cr',checked:false,width:120},
        //     {label:'EPC',value:'epc',checked:false,width:120},                                                                    
        // ]
    })

    const [editColumnsPopup, setEditColumnsPopup] = React.useState(false);
    const [editColumnsList, setEditColumnsList] = React.useState([]);

    // const resetColumnList = [
    //    ]

    const handleChange = (event, value) => {
        setPage(value);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        }
        );
        getClickConversionRatioRequest(dateRangeValue,true,limit,currency,value)
    };

    const togglePopup = (closeButton=false) => {
        setPopupOpen(!popupOpen);
        // if (closeButton){
        //     window.location.reload();
        // }
        }

    const changeDate = () => {
        setShowDatePicker(true)
    }

    const dateRangePicked = (item) => {
        setState([item.selection]);
        if (item.selection.endDate !== item.selection.startDate){
            setShowDatePicker(false)
            // setShowLoader(true);
            var value = [item.selection.startDate,item.selection.endDate]
            if (value[0] && value[1]){
                setDateRangeValue(value)
                setValue("".concat(value[0].getDate(),"/",(value[0].getMonth()+1),"/",value[0].getFullYear()," -> ",value[1].getDate(),"/",(value[1].getMonth()+1),"/",value[1].getFullYear()))
                // getClickConversionRatioRequest(value,true)
                }
        }
    }
    
    const [state, setState] = useState([
        {
          startDate: new Date(new Date().getTime()-86400000*6),
          endDate: new Date(),
          key: 'selection'
        }
      ]);

    const getAdvertisers = async() => {
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.ADVERTISER,"/","details"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        })
        .then(resp => {
            let advertiserlist = []
            for (var item in resp.data.result){
                advertiserlist.push({label:resp.data.result[item][Object.keys(resp.data.result[item])[0]] + " - " + Object.keys(resp.data.result[item])[0],value:Object.keys(resp.data.result[item])[0]})
            }
            sorting(advertiserlist,'label',false,true)
            setAdvertisersList(advertiserlist)
        })
        .catch(err => {
            console.error(err);
        })
    };
      
    const getAffiliates = async() => {
      axios({
          method: 'GET',
          url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"affiliate/details"),
          headers:{
              Authorization:getLocalStorageValue('adminPanelLoginData').token
          },
      })
      .then(resp => {
          var affiliatelist = [];
          affiliatelist.push()
          for (var affiliate in resp.data.result){
              affiliatelist.push({label:resp.data.result[affiliate][Object.keys(resp.data.result[affiliate])[0]] + " - " + Object.keys(resp.data.result[affiliate])[0] ,value:Object.keys(resp.data.result[affiliate])[0]});
          }
          sorting(affiliatelist,'label',false,true)
          setAffiliateList(affiliatelist);
      })
      .catch(err => {
          console.error(err);
      }) 
    };  

    const getManagers = async() => {
        axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"manager/details"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        })
        .then(resp => {
            var managersList = [];
            for (var affiliate in resp.data.result){
                managersList.push({label:resp.data.result[affiliate][Object.keys(resp.data.result[affiliate])[0]] + " - " + Object.keys(resp.data.result[affiliate])[0] ,value:Object.keys(resp.data.result[affiliate])[0]});
            }
            sorting(managersList,'label',false,true)
            setManagersList(managersList);
        })
        .catch(err => {
            console.error(err);
        }) 
      };  
  
    const getClickConversionRatioRequest = async(dateList,comingNotFromReload=false,manual_limit,manualCurrency,manual_page,manual_orderField,manual_orderby,manual_offerid,manual_advertiser,manual_affiliate,manual_advertiser_manager,manual_affiliate_manager) => {

        setShowLoader(true)
        var fromDate = new Date(dateList[0].getTime())
        var toDate = new Date(dateList[1].getTime())

        setValue("".concat(fromDate.getDate(),"/",(fromDate.getMonth()+1),"/",fromDate.getFullYear()," -> ",toDate.getDate(),"/",(toDate.getMonth()+1),"/",toDate.getFullYear()))
        
        fromDate = fromDate.getFullYear() +"-"+(fromDate.getMonth()+1)+"-"+fromDate.getDate()
        toDate = toDate.getFullYear() +"-"+(toDate.getMonth()+1)+"-"+toDate.getDate()
        if (!requestDone){
            if(affiliateSelected && affiliateSelected.length>0){
                var affiliate_id = ""
                for(var index in affiliateSelected){
                    affiliate_id+=affiliateSelected[index].value + ","
                }
                affiliate_id = affiliate_id.slice(0,affiliate_id.length-1)
            }
            if(advertiserSelected && advertiserSelected.length>0){
                var advertiser_id = ""
                for(index in advertiserSelected){
                    advertiser_id+=advertiserSelected[index].value + ","
                }
                advertiser_id = advertiser_id.slice(0,advertiser_id.length-1)
            }
            if(advertiserManagerSelected && advertiserManagerSelected.length>0){
                var advertiser_manager_id = ""
                for(index in advertiserManagerSelected){
                    advertiser_manager_id+=advertiserManagerSelected[index].value + ","
                }
                advertiser_manager_id = advertiser_manager_id.slice(0,advertiser_manager_id.length-1)
            }
            if(affiliateManagerSelected && affiliateManagerSelected.length>0){
                var affiliate_manager_id = ""
                for(index in affiliateManagerSelected){
                    affiliate_manager_id+=affiliateManagerSelected[index].value + ","
                }
                affiliate_manager_id = affiliate_manager_id.slice(0,affiliate_manager_id.length-1)
            }

            if(goalValueSelected && goalValueSelected.length>0){
                var goal_value = ""
                for(index in goalValueSelected){
                    goal_value+=goalValueSelected[index].value + ","
                }
                goal_value = goal_value.slice(0,goal_value.length-1)
            }


            if(requestColumns.length>0){
                var custom_columns = ""
                for(index in requestColumns){
                    custom_columns+=requestColumns[index] + ","
                }
                custom_columns = custom_columns.slice(0,-1)
            }
            setRequestDone(true);

        var dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric'};
        var startDate = new Date(dateList[0]).toLocaleDateString("sv-SE", dateOptions)
        var endDate = new Date(dateList[1]).toLocaleDateString("sv-SE", dateOptions)

        return await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.GETSTATS,"/custom"),
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token
              },
            params: {
                startDate,
                endDate,
                // tz : "utc",
                currency:selectedCurrency && selectedCurrency.toUpperCase()==='ALL/USD' ? undefined : selectedCurrency,
                limit:manual_limit? manual_limit : limit,
                page:manual_page?manual_page : page,
                offer_id:manual_offerid ? manual_offerid : (searchedOffer ? searchedOffer : null),
                advertiser_id,
                advertiser_manager_id,
                affiliate_id,
                affiliate_manager_id,
                goal_value,
                // order_by : manual_orderby ? manual_orderby : requestOrderBy,
                // order_field : manual_orderField ? manual_orderField : requestOrder,
                custom_columns,
                utc_offset : timezoneSelected.value ? timezoneSelected.value.split(timezoneSelected.label.split(" (UTC")[0])[timezoneSelected.value.split(timezoneSelected.label.split(" (UTC")[0]).length-1].split("UTC")[1] : "+0:00",
            }
        })
        .then(resp => {  
            if (resp.data && (clickConversionRatioResponse.loaded===false || comingNotFromReload)){
                var tempEditColumnList = []
                for(var key in customStatsData){
                    for(var keyIndex in customStatsData[key]){
                        if(requestColumns.includes(customStatsData[key][keyIndex].value)){
                            tempEditColumnList.push({
                                id:(key+keyIndex).toString(),
                                label:customStatsData[key][keyIndex].label,
                                value:customStatsData[key][keyIndex].value,
                                show:customStatsData[key][keyIndex].checked,
                                parent:key,
                                width:customStatsData[key][keyIndex].width
                            })
                            if(customStatsData[key][keyIndex].children){
                                for(var childIndex in customStatsData[key][keyIndex].children){
                                    if(requestColumns.includes(customStatsData[key][keyIndex].children[childIndex].value)){
                                        tempEditColumnList.push({
                                            id:(key+keyIndex+childIndex).toString(),
                                            label:customStatsData[key][keyIndex].children[childIndex].label,
                                            value:customStatsData[key][keyIndex].children[childIndex].value,
                                            show:customStatsData[key][keyIndex].children[childIndex].checked,
                                            parent:key,
                                            width:customStatsData[key][keyIndex].children[childIndex].width
                                        })
                                    }
                                }
                            }
                        }
                    }
                }
                setEditColumnsList(tempEditColumnList)
                if(resp.data.result){
                    setclickConversionRatioResponse({loaded:true,data:resp.data.result})
                }
                setShowLoader(false)
                setRequestDone(false);
                setCurrency("usd");
            }
            })
        .catch((error) => {
            setclickConversionRatioResponse({loaded:false,data:{}})
            togglePopup(); 
            setShowLoader(false);
            setRequestDone(false);
            if (error.response) {
                setError({data:error.response.data,status:error.response.status})
                Store.addNotification({
                    title: "Warning!",
                    message: error.response.data.result,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true
                    },
                });
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else {
                // Something happened in setting up the request that triggered an Error
                Store.addNotification({
                    title: "Error!",
                    message: "Some Error occured. Please check data input.",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true
                    },
                });
                console.log('Error', error.message);
              }
        })
        }
    };
    
    const exportCustomStats = async(dateList) => {
        var fromDate = new Date(dateList[0].getTime())
        var toDate = new Date(dateList[1].getTime())

        setValue("".concat(fromDate.getDate(),"/",(fromDate.getMonth()+1),"/",fromDate.getFullYear()," -> ",toDate.getDate(),"/",(toDate.getMonth()+1),"/",toDate.getFullYear()))
        
        fromDate = fromDate.getFullYear() +"-"+(fromDate.getMonth()+1)+"-"+fromDate.getDate()
        toDate = toDate.getFullYear() +"-"+(toDate.getMonth()+1)+"-"+toDate.getDate()
        if(affiliateSelected && affiliateSelected.length>0){
            var affiliate_id = ""
            for(var index in affiliateSelected){
                affiliate_id+=affiliateSelected[index].value + ","
            }
            affiliate_id = affiliate_id.slice(0,affiliate_id.length-1)
        }
        if(advertiserSelected && advertiserSelected.length>0){
            var advertiser_id = ""
            for(index in advertiserSelected){
                advertiser_id+=advertiserSelected[index].value + ","
            }
            advertiser_id = advertiser_id.slice(0,advertiser_id.length-1)
        }
        if(advertiserManagerSelected && advertiserManagerSelected.length>0){
            var advertiser_manager_id = ""
            for(index in advertiserManagerSelected){
                advertiser_manager_id+=advertiserManagerSelected[index].value + ","
            }
            advertiser_manager_id = advertiser_manager_id.slice(0,advertiser_manager_id.length-1)
        }
        if(affiliateManagerSelected && affiliateManagerSelected.length>0){
            var affiliate_manager_id = ""
            for(index in affiliateManagerSelected){
                affiliate_manager_id+=affiliateManagerSelected[index].value + ","
            }
            affiliate_manager_id = affiliate_manager_id.slice(0,affiliate_manager_id.length-1)
        }

        if(goalValueSelected && goalValueSelected.length>0){
            var goal_value = ""
            for(index in goalValueSelected){
                goal_value+=goalValueSelected[index].value + ","
            }
            goal_value = goal_value.slice(0,goal_value.length-1)
        }

        if(requestColumns.length>0){
            var custom_columns = ""
            for(index in requestColumns){
                custom_columns+=requestColumns[index] + ","
            }
            custom_columns = custom_columns.slice(0,-1)
        }
        return await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.GETSTATS,"/custom/export"),
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token
              },
            params: {
                startDate: fromDate,
                endDate: toDate,
                // tz : "utc",
                stats_type:'Stats-Custom',
                offer_id:searchedOffer ? searchedOffer : null,
                advertiser_id,
                advertiser_manager_id,
                affiliate_id,
                affiliate_manager_id,
                currency:selectedCurrency && selectedCurrency.toUpperCase()==='ALL/USD' ? undefined : selectedCurrency,
                goal_value,
                // order_by : manual_orderby ? manual_orderby : requestOrderBy,
                // order_field : manual_orderField ? manual_orderField : requestOrder,
                custom_columns,
                utc_offset : timezoneSelected.value ? timezoneSelected.value.split(timezoneSelected.label.split(" (UTC")[0])[timezoneSelected.value.split(timezoneSelected.label.split(" (UTC")[0]).length-1].split("UTC")[1] : "+0:00",
            }
        })
        .then(resp => {  
            Store.addNotification({
                title: "Success!",
                message: resp.data.result,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2000,
                  onScreen: true
                },
            });
            })
        .catch((error) => {
            if (error.response) {
                setError({data:error.response.data,status:error.response.status})
                Store.addNotification({
                    title: "Warning!",
                    message: error.response.data.result,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true
                    },
                });
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else {
                // Something happened in setting up the request that triggered an Error
                Store.addNotification({
                    title: "Error!",
                    message: "Some Error occured. Please check data input.",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true
                    },
                });
                console.log('Error', error.message);
              }
        })
        
    }
    if (!useEffectVar){
        setUseEffectVar(true)
        document.title = "Statistics - Custom"
        window.scrollTo({
            top: 0,
            left: 0,
        }
        );
        setRequestOrder(null)
        setRequestOrderBy(null)
        getAffiliates();
        getAdvertisers();
        getManagers();
        setValue("".concat(dateRangeValue[0].getDate(),"/",(dateRangeValue[0].getMonth()+1),"/",dateRangeValue[0].getFullYear()," -> ",dateRangeValue[1].getDate(),"/",(dateRangeValue[1].getMonth()+1),"/",dateRangeValue[1].getFullYear()))
        if(cookies.get('customStats')){
            setEditColumnsList(cookies.get('customStats'))
        }
    }

    const handleLimitChange = (e) =>{
        setLimit(parseInt(e.target.value))
        setPage(1)
        // getClickConversionRatioRequest(dateRangeValue,true,e.target.value,currency,1)
    }
    
    const [headerScrollPosition, setHeaderScrollPosition] = useState(0)
    const showHeaders = () => {
       return <div id='customStatsHeaderV2' className='tableHeaderStatsV2' onScroll={(e)=>{document.getElementById('customStatsValueV2').scrollLeft = e.target.scrollLeft;setHeaderScrollPosition(e.target.scrollLeft);}}>
               {editColumnsList.map((tableHead,tableHeadIndex)=>{
                   if((tableHead.value==='payout' || tableHead.value==='revenue' ) && (requestColumns.includes('approved') || requestColumns.includes('declined')) ){
                        return <div key={tableHeadIndex} style={{display:'flex'}}>
                                {requestColumns.includes('approved') &&
                                    <Resizable
                                        key={tableHeadIndex}
                                        id={tableHead.value}
                                        style={{borderTop:'1px solid rgb(220,220,220',borderBottom:'1px solid rgb(220,220,220)',borderRight:'1px solid rgb(220,220,220)',cursor:'default',userSelect:'none',display:'flex',breakWord:'break-all',justifyContent:'center',alignItems:'center',color:'rgb(100,100,100)',padding:'5px',fontSize:'13px',paddingTop:'12px',paddingBottom:'12px'}}
                                        size={{width:tableHead.width+11}}
                                        enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                                        onResizeStart={()=>{
                                            document.getElementById('customStatsHeaderV2').scrollLeft = headerScrollPosition
                                        }}
                                        onResize={()=>{
                                            document.getElementById('customStatsHeaderV2').scrollLeft = headerScrollPosition
                                        }}
                                        onResizeStop={(e, direction, ref, d) => {
                                            var tempEditColumnList = editColumnsList
                                            tempEditColumnList[tableHeadIndex].width = tempEditColumnList[tableHeadIndex].width + d.width
                                            setEditColumnsList(tempEditColumnList);
                                            refreshComponent(e);
                                            }}
                                            >
                                            {"Delivered "+tableHead.label}
                                    </Resizable>
                                }
                                {requestColumns.includes('declined') &&
                                    <Resizable
                                        key={tableHeadIndex}
                                        id={tableHead.value}
                                        style={{borderTop:'1px solid rgb(220,220,220',borderBottom:'1px solid rgb(220,220,220)',borderRight:'1px solid rgb(220,220,220)',cursor:'default',userSelect:'none',display:'flex',breakWord:'break-all',justifyContent:'center',alignItems:'center',color:'rgb(100,100,100)',padding:'5px',fontSize:'13px',paddingTop:'12px',paddingBottom:'12px'}}
                                        size={{width:tableHead.width+11}}
                                        enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                                        onResizeStart={()=>{
                                            document.getElementById('customStatsHeaderV2').scrollLeft = headerScrollPosition
                                        }}
                                        onResize={()=>{
                                            document.getElementById('customStatsHeaderV2').scrollLeft = headerScrollPosition
                                        }}
                                        onResizeStop={(e, direction, ref, d) => {
                                            var tempEditColumnList = editColumnsList
                                            tempEditColumnList[tableHeadIndex].width = tempEditColumnList[tableHeadIndex].width + d.width
                                            setEditColumnsList(tempEditColumnList);
                                            refreshComponent(e);
                                            }}
                                            >
                                            {"Declined "+tableHead.label}
                                    </Resizable>
                                }
                                </div>
                   }
                   return <Resizable
                                key={tableHeadIndex}
                                id={tableHead.value}
                                style={{borderTop:'1px solid rgb(220,220,220',borderBottom:'1px solid rgb(220,220,220)',borderRight:'1px solid rgb(220,220,220)',cursor:'default',userSelect:'none',display:'flex',breakWord:'break-all',justifyContent:'center',alignItems:'center',color:'rgb(100,100,100)',padding:'5px',fontSize:'13px',paddingTop:'12px',paddingBottom:'12px'}}
                                size={{width:tableHead.width+11}}
                                enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                                onResizeStart={()=>{
                                    document.getElementById('customStatsHeaderV2').scrollLeft = headerScrollPosition
                                }}
                                onResize={()=>{
                                    document.getElementById('customStatsHeaderV2').scrollLeft = headerScrollPosition
                                }}
                                onResizeStop={(e, direction, ref, d) => {
                                    var tempEditColumnList = editColumnsList
                                    tempEditColumnList[tableHeadIndex].width = tempEditColumnList[tableHeadIndex].width + d.width
                                    setEditColumnsList(tempEditColumnList);
                                    refreshComponent(e);
                                    }}
                                    >
                                    {tableHead.label}
                        </Resizable>
               })}
            </div>
    }

    const showValue = () => {
        if(clickConversionRatioResponse.loaded && clickConversionRatioResponse.data){
            return <div id='customStatsValueV2' className='tableValueStatsV2' style={{overflow:'auto'}} onScroll={(e)=>{document.getElementById('customStatsHeaderV2').scrollLeft = e.target.scrollLeft;setHeaderScrollPosition(e.target.scrollLeft);}}>
                    {clickConversionRatioResponse.data.data.map((item,index)=>{
                        return <div key={index} style={{display:'flex'}}>
                            {editColumnsList.map((headerValue,headerindex)=>{
                                if((headerValue.value==='payout' || headerValue.value==='revenue') && (requestColumns.includes('approved') || requestColumns.includes('declined')) ){
                                    return <div style={{display:'flex'}}>
                                            {requestColumns.includes('approved') &&
                                                <div key={headerindex} style={{minWidth:headerValue.width,maxWidth:headerValue.width,width:headerValue.width,borderRight:'1px solid rgb(220,220,220)',borderBottom:'1px solid rgb(220,220,220)',cursor:'default',display:'flex',wordBreak:'break-all',wordWrap:'break-word',justifyContent:'center',alignItems:'center',color:'rgb(50,50,50)',fontSize:'12px'}} className='headerTileV2'>
                                                    {item['approved_'+headerValue.value] || item['approved_'+headerValue.value]===0 ? item['approved_'+headerValue.value] : '-'}
                                                </div>
                                                
                                            }
                                            {requestColumns.includes('declined') &&
                                            <div key={headerindex} style={{minWidth:headerValue.width,maxWidth:headerValue.width,width:headerValue.width,borderRight:'1px solid rgb(220,220,220)',borderBottom:'1px solid rgb(220,220,220)',cursor:'default',display:'flex',wordBreak:'break-all',wordWrap:'break-word',justifyContent:'center',alignItems:'center',color:'rgb(50,50,50)',fontSize:'12px'}} className='headerTileV2'>
                                                    {item['declined_'+headerValue.value] || item['declined_'+headerValue.value]===0 ? item['declined_'+headerValue.value] : '-'}
                                            </div>
                                            }
                                            </div>
                            }
                                // if(headerValue.label==='Payouts' && item[headerValue.value]){
                                //     return <div style={{minWidth:headerValue.width,maxWidth:headerValue.width,width:headerValue.width,borderRight:'1px solid rgb(220,220,220)',display:'flex',alignItems:'center',padding:'5px'}}>
                                //                 <div style={{width:'100%'}}>
                                //                     {item[headerValue.value].map((payoutItem,payoutIndex)=>{
                                //                         return <div key={payoutIndex} style={{display:'flex',width:'100%',alignItems:'center'}}>
                                //                             {['currency','approved_payout','declined_payout'].map((currencyItem,currencyIndex)=>{
                                //                                 return <div style={{width:'33%',textAlign:'center',fontSize:'12px',cursor:'default',color:'rgb(50,50,50)'}}>
                                //                                         {payoutItem[currencyItem]}
                                //                                     </div>
                                //                             })}
                                //                         </div>
                                //                     })}
                                //                 </div>
                                //             </div>
                                //     }

                                // if(headerValue.label==='Revenue' && item['payouts']){
                                //     return <div style={{minWidth:headerValue.width,maxWidth:headerValue.width,width:headerValue.width,borderRight:'1px solid rgb(220,220,220)',display:'flex',alignItems:'center',padding:'5px'}}>
                                //                 <div style={{width:'100%'}}>
                                //                     {item['payouts'].map((payoutItem,payoutIndex)=>{
                                //                         return <div style={{display:'flex',width:'100%',alignItems:'center'}}>
                                //                             {['currency','approved_revenue','declined_revenue'].map((currencyItem,currencyIndex)=>{
                                //                                 return <div style={{width:'33%',textAlign:'center',fontSize:'12px',cursor:'default',color:'rgb(50,50,50)'}}>
                                //                                         {payoutItem[currencyItem]}
                                //                                     </div>
                                //                             })}
                                //                         </div>
                                //                     })}
                                //                 </div>
                                //             </div>
                                //     }
                                // if(headerValue.label==='Earnings' && item['payouts']){
                                //     return <div style={{minWidth:headerValue.width,maxWidth:headerValue.width,width:headerValue.width,borderRight:'1px solid rgb(220,220,220)',display:'flex',alignItems:'center',padding:'5px'}}>
                                //                 <div style={{width:'100%'}}>
                                //                     {item['payouts'].map((payoutItem,payoutIndex)=>{
                                //                         return <div key={payoutIndex} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}>
                                //                             <div style={{width:'33%',textAlign:'center',fontSize:'12px',cursor:'default',color:'rgb(50,50,50)'}}>
                                //                                 {(parseFloat(payoutItem['approved_payout']) - parseFloat(payoutItem['approved_revenue'])).toFixed(3)}
                                //                             </div>
                                //                         </div>
                                //                     })}
                                //                 </div>
                                //             </div>
                                //     }
                                // if(headerValue.label==='Total Conversions'){
                                //     return <div key={headerindex} style={{minWidth:headerValue.width,maxWidth:headerValue.width,width:headerValue.width,borderRight:'1px solid rgb(220,220,220)',cursor:'default',display:'flex',wordBreak:'break-all',wordWrap:'break-word',justifyContent:'center',alignItems:'center',color:'rgb(50,50,50)',fontSize:'12px'}} className='headerTileV2'>
                                //         {item["approved"] + item['declined']}
                                //     </div>
                                // }
                                // if(headerValue.label==='CR'){
                                //     return <div key={headerindex} style={{minWidth:headerValue.width,maxWidth:headerValue.width,width:headerValue.width,borderRight:'1px solid rgb(220,220,220)',cursor:'default',display:'flex',wordBreak:'break-all',wordWrap:'break-word',justifyContent:'center',alignItems:'center',color:'rgb(50,50,50)',fontSize:'12px'}} className='headerTileV2'>
                                //         {Math.round((((item.approved + item.declined)/item.clicks) + Number.EPSILON) * 10000)*100 / 10000}
                                //     </div>
                                // }

                                // if(headerValue.label==='EPC'){
                                //     return <div style={{minWidth:headerValue.width,maxWidth:headerValue.width,width:headerValue.width,borderRight:'1px solid rgb(220,220,220)',display:'flex',alignItems:'center',padding:'5px'}}>
                                //                 <div style={{width:'100%'}}>
                                //                     {item['payouts'].map((payoutItem,payoutIndex)=>{
                                //                         return <div key={payoutIndex} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}>
                                //                             <div style={{width:'20%',textAlign:'center',fontSize:'12px',cursor:'default',color:'rgb(50,50,50)'}}>
                                //                                 {(item.payouts[payoutIndex].approved_revenue/item.clicks).toFixed(3)}
                                //                             </div>
                                //                         </div>
                                //                     })}
                                //                 </div>
                                //             </div>
                                //     // return <div key={headerindex} style={{minWidth:headerValue.width,maxWidth:headerValue.width,width:headerValue.width,borderRight:'1px solid rgb(220,220,220)',cursor:'default',display:'flex',wordBreak:'break-all',wordWrap:'break-word',justifyContent:'center',alignItems:'center',color:'rgb(50,50,50)',fontSize:'12px'}} className='headerTileV2'>
                                //     //     {(item.payouts[0].approved_revenue/item.clicks).toFixed(3)}
                                //     // </div>
                                // }
                                return <div key={headerindex} style={{minWidth:headerValue.width,maxWidth:headerValue.width,width:headerValue.width,borderRight:'1px solid rgb(220,220,220)',borderBottom:'1px solid rgb(220,220,220)',cursor:'default',display:'flex',wordBreak:'break-all',wordWrap:'break-word',justifyContent:'center',alignItems:'center',color:'rgb(50,50,50)',fontSize:'12px'}} className='headerTileV2'>
                                    {item[headerValue.value] || item[headerValue.value]===0 ? typeof item[headerValue.value] === 'object' ? JSON.stringify(item[headerValue.value]) : item[headerValue.value] : '-'}
                                    </div>
                                })}
                        </div>
                    })}
                </div>
            }
        else{
            return <div style={{fontSize:'12px'}}>No Data Found</div>
        }
     }
 
    const doNothing = () => {
        if (error!==""){
        }
    }

    const handleSearchButton = (e) => {
        getClickConversionRatioRequest(dateRangeValue,true,limit,currency,1);
        setPage(1);
        var url = '/statistics/custom?'
        url+= (searchedOffer  ? "offer_id=" + searchedOffer +"&" : "")
        url+= (advertiserSelected && advertiserSelected.value ? "advertiser=" + advertiserSelected.value+"_"+advertiserSelected.label+"&" : "")
        url+= (affiliateSelected && affiliateSelected.value ? "affiliate=" + affiliateSelected.value+"_"+affiliateSelected.label+"&" : "")
        url+= (advertiserManagerSelected && advertiserManagerSelected.value ? "advertiser_manager=" + advertiserManagerSelected.value+"_"+advertiserManagerSelected.label+"&" : "")
        url+= (affiliateManagerSelected && affiliateManagerSelected.value ? "affiliate_manager=" + affiliateManagerSelected.value+"_"+affiliateManagerSelected.label+"&" : "")
        url = url.slice(0,url.length-1)
        window.history.pushState('Statistics - Dates', 'Statistics - Dates', url);
    }


    const closeEditColumnsPopup = (e) => {
        setEditColumnsPopup(false);
        cookies.set('customStats', JSON.stringify(editColumnsList), { path: '/' });
    }

    
    const offerSearchedChanged = (e) => {
        if(e.length===0 || e.length<searchedOffersList.length){
            var temp = e
            setSearchedOffersList(e)
        }
        else{
            if(e.length>searchedOffersList.length){
                var value = e[e.length-1].value
                if(value.includes(",")){
                    var splitedList = value.split(",")
                }
                else{
                    splitedList = [value]
                }
                temp = searchedOffersList
                for(var index in splitedList){
                    var dict = {
                        label:splitedList[index].replace(/\s+/g, ''),
                        value:splitedList[index].replace(/\s+/g, '')
                    }
                    if(!temp.includes(dict)){
                        temp.push(dict)
                    }
                }
                setSearchedOffersList(temp)
            }   
        }
        if(temp.length>0){
            var searchOfferString = ""
            for(index in temp){
                searchOfferString += temp[index].value+","
            }
            searchOfferString = searchOfferString.slice(0,searchOfferString.length-1)
            
            setSearchedOffer(searchOfferString)     
        }
        else{
            setSearchedOffer("")     
        }
    }

    const handleCheckboxCustomStats = (e,item,index2,index3) => {
        var tempCustomStatsData = customStatsData
        var tempRequestColumns = requestColumns
        if(tempRequestColumns.includes('clicks') && tempCustomStatsData[item][index2].value==='approved'){
            handleCheckboxCustomStats(e,item,index2+1)
            if(tempRequestColumns.includes('approved')){
                tempRequestColumns = tempRequestColumns.filter(e=> e!=='declined')
            }
        }
        if(item==='Data' || item==="Extra Data"){
            if(e.target.checked){
                setDataItemsAdded(dataItemsAdded+1)
            }
            else{
                setDataItemsAdded(dataItemsAdded-1)
            }
        }
        if(!index3 && index3!==0){
            tempCustomStatsData[item][index2].checked = e.target.checked
            if(e.target.checked){
                if(!tempRequestColumns.includes(tempCustomStatsData[item][index2].value)){
                    tempRequestColumns.push(tempCustomStatsData[item][index2].value)
                }
            }
            else{
                if(tempCustomStatsData[item][index2].children){
                    for(var childIndex in tempCustomStatsData[item][index2].children){
                        tempCustomStatsData[item][index2].children[childIndex].checked = false
                        var notValue = tempCustomStatsData[item][index2].children[childIndex].value
                        var temptempRequestColumns = []
                        for(var customColumnIndex in tempRequestColumns){
                            if(tempRequestColumns[customColumnIndex] !== notValue){
                                temptempRequestColumns.push(tempRequestColumns[customColumnIndex])
                            }
                        }
                        tempRequestColumns = temptempRequestColumns
                    }
                }
                tempRequestColumns = tempRequestColumns.filter(e=>e!==tempCustomStatsData[item][index2].value)
            }
        }
        if(index3 || index3===0){
            tempCustomStatsData[item][index2].children[index3].checked = e.target.checked
            if(e.target.checked){
                if(!tempRequestColumns.includes(tempCustomStatsData[item][index2].children[index3].value)){
                    tempRequestColumns.push(tempCustomStatsData[item][index2].children[index3].value)
                }
            }
            else{
                tempRequestColumns = tempRequestColumns.filter(e=>e!==tempCustomStatsData[item][index2].children[index3].value)
            }
        }

        if(tempRequestColumns.includes('clicks')){
            var subList = ['sub1','sub2','sub3','sub4','sub5','sub6','sub7','sub8']
            subList.forEach(subItem => {
                tempRequestColumns = tempRequestColumns.filter(e => e!==subItem)
            })
        }
        setRequestedColumns(tempRequestColumns)
        setCustomStatsData(tempCustomStatsData);
        refreshComponent(e);
    }
   
    var panelCurrencies = [{label:'ALL/USD',value:null}]
    if(getLocalStorageValue('adminPanelLoginData').panelSettings && getLocalStorageValue('adminPanelLoginData').panelSettings.currency){
        for(index in getLocalStorageValue('adminPanelLoginData').panelSettings.currency){
            panelCurrencies.push({label:index,value:index})
        }
    }
    var listedCurrencies = panelCurrencies

    const [selectedCurrency, setSelectedCurrency] = useState(null)

    var subSelectedValidation = (item) => {
        if((requestColumns.includes('sub1')
            ||
            requestColumns.includes('sub2')
            ||
            requestColumns.includes('sub3')
            ||
            requestColumns.includes('sub4')
            ||
            requestColumns.includes('sub5')
            ||
            requestColumns.includes('sub6')
            ||
            requestColumns.includes('sub7')
            ||
            requestColumns.includes('sub8')
            ||
            requestColumns.includes('goal_value')
            )
            && item==="Traffic"
            )
        {
            return true
        }
        return false
    }
    return (       
        <div className='mainContainerV2' id="outside">

                <StatisticsRouter/>
                {showExportPopup && 
                <DownloadStats 
                    handleClose={()=>{setShowExportPopup(false)}}
                    type="custom"
                    currency={selectedCurrency}
                    datelist={dateRangeValue}
                    order_field={requestOrder}
                    order_by={requestOrderBy}
                    offer_id={searchedOffer}
                    advertiser_id={advertiserSelected}
                    advertiser_manager_id={advertiserManagerSelected}
                    affiliate_id={affiliateSelected}
                    affiliate_manager_id={affiliateManagerSelected}
                /> }
                {editColumnsPopup && <ArrangeColumns handleClose={closeEditColumnsPopup} editColumnsList={editColumnsList} setEditColumnsList={setEditColumnsList}/>}

                <div style={{display:"flex",alignItems:'center',justifyContent:'flex-start',marginTop:"1%",marginLeft:"2%",flexWrap:'wrap'}}>
                        <div style={{display:'grid',minWidth:'200px',width:'max-content',maxWidth:'250px'}}>
                            <label style={{fontSize:"11px",fontWeight:"700",color:"grey",paddingBottom:'5px'}}>Date Range</label>
                            <TextField fullWidth value={value} style={{transform:'scale(0.8) translate(-13%) translateY(-18%)'}} size="small" disabled={requestDone} onClick={requestDone ? doNothing : changeDate}/>
                        </div>
                            {showDatePicker && 
                                <>
                                <div className='datePicker' style={{transform:'scale(0.7) translateX(-10%) translateY(55%)'}}>   
                                    <div style={{cursor:"pointer",marginLeft:'-15px',marginTop:'-15px',background:'white',position:'absolute'}} onClick={()=>{setShowDatePicker(false)}}>&nbsp;X</div>
                                    <DateRangePicker
                                    onChange={item => dateRangePicked(item)}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={state}
                                    direction="horizontal"
                                    />
                                </div>
                                </>
                            }

                        <div style={{display:'grid',minWidth:'200px',width:'max-content',maxWidth:'250px'}}>
                            <div style={{fontSize:"11px",fontWeight:"700",color:"grey",paddingBottom:'5px'}}>Timezone</div>
                            <div style={{transform:'scale(0.85) translateX(-10%) translateY(-17%)'}}>
                                <Select options={Constants.timezoneList}
                                    value={timezoneSelected}
                                    onChange={(e)=>{setTimezoneSelected(e);}}
                                    menuPortalTarget={document.body} 
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999}),
                                            option: styles => ({...styles,fontSize:"13px"}),
                                            control : provided => ({
                                                ...provided,
                                            }),
                                            valueContainer: provided => ({
                                                ...provided,
                                                fontSize:"13px",
                                                maxHeight:'30px',
                                                overflow:'auto',
                                                })
                                    }}/>
                            </div>
                        </div>
                        <div style={{display:'grid',minWidth:'200px',width:'max-content',maxWidth:'250px'}}>
                            <label style={{fontSize:"11px",fontWeight:"700",color:"grey",paddingBottom:'5px'}}>Offer ID</label>
                            <div style={{transform:'scale(0.85) translateX(-10%) translateY(-17%)'}}>
                                <CreatableSelect
                                    isClearable
                                    isMulti
                                    value={searchedOffersList}
                                    onChange={(e)=>{offerSearchedChanged(e);localStorage.setItem('statsOfferId',JSON.stringify(e));}}
                                    // onInputChange={offerSearchedChanged}
                                    // options={categoriesOptions}
                                    menuPortalTarget={document.body} 
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999}),
                                            option: styles => ({...styles,fontSize:"14px"}),
                                            valueContainer: provided => ({
                                                ...provided,
                                                fontSize:"13px",
                                                maxHeight:'30px',
                                                overflow:'auto',
                                                })
                                    }}
                                    />
                            </div>
                            {/* <TextField fullWidth size="small" disabled={requestDone} value={searchedOffer} onChange={(e)=>{setSearchedOffer(e.target.value)}} placeholder='Search'/> */}
                        </div>
                        <div style={{display:'grid',minWidth:'200px',width:'max-content',maxWidth:'250px'}}>
                            <div style={{fontSize:"11px",fontWeight:"700",color:"grey",paddingBottom:'5px'}}>Affiliate</div>
                            <div style={{transform:'scale(0.85) translateX(-10%) translateY(-17%)'}}>
                                <Select options={affiliateList}
                                    value={affiliateSelected}
                                    isMulti
                                    onChange={(e)=>{setAffiliateSelected(e);localStorage.setItem('statsAffiliate',JSON.stringify(e));}}
                                    menuPortalTarget={document.body} 
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999}),
                                            option: styles => ({...styles,fontSize:"13px"}),
                                            control : provided => ({
                                                ...provided,
                                            }),
                                            valueContainer: provided => ({
                                                ...provided,
                                                fontSize:"13px",
                                                maxHeight:'30px',
                                                overflow:'auto',
                                                })
                                    }}/>
                            </div>
                        </div>
                        <div style={{display:'grid',minWidth:'200px',width:'max-content',maxWidth:'250px'}}>
                            <div style={{fontSize:"11px",fontWeight:"700",color:"grey",paddingBottom:"5px"}}>Advertiser</div>
                            <div style={{transform:'scale(0.85) translateX(-10%) translateY(-17%)'}}>
                                <Select options={advertisersList}
                                    value={advertiserSelected}
                                    isMulti
                                    onChange={(e)=>{setAdvertiserSelected(e);localStorage.setItem('statsAdvertiser',JSON.stringify(e));}}                                
                                    menuPortalTarget={document.body} 
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999}),
                                            option: styles => ({...styles,fontSize:"13px"}),
                                            control : provided => ({
                                                ...provided,
                                            }),
                                            valueContainer: provided => ({
                                                ...provided,
                                                fontSize:"13px",
                                                maxHeight:'30px',
                                                overflow:'auto',
                                                })
                                    }}/>
                            </div>
                        </div>
                        <div style={{display:'grid',minWidth:'200px',width:'max-content',maxWidth:'250px'}}>
                            <div style={{fontSize:"11px",fontWeight:"700",color:"grey",paddingBottom:"5px"}}>Affiliate Manager</div>
                            <div style={{transform:'scale(0.85) translateX(-10%) translateY(-17%)'}}>
                                
                                <Select options={managersList}
                                    value={affiliateManagerSelected}
                                        isMulti
                                        onChange={(e)=>{setAffiliateManagerSelected(e);localStorage.setItem('statsAffiliateManager',JSON.stringify(e));}}                                
                                    menuPortalTarget={document.body} 
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999}),
                                            option: styles => ({...styles,fontSize:"13px"}),
                                            control : provided => ({
                                                ...provided,
                                            }),
                                            valueContainer: provided => ({
                                                ...provided,
                                                fontSize:"13px",
                                                maxHeight:'30px',
                                                overflow:'auto',
                                                })
                                    }}/>
                            </div>
                        </div>
                        
                        <div style={{display:'grid',minWidth:'200px',width:'max-content',maxWidth:'250px'}}>
                            <div style={{fontSize:"11px",fontWeight:"700",color:"grey",paddingBottom:"5px"}}>Advertiser Manager</div>
                            <div style={{transform:'scale(0.85) translateX(-10%) translateY(-17%)'}}>
                            
                                <Select options={managersList}
                                    value={advertiserManagerSelected}
                                        isMulti
                                        onChange={(e)=>{setAdvertiserManagerSelected(e);localStorage.setItem('statsAdvertiserManager',JSON.stringify(e));}}
                                    menuPortalTarget={document.body} 
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999}),
                                            option: styles => ({...styles,fontSize:"13px"}),
                                            control : provided => ({
                                                ...provided,
                                            }),
                                            valueContainer: provided => ({
                                                ...provided,
                                                fontSize:"13px",
                                                maxHeight:'30px',
                                                overflow:'auto',
                                                })
                                    }}/>
                            </div>
                        </div>

                        <div style={{display:'grid',minWidth:'200px',width:'max-content',maxWidth:'250px'}}>
                            <div style={{fontSize:"11px",fontWeight:"700",color:"grey",paddingBottom:"5px"}}>Goal Value</div>
                            <div style={{transform:'scale(0.85) translateX(-10%) translateY(-17%)'}}>
                            
                                <CreatableSelect 
                                    // options={managersList}
                                    value={goalValueSelected}
                                        isMulti
                                        onChange={(e)=>{setGoalValueSelected(e);}}
                                    menuPortalTarget={document.body} 
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999}),
                                            option: styles => ({...styles,fontSize:"13px"}),
                                            control : provided => ({
                                                ...provided,
                                            }),
                                            valueContainer: provided => ({
                                                ...provided,
                                                fontSize:"13px",
                                                maxHeight:'30px',
                                                overflow:'auto',
                                                })
                                    }}/>
                            </div>
                        </div>
                        <div style={{display:'flex',alignItems:'center'}}>
                            <Button disabled={requestDone} sx={{...Constants.CONTAINEDBUTTON,height:'25px',fontSize:'12px',textTransform:'none',marginTop:'15px'}} onClick={handleSearchButton}> Search </Button>
                            <Button disabled={requestDone} sx={{...Constants.OUTLINEDBUTTON,height:'25px',fontSize:'12px',marginLeft:'10px',marginTop:'15px',textTransform:'none',}} onClick={()=>{setAffiliateSelected('');setAdvertiserSelected('');setAffiliateManagerSelected('');setAdvertiserManagerSelected('');setSearchedOffersList([]);setTimezoneSelected(false);setGoalValueSelected(false);dateRangePicked({selection : {
                                                            startDate: new Date(new Date().getTime()-86400000*6),
                                                            endDate: new Date(),
                                                            key: 'selection'
                                                        }})}}> Reset </Button>
                        </div>
                </div>  
                <div style={{display:"flex",marginBottom:"1%",paddingLeft:"2%"}}>
                    {/* <div style={{width:'50%'}}></div> */}
                    <div style={{display:'flex',width:'100%',marginRight:"3%",justifyContent:"flex-end"}}>
                        <Tooltip title="Export Data">
                            <IconButton className='settingsButton' size={'small'} onClick={()=>{exportCustomStats(dateRangeValue)}}>
                                <FileDownloadIcon/>
                            </IconButton>
                        </Tooltip>
                        {/* <Tooltip title="Reset Table Settings">
                            <IconButton className='settingsButton' size={'small'} onClick={()=>{setEditColumnsList(resetColumnList);cookies.set('customStats', JSON.stringify(resetColumnList), { path: '/' });}}>
                                <RestartAltIcon/>
                            </IconButton>
                        </Tooltip>
                        
                        <Tooltip title="Table Settings">
                            <IconButton className='settingsButton' size={'small'} onClick={()=>{setEditColumnsPopup(true);}}>
                                <SettingsIcon/>
                            </IconButton>
                        </Tooltip> */}
                    </div>
                </div>
                <div style={{borderBottom:"1px solid grey",margin:"1%"}}></div>   
                <div style={{height:'25px',paddingLeft:'2%',paddingRight:'2%',marginBottom:'10px',fontSize:'12px',display:'flex',alignItems:'center',opacity:1,transition:'all 0.5s linear'}}>
                    <div style={{display:'flex',background:'rgb(255,255,255)',zIndex:'1'}}>Currency:</div>
                    <div  style={{display:'flex',alignItems:'center',transition:'all 0.5s linear'}}>
                        {/* {listedCurrencies.splice(0,1).map((currencyItem,currencyIndex)=>{
                            return <div style={{display:'flex',cursor:'pointer',color:(selectedCurrency === currencyItem.value ? localStorage.getItem('ambientColor') : 'grey'),paddingLeft:'5px',paddingRight:'5px'}} onClick={()=>{setSelectedCurrency(currencyItem.value)}} key={currencyIndex}>
                                    {currencyItem.label}
                                </div>
                        })} */}
                        <select style={{border:'0px solid transparent',fontSize:'12px',}} onChange={(e)=>{setSelectedCurrency(e.target.value)}}>
                            {/* <option disabled selected value>-- Select Another Currency --</option> */}
                            {listedCurrencies.map((currencyItem,currencyIndex)=>{
                            return <option selected={selectedCurrency === currencyItem.value ? 'selected' : false} value={currencyItem.value} key={currencyIndex}>
                                    {currencyItem.label}
                                </option>
                            })}
                        </select>
                    </div>
                </div>
                <div style={{display:'flex',flexWrap:'wrap'}}>
                    {Object.keys(customStatsData).map((item,index)=>{
                        return <div style={{marginLeft:'2%',marginRight:'2%',marginBottom:'2%'}} key={index}>
                                <div style={{fontSize:'15px',fontWeight:'500',padding:'8px'}}>{item}</div>
                                
                                {customStatsData[item].map((item2,index2)=>{
                                    return <div key={index2}>
                                                <div style={{display:'flex',alignItems:'center',padding:'3px'}}>
                                                    <input type={'checkbox'} id={item2.value} disabled={(!(item==="Extra Data" || item==="Data") && dataItemsAdded===0 ? true : false) || (requestColumns.includes('clicks') && item==="Extra Data") || (requestColumns.includes('clicks') && item2.label==="Declined Conversions") || (subSelectedValidation(item))} checked={item2.checked} onChange={(e)=>{handleCheckboxCustomStats(e,item,index2)}}/>
                                                    <label for={item2.value} style={{fontSize:'13px',userSelect:'none'}}>{item2.label}</label>
                                                </div>
                                                {item2.children && item2.children.map((item3,index3)=>{
                                                    return <div key={index3}>
                                                        <div  style={{display:'flex',alignItems:'center',padding:'3px'}}>
                                                            <input type={'checkbox'} id={item3.value} checked={item3.checked} disabled={item2.checked ? false : true} onChange={(e)=>{handleCheckboxCustomStats(e,item,index2,index3)}}/>
                                                            <label for={item3.value} style={{fontSize:'13px',userSelect:'none'}}>{item3.label}</label>
                                                        </div>
                                                    </div>
                                                })}
                                            </div>
                                })}
                            </div>
                    })}
                        
                </div>
                <div style={{display:'flex',justifyContent:'flex-end'}}>
                    <div style={{textAlign: "right",marginRight:'1%'}}>
                        <label style={{paddingLeft:"20px",paddingRight:"20px"}}>LIMIT</label>
                        <NativeSelect
                            value={limit}
                            inputProps={{
                                name: 'LIMIT',
                                id: 'uncontrolled-native',
                            }}
                            color="warning"
                            onChange={handleLimitChange}
                            >
                            <option>25</option>
                            <option>50</option>
                            <option>75</option>
                            <option>100</option>
                        </NativeSelect>
                    </div>
                </div>
                <div style={{borderTop:"1px solid grey",margin:"1%",paddingBottom:(!showLoader ? '0' :'5%')}}></div>
                <div style={{display:"block",margin:"2%",marginTop:'5px',marginBottom:'5px'}}> 
                    {showLoader && requestDone && 
                        <div className="loaderWrapper">  
                            <div className="loader">  
                                <CircularProgress color="warning"/> 
                            </div>
                        </div>
                    }
                </div>

                {!showLoader && showHeaders()}
                {!showLoader && showValue()}
        
                {!showLoader &&
                    <div className="row" style={{paddingBottom:'10px'}}>
                        <Pagination count={clickConversionRatioResponse.loaded ? Math.ceil(clickConversionRatioResponse.data.count/limit) : 1} page={page} onChange={handleChange} />
                    </div>
                    }
        </div>  
    )
}

export default CustomStats;
