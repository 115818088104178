import React, { useEffect, useState } from 'react'
import './advertiserBilling.css';
import './advertiserBillingSummary.css';
import ReactSelect from 'react-select';
import { CssTextField, ReactSelectStyles } from '../../data/constants';
import { checkForPermission, getLocalStorageValue, getRequests, isSuperUser, removeLocalStorageValue, setLocalStorageValue, sortingV2, useForceUpdate, zeroPad } from '../../util/util';
import { getAdvertisers, getBillingAdvertisersTag, getManager } from '../../apis/dropdown/dropdowns';
import { CONTAINEDBUTTON } from '../../data/constants';
import { Button, IconButton, Pagination, Tooltip } from '@mui/material';
import TableModel from '../../components/tableModel/tablemodel';
import axios from 'axios';
import * as Constants from '../../data/constants';
import ShowLoaderComponent from '../../components/loader';
import { CircularProgress } from '@mui/material'
import NoResultsFound from '../../components/noResultFound';
import SomeErrorOccured from '../../components/someErrorOccured';
import MinimizeIcon from '@mui/icons-material/Minimize';
import { Close } from '@mui/icons-material';
import LaunchIcon from '@mui/icons-material/Launch';
import ReactApexChart  from 'react-apexcharts';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Store } from 'react-notifications-component';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const AdvertiserBillingSummary = () => {
    document.title = 'Advertiser Billing'
    const refreshComponent = useForceUpdate();
    var todayDate = new Date()
    todayDate.setMonth(todayDate.getMonth() - 1);
    var lastMonthList = todayDate.toISOString().split('T')[0].split('-')
    var lastMonth = lastMonthList[0]+'-'+lastMonthList[1]
    if(document.getElementsByClassName('mainWindowSidebarClose').length>0){
        document.getElementsByClassName('mainWindowSidebarClose')[0].style.background = 'white'
    }
    lastMonth = [lastMonth,null]

    const queryParameters = new URLSearchParams(window.location.search)
    var showDirectYTD = queryParameters.get('showYTD') === 'True' ? true : false
    var tempMonthSelected = queryParameters.get('monthSelected')
    if(tempMonthSelected){
        if(tempMonthSelected.includes('_')){
            var tempMonthSelectedList = tempMonthSelected.split('_')
            if(tempMonthSelectedList[1]!=='undefined' && tempMonthSelectedList[1]!=='null'){
                tempMonthSelected = [new Date(tempMonthSelectedList[0]),new Date(tempMonthSelectedList[1])]
            }
            else{
                tempMonthSelected = [new Date(tempMonthSelectedList[0]),null]
            }
        }
        else{
            tempMonthSelected = new Date(tempMonthSelected)
            tempMonthSelected = [tempMonthSelected,null]
        }
    }
    var tempPackageName = queryParameters.get('package_name')
    var tempFilterData = {}
    var tempAdvertiserId = queryParameters.get('advertiser_id')
    var tempAdvertiserName = queryParameters.get('advertiser_name')
    var tempTeamName = queryParameters.get('team')

    var subteamMembers = {
        Team1:['Pushkal','Pawan','Nitesh'],
        Team2:['Udit','Umair','Divyanshu'],
        Team3:['Shikha','Harsh','Aniket'],
    }
    var isClearableTeam = true
    var teamOptions = [{label:'Team1',value:'Team1'},{label:'Team2',value:'Team2'},{label:'Team3',value:'Team3'}]
    if(checkForPermission('can_view_team1') && !isSuperUser()){
        subteamMembers = {
            Team1:['Pushkal','Pawan','Nitesh'],
        }
        teamOptions=[{label:'Team1',value:'Team1'}]
        tempTeamName = 'Team1'
        isClearableTeam = false
    }
    if(checkForPermission('can_view_team2') && !isSuperUser()){
        subteamMembers = {
            Team2:['Udit','Umair','Divyanshu'],
        }
        teamOptions=[{label:'Team2',value:'Team2'}]
        tempTeamName = 'Team2'
        isClearableTeam = false
    }
    if(checkForPermission('can_view_team3') && !isSuperUser()){
        subteamMembers = {
            Team3:['Shikha','Harsh','Aniket'],
        }
        teamOptions=[{label:'Team3',value:'Team3'}]
        tempTeamName = 'Team3'
        isClearableTeam = false
    }
    if(tempPackageName){
        tempFilterData['package_name'] = tempPackageName
    }
    if(tempAdvertiserId){
        tempFilterData['advertiser'] = {value:tempAdvertiserId,label:tempAdvertiserName}
    }
    if(tempTeamName){
        tempFilterData['team'] = {value:tempTeamName,label:tempTeamName}
    }
    const [monthSelected, setMonthSelected] = useState(tempMonthSelected ? tempMonthSelected : getLocalStorageValue('billingFilters') && getLocalStorageValue('billingFilters').monthSelected ? getLocalStorageValue('billingFilters').monthSelected : lastMonth)
    const [loader, setLoader] = useState({main:true})
    const [manualUseEffect, setManualUseEffect] = useState(false)
    const [optionsAdvertiserList, setOptionsAdvertiserList] = useState([])
    const [optionsAdvertiserBillingTagList, setOptionsAdvertiserBillingTagList] = useState([])
    const [filterData, setFilterData] = useState(Object.keys(tempFilterData).length>0 ? tempFilterData : getLocalStorageValue('billingFilters') ? getLocalStorageValue('billingFilters') : {})
    const [optionsManagerList, setOptionsManagerList] = useState([])
    const [tableValues, setTableValues] = useState([])
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [totalResults, setTotalResults] = useState(10);
    const [totalRow, setTotalRow] = useState([]);
    const [openedTabs, setOpenedTabs] = useState([])
    const [compareWithManual,setCompareWithManual] = useState(getLocalStorageValue('billingFilters') ? getLocalStorageValue('billingFilters').compare_with_manual ? true : false : false)
    const [sortingData,setSortingData] = useState(false)
    const [datatillThisMonth,setDatatillThisMonth] = useState(getLocalStorageValue('billingFilters') ? getLocalStorageValue('billingFilters').data_till_this_month ? true : false : false)
    const [montlyAdvertiserData, setMontlyAdvertiserData] = useState({})
    const [calculateWith, setCalculateWith] = useState('conversions_verified')
    const [excludeMMPRebroking, setExcludeMMPRebroking] = useState(false)
    const [advertiserBillingTags, setAdvertiserBillingTags] = useState({})
    const [advertiserBillingTagsLocked, setAdvertiserBillingTagsLocked] = useState(true)


    const getRevenueStatusData = async(adv_id_list,monthNumber=null) => {
        if(adv_id_list.length>0){
            return await axios({
                method: 'GET',
                url:"".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"invoice/summary/adv-data"),
                headers: {
                    "Content-Type":"application/json",
                    Authorization:getLocalStorageValue('adminPanelLoginData').token
                },
                params: { 
                    month:monthNumber ? monthNumber : monthSelected,
                    adv_id_list:adv_id_list.join(","),
                }
            })
            .then(resp => {
                return resp.data.result;
            })
        }
    }


    const getFromToMonth = () => {
        var date_temp = new Date(monthSelected[0]).toLocaleString('en-US',{year:'numeric',month:'numeric'}).split(',')[0].split('/')
        var fromMonth = date_temp[1]+'-'+zeroPad(date_temp[0],2)
        if(monthSelected[1]){
            date_temp = new Date(monthSelected[1]).toLocaleString('en-US',{year:'numeric',month:'numeric'}).split(',')[0].split('/')
            var toMonth = date_temp[1]+'-'+zeroPad(date_temp[0],2)
        }
        return {fromMonth,toMonth}
    }
    const getRevenueData = async(e,manual_limit=null,manual_page=null,sortingKeyword=null,sortingOrder=null,exportReport=null) => {
        await getAdvertiserBillingTags()
        setLoader({...loader,main:true})
        setOpenedTabs([]);
        setOffset((manual_limit ? manual_limit : limit)*(manual_page ? (manual_page-1) : (page-1)))
        
        var {fromMonth,toMonth} = getFromToMonth()
        await axios({
            method: 'GET',
            url:"".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"invoice/summary/adv-data"),
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params: { 
                //filters
                fromMonth:fromMonth,
                toMonth:toMonth,
                adv_id:filterData.advertiser ? filterData.advertiser.value : undefined,
                advertiser_manager_id:filterData.adv_manager ? filterData.adv_manager.value : undefined,
                package_name:filterData.package_name ? filterData.package_name : undefined,
                data_till_this_month:filterData.data_till_this_month ? filterData.data_till_this_month : undefined,
                limit: manual_limit ? manual_limit : limit,
                page: manual_page ? manual_page : page,
                total_row_required:true,
                sorting_key: sortingKeyword ? sortingKeyword : sortingData.sortingKeyword ? sortingData.sortingKeyword : 'total_revenue',
                sorting_order:sortingOrder ? sortingOrder : sortingData.sortingOrder ? sortingData.sortingOrder : false,
                export:exportReport ? exportReport : null,
                team:filterData.team ? filterData.team.value : undefined,
                subteam:filterData.subteam ? filterData.subteam.value : undefined,
                calculateWith:calculateWith,
                exclude_missing_offers:excludeMMPRebroking,
            }
        })
        .then(async(resp) => {
            if(exportReport){
                const FileDownload = require('js-file-download');
                FileDownload(resp.data, 'advertiser_revenue_summary.csv')
                return
            }
            setTotalResults(resp.data.count)
            var items = resp.data.result
            sortingV2(items,sortingKeyword ? sortingKeyword : sortingData.sortingKeyword ? sortingData.sortingKeyword : 'total_revenue',sortingOrder ? sortingOrder : sortingData.sortingOrder ? sortingData.sortingOrder : false)
            setTableValues(items);
            if(resp.data.total_row){
                setTotalRow([resp.data.total_row])
            }
           
        })
        .catch((error) => {
            setTableValues([])
            if (error.response) {
                console.log(error.response.data);
                if(error.response.data){
                    setTableValues(error.response.data.result);
                }
                console.log(error.response.status);
                console.log(error.response.headers);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        })
        setLoader({...loader,main:false})
    }
    const getAdvertiserBillingTags = async(e) => {
        var {fromMonth,toMonth} = getFromToMonth()
        if(fromMonth===toMonth || (fromMonth && !toMonth)){
            await axios({
                method: 'GET',
                url:"".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"invoice/summary/adv-data/tag"),
                headers: {
                    "Content-Type":"application/json",
                    Authorization:getLocalStorageValue('adminPanelLoginData').token
                },
                params: { 
                    month:fromMonth,
                    }
            })
            .then(async(resp) => {
                setAdvertiserBillingTags(resp.data.result)
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    }
            })
        }
    }
    const sortingFunction = (keyword,sortingOrder) => {
        getRevenueData(null,null,null,keyword,sortingOrder)
        setSortingData({sortingKeyword:keyword,sortingOrder})
    }

    const getRevenueDataMonthlyForAdv = async(e,adv_id,valueIndex) => {
        if(!montlyAdvertiserData[adv_id]){
            var tableValue = await axios({
                method: 'GET',
                url:"".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"invoice/summary/adv-data"),
                headers: {
                    "Content-Type":"application/json",
                    Authorization:getLocalStorageValue('adminPanelLoginData').token
                },
                params: { 
                    //filters
                    year:true,
                    adv_id:adv_id,
                    package_name:filterData.package_name ? filterData.package_name : undefined
                    // currency:(manualCurrency ? manualCurrency : currency).toLowerCase(),
                    }
            })
            .then(async(resp) => {
                var item_dict = resp.data.result
                sortingV2(item_dict,'month',true)
                return item_dict
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    }
            })
            if(showDirectYTD){
                setActiveTab(0)
            }
            setMontlyAdvertiserData({...montlyAdvertiserData,[valueIndex]:tableValue})
        }
    }

    const letsStartAThing = async() => {
        await getRequests(getAdvertisers(false,'both'),setOptionsAdvertiserList)
        await getRequests(getBillingAdvertisersTag(),setOptionsAdvertiserBillingTagList)
        await getRevenueData(1)
        if(showDirectYTD){
            await getRevenueDataMonthlyForAdv('123',tempAdvertiserId,0)
        }
        await getRequests(getManager(),setOptionsManagerList)
    }
    if(!manualUseEffect){
        setManualUseEffect(true)
        letsStartAThing()
    }

    const updateFilterData = (value,item) => {
        setFilterData({...filterData,[item.value]:value})
        var oldSessionFilterStorage = getLocalStorageValue('billingFilters') ? getLocalStorageValue('billingFilters') : {}
        setLocalStorageValue("billingFilters",{...oldSessionFilterStorage,[item.value]:value})
    }

    const resetFilters = () => {
        removeLocalStorageValue('billingFilters');
        setFilterData({})
        setMonthSelected(lastMonth);
        refreshComponent(123);
    }

    var subteamoptions = () => {
        if(filterData && filterData.team){
            if(filterData.team.value){
                return subteamMembers[filterData.team.value].map((item)=>{return {label:item,value:item}})
            }
        }
    }
    const filters = [
        {label:'Month', value:'month',type:'monthpicker'},
        {label:'Offer Package', value:'package_name',type:'textfield',setMethod:updateFilterData},
        {label:'Advertiser',isSingle:true, value:'advertiser',type:'select',options:optionsAdvertiserList.advertiserlist,setMethod:updateFilterData},
        // {label:'Advertiser Manager',isSingle:true, value:'adv_manager',type:'select',options:optionsManagerList,setMethod:updateFilterData},
        {label:'Tech Team',isSingle:true, value:'team',type:'select',options:teamOptions,setMethod:updateFilterData},
        {label:'Tech Sub Team',isSingle:true, value:'subteam',type:'select',options:subteamoptions(),setMethod:updateFilterData},
        {label:'Submit', disabled:loader.main,value:'submitButton',type:'button',options:optionsManagerList,onSubmit:getRevenueData,setMethod:updateFilterData},
        {label:'Reset', value:'resetButton',variant:'outlined',type:'button',onSubmit:resetFilters},

    ]
    
    const validationComponent = (valueItem,item,index,valueIndex,tableValues) => {
        if(valueItem){
            var [validated_offers,total_offers] = valueItem['validated'].split('/')
            var percent = (validated_offers*100)/total_offers
            if (percent<25){
                var color = '#ff0000'
                var highlightColor = '#ffcccc'
            }
            else if (percent<50){
                color = '#ff6200'
                highlightColor = '#ffe6cc'
            }
            else if (percent<75){
                color = '#6f9400'
                highlightColor = '#ebffc2'
            }
            else{
                color = '#0f8500'
                highlightColor = '#d2ffcc'
            }
            if(valueItem.advertiser_id==='Total'){
                var redirectingUrl = '/billing/advertiser'
            }
            else{
                redirectingUrl = '/billing/advertiser?advertiser_id='+valueItem.advertiser_id+"&advertiser_name="+optionsAdvertiserList.advertiserDict[valueItem.advertiser_id]
                if (valueItem.month){
                    redirectingUrl+='&monthSelected='+valueItem.month
                }
                else{
                    var {fromMonth,toMonth} = getFromToMonth()
                    redirectingUrl+='&monthSelected='+fromMonth+'_'+toMonth
                }
            }
            if(filterData.package_name){
                redirectingUrl+='&package_name='+filterData.package_name
            }
            return <a href={redirectingUrl} target='_blank' rel="noreferrer" style={{textDecoration:'none',color,fontWeight:500,background:highlightColor,marginLeft:(valueItem.advertiser_id==='Total' ? '5px' : '0px'),padding:'5px',width:'max-content',borderRadius:'10px',cursor:'pointer'}}>
                {valueItem['validated']}
            </a>
        }
    }
    
    const validationPercentageComponent = (valueItem,item,index,valueIndex,tableValues) => {
        var percent = valueItem[item.value] ? parseInt(valueItem[item.value]) : 0.00
        if (percent<25){
            var color = '#ff0000'
            var highlightColor = '#ffcccc'
        }
        else if (percent<50){
            color = '#ff6200'
            highlightColor = '#ffe6cc'
        }
        else if (percent<75){
            color = '#6f9400'
            highlightColor = '#ebffc2'
        }
        else{
            color = '#0f8500'
            highlightColor = '#d2ffcc'
        }

        return <div style={{color,fontWeight:500,background:highlightColor,marginLeft:(valueItem.advertiser_id==='Total' ? '5px' : '0px'),padding:'5px',width:'max-content',borderRadius:'10px'}}>
            {valueItem.validated ? percent.toString()+'%' : 'Pending'}
        </div>
    }

    const [activeTab, setActiveTab] = useState(false)
    const showDetailSummaryComponent = (valueItem,item,index,valueIndex,tableValues) => {
        if(valueItem.advertiser_id==='Total'){
            return <></>
        }
        return <div style={{width:'80%',color:localStorage.getItem('ambientColor'),cursor:'pointer'}} 
        onClick={(e)=>{setActiveTab(valueIndex);
                        getRevenueDataMonthlyForAdv(e,valueItem.advertiser_id,valueIndex);
                        for(var index in openedTabs){
                            var item = openedTabs[index];
                            if(item.valueIndex === valueIndex){
                                var donotpush = true;
                            }
                        }  
                        if(!donotpush){
                            setOpenedTabs([...openedTabs.slice(-4),{valueIndex,...valueItem}])
                        }
        }}>
                Show Summary
            </div>
    }
    const roundComponent = (valueItem,item,index,valueIndex,tableValues) => {
        var value = Math.round(valueItem[item.value])
        return <div style={{marginLeft:(valueItem.advertiser_id === 'Total' ? '5px' : '0px')}}>
            {value}
        </div>
    }

    const advertiserComponent = (valueItem,item) => {
        if(valueItem[item.value]==='Total'){
            return <div style={{marginLeft:'5px'}}>
                    Total
                </div>
        }
        return <div style={{textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap'}}>
            {(optionsAdvertiserList.advertiserDict ? optionsAdvertiserList.advertiserDict[valueItem[item.value]] : '')}
        </div>
    }

    const validatedCommentComponent =  (valueItem,item,index,valueIndex,tableValues) => {
        if(valueItem.advertiser_id==='Total'){
            return ''
        }
        var validated_offers = parseInt(valueItem.validated.split('/')[0])
        var total_offers = parseInt(valueItem.validated.split('/')[1])
        var validation_comment_dict = {label:'Pending',color:'red'}
        if(validated_offers === total_offers){
            validation_comment_dict = {label:'Validated',color:'green'}
        }
        else if(validated_offers>0){
            validation_comment_dict = {label:'Partially Validated',color:'orange'}
        }
        return <div style={{color:validation_comment_dict.color}}>
            {validation_comment_dict.label}
        </div>
    }

    const roundApprovedComponent = (valueItem,item,index,valueIndex,tableValues) => {
        if(valueItem.advertiser_id==='Total'){
            return <div style={{marginLeft:'5px'}}>
                {Math.round(valueItem[item.value])}
            </div>
        }
        return (valueItem.validated.split("/")[0]!=="0" ? Math.round(valueItem[item.value]) : 'Pending')
    }

    const diffManualPanelPercent = (valueItem,item,index,valueIndex,tableValues) => {
        var percent = valueItem[item.value] ? parseInt(valueItem[item.value]) : 0.00
        var color = 'rgb(255,0,0)'
        var highlightColor = 'rgb(255, 166, 166)'
        if (percent>=90 && percent<=110){
            color = 'rgb(23, 100, 0)'
            highlightColor = 'rgb(152, 192, 140)'
        }

        return <div style={{color,marginLeft:(valueItem.advertiser_id==='Total' ? '5px' : '0px'),padding:'5px',width:'max-content',borderRadius:'20px'}}>
            {percent.toString()+'%'}
        </div>
    }
    const validationTagComponent = (valueItem,item) => {
        if(valueItem.advertiser_id==='Total'){
            return 
        }
        var {fromMonth,toMonth} = getFromToMonth()
        const updateBillingTags = async(e) => {
            if(fromMonth===toMonth || (fromMonth && !toMonth)){
                var tags_list = e.map((item)=>{return item.label})
                item.setAdvertiserBillingTags({...item.advertiserBillingTags,[valueItem.advertiser_id]:tags_list})
                await axios({
                    method: 'POST',
                    url:"".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"invoice/summary/adv-data/tag"),
                    headers: {
                        "Content-Type":"application/json",
                        Authorization:getLocalStorageValue('adminPanelLoginData').token
                    },
                    data: { 
                        month:fromMonth,
                        advertiser_id:valueItem.advertiser_id,
                        tags_list
                        }
                })
                .then(async(resp) => {
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                        }
                })
            }
            else{
                Store.addNotification({
                    title: "Warning!",
                    message: "Right now we support tag adding for single month only.",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 3000,
                      onScreen: true
                    }
                });
            }
        
        }
        if(!(fromMonth===toMonth || (fromMonth && !toMonth))){
            return 'NA'
        }
        if(item.advertiserBillingTagsLocked || (checkForPermission('can_view_billing_only') && !isSuperUser())){
            if(item.advertiserBillingTags[valueItem.advertiser_id] && item.advertiserBillingTags[valueItem.advertiser_id].length>0){
                return <ol className='billingTags'>{item.advertiserBillingTags[valueItem.advertiser_id].map((item,index)=>{return <li key={index}>{item}</li>})}</ol>
            } 
            else{
                return '-'
            }
        }
        return <ReactSelect 
                    onKeyDown={(e)=>{if(e.key==='Enter'){item.setAdvertiserBillingTagsLocked(true)}}}
                    isClearable={true}
                    options={optionsAdvertiserBillingTagList}
                    value={item.advertiserBillingTags[valueItem.advertiser_id] ? item.advertiserBillingTags[valueItem.advertiser_id].map((it)=>{return {label:it,value:it}}) : null}
                    isMulti={true}
                    onChange={(e)=>{updateBillingTags(e)}}
                    menuPortalTarget={document.body} 
                    styles={{...ReactSelectStyles}}/>
    }

    const tempTableHeaders = [
        {label:'Advertiser',value:'advertiser_id',width:120,locked:true,showTooltip:true,hide:false,customComponent:advertiserComponent},
        {label:'Offers Validated',value:'offers_approved_percentage',width:120,hide:false,customComponent:validationComponent},
        {label:'Validation Comment',value:'offers_approved_percentage',width:120,hide:false,customComponent:validatedCommentComponent},
        {label:'Delivered Qty',value:'conversions',width:100,hide:true},
        {label:'Delivered Revenue',value:'delivered_revenue',width:100,customComponent:roundComponent},
        {label:'Declined Revenue',value:'declined_revenue',width:100,hide:true,customComponent:roundComponent},
        {label:'Total Revenue',value:'total_revenue',width:100,hide:false,customComponent:roundComponent},
        {label:'Manual Revenue',value:'conversions_verified',width:100,hide:false,customComponent:roundComponent},
        {label:'Revenue Difference (Manual -'+(calculateWith==='delivered_revenue' ? 'Delivered' : 'Total')+')',value:'diff_manual_'+(calculateWith==='delivered_revenue' ? 'delivered' : 'panel'),width:100,hide:false,customComponent:roundComponent},
        {label:'Panel Revenue Accuracy',value:'diff_manual_'+(calculateWith==='delivered_revenue' ? 'delivered' : 'panel')+'_percent',width:100,hide:false,customComponent:diffManualPanelPercent},
        {label:'Discrepancy Tag',value:'validation_tag',hideSorting:true,width:160,hide:false,customComponent:validationTagComponent,advertiserBillingTags,setAdvertiserBillingTags,advertiserBillingTagsLocked,setAdvertiserBillingTagsLocked},
        {label:'Approved Revenue',value:'manager_approved_revenue',width:100,hide:false,customComponent:roundApprovedComponent},
        {label:'Validation Percentage',value:'validation_percentage'+(calculateWith==='conversions_verified' ? '_manual' : calculateWith==='delivered_revenue' ? '_delivered' : ''),width:100,hide:false,customComponent:validationPercentageComponent},
        {label:'Deduction',value:'deduction_revenue'+(calculateWith==='conversions_verified' ? '_manual' : calculateWith==='delivered_revenue' ? '_delivered' : ''),width:100,hide:false,customComponent:roundComponent},
        {label:'Pending',value:'pending_revenue'+(calculateWith==='conversions_verified' ? '_manual' : calculateWith==='delivered_revenue' ? '_delivered' : ''),width:100,hide:false,customComponent:roundComponent},
        {label:'      ',value:'view_details',width:80,hideSorting:true,hide:false,customComponent:showDetailSummaryComponent},
    ]
    // const [tableHeaders, setTableHeaders] = useState(tempTableHeaders)
    useEffect(() => {
        document.addEventListener('click',e=>{

        })
        document.addEventListener('keydown',e=>{
            if(e.key==='Escape'){
                setActiveTab(false)
            }
        })
        return () => {
            document.removeEventListener('click',e=>{
            })
            document.removeEventListener('keydown',e=>{
                if(e.key==='Escape'){
                    setActiveTab(false)
                }
            })
        }
    }, [])

    
    const summaryActivePopupData = () => {
        const getSeries = (value) => {
            if(montlyAdvertiserData[activeTab]){
                var series = montlyAdvertiserData[activeTab].map(item=>{
                    return item[value]
                })
            }
            else{
                series = []
            }
            return series
        }
        const state = {
            series: [
                {
                    name: "Delivered Quantity",
                    type:"line",
                    data: getSeries('conversions')
                  },
                  {
                    name: "Validation Percentage",
                    type:"line",
                    data: compareWithManual ? getSeries('validation_percentage_manual') : getSeries('validation_percentage')
                  },
                {
                  name: "Revenue",
                  type:"column",
                  data: compareWithManual ? getSeries('conversions_verified') : getSeries('total_revenue')
                },
                {
                  name: "Validated Revenue",
                  type:"column",
                  data: getSeries('manager_approved_revenue')
                },
                {
                  name: "Deduction Revenue",
                  type:"column",
                  data: compareWithManual ? getSeries('deduction_revenue_manual') : getSeries('deduction_revenue')
                },
                {
                  name: "Pending Revenue",
                  type:"column",
                  data: compareWithManual ? getSeries('pending_revenue_manual') : getSeries('pending_revenue')
                },
              ],
            options: {
              plotOptions: {
                bar: {
                  columnWidth: "30%"
                }
              },
              chart: {
                height: 350,
                width: 850,
                type: 'line',
                  dropShadow: {
                    enabled: false,
                    color: '#000',
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2
                  },
                  toolbar: {
                    show: false
                  },
                  zoom: {
                    enabled: false,
                  }
                },
                colors: ['#8a2900','#8a2900','#8B75D7','#50E7A6','#FF6378','#FEBC4A'],
                dataLabels: {
                enabled: false,
              },
              grid:{
                show:true
              },
              markers: {
                  size: 4
                },
    
              stroke:{
                width:2,
                curve: 'smooth',
              },
              xaxis: {
                  crosshairs:{
                        show:false,
                  },  
                  labels: {
                    
                  },
                  // type: ',
                  tickAmount: 'dataPoints',
                  categories:getSeries('month').map((item)=>{
                    return new Date(item).toLocaleDateString('en-US',{month:'long'})
                  }),
                  forceNiceScale:true,
    
              },
              yaxis: [
                { 
                  min:Math.min(0),
                  max:Math.max(...getSeries('conversions'))+100,
                    forceNiceScale:true,
                    show:false,
                    opposite: true,
                    tickAmount: 5,
                    labels:{
                        formatter: (value) => { return parseInt(Math.round(value)) },
                    },
                },
                { 
                    // min:Math.min(0),
                    // max:Math.max(...getSeries('validation_percentage')),
                      forceNiceScale:true,
                      show:false,
                      opposite: true,
                      tickAmount: 5,
                      labels:{
                          formatter: (value) => { return (value ? parseInt(Math.round(value)) : 0 )+'%'},
                      },
                  },
                  { 
                    min:Math.min(0),
                    max:Math.max(...getSeries('total_revenue'))+100,
                      forceNiceScale:true,
                      show:false,
                      opposite: true,
                      tickAmount: 5,
                      labels:{
                          formatter: (value) => { return value ? parseInt(Math.round(value)) : 0 },
                      },
                  },
                  { 
                    min:Math.min(0),
                    max:Math.max(...getSeries('total_revenue'))+100,
                      forceNiceScale:true,
                      show:false,
                      opposite: true,
                      tickAmount: 5,
                      labels:{
                          formatter: (value) => { return value ? parseInt(Math.round(value)) : 0 },
                      },
                  },
                  { 
                    min:Math.min(0),
                    max:Math.max(...getSeries('total_revenue'))+100,
                      forceNiceScale:true,
                      show:false,
                      opposite: true,
                      tickAmount: 5,
                      labels:{
                          formatter: (value) => { return value ? parseInt(Math.round(value)) : 0 },
                      },
                  },
                  { 
                    min:Math.min(0),
                    max:Math.max(...getSeries('total_revenue'))+100,
                      forceNiceScale:true,
                      show:false,
                      opposite: true,
                      tickAmount: 5,
                      labels:{
                          formatter: (value) => { return value ? parseInt(Math.round(value)) : 0 },
                      },
                  },
            ],
              
              legend: {
                position: 'top',
                horizontalAlign: 'right',
                floating: true,
                offsetY: 0,
                offsetX: -5
              }
            },
        };
        
        const monthComponent = (valueItem,item) => {
            if(optionsAdvertiserList.advertiserDict){
                return <a style={{textDecoration:'none',color:'blue'}} href={'/billing/advertiser?monthSelected='+valueItem.month+"&advertiser_id="+valueItem.advertiser_id+"&advertiser_name="+optionsAdvertiserList.advertiserDict[valueItem.advertiser_id]} target='_blank'>
                    {new Date(valueItem[item.value]).toLocaleDateString('en-US',{year:'numeric',month:'long'})}
                </a>
            }
        }

        var ytdTableHeaders = [
            {label:'Month',value:'month',width:120,customComponent:monthComponent,hideSorting:true},
            {label:'Offers Validated',value:'offers_approved_percentage',width:120,customComponent:validationComponent,hideSorting:true},
            {label:'Delivered Qty',value:'conversions',hide:true,width:100,hideSorting:true},
            {label:'Delivered Revenue',value:'delivered_revenue',width:100,customComponent:roundComponent,hideSorting:true},
            {label:'Declined Revenue',value:'declined_revenue',hide:true,width:100,customComponent:roundComponent,hideSorting:true},
            {label:'Total Revenue',value:'total_revenue',width:100,customComponent:roundComponent,hideSorting:true},
            {label:'Manual Revenue',value:'conversions_verified',width:100,customComponent:roundComponent},
            {label:'Revenue Difference (Manual -'+(calculateWith==='delivered_revenue' ? 'Delivered' : 'Total')+')',value:'diff_manual_'+(calculateWith==='delivered_revenue' ? 'delivered' : 'panel'),width:100,hide:false,customComponent:roundComponent},
            {label:'Panel Revenue Accuracy',value:'diff_manual_'+(calculateWith==='delivered_revenue' ? 'delivered' : 'panel')+'_percent',width:100,hide:false,customComponent:diffManualPanelPercent},
            {label:'Approved Revenue',value:'manager_approved_revenue',width:100,customComponent:roundApprovedComponent,hideSorting:true},
            {label:'Validation Percentage',value:'validation_percentage'+(calculateWith==='conversions_verified' ? '_manual' : calculateWith==='delivered_revenue' ? '_delivered' : ''),width:100,hide:false,customComponent:validationPercentageComponent},
            {label:'Deduction',value:'deduction_revenue'+(calculateWith==='conversions_verified' ? '_manual' : calculateWith==='delivered_revenue' ? '_delivered' : ''),width:100,hide:false,customComponent:roundComponent},
            {label:'Pending',value:'pending_revenue'+(calculateWith==='conversions_verified' ? '_manual' : calculateWith==='delivered_revenue' ? '_delivered' : ''),width:100,hide:false,customComponent:roundComponent},
        ]
        return <div className='summaryActiveTabData'>
            <div style={{fontSize:'17px',fontWeight:'500',marginTop:'10px',marginBottom:'20px'}}>
                Summary of {new Date(monthSelected).toLocaleDateString('en-US',{year:'numeric'})}
            </div>
            {
                !montlyAdvertiserData[activeTab]
                &&
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',minHeight:'200px'}}>
                    <CircularProgress sx={{color:localStorage.getItem('ambientColor')}}/>
                </div>
            }   
            {
                montlyAdvertiserData[activeTab]
                &&
                <ReactApexChart className="graphs" options={state.options} series={state.series} type="line" height={"350px"} width={"100%"}/>
            }
            {
                montlyAdvertiserData[activeTab]
                &&
                <TableModel
                    id={'ytdAdvertiserBilling'}
                    serialOffset={offset}
                    tableHeaders={ytdTableHeaders}
                    tableValues={montlyAdvertiserData[activeTab] ? montlyAdvertiserData[activeTab] : []}
                    setTableValues={()=>{}}
                    style={{background:'rgb(245,245,245)'}}
                    tableValueRow={'rest'}
                    setTableValueRow={()=>{console.log('test')}}
                    />
            }
        </div>
    }

    var lockUnlockButton = () => {
        return <IconButton onClick={()=>{setAdvertiserBillingTagsLocked(!advertiserBillingTagsLocked)}}>
            <Tooltip title={advertiserBillingTagsLocked ? 'Click to edit Accuracy Tag' : 'Click to disable editing Accuracy Tag'}>
                {advertiserBillingTagsLocked ? <LockIcon/> : <LockOpenIcon/>}
            </Tooltip>
        </IconButton>
    }
    return (
        <div className='advertiserBillingContainer'>
            <br/>
            <div style={{position:'sticky',left:0,width:'100%',zIndex:'999'}}>
                <div>

                <div className='advertiserLabel'>Advertiser Summary</div>
                    <div className='advertiserFilters'>
                            
                        {filters.map((filterItem,filterIndex)=>{
                            return <div className='filterItem' key={filterIndex}>
                                    {filterItem.type !=='button' && <div className='filterItemLabel'>{filterItem.label}</div>}
                                    {/* {filterItem.type === 'monthpicker'
                                    &&
                                    <input className='monthPicker' value={monthSelected} onChange={(e)=>{setMonthSelected(e.target.value);var oldSessionFilterStorage = getLocalStorageValue('billingFilters') ? getLocalStorageValue('billingFilters') : {}
                                    setLocalStorageValue('billingFilters',{...oldSessionFilterStorage,monthSelected:e.target.value});}} type='month'/>
                                    }
                                    {filterItem.type === 'monthpicker'
                                    &&
                                    datatillThisMonth
                                    &&
                                    <input className='monthPicker' value={monthSelected} onChange={(e)=>{setMonthSelected(e.target.value);var oldSessionFilterStorage = getLocalStorageValue('billingFilters') ? getLocalStorageValue('billingFilters') : {}
                                    setLocalStorageValue('billingFilters',{...oldSessionFilterStorage,monthSelected:e.target.value});}} type='month'/>
                                    } */}
                                    {filterItem.type === 'monthpicker'
                                    &&
                                    <DatePicker
                                        // selected={new Date(monthSelected)}
                                        customInput={<input className='monthPicker'/>}
                                        selectsRange
                                        startDate={new Date(monthSelected[0])}
                                        endDate={monthSelected[1] ? new Date(monthSelected[1]) : null}
                                        onChange={(date)=>{
                                            var date_temp = date[0].toLocaleString('en-US',{year:'numeric',month:'numeric'}).split(',')[0].split('/')
                                            date[0] = date_temp[1]+'-'+zeroPad(date_temp[0],2)
                                            if(date[1]){
                                                date_temp = date[1].toLocaleString('en-US',{year:'numeric',month:'numeric'}).split(',')[0].split('/')
                                                date[1] = date_temp[1]+'-'+zeroPad(date_temp[0],2)
                                            }
                                            setMonthSelected(date);
                                            var oldSessionFilterStorage = getLocalStorageValue('billingFilters') ? getLocalStorageValue('billingFilters') : {}
                                            setLocalStorageValue('billingFilters',{...oldSessionFilterStorage,monthSelected:date});}}
                                        showYearPicker={datatillThisMonth ? true : false}
                                        showMonthYearPicker={datatillThisMonth ? false : true}
                                        dateFormat={datatillThisMonth ? "yyyy" : 'MMMM yyyy'}
                                    />
                                    }
                                    {filterItem.type === 'textfield'
                                    &&
                                    <CssTextField variant='standard' value={filterData[filterItem.value] ? filterData[filterItem.value] : ''} onChange={e=>{filterItem.setMethod(e.target.value,filterItem);}}/>}
                                    {filterItem.type === 'select'
                                    &&
                                    <div style={{minWidth:200}}>
                                        <ReactSelect 
                                            isClearable={filterItem.value==='team' ? isClearableTeam : true}
                                            options={filterItem.options}
                                            value={filterData[filterItem.value] ? filterData[filterItem.value] : ''}
                                            isMulti={filterItem.isSingle ? false : true}
                                            onChange={(e)=>{filterItem.setMethod(e,filterItem)}}
                                            menuPortalTarget={document.body} 
                                            styles={{...ReactSelectStyles}}/>
                                    </div>
                                    }
                                    {
                                        filterItem.type === 'button'
                                        &&
                                        <div style={{marginTop:'21px'}}>
                                            <Button id='testbutton' disabled={filterItem.disabled} onClick={filterItem.onSubmit} sx={{...(filterItem.variant && filterItem.variant==='outlined' ? Constants.OUTLINEDBUTTON : Constants.CONTAINEDBUTTON),fontSize:'14px',minWidth:'102px',minHeight:'40px'}}>
                                                {filterItem.disabled
                                                &&
                                                <CircularProgress size={'15px'} sx={{color:'white'}}/>
                                                }
                                                {!filterItem.disabled && filterItem.label}
                                            </Button>
                                        </div>
                                    }
                                </div>
                        })}
                    </div>
                    <div style={{display:'flex'}}>
                        <div style={{paddingLeft:'20px'}}>
                            Calculation with 
                            <select value={calculateWith} className='selectPending' style={{fontSize:'16px',width:'auto'}} onChange={e=>{setCalculateWith(e.target.value)}}>
                                <option value='delivered_revenue'>Delivered Revenue</option>
                                <option value='total_revenue'>Total Revenue</option>
                                <option value='conversions_verified'>Manual Revenue</option>
                            </select>
                        </div>
                        <div style={{paddingLeft:'20px'}}>
                            <input checked={excludeMMPRebroking} id='exludeMMPRebroking' type='checkbox' onChange={(e)=>{setExcludeMMPRebroking(e.target.checked);updateFilterData(e.target.checked,{value:'exclude_mmp_rebroking'});}}/>
                            <label style={{fontSize:'14px'}} htmlFor='exludeMMPRebroking' onChange={(e)=>{setExcludeMMPRebroking(e.target.checked);updateFilterData(e.target.checked,{value:'exclude_mmp_rebroking'})}}>Exclude MMP Rebroking</label>
                        </div>
                        {/* <div style={{paddingLeft:'20px'}}>
                            <input checked={datatillThisMonth} id='dataTillThisMonth' type='checkbox' onChange={(e)=>{setDatatillThisMonth(e.target.checked);updateFilterData(e.target.checked,{value:'data_till_this_month'});}}/>
                            <label style={{fontSize:'14px'}} htmlFor='dataTillThisMonth' onChange={(e)=>{setDatatillThisMonth(e.target.checked);updateFilterData(e.target.checked,{value:'data_till_this_month'})}}>Get Year Stats.</label>
                        </div> */}
                    </div>
                </div>
            </div>
            {loader.main
            &&
            <ShowLoaderComponent/>
            }
            {!loader.main
            &&
            typeof tableValues === 'object' && 
            tableValues.length>0 
            &&
            <TableModel
                serialOffset={offset}
                tableHeaders={tempTableHeaders}
                tableValues={tableValues}
                setTableValues={setTableValues}
                style={{background:'rgb(245,245,245)'}}
                tableValueRow={'rest'}
                setTableValueRow={()=>{console.log('test')}}
                totalRow={totalRow.length>0 ? totalRow : undefined}
                sortingFunction={sortingFunction}
                exportFunction={()=>{getRevenueData(null,null,null,null,null,true)}}
                optionsTopbar={!(checkForPermission('can_view_billing_only') && !isSuperUser()) ? [lockUnlockButton()] : null}
                />
            }
            {!loader.main && totalResults/limit>1 && typeof tableValues === 'object' && tableValues.length>0 && 
                <div style={{display:'flex',alignItems:'center',justifyContent:"space-between",paddingLeft:'20px',paddingRight:'20px',paddingBottom:'20px',position:'sticky',bottom:0,left:0,background:'white',zIndex:1000}}>
                    <div>
                        <label style={{paddingRight:"5px",fontSize:'14px'}}>Limit</label>
                        <select value={limit} style={{outline:'none',border:'none'}} onChange={(e)=>{getRevenueData(e,e.target.value,1);setLimit(parseInt(e.target.value));setPage(1);}}>
                            {[5,10,50,100,200,300,500].map((item,index)=>{
                                return <option key={index} value={item}>{item}</option>
                            })}
                            {/* {statsType!=='offer' && [25,50,75,100].map((item,index)=>{
                                return <option key={index} value={item}>{item}</option>
                            })} */}
                        </select>
                    </div>
                    <Pagination count={Math.ceil(totalResults/limit)} page={page} onChange={(event,value)=>{getRevenueData(event,limit,value);setPage(value);refreshComponent(event);window.scrollTo({top: 0,left: 0,behavior: 'smooth'});}} />
                </div>
            }
            {!loader.main
                &&typeof tableValues === 'object' && 
                tableValues.length===0 &&
                <NoResultsFound/>
            }
            {!loader.main
                &&
                typeof tableValues === 'string' &&
                <SomeErrorOccured error={tableValues}/>
            }
            {
                (activeTab===0 || activeTab)
                &&
                <div className='activeSummaryTabContainer'>
                    <div className='activeSummaryTabWrapper'>
                        <div className='activeSummaryHeaderRow'>
                            <div style={{fontSize:'18px',fontWeight:'500'}}>
                                {(tableValues[activeTab] && optionsAdvertiserList.advertiserDict ? optionsAdvertiserList.advertiserDict[tableValues[activeTab].advertiser_id] : '') +  (filterData.package_name ? (' => '+filterData.package_name)  : '')}
                            </div>
                            <div style={{width:'60px',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                                <Tooltip title={'Minimise'}>
                                    <MinimizeIcon sx={{color:'black',marginTop:'-10px'}}  onClick={()=>{
                                        var tempOpenTabs = openedTabs;
                                        tempOpenTabs = tempOpenTabs.filter(e => e.valueIndex!==activeTab);
                                        tempOpenTabs.push({...tableValues[activeTab],valueIndex:activeTab});
                                        setOpenedTabs(tempOpenTabs);
                                        setActiveTab(false);
                                        }}/>
                                </Tooltip>
                                <Tooltip title={'Close'}>
                                    <Close sx={{color:'black'}} onClick={()=>{
                                        var tempOpenTabs = openedTabs;
                                        tempOpenTabs = tempOpenTabs.filter(e => e.valueIndex!==activeTab);
                                        setOpenedTabs(tempOpenTabs);
                                        setActiveTab(false);
                                        if(montlyAdvertiserData[activeTab]){
                                            var tempMonthlyData = montlyAdvertiserData
                                            delete tempMonthlyData[activeTab]
                                            setMontlyAdvertiserData(tempMonthlyData)
                                        }
                                    }}/>
                                </Tooltip>
                            </div>
                        </div>
                        <div className='activeSummaryTabData'>
                            {summaryActivePopupData()}
                        </div>
                    </div>
                </div>
            }
            {
                openedTabs.length>0
                &&
                <div className='openedTabsRowContainer'>
                    {openedTabs.map((tabItem,tabIndex)=>{
                        if(tabItem.valueIndex !== activeTab){
                            return <div key={tabIndex} className='openedTabWrapper'>
                                <div className='openedTabLabel'>
                                    {(optionsAdvertiserList ? optionsAdvertiserList.advertiserDict[tabItem.advertiser_id] : '') + (filterData.package_name ? (' => '+filterData.package_name)  : '')}
                                </div>
                                <div style={{width:'40px',display:'flex',alignItems:'center',justifyContent:'space-around'}}>
                                    <Tooltip title={'Maximize'}>
                                        <LaunchIcon sx={{color:'black',fontSize:'18px'}} onClick={()=>{setActiveTab(tabItem.valueIndex)}}/>
                                    </Tooltip>
                                    <Tooltip title={'Close'}>
                                        <Close sx={{color:'black',fontSize:'18px'}}
                                            onClick={()=>{
                                                var tempOpenTabs = openedTabs;
                                                tempOpenTabs = tempOpenTabs.filter(e => e.valueIndex!==tabItem.valueIndex);
                                                setOpenedTabs(tempOpenTabs);
                                                setActiveTab(false);
                                                if(montlyAdvertiserData[tabItem.valueIndex]){
                                                    var tempMonthlyData = montlyAdvertiserData
                                                    delete tempMonthlyData[tabItem.valueIndex]
                                                    setMontlyAdvertiserData(tempMonthlyData)
                                                }
                                            }}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        }
                    })}
                    
                </div>
            }
        </div>
    )
}

export default AdvertiserBillingSummary