import React, { useState } from 'react'
import * as Constants from '../../data/constants';
import { DateRangePicker } from 'react-date-range';
import { createGlobalStyle } from 'styled-components';
import ReactSelect from 'react-select';
import { Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import { getLocalStorageValue, useForceUpdate } from '../../util/util';
import { Store } from 'react-notifications-component';

const DatePickerWrapperStyles = createGlobalStyle`
    .rdrDefinedRangesWrapper {
        display: none;
    }
`;
const GenerateAffiliateInvoice = (props) => {
    const refreshComponent = useForceUpdate();
    const setShowGenerateInvoiceAffiliate = props.setShowGenerateInvoiceAffiliate
    const [showDatePickerGenerate, setShowDatePickeGenerate] = useState(false)
    const [dateRange, setDateRange] = useState([new Date(),new Date()])
    const [dateRangeState, setDateRangeState] = useState([{
        startDate: new Date(dateRange[0]),
        endDate: new Date(dateRange[1]),
        key: 'selection'
    }])
    const [endDateRange, setEndDateRange] = useState({label:'Last 7 Days',value:7})
    const [affiliateAdvertiserSelected, setAffiliateAdvertiserSelected] = useState(false)
    const [currencySelected, setCurrencySelected] = useState(false)

    const dateRangePickedMethod = (item) => {
        setDateRangeState([item.selection]);
        setShowDatePickeGenerate(false)
        var value = [item.selection.startDate,item.selection.endDate]
        if (value[0] && value[1]){
            setDateRange(value)
            setEndDateRange(false);
        }
        refreshComponent(123);
    }
    
    const [generateInvoiceLoader, setGenerateInvoiceLoader] = useState(false)
    const generateInvoice = async() => {
        var startDate = new Date(new Date(dateRange[0]).getTime() - 86400000*(endDateRange ? endDateRange.value-1 : 0)).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })
        var endDate = dateRange[0].toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })
        setGenerateInvoiceLoader(true)
        await axios({
            method: 'POST',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"generate/invoice"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                'Content-Type':'application/json'
            },
            data:{
                startDate,
                endDate,
                affiliate_id:affiliateAdvertiserSelected ? affiliateAdvertiserSelected.value : undefined,
                currency:currencySelected ? currencySelected.value : undefined,
            }
        })
        .then(resp => {
            if(resp.data.result === "Invoice sucessfully generated"){
                Store.addNotification({
                    title: "Success!",
                    message: resp.data.result,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                });
            }
            else{
                Store.addNotification({
                    title: "Warning!",
                    message: resp.data.result,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                });
            }
            props.getInvoiceList();
            props.setShowGenerateInvoiceAffiliate(false);
        })
        .catch(error => {
            console.error(error);
            if(error.response && error.response.data){
                Store.addNotification({
                    title: "Error!",
                    message: typeof error.response.data.result === "string" ? error.response.data.result : "Some error occured",
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                });
            }
        })
        setGenerateInvoiceLoader(false)
    };
    
    return (
        <div onClick={(e)=>{e.stopPropagation()}} style={{position:'fixed',width:'70vw',minHeight:'50vh',top:'20vh',left:'15vw',background:'white',border:'1px solid rgb(220,220,220)',zIndex:'1000',boxShadow:'0 10px 10px 5px rgb(230,230,230)'}}>
            
            <div style={{display:'flex',justifyContent:'space-between',padding:'10px',fontSize:'18px'}}>
                <div>Generate Affiliate Invoice</div>
                <div style={{cursor:'pointer'}} onClick={()=>{setShowGenerateInvoiceAffiliate(false);}}>X</div>
            </div>
            <div style={{padding:'20px'}}>

                <div className='invoiceFlex' style={{paddingTop:'20px'}}>
                    <div className='invoiceLabel'>Affiliate</div>
                    <div style={{position:'relative',zIndex:0,transform:'scale(0.8)',transformOrigin:'left',minWidth:'220px'}}>
                        <ReactSelect 
                            options={props.affiliateAdvertiserOptions}
                            value={affiliateAdvertiserSelected}
                            // isMulti={filterItem.isSingle ? false : true}
                            onChange={(e)=>{setAffiliateAdvertiserSelected(e)}}
                            placeholder={'Select Affiliate'}
                            menuPortalTarget={document.body} 
                            styles={{...Constants.ReactSelectStyles}}
                        />
                    </div>
                </div>
                <div className='invoiceFlex'>
                    <div className='invoiceLabel'>Start Date Range</div>
                    <div style={{position:'relative',transform:'scale(0.8)',transformOrigin:'left',minWidth:'220px'}}>
                        <ReactSelect 
                            options={[
                                        {label:'Last 7 Days',value:7},
                                        {label:'Last 15 Days',value:15},
                                        {label:('Last 30 Days'),value:30},
                                        {label:('This month'),value:new Date(dateRange[0]).getDate()},
                                    ]}
                            value={endDateRange}
                            // isMulti={filterItem.isSingle ? false : true}
                            onChange={(e)=>{setEndDateRange(e)}}
                            placeholder={'Select Range'}
                            menuPortalTarget={document.body} 
                            styles={{...Constants.ReactSelectStyles}}
                        />
                    </div>
                </div>
                <div className='invoiceFlex'>
                    <div className='invoiceLabel'>End Date</div>
                    <div style={{position:'relative',zIndex:1,transform:'scale(0.8)',transformOrigin:'left'}}>
                        <Constants.CssTextField sx={{minWidth:'220px'}} size='small' value={dateRangeState[0].startDate ? dateRangeState[0].startDate.toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }) : ''} style={{width:'200px'}} onClick={()=>{setShowDatePickeGenerate(true)}}/>
                        <div style={{position:'absolute',zIndex:10,transform:'scale(1)',transformOrigin:'top left'}}>
                            {showDatePickerGenerate && <DateRangePicker
                                onChange={item => dateRangePickedMethod(item)}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={1}
                                preventSnapRefocus={true}
                                rangeColors={[localStorage.getItem('ambientColor')]}
                                ranges={dateRangeState}
                                showDateDisplay={false}
                                staticRanges={[]}
                                inputRanges={[]}
                                scroll={{ enabled: window.innerWidth<800 ? true : false }}
                                direction={window.innerWidth<800 ? "vertical" : "horizontal"}
                                calendarFocus="backwards"
                                />
                            }
                            <DatePickerWrapperStyles/>
                        </div>
                    </div>
                </div>
            
                <div className='invoiceFlex' style={{paddingTop:'20px'}}>
                    <div className='invoiceLabel'>Selected Date Range</div>
                    <div style={{position:'relative',zIndex:'0',transform:'scale(0.8)',transformOrigin:'left',minWidth:'220px'}}>
                        <label>{new Date(new Date(dateRange[0]).getTime() - 86400000*(endDateRange ? endDateRange.value - 1 : 0)).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }) + ' -> ' +  dateRange[0].toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })}</label>
                    </div>
                </div>

                <div className='invoiceFlex' style={{paddingTop:'20px'}}>
                    <div className='invoiceLabel'>Currency</div>
                    <div style={{position:'relative',zIndex:0,transform:'scale(0.8)',transformOrigin:'left',minWidth:'220px'}}>
                        <ReactSelect 
                            options={getLocalStorageValue('adminPanelLoginData').panelSettings && getLocalStorageValue('adminPanelLoginData').panelSettings.currency ? Object.keys(getLocalStorageValue('adminPanelLoginData').panelSettings.currency).map((item,index)=>{return {label:item,value:item}}) : []}
                            value={currencySelected}
                            // isMulti={filterItem.isSingle ? false : true}
                            onChange={(e)=>{setCurrencySelected(e)}}
                            placeholder={'Select Currency'}
                            menuPortalTarget={document.body} 
                            styles={{...Constants.ReactSelectStyles}}
                        />
                    </div>
                </div>
                <div style={{marginRight:'20px',marginBottom:'20px',marginTop:'30px',marginLeft:'20px'}}>
                    <Button disabled={!currencySelected.value || !affiliateAdvertiserSelected.value} sx={{...Constants.CONTAINEDBUTTON,textTransform:'capitalize',fontSize:'12px',minWidth:'90px',minHeight:'33px'}} onClick={generateInvoice}>
                        {generateInvoiceLoader && <CircularProgress size={'14px'} sx={{color:'white'}}/>}
                        {!generateInvoiceLoader && "Generate"}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default GenerateAffiliateInvoice