import React, { useEffect, useState } from 'react';
import './export.css';
import exportImage from '../../img/export.png'
import { Button, CircularProgress } from '@mui/material';
import * as Constants from '../../data/constants';
import { exportAdvertiser, exportAffiliate, exportOffers } from '../../apis/export';
import { getLocalStorageValue, setLocalStorageValue, useForceUpdate } from '../../util/util';
import CreatableSelect from 'react-select/creatable';
import { increase_brightness } from '../customization/customization';

const ExportPopup = (props) => {
    const refreshComponent = useForceUpdate()
    const [selectedFields, setSelectedFields] = useState([])
    const [loader,setLoader] = useState(false)
    var differentPages = {

        AdvertiserView : [
            {label:'Advertiser ID',value:'id'},
            {label:'Contact Person Name',value:'name'},
            {label:'Company',value:'company'},
            {label:'Address',value:'address'},
            {label:'Contact Email',value:'email'},
            {label:'Manager',value:'manager_name'},
            {label:'Skype ID',value:'skype_id'},
            {label:'Website',value:'website'},
            {label:'Contact Number',value:'contact_number'},
            {label:'Created At',value:'created_at'},
        ],

        AffiliateView : [
            {label:'Affiliate ID',value:'id'},
            {label:'Contact Person Name',value:'contact_person'},
            {label:'Company',value:'company'},
            {label:'Address',value:'address'},
            {label:'Contact Email',value:'email'},
            {label:'Manager',value:'manager_name'},
            {label:'Skype ID',value:'skype_id'},
            {label:'Contact Number',value:'contact_number'},
            {label:'Created At',value:'created_at'},
        ],

        OfferView : [
            {label:'Offer Id',value:'id'},
            {label:'Offer Title',value:'title'},
            {label:'Advertiser',value:'advertiser'},
            {label:'Status',value:'offer_status'},
            {label:'Type',value:'offer_type'},
            {label:'Affiliates',value:'affiliates'},
            {label:'Categories',value:'categories'},
            {label:'OS',value:'os'},
            {label:'External Offer Id',value:'external_offer_id'},
            {label:'Geo',value:'geo'},
            {label:'KPI',value:'kpi'},
            {label:'Description',value:'description'},
            {label:'Caps',value:'caps'},
            {label:'Payouts',value:'payouts'},
            {label:'Tracking Url',value:'tracking_url'},
            {label:'Preview Url',value:'preview_url'},
            {label:'Impression Url',value:'impression_link'},
            {label:'Logo',value:'logo'},
            {label:'Stop At',value:'stop_at'},
        ]
    }

    const handleExportDataButton = () => {
        if(props.type==='OfferView'){
            exportOffers(selectedFields,props.filterSearch,setLoader,props.setExportPopup)
        }
        else if(props.type==='AdvertiserView'){
            exportAdvertiser(selectedFields,props.filterSearch,setLoader,props.setExportPopup)
        }
        else if(props.type==='AffiliateView'){
            exportAffiliate(selectedFields,props.filterSearch,setLoader,props.setExportPopup)
        }
    }

    const [manualUseEffect, setManualUseEffect] = useState(false)
    const [presetOptions, setPresetOptions] = useState([])

    if(!manualUseEffect){
        setManualUseEffect(true);
        var tempPresetOptions = []
        if(getLocalStorageValue(window.location.pathname+'/export-preset')){
            tempPresetOptions = getLocalStorageValue(window.location.pathname+'/export-preset')
        }
        setPresetOptions(tempPresetOptions)
    }
    const [presetSelected, setPresetSelected] = useState('')
    const handlePresetSelect = (e) => {
        setPresetSelected(e)
        if(e.selectedFields){
            setSelectedFields(e.selectedFields)
        }
    }
    const [presetAdded, setPresetAdded] = useState(false)
    const handleAddPreset = (e) => {
        var tempPresetOptions = []
        if(getLocalStorageValue(window.location.pathname+'/export-preset')){
            tempPresetOptions = getLocalStorageValue(window.location.pathname+'/export-preset')
        }
        var foundFlag = false
        for(var index in tempPresetOptions){
            if(presetSelected.value===tempPresetOptions[index].value){
                foundFlag = true
                tempPresetOptions[index]['selectedFields'] = selectedFields
            } 
        }
        if(!foundFlag){
            var tempPresetSelected = presetSelected
            delete presetSelected['__isNew__']
            tempPresetOptions.push({...tempPresetSelected,selectedFields})
        }
        setLocalStorageValue(window.location.pathname+'/export-preset',tempPresetOptions)
        setPresetAdded(true);
    }
    useEffect(() => {
        document.addEventListener('keydown',(e)=>{
            if(e.key==='Escape'){props.setExportPopup(false);}
        })
        
        return () => {
            document.removeEventListener('keydown',(e)=>{
                if(e.key==='Escape'){props.setExportPopup(false);}
            })
        }
    }, [props])
    
    return (
        <div className='exportCSVPopupContainer' onClick={()=>{props.setExportPopup(false);}}>
            <div className='exportCSVPopupWrapper' onClick={(e)=>{e.stopPropagation();}}>
                <div style={{width:'40%',height:'90%',textAlign:'center',borderRight:'1px solid rgb(200,200,200)'}}>
                    <img src={exportImage} alt='' width={'90%'}/>
                    <div style={{fontWeight:'600',fontSize:'22px'}}>Export Data</div>
                </div>
                <div style={{width:'56%',display:'flex',flexDirection:'column',justifyContent:'center',height:'90%',paddingLeft:'2%',paddingRight:'2%'}}>
                    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                        <label style={{fontSize:'20px',fontWeight:'500'}}>Select Fields.</label>
                        <div style={{fontSize:'15px',cursor:'pointer',color:'rgb(100,100,100)'}} onClick={()=>{props.setExportPopup(false);}}>X</div>
                    </div>
                    <div style={{height:'8vh',display:'flex',alignItems:'center',transform:'scale(0.9) translateX(-5%)'}}>
                        <div style={{width:'50%'}}>
                            <CreatableSelect
                                options={presetOptions}
                                value={presetSelected}
                                onChange={handlePresetSelect}
                                menuPortalTarget={document.body} 
                                placeholder={"Select preset"}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 1000000 }),
                                            control: (base,state) => ({
                                                ...base,
                                                // maxHeight: 100,
                                                minHeight: 30,
                                                color:'rgb(200,200,200)',
                                                overflowY: "auto",
                                                fontSize:14,
                                                border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                                '&:hover': {
                                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                    boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                                }
                                            }),
                                            placeholder: (defaultStyles) => {
                                                return {
                                                    ...defaultStyles,
                                                    color: 'rgb(150,150,150)',
                                                    marginTop:'-2px'
                                                }
                                            },
                                            option: (styles, {isFocused, isSelected}) => ({
                                                ...styles,
                                                fontSize:'11px',
                                                background: isFocused
                                                    ? localStorage.getItem('ambientColor')
                                                    : isSelected
                                                        ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                        : undefined,
                                                zIndex: 1
                                            }),
                                        }}
                                />
                        </div>
                        <Button disabled={presetAdded || selectedFields.length===0 || !presetSelected} sx={{...Constants.OUTLINEDBUTTON,fontSize:'14px',height:'35px',width:'30%',paddingLeft:'15px',borderRadius:'0px',marginLeft:'15px',paddingRight:'15px',textTransform:'none',fontWeight:500}} onClick={handleAddPreset}>
                            {presetSelected && !presetSelected.__isNew__ && '+Update Preset'}
                            {(!presetSelected || presetSelected.__isNew__) && '+Add Preset'}
                        </Button>
                    </div>
                    <div style={{display:'flex',alignItems:'center',marginTop:'2%',fontSize:'12px'}}> 
                        <div style={{color:localStorage.getItem('ambientColor'),cursor:'pointer'}} onClick={()=>{var tempSelectedFields = []; tempSelectedFields = differentPages[props.type].map((item,index)=>{return item.value});setSelectedFields(tempSelectedFields);refreshComponent(123)}}>Select All</div>
                        <div style={{paddingLeft:'10px',cursor:'pointer'}} onClick={()=>{setSelectedFields([]);refreshComponent(123)}}>Unselect All</div>
                    </div>
                    <div style={{height:'60%',display:'flex',flexWrap:'wrap'}}>
                        {differentPages[props.type].map((item,index)=>{ 
                            return <div key={index} style={{width:'33%',fontSize:'13px',display:'flex',alignItems:'center'}}>
                                    <input type='checkbox' checked={selectedFields.includes(item.value)} id={item.value} onChange={(e)=>{setPresetAdded(false);if(e.target.checked){setSelectedFields([...selectedFields,item.value])}else{setSelectedFields(selectedFields.filter(e => e!==item.value))}}}/>
                                    <div>
                                        <label htmlFor={item.value}>
                                            {item.label}
                                        </label>
                                    </div>
                                </div>
                        })}
                    </div>
                    <Button disabled={selectedFields.length===0} sx={{...Constants.CONTAINEDBUTTON,fontSize:'14px',height:'35px',width:'100%',paddingLeft:'15px',borderRadius:'0px',paddingRight:'15px',marginTop:'20px',textTransform:'none',fontWeight:500}} onClick={(e)=>{handleExportDataButton()}}>
                        {!loader && "Request Export"}
                        {loader && <CircularProgress sx={{color:'white'}} size={18}/>}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ExportPopup