import axios from 'axios';
import * as Constants from '../../data/constants';
import { Store } from 'react-notifications-component';
import { getLocalStorageValue } from '../../util/util';

export async function loginAffiliateApi(affiliateId) {
    return await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"login/direct"),
        headers: {
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
        
        params: {
            "affiliate_id":affiliateId
        }
    })
     .then(resp => {
        var token_dict = JSON.stringify({token:"Token "+resp.data.token})
        window.open("http://"+Constants.AFFILIATE_LOGIN_HOST+"/dashboard/?email="+resp.data.user.email+"&aff_id="+affiliateId.value+"&companyname="+resp.data.user.company+"&token="+token_dict+"&manager_email="+resp.data.user.manager_email+"&manager="+resp.data.user.manager_name)
    })
    .catch(error => {
        if (error.response) {
        // Request made and server responded
            Store.addNotification({
                title: "Error!",
                message: typeof error.response.data.result === "string" ? error.response.data.result : "Some error occured",
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            }); 
            console.log(error.response.status);
            console.log(error.response.headers);
        } 
        else {
            // Something happened in setting up the request that triggered an Error
            Store.addNotification({
                title: "Error!",
                message: typeof error.message === "string" ? error.message : "Some error occured",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            }); 
            console.log('Error', error.message);
        }
    })
}