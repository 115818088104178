import React, { useState } from 'react';

import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import Switch from '@mui/material/Switch';
import Cookies from 'universal-cookie';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

function useForceUpdate(){
    const [value, setValue] = React.useState(0); // integer state
    if(false){
        console.log(value)
    }
    return () => setValue(value => value + 1); // update the state to force render
}

export const increase_brightness = function(color,percent){

    var ctx = document.createElement('canvas').getContext('2d');

    ctx.fillStyle = color;
    ctx.fillRect(0,0,1,1);

    color = ctx.getImageData(0,0,1,1);
    var r = color.data[0] + Math.floor( percent / 100 * 255 );
    var g = color.data[1] + Math.floor( percent / 100 * 255 );
    var b = color.data[2] + Math.floor( percent / 100 * 255 );

    return 'rgb('+r+','+g+','+b+')';
}

export const CustomizationPage = () => {
    const updateComponent = useForceUpdate()
    const cookies = new Cookies();
 
    /// update CSS
    var ambientColor = localStorage.getItem('ambientColor')  ? localStorage.getItem('ambientColor') : "rgb(0, 166, 178)"
    var fontColorLocal = cookies.get('fontColor')  ? localStorage.getItem('ambientColor') : "rgb(0, 0, 0)"
    var r = document.querySelector(':root');
    var reverse = cookies.get('nightmode')==='false' ? false : true
    if(!reverse){
        r.style.setProperty('--container-shadow-color', increase_brightness(ambientColor,-10));
        r.style.setProperty('--container-shadow-color-hover', ambientColor);
        r.style.setProperty('--container-shadow-color-hover-lighter', increase_brightness(ambientColor,10));    
        r.style.setProperty('--container-shadow-color-hover-more-lighter', increase_brightness(ambientColor,95));    
        r.style.setProperty('--top-bg-color', ambientColor);
        r.style.setProperty('--hamburger-icon-hover-color', increase_brightness(ambientColor,-10));
        r.style.setProperty('--menu-item-hover-color', 'white');
        r.style.setProperty('--menu-item-hover-font-color', 'black');
        r.style.setProperty('--font-color', 'white');
        r.style.setProperty('--sidebar-bg-color', ambientColor);
    }
    else{
        var backgroundColor = 'rgb(252,252,252)'
        r.style.setProperty('--container-shadow-color', increase_brightness(backgroundColor,-10));
        r.style.setProperty('--container-shadow-color-hover', backgroundColor);
        r.style.setProperty('--container-shadow-color-hover-lighter', increase_brightness(backgroundColor,10));     
        r.style.setProperty('--container-shadow-color-hover-more-lighter', increase_brightness(backgroundColor,0));    
        r.style.setProperty('--top-bg-color', backgroundColor);
        r.style.setProperty('--hamburger-icon-hover-color', increase_brightness(backgroundColor,-10));
        r.style.setProperty('--menu-item-hover-color', ambientColor);
        r.style.setProperty('--menu-item-hover-font-color', 'white');
        r.style.setProperty('--font-color', 'rgb(50,50,50)');
        r.style.setProperty('--sidebar-bg-color', backgroundColor);
    }
    
    const BootstrapButton = styled(Button)({
        ":disabled":{
            color:'rgb(150,150,150)',
            backgroundColor: increase_brightness(ambientColor,-50),
        },
        color:'white',
        backgroundColor: ambientColor,
        '&:hover': {
          backgroundColor: increase_brightness(ambientColor,-10),
          borderColor: increase_brightness(ambientColor,-20),
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
          backgroundColor: increase_brightness(ambientColor,10),
          borderColor: increase_brightness(ambientColor,-10),
        },
    });

    const [showAmbientColor, setShowAmbientColor] = useState(false)
    const [showFontColor, setShowFontColor] = useState(false)
    const [newSidebar, setNewSidebar] = useState(cookies.get('newSidebar')==='true' ? true : false)
    const [color, setColor] = useColor("hex", ambientColor);
    const [fontColor, setFontColor] = useColor("hex", fontColorLocal);
    const changeSidebar = () => {
        if(cookies.get('newSidebar')==='true'){
            cookies.set('newSidebar',false)
            setNewSidebar(false);
        }
        else{
            cookies.set('newSidebar',true);
            setNewSidebar(true);
        }
        window.location.reload();
    }
    return (  
        <>
            {/* <div style={{display:"flex",height:"8%",alignItems:'center'}}>
                <div style={{width:"95%",display:'flex',justifyContent:'center',paddingLeft:"2%",fontSize:"15px",fontWeight:"500",userSelect:'none',height:'10%'}}>Custom Page Color</div>
            </div>           */}
            <div className='getLogsContainer'  onDrag={updateComponent}>
                
                {/* <div style={{display:'flex',alignItems:'center'}}>
                    <div style={{paddingTop:"2%",paddingLeft:'2%',display:'flex',alignItems:'center',fontSize:'13px'}}>
                        New Sidebar
                    </div>
                    <div style={{transform:'scale(0.7)',paddingTop:"2%",paddingLeft:'2%'}}><Switch checked={newSidebar} onChange={(e)=>{changeSidebar(e)}}/></div>
                </div> */}

                {/* <div style={{display:'flex',alignItems:'center'}}>
                    <div style={{paddingTop:"2%",paddingLeft:'2%',display:'flex',alignItems:'center',fontSize:'13px'}}>
                        New Sidebar Hover Menu
                    </div>
                    <div style={{transform:'scale(0.7)',paddingTop:"2%",paddingLeft:'2%'}}><Switch checked={localStorage.getItem("hoverSidebarItem") ? true : false} onChange={(e)=>{if(e.target.checked){localStorage.setItem('hoverSidebarItem',true)}else{localStorage.removeItem('hoverSidebarItem')};updateComponent(e)}}/></div>
                </div> */}

                <div style={{display:'flex',alignItems:'center'}}>
                    <div style={{paddingTop:"2%",paddingLeft:'2%',display:'flex',alignItems:'center',fontSize:'13px'}}>
                        Ambient Color
                    </div>
                    <div style={{height:'20px',marginLeft:'2%',marginTop:'20px',width:'50px',background:ambientColor}} onClick={()=>{setShowAmbientColor(true)}}>

                    </div>
                </div>
                {/* <div style={{display:'flex',alignItems:'center'}}>
                    <div style={{paddingTop:"2%",paddingLeft:'2%',display:'flex',alignItems:'center',fontSize:'13px'}}>
                        Font Color
                    </div>
                    <div  style={{height:'20px',marginLeft:'2%',marginTop:'20px',width:'50px',background:fontColor}} onClick={()=>{setShowFontColor(true)}}>

                    </div>
                </div> */}
                <div style={{transform:'scale(0.7)',marginTop:'10%',marginLeft:'10%'}}>
                    <BootstrapButton onClick={()=>{localStorage.setItem('ambientColor', "rgb(0, 166, 178)");window.location.reload();}}> Reset to Default</BootstrapButton>
                </div>

                {
                    showAmbientColor && 
                        <div onMouseLeave={()=>{setShowAmbientColor(false)}} onBlur={()=>{setShowAmbientColor(false)}}>
                            <ColorPicker width={456} height={228} 
                            color={color} 
                            onChange={(e)=>{setColor(e);localStorage.setItem('ambientColor',e.hex)}}
                            hideHSV dark 
                            />
                        </div>
                }
                {
                    showFontColor && 
                        <div onMouseLeave={()=>{setShowFontColor(false)}} onBlur={()=>{setShowFontColor(false)}}>

                            <ColorPicker width={456} height={228} 
                                color={fontColor} 
                                onChange={(e)=>{setFontColor(e);localStorage.setItem('fontColor',e.hex)}} hideHSV dark 
                                />
                        </div>

                }
            </div>
        </>  
    )
}

export default CustomizationPage;
