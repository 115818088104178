import React from 'react'
import axios from 'axios';
import * as Constants from '../../data/constants';

import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import {capitalize, getLocalStorageValue} from '../../util/util';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';

export const GroupsComponent = () => {

    const [manualUseState, setManualUseState] = React.useState(false);
    const [showLoader, setShowLoader] = React.useState(true);

    const [showResponsePopup, setShowResponsePopup] = React.useState(false);
    const [responsePopup, setResponsePopup] = React.useState(false);
  
    const [showEditPopup, setShowEditPopup] = React.useState(false);
    
    const [groupsData, setGroupsData] = React.useState(false);
    const [selectedIndex, setSelectedEditIndex] = React.useState(false);

    const [permission_list, setPermission_list] = React.useState([]);
    const [selectedPermission, setSelectedPermission] = React.useState([]);
    const [selectedPermissionCheck, setSelectedPermissionCheck] = React.useState([]);
    const [sudoUser, setSudoUser] = React.useState(false);
    const [groupName, setGroupName] = React.useState(false);
    
    const getPermission = async() => {
        await axios({
          method: 'GET',
          url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"permissions"),
          headers:{
              Authorization:getLocalStorageValue('adminPanelLoginData').token
          },
        }
        )
        .then((resp) => {
            var data = resp.data.result
            var permission_list = []
            var permission_list_value = []
            for (var item in data){
              if(!permission_list.includes(capitalize(item.split(" add ")[1] || item.split(" change ")[1] || item.split(" delete ")[1] || item.split(" view ")[1])))
              permission_list.push(capitalize(item.split(" add ")[1] || item.split(" change ")[1] || item.split(" delete ")[1] || item.split(" view ")[1]))
              permission_list_value.push(data[item])
            }
            setPermission_list(permission_list)
        })
        .catch(err => {
            console.error(err);
        })    
      };
    
    const getGroups = async() => {
        setShowLoader(true)
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"groups"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
            }
        })
        .then(resp => {
            setShowLoader(false);
            setGroupsData(resp.data.result)
        })
        .catch(err => {
            setShowResponsePopup(true)
            setResponsePopup(err.response.data.result)
            console.error(err);
        })
    };

    const addeditGroup = async() => {
        var url_part = "group/add"
        if(selectedIndex || selectedIndex===0){
            url_part = "group/change"
        }

        await axios({
          method: 'POST',
          url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,url_part),
          headers:{
              Authorization:getLocalStorageValue('adminPanelLoginData').token,
              "Content-Type":"application/json",
          },
          data:{
                "group_name": groupName,
                "permissions": selectedPermission,
                "group_id" : (selectedIndex || selectedIndex===0 ? groupsData[selectedIndex].id : "")
          }
          })
        .then((resp)=>{
          if(resp.data.result)
            { 
              setResponsePopup(resp.data.result);
              getGroups();
            }

              
          setShowResponsePopup(true)
          }
        )
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            setResponsePopup(error.response.data.result);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else {
            // Something happened in setting up the request that triggered an Error
            setResponsePopup(JSON.stringify({error:error.message}));
            console.log('Error', error.message);
          }
          setShowResponsePopup(true)
    
        });
      }
    
    if (!manualUseState){
        setManualUseState(true);
        getGroups();
        getPermission();
    }

    const showGroups = () => {
        return groupsData.map((item,index)=>{
            var permissions_list = []
            for (var permission_key in item.permissions){
                permissions_list.push(item.permissions[permission_key])
            }
            return <div key={index} style={{display:"flex",width:"96%",paddingLeft:"4%",paddingTop:"5px",fontSize:"12px"}}>
                        <div style={{width:"10%"}}>
                            {item.id}
                        </div>
                        <div style={{width:"70%"}}>
                            {item.name}
                        </div>
                        <div style={{color:"orange",fontWeight:"500",cursor:"pointer"}} onClick={()=>{setShowEditPopup(true);setSelectedEditIndex(index);setSelectedPermission(permissions_list);setGroupName(groupsData[index].name)}}>
                            Edit
                        </div>
                    </div>
        })
    }

    const remove_element = (list,permission) => {
        return list.filter(e => e!==permission)
      }

    const allRadioButtonChecked = (e,item) =>{
    var tempList = selectedPermission
    if(!tempList){
        tempList = []
    }
    if(tempList.includes("add_"+item) && tempList.includes("change_"+item) && tempList.includes("delete_"+item) && tempList.includes("view_"+item)){
        setSudoUser(false)
        tempList = remove_element(tempList,"add_"+item)
        tempList = remove_element(tempList,"change_"+item)
        tempList = remove_element(tempList,"delete_"+item)
        tempList = remove_element(tempList,"view_"+item)
    }
    else{
        tempList.push("add_"+item)
        tempList.push("change_"+item)
        tempList.push("delete_"+item)
        tempList.push("view_"+item)
    }
    setSelectedPermission(tempList)
    setSelectedPermissionCheck(selectedPermissionCheck+item)
    }

    const addPermissionChecked = (e,item) =>{
    var tempList = selectedPermission
    var viewitem = "view_"+item
    item = "add_"+item
    if(!tempList){
        tempList = []
    }
    if(tempList.includes(item)){
        setSudoUser(false)
        tempList = remove_element(tempList,item)
    }
    else{
        tempList.push(item)
        if (!tempList.includes(viewitem))
        {tempList.push(viewitem)}
    }
    setSelectedPermission(tempList)
    setSelectedPermissionCheck(selectedPermissionCheck+item)
    }

    const changePermissionChecked = (e,item) =>{
    var tempList = selectedPermission
    var viewitem = "view_"+item
    item = "change_"+item
    if(!tempList){
        tempList = []
    }
    if(tempList.includes(item)){
        setSudoUser(false)
        tempList = remove_element(tempList,item)
    }
    else{
        tempList.push(item)
        if (!tempList.includes(viewitem))
        {tempList.push(viewitem)}
        }
    setSelectedPermission(tempList)
    setSelectedPermissionCheck(selectedPermissionCheck+item)
    }

    // const deletePermissionChecked = (e,item) =>{
    // var tempList = selectedPermission
    // var viewitem = "view_"+item
    // item = "delete_"+item
    // if(!tempList){
    //     tempList = []
    // }
    // if(tempList.includes(item)){
    //     setSudoUser(false)
    //     tempList = remove_element(tempList,item)
    // }
    // else{
    //     tempList.push(item)
    //     if (!tempList.includes(viewitem))
    //     {tempList.push(viewitem)}
    //     }
    // setSelectedPermission(tempList)
    // setSelectedPermissionCheck(selectedPermissionCheck+item)
    // }

    const viewPermissionChecked = (e,item) =>{
    var tempList = selectedPermission
    var viewitem = "view_"+item
    if(!tempList){
        tempList = []
    }
    if(tempList.includes(viewitem)){
        setSudoUser(false)
        tempList = remove_element(tempList,viewitem)
        tempList = remove_element(tempList,"add_"+item)
        tempList = remove_element(tempList,"delete_"+item)
        tempList = remove_element(tempList,"change_"+item)
    }
    else{
        tempList.push(viewitem)
    }
    setSelectedPermission(tempList)
    setSelectedPermissionCheck(selectedPermissionCheck+viewitem)
    }
    
    const sudoUserChange = (e,sudouser) =>{
        setSudoUser(sudouser)

        if(sudouser){
        var tempList = []
        for (var index in permission_list){
            var item = permission_list[index].replace(" ","").replace(" ","").toLowerCase()
            tempList.push("add_"+item)
            tempList.push("change_"+item)
            tempList.push("delete_"+item)
            tempList.push("view_"+item)
            setSelectedPermissionCheck(selectedPermissionCheck+item)
        }
        setSelectedPermission(tempList)
        }
        else{
        setSelectedPermissionCheck("")
        setSelectedPermission([])
        }
    }

    const editAddPopup = () => {
        return (
            <div className="popup-box">
              <div className="box">
                  <div className="popupHeader">
                        <div style={{display:"flex",alignItems:"center"}}>
                            <div style={{width:"85%"}}>{selectedIndex || selectedIndex===0? "EDIT GROUP : " + groupsData[selectedIndex].name : "Add Group"}</div>
                            
                            <Button style={{transform:"scale(0.9)"}} onClick={addeditGroup} color="warning" variant="contained">{selectedIndex || selectedIndex===0 ? "EDIT" : "ADD"}</Button>
                        </div> 
                        <div style={{borderBottom:"1px solid rgb(150,150,150)",paddingTop:"1%",width:"40%"}}></div>
                  </div>
                  <div className="popupData" style={{height:"80%"}}>
                    <div style={{display:"flex",paddingLeft:"5%",paddingBottom:"2%",alignItems:"center"}}> 
                        <div style={{width:"50%"}}>
                            Group Name
                        </div>
                            <TextField fullWidth size='small' value={groupName} style={{transform:"scale(0.8)"}} onChange={(e)=>{setGroupName(e.target.value)}}></TextField>
                    </div>      
                    <div>
                        <div style={{display:"flex",height:"5vh",paddingLeft:"5%",paddingRight:"5%",fontSize:"15px",fontWeight:"600"}}>
                            <div style={{display:"flex",width:"50%"}}>Permissions</div>
                            <div style={{display:"flex",width:"10%",justifyContent:"center"}}>Allow All</div>
                            <div style={{display:"flex",width:"10%",justifyContent:"center"}}>View</div>
                            <div style={{display:"flex",width:"10%",justifyContent:"center"}}>Add</div>
                            <div style={{display:"flex",width:"10%",justifyContent:"center"}}>Edit</div>
                            {/* <div style={{display:"flex",width:"10%",justifyContent:"center"}}>Delete</div> */}
                        </div>
                        <div style={{display:"block",height:"100%",paddingLeft:"5%",paddingRight:"5%",overflowY:"auto",fontSize:"13px"}}>
                            <div style={{display:"flex",paddingTop:"2px"}}>
                            <div style={{display:"flex",width:"50%"}}>Sudo User</div>
                            <div style={{display:"flex",width:"10%",justifyContent:"center"}}>
                                <Switch checked={sudoUser} color="secondary" onChange={(e)=>{sudoUserChange(e,!sudoUser)}} size="small" />
                            </div>
                            </div>
                            {permission_list.map((item,index)=>{
                            var item_without_underscore = item
                            item = item.replace(" ","").replace(" ","").toLowerCase()
                            return <div key={index} style={{display:"flex",paddingTop:"5px"}}>
                                <div style={{display:"flex",width:"50%"}}>{item_without_underscore}</div>
                                <div style={{display:"flex",width:"10%",justifyContent:"center"}}>
                                <Switch checked={selectedPermission.includes("add_"+item) && selectedPermission.includes("change_"+item) && selectedPermission.includes("delete_"+item) && selectedPermission.includes("view_"+item)} color="warning" onChange={(e)=>{allRadioButtonChecked(e,item)}} size="small" />
                                </div>
                                <div style={{display:"flex",width:"10%",justifyContent:"center"}}>
                                <Switch checked={selectedPermission.includes("view_"+item)} onChange={(e)=>{viewPermissionChecked(e,item)}} size="small" />
                                </div>
                                <div style={{display:"flex",width:"10%",justifyContent:"center"}}>
                                <Switch checked={selectedPermission.includes("add_"+item)} onChange={(e)=>{addPermissionChecked(e,item)}}  size="small" />
                                </div>
                                <div style={{display:"flex",width:"10%",justifyContent:"center"}}>
                                <Switch checked={selectedPermission.includes("change_"+item)} onChange={(e)=>{changePermissionChecked(e,item)}} size="small" />
                                </div>
                                {/* <div style={{display:"flex",width:"10%",justifyContent:"center"}}>
                                <Switch checked={selectedPermission.includes("delete_"+item)} onChange={(e)=>{deletePermissionChecked(e,item)}}  size="small" />
                                </div> */}
                            </div>
                        })
                        }
                        </div>
                    </div>
                  </div>
                <div className="close-icon" onClick={()=>{setShowEditPopup(false)}}>X</div>
              </div>
            </div>
          );
    }

    return (
            <div style={{padding:"4%"}}>
                <div style={{fontSize:"15px",fontWeight:"600",padding:"15px",display:"flex",alignItems:"center"}}>
                    <div style={{width:"90%"}}>Groups</div>
                    <Button style={{transform:"scale(0.8)"}} size="small" onClick={()=>{setShowEditPopup(true);setSelectedEditIndex(false);setSelectedPermission([]);setGroupName("")}} color="warning" variant='outlined'>Add</Button>
                </div>
                <div style={{borderBottom:"1px solid rgb(150,150,150)"}}></div>

                <div style={{display:"flex",width:"96%",padding:"4%",paddingTop:"1%",paddingBottom:"1%",fontSize:"14px"}}>
                    <div style={{width:"10%"}}>
                        Id
                    </div>
                    <div style={{width:"70%"}}>
                        Group Name
                    </div>
                </div>
                {showEditPopup && editAddPopup()}
                {!showLoader && groupsData && showGroups()}
                {showLoader && <div style={{display:'flex',justifyContent:"center",alignItems:"center",minHeight:"100px"}}>  
                                    <div style={{transform:"scale(0.5)"}}>
                                        <CircularProgress color="warning"/> 
                                    </div>
                                </div>}
                
                {showResponsePopup && 
                        <div className="popup-box">
                            <div className="box-response">
                            <div className="popupHeader" >{"Result"}</div>
                            <div style={{borderBottom:"2px solid orange"}}></div>
                                <div className="popupData">
                                {responsePopup}
                                </div>
                                {
                                    <div className="close-icon-response" onClick={()=>{setShowResponsePopup(false)}}>X</div>
                                }
                            </div>
                        </div>
                    }
            </div>
            
    )
}

export default GroupsComponent;
