import React from 'react'
import { useForceUpdate } from '../../util/util';
import Pagination from '@mui/material/Pagination';

const PaginationComponent = (props) => {
    const refreshComponent = useForceUpdate()
    if(props.limitList){
        var limitList = props.limitList
    }
    else{
        limitList = [25,50,75,100]
    }
    return (
        <div style={{display:(Math.ceil(props.totalResults/props.limit)>1 ? 'flex' : 'none'),flexWrap:'wrap',alignItems:'center',justifyContent:"space-between",paddingLeft:'20px',paddingRight:'20px',paddingBottom:'20px',position:'sticky',bottom:0,background:'white',zIndex:100}}>
            <div>
                <label style={{paddingRight:"5px",fontSize:'14px'}}>Limit</label>
                <select value={props.limit} style={{outline:'none',border:'none'}} onChange={(e)=>{if(props.onClick){props.onClick()};props.apiRequest(e.target.value,1);props.setLimit(parseInt(e.target.value));props.setPage(1);}}>
                    {limitList.map((item,index)=>{
                        return <option key={index} value={item}>{item}</option>
                    })}
                </select>
            </div>
            <Pagination count={Math.ceil(props.totalResults/props.limit)} page={props.page} onChange={(event,value)=>{if(props.onClick){props.onClick()};props.apiRequest(props.limit,value);props.setPage(value);refreshComponent(event);window.scrollTo({top: 0,left: 0,behavior: 'smooth'});}} />
        </div>
    )
}

export default PaginationComponent