import React from 'react';
import { Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
export default function PageNotFound(){
    return (
        <div style={{display:"flex",height:"60vh",justifyContent:"center",alignItems:"center"}}>
            <div style={{display:'block'}}>
                <div style={{display:'flex',fontSize:'50px',fontWeight:'500'}}>OOPS!</div>
                <div>Page you are looking for is invalid or has been temporarily disabled.</div>
                <br/>
                <br/>
                <NavLink to={'/dashboard'} style={{textDecoration:'none'}}><Button variant='contained' color='warning'>Go To Homepage</Button></NavLink>
            </div>
        </div>
    )
}