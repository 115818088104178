import React, { useEffect, useState } from 'react'
import '../export/export.css';
import axios from 'axios';
import AdvertiserImportPng from '../../img/advertiserImportExample.png';
import AffiliateImportPng from '../../img/affiliateImportExample.png';
import { Button, CircularProgress } from '@mui/material';
import * as Constants from '../../data/constants';
import { getLocalStorageValue } from "../../util/util";
import { Store } from 'react-notifications-component';

const ImportPopup = (props) => {
    const [loader, setLoader] = useState(false)
    const FileDownload = require('js-file-download');

    const importDataRequest = async(event) => {
        setLoader(true)
        if(event.target && event.target.files){
            var file = event.target.files[0]
            var formData = new FormData();
            formData.append('file',file)
            if(props.type==='AdvertiserView'){
                var responseTrue = "Advertiser"
                var url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'advertiser/import')
                var filename = 'advertiser_import_response.csv'
            }
            else if(props.type==='AffiliateView'){
                responseTrue = "Affiliate"
                url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'affiliate/import')
                filename = 'affiliate_import_response.csv'
            }
            await axios({
                method: "POST",
                url,
                headers:{
                    "Content-Type": "multipart/form-data",
                    Authorization:getLocalStorageValue('adminPanelLoginData').token
                },
                data: formData,
            })
            .then(resp =>{
                props.setImportPopup(false)
                Store.addNotification({
                    title: "Success!",
                    message: responseTrue+" import done, please check downloaded csv file.",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true
                    }
                });
                FileDownload(resp.data, filename)
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    Store.addNotification({
                        title: "Error!",
                        message: error.response.data.result,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 2000,
                          onScreen: true
                        }
                      });
                } 
                else if (error.request) {
                    console.log(error.request);
                } 
                else {
                    console.log('Error', error.message);
                }
            })
            event.target.value = null;
            setLoader(false)
        }
    }

    const handleDownload = () => {
        if(props.type==='AdvertiserView'){
            var url = './advertiser_import.csv'
            var filename = 'sample_advertiser_import.csv'
        }
        else{
            url = '/affiliate_import.csv'
            filename = 'sample_affiliate_import.csv'
        }
        axios.get(url, {
            responseType: 'blob',
            })
            .then((res) => {
                FileDownload(res.data, filename)
            }
        )
    }

    useEffect(() => {
        document.addEventListener('keydown',(e)=>{
            if(e.key==='Escape'){props.setImportPopup(false);}
        })
        
        return () => {
            document.removeEventListener('keydown',(e)=>{
                if(e.key==='Escape'){props.setImportPopup(false);}
            })
        }
    }, [props])
    
    return (
        <div className='exportCSVPopupContainer' onClick={()=>{props.setImportPopup(false)}}>
            <div className='exportCSVPopupWrapper' onClick={(e)=>{e.stopPropagation()}} style={{justifyContent:'flex-start',borderRadius:'10px',minHeight:'70vh',alignItems:'flex-start',flexDirection:'column'}}>
                <div style={{padding:'20px',width:'calc(100% - 40px)'}}>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <label style={{fontSize:'18px',fontWeight:500}}>Import Data</label>
                        <div style={{cursor:'pointer'}} onClick={()=>{props.setImportPopup(false)}}>X</div>
                    </div>
                    <div style={{marginTop:'15px',fontSize:'14px',maxHeight:'60vh',overflow:'auto'}}>
                        Please upload a CSV file, where format of columns is according to given below. 
                        <div style={{marginTop:'10px',marginBottom:'10px'}}>
                            <img src={(props.type==='AdvertiserView' ? AdvertiserImportPng : AffiliateImportPng)} width={'100%'} alt=''/>
                        </div>

                        {"Also, you need to enter the headers in csv file (first row in above example)."}
                        <div style={{display:'flex',alignItems:'center',marginTop:'10px'}}>
                            Here, a <div style={{color:localStorage.getItem('ambientColor'),paddingTop:'0.007em',paddingLeft:'5px',paddingRight:'5px',cursor:'pointer'}} onClick={()=>{handleDownload('ete')}}>sample</div>csv file, you can use.
                        </div>
                        <div>
                            <Button    
                                component="label"
                                sx={{...Constants.CONTAINEDBUTTON,fontSize:'14px',height:'35px',width:'150px',paddingLeft:'15px',borderRadius:'0px',paddingRight:'15px',marginTop:'20px',textTransform:'none',fontWeight:500}} 
                                style={{minWidth:'15%',fontSize:'13px',marginTop:'30px'}}
                            >
                                {!loader && "Import"}
                                {loader && <CircularProgress sx={{color:'white'}} size={18}/>}
                                <input type="file" accept=".csv" name="importfile" hidden onChange={(e)=>{importDataRequest(e)}}/>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImportPopup