import React from 'react';
import '../../css/pages/viewPage.css';
import axios from 'axios';
import { NavLink } from "react-router-dom";
import parse from 'html-react-parser';

import * as Constants from '../../data/constants';

import Button from '@mui/material/Button';
import {getLocalStorageValue} from '../../util/util';

export const ViewAdvertiserPage = () => {

    const advertiserId = window.location.href.split("/")[window.location.href.split("/").length-1];
    const [manualUseState, setManualUseState] = React.useState(false);

    const [companyName, setCompanyName] = React.useState(null);
    const [contactPerson, setContactPerson] = React.useState(null);
    const [managerID, setManagerID] = React.useState(null);
    const [skype, setSkype] = React.useState(null);
    const [email, setEmail] = React.useState(null);
    const [address, setAddress] = React.useState(null);
    const [site, setSite] = React.useState(null);
    const [note, setNote] = React.useState(null);
    const [contactNumber, setContactNumber] = React.useState(null);
    
    const [showPopup, setShowPopup] = React.useState(false);
    
    const showAdvertiserRequest = async() => {
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.ADVERTISERS),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
              id:advertiserId,
            }
        }
        )
        .then((resp) => {
            var responseResult = resp.data.result
            
            for (var item in responseResult){
            if (responseResult[item].id.toString() === advertiserId){
                setCompanyName(responseResult[item].company)
                setContactPerson(responseResult[item].contact_person)
                setManagerID(responseResult[item].manager_name)
                setSkype(responseResult[item].skype_id)
                setEmail(responseResult[item].email)
                setAddress(responseResult[item].address)
                setSite(responseResult[item].website)
                setNote(responseResult[item].note)
                setContactNumber(responseResult[item].contact_number)
                
            }}
        })
        .catch(err => {
            console.error(err);
            if(err.response){
                if(err.response.data && err.response.data.result){
                    setShowPopup(err.response.data.result)
                }
            }
        })
    };

    if (!manualUseState){
        setManualUseState(true);
        showAdvertiserRequest();
    }

    const showAddress = () => {
        var addressString = ""
        if (address.address1){
            addressString += address.address1 + " , <br/>"
        }
        if (address.address2){
            addressString += address.address2 + " , <br/>"
        }
        if (address.city){
            addressString += address.city + " , <br/>"
        }
        if (address.country){
            addressString += address.country + " , <br/>"
        }
        if (address.zipcode){
            addressString += address.zipcode + " ,<br/>"
        }
        return addressString.substring(0,addressString.length-7)
    }
    return(

        <div className="row">
            <div className="columnData" style={{width:"95%",background:"rgb(245, 245, 245)",height:"auto"}}>
                {showPopup && 
                    <div className="popup-box">
                        <div className="box-response">
                        <div className="popupHeader" >{"Error"}</div>
                        <div style={{borderBottom:"2px solid orange"}}></div>
                            <div className="popupData">
                            {showPopup}
                            </div>
                            <NavLink style={{textDecoration:"none",color:"black"}} to={"/advertiser"}>
                                <div className="close-icon-response" onClick={()=>{setShowPopup(false)}}>X</div>
                            </NavLink>
                        </div>
                    </div>
                }
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <div style={{marginTop:"2%",marginLeft:"5%",fontSize:"18px",fontWeight:"700",textAlign:"left"}}>View Advertiser : {companyName }</div>
                    <div style={{marginTop:"2%",marginRight:"5%",fontSize:"18px",fontWeight:"700",textAlign:"right"}}>
                        <NavLink to={"/statistics/daily?advertiser="+companyName+" - "+advertiserId} target="_blank" style={{textDecoration:"none"}}>
                            <Button variant='outlined' color="warning">View Stats</Button>
                        </NavLink>
                    </div>
                    
                </div>
                <div className="container">
                    <table width={"100%"}>
                        <tbody>
                            <tr>
                                <td className='leftSideColumn'>
                                    ID
                                </td>
                                <td className='rightSideColumn'>
                                    {advertiserId ? advertiserId : "Not available"}
                                </td>
                            </tr>
                            <tr>
                                <td className='leftSideColumn'>
                                    Company Name
                                </td>
                                <td className='rightSideColumn'>
                                    {companyName ? companyName : "Not available"}
                                </td>
                            </tr>
                            <tr>
                                <td className='leftSideColumn'>
                                    Contact Person
                                </td>
                                <td className='rightSideColumn'>
                                    {contactPerson ? contactPerson : "Not available"}
                                </td>
                            </tr>
                            <tr>
                                <td className='leftSideColumn'>
                                    Contact Person Number
                                </td>
                                <td className='rightSideColumn'>
                                    {contactNumber ? contactNumber : "Not available"}
                                </td>
                            </tr>
                            <tr>
                                <td className='leftSideColumn'>
                                    Manager
                                </td>
                                <td className='rightSideColumn'>
                                    {managerID ? managerID : "Not available"}
                                </td>
                            </tr>
                            <tr>
                                <td className='leftSideColumn'>
                                    Email
                                </td>
                                <td className='rightSideColumn'>
                                    {email ? email : "Not available"}
                                </td>
                            </tr>
                            <tr>
                                <td className='leftSideColumn'>
                                    Skype Id
                                </td>
                                <td className='rightSideColumn'>
                                    {skype ? skype : "Not available"}
                                </td>
                            </tr>
                            <tr>
                                <td className='leftSideColumn'>
                                    Address
                                </td>
                                <td className='rightSideColumn'>
                                    { address && parse(showAddress())
                                    }
                                </td>
                            </tr>

                            <tr>
                                <td className='leftSideColumn'>
                                    Website
                                </td>
                                <td className='rightSideColumn'>
                                    {site ? site : "Not available"}
                                </td>
                            </tr>

                            <tr>
                                <td className='leftSideColumn'>
                                    Note
                                </td>
                                <td className='rightSideColumn'>
                                    {note ? note : "Not available"}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {/* <div className="leftSideDiv">
                        <label className='leftSideLabel'>ID</label>
                        <label className='leftSideLabel'>Company Name</label>
                        <label className='leftSideLabel'>Contact Person</label>
                        <label className='leftSideLabel'>Email</label>
                        <label className='leftSideLabel'>Skype Id</label>
                    </div>
                    <div className="rightSideDiv">
                        
                        <label className='rightSideLabel'>{advertiserId ? advertiserId : ""}</label>
                        <label className='rightSideLabel' >{companyName ? companyName : ""}</label>
                        <label className='rightSideLabel' >{contactPerson ? contactPerson : ""}</label>
                        <label className='rightSideLabel' >{email ? email : 'Not available'}</label>
                        <label className='rightSideLabel' >{skype ? skype : "Not available"}</label>
                    </div> */}
                </div>
                <div style={{marginLeft:"10%",marginTop:"5%",marginBottom:"2%"}}>
                    <NavLink style={{textDecoration:"none",color:"black"}} to={"/advertiser/edit/"+advertiserId}><Button variant="outlined" color="warning">EDIT</Button></NavLink>
                    <NavLink style={{marginLeft:"2%",textDecoration:"none",color:"black"}} to={"/advertiser"}><Button variant="text">Cancel</Button></NavLink>
                </div>
            </div>
        </div>
    )
    };

export default ViewAdvertiserPage;