import React from 'react';
import '../css/sidebar.css'
import {sidebarTabs} from '../data/sidebarTabs';
import { SubMenu } from './submenu';

const Sidebar = (props) => {
    var sidebarOpen = props.sidebarOpen

    var [mouseHoverSidebar,setMouseHoverSidebar] = React.useState(false);
    
    return (
        <div className="sidebarContainer" style={{width:(sidebarOpen || mouseHoverSidebar ? "15vw" : "5vw"),transition: "all 0.7s ease-in-out"}}
            onMouseEnter={()=>{setMouseHoverSidebar(true)}}
            onMouseLeave={()=>{setMouseHoverSidebar(false)}}
        >
            {sidebarTabs.map((item,index)=>{
                if (localStorage.getItem('allowedPages').includes(item.link)){
                    return <SubMenu item={item} props={props} mouseHoverSidebar={mouseHoverSidebar} key={index}></SubMenu>
                }
                else if(localStorage.getItem('allowedPages')==="all"){
                    return <SubMenu item={item} props={props} mouseHoverSidebar={mouseHoverSidebar} key={index}></SubMenu>
                }
                else{
                    return null;
                }
            })}
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    )
}

export default Sidebar;
