import axios from 'axios';
import React, { useEffect } from 'react';
import * as Constants from './data/constants';
import AppAnalyticsLogo from './img/companies/appanalytics.png'
import MaintainanceGif from './img/maintainanceGif.gif'
import { getLocalStorageValue } from './util/util';
const ServerMaintainance = () => {
    const getOfferRequestCounts = async() => {
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"offerapprovalrequests/count"),
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token
                },
        })
        .then(resp => {
            window.history.back();
        })
        .catch(err => {
            // props.setRequest3Done(true)
            console.error(err);
        })  
    };
    useEffect(() => {
        setInterval(() => {
            getOfferRequestCounts()
        }, 10000);
        
        return () => {
            
        }
    }, [])
    
    return (
        <div>
            <div style={{display:"flex",justifyContent:"center", alignItems: "center",paddingTop: "20px"}}>
                <img alt='' src={AppAnalyticsLogo} width={'200px'}/>
            </div>
            <div style={{display:"flex",justifyContent:"center", alignItems: "center",paddingTop: "20px"}}>
                <img alt='' src={MaintainanceGif} width="40%"/>
            </div>
            <div style={{display:"flex",justifyContent:"center", alignItems: "center",fontWeight: 900,letterSpacing: "1px",fontFamily: "Anton", fontSize: "36px",textAlign: "center"}}>
                The site is currently down for maintenance
            </div>
            <div style={{display:"flex",justifyContent:"center", alignItems: "center",fontWeight: 400,fontFamily: "Arial, Helvetica, sans-serif", fontSize: "14px",textAlign: "center"}}>
                We apologize for any inconveniences caused.<br/>
                We've almost done.
            </div>
        </div>
    )
}

export default ServerMaintainance