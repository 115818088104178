import React, { useEffect } from 'react';
import Login from '../pages/login';
import Router from './router';
import Cookies from 'universal-cookie';
import ParticleComponent from './particles/particles';
import { getLocalStorageValue } from '../util/util';
import axios from 'axios';

function getToken() {
    const tokenString = getLocalStorageValue('adminPanelLoginData');
    return tokenString?.token
}

export default function CheckLogin(){
    const cookies = new Cookies();
    const token = getToken();
    const lastLoginTime = parseInt(localStorage.getItem("adminloginTime")) || 0;
    const expireTime = 1000*60*60*12;
    var tempSidebarOpen = false;
    if(cookies.get('sidebarOpen')){
        if(cookies.get('sidebarOpen')==='false'){
            tempSidebarOpen = false
        }
    }
    const [sidebarOpen,setSidebarOpen] = React.useState(tempSidebarOpen);
    const [loginExpired,setLoginExpired] = React.useState(false);
    
    if(!((new Date().getTime() - lastLoginTime) > expireTime) && loginExpired){
        setLoginExpired(true)
    }

    const loginCheck = () => {
        if (!token || (new Date().getTime() - lastLoginTime) > expireTime){
            setLoginExpired(true)
        }
    }

    //creating IP state
    //creating function to load ip address from the API
    const getData = async()=>{
        await axios.get('https://geolocation-db.com/json/').then(resp => {
            // axios.defaults.headers.post['ip-address'] = resp.data.IPv4
        })
        
    }
    useEffect(() => {
        getData()
    
    }, [])
    

    if (loginExpired || !token || (new Date().getTime() - lastLoginTime) > expireTime){
        return <>
                <ParticleComponent/>
                <Login/>
                </>
    }
    else{
    return <div className="main" style={{backgroundColor:"rgb(240, 240, 240)"}} onClick={loginCheck}>
                <Router sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}/>
            </div>
    }
}