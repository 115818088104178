import React, { useState } from 'react';
import ReactSelect from 'react-select';
import { NavLink } from "react-router-dom";
import axios from 'axios';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

import { getAdvertisers, getAffiliates, getSmartlinks } from '../../../apis/dropdown/dropdowns';
import { increase_brightness } from '../../../components/customization/customization';
import TableModal from '../../../components/tableModal/tableModal';
import '../../../css/pages/v2/advertiser.css'; //CSS IS SAME as advertiser page
import '../../../css/pages/v3/offers.css';
import * as Constants from '../../../data/constants';
import { capitalize, getLocalStorageValue, getRequests, setLocalStorageValue, useForceUpdate, sortingV2 } from '../../../util/util';
import PaginationComponent from '../../../components/pagination/pagination';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import BookmarkRemoveOutlinedIcon from '@mui/icons-material/BookmarkRemoveOutlined';
import { Store } from 'react-notifications-component';
import ExportPopup from '../../../components/export/export';
import { getManager } from '../../../apis/dropdown/dropdowns';

const OfferPageViewV3 = () => {
    const refreshComponent = useForceUpdate()
    document.title = 'Offers'
    var tempLimit = 25
    var tempPage = 1
    var tempOfferId = ''
    var tempOfferTitle = ''
    var tempAdvertiser = ''
    var tempAdvertiserManager = ''
    var tempStatus = ''
    var tempType = ''
    var tempOfferPageType = ''
    var tempSmartlink = ''
    var tempOfferSource = ''

    const handleTabChange = (item) => {
        setOfferTabSelected(item);
        var url = window.location.href;
        if(url.includes("?")){
            url = url.split('?')[0]
        }
        setLimit(25);
        setPage(1);
        setFilterSearch({id:'',offer_title:'',advertiser:'',status:'',type:'',smartlink_id:'',offer_source:''});
        url+="?limit=50&page=1&offerPageType="+item
        window.history.pushState('Offers', 'Offers', url);
    }
    if(window.location.href.includes("?")){
        var newUrl = new URL(window.location.href)
        if(newUrl.searchParams.get('limit')){
            tempLimit = parseInt(newUrl.searchParams.get('limit'))
        }
        if(newUrl.searchParams.get('page')){
            tempPage = parseInt(newUrl.searchParams.get('page'))
        }
        if(newUrl.searchParams.get('id')){
            tempOfferId = newUrl.searchParams.get('id')
            tempOfferId = {label:tempOfferId.split(" - ")[0] + " - " + tempOfferId.split(" - ")[1],value:tempOfferId.split(" - ")[1]}
        }
        if(newUrl.searchParams.get('name')){
            tempOfferTitle = newUrl.searchParams.get('name')
        }
        if(newUrl.searchParams.get('advertiser')){
            tempAdvertiser = newUrl.searchParams.get('advertiser')
            var tempAdvertiserList = []
            tempAdvertiser = newUrl.searchParams.get('advertiser').split(",")
            for(var index in tempAdvertiser){
                var label = tempAdvertiser[index].split(" - ")[0] + (tempAdvertiser[index].split(" - ")[1]==='null' ? '' : " - " + tempAdvertiser[index].split(" - ")[1])
                tempAdvertiserList.push({label:label,value:tempAdvertiser[index].split(" - ")[1]==='null' ? null : tempAdvertiser[index].split(" - ")[1]})
            }
        }

        if(newUrl.searchParams.get('manager_id')){
            tempAdvertiserManager = newUrl.searchParams.get('manager_id')
            var tempAdvertiserManagerList = []
            tempAdvertiserManager = newUrl.searchParams.get('manager_id').split(",")
            for(index in tempAdvertiserManager){
                label = tempAdvertiserManager[index].split(" - ")[0] + (tempAdvertiserManager[index].split(" - ")[1]==='null' ? '' : " - " + tempAdvertiserManager[index].split(" - ")[1])
                tempAdvertiserManagerList.push({label:label,value:tempAdvertiserManager[index].split(" - ")[1]==='null' ? null : tempAdvertiserManager[index].split(" - ")[1]})
            }
        }
        if(newUrl.searchParams.get('smartlink_id')){
            tempSmartlink = newUrl.searchParams.get('smartlink_id')
            var tempSmartlinkList = []
            tempSmartlink = newUrl.searchParams.get('smartlink_id').split(",")
            for(index in tempSmartlink){
                label = tempSmartlink[index].split(" - ")[0] + (tempSmartlink[index].split(" - ")[1]==='null' ? '' : " - " + tempSmartlink[index].split(" - ")[1])
                tempSmartlinkList.push({label:label,value:tempSmartlink[index].split(" - ")[1]==='null' ? null : tempSmartlink[index].split(" - ")[1]})
            }
        }
        if(newUrl.searchParams.get('status')){
            tempStatus = newUrl.searchParams.get('status')
            label = tempStatus.split(" - ")[0]
            tempStatus = {label:label,value:tempStatus.split(" - ")[1]==='null' ? null : tempStatus.split(" - ")[1]}
        }
        if(newUrl.searchParams.get('type')){
            tempType = newUrl.searchParams.get('type')
            label = tempType.split(" - ")[0] + (tempType.split(" - ")[1]==='null' ? '' : " - " + tempType.split(" - ")[1])
            tempType = {label:label,value:tempType.split(" - ")[1]==='null' ? null : tempType.split(" - ")[1]}
        }
        if(newUrl.searchParams.get('offerPageType')){
            tempOfferPageType = newUrl.searchParams.get('offerPageType')
        }
        if(newUrl.searchParams.get('offerSource')){
            tempOfferSource = {label:newUrl.searchParams.get('offerSource').split('-')[0],value:newUrl.searchParams.get('offerSource').split('-')[1]}
        }
    }
    const [manualUseEffect, setManualUseEffect] = useState(false)
    const [filterSearch, setFilterSearch] = useState({
        id:tempOfferId ? tempOfferId : '',
        offer_title:tempOfferTitle,
        advertiser:tempAdvertiserList ? tempAdvertiserList.length>0 ? tempAdvertiserList : '' : '',
        status:tempStatus ? tempStatus : '',
        type:tempType ? tempType : '',
        offer_source:tempOfferSource ? tempOfferSource : '',
        smartlink_id:tempSmartlinkList ? tempSmartlinkList.length>0 ? tempSmartlinkList : '' : '',
        manager_id:tempAdvertiserManagerList ? tempAdvertiserManagerList.length>0 ? tempAdvertiserManagerList : '' : '',
    })
    const [advertiserOptions, setAdvertiserOptions] = useState([])
    const [advertiserManagerOptions, setAdvertiserManagerOptions] = useState([])
    const [affiliateOptions, setAffiliatesOptions] = useState([])
    const [smartlinkOptions, setSmartlinkOptions] = useState([])
    const [offerData, setOfferData] = useState({})
    const [advertisersTotalCount, setOfferTotalCount] = useState({})
    const [loader, setLoader] = useState(false)

    const [page, setPage] = useState(tempPage)
    const [limit, setLimit] = useState(tempLimit)

    var offerViewTabs = {
        'all_campaigns':'All Campaigns',
        // 'new_campaigns':'New Campaigns',
        'top_campaigns':'Top Campaigns',
        'trending_campaigns':'Trending',
        'recently_viewed':'Recently Viewed',
        'bookmarked':'Bookmarked',
    }
    const [offerTabSelected, setOfferTabSelected] = useState(tempOfferPageType ? tempOfferPageType : 'all_campaigns')
    
    var changeUrlParams = (obj) => {
        var str = [];
        for(var p in obj){
            if(obj[p]){
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        }
        var paramString = str.join("&");
        var url = window.location.pathname
        url+= '?'+paramString
        window.history.pushState('Offers', 'Offers', url);
    }
    const showOfferDataRequest = async(manualLimit,manualPage,tempPageType,tempfilterSearchData) => {
        /// FOR URL 
        var pageType = tempPageType ? tempPageType : offerTabSelected
        var filterSearchData = tempfilterSearchData ? tempfilterSearchData : filterSearch
        var urlParamAdvertiser = ''
        var paramAdvertiser = ''
        var urlParamAdvertiserManager = ''
        var paramAdvertiserManager = ''
        setOfferData({[pageType]:[]})
        setLimit(manualLimit ? manualLimit : limit)
        setPage(manualPage ? manualPage : page)
        if(filterSearchData.advertiser){
            for(var index in filterSearchData.advertiser){
                urlParamAdvertiser += filterSearchData.advertiser[index].label +' - '+filterSearchData.advertiser[index].value +","
                paramAdvertiser += filterSearchData.advertiser[index].value +","
            }
            urlParamAdvertiser = urlParamAdvertiser.slice(0,-1)
            paramAdvertiser = paramAdvertiser.slice(0,-1)
        }
        if(filterSearchData.manager_id){
            for(index in filterSearchData.manager_id){
                urlParamAdvertiserManager += filterSearchData.manager_id[index].label +' - '+filterSearchData.manager_id[index].value +","
                paramAdvertiserManager += filterSearchData.manager_id[index].value +","
            }
            urlParamAdvertiserManager = urlParamAdvertiserManager.slice(0,-1)
            paramAdvertiserManager = paramAdvertiserManager.slice(0,-1)
        }
        var urlParamSmartlink = ''
        var paramSmartlink = ''
        if(filterSearchData.smartlink_id){
            for(index in filterSearchData.smartlink_id){
                urlParamSmartlink += filterSearchData.smartlink_id[index].label +' - '+filterSearchData.smartlink_id[index].value +","
                paramSmartlink += filterSearchData.smartlink_id[index].value +","
            }
            urlParamSmartlink = urlParamSmartlink.slice(0,-1)
            paramSmartlink = paramSmartlink.slice(0,-1)
        }
        if(filterSearchData.offer_source){
            var offer_source = filterSearchData.offer_source.label+'-'+ filterSearchData.offer_source.value
        }
        var urlParams = {
            limit:manualLimit ? manualLimit : limit,
            page:manualPage ? manualPage : page,
            id : filterSearchData.id ? filterSearchData.id : null,  //Search
            name : filterSearchData.offer_title ? filterSearchData.offer_title : null,  //Search
            external_offer_id : filterSearchData.external_offer_id ? filterSearchData.external_offer_id : null,  //Search
            advertiser : urlParamAdvertiser ? urlParamAdvertiser : null,  //Search
            status : filterSearchData.status ? filterSearchData.status.label +' - '+filterSearchData.status.value : null,  //Search
            type : filterSearchData.type ? filterSearchData.type.label +' - '+filterSearchData.type.value : null,  //Search
            smartlink_id : urlParamSmartlink ? urlParamSmartlink : null,  //Search
            offerPageType : pageType,  //Search
            offerSource : offer_source ? offer_source : null,  //Search
            manager_id : urlParamAdvertiserManager ? urlParamAdvertiserManager : null,
        }
        changeUrlParams(urlParams)

        var params = {
            limit:manualLimit ? manualLimit : limit,
            page:manualPage ? manualPage : page,
            offer_id : filterSearchData.id ? filterSearchData.id : null,  //Search
            offer_title : filterSearchData.offer_title ? filterSearchData.offer_title : null,  //Search
            external_offer_id : filterSearchData.external_offer_id ? filterSearchData.external_offer_id : null,  //Search
            offer_status : filterSearchData.status ? filterSearchData.status.value : null,  //Search
            offer_type : filterSearchData.type ? filterSearchData.type.value : null,  //Search
            advertiser_id : paramAdvertiser ? paramAdvertiser : null,  //Search
            manager_id : paramAdvertiserManager ? paramAdvertiserManager : null,
            smartlink_id : paramSmartlink ? paramSmartlink : null,  //Search
            offer_source : filterSearch.offer_source ? filterSearch.offer_source.value : null,  //Search
            columns_required: 'id,title,advertiser,manager_name,offer_status,offer_type,affiliates,categories,os,external_offer_id,geo,caps,payouts,tracking_url,preview_url,impression_link,kpi,description,logo,stop_at,offer_source,offer_expiry'
        }
        setLoader(true)
        
        if(pageType ==='recently_viewed'){
            var resp = []
            if(getLocalStorageValue('recentlyViewedOffers')){
                resp = getLocalStorageValue('recentlyViewedOffers')
            }
            setOfferData({...offerData,[pageType]:resp})
            setOfferTotalCount({...advertisersTotalCount,[pageType]:resp.length})
            showOfferCapsRequest(resp,pageType)
            setLoader(false)
        }
        else{
            if(pageType==='bookmarked'){
                var url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'bookmark/view')
            }
            else if(pageType==='top_campaigns'){
                url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'offers/payout/top')
            }
            else if(pageType==='trending_campaigns'){
                url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'offers/trending')
            }
            else{
                url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.GETOFFERS)
            }
            await axios({
                method: 'GET',
                url,
                headers:{
                    Authorization:getLocalStorageValue('adminPanelLoginData').token
                },
                params
            }
            )
            .then((resp) => {
                setOfferData({...offerData,[pageType]:resp.data.result})
                setOfferTotalCount({...advertisersTotalCount,[pageType]:resp.data.count})                
                showOfferCapsRequest(resp.data.result,pageType)
                setLoader(false)
            })
            .catch(function (error) {
                setLoader(false)
                setOfferTotalCount({...advertisersTotalCount,[pageType]:0})                
                if(error.response && error.response.data){
                    setOfferData({[pageType]:"errorFromAxios"+error.response.data.result})
                }
                else{
                    setOfferData({[pageType]:"errorFromAxiosServer Error. Contact your account manager regarding this."})
                }
            });
        }
    };
    const updateRecentlyViewedOffers = (offer_data) => {
        var recentlyViewedOffers = []
        if(getLocalStorageValue('recentlyViewedOffers')){
            recentlyViewedOffers = getLocalStorageValue('recentlyViewedOffers')
        }
        var foundCheck = false
        var foundIndex = null
        if(recentlyViewedOffers.length>0){
            for(var index in recentlyViewedOffers){
                if(recentlyViewedOffers[index].id === offer_data.id){
                    foundCheck= true
                    foundIndex = index
                }
            }
        }
        if(foundCheck){
            if(foundIndex===0 || foundIndex){
                recentlyViewedOffers.splice(foundIndex,1)
                recentlyViewedOffers.unshift(offer_data)
            }
        }
        if(!foundCheck){
            if(recentlyViewedOffers.length>20){
                recentlyViewedOffers.splice(recentlyViewedOffers.length-1,1)
            }
            recentlyViewedOffers.unshift(offer_data)
        }
        setLocalStorageValue('recentlyViewedOffers',recentlyViewedOffers)
    }

    const showOfferCapsRequest = async(tempOfferData,pageType) => {
        var offer_ids = tempOfferData.map((item,index)=>{return item.id})
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"offer/caps"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                offer_id:offer_ids.toString()
            }
        }
        )
        .then((resp) => {
            for(var index in tempOfferData){
                tempOfferData[index]={...tempOfferData[index],...resp.data.result[tempOfferData[index].id]}
            }
            setOfferData({...offerData,[pageType]:tempOfferData});
            refreshComponent(123);
            showOfferBookmarksRequest(tempOfferData,offer_ids,pageType)
        })
        .catch(function (error) {
            setLoader(false)
        });
    }


    const showOfferBookmarksRequest = async(tempOfferData,offer_ids,pageType) => {
        await axios({
            method: 'POST',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"bookmark/status"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":'application/json'
            },
            data:{
                offer_id:offer_ids
            }
        }
        )
        .then((resp) => {
            for(var index in tempOfferData){
                tempOfferData[index]={...tempOfferData[index],bookmark:resp.data.result[tempOfferData[index].id] ? true : false}
            }
            setOfferData({...offerData,[pageType]:tempOfferData});
            refreshComponent(123);
        })
        .catch(function (error) {
            setLoader(false)
        });
    }

    
    const changeOfferThings = async(e,column_name,updated_value) => {
        if(selectedElements.length>1){
            var offersString = "Offers are set to " + updated_value
        }
        else{
            offersString = "Offer is set to " + updated_value
        }

        await axios({
            method: 'POST',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.GETOFFERS,"s/change"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":'application/json',
            },
            data:{
                offer_id_list : selectedElements,
                column_name,
                updated_value
            }
        })
        .then(resp => {
            var tempOfferList = offerData[offerTabSelected]
            for(var index in selectedElements){
                for(var index2 in tempOfferList){
                    if(tempOfferList[index2].id === selectedElements[index]){
                        tempOfferList[index2][column_name] = updated_value
                    }
                }
            }
            setOfferData({...offerData,[offerTabSelected]:tempOfferList});
            if(resp.data.result==="offer data successfully updated"){
                Store.addNotification({
                    title: "Success!",
                    message: offersString,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true
                    },
                });
            }
            setChangeAffiliatesSelected([]);
            setSelectAll(false);
            setSelectedElements([]);
            refreshComponent(123);
            if(column_name==='offer_type'){
                setChangePrivacy(false);
            }
            else if(column_name==='offer_status'){
                setChangeStatus(false);
            }
        })
        .catch(err => {
            console.error(err);
        })
    };

    const changeAffiliateRequest = async(status) => {
        var offerString = ""
        for(var index in selectedElements){
            if(parseInt(index)!==selectedElements.length-1){
                offerString += selectedElements[index]+","
            }
            else{
                offerString += selectedElements[index]
            }
        }

        await axios({
            method: 'POST',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.GETOFFERS,"s/acess/multiple"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":'application/json',
            },
            data:{
                offer_id : offerString,
                "status" : status,
                "affiliates" : changeAffiliatesSelected
            }
        })
        .then(resp => {
            if(resp.data.result){
                Store.addNotification({
                    title: "Success!",
                    message: "Affiliates "+status+" for these offers.",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true
                    },
                });
                setChangeAffiliatesSelected([]);
                setSelectAll(false);
                setSelectedElements([]);
                showOfferDataRequest([]);
            }
        })
        .catch(err => {
            console.error(err);
        })
    };
    
    const [recheckContinueApiLoader, setRecheckContinueApiLoader] = useState(false)
    const recheckContinueOffers = async() => {
        setRecheckContinueApiLoader(true)
        var offerString = ""
        for(var index in selectedElements){
            offerString += selectedElements[index]+","
        }
        offerString = offerString.slice(0,-1)
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"offer/recheck/status"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                offer_id : offerString
            }
        })
        .then(resp => {
            if(resp.data.result){
                setSelectedElements([]);
                setSelectAll(false);
                Store.addNotification({
                    title: "Success!",
                    message: resp.data.result,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true
                    },
                });
            }
        })
        .catch(err => {
            console.error(err);
            if(err.response && err.response.data){
                Store.addNotification({
                    title: "Error!",
                    message: err.response.data.result,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true
                    },
                });
            }
        }) 
        setRecheckContinueApiLoader(false)

    };   

    if(!manualUseEffect){
        setManualUseEffect(true)
        showOfferDataRequest()
        getRequests(getAdvertisers(),setAdvertiserOptions)
        getRequests(getManager(),setAdvertiserManagerOptions)
        getRequests(getAffiliates(),setAffiliatesOptions)
        getRequests(getSmartlinks(),setSmartlinkOptions)
    }

    var statusOptions = [
        {label:'All',value:null},
        {label:'Active',value:'active'},
        {label:'Paused',value:'paused'},
        {label:'Disabled',value:'disabled'},
        {label:'Expired',value:'expired'},
    ]

    var typeOptions = [
        {label:'All',value:null},
        {label:'Public',value:'public'},
        {label:'Private',value:'private'},
        {label:'Request',value:'protected'},
    ]
    var filterOptions = [
        // {id:'id',label:'Id',type:'textfield',value:filterSearch['id'],setValue:setFilterSearch,minWidth:'70px'},
        {id:'id',label:'Offer Id',type:'textfield',value:filterSearch['id'],setValue:setFilterSearch,minWidth:130,},
        {id:'offer_title',label:'Offer Title',type:'textfield',value:filterSearch['offer_title'],setValue:setFilterSearch},
        {id:'external_offer_id',label:'External Offer Id',type:'textfield',value:filterSearch['external_offer_id'],setValue:setFilterSearch,minWidth:150,},
        {id:'offer_source',label:'Offer Source',isMulti:false,type:'select',value:filterSearch['offer_source'],setValue:setFilterSearch,options:[{label:'All',value:null},{label:'Automated',value:'automated'},{label:'Manual',value:'manual'}],minWidth:200},
        {id:'advertiser',label:'Advertiser',isMulti:true,type:'select',value:filterSearch['advertiser'],setValue:setFilterSearch,options:[...advertiserOptions],minWidth:170},
        {id:'manager_id',label:'Advertiser Manager',isMulti:true,type:'select',value:filterSearch['manager_id'],setValue:setFilterSearch,options:[...advertiserManagerOptions],minWidth:240},
        {id:'status',label:'Offer Status',type:'select',value:filterSearch['status'],setValue:setFilterSearch,options:statusOptions,minWidth:185},
        {id:'type',label:'Offer Type',type:'select',value:filterSearch['type'],setValue:setFilterSearch,options:typeOptions,minWidth:185},
        {id:'smartlink_id',label:'Smartlink',type:'select',value:filterSearch['smartlink_id'],setValue:setFilterSearch,options:smartlinkOptions,isMulti:true,minWidth:185},
    ]

    const editComponent = (item) =>{
        return <NavLink className='threeDotMenuOption'  to={"/offers/edit/"+item.id} onClick={()=>{updateRecentlyViewedOffers(item)}}>
                <EditIcon sx={{color:localStorage.getItem('ambientColor'),fontSize:'16px',marginRight:'5px'}}/>
                Edit
            </NavLink>
    }

    const changeBookmark = async(item,index,tableValues,setTableValues) =>{
        await axios({
            method: 'POST',
            url : "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'bookmark/change '),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":"application/json",
            },
            data:{
                offer_id:item.id
            }
        })
        .then(resp => {
            var tempTableValues = tableValues;

            if(window.location.href.includes('offerPageType=bookmarked')){
                showOfferDataRequest()
            }
            else{
                if(tempTableValues[index].bookmark){
                    tempTableValues[index].bookmark = !tempTableValues[index].bookmark
                }
                else{
                    tempTableValues[index].bookmark = true;
                }
            }
            setTableValues(tempTableValues);
            refreshComponent(123);
            Store.addNotification({
                title: "Success!",
                message: "Bookmark Changed.",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                duration: 2000,
                onScreen: true
                },
            });
        })
        .catch(error => {
            console.log(error)
        })
    }

    const bookmarkComponent = (item,index,tableValues,setTableValues) =>{
        if(!item.bookmark){
            return <div className='threeDotMenuOption' style={{cursor:'pointer'}} onClick={()=>{changeBookmark(item,index,tableValues,setTableValues)}}>
                        <BookmarkAddOutlinedIcon sx={{color:localStorage.getItem('ambientColor'),fontSize:'16px',marginRight:'5px'}}/>
                        Add to Bookmark
                    </div>
        }
        else{
            return <div className='threeDotMenuOption' style={{cursor:'pointer'}}  onClick={()=>{changeBookmark(item,index,tableValues,setTableValues)}}>
                        <BookmarkRemoveOutlinedIcon sx={{color:localStorage.getItem('ambientColor'),fontSize:'16px',marginRight:'5px'}}/>
                        Remove Bookmark
                    </div>
        }
    }
    const viewStatsComponent = (item) =>{
        return <NavLink className='threeDotMenuOption' to={"/statistics/daily?offerId="+item.id}>
                <QueryStatsIcon sx={{color:localStorage.getItem('ambientColor'),fontSize:'16px',marginRight:'5px'}}/>
                View Stats
            </NavLink>
    }

    const companyName = (value,tableHeadIndex,item) => {
        return <Tooltip title={value}>
                    <NavLink style={{textDecoration:'none',color:localStorage.getItem('ambientColor')}} to={'/offers/view/'+item.id} onClick={()=>{updateRecentlyViewedOffers(item)}}>
                        {value}
                    </NavLink>
                </Tooltip>
    }

    var statusDictColor = {
        'active':{
            color:'rgb(0, 150, 0)',
            backgroundColor:'rgb(217, 255, 200)',
            label:'Active'
        },
        'paused':{
            color:'orange',
            backgroundColor:'rgb(255, 234, 195)',
            label:'Paused',
        },
        'disabled':{
            color:'red',
            backgroundColor:'rgb(246,187,188)',
            label:'Disabled',
        },
        'expired':{
            color:'black',
            backgroundColor:'rgb(200,200,200)',
            label:'Expired',
        },
        
    }
    const statusComponent = (value,tableHeadIndex,item) => {
        return <div style={{display:'flex',justifyContent:'center'}}>
                    <div style={{minWidth:'70px',wordWrap:'break-word',color:(statusDictColor[value] ? statusDictColor[value].color : 'black'),border:'1px solid '+(statusDictColor[value] ? statusDictColor[value].color : 'black'),padding:'2px',backgroundColor:(statusDictColor[value] ? statusDictColor[value].backgroundColor : 'white'),borderRadius:'5px',paddingTop:'5px',paddingBottom:'5px'}}>
                        {statusDictColor[value] ? statusDictColor[value].label : value}
                    </div>
                </div>
    }

    const privacyComponent = (value) => {
        if(value==='protected'){
            value = 'request';
        }
        return <div>
                {capitalize(value)}
            </div>
    }

    const [affiliateData, setAffiliateData] = useState("")
    const getAffiliatesOfOffer = async(item,index,tableHead) => {
        tableHead.setAffiliateData({id:item.id,data:'loading'})
        axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"offerapprovalrequests/affiliate/status"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                offer_id:item.id,
                status:"approved",
            }
        })
        .then(resp => {
            var affiliateIdList = []
            affiliateIdList = resp.data.result.map((item,index)=>{
                return <div key={index} style={{marginTop:'2px',marginBottom:'2px'}}>
                    {item.split(") ")[1] + " - " + item.split(") ")[0].split("(")[1]}
                </div>
            })
            // for (var affiliate in resp.data.result){
            //     affiliateIdList += resp.data.result[affiliate].split(") ")[1] + " - " + resp.data.result[affiliate].split(") ")[0].split("(")[1]
            //     if(parseInt(affiliate)!==resp.data.result){
            //         affiliateIdList+=", "
            //     }
            // }
            tableHead.setAffiliateData({id:item.id,data:affiliateIdList})
            refreshComponent(1234)
        })
        .catch(err => {
            console.error(err);
        }) 
        // document.getElementById('affiliateList'+item.id).innerText = '123 deepesh'
    }

    const affiliateComponent = (value,tableHeadIndex,item,index,tableHead) => {
        return <div style={{cursor:'pointer'}} onClick={(e)=>{e.stopPropagation();getAffiliatesOfOffer(item,index,tableHead)}}>
                    {(!tableHead.affiliateData || (tableHead.affiliateData && (tableHead.affiliateData.data!=='loading' || item.id!==tableHead.affiliateData.id))) && value}
                    {tableHead.affiliateData && item.id===tableHead.affiliateData.id && tableHead.affiliateData.data==='loading' && <CircularProgress sx={{color:localStorage.getItem('ambientColor')}} size={18}/>}
                    {tableHead.affiliateData && item.id===tableHead.affiliateData.id &&
                        <div id={'affiliateList' + item.id} style={{position:'absolute',marginTop:'10px',minWidth:'100px',background:'white',boxShadow:'0 0 5px 1px grey',minHeight:'30px',wordWrap:'break-word',whiteSpace:'none'}}>
                            <div style={{textAlign:'center',padding:'5px',fontSize:'13px',fontWeight:'500',borderBottom:'1px solid rgb(200,200,200)'}}>Approved Affiliates</div>
                            <div style={{maxHeight:'30vh',overflow:'auto'}}>{tableHead.affiliateData.data}</div>
                        </div>
                    }
                </div>
    }

    const categoriesComponent = (value) => {
        return <div>
                    {value}
                </div>
    }

    const osComponent = (value,tableHeadIndex,item) => {
        return <div>
                    {value}
                </div>
    }
    const dateTimeComponent = (value) => {
        if(value){
            var dateOptions = { year: 'numeric', month: 'short', day: 'numeric' ,hour:'numeric',minute:'numeric',second:'numeric'};
            return new Date(value).toLocaleDateString('en-US',dateOptions)
        }
        else{
            return "-"
        }
    }

    const dateComponent = (value) => {
        if(value){
            var dateOptions = { year: 'numeric', month: 'short', day: 'numeric'};
            return new Date(value).toLocaleDateString('en-US',dateOptions)
        }
        else{
            return "-"
        }
    }
    const sortData = (key,childKey,values,setItem,tableSortingOrder,setTableSortingOrder) => {
        setTableSortingOrder({value:key+"-"+childKey,order:!tableSortingOrder.order})
        sortingV2(values,key,!tableSortingOrder.order,childKey)
        setItem(values)
    }

    const capsTableHeadComponent = (tableHead,tableHeadIndex,childItems,tableSortingOrder,setTableSortingOrder) => {
        return <table style={{width:"100%"}}>
            <thead>
                <tr>
                    <td colSpan={3}>
                        Caps
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr style={{fontSize:'11px'}}>
                    <td width={'33%'} style={{cursor:'pointer'}} onClick={()=>{sortData('caps','caps_count',childItems.tableValues,childItems.setTableValues,tableSortingOrder,setTableSortingOrder)}}>Caps Count</td>
                    <td width={'33%'} style={{cursor:'pointer'}} onClick={()=>{sortData('caps','daily_limit',childItems.tableValues,childItems.setTableValues,tableSortingOrder,setTableSortingOrder)}}>Daily Limit</td>
                    <td width={'33%'} style={{cursor:'pointer'}} onClick={()=>{sortData('caps','total_limit',childItems.tableValues,childItems.setTableValues,tableSortingOrder,setTableSortingOrder)}}>Total Limit</td>
                </tr>
            </tbody>
        </table>
    }

    const capComponent = (value,tableHeadIndex,item) => {
        return <table style={{width:"100%"}}>
            <tbody>
                <tr style={{fontSize:'11px'}}>
                    <td style={{minWidth:'33%',width:'33%',maxWidth:'33%'}} className='overflowText'>{value.caps_count ? value.caps_count : '-'}</td>
                    <td style={{minWidth:'33%',width:'33%',maxWidth:'33%'}} className='overflowText'>{value.daily_limit ? value.daily_limit : '-'}</td>
                    <td style={{minWidth:'33%',width:'33%',maxWidth:'33%'}} className='overflowText'>{value.total_limit ? value.total_limit : '-'}</td>
                </tr>
            </tbody>
        </table>
    }

    const payoutHeadComponent = (tableHead,tableHeadIndex,childItems,tableSortingOrder,setTableSortingOrder) => {
        return <table style={{width:"100%"}}>
            <thead>
                <tr>
                    <td colSpan={3}>
                        Payouts
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr style={{fontSize:'11px'}}>
                    <td width={'33%'} style={{cursor:'pointer'}} onClick={()=>{sortData('payouts','revenue',childItems.tableValues,childItems.setTableValues,tableSortingOrder,setTableSortingOrder)}}>Revenue</td>
                    <td width={'33%'} style={{cursor:'pointer'}} onClick={()=>{sortData('payouts','payout',childItems.tableValues,childItems.setTableValues,tableSortingOrder,setTableSortingOrder)}}>Payouts</td>
                    <td width={'33%'} style={{cursor:'pointer'}} onClick={()=>{sortData('payouts','goal_value',childItems.tableValues,childItems.setTableValues,tableSortingOrder,setTableSortingOrder)}}>Goal Value</td>
                </tr>
            </tbody>
        </table>
    }

    const payoutComponent = (value,tableHeadIndex,item,index,tableHead) => {
        return <table style={{width:"100%",tableLayout:'fixed'}}>
            <tbody>
                <tr style={{fontSize:'11px'}}>
                    <td style={{minWidth:'33%',width:'33%',maxWidth:'33%'}} className='overflowText'>
                        {value.revenue ? value.revenue : '-'}
                    </td>
                    <td style={{minWidth:'33%',width:'33%',maxWidth:'33%'}} className='overflowText'>
                        {value.payout ? value.payout : '-'}
                    </td>
                    <td style={{minWidth:'33%',width:'33%',maxWidth:'33%'}} className='overflowText'>
                        <Tooltip title={value.goal_value ? value.goal_value : '0'}>
                            <div>
                                {value.goal_value ? value.goal_value : '-'}
                            </div>
                        </Tooltip>
                    </td>
                </tr>
            </tbody>
        </table>
    }
    const geoComponent = (value) => {
        if(value){
            var stringValue = ''
            for(var index in value){
                var item = value[index]

                if(item.includes(',')){
                    var splitItemList = item.split(',')
                    for(var splitIndex in splitItemList){
                        stringValue+= splitItemList[splitIndex] + ', '
                    }
                }
                else{
                    stringValue+= item + ', '
                }
            }
            if(stringValue){
                stringValue = stringValue.slice(0,-2)
            }
            return stringValue
        }
        else{
            return "-"
        }
    }
    const [selectedElements, setSelectedElements] = useState([])
    const [selectAll, setSelectAll] = useState(false)

    var tempTableHeaders = [
        {id:'checkbox',label:'',value:'checkbox',show:true,width:'2%',minWidth:30,whatToSelect:'id'},
        {id:'id',label:'ID',value:'id',show:true,width:'5%',minWidth:60,},
        {id:'title',label:'Title',value:'title',show:true,width:'12%',minWidth:80,alignHead:'left',alignValue:'left',color:localStorage.getItem('ambientColor'),customComponent:companyName},
        {id:'advertiser',label:'Advertiser',value:'advertiser',show:true,width:'10%',minWidth:50,alignHead:'left',alignValue:'left',},
        {id:'manager_name',label:'Advertiser Manager',value:'manager_name',show:true,width:'10%',minWidth:140,alignHead:'left',alignValue:'left',},
        {id:'created_at',label:'Created At',value:'created_at',show:true,width:'8%',minWidth:170,alignValue:'center',customComponent:dateTimeComponent},
        {id:'offer_expiry',label:'Offer Expiry',value:'offer_expiry',show:true,width:'8%',minWidth:140,alignValue:'center',customComponent:dateComponent},
        {id:'offer_source',label:'Source',value:'offer_source',show:true,width:'8%',minWidth:80,alignValue:'center',tableValueStyle:{textTransform:'capitalize'}},
        {id:'offer_status',label:'Status',value:'offer_status',show:true,width:'8%',minWidth:80,alignValue:'center',customComponent:statusComponent},
        {id:'offer_type',label:'Privacy',value:'offer_type',show:true,width:'8%',minWidth:80,customComponent:privacyComponent},
        {id:'affiliates',label:'Affiliates',value:'affiliates',show:true,width:'8%',minWidth:80,customComponent:affiliateComponent,affiliateData,setAffiliateData},
        {id:'categories',label:'Categories',value:'categories',show:true,width:'8%',minWidth:80,customComponent:categoriesComponent},
        {id:'os',label:'OS',value:'os',show:true,width:'4%',minWidth:40,customComponent:osComponent},
        {id:'external_offer_id',label:'External Offer Id',value:'external_offer_id',alignHead:'left',alignValue:'left',show:true,width:'8%',minWidth:40},
        {id:'geo',label:'Geo',value:'geo',show:true,width:'4%',minWidth:40,customComponent:geoComponent},
        {id:'caps',label:'Caps',value:'caps',show:true,width:'17%',minWidth:200,hideSorting:true,customHeadComponent:capsTableHeadComponent,customComponent:capComponent},
        {id:'payouts',label:'Payouts',value:'payouts',show:true,width:'17%',minWidth:200,hideSorting:true,customHeadComponent:payoutHeadComponent,customComponent:payoutComponent},
        {id:'caps_period',label:'Caps Period',value:'caps_period',exportHide:true,show:true,width:'6%',minWidth:50,tableValueStyle:{textTransform:'capitalize'}},
        {id:'caps_type',label:'Caps Type',value:'caps_type',exportHide:true,show:true,width:'6%',minWidth:50,tableValueStyle:{textTransform:'capitalize'}},
        {id:'yesterday',label:'Yesterday Caps',value:'yesterday',exportHide:true,show:true,width:'6%',minWidth:50},
        {id:'today',label:'Today Caps',value:'today',exportHide:true,show:true,width:'6%',minWidth:50},
        {id:'total',label:'Total Caps',value:'total',exportHide:true,show:true,width:'6%',minWidth:50},
        {id:'tracking_url',label:'Tracking Url',value:'tracking_url',show:true,width:'10%',minWidth:50,hide:true,tableValueStyle:{textTransform:'none'}},
        {id:'preview_url',label:'Preview Url',value:'preview_url',show:true,width:'10%',minWidth:50,hide:true,tableValueStyle:{textTransform:'none'}},
        {id:'impression_link',label:'Impression Url',value:'impression_link',show:true,width:'10%',minWidth:50,hide:true,tableValueStyle:{textTransform:'none'}},
        {id:'threeDotMenu',label:'...',value:'threeDotMenu',show:true,width:'6%',hideSorting:true,minWidth:50,threeDotMenu:true,menuOptions:[{label:'Edit',value:'edit',component:editComponent},{label:'View Statistics',value:'viewStatistics',component:viewStatsComponent},{label:'Bookmark',value:'bookmark',component:bookmarkComponent},],height:60},
    ]

    if(getLocalStorageValue(window.location.pathname)){
        try{
            var hideShowColumns = getLocalStorageValue(window.location.pathname)
            for(index in tempTableHeaders){
                tempTableHeaders[index].hide = hideShowColumns[index].hide
            }
        }
        catch{

        }
    }
    const [tableHeaders, setTableHeaders] = useState(tempTableHeaders)
    
    const gridComponent = (item,index) => {
        return <div style={{width:'250px',minHeight:'300px',margin:'15px',border:'1px solid rgb(220,220,220)'}} key={index}>
            {item.id}
        </div>
    }

    const [changeStatus, setChangeStatus] = useState(false)
    const [changeStatusSelected, setChangeStatusSelected] = useState(false)
    const [changePrivacy, setChangePrivacy] = useState(false)
    const [changePrivacySelected, setChangePrivacySelected] = useState(false)
    
    const [changeAffiliates, setChangeAffiliates] = useState(false)
    const [searchChangeAffiliate, setSearchChangeAffiliate] = useState('')
    const [changeAffiliatesSelected, setChangeAffiliatesSelected] = useState([])
    
    const selectedElementsComponent = () => {
        return <div style={{color:'white',fontSize:'13px',top:0,height:50,zIndex:999,position:'sticky',display:'flex',background:localStorage.getItem('ambientColor'),alignItems:'center',justifyContent:'space-between',paddingLeft:'20px',paddingRight:'20px'}}>
            <div style={{fontWeight:'600'}}>Selected {selectedElements.length} Offer</div>
            <div style={{display:'flex',flexDirection:'row-reverse',width:'70%',justifyContent:'flex-start'}}>

                <div className='advertiserPageV2ChangeStatus' style={{marginLeft:'20px'}} onClick={(e)=>{e.stopPropagation();setChangeStatus(true);setChangePrivacy(false);setChangeAffiliates(false);}}>
                    Change Status
                    {
                        changeStatus && 
                        <div style={{color:'black',position:'absolute',minHeight:90,width:150,marginLeft:'-30px',marginTop:'10px',background:'white',boxShadow:'0 0 5px 1px grey'}} onClick={(e)=>{e.stopPropagation()}}>
                            <div style={{display:'flex',justifyContent:'center',fontSize:'14px',padding:'10px'}}> Change Status</div>
                            <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                {Object.keys(statusDictColor).map((item,index)=>{
                                    var value = item
                                    return <div key={index} style={{display:'flex',justifyContent:'center',marginTop:'10px'}}>
                                                <input id={item} type='radio' name='status' onChange={(e)=>{setChangeStatusSelected(item);}}/>
                                                <label htmlFor={item} style={{marginLeft:'10px',textAlign:'center',minWidth:'70px',wordWrap:'break-word',color:(statusDictColor[value] ? statusDictColor[value].color : 'black'),border:'1px solid '+(statusDictColor[value] ? statusDictColor[value].color : 'black'),padding:'2px',backgroundColor:(statusDictColor[value] ? statusDictColor[value].backgroundColor : 'white'),borderRadius:'5px',paddingTop:'5px',paddingBottom:'5px'}}>
                                                    {statusDictColor[value] ? statusDictColor[value].label : value}
                                                </label>
                                            </div>
                                })}
                            </div>
                            <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                <Button disabled={changeStatus==='loading'} sx={{...Constants.CONTAINEDBUTTON,fontSize:'14px',height:'35px',width:'100%',paddingLeft:'15px',borderRadius:'0px',paddingRight:'15px',marginTop:'20px',textTransform:'none',fontWeight:500}} onClick={(e)=>{changeOfferThings(e,'offer_status',changeStatusSelected);setChangeStatus('loading');}}>
                                    {changeStatus==='loading' && <CircularProgress sx={{color:'white'}} size={18}/>}
                                    {changeStatus!=='loading' && "Save"}
                                </Button>
                            </div>
                        </div>
                    }
                </div>
                <div className='advertiserPageV2ChangeStatus' style={{marginLeft:'20px'}} onClick={(e)=>{e.stopPropagation();setChangePrivacy(true);setChangeStatus(false);setChangeAffiliates(false);}}>
                    Change Privacy
                    {
                        changePrivacy && 
                        <div style={{color:'black',position:'absolute',minHeight:90,width:150,marginLeft:'-30px',marginTop:'10px',background:'white',boxShadow:'0 0 5px 1px grey'}} onClick={(e)=>{e.stopPropagation()}}>
                            <div style={{display:'flex',justifyContent:'center',fontSize:'14px',padding:'10px'}}> Change Privacy</div>
                            <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                {typeOptions.map((item,index)=>{
                                    if(item.value){
                                        return <div key={index} style={{display:'flex',justifyContent:'center',marginTop:'10px'}}>
                                                    <input id={item.value} type='radio' name='type' onChange={(e)=>{setChangePrivacySelected(item.value);}}/>
                                                    <label htmlFor={item.value} style={{fontWeight:'400'}}>
                                                        {item.label}
                                                    </label>
                                                </div>
                                    }
                                    return null;
                                })}
                            </div>
                            <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                <Button disabled={changeStatus==='loading'} sx={{...Constants.CONTAINEDBUTTON,fontSize:'14px',height:'35px',width:'100%',paddingLeft:'15px',borderRadius:'0px',paddingRight:'15px',marginTop:'20px',textTransform:'none',fontWeight:500}} onClick={(e)=>{changeOfferThings(e,'offer_type',changePrivacySelected);setChangePrivacy('loading');}}>
                                    {changePrivacy==='loading' && <CircularProgress sx={{color:'white'}} size={18}/>}
                                    {changePrivacy!=='loading' && "Save"}
                                </Button>
                            </div>
                        </div>
                    }
                </div>
                <div className='advertiserPageV2ChangeStatus' style={{marginLeft:'20px'}} onClick={(e)=>{e.stopPropagation();setChangePrivacy(false);setChangeStatus(false);setChangeAffiliates(true);}}>
                    Affiliates
                    {
                        changeAffiliates && 
                        <div style={{color:'black',position:'absolute',minHeight:90,width:250,marginLeft:'-30px',marginTop:'10px',background:'white',boxShadow:'0 0 5px 1px grey'}} onClick={(e)=>{e.stopPropagation()}}>
                            <div style={{display:'flex',justifyContent:'center',fontSize:'14px',padding:'10px'}}> Enable/Disable Affiliates</div>
                            <div style={{display:'flex',justifyContent:'center',fontSize:'14px',padding:'10px'}}> 
                                <Constants.CssTextField size='small' fullWidth onChange={(e)=>{setSearchChangeAffiliate(e.target.value)}}/>
                            </div>
                            <div style={{maxHeight:'250px',overflow:'auto',paddingLeft:'20px',paddingRight:'20px'}}>
                                <table style={{width:'100%'}}>
                                    <tbody>
                                        {affiliateOptions.map((item,index)=>{
                                            if(item.value && (searchChangeAffiliate==='' || item.label.toLowerCase().includes(searchChangeAffiliate))){
                                                return <tr key={index}>
                                                            <td style={{textAlign:'right',paddingTop:'5px'}}>
                                                                <input id={item.value} type='checkbox' onChange={(e)=>{if(e.target.checked){setChangeAffiliatesSelected([...changeAffiliatesSelected,item.value])} else{setChangeAffiliatesSelected(changeAffiliatesSelected.filter(e=>e!==item.value))}}}/>
                                                            </td>
                                                            <td>
                                                                <label htmlFor={item.value} style={{fontWeight:'400'}}>
                                                                    {item.label}
                                                                </label>
                                                            </td>
                                                        </tr>
                                            }
                                            return null;
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <Button sx={{...Constants.CONTAINEDBUTTON,fontSize:'14px',height:'35px',width:'50%',paddingLeft:'15px',borderRadius:'0px',paddingRight:'15px',marginTop:'20px',textTransform:'none',fontWeight:500}} onClick={(e)=>{changeAffiliateRequest('approved');setChangeAffiliates(false);}}>Enable</Button>
                                <Button sx={{...Constants.CONTAINEDBUTTON,fontSize:'14px',height:'35px',width:'50%',paddingLeft:'15px',borderRadius:'0px',paddingRight:'15px',marginTop:'20px',textTransform:'none',fontWeight:500}} onClick={(e)=>{changeAffiliateRequest('denied');setChangeAffiliates(false);}}>Disable</Button>
                            </div>
                        </div>
                    }
                </div>

                {
                process.env.REACT_APP_FEATURE_RECHECK_CONTINUE==='true' &&
                    <div className='advertiserPageV2ChangeStatus' style={{minWidth:'80px'}} onClick={(e)=>{e.stopPropagation();setChangePrivacy(false);setChangeStatus(false);setChangeAffiliates(false);recheckContinueOffers();}}>
                        <Tooltip title='Check Status of offers continously for 24 hours.'>
                            <div style={{position:'relative',display:'flex',justifyContent:'center'}}>
                                {recheckContinueApiLoader && 
                                <CircularProgress sx={{color:'white'}} size={'20px'}/>
                                }
                                {!recheckContinueApiLoader && 
                                <>
                                    <div style={{display:'flex',paddingLeft:'5px',paddingRight:'5px',fontWeight:900,fontSize:'8px',position:'absolute',background:'red',top:-5,right:-15,transform:'scale(0.8) rotate(40deg)'}}>
                                        New
                                    </div>
                                    Recheck Continue
                                </>
                                }

                                {/* // TODO: REMOVE THIS WHEN THIS ISNT NEW */}
                            </div>
                        </Tooltip>
                    </div>
                }

                <div className='advertiserPageV2ChangeStatus' onClick={(e)=>{e.stopPropagation();setChangePrivacy(false);setChangeStatus(false);setChangeAffiliates(false);setExportPopup(true);}}>
                    <div style={{position:'relative'}}>
                        Export Offers
                    </div>
                </div>
                
            </div>
        </div>
    }
    const setOfferDataFunction = (values) => {
        setOfferData({...offerData,[offerTabSelected]:values})
    }
    const [exportPopup, setExportPopup] = useState(false)

    const checkForMillions = (value) => {
        try{
            var string =  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return string;
        }
        catch{
            return value;
        }
    }
    return (
        <div className='advertiserPageV2' onClick={()=>{setChangeStatus(false);setChangePrivacy(false);setChangeAffiliates(false);setAffiliateData("")}}>
            {exportPopup && <ExportPopup setExportPopup={setExportPopup} type={'OfferView'} filterSearch={{...filterSearch,selectedElements,pageType:offerTabSelected}}/>}
            <div className='containerFirstRow'>
                <div> Found {advertisersTotalCount[offerTabSelected] || advertisersTotalCount[offerTabSelected]===0 ? checkForMillions(advertisersTotalCount[offerTabSelected]) : <CircularProgress sx={{color:localStorage.getItem('ambientColor'),paddingLeft:'5px',paddingRight:'5px'}} size={13}/>} Offer</div>
                <div className='containerFirstRowColTwo'>
                    {/* <div style={{marginRight:'20px',cursor:'pointer',}} onClick={()=>{localStorage.setItem('oldOfferPage',true);window.location.reload();}}> Back to old design </div> */}
                    {(offerTabSelected==='all_campaigns' || offerTabSelected==='bookmarked')
                        &&
                        <div style={{marginRight:'20px',cursor:'pointer',color:localStorage.getItem('ambientColor')}} onClick={()=>{setExportPopup(true)}}> Export CSV </div>
                    }
                    <NavLink to={'/offers/add'} style={{textDecoration:'none'}}>
                        <Button sx={{...Constants.OUTLINEDBUTTON,height:'30px',fontSize:'15px',textTransform:'none',fontWeight:500}}> Add</Button>
                    </NavLink>
                </div>
            </div>
            <div style={{marginTop:'5px',borderTop:'1px solid '+localStorage.getItem('ambientColor')}}/>
            <div className='offerTabsContainerV3'>
                {Object.keys(offerViewTabs).map((item,index)=>{
                    return <div key={index}>
                                <div className={offerTabSelected===item ? 'offerTabV3Selected offerTabV3' : 'offerTabV3'} onClick={()=>{handleTabChange(item);showOfferDataRequest(limit,1,item,{id:'',offer_title:'',advertiser:'',status:'',type:''})}}>
                                    {offerViewTabs[item]}
                                </div>
                            </div>
                })}
            </div>
            <div className='containerSecondRow' style={{width:'100%'}}>
                {filterOptions.map((filterItem,filterIndex)=>{
                    if(filterItem.type==='textfield'){
                        return <div key={filterIndex} style={{width:(filterItem.minWidth ? filterItem.minWidth : 'max-content'),marginRight:'15px',marginTop:20}}>
                                <Constants.CssTextField 
                                    size='small' 
                                    disabled={filterItem.disable || offerTabSelected==='recently_viewed'} 
                                    fullWidth 
                                    value={filterSearch[filterItem.id]} 
                                    placeholder={filterItem.label}
                                    onChange={(e)=>{setFilterSearch({...filterSearch,[filterItem.id]:e.target.value})}} 
                                    onKeyDown={(e)=>{if(e.key==='Enter' && !loader){showOfferDataRequest(limit,1)}}}
                                    />
                            </div>
                    }
                    else if(filterItem.type==='select'){
                        return <div key={filterIndex} style={{width:(filterItem.minWidth ? filterItem.minWidth : 'max-content'),marginRight:'15px',marginTop:20}}>
                            <ReactSelect
                                isClearable
                                isDisabled={filterItem.disable || offerTabSelected==='recently_viewed'}
                                isMulti={filterItem.isMulti}
                                key={filterIndex} 
                                options={filterItem.options}
                                value={filterSearch[filterItem.id]}
                                onChange={(e)=>{setFilterSearch({...filterSearch,[filterItem.id]:e})}}
                                menuPortalTarget={document.body} 
                                placeholder={"Select "+filterItem.label}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 1000000 }),
                                            control: (base,state) => ({
                                                ...base,
                                                // maxHeight: 100,
                                                minHeight: 30,
                                                color:'rgb(200,200,200)',
                                                overflowY: "auto",
                                                fontSize:14,
                                                border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                                '&:hover': {
                                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                    boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                                }
                                            }),
                                            placeholder: (defaultStyles) => {
                                                return {
                                                    ...defaultStyles,
                                                    color: 'rgb(150,150,150)',
                                                    marginTop:'-2px'
                                                }
                                            },
                                            option: (styles, {isFocused, isSelected}) => ({
                                                ...styles,
                                                fontSize:'11px',
                                                background: isFocused
                                                    ? localStorage.getItem('ambientColor')
                                                    : isSelected
                                                        ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                        : undefined,
                                                zIndex: 1
                                            }),
                                        }}
                            />
                        </div>
                    }
                    return null;
                })}
            </div>
            <div className='containerThirdRow'>
                <Button disabled={loader || offerTabSelected==='recently_viewed' ? true : false} sx={{...Constants.CONTAINEDBUTTON,minHeight:'40px',fontSize:'15px',minWidth:'80px',paddingLeft:'15px',paddingRight:'15px',marginTop:'20px',textTransform:'none',fontWeight:500,marginLeft:'15px'}} onClick={()=>{showOfferDataRequest(limit,1)}}>
                    {loader && 
                    <CircularProgress sx={{color:'white'}} size={20}/>
                    }
                    {!loader && "Search"}
                </Button>
                <Button disabled={loader || offerTabSelected==='recently_viewed' ? true : false} sx={{...Constants.OUTLINEDBUTTON,minHeight:'40px',fontSize:'15px',paddingLeft:'15px',paddingRight:'15px',marginTop:'20px',textTransform:'none',fontWeight:500,marginLeft:'15px'}} onClick={()=>{setFilterSearch({id:'',offer_title:'',manager_id:'',external_offer_id:'',advertiser:'',status:'',type:'',smartlink_id:''})}}>Reset</Button>
            </div>

            <TableModal 
                stickyColumn={[0,1,2]} 
                rightStickyColumnList={[tableHeaders.length-1]} 
                showLoader={loader} 
                tableHeaders={tableHeaders} 
                setTableHeaders={setTableHeaders} 
                tableHeadersStyle={{fontSize:'14px',wordWrap:'break-word',whiteSpace:'normal',textAlign:'center'}}
                tableValues={offerData[offerTabSelected]} 
                setTableValues={setOfferDataFunction} 
                hideTooltip={false} 
                showCustomization={true} 
                childItems={{
                    tableValues:offerData[offerTabSelected],
                    setTableValues:setOfferDataFunction,
                    affiliateData,
                    setAffiliateData}}
                gridComponent={gridComponent}
                tableSelectAll={selectAll} 
                setTableSelectAll={setSelectAll} 
                setSelectedElements={setSelectedElements} 
                selectedElements={selectedElements}
                selectedElementsComponent = {selectedElementsComponent}
            />
            <PaginationComponent page={page} setPage={setPage} limit={limit} setLimit={setLimit} limitList={[25,50,100,200,300,400,500]} totalResults={advertisersTotalCount[offerTabSelected] ? advertisersTotalCount[offerTabSelected] : 0} apiRequest={showOfferDataRequest} onClick={()=>{setSelectAll(false);setSelectedElements([]);}}/>
        </div>
    )
}

export default OfferPageViewV3