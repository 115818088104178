import React, { useState } from 'react'
import { getBase64FromFile, getLocalStorageValue } from '../../util/util';
import * as Constants from '../../data/constants';
import axios from 'axios';
import './logo.css';
import { Store } from 'react-notifications-component';

const Logo = (props) => {
    const [logo, setLogo] = useState(false)
    const [manualUseState, setManualUseState] = useState(false)
    
    const handleFileInputChange = e => {
        var file = e.target.files[0];
        getBase64FromFile(file)
        .then(result => {
            file["base64"] = result;
            if(file.size>200000){
                Store.addNotification({
                    title: "Image Size Error!",
                    message: 'Image size should be less than 200kb',
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true
                    },
                });
            }
            else{
                setPhoto({
                    base64URL: result,
                    file
                })
            }
          })
          .catch(err => {
            console.log(err);
          });
          e.target.value = null;
    };    

    const getPhoto = async() => {
        if(props.page==='offer'){
            var params = {  
                    offer_id: props.id, 
                    }
        }
        else if(props.page==='manager'){
            params = {  
                manager_id: props.id, 
                }
        }
        await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,props.page,"/image"),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
        params
        })
        .then((resp) => {
            if(resp.data.result.length>0){
                var base64URL = resp.data.result[0].image
            }
            setLogo({base64URL})
        })
        .catch(err => {
            console.error(err);
        }) 
    }
    if(!manualUseState){
        setManualUseState(true);
        getPhoto()
    }
    const setPhoto = async(logo) => {
        setLogo(logo);
        if(props.page==='offer'){
            var data = {  
                    offer_id: props.id, 
                    image: logo.base64URL
                    }
        }
        else if(props.page==='manager'){
            data = {  
                manager_id: props.id, 
                image: logo.base64URL
                }
        }
        await axios({
        method: 'POST',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,props.page,"/image"),
        headers:{
            'Content-type':'application/json',
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
        data
        }
        )
        .then((resp) => {

        })
        .catch(err => {
            console.error(err);
        }) 
    }

    return (
        <div style={{position:'relative',top:0,left:0}}>
            {!props.hideEdit
            &&
            <div className={!logo.base64URL ? 'updateImage' : 'hideUpdateImage updateImage'}>
                <input type='file' name="file" id='logoSelect' onChange={handleFileInputChange} hidden></input>
                <label className='addImageLabel' htmlFor='logoSelect' >
                    {'+ Add ' + (props.addText ? props.addText : 'Photo')}
                    <br/>
                </label>
            </div>
            }
            {logo.base64URL &&
                <img style={{position:'absolute',top:0,zIndex:0}} src={logo.base64URL} alt='' width={props.size ? props.size : '150px'} height={props.size ? props.size : '150px'}/>
            }
            {logo.base64URL && !props.hideEdit &&
                <div style={{cursor:'pointer',textAlign:'center',fontSize:'13px',color:localStorage.getItem('ambientColor'),marginTop:'10px'}} onClick={()=>{document.getElementById('logoSelect').click();}}>
                    Change {(props.addText ? props.addText : 'Photo')}
                </div>
            }
            {logo.base64URL && !props.hideEdit &&
                <div style={{cursor:'pointer',textAlign:'center',fontSize:'13px',color:'red',marginTop:'5px'}} onClick={()=>{setPhoto({base64URL:''})}}>
                    Remove {(props.addText ? props.addText : 'Photo')}
                </div>
            }
        </div>
    )
}

export default Logo