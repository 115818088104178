import React from 'react';
import noResultsFoundPng from '../img/noResults.png'

const NoResultsFound = () => {
    return (
        <div style={{display:'flex',width:'100%',flexDirection:'column',alignItems:'center'}}>
            <img src={noResultsFoundPng} width={'20%'} alt=""></img>
            <div style={{fontSize:'16px',fontWeight:'600',marginTop:'15px'}}>No Results found.</div>
            <div style={{fontSize:'12px',color:'rgb(50,50,50)',marginTop:'15px'}}>We did not found any results for above filters.</div>
        </div>
    )
}

export default NoResultsFound