import React, { useMemo } from "react";
import styled from "styled-components";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
const CardHeader = styled.div`
  font-weight: 500;
  font-size:12px;
  text-align: start;
  padding-left:10px;
`;

const DragItem = styled.div`
  padding: 7px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: white;
  margin: 0 0 8px 0;
  display: flex;
  align-items:center;
`;


const ListItem = ({ item, provided, snapshot ,changeItemShow}) => {
  const randomHeader = useMemo(() => (item.parent ? item.parent + " - " : "") + item.label, [item.parent,item.label]);

  return (
    <DragItem
      ref={provided.innerRef}
      snapshot={snapshot}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >   
        <div onClick={changeItemShow}>{item.show ? <VisibilityOutlinedIcon fontSize="small"/> : <VisibilityOffOutlinedIcon fontSize="small"/>} </div>
        <CardHeader>{randomHeader}</CardHeader>
    </DragItem>
  );
};

export default ListItem;
