import React from "react";
import { useState } from "react";
import "../../../css/pages/demandsource.css";
import PropTypes from 'prop-types';
import { NavLink } from "react-router-dom";
import Select from 'react-select';

import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import * as Constants from '../../../data/constants';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { getLocalStorageValue, sorting } from "../../../util/util";

import axios from 'axios';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  if (false){
    console.log(value)
  }
  return (value) => setValue(value => value + 1); // update the state to force render
}
const NewDemandSource = props => {

  const forceUpdate = useForceUpdate();
  const [addTrackingUrlMacros, setAddTrackingUrlMacros] = React.useState(0);
  const [addExtraDetails, setAddExtraDetails] = React.useState(0);
  const [advertisersList,setAdvertisersList] = React.useState([])
  const [advertiser_id, setNetwork_id] = React.useState(false);
  const [api_key, setApi_key] = React.useState("");
  const [base_url, setBase_url] = React.useState("");
  const [handleExtraDetailsDict, setHandleExtraDetailsDict] = React.useState({});
  // const [extra_details, setExtra_details] = React.useState({});
  const [handleMacrosDict, setHandleMacrosDict] = React.useState({});
  // const [macros_details, setMacros_details] = React.useState({});
  const [manualUseState, setManualUseState] = React.useState(false);
  const [platform, setPlatform] = React.useState(false);
  const [platformList,setPlatformList] = React.useState([])
  const [payout_percentage, setPayout_percentage] = React.useState("70");
  const [responsePopup, setResponsePopup] = React.useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = React.useState(true);
  const [showResponsePopup, setShowResponsePopup] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  
  const [responsePopupError, setResponsePopupError] = React.useState(false);

  const [offer18Check,setOffer18Check] = React.useState(false)
  const [offerlookCheck,setOfferlookCheck] = React.useState(false)
  const [hopemobiCheck,setHopemobiCheck] = React.useState(false)
  const [orangearCheck,setOrangearCheck] = React.useState(false)
  const [affiseCheck,setAffiseCheck] = React.useState(false)
  const [fuseclickCheck,setFuseclickCheck] = React.useState(false)
  const [adsplayCheck,setAdsplayCheck] = React.useState(false)
  const [jinglemobiCheck,setJinglemobiCheck] = React.useState(false)
  const [creativeclicksCheck,setCreativeclicksCheck] = React.useState(false)
  const [huggoffersCheck,setHuggoffersCheck] = React.useState(false)
  const [totoroadxCheck,setTotoroadxCheck] = React.useState(false)
  const [inmobiCheck,setInmobiCheck] = React.useState(false)
  const [instalCheck,setInstalCheck] = React.useState(false)
  const [mocatechCheck,setMocatechCheck] = React.useState(false)
  const [everflowCheck,setEverflowCheck] = React.useState(false)
  const [gxmediaCheck,setGxmediaCheck] = React.useState(false)
  const [paddlewaverCheck,setPaddlewaverCheck] = React.useState(false)
  const [seanearCheck,setSeanearCheck] = React.useState(false)
  const [melodongCheck,setMelodongCheck] = React.useState(false)
  const [apponeCheck,setappOneCheck] = React.useState(false)
  const [gazetmCheck,setGazetmCheck] = React.useState(false)
  const [youmiCheck,setYoumiCheck] = React.useState(false)
  const [batmobileCheck,setBatmobileCheck] = React.useState(false)
  const [avazuCheck,setAvazuCheck] = React.useState(false)
  const [superadsCheck,setSuperadsCheck] = React.useState(false)
  const [bikikoCheck,setBikikoCheck] = React.useState(false)
  const [mediuminCheck,setMediuminCheck] = React.useState(false)
  const [appfloodCheck,setAppfloodCheck] = React.useState(false)
  const [admuxerCheck,setAdmuxerCheck] = React.useState(false)
  const [gourdmobiCheck,setGourdmobiCheck] = React.useState(false)
  const [accordmobiCheck,setAccordmobiCheck] = React.useState(false)
  const [mobrandCheck,setMobrandCheck] = React.useState(false)
  const [adwoolCheck,setAdwoolCheck] = React.useState(false)
  const [qverseCheck,setQverseCheck] = React.useState(false)
  const [appierCheck,setAppierCheck] = React.useState(false)
  const [tapticaCheck,setTapticaCheck] = React.useState(false)
  const [proffcussCheck,setProffcussCheck] = React.useState(false)
  const [appicmediaCheck,setAppicmediaCheck] = React.useState(false)
  const [recomobCheck,setRecomobCheck] = React.useState(false)
  const [affleCheck,setAffleCheck] = React.useState(false)
  const [rtbCheck,setRtbCheck] = React.useState(false)
  const [linkgoadsCheck,setLinkgoadsCheck] = React.useState(false)
  const [macannativeCheck,setMacannativeCheck] = React.useState(false)
  const [megableCheck,setMegableCheck] = React.useState(false)
  const [quantafiCheck,setQuantafiCheck] = React.useState(false)
  const [mobrainCheck,setMobrainCheck] = React.useState(false)
  const [mobisummerCheck,setMobisummerCheck] = React.useState(false)
  const [appsilonCheck,setAppsilonCheck] = React.useState(false)
  const [adinallCheck,setAdinallCheck] = React.useState(false)
  const [hasoffersCheck,setHasoffersCheck] = React.useState(false)
  const [promoadxCheck,setPromoadxCheck] = React.useState(false)
  const [gadmobCheck,setGadmobCheck] = React.useState(false)
  const [clicks9Check,setClicks9Check] = React.useState(false)
  
  const [disableAddButton, setDisableAddButton] = React.useState(false);

  var number =  /^-?\d+\.?\d*$/;
  
  const handleTabValue = (event, newValue) => {
    setTabValue(newValue);
  };


  const getAdvertisers = async() => {
    await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.ADVERTISER,"/","details"),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
    })
    .then(resp => {
      let advertiserlist = []
      for (var item in resp.data.result){
            advertiserlist.push({label:resp.data.result[item][Object.keys(resp.data.result[item])[0]],value:Object.keys(resp.data.result[item])[0]})
          }
        sorting(advertiserlist,'label',false,true)

        setAdvertisersList(advertiserlist)
    })
    .catch(err => {
        console.error(err);
    })
};

  const getPlatforms = async() => {
    await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"platform","/","details"),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
    })
    .then(resp => {
      let platformlist = []
      for (var item in resp.data.result){
            platformlist.push({label:resp.data.result[item][Object.keys(resp.data.result[item])[0]],value:Object.keys(resp.data.result[item])[0]})
          }
        sorting(platformlist,'label',false,true)
          setPlatformList(platformlist)
    })
    .catch(err => {
        console.error(err);
    })
  };

  const addAdvertiserRequest = async(macrosDetails,extraDetails) => {
    setDisableAddButton(true)
    await axios({
      method: 'POST',
      url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.ADD_DEMANDSOURCE),
      headers:{
          Authorization:getLocalStorageValue('adminPanelLoginData').token,
          "Content-Type":"application/json",
      },
      data:{
        platform:platform.value,
        api_key,
        base_url,
        extra_details:extraDetails,
        advertiser_id:advertiser_id.value,
        macros_details:macrosDetails,
        payout_percentage:parseFloat(payout_percentage),
        }
      })
    .then((resp)=>{
      setShowResponsePopup(true)
      if(resp.data.result && resp.data.result["demand source"])
        {          setResponsePopupError(false)
           setResponsePopup("Demand Source added successfully");}
      else{
          setResponsePopupError(true)
          setResponsePopup(resp.data.result);
        }
        setDisableAddButton(false)
      }
    )
    .catch(function (error) {
      setDisableAddButton(false)
      setResponsePopupError(true)
      if (error.response) {
        // Request made and server responded
        setResponsePopup(error.response.data.result);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else {
        // Something happened in setting up the request that triggered an Error
        setResponsePopup("Some error occured, please contact technical team.");
        console.log('Error', error.message);
      }
  
    });
  }

  if (!manualUseState){
    getAdvertisers();
    getPlatforms();
    setManualUseState(true);
  }

  const extraDetailsValidation = () => {
    var validateFlag = false
    for (var key in handleExtraDetailsDict){
      if (handleExtraDetailsDict[key].key && handleExtraDetailsDict[key].value && handleExtraDetailsDict[key].value!==""){
        validateFlag = true
      }
      else{
        validateFlag = false
        break
      }
    }
    return validateFlag
  }

  if (platform 
      && (api_key)
      && (base_url || offer18Check)
      && advertiser_id 
      && ((
        offer18Check || offerlookCheck || fuseclickCheck 
        || huggoffersCheck || totoroadxCheck || inmobiCheck 
        || mocatechCheck || gxmediaCheck
        || paddlewaverCheck || melodongCheck || seanearCheck || gazetmCheck || superadsCheck || mediuminCheck
        || accordmobiCheck || mobrandCheck || adwoolCheck || adsplayCheck || proffcussCheck || appicmediaCheck
        || recomobCheck || rtbCheck || linkgoadsCheck || megableCheck || quantafiCheck || mobrainCheck || adinallCheck
        || promoadxCheck || gadmobCheck
        ) ? extraDetailsValidation() : true)
      && payout_percentage !== "" 
      && (payout_percentage==="" || (parseFloat(payout_percentage)>=0 && parseFloat(payout_percentage)<=100))){
    if (saveButtonDisabled===true){
      setSaveButtonDisabled(false)
    }
  }
  else{
    if (saveButtonDisabled===false){
      setSaveButtonDisabled(true)
    }
  }
  

  const handleSaveAdvertiserButton = () => {
    var macrosDictVar = {}
    for (var key in handleMacrosDict){
        macrosDictVar[handleMacrosDict[key].key]=handleMacrosDict[key].value
    }
    var extraDetailsVar = {}
    for (key in handleExtraDetailsDict){
      extraDetailsVar[handleExtraDetailsDict[key].key]=handleExtraDetailsDict[key].value
    }
    // setMacros_details(macrosDictVar)
    // setExtra_details(extraDetailsVar)
    if (extraDetailsValidation){
      addAdvertiserRequest(macrosDictVar,extraDetailsVar)
    }
    else{
      setShowResponsePopup(true)
      setResponsePopup("Extra Details Value cannot be blank.");
    }
  }
  
//   const handleSaveAndContinueAdvertiserButton = () => {
//     handleTabValue("continue",(tabValue<1 ? tabValue+1 : 0))
//   }

  const showTrackingMacros = () => {
        var rows = [];
        for (var i = 0; i < addTrackingUrlMacros; i++) {
          try{
            var key = handleMacrosDict["trackingMacrosText"+i].key
            var value = handleMacrosDict["trackingMacrosText"+i].value
          }
          catch{
              key = ""
              value = ""
          }
            rows.push(
                <div style={{display:"flex",width:"30vw"}} key={"trackingMacros"+i}>
                    {affiseCheck && <TextField id={"trackingMacrosText"+i} style={{margin:"5px",marginLeft:"0px"}} defaultValue={key} fullWidth size="small" onChange={handleMacrosKey}/>}
                    {appierCheck && <TextField id={"trackingMacrosText"+i} style={{margin:"5px",marginLeft:"0px"}} defaultValue={key} fullWidth size="small" onChange={handleMacrosKey}/>}
                    {creativeclicksCheck && <TextField id={"trackingMacrosText"+i} style={{margin:"5px",marginLeft:"0px"}} defaultValue={key} fullWidth size="small" onChange={handleMacrosKey}/>}
                    {jinglemobiCheck && <TextField id={"trackingMacrosText"+i} style={{margin:"5px",marginLeft:"0px"}} defaultValue={key} fullWidth size="small" onChange={handleMacrosKey}/>}
                    {instalCheck && <TextField id={"trackingMacrosText"+i} style={{margin:"5px",marginLeft:"0px"}} defaultValue={key} fullWidth size="small" onChange={handleMacrosKey}/>}
                    {inmobiCheck && <TextField id={"trackingMacrosText"+i} style={{margin:"5px",marginLeft:"0px"}} defaultValue={key} fullWidth size="small" onChange={handleMacrosKey}/>}
                    {!inmobiCheck && !instalCheck && !jinglemobiCheck && !affiseCheck && !appierCheck && !creativeclicksCheck &&
                      <TextField id={"trackingMacrosText"+i} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleMacrosKey}/>}

                    { affiseCheck && <TextField id={"trackingMacrosText"+i} style={{margin:"5px"}} fullWidth size="small" defaultValue={value} onChange={(e)=>{handleMacrosValue(e);forceUpdate(e)}}/>}
                    { appierCheck && <TextField id={"trackingMacrosText"+i} style={{margin:"5px"}} fullWidth size="small" defaultValue={value} onChange={(e)=>{handleMacrosValue(e);forceUpdate(e)}}/>}
                    { creativeclicksCheck && <TextField id={"trackingMacrosText"+i} style={{margin:"5px"}} fullWidth size="small" defaultValue={value} onChange={(e)=>{handleMacrosValue(e);forceUpdate(e)}}/>}
                    { jinglemobiCheck && <TextField id={"trackingMacrosText"+i} style={{margin:"5px"}} fullWidth size="small" defaultValue={value} onChange={(e)=>{handleMacrosValue(e);forceUpdate(e)}}/>}
                    { instalCheck && <TextField id={"trackingMacrosText"+i} style={{margin:"5px"}} fullWidth size="small" defaultValue={value} onChange={(e)=>{handleMacrosValue(e);forceUpdate(e)}}/>}
                    { inmobiCheck && <TextField id={"trackingMacrosText"+i} style={{margin:"5px"}} fullWidth size="small" defaultValue={value} onChange={(e)=>{handleMacrosValue(e);forceUpdate(e)}}/>}
                    {!inmobiCheck && !instalCheck &&  !jinglemobiCheck && !affiseCheck && !appierCheck && !creativeclicksCheck && <TextField id={"trackingMacrosText"+i} style={{margin:"5px"}} fullWidth size="small" onChange={(e)=>{handleMacrosValue(e);forceUpdate(e)}}/>}
                    <RemoveCircleOutlineIcon style={{transform:"scale(1.4)",marginTop:"3%",color:"red",marginLeft:"2%"}} id={"trackingMacros"+i} onClick={(e)=>{removeTrackingMacros(e)}}/>
                </div>
            );
        }
        return rows;
  }

  const handleMacrosKey = (e) => {
    var id = e.target.id
    var macrosDict = handleMacrosDict

    if (macrosDict[id]){
        macrosDict[id]={...macrosDict[id],key:e.target.value}
    }
    else{
        macrosDict[id] = {key:e.target.value}
    }
    setHandleMacrosDict(handleMacrosDict)
  }

  const handleMacrosValue = (e) => {
    var id = e.target.id
    var macrosDict = handleMacrosDict

    if (macrosDict[id]){
        macrosDict[id]={...macrosDict[id],value:e.target.value.replace(/^\s+|\s+$/gm,'')}
        }
    else{
        macrosDict[id] = {value:e.target.value.replace(/^\s+|\s+$/gm,'')}
    }
    setHandleMacrosDict(macrosDict)
  }

  const removeTrackingMacros = (e) => {
    var id = e.target.id
    var elem = document.getElementById(id);
    elem.parentNode.remove(elem);
    if (handleMacrosDict[id.replace("trackingMacros","trackingMacrosText")]){
        delete handleMacrosDict[id.replace("trackingMacros","trackingMacrosText")]
    }
  }
  

  const showExtraDetails = () => {
    var rows = [];

    for (var i = 0; i < addExtraDetails; i++) {
      try{
          var key = handleExtraDetailsDict["extraDetailsText"+i].key
          var value = handleExtraDetailsDict["extraDetailsText"+i].value
          var disabled = handleExtraDetailsDict["extraDetailsText"+i].disabled ? handleExtraDetailsDict["extraDetailsText"+i].disabled : false
          var pseudoKey = handleExtraDetailsDict["extraDetailsText"+i].pseudo_key
      }
      catch{
          key = ""
          value = ""
          pseudoKey = ""
          disabled = false
      }
      rows.push(
          <div style={{display:"flex"}} key={"trackingMacros"+i}>
              { offer18Check && <TextField id={"extraDetailsText"+i} disabled={i<3 ? true : false} defaultValue={offer18Check ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { offerlookCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={offerlookCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { fuseclickCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={fuseclickCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { huggoffersCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={huggoffersCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { totoroadxCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={totoroadxCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { inmobiCheck && <TextField id={"extraDetailsText"+i} disabled={i<4 ? true : false} defaultValue={inmobiCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { mocatechCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={mocatechCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { gxmediaCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={gxmediaCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { paddlewaverCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={paddlewaverCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { melodongCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={melodongCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { seanearCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={seanearCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { gazetmCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={gazetmCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { superadsCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={superadsCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { mediuminCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={mediuminCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { accordmobiCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={accordmobiCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { mobrandCheck && <TextField id={"extraDetailsText"+i} disabled={i<2 ? true : false} defaultValue={mobrandCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { adwoolCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={adwoolCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { qverseCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={qverseCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { tapticaCheck && <TextField id={"extraDetailsText"+i} disabled={i<2 ? true : false} defaultValue={tapticaCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { adsplayCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={adsplayCheck ? pseudoKey : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { proffcussCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={proffcussCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { appicmediaCheck && <TextField id={"extraDetailsText"+i} disabled={i<2 ? true : false} defaultValue={appicmediaCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { recomobCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={recomobCheck ? i===0 ? "Affiliate" : key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { rtbCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={rtbCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { linkgoadsCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={linkgoadsCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { megableCheck && <TextField id={"extraDetailsText"+i} disabled={i<2 ? true : false} defaultValue={megableCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { quantafiCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={quantafiCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { mobrainCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={mobrainCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { adinallCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={adinallCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { promoadxCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={promoadxCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              { gadmobCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={gadmobCheck ? key : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              {!gadmobCheck && !promoadxCheck && !adinallCheck && !mobrainCheck && !quantafiCheck && !megableCheck && !linkgoadsCheck && !rtbCheck && !recomobCheck && !appicmediaCheck && !proffcussCheck && !adsplayCheck && !tapticaCheck && !qverseCheck && !accordmobiCheck && !mobrandCheck && !mediuminCheck && !adwoolCheck && !superadsCheck && !gazetmCheck && !melodongCheck && !seanearCheck && !paddlewaverCheck && !gxmediaCheck && !mocatechCheck && !inmobiCheck && !offer18Check && !offerlookCheck && !fuseclickCheck && !huggoffersCheck && !totoroadxCheck && <TextField id={"extraDetailsText"+i} defaultValue={key} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsKey}/>}
              
              { inmobiCheck && <TextField id={"extraDetailsText"+i} disabled={disabled} defaultValue={inmobiCheck ? value : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsValue}/>}
              { appicmediaCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={appicmediaCheck ? value : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsValue}/>}
              { megableCheck && <TextField id={"extraDetailsText"+i} disabled={i<1 ? true : false} defaultValue={megableCheck ? value : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsValue}/>}
              {/* { tapticaCheck && <TextField id={"extraDetailsText"+i} disabled={i<2 ? true : false} defaultValue={tapticaCheck ? value : ""} style={{margin:"5px",marginLeft:"0px"}} fullWidth size="small" onChange={handleExtraDetailsValue}/>} */}
              {!megableCheck && !appicmediaCheck && !inmobiCheck && <TextField id={"extraDetailsText"+i} defaultValue={value} style={{margin:"5px"}} fullWidth size="small" onChange={(e) => {handleExtraDetailsValue(e);forceUpdate(e)}}/>}
              {(
                (gadmobCheck && i>0) || (promoadxCheck && i>0) || (adinallCheck && i>0) || (mobrainCheck && i>0) || (quantafiCheck && i>0) || (megableCheck && i>1) || (linkgoadsCheck && i>0) || (rtbCheck && i>0) || (recomobCheck && i>0) || (appicmediaCheck && i>1) || (proffcussCheck && i>0) || (adsplayCheck && i>0) || (tapticaCheck && i>1) || (qverseCheck && i>0) || (accordmobiCheck && i>0) || (mobrandCheck && i>1) || (adwoolCheck && i>0) || (mediuminCheck && i>0) || (superadsCheck && i>0) || (gazetmCheck && i>0) ||
                (paddlewaverCheck && i>0) || (melodongCheck && i>0) || (seanearCheck && i>0) || (gxmediaCheck && i>0) || (mocatechCheck && i>0) || (inmobiCheck && i>3) 
                || (totoroadxCheck && i>0) || (huggoffersCheck && i>0) || (offer18Check && i>2) 
                || (offerlookCheck && i>0) || (fuseclickCheck && i>0) 
                    || 
                (!gadmobCheck && !promoadxCheck && !adinallCheck && !mobrainCheck && !quantafiCheck && !linkgoadsCheck && !rtbCheck && !recomobCheck && !appicmediaCheck && !proffcussCheck && !adsplayCheck && !tapticaCheck && !qverseCheck && !accordmobiCheck && !mobrandCheck && !adwoolCheck && !mediuminCheck && !superadsCheck && !gazetmCheck && !paddlewaverCheck && !melodongCheck && !seanearCheck && !gxmediaCheck && !mocatechCheck && !inmobiCheck && !totoroadxCheck && !huggoffersCheck && !offer18Check && !offerlookCheck && !fuseclickCheck)
                ) && <RemoveCircleOutlineIcon style={{transform:"scale(1.4)",marginTop:"3%",color:"red",marginLeft:"2%"}} id={"extraDetails"+i} onClick={(e)=>{removeExtraDetails(e)}}/>}
          </div>
      );
      }
    return rows;
  }

  const handleExtraDetailsKey = (e) => {
    var id = e.target.id
    var extraDetailsDict = handleExtraDetailsDict

    if (extraDetailsDict[id]){
      extraDetailsDict[id]={...extraDetailsDict[id],key:e.target.value}
    }
    else{
      extraDetailsDict[id] = {key:e.target.value}
    }
    setHandleExtraDetailsDict(handleExtraDetailsDict)
    }

  const handleExtraDetailsValue = (e) => {
    var id = e.target.id
    var extraDetailsDict = handleExtraDetailsDict

    if (extraDetailsDict[id]){
      extraDetailsDict[id]={...extraDetailsDict[id],value:e.target.value.replace(/^\s+|\s+$/gm,'')}
        }
    else{
      extraDetailsDict[id] = {value:e.target.value.replace(/^\s+|\s+$/gm,'')}
    }
    setHandleExtraDetailsDict(extraDetailsDict)
    }

  const removeExtraDetails = (e) => {
    var id = e.target.id
    var elem = document.getElementById(id);
    elem.parentNode.remove(elem);
    if (handleExtraDetailsDict[id.replace("extraDetails","extraDetailsText")]){
        delete handleExtraDetailsDict[id.replace("extraDetails","extraDetailsText")]
    }
    }

    const handleChangePlatform = (e) => {
        setPlatform(e); 
        setBase_url("");
        setHopemobiCheck(false);
        setOrangearCheck(false);
        setOfferlookCheck(false);
        setOffer18Check(false);
        setAffiseCheck(false);
        setFuseclickCheck(false);
        setAdsplayCheck(false);
        setJinglemobiCheck(false);
        setCreativeclicksCheck(false);
        setHuggoffersCheck(false);
        setTotoroadxCheck(false);
        setInmobiCheck(false);
        setInstalCheck(false);
        setMocatechCheck(false);
        setEverflowCheck(false);
        setGxmediaCheck(false);
        setPaddlewaverCheck(false);
        setSeanearCheck(false);
        setMelodongCheck(false);
        setappOneCheck(false);
        setGazetmCheck(false);
        setYoumiCheck(false);
        setBatmobileCheck(false);
        setAvazuCheck(false);
        setSuperadsCheck(false);
        setBikikoCheck(false);
        setMediuminCheck(false);
        setAppfloodCheck(false);
        setAdmuxerCheck(false);
        setGourdmobiCheck(false);
        setAccordmobiCheck(false);
        setMobrandCheck(false);
        setAdwoolCheck(false);
        setQverseCheck(false);
        setAppierCheck(false);
        setTapticaCheck(false);
        setProffcussCheck(false);
        setAppicmediaCheck(false);
        setRecomobCheck(false);
        setAffleCheck(false);
        setRtbCheck(false);
        setMacannativeCheck(false);
        setMegableCheck(false);
        setQuantafiCheck(false);
        setMobrainCheck(false);
        setMobisummerCheck(false);
        setAppsilonCheck(false);
        setAdinallCheck(false);
        setHasoffersCheck(false);
        setPromoadxCheck(false);
        setGadmobCheck(false);
        setClicks9Check(false);
        setAddExtraDetails(0);
        setHandleExtraDetailsDict({});
        setAddTrackingUrlMacros(0);
        setHandleMacrosDict({});
        forceUpdate(e);
        if (e.label === "Offer18") {
        setOffer18Check(true);
        setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "aid",
            },
            "extraDetailsText1": {
                "key": "mid",
            },
            "extraDetailsText2": {
                "key": "authorized",
                "value":"1",
            }
        })
        setBase_url("https://api.offer18.com")
        setAddExtraDetails(3);
        forceUpdate(e);
        } 
        else if (e.label === "Offerslook" || e.label === "Marversal") {
        setOfferlookCheck(true);
        setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "Username",
            },
        })
        setAddExtraDetails(1);
        forceUpdate(e);
        } 

        else if ((e.label).toLowerCase() === "adsplay") {
            setAdsplayCheck(true);
            setAddExtraDetails(0);
            setBase_url("http://affiliates.adsplay.in");
            setAddExtraDetails(1);
            setHandleExtraDetailsDict({
              "extraDetailsText0": {
                  "key": "api_aid",
                  "pseudo_key":"Affiliate ID"
              },
            })
            forceUpdate(e);
            
        } 
        else if ((e.label).toLowerCase() === "hopemobi") {
        setHopemobiCheck(true);
        // setBase_url("http://api.hopemobi.net")
        setAddExtraDetails(0);
        forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "orangear") {
        setOrangearCheck(true);
        setBase_url("")
        setAddExtraDetails(0);
        forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "affise") {
        setAffiseCheck(true);
        setAddExtraDetails(0);
        setAddTrackingUrlMacros(2);
        setHandleMacrosDict({
            "trackingMacrosText0": {
            "key": "sub1",
            "value":'{click_id}'
            },
        "trackingMacrosText1": {
            "key": "sub2",
            "value":'{sub2}'
            },
        });
        forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "taptica") {
            setTapticaCheck(true);
            setBase_url("https://api.taptica.com")
            setAddExtraDetails(0);
            forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "appier") {
        setAppierCheck(true);
        setAddTrackingUrlMacros(2);
        setHandleMacrosDict({
            "trackingMacrosText0": {
            "key": "aff_sub",
            "value":'{click_id}'
            },
        "trackingMacrosText1": {
            "key": "aff_sub2",
            "value":'{sub2}'
            },
        });
        forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "fuseclick") {
        setFuseclickCheck(true);
        setBase_url("")
        setAddExtraDetails(1);
        setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "a",
            },
        })
        forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "jinglemobi") {
        setJinglemobiCheck(true);
        setBase_url("");
        setAddTrackingUrlMacros(2);
        setHandleMacrosDict({
            "trackingMacrosText0": {
            "key": "aff_sub",
            "value":'{click_id}'
            },
        "trackingMacrosText1": {
            "key": "aff_sub2",
            "value":'{sub2}'
            },
        });
        forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "creative clicks") {
        setCreativeclicksCheck(true);
        setBase_url("");
        setAddTrackingUrlMacros(2);
        setHandleMacrosDict({
            "trackingMacrosText0": {
            "key": "s1",
            "value":'{click_id}'
            },
        "trackingMacrosText1": {
            "key": "s2",
            "value":'{sub2}'
            },
        });
        forceUpdate(e);
        }   
        else if ((e.label).toLowerCase() === "hugoffers") {
            setHuggoffersCheck(true);
            setBase_url("")
            setAddExtraDetails(1);
            setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "cid",
            },
            })
            forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "totoroadx") {
        setTotoroadxCheck(true);
        setBase_url("http://api.totoroadx.com")
        setAddExtraDetails(1);
        setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "id",
            },
        })
        forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "inmobi") {
        setInmobiCheck(true);
        setBase_url("")
        setAddExtraDetails(4);
        setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "target",
                "value":"affiliate_offer",
                "disabled":true
            },
            "extraDetailsText1": {
            "key": "method",
            "value":"findMyApprovedOffers",
            "disabled":true
            },
            "extraDetailsText2": {
            "key": "userName",
            },
            "extraDetailsText3": {
            "key": "secretKey",
            },
        });
        setAddTrackingUrlMacros(2);
        setHandleMacrosDict({
            "trackingMacrosText0": {
            "key": "aff_sub",
            "value":'{click_id}'
            },
        "trackingMacrosText1": {
            "key": "aff_sub2",
            "value":'{sub2}'
            },
        });
        forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "instal") {
        setInstalCheck(true);
        setBase_url("");
        setAddTrackingUrlMacros(1);
        setHandleMacrosDict({
            "trackingMacrosText0": {
            "key": "custom1",
            "value":'{click_id}'
            },
        });
        forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "mocatech") {
        setMocatechCheck(true);
        setBase_url("http://s2s.moca-tech.net/")
        setAddExtraDetails(1);
        setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "aff_id",
            },
        })
        forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "everflow") {
            setEverflowCheck(true);
            setBase_url("https://api.eflow.team")
            forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "gxmedia") {
            setGxmediaCheck(true);
            setBase_url("http://api.gxmedia-ads.com")
            setAddExtraDetails(1);
            setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "devapp_id",
            },
            })
            forceUpdate(e);
        } 

        else if ((e.label).toLowerCase() === "paddlewaver") {
        setPaddlewaverCheck(true);
        setBase_url("http://api.campaign.paddlewaver.com")
        setAddExtraDetails(1);
        setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "publisher_id",
            },
        })
        forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "seanear") {
            setSeanearCheck(true);
            setBase_url("http://api.seanear.com")
            setAddExtraDetails(1);
            setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "publisher_id",
            },
            })
            forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "melodong") {
            setMelodongCheck(true);
            setBase_url("http://cpiapi.melodong.com")
            setAddExtraDetails(1);
            setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "publisher_id",
            },
            })
            forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "appone") {
            setappOneCheck(true);
            setBase_url("");
            forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "gazetm") {
        setGazetmCheck(true);
        setBase_url("https://api.gazetm.com");
        setAddExtraDetails(1);
            setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "client_id",
            },
            })
        forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "youmi") {
        setYoumiCheck(true);
        setBase_url("http://ad.api.yyapi.net")
        forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "batmobi") {
        setBatmobileCheck(true);
        setBase_url("http://bulk3.batmobi.net")
        forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "avazu") {
        setAvazuCheck(true);
        setBase_url("http://api.c.avazunativeads.com")
        forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "superads") {
        setSuperadsCheck(true);
        setBase_url("http://offer.superads.cn");
        setAddExtraDetails(1);
            setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "u",
            },
            })
        forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "bikiko") {
        setBikikoCheck(true);
        setBase_url("http://offer.bikiko.com")
        forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "mediumin") {
        setMediuminCheck(true);
        setBase_url("http://api.mediumin.com");
        setAddExtraDetails(1);
            setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "aff_id",
            },
            })
        forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "appflood") {
        setAppfloodCheck(true);
        setBase_url("http://api.appflood.com")
        forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "admuxer") {
        setAdmuxerCheck(true);
        setBase_url("http://feed.admuxer.com")
        forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "gourdmobi") {
        setGourdmobiCheck(true);
        setBase_url("http://api.gourdmobi.com")
        forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "accordmobi") {
        setAccordmobiCheck(true);
        setBase_url("https://api.accordmobi.com");
        setAddExtraDetails(1);
            setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "username",
            },
            })
        forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "mobrand") {
        setMobrandCheck(true);
        setBase_url("https://api.mobrand.net");
        setAddExtraDetails(2);
            setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "source_id",
            },
            "extraDetailsText1": {
                "key": "publisher_id",
            },
            })
        forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "adwool") {
        setAdwoolCheck(true);
        setBase_url("http://api.adwool.com");
        setAddExtraDetails(1);
            setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "username",
            },
            })
        forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "qverse") {
        setQverseCheck(true);
        setBase_url("https://pub-api.qverseads.com");
        setAddExtraDetails(1);
            setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "apiClientId",
            },
            })
        forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "proffcus") {
          setProffcussCheck(true);
          setAddExtraDetails(1);
            setHandleExtraDetailsDict({
              "extraDetailsText0": {
                  "key": "secretkey",
              },
            })
          forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "appicmedia") {
          setAppicmediaCheck(true);
          setBase_url('https://api.appicmedia.com');
          setAddExtraDetails(2);
            setHandleExtraDetailsDict({
              "extraDetailsText0": {
                  "key": "status",
                  'value':'active',
              },
              "extraDetailsText1": {
                "key": "affid",
            },
            })
          forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "recomob") {
          setRecomobCheck(true);
          setBase_url('');
          setAddExtraDetails(1);
            setHandleExtraDetailsDict({
              "extraDetailsText0": {
                  "key": "a",
                  "pseudo_key":"Affiliate"
              },
            })
          forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "affle") {
          setRecomobCheck(true);
          setBase_url('https://mapi.affle.co');
          setAddExtraDetails(1);
          setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "cId",
                "value":"all"
            },
          })
          forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "rtb") {
          setRtbCheck(true);
          setApi_key("no api key required");
          setBase_url('http://cpi.rtbdem.com');
          setAddExtraDetails(1);
          setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "affid",
            },
          })
          forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "linkgoads") {
          setLinkgoadsCheck(true);
          setBase_url('http://www.linkgoads.com');
          setAddExtraDetails(1);
          setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "aff_id",
            },
          })
          forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "macannative") {
          setMacannativeCheck(true);
          setBase_url('https://macan-native.com');
          setAddExtraDetails(0);
          forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "megable") {
          setMegableCheck(true);
          setAddExtraDetails(2);
          setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "request_uri",
                "value" : "/offer/Getoffer/getOffer"
            },
            "extraDetailsText1": {
              "key": "app_id",
          },
          })
          forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "quantafi") {
          setQuantafiCheck(true);
          setAddExtraDetails(1);
          setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "username",
            },
          })
          forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "mobrain") {
          setMobrainCheck(true);
          setBase_url("https://api.mobra.in");
          setAddExtraDetails(1);
          setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "username",
            },
          })
          forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "mobisummer") {
          setMobisummerCheck(true);
          forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "appsilon") {
          setAppsilonCheck(true);
          setBase_url('https://offerapi.appsilon.kr')
          forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "adinall") {
          setAdinallCheck(true);
          setBase_url('http://g.cpi.adinall.com/');
          setAddExtraDetails(1);
          setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "aff_id",
            },
          })
          forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "hasoffers") {
          setHasoffersCheck(true);
          forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "promoadx") {
          setPromoadxCheck(true);
          setBase_url('http://sdk.promoadx.com/');
          setAddExtraDetails(1);
          setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "username",
            },
          })
          forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "gadmobe") {
          setGadmobCheck(true);
          setBase_url('http://ad.gadmobe.com/');
          setAddExtraDetails(1);
          setHandleExtraDetailsDict({
            "extraDetailsText0": {
                "key": "username",
            },
          })
          forceUpdate(e);
        } 
        else if ((e.label).toLowerCase() === "clicks9") {
          setClicks9Check(true);
          setBase_url('http://affiliates.clicks9.com/');
          forceUpdate(e);
        } 
        else {
        setHandleExtraDetailsDict({});
        setAddExtraDetails(1);
        setBase_url("");
        forceUpdate(e);
        }
    }
    
    const showImage = (item) => {
        try{
            return <img src={require('../../../img/platforms/'+item.label.toLowerCase()+'.png')} alt='' width={"80%"} height={["mocatech","seanear","youmi"].includes(item.label.toLowerCase()) ? "80%" : ""} ></img>
        }
        catch{
            return <label style={platform.value===item.value ? {color:'orange'} : {color:'grey'}}>{item.label[0]}</label>

        }
    }
    const [searchedPlatform, setSearchedPlatform] = useState("")
    const selectPlatform = () => {
        return <div style={{height:"58vh"}}>
            <div style={{paddingTop:"2%",height:"15%",display:'flex',alignItems:'center'}}><TextField style={{transform:"scale(0.8)"}} size="small" color='warning' value={searchedPlatform} placeholder="Search Platform" fullWidth onChange={(e)=>{setSearchedPlatform(e.target.value)}}/></div>
            <div style={{display:'flex',flexWrap:'wrap',padding:"1%",maxHeight:"85%",overflowY:"auto",justifyContent:'center',paddingLeft:'2%',paddingRight:'2%'}}>
                {platformList.map((item,index)=>{
                    if(searchedPlatform==="" || item.label.toLowerCase().includes(searchedPlatform.toLowerCase())){
                        return <div key={index} className={platform.value===item.value ? 'platformIconSelected' : 'platformIcon'} onClick={()=>{handleChangePlatform(item)}}>
                                <div style={{display:'flex',height:"80%",width:'150px',fontSize:"48px",justifyContent:'center',alignItems:'center'}}>
                                    {showImage(item)}
                                </div>
                                <div style={{display:'flex',height:"20%",justifyContent:'center',fontSize:"14px",alignItems:'center'}}>
                                    {item.label}
                                </div>
                                
                                </div>
                    }
                    return null;
                })}
            </div>
        </div>
    }

  const general = () => {
    return(
      <div style={{display:"flex"}}>
        <div style={{height:"100%",overflowY:"auto",marginLeft:"1%",width:"60%",marginTop:"2%"}} onKeyDown={(e)=>{forceUpdate(e)}}>
          {platform && 
          <>
            <div style={{display:"flex"}}>
              <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"30%"}}>Platform</div>
              <div style={{transform:"scale(0.8)",width:"70%"}}><TextField fullWidth size="small" value={platform.label} disabled/></div>
            </div>
            {!rtbCheck &&
              <div style={{display:"flex"}}>
                <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"30%"}}>API Key *</div>
                <div style={{transform:"scale(0.8)",width:"70%"}}><TextField fullWidth size="small" value={api_key} onChange={(e)=>{setApi_key(e.target.value.replace(/^\s+|\s+$/gm,''))}}/></div>
              </div>
            }
            {
              !offer18Check && !adsplayCheck && !totoroadxCheck && !mocatechCheck && !everflowCheck && !gxmediaCheck &&
              !paddlewaverCheck && !seanearCheck && !melodongCheck && !gazetmCheck && !youmiCheck && !batmobileCheck && 
              !avazuCheck && !superadsCheck && !bikikoCheck && !mediuminCheck && !appfloodCheck && !admuxerCheck &&
              !gourdmobiCheck && !accordmobiCheck && !mobrandCheck && !adwoolCheck && !qverseCheck && !tapticaCheck &&
              !appicmediaCheck && !affleCheck && !rtbCheck && !linkgoadsCheck && !macannativeCheck && !mobrainCheck && !appsilonCheck && !adinallCheck &&
              !promoadxCheck && !gadmobCheck && !clicks9Check &&
              <div style={{display:"flex"}}>
                <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"30%"}}>Base Url *</div>
                <div style={{transform:"scale(0.8)",width:"70%"}}><TextField fullWidth size="small" value={base_url} onChange={(e)=>{setBase_url(e.target.value.replace(/^\s+|\s+$/gm,''))}}/></div>
              </div>
            }
            <div style={{display:"flex"}}>
              <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"30%"}}>Advertiser ID *</div>
              <div style={{transform:"scale(0.8)",width:"70%"}}>
                {!advertiser_id && <Select options={advertisersList}
                    defaultValue={advertiser_id ? {label:advertiser_id.label,value:advertiser_id.value} : null}
                    onChange={(e)=>{setNetwork_id(e)}}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999}),
                            option: styles => ({...styles,fontSize:"10px"}),
                    }}/>}
                {advertiser_id && <Select options={advertisersList}
                    defaultValue={advertiser_id ? {label:advertiser_id.label,value:advertiser_id.value} : null}
                    onChange={(e)=>{setNetwork_id(e)}}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999}),
                            option: styles => ({...styles,fontSize:"10px"}),
                    }}/>}
              </div>
            </div>
            <div style={{display:"flex"}}>
              <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"30%"}}>Payout Percentage</div>
              <div style={{transform:"scale(0.8)",width:"70%"}}><TextField fullWidth size="small" defaultValue={payout_percentage} onChange={(e)=>{setPayout_percentage(e.target.value.replace(/^\s+|\s+$/gm,''))}}/></div>
              {(payout_percentage ? !(parseFloat(payout_percentage)>=0 && parseFloat(payout_percentage)<=100) || !payout_percentage.match(number) ? <div style={{fontSize:"11px",display:"flex",alignItems:"center",color:"red"}}> Payout percentage in incorrect form.</div> : null  : null)}
            </div>
            { !hopemobiCheck && !orangearCheck && !affiseCheck && 
              !creativeclicksCheck && !jinglemobiCheck && !instalCheck && 
              !everflowCheck && !apponeCheck && !youmiCheck && !batmobileCheck &&
              !avazuCheck && !bikikoCheck && !appfloodCheck && !admuxerCheck &&
              !gourdmobiCheck && !tapticaCheck && !affleCheck && !macannativeCheck && !mobisummerCheck && !hasoffersCheck &&
              !clicks9Check &&
              <div style={{display:"flex"}}>
                <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"30%"}}>Extra API Parameters{(offer18Check || offerlookCheck) && " *"}</div>
                <div style={{transform:"scale(0.8)",width:"70%"}}>
                  {
                      showExtraDetails()
                  }
                <div style={{color:"green",marginTop:"2%",width:"40%"}} onClick={()=>{setAddExtraDetails(addExtraDetails+1)}}><AddCircleOutlineIcon/></div>
                </div>
              </div>
            }
          </>  
          }
          
        </div>
        {offerlookCheck
        &&
        <div style={{height:"100%",marginLeft:"1%",width:"30%",marginTop:"5%"}}>
          <div style={{minHeight:"100px",background:"rgb(248,195,85)",width:"auto",padding:"10px",borderRadius:"5%"}}>
              <div style={{paddingBottom:"10px",fontSize:"15px"}}>Please add our IPs to whitelist:</div>
            {process.env.REACT_APP_OFFERLOOK_IPS.split(",").map((item,index)=>{
              return <div key={{index}} style={{paddingLeft:"10px",paddingBottom:"2px",fontSize:"13px"}}>&rarr;&nbsp;&nbsp;&nbsp;{item}</div>
            })}
          </div>
        </div>
        }
        {fuseclickCheck
        &&
        <div style={{height:"100%",marginLeft:"1%",width:"30%",marginTop:"5%"}}>
          <div style={{minHeight:"100px",background:"rgb(248,195,85)",width:"auto",padding:"10px",borderRadius:"5%"}}>
              <div style={{paddingBottom:"10px",fontSize:"15px"}}>Please add our IPs to whitelist:</div>
            {process.env.REACT_APP_FUSECLICK_IPS.split(",").map((item,index)=>{
              return <div key={{index}} style={{paddingLeft:"10px",paddingBottom:"2px",fontSize:"13px"}}>&rarr;&nbsp;&nbsp;&nbsp;{item}</div>
            })}
          </div>
        </div>
        }
      </div>
    )
  }

  const trackingMacros = () => {
    return(
      <div style={{display:"flex"}}>
        <div style={{height:"100%",overflowY:"auto",marginLeft:"1%",width:"60%",marginTop:"2%"}} onKeyDown={(e)=>{forceUpdate(e)}}>
          {platform && 
            <div style={{display:"flex"}}>
              <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"40%"}}><label>Tracking Url Params <label style={{color:"black"}}>&nbsp;Show macros</label></label></div>
              <div style={{transform:"scale(0.8)",width:"43%"}}>
                {
                    showTrackingMacros()
                }
              <div style={{color:"green",marginTop:"2%",width:"40%"}} onClick={()=>{setAddTrackingUrlMacros(addTrackingUrlMacros+1)}}><AddCircleOutlineIcon/></div>
              </div>
            </div>
          }
          
        </div>
      </div>
    )
  }

  return (
    <div className="row">
        <div className="columnData" style={{width:"95%",height:"80vh",background:"white"}}>
            <div style={{background:"white",width:"auto",height:"8%"}}>
            {/* <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}> */}
                <Tabs 
                TabIndicatorProps={{style: {background:'orange',backgroundColor:'orange'}}}
                value={tabValue} 
                onChange={handleTabValue} 
                aria-label="basic tabs example">
                <Tab label={<span className={tabValue===0? 'Active' : 'Inactive'}>Select Platform</span>}/>
                {platform && <Tab label={<span className={tabValue===1? 'Active' : 'Inactive'}>General</span>}/>}
                {platform && !saveButtonDisabled && <Tab label={<span className={tabValue===2? 'Active' : 'Inactive'}>Tracking Macros</span>}/>}
                </Tabs>
            {/* </Box>
            </Box> */}
            
            </div>
            <div style={{height:"81%",width:"100%",background:"white",overflowY:"auto"}}>
            <TabPanel style={{height:'100%'}} value={tabValue} index={0}>
                {selectPlatform()}
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                {general()}
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                {trackingMacros()}
            </TabPanel>
            </div>

            <div style={{display:"flex",alignItems:"center",height:"11%",width:"90%"}}>
              <div style={{marginLeft:"20%"}}>
                {(tabValue===0) && <Button variant="contained" color="warning" onClick={()=>{setTabValue(tabValue+1)}} disabled={platform ? false : true}>Next</Button>}
                {(tabValue===1) && <Button variant="contained" color="warning" onClick={()=>{setTabValue(tabValue+1)}} disabled={saveButtonDisabled}>Next</Button>}
                {tabValue===2 && <Button variant="contained" color="warning" onClick={handleSaveAdvertiserButton} disabled={saveButtonDisabled || disableAddButton}>ADD</Button>}
              </div>
              <div style={{marginLeft:"2%"}}>
              <NavLink style={{textDecoration:"none",color:"black"}} to={"/demandsource/management"}>
                  <Button variant="text">Cancel</Button>
                </NavLink>           
              </div>
            </div>
      </div>
      {showResponsePopup && 
          <div className="popup-box">
            <div className="box-response">
              <div className="popupHeader" >{"RESULT"}</div>
              <div style={{borderBottom:"2px solid orange"}}></div>
                <div className="popupData">
                  {responsePopup}
                </div>
                {
                  !responsePopupError && 
                    <NavLink style={{textDecoration:"none",color:"black"}} to={"/demandsource/management"}>
                      <div className="close-icon-response" onClick={()=>{setShowResponsePopup(false)}}>X</div>
                    </NavLink>
                    
                  }
                {
                  responsePopupError &&
                    <div className="close-icon-response" onClick={()=>{setShowResponsePopup(false)}}>X</div>
                } 
            </div>
          </div>
      }
    </div>
  );
};
 
export default NewDemandSource;