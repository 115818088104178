import { Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import ReactSelect from 'react-select';
import { increase_brightness } from '../../components/customization/customization';
import '../../css/pages/v2/advertiser.css';
import * as Constants from '../../data/constants';
import { capitalize, getLocalStorageValue, getRequests, useForceUpdate } from '../../util/util';
import { Store } from 'react-notifications-component';
import { NavLink } from 'react-router-dom';
import { getAffiliates } from '../../apis/dropdown/dropdowns';
import CreatableSelect from 'react-select/creatable';
import CopyButton from '../../components/copyButton';
import { LiveHelpRounded } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';

const AddEditSmartLink = () => {
    const refreshComponent = useForceUpdate()
    if(window.location.href.includes('/edit')){
        var tempAdvertiserId = window.location.href.split('/edit/')[1]
    }
    else{
        tempAdvertiserId = false
    }
    if(tempAdvertiserId){
        document.title = 'Edit Smartlink - '+tempAdvertiserId
    }
    else{
        document.title = 'Add Smartlink'
    }

    const [advertiserId, setAdvertiserId] = useState(tempAdvertiserId)
    const [manualUseEffect, setManualUseEffect] = useState(false)
    const [affiliateOptions, setAffiliateOptions] = useState([])
    const [response, setResponse] = useState({privacy:{label:'Private',value:'private'}})
    const [buttonLoader, setButtonLoader] = useState(false)
    const [weightedOfferId, setWeightedOfferIdData] = useState([])
    const [generatedLink, setGeneratedLink] = useState('')

    const getAdvertiserDetails = async() => {
        setButtonLoader(true)
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'smartlink/',advertiserId),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        }
        )
        .then((resp) => {
            var responseResult = resp.data.result
            checkValidation('name',responseResult['name'])

            var disabled_affiliates = responseResult.disabled_affiliates.map((item,index)=>{
                return {label:item.split(") ")[1]+ " - "+ item.split(") ")[0].split("(")[1],value:item.split(") ")[0].split("(")[1]}
            })
            var enabled_affiliates = responseResult.enabled_affiliates.map((item,index)=>{
                return {label:item.split(") ")[1]+ " - "+ item.split(") ")[0].split("(")[1],value:item.split(") ")[0].split("(")[1]}
            })
            var offers = responseResult.offers.map((item,index)=>{
                return {label:item.split(") ")[1] + " - "+ item.split(") ")[0].split("(")[1],value:item.split(") ")[0].split("(")[1]}
            })
            
            var tempDistributedData = responseResult.offers.map((item,index)=>{
                var percentage = ''
                var offer_id = item.split(") ")[0].split("(")[1]
                if(responseResult.distribution_percentage && (responseResult.distribution_percentage[offer_id] || responseResult.distribution_percentage[offer_id]===0)){
                    percentage = responseResult.distribution_percentage[offer_id]
                }
                return {offer_id,percentage}
            })
            
            var tempDistributedType = {label:responseResult.get_distribution_type_display,value:responseResult.get_distribution_type_display}
            setWeightedOfferIdData(tempDistributedData)
            var privacy = {label:capitalize(responseResult.privacy),value:responseResult.privacy}
            responseResult = {...responseResult,privacy,disabled_affiliates,enabled_affiliates,offers,distribution_type:tempDistributedType}
            setResponse(responseResult)
            setButtonLoader(false)
        })
        .catch(error => {
            console.log(error)
            if (error.response) {
                // Request made and server responded
                console.log(error.response.status);
                console.log(error.response.headers);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        });
    }

    if(!manualUseEffect){
        setManualUseEffect(true);
        getRequests(getAffiliates(),setAffiliateOptions)
        if(advertiserId){
            getAdvertiserDetails()
        }
    }

    const changeWeightedOfferId = (e,key,index) => {
        var tempWeightedOfferList = weightedOfferId
        if(key==='percentage'){
            var percentageLeft = 100
            for(var compareIndex in tempWeightedOfferList){
                compareIndex = parseInt(compareIndex)
                index = parseInt(index)
                if(compareIndex!==index){
                    if(compareIndex<index){
                        percentageLeft = percentageLeft - tempWeightedOfferList[compareIndex].percentage
                    }
                    else{
                        if(percentageLeft>0){
                            tempWeightedOfferList[compareIndex].percentage = Math.round(percentageLeft/(tempWeightedOfferList.length - compareIndex))
                            percentageLeft = percentageLeft - Math.round(percentageLeft/(tempWeightedOfferList.length - compareIndex))
                        }
                        else{
                            tempWeightedOfferList[compareIndex].percentage = 0
                        }
                    }
                }
                if(compareIndex === index){
                    var x = e.target.value
                    if(x===''){
                        x='0'
                    }
                    if(x.length>1 && x[0]==='0'){
                        x = x.slice(1,x.length)
                    }
                    
                    if(!x.match(/(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/) 
                        // && (x.slice(0,-1).includes('.') || x[x.length-1]!=='.')
                        ||
                        parseInt(x)>percentageLeft
                        ){
                        if(x.length>0){
                            x = x.slice(0,-1)
                        }
                    }
                    percentageLeft = percentageLeft - x
                }
            }
        }
        else{
            x = e.target.value
        }
        tempWeightedOfferList[index][key] = x
        setWeightedOfferIdData(tempWeightedOfferList)
        refreshComponent(123)
    }

    const distributionPercentageComponent = (childItem) => {
        if(response.distribution_type && response.distribution_type.value==='Weighted'){
            return <div className='advertiserPageGeneralDataWrapper' key={childItem.label}>
                        
                        <div className='addEditAdvertiserPageLabel' style={{display:'flex',textAlign:'left'}}>
                            {childItem.label}{childItem.mandatory ? '*' : ''}
                            <Tooltip title='Sum of all offers percentage must be 100'>
                            <div>
                                <LiveHelpRounded sx={{fontSize:'12px'}}/>
                            </div>
                        </Tooltip>
                        </div>
                        <div style={{marginLeft:'4px',width:'69.5%'}}>
                            {weightedOfferId.map((item,index)=>{
                                return <div key={index} style={{display:'flex',position:'relative'}}>
                                    <div style={{width:'60%'}}>
                                        <Constants.CssTextField value={item.offer_id} onChange={(e)=>{changeWeightedOfferId(e,'offer_id',index)}} size='small' label='Offer ID' sx={{transform:'scale(0.8)'}}/>
                                    </div>
                                    <div style={{width:'40%'}}>
                                        <Constants.CssTextField value={item.percentage} onChange={(e)=>{changeWeightedOfferId(e,'percentage',index)}} size='small' label='Percentage' sx={{transform:'scale(0.8)'}}/>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
        }
    }
    const distributionSelectionComponent = (childItem) => {
        return <div className='advertiserPageGeneralDataWrapper' key='distruadasd'>
                    
                    <div className='addEditAdvertiserPageLabel' style={{display:'flex',textAlign:'left'}}>
                        {childItem.label}{childItem.mandatory ? '*' : ''}
                        <Tooltip title='Random: links will be redirected randomly, 
                                        EPC: links will be redirected according to earnings, 
                                        Weighted: Links will be redirected according to percentage.
                                        '>
                            <div>
                                <LiveHelpRounded sx={{fontSize:'12px'}}/>
                            </div>
                        </Tooltip>
                    </div>
                    <div style={{marginLeft:'20px',width:'69.5%'}}>
                        <ReactSelect
                            isDisabled={buttonLoader}
                            options={childItem.options}
                            isMulti={childItem.isMulti}
                            value={response[childItem.value]}
                            onChange={(e)=>{setResponse({...response,[childItem.value]:e});
                                            if(e.value==='Weighted'){
                                                var tempOfferIdData = []
                                                for(var index in weightedOfferId){
                                                    var item = weightedOfferId[index]
                                                    var percentage = Math.round(100/weightedOfferId.length)
                                                    if(parseInt(index)===weightedOfferId.length-1){
                                                        percentage = 100 - ((weightedOfferId.length-1) * Math.round(100/weightedOfferId.length))
                                                    }
                                                    item.percentage = percentage
                                                    tempOfferIdData.push(item)
                                                }
                                                setWeightedOfferIdData(tempOfferIdData)
                                                refreshComponent(21)
                                            }

                                            }}
                            menuPortalTarget={document.body} 
                            placeholder={"Select "+childItem.label}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 1000000 }),
                                        control: (base,state) => ({
                                            ...base,
                                            // maxHeight: 100,
                                            minHeight: 30,
                                            color:'rgb(200,200,200)',
                                            overflowY: "auto",
                                            fontSize:12,
                                            border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                            boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                            '&:hover': {
                                                border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                            }
                                        }),
                                        placeholder: (defaultStyles) => {
                                            return {
                                                ...defaultStyles,
                                                color: 'rgb(150,150,150)',
                                                marginTop:'-2px'
                                            }
                                        },
                                        option: (styles, {isFocused, isSelected}) => ({
                                            ...styles,
                                            fontSize:'11px',
                                            background: isFocused
                                                ? localStorage.getItem('ambientColor')
                                                : isSelected
                                                    ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                    : undefined,
                                            zIndex: 1
                                        }),
                                    }}
                        />
                    </div>
                </div>
        
    }

    useEffect(() => {
        document.addEventListener('click',(e)=>{
            setShowMacrosClicked(false);
        })
        
        return () => {
            document.removeEventListener('click',(e)=>{
                setShowMacrosClicked(false);
            })
        }
    }, [])
    
    const [showMacrosClicked, setShowMacrosClicked] = useState(false)
    const generateLink = () => {
        var macrosDetails = {
            "{click_id}" :  "Click ID",
            "{sub1}" :  "SubId #1",
            "{sub2}" :  "SubId #2",
            "{sub3}" :  "SubId #3",
            "{sub4}" :  "SubId #4",
            "{sub5}" :  "SubId #5",
            "{sub6}" :  "SubId #6",
            "{sub7}" :  "SubId #7",
            "{sub8}" :  "SubId #8",
            "{offer_id}" :  "Offer ID",
            "{aff_id}" :  "Affiliate ID",
            "{offer_name}" :  "Offer title",
            }
        const generateLinkFunction = () => {
            var generatedLink = process.env.REACT_APP_TARGETING_URL_SMARTLINK+'?aff_id='+response.selected_affiliates.value+"&sl_id="+advertiserId
            setGeneratedLink(generatedLink)
            refreshComponent(2123);
        }
        return <div className='advertiserPageGeneralDataWrapper' style={{position:'relative'}} key='distruadasd'>
                    <div className='addEditAdvertiserPageLabel'>
                        Generate Link
                    </div>
                    <div style={{width:'70%',marginLeft:'5%',display:'flex',position:'relative',alignItems:'center',flexWrap:'wrap'}}>
                        <div style={{width:'100%',position:'relative',display:'flex',alignItems:'center',marginRight:'-18%'}}>
                            <Constants.CssTextField value={generatedLink} fullWidth size='small' placeholder='Generated Link' sx={{transform:'scale(0.8)',transformOrigin:'left'}}/>
                            <div style={{position:'absolute',right:'20%',transform:'scale(0.6)'}}>
                                <CopyButton valueToCopy={generatedLink}/>
                            </div>
                        </div>
                        <Button sx={{...Constants.OUTLINEDBUTTON,fontSize:'12px',minWidth:'120px',transform:'scale(0.75)',transformOrigin:'left'}} onClick={generateLinkFunction}>Generate Link</Button>
                        <div style={{fontSize:'13px',color:localStorage.getItem('ambientColor'),cursor:'pointer'}} onClick={(e)=>{e.stopPropagation();setShowMacrosClicked(!showMacrosClicked)}}>
                            Show Macros
                            {showMacrosClicked && 
                                <div style={{position:'absolute',paddingTop:'10px',marginTop:'-290px',color:'black',zIndex:'10'}}>
                                    <div style={{minHeight:'200px',minWidth:'100px',width:'max-content',padding:'10px',background:'white',boxShadow:'0 0 5px 1px grey'}} onClick={(e)=>{e.stopPropagation();}}>
                                        <table>
                                            <tbody>
                                            {Object.keys(macrosDetails).map((item,index)=>{
                                                return <tr style={{fontSize:'12px'}} key={index}>
                                                            <td style={{textAlign:'center',paddingTop:'2px'}}>{item}</td> 
                                                            <td>-</td> 
                                                            <td style={{paddingLeft:'10px'}}>{macrosDetails[item]}</td>
                                                        </tr>
                                            })}
                                            </tbody>
                                        </table>
                                        
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
    }
    const updateWeightedOffers = (e) => {
        var newList = []
        var totalPercentage = 100
        for(var inputIndex in e){
            var percentage = Math.round(100/e.length)
            if(percentage<totalPercentage){
                totalPercentage = totalPercentage - percentage
            }
            else{
                percentage = totalPercentage
            }
            newList.push({offer_id:e[inputIndex].value,percentage:percentage})  
        }
        setWeightedOfferIdData(newList)

    }
    var formData = [
        {
            label:'General',
            width:'65%',
            child:[
                {label:'Smartlink Name',value:'name',type:'textfield',mandatory:true},
                {label:'Description',value:'description',type:'textarea',mandatory:false},
                {label:'Privacy',value:'privacy',type:'select',mandatory:true,options:[{label:'Private',value:'private'},{label:'Public',value:'public'},{label:'Request',value:'protected'},]},
                {label:'Enabled Affiliates',value:'enabled_affiliates',isMulti:true,type:'select',mandatory:false,options:affiliateOptions},
                {label:'Disabled Affiliates',value:'disabled_affiliates',isMulti:true,type:'select',mandatory:false,options:affiliateOptions},
                {label:'Offers',value:'offers',type:'select',isMulti:true,isCreatable:true,mandatory:false,customFunction:updateWeightedOffers},
            ]
        },
        {
            label:'Distribution',
            width:'35%',
            child:[
                {label:'Distribution Type',value:'distribution_type',customComponent:distributionSelectionComponent,options:[{label:'Random',value:'Random'},{label:'EPC',value:'EPC'},{label:'Weighted',value:'Weighted'}]},
                {label:'Distribution Percentage',value:'distribution_percentage',customComponent:distributionPercentageComponent},
            ]
        },
        {
            label:'Link Generator',
            width:'100%',
            child:[
                {label:'Affiliates',value:'selected_affiliates',type:'select',options:affiliateOptions},
                {label:'Generated Link',value:'generated_link',customComponent:generateLink},
            ]
        },
    ]
    

    const addEditAdvertiserRequest = async() => {
        setButtonLoader(true)
        var name = response.name
        var description = response.description
        var privacy = response.privacy.value
        var disabled_affiliates = response.disabled_affiliates ? response.disabled_affiliates.map((item,index)=>{return parseInt(item.value)}) : []
        var enabled_affiliates = response.enabled_affiliates ? response.enabled_affiliates.map((item,index)=>{return parseInt(item.value)}) : []
        var offers = response.offers ? response.offers.map((item,index)=>{return parseInt(item.value)}) : []
        var distribution_type = response.distribution_type ? response.distribution_type.value : []
        var distribution_percentage = {}
        if(distribution_type==='Weighted'){
            for(var offerIdIndex in weightedOfferId){
                distribution_percentage[parseInt(weightedOfferId[offerIdIndex].offer_id)] = weightedOfferId[offerIdIndex].percentage ? parseInt(weightedOfferId[offerIdIndex].percentage) : 0
            }
        }
        var url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'smartlinks/add')
        if(advertiserId){
            url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'smartlink',"/",advertiserId,"/",Constants.CHANGE)
        }
        await axios({
            method: 'POST',
            url,
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":"application/json",
            },
            data:{
                name,
                description,
                privacy,
                disabled_affiliates,
                enabled_affiliates,
                offers,
                distribution_type,
                distribution_percentage,
            }
            })
            .then((resp)=>{
                var responseString = 'added'
                if(advertiserId){
                    responseString = 'edited'
                }
                if(resp.data.result && resp.data.result.id){
                    setAdvertiserId(resp.data.result.id)
                    Store.addNotification({
                        title: "Success!",
                        message: "Smartlink "+responseString+" successfully.",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true
                        }
                    });
                }
                else{
                    Store.addNotification({
                        title: "Error!",
                        message: typeof resp.data.result === "string" ? resp.data.result : "Some error occured",
                        type: "warning",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true
                        }
                    });
                }

            })
            .catch(function (error) {
                if (error.response) {
                    if(error.response.data){
                        Store.addNotification({
                            title: "Error!",
                            message: typeof error.response.data.result === "string" ? error.response.data.result : "Some error occured",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 3000,
                                onScreen: true
                            }
                        });
                    }
                    // Request made and server responded
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
        });
        setButtonLoader(false);
    };

    const [disableButton, setDisableButton] = useState(true)
    const [validationFields, setValidationFields] = useState({
        name : false,
        privacy : true,
    })
    const checkValidation = (key,value,returnPlease) => {
        const specialCharacter = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
        const number = /[0-9]/g;
        const email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const skype_regex = /[a-zA-Z][a-zA-Z0-9.,\-_]{5,31}/;
        const website_regex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
        var validationCheck = false;
        if(key==='name'){
            if(value===''){
                validationCheck = false
            }
            else{
                validationCheck = !value.match(specialCharacter) 
            }
        }
        else if(key==='contact_person'){
            if(value===''){
                validationCheck = false
            }
            else{
                validationCheck = true
            }
        }
        else if(key==='email' && value!==''){
            validationCheck = value.match(email_regex)
        }
        else if(key==='contact_number' && value!==''){
            validationCheck = value.match(number)
        }
        else if(key==='website' && value!==''){
            validationCheck = value.match(website_regex)
        } 
        else if(key==='skype_id' && value!==''){
            validationCheck = value.match(skype_regex)
        }
        else{
            validationCheck = true
        }
        var tempValidationFields = returnPlease ? returnPlease :validationFields
        if(tempValidationFields[key] || tempValidationFields[key]===false){
            tempValidationFields = {...tempValidationFields,[key]:validationCheck ? true : false}
        }
        setValidationFields(tempValidationFields)
        var disableButton = false
        for(var index in tempValidationFields){
            if(!tempValidationFields[index]){
                disableButton = true
                break;
            }
        }
        setDisableButton(disableButton)
        if(returnPlease){
            return tempValidationFields
        }
    }

    return (
        <div className='advertiserPageV2'>
            <div style={{fontSize:'18px',fontWeight:600,display:'flex',alignItems:'center'}}>
                {advertiserId ? 'Edit ' : 'Add '} Smartlink
                {buttonLoader && <CircularProgress sx={{color:localStorage.getItem('ambientColor'),marginLeft:'20px'}} size={17}/>}
            </div>
            <div className='advertiserPageDataContainer'>
                {formData.map((item,index)=>{
                    return <div key={index} className='advertiserPageDataWrapperMobile' style={{width:item.width}}>
                                <div className='advertiserPageDataWrapper'>
                                    <div className='advertiserPageDataHeader'>{item.label}</div>
                                    <div className='advertiserPageGeneralDataContainer'>
                                        {item.child.map((childItem,childIndex) => {
                                            if(childItem.customComponent){
                                                return childItem.customComponent(childItem)
                                            }
                                            return <div className='advertiserPageGeneralDataWrapper' key={childIndex}>
                                                    {!childItem.hideLabel && <div className='addEditAdvertiserPageLabel'>{childItem.label}{childItem.mandatory ? '*' : ''}</div>}
                                                    {childItem.type==='textfield' && 
                                                        <input id={childItem.value} className={(childItem.mandatory && !response[childItem.value]) || (childItem.mandatory && validationFields[childItem.value]===false) || (!childItem.mandatory && response[childItem.value] && validationFields[childItem.value]===false) ? 'addEditAdvertiserPageTextField invalidAddEditAdvertiserPageTextField' : 'addEditAdvertiserPageTextField'} style={ !childItem.hideLabel ? {} : {width:'100%'}} disabled={childItem.disabled || buttonLoader} value={response[childItem.value] ? response[childItem.value] : ''} onChange={(e)=>{checkValidation(childItem.value,e.target.value);setResponse({...response,[childItem.value]:e.target.value})}}/>
                                                    }
                                                    {childItem.type==='select' && childItem.isCreatable && 
                                                        <div id={childItem.value} className='addEditAdvertiserPageSelectField' style={{width:'66.5%'}}>
                                                            <CreatableSelect
                                                                isDisabled={buttonLoader}
                                                                options={childItem.options}
                                                                isMulti={childItem.isMulti}
                                                                value={response[childItem.value]}
                                                                onChange={(e)=>{setResponse({...response,[childItem.value]:e});if(childItem.customFunction){childItem.customFunction(e)};}}
                                                                menuPortalTarget={document.body} 
                                                                placeholder={"Select "+childItem.label}
                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 1000000 }),
                                                                            control: (base,state) => ({
                                                                                ...base,
                                                                                // maxHeight: 100,
                                                                                minHeight: 30,
                                                                                color:'rgb(200,200,200)',
                                                                                overflowY: "auto",
                                                                                fontSize:12,
                                                                                border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                                                boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                                                                '&:hover': {
                                                                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                                                    boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                                                                }
                                                                            }),
                                                                            placeholder: (defaultStyles) => {
                                                                                return {
                                                                                    ...defaultStyles,
                                                                                    color: 'rgb(150,150,150)',
                                                                                    marginTop:'-2px'
                                                                                }
                                                                            },
                                                                            option: (styles, {isFocused, isSelected}) => ({
                                                                                ...styles,
                                                                                fontSize:'11px',
                                                                                background: isFocused
                                                                                    ? localStorage.getItem('ambientColor')
                                                                                    : isSelected
                                                                                        ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                                        : undefined,
                                                                                zIndex: 1
                                                                            }),
                                                                        }}
                                                            />
                                                        </div>
                                                        }
                                                    {childItem.type==='select' && !childItem.isCreatable && 
                                                        <div id={childItem.value} className='addEditAdvertiserPageSelectField' style={{width:'66.5%'}}>
                                                            <ReactSelect
                                                                isDisabled={buttonLoader}
                                                                options={childItem.options}
                                                                isMulti={childItem.isMulti}
                                                                value={response[childItem.value]}
                                                                onChange={(e)=>{setResponse({...response,[childItem.value]:e})}}
                                                                menuPortalTarget={document.body} 
                                                                placeholder={"Select "+childItem.label}
                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 1000000 }),
                                                                            control: (base,state) => ({
                                                                                ...base,
                                                                                // maxHeight: 100,
                                                                                minHeight: 30,
                                                                                color:'rgb(200,200,200)',
                                                                                overflowY: "auto",
                                                                                fontSize:12,
                                                                                border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                                                boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                                                                '&:hover': {
                                                                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                                                    boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                                                                }
                                                                            }),
                                                                            placeholder: (defaultStyles) => {
                                                                                return {
                                                                                    ...defaultStyles,
                                                                                    color: 'rgb(150,150,150)',
                                                                                    marginTop:'-2px'
                                                                                }
                                                                            },
                                                                            option: (styles, {isFocused, isSelected}) => ({
                                                                                ...styles,
                                                                                fontSize:'11px',
                                                                                background: isFocused
                                                                                    ? localStorage.getItem('ambientColor')
                                                                                    : isSelected
                                                                                        ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                                        : undefined,
                                                                                zIndex: 1
                                                                            }),
                                                                        }}
                                                            />
                                                        </div>
                                                    }
                                                    {childItem.type==='textarea' && 
                                                        <textarea id={childItem.value} className='addEditAdvertiserPageTextField' style={ !childItem.hideLabel ? {height:'200px'} : {width:'99%',height:'200px'}} value={response[childItem.value] ? response[childItem.value] : ''} onChange={(e)=>{setResponse({...response,[childItem.value]:e.target.value})}} disabled={childItem.disabled}/>
                                                    }
                                                </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                })}
            </div>
            <div className='advertiserPageButtonPallete'>
                <Button disabled={disableButton || buttonLoader} sx={{...Constants.CONTAINEDBUTTON,fontSize:'15px',paddingLeft:'15px',paddingRight:'15px',textTransform:'none',fontWeight:500,marginLeft:'15px',width:'40px',height:'30px'}} onClick={addEditAdvertiserRequest}>
                    {!buttonLoader && (advertiserId ? 'Edit' : 'Add')}
                    {buttonLoader && <CircularProgress sx={{color:'white'}} size={18}/>}
                </Button>
                <NavLink to={'/smartlinks'} style={{textDecoration:'none'}}>
                    <Button sx={{...Constants.OUTLINEDBUTTON,fontSize:'15px',paddingLeft:'15px',paddingRight:'15px',textTransform:'none',fontWeight:500,marginLeft:'15px',height:'30px'}}>Cancel</Button>
                </NavLink>
            </div>
        </div>
    )
}

export default AddEditSmartLink