import React, { useEffect, useCallback, useState } from 'react'
import axios from 'axios';

import * as Constants from '../data/constants';

import '../css/components/rightBar.css';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import LoginAffiliate from './rightBarComponents/loginAffiliate';
import { AffiliateTickets } from './rightBarComponents/zoomedAffiliateRequests';
import QuickLinks from './rightBarComponents/quickLinks';
import { getLocalStorageValue } from '../util/util';


function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time)
  )
}
const RightBar = () => {
    
    const [openRightBar, setOpenRightBar] = useState(false)
    const [zoomed,setZoomed] = useState(false);
    const [zoomedState,setZoomedState] = useState(false);
    const [showData,setShowData] = useState(false);
    const [manualUseEffect,setManualUseEffect] = React.useState(false);
    const [approvalCount, setApprovalCount] = React.useState("");
    const [rejectedCount, setRejectedCount] = React.useState("");
    const [requestedCount, setRequestedCount] = React.useState("");
    const getOfferRequestCounts = async() => {
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"offerapprovalrequests/count"),
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token
                },
        })
        .then(resp => {
            setApprovalCount(resp.data.result.approved)
            setRejectedCount(resp.data.result.denied)
            setRequestedCount(resp.data.result.requested)
            // props.setRequest3Done(true)
        })
        .catch(err => {
            // props.setRequest3Done(true)
            console.error(err);
        })  
    };

    if (!manualUseEffect){
        setManualUseEffect(true);
        getOfferRequestCounts()
    }

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            setZoomed(false);
        }
      }, []);
      
    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
    
        return () => {
          document.removeEventListener("keydown", escFunction, false);
        };
    });
    if (zoomed){
        if(!showData){
            sleep(200).then(() => {
                setShowData(true)
                })
        }
    }

    const AffiliateInfo = () => {
        return <div style={{width:'90%'}}>
                    <div style={{display:"flex",width:'100%'}}>
                        <table style={{width:"100%",margin:"4%",marginTop:"2%"}}>
                            <tbody>
                                <tr>
                                    <td className="leftColumnData" style={{fontSize:"15px"}}>Offer Requests</td>
                                    <td className="rightColumnData" style={{fontSize:"15px",cursor:"pointer"}}  onClick={()=>{setZoomed(true);setZoomedState("requested")}}>{requestedCount ? requestedCount : 0}</td>
                                </tr>
                                <tr>
                                    <td className="leftColumnData" style={{fontSize:"15px"}}>Offer Approved</td>
                                    <td className="rightColumnData" style={{fontSize:"15px",cursor:"pointer"}}  onClick={()=>{setZoomed(true);setZoomedState("approved")}}>{approvalCount ? approvalCount : 0}</td>
                                </tr>
                                <tr>
                                    <td className="leftColumnData" style={{fontSize:"15px"}}>Offer Declined</td>
                                    <td className="rightColumnData" style={{fontSize:"15px",cursor:"pointer"}}  onClick={()=>{setZoomed(true);setZoomedState("denied")}}>{rejectedCount ? rejectedCount : 0}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
    }

    return (
        <div className='rightBarMainContainer' style={{marginRight:(openRightBar ? '0px' : '-300px')}}>
            <div className='rightBarOpenTab' onClick={()=>{setOpenRightBar(!openRightBar)}}>
                <KeyboardArrowLeftIcon sx={{fontSize:'30px',transform:(openRightBar ? 'rotateZ(180deg)' : "")}}/>
            </div>
            <AffiliateTickets showData={showData} state={zoomedState} zoomed={zoomed} close={()=>{setZoomed(false);setShowData(false);}}/>
            <div>
                <div className='rightBarContainer'>
                    <div className='affiliateLogin'>
                        <div style={{paddingTop:'10px',paddingBottom:'10px',fontFamily:"'Times New Roman', Times, serif"}}>Affiliate Login</div>
                        <LoginAffiliate/>
                    </div>
                    <div className='affiliateInfo'>
                        <div style={{paddingTop:'10px',paddingBottom:'10px',fontFamily:"'Times New Roman', Times, serif",color:localStorage.getItem('ambientColor'),fontWeight:'600',fontSize:'18px'}}>Affiliate Requests</div>
                        <AffiliateInfo/>
                    </div>
                    <div id='quickLinksRightBar' className='quickLinks'>
                        <div style={{paddingTop:'10px',paddingBottom:'10px',fontFamily:"'Times New Roman', Times, serif",color:localStorage.getItem('ambientColor'),fontWeight:'600'}}>Quick Links</div>
                        <QuickLinks/>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </div>
        </div>
        
    )
}

export default RightBar