import React, { useState } from 'react';
import ReactSelect from 'react-select';
import { NavLink } from "react-router-dom";
import axios from 'axios';
import { Button, CircularProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import LoginIcon from '@mui/icons-material/Login';
import { Store } from 'react-notifications-component';

import { getAffiliates, getAffiliatesTags, getManager } from '../../../apis/dropdown/dropdowns';
import { increase_brightness } from '../../../components/customization/customization';
import TableModal from '../../../components/tableModal/tableModal';
import '../../../css/pages/v2/advertiser.css';   //// PLEASE USE THIS CSS ONLY FOR AFFILIATE PAGE ALSO
import * as Constants from '../../../data/constants';
import { getLocalStorageValue, getRequests } from '../../../util/util';
import PaginationComponent from '../../../components/pagination/pagination';
import { loginAffiliateApi } from '../../../apis/dropdown/loginAffiliate';
import ExportPopup from '../../../components/export/export';
import ImportPopup from '../../../components/import/import';
const AffiliatePageV2 = () => {
    /// note -----  all advertiser variables are equivalent to affiliate variables, if you have no work please help me replace them with affiliate.
    document.title = 'Affiliates'
    var tempLimit = 50
    var tempPage = 1
    var tempAdvId = ''
    var tempContactName = ''
    var tempManager = ''
    var tempTag = ''

    if(window.location.href.includes("?")){
        var newUrl = new URL(window.location.href)
        if(newUrl.searchParams.get('limit')){
            tempLimit = parseInt(newUrl.searchParams.get('limit'))
        }
        if(newUrl.searchParams.get('page')){
            tempPage = parseInt(newUrl.searchParams.get('page'))
        }
        if(newUrl.searchParams.get('id')){
            tempAdvId = newUrl.searchParams.get('id')
            tempAdvId = {label:tempAdvId.split(" - ")[0] + " - " + tempAdvId.split(" - ")[1],value:tempAdvId.split(" - ")[1]}
        }
        if(newUrl.searchParams.get('name')){
            tempContactName = newUrl.searchParams.get('name')
        }
        if(newUrl.searchParams.get('manager')){
            tempManager = newUrl.searchParams.get('manager')
            tempManager = {label:tempManager.split(" - ")[0] + " - " + tempManager.split(" - ")[1],value:tempManager.split(" - ")[1]}
        }
        if(newUrl.searchParams.get('tags')){
            tempTag = newUrl.searchParams.get('tags')
            tempTag = {label:tempTag,value:tempTag}
        }
    }

    const [manualUseEffect, setManualUseEffect] = useState(false)
    const [filterSearch, setFilterSearch] = useState({id:'',adv_name:tempAdvId ? tempAdvId : '',adv_email:tempContactName,manager:tempManager ? tempManager : '',tags:tempTag ? tempTag :''})
    const [managerOptions, setManagerOptions] = useState([])
    const [advertiserOptions, setAdvertiserOptions] = useState([])
    const [tagOptions, setTagOptions] = useState([])
    const [advertiserData, setAdvertiserData] = useState([])
    const [advertisersTotalCount, setAdvertisersTotalCount] = useState(0)
    const [loader, setLoader] = useState(false)

    const [page, setPage] = useState(tempPage)
    const [limit, setLimit] = useState(tempLimit)

    var changeUrlParams = (obj) => {
        var str = [];
        for(var p in obj){
            if(obj[p]){
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        }
        var paramString = str.join("&");
        var url = window.location.pathname
        url+= '?'+paramString
        window.history.pushState('Advertisers', 'Advertisers', url);
    }
    const showAdvertiserRequest = async(manualLimit,manualPage) => {
        /// FOR URL 
        var urlParams = {
            limit:manualLimit ? manualLimit : limit,
            page:manualPage ? manualPage : page,
            id : filterSearch.adv_name ? filterSearch.adv_name.label +' - '+filterSearch.adv_name.value : null,  //Search
            name : filterSearch.adv_email ? filterSearch.adv_email.toLowerCase() : null,  //Search
            manager : filterSearch.manager ? filterSearch.manager.label +' - '+filterSearch.manager.value : null,  //Search
            tags : filterSearch.tags ? filterSearch.tags.label : null,  //Search
        }
        changeUrlParams(urlParams)

        var params = {
            limit:manualLimit ? manualLimit : limit,
            page:manualPage ? manualPage : page,
            id : filterSearch.adv_name ? filterSearch.adv_name.value : null,  //Search
            contact : filterSearch.adv_email ? filterSearch.adv_email.toLowerCase() : null,  //Search
            manager_id : filterSearch.manager ? filterSearch.manager.value : null,  //Search
            tags : filterSearch.tags ? filterSearch.tags.value : null,  //Search
        }
        setLoader(true)
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.AFFILIATES),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params
        }
        )
        .then((resp) => {
            setAdvertiserData(resp.data.result)
            setAdvertisersTotalCount(resp.data.count)
            setLoader(false)
        })
        .catch(function (error) {
            setLoader(false)
            if(error.response.data){
                setAdvertiserData("errorFromAxios"+error.response.data.result)
            }
        });
    };

    const changeStatusApi = async(e) => {
        /// FOR URL 
        if(changeStatusSelected){
            var advertiserString = ''
            for(var index in selectedElements){
                advertiserString += selectedElements[index] + ","
            }
            advertiserString = advertiserString.slice(0,-1)
            await axios({
                method: 'GET',
                url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'affiliate/multiple/change'),
                headers:{
                    Authorization:getLocalStorageValue('adminPanelLoginData').token,
                    'Content-type':'application/json',
                },
                params:{
                    affiliate_id:advertiserString,
                    status:changeStatusSelected
                }
            }
            )
            .then((resp) => {
                showAdvertiserRequest();
                setChangeStatus(false);
                setSelectAll(false);
                setSelectedElements([]);
                Store.addNotification({
                    title: "Success!",
                    message: 'Status changed successfully.',
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                });
            })
            .catch(function (error) {
                if(error.response){
                    Store.addNotification({
                        title: "Error!",
                        message: error.response.data.result,
                        type: "warning",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true
                        }
                    });
                }
            });
        }
    };

    if(!manualUseEffect){
        setManualUseEffect(true)
        getRequests(getManager(),setManagerOptions)
        getRequests(getAffiliates(),setAdvertiserOptions)
        getRequests(getAffiliatesTags(),setTagOptions)
        showAdvertiserRequest()
    }
    var filterOptions = [
        // {id:'id',label:'Id',type:'textfield',value:filterSearch['id'],setValue:setFilterSearch,minWidth:'70px'},
        {id:'adv_name',label:'Affiliate Name',type:'select',value:filterSearch['adv_name'],setValue:setFilterSearch,options:advertiserOptions,minWidth:220,},
        {id:'adv_email',label:'Affiliate Contact',type:'textfield',value:filterSearch['adv_email'],setValue:setFilterSearch},
        {id:'manager',label:'Manager',type:'select',value:filterSearch['manager'],setValue:setFilterSearch,options:managerOptions,minWidth:170},
        {id:'tags',label:'Affiliate Tags',type:'select',value:filterSearch['tags'],setValue:setFilterSearch,options:tagOptions,minWidth:220,},
    
    ]

    const editComponent = (item) =>{
        return <NavLink className='threeDotMenuOption'  to={"/affiliates/management/edit/"+item.id} >
                <EditIcon sx={{color:localStorage.getItem('ambientColor'),fontSize:'16px',marginRight:'5px'}}/>
                Edit
            </NavLink>
    }

    const loginComponent = (item) =>{
        return <div className='threeDotMenuOption' style={{cursor:'pointer'}} onClick={()=>{loginAffiliateApi(item.id)}}>
                <LoginIcon sx={{color:localStorage.getItem('ambientColor'),fontSize:'16px',marginRight:'5px'}}/>
                Login Affiliate
            </div>
    }
    const viewStatsComponent = (item) =>{
        return <NavLink className='threeDotMenuOption' to={"/statistics/daily?affiliate="+item.company+" - "+item.id}>
                <QueryStatsIcon sx={{color:localStorage.getItem('ambientColor'),fontSize:'16px',marginRight:'5px'}}/>
                View Stats
            </NavLink>
    }
    const companyName = (value,tableHeadIndex,item) => {
        return <NavLink style={{textDecoration:'none',color:localStorage.getItem('ambientColor')}} to={'/affiliates/management/edit/'+item.id}>
            {value}
        </NavLink>
    }

    var statusDictColor = {
        'active':{
            color:'rgb(0, 150, 0)',
            backgroundColor:'rgb(217, 255, 200)',
            label:'Active'
        },
        'notactive':{
            color:'rgb(255 120 120)',
            backgroundColor:'rgb(255 216 216)',
            label:'Not Active'
        },
        'banned' : {
            color:'rgb(210, 0, 0)',
            backgroundColor:'rgb(255, 175, 175)',
            label:'Banned'
        },
        'moderation' : {
            color:'rgb(204, 133, 0)',
            backgroundColor:'rgb(255, 232, 188)',
            label:'On Moderation'
        }
    }
    const statusComponent = (value,tableHeadIndex,item) => {
        return <div style={{display:'flex',justifyContent:'center'}}>
                    <div style={{minWidth:'70px',wordWrap:'break-word',color:(statusDictColor[value] ? statusDictColor[value].color : 'black'),border:'1px solid '+(statusDictColor[value] ? statusDictColor[value].color : 'black'),padding:'2px',backgroundColor:(statusDictColor[value] ? statusDictColor[value].backgroundColor : 'white'),borderRadius:'5px',paddingTop:'5px',paddingBottom:'5px'}}>
                        {statusDictColor[value] ? statusDictColor[value].label : value}
                    </div>
                </div>
    }

    const tagComponent = (value,tableHeadIndex,item) => {
        if(value && value.length>0){
            return <div style={{display:'flex',justifyContent:'center',flexWrap:'wrap'}}>
                        {value.map((item,index)=>{
                            return <div key={index} style={{paddingLeft:'5px',paddingRight:'5px',marginTop:'5px',minWidth:'70px',wordWrap:'break-word',color:'black',border:'1px solid rgb(100,100,100)',padding:'2px',backgroundColor:'rgb(200,200,200)',borderRadius:'5px',paddingTop:'5px',paddingBottom:'5px'}}>
                                        {item}
                                    </div>
                        })}
                        
                    </div>
        }
        else{
            return "-"
        }
    }


    const [selectedElements, setSelectedElements] = useState([])
    const [selectAll, setSelectAll] = useState(false)

    var tempTableHeaders = [
        {id:'checkbox',label:'',value:'checkbox',show:true,width:'2%',minWidth:30,whatToSelect:'id'},
        {label:'ID',value:'id',show:true,width:'6%',minWidth:60,},
        {label:'Company',value:'company',show:true,width:'13%',minWidth:80,alignHead:'left',alignValue:'left',color:localStorage.getItem('ambientColor'),customComponent:companyName},
        {label:'Status',value:'affiliate_status',show:true,width:'10%',minWidth:80,alignValue:'center',customComponent:statusComponent},
        {label:'Commission Percentage',value:'commision_percenatge',show:true,width:'10%',minWidth:80,alignValue:'center'},
        {label:'Contact Person',value:'contact_person',show:true,width:'10%',minWidth:50,alignHead:'left',alignValue:'left',color:'blue'},
        {label:'Contact Email',value:'email',show:false,alignHead:'left',alignValue:'left',width:'15%',minWidth:50},
        {label:'Manager',value:'manager_name',show:true,width:'10%',minWidth:50},
        {label:'Last Login',value:'last_login',dateTime:true,show:true,width:'14.5%',minWidth:100},
        {label:'Created Date',value:'created_at',show:true,width:'14.5%',minWidth:100},
        {label:'Contact Skype',value:'skype_id',hide:true,alignHead:'left',alignValue:'left',width:'15%',minWidth:50},
        {label:'Contact Number',value:'contact_number',hide:true,alignHead:'left',alignValue:'left',width:'15%',minWidth:50},
        {label:'Tags',value:'tags',hide:true,width:'10%',minWidth:80,alignValue:'center',customComponent:tagComponent},
        {label:'',value:'threeDotMenu',show:true,width:'5%',minWidth:50,threeDotMenu:true,menuOptions:[{label:'Login',value:'login',component:loginComponent},{label:'Edit',value:'edit',component:editComponent},{label:'View Statistics',value:'viewStatistics',component:viewStatsComponent}],height:90},
    ]

    if(getLocalStorageValue(window.location.pathname)){
        try{
            var hideShowColumns = getLocalStorageValue(window.location.pathname)
            for(var index in tempTableHeaders){
                tempTableHeaders[index].hide = hideShowColumns[index].hide
            }
        }
        catch{

        }
    }
    const [tableHeaders, setTableHeaders] = useState(tempTableHeaders)
    
    // const gridComponent = () => {
    //     return advertiserData.map((item,index)=>{
    //         return <div className='gridComponentAdvertiser'>

    //         </div>
    //     })
    // }


    const [changeStatus, setChangeStatus] = useState(false)
    const [changeStatusSelected, setChangeStatusSelected] = useState(false)

    const selectedElementsComponent = () => {
        return <div style={{color:'white',fontSize:'13px',top:0,height:50,zIndex:999,position:'sticky',display:'flex',background:localStorage.getItem('ambientColor'),alignItems:'center',justifyContent:'space-between',paddingLeft:'20px',paddingRight:'20px'}}>
            <div style={{fontWeight:'600'}}>Selected {selectedElements.length} Affiliate.</div>
            <div className='advertiserPageV2ChangeStatus' onClick={(e)=>{e.stopPropagation();setChangeStatus(true)}}>
                Change Status
                {
                    changeStatus && 
                    <div style={{color:'black',position:'absolute',minHeight:90,width:150,marginLeft:'-50px',marginTop:'10px',background:'white',boxShadow:'0 0 5px 1px grey'}} onClick={(e)=>{e.stopPropagation()}}>
                        <div style={{display:'flex',justifyContent:'center',fontSize:'14px',padding:'10px'}}> Change Status</div>
                        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                            {Object.keys(statusDictColor).map((item,index)=>{
                                var value = item
                                return <div key={index} style={{display:'flex',justifyContent:'center',marginTop:'10px'}}>
                                            <input id={item} type='radio' name='status' onChange={(e)=>{setChangeStatusSelected(item);}}/>
                                            <label htmlFor={item} style={{minWidth:'70px',wordWrap:'break-word',color:(statusDictColor[value] ? statusDictColor[value].color : 'black'),border:'1px solid '+(statusDictColor[value] ? statusDictColor[value].color : 'black'),padding:'2px',backgroundColor:(statusDictColor[value] ? statusDictColor[value].backgroundColor : 'white'),borderRadius:'5px',paddingTop:'5px',paddingBottom:'5px'}}>
                                                {statusDictColor[value] ? statusDictColor[value].label : value}
                                            </label>
                                        </div>
                            })}
                        </div>
                        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                            <Button sx={{...Constants.CONTAINEDBUTTON,fontSize:'14px',height:'35px',width:'100%',paddingLeft:'15px',borderRadius:'0px',paddingRight:'15px',marginTop:'20px',textTransform:'none',fontWeight:500}} onClick={changeStatusApi}>Save</Button>
                        </div>
                    </div>
                }
            </div>
        </div>
    }
    const [showImportPopup, setShowImportPopup] = useState(false)
    const [showExportPopup, setShowExportPopup] = useState(false)
    return (
        <div className='advertiserPageV2'>
            {showImportPopup && <ImportPopup setImportPopup={setShowImportPopup} type={'AffiliateView'}/>}
            {showExportPopup && <ExportPopup setExportPopup={setShowExportPopup} type={'AffiliateView'} filterSearch={filterSearch}/>}
            <div className='containerFirstRow'>
                <div> Found {advertisersTotalCount ? advertisersTotalCount : <CircularProgress sx={{color:localStorage.getItem('ambientColor'),paddingLeft:'5px',paddingRight:'5px'}} size={13}/>} Affiliate</div>
                <div className='containerFirstRowColTwo'>
                    <div style={{marginRight:'20px',cursor:'pointer',}} onClick={()=>{localStorage.setItem('oldAffiliatePage',true);window.location.reload();}}> Back to old design </div>
                    <div style={{marginRight:'20px',cursor:'pointer',color:localStorage.getItem('ambientColor')}} onClick={()=>{setShowImportPopup(true)}}> Import from CSV </div>
                    <div style={{marginRight:'20px',cursor:'pointer',color:localStorage.getItem('ambientColor')}} onClick={()=>{setShowExportPopup(true);}}> Export CSV </div>
                    <NavLink to={'/affiliates/management/add'} style={{textDecoration:'none'}}>
                        <Button sx={{...Constants.OUTLINEDBUTTON,height:'30px',fontSize:'15px',textTransform:'none',fontWeight:500}}>Add</Button>
                    </NavLink>
                </div>
            </div>
            <div className='containerSecondRow'>
                {filterOptions.map((filterItem,filterIndex)=>{
                    if(filterItem.type==='textfield'){
                        return <div key={filterIndex} style={{width:(filterItem.minWidth ? filterItem.minWidth : 'max-content'),marginRight:'15px',marginTop:20}}>
                                <Constants.CssTextField 
                                size='small' 
                                disabled={filterItem.disable} 
                                fullWidth 
                                value={filterSearch[filterItem.id]} 
                                placeholder={filterItem.label}
                                onChange={(e)=>{setFilterSearch({...filterSearch,[filterItem.id]:e.target.value})}} 
                                onKeyDown={(e)=>{if(e.key==='Enter' && !loader){showAdvertiserRequest(limit,1)}}}
                                />
                            </div>
                    }
                    else if(filterItem.type==='select'){
                        return <div key={filterIndex} style={{width:(filterItem.minWidth ? filterItem.minWidth : 'max-content'),marginRight:'15px',marginTop:20}}>
                            <ReactSelect
                                isClearable
                                isDisabled={filterItem.disable}
                                key={filterIndex} 
                                options={filterItem.options}
                                value={filterSearch[filterItem.id]}
                                onChange={(e)=>{setFilterSearch({...filterSearch,[filterItem.id]:e})}}
                                menuPortalTarget={document.body} 
                                placeholder={"Select "+filterItem.label}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 1000000 }),
                                            control: (base,state) => ({
                                                ...base,
                                                // maxHeight: 100,
                                                minHeight: 30,
                                                color:'rgb(200,200,200)',
                                                overflowY: "auto",
                                                fontSize:14,
                                                border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                                '&:hover': {
                                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                    boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                                }
                                            }),
                                            placeholder: (defaultStyles) => {
                                                return {
                                                    ...defaultStyles,
                                                    color: 'rgb(150,150,150)',
                                                    marginTop:'-2px'
                                                }
                                            },
                                            option: (styles, {isFocused, isSelected}) => ({
                                                ...styles,
                                                fontSize:'11px',
                                                background: isFocused
                                                    ? localStorage.getItem('ambientColor')
                                                    : isSelected
                                                        ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                        : undefined,
                                                zIndex: 1
                                            }),
                                        }}
                            />
                        </div>
                    }
                    return null;
                })}
           </div>
           <div className='containerThirdRow'>
                <Button disabled={loader ? true : false} sx={{...Constants.CONTAINEDBUTTON,minHeight:'40px',fontSize:'15px',minWidth:'80px',paddingLeft:'15px',paddingRight:'15px',marginTop:'20px',textTransform:'none',fontWeight:500,marginLeft:'15px'}} onClick={()=>{showAdvertiserRequest(limit,1)}}>
                    {loader && 
                    <CircularProgress sx={{color:'white'}} size={20}/>
                    }
                    {!loader && "Search"}
                </Button>
                <Button sx={{...Constants.OUTLINEDBUTTON,minHeight:'40px',fontSize:'15px',paddingLeft:'15px',paddingRight:'15px',marginTop:'20px',textTransform:'none',fontWeight:500,marginLeft:'15px'}} onClick={()=>{setFilterSearch({id:'',adv_name:'',adv_email:'',manager:'',tags:''})}}>Reset</Button>
            </div>

            <TableModal 
                stickyColumn={[0,1,2]} 
                rightStickyColumnList={[tableHeaders.length-1]} 
                showLoader={loader} tableHeaders={tableHeaders} 
                setTableHeaders={setTableHeaders} 
                tableValues={advertiserData} 
                setTableValues={setAdvertiserData} 
                hideTooltip={true} 
                showCustomization={true}
                tableSelectAll={selectAll} 
                setTableSelectAll={setSelectAll} 
                setSelectedElements={setSelectedElements} 
                selectedElements={selectedElements}
                selectedElementsComponent = {selectedElementsComponent}
            />
            <PaginationComponent page={page} setPage={setPage} limit={limit} setLimit={setLimit} totalResults={advertisersTotalCount} apiRequest={showAdvertiserRequest} onClick={()=>{setSelectAll(false);setSelectedElements([]);}}/>
            
        </div>
    )
}

export default AffiliatePageV2