import React from 'react'
import ConstructorPng from '../img/constructor.png'
export default function UnderConstruction(){

    return (
        <div style={{display:"flex",height:"60vh",justifyContent:"center",alignItems:"center"}}>
            <img src={ConstructorPng} alt="" width="100" height="100"/>
            <br/>
            Under Construction
            <br/>
            We are currently working on this page. 
            
        </div>
    )
}