import React from 'react'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from  'react-loader-spinner'

const DashboardLoader = () => {
    return (
        <div style={{display:'flex',alignItems:'center',justifyContent:'center',height:'100%',position:'absolute',backdropFilter:'blur(5px)',zIndex:10,width:'100%',borderRadius:'30px'}}>
            <Loader type={"Bars"} color={localStorage.getItem('ambientColor')} height={30} width={30} />
        </div>
    )
}

export default DashboardLoader