import React from 'react'
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useScrollDirection } from 'react-use-scroll-direction';
import axios from 'axios';

import '../../css/pages/advertiser.css';

import * as Constants from '../../data/constants';
import { getLocalStorageValue, sorting } from '../../util/util';
import NewAdvertiserPopup from './newAdvertiserPopup';
import Select from 'react-select';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NativeSelect from '@mui/material/NativeSelect';
import Pagination from '@mui/material/Pagination';

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    if(false){
        console.log(value)
    }
    return () => setValue(value => value + 1); // update the state to force render
  }

export const AdvertiserPage = () => {
    document.title = "Advertisers - Admin"
    const refreshComponent = useForceUpdate()

    const [manualUseState, setManualUseState] = React.useState(false);
    const [showLoader, setShowLoader] = React.useState(true);
    
    const [advertiserDict,setAdvertiserDict] = useState({loaded:false,data:[]})
    const [editAdvertiser, setEditAdvertiser] = React.useState(false);
    const [totalResults,setTotalResults] = useState("-");
    const [showPopupNewAdvertiser, setShowPopupNewAdvertiser] = React.useState(false);
    
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(50);
   
    const [threeDotMenuTop, setThreeDotMenuTop] = React.useState(0);
    const [threeDotMenuLeft, setThreeDotMenuLeft] = React.useState(0);
    const [showThreeDotMenu, setShowThreeDotMenu] = React.useState(false);
        
    const [showResponsePopup, setShowResponsePopup] = React.useState(false);
    const [responsePopup, setResponsePopup] = React.useState(false);
    
    const [sortingFactor, setSortingFactor] = React.useState("id");
    const [sortingOrder, setSortingOrder] = React.useState(false); //true for asc and false for desc
    const [sortingFactorType, setSortingFactorType] = React.useState(true); //true for int and false for string
    
    const [direction, setDirection] = React.useState(String)
    const { isScrollingUp, isScrollingDown } = useScrollDirection()
    
    var tableRows = []

    const handleCheckSortingKey = (e) => {
        if (["id"].includes(e.target.id)){
            if (sortingFactor===e.target.id){
                setSortingOrder(!sortingOrder);
            }
            else{
                setSortingOrder(true);
            }
            setSortingFactor(e.target.id);
            setSortingFactorType(true);
        }
        else if (["company","contact_person","skype_id","email","manager_name","created_at"].includes(e.target.id)){
            if (sortingFactor===e.target.id){
                setSortingOrder(!sortingOrder);
            }
            else{
                setSortingOrder(true);
            }
            setSortingFactor(e.target.id);
            setSortingFactorType(false);       
            }
        else{
            setSortingFactor("id");
            setSortingOrder(true);
            setSortingFactorType(true);   
        }
    }
    
    const handleLimitChange = (e) =>{
        setLimit(parseInt(e.target.value))
        setPage(1);
        showAdvertiserRequest(1,e.target.value);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
        // setOffset((value-1)*limit);
        showAdvertiserRequest(value,limit);
    };

    const [advertisersList,setAdvertisersList] = React.useState([{label:"All",value:null}])
    const [advertiserSelected,setAdvertiserSelected] = React.useState({label:"All",value:null})

    const getAdvertisers = async() => {
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.ADVERTISER,"/","details"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        })
        .then(resp => {
            let advertiserlist = []
            advertiserlist.push({label:"All",value:null})
            for (var item in resp.data.result){
                advertiserlist.push({label:resp.data.result[item][Object.keys(resp.data.result[item])[0]] + " - " + Object.keys(resp.data.result[item])[0],value:Object.keys(resp.data.result[item])[0]})
            }
            sorting(advertiserlist,'label',false,true)
            var tempList = advertisersList.concat(advertiserlist)
            setAdvertisersList(tempList)
        })
        .catch(err => {
            console.error(err);
        })
    };

    const showAdvertiserRequest = async(manual_page=false,manual_limit=false,searchButton=false) => {
        setShowLoader(true);
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.ADVERTISERS),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                limit:manual_limit ? manual_limit : limit,
                page:manual_page ? manual_page : page,
                id : advertiserSelected ? advertiserSelected.value : null,
            }
        }
        )
        .then((resp) => {
            if(searchButton){
                setPage(1);
            }
            setAdvertiserDict({loaded:true,data:resp})
            setTotalResults(resp.data.count)
            setShowLoader(false);
        })
        .catch(function (error) {
            if (error.response) {
              // Request made and server responded
              setShowResponsePopup(true)
              setResponsePopup(error.response.data.result);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else {
              // Something happened in setting up the request that triggered an Error
              setResponsePopup(JSON.stringify({error:error.message}));
              console.log('Error', error.message);
            }
        
          });
    };

    const threeDotButtonClicked = (e,item) => {
        if (e.target.id){
            if (showThreeDotMenu){
                setShowThreeDotMenu(false)
            }
            else{
                setShowThreeDotMenu(true)
            }
            var offsets = document.getElementById(e.target.id).getBoundingClientRect();
            var top = offsets.top-10;
            var left = offsets.left-160;
            setThreeDotMenuTop(top)
            setThreeDotMenuLeft(left)
            }
                    
    }

    const togglePopup = (closeButton=false) => {
        setShowPopupNewAdvertiser(!showPopupNewAdvertiser);
        }

    const showData = () => {
        if (advertiserDict.loaded!==false){
            
            var advertiserData =  advertiserDict.data.data.result
            sorting(advertiserData,sortingFactor,sortingFactorType,sortingOrder);
            advertiserData.map((item,index)=>{
                if(true){  
                        var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
                        var registrationDate = new Date(item.created_at)
                        tableRows.push(<tr key={index} className="dataRow" style={{backgroundColor:"rgb(255,255,255)" }}>
                            <td className="dataColumn">{item.id}</td>
                            <td className="dataColumn">
                                <NavLink style={{textDecoration:"none",color:"blue"}} to={"/advertiser/view/"+item.id}>
                                    {item.company}
                                </NavLink>
                            </td>

                            <td className="dataColumn">{item.contact_person}</td>
                            <td className="dataColumn">{item.skype_id || "NA"}</td>
                            <td className="dataColumn">{item.email || "NA"}</td>
                            <td className="dataColumn">{item.manager_name || "NA"}</td>
                            <td className="dataColumn">{registrationDate.toLocaleDateString("en-US", options)}</td>
                            <td className="dataColumn">{item.Offers || "NA"}</td>
                            <td className="dataColumn">{item.Cap || "NA"}</td>
                            <td className="dataColumn">{item.DailyLimit || "NA"}</td>
                            <td className="dataColumn">{item.TotalLimit || "NA"}</td>   

                            <td id={"threeDotButton"+index} className="dataColumn threeDotButton" onClick={(e)=>{setEditAdvertiser(item.id);threeDotButtonClicked(e);}}>
                                <MoreVertIcon id={"threeDotButton"+index} onClick={(e)=>{setEditAdvertiser(item.id);threeDotButtonClicked(e);}}/>
                            </td>
                        </tr>)
                }
                return 1;
            })
            return  <table className="dataTable">
                        <tbody>
                            <tr className="dataRow" style={{backgroundColor:"rgb(250,250,250)"}}>
                                <td className="dataColumnHead" id="id" style={{width:"3%"}} onClick={handleCheckSortingKey}>ID</td>
                                <td className="dataColumnHead" id="company" style={{width:"20%"}} onClick={handleCheckSortingKey}>Company</td>
                                <td className="dataColumnHead" id="contact_person" style={{width:"10%"}} onClick={handleCheckSortingKey}>Contact Person</td>
                                <td className="dataColumnHead" id="skype_id" style={{width:"10%"}}  onClick={handleCheckSortingKey}>IM / Skype</td>
                                <td className="dataColumnHead" id="email" style={{width:"10%"}}  onClick={handleCheckSortingKey}>Email</td>
                                <td className="dataColumnHead" id={"manager_name"} style={{width:"10%"}}  onClick={handleCheckSortingKey}> Manager</td>
                                <td className="dataColumnHead" id={"created_at"} style={{width:"15%"}}  onClick={handleCheckSortingKey}> Created At</td>
                                <td className="dataColumnHead" style={{width:"5%"}}> Offers</td>
                                <td className="dataColumnHead" style={{width:"5%"}}> Cap</td>
                                <td className="dataColumnHead" style={{width:"5%"}}> Daily limit</td>
                                <td className="dataColumnHead" style={{width:"5%"}}> Total limit</td>
                            </tr>
                            {tableRows}
                            
                        </tbody>
                    </table>
            }
    }
    
    React.useEffect(() => {
        isScrollingDown && setDirection('down')
        isScrollingUp && setDirection('up')
      }, [isScrollingDown, isScrollingUp])
      if(direction){
      }
      if (isScrollingDown || isScrollingUp){
          if (showThreeDotMenu){
              setShowThreeDotMenu(false);
          }
      }
  
      if (!manualUseState){
          setManualUseState(true);
          showAdvertiserRequest();
          getAdvertisers();
      }
    
    return (
        <div className="row">
            <div className="columnData" style={{width:"95%",height:"auto"}}>
                {
                    showThreeDotMenu && editAdvertiser && 
                    <div className="threeDotMenu" style={{height:"80px",top:threeDotMenuTop,left:threeDotMenuLeft}}>
                        <NavLink style={{textDecoration:"none",color:"black"}} to={"/advertiser/edit/" + editAdvertiser}>
                            <div className="threeDotMenuItems" style={{height:"45px"}}>
                                <ModeEditOutlineIcon style={{color:"rgb(0, 119, 255)",transform:"scale(0.8)"}}/>
                                Edit
                            </div>
                        </NavLink>  
                    </div>
                }
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <div style={{marginLeft:"2%",marginTop:"2%",fontSize:"12px"}}>Found {totalResults} advertisers</div>
                    <div style={{marginRight:"2%",marginTop:"2%",fontSize:"12px",display:'flex',alignItems:'center'}}>
                        <div style={{cursor:'pointer',marginRight:'10px'}} onClick={()=>{localStorage.removeItem('oldAdvertiserPage');window.location.reload();}}>Switch to New Design</div>
                        <NavLink style={{textDecoration:"none",color:"black"}} to={"/advertiser/add"}>
                            <Button variant="outlined" color="warning">New Advertiser</Button>
                        </NavLink>  
                    </div>
                </div>
                <div style={{display:"flex",marginTop:"1%"}}>
                    <div style={{width:"17%",marginLeft:"2%"}}>
                        <div style={{fontSize:"12px",fontWeight:"700",color:"grey",paddingBottom:"2px"}}>Advertiser</div>
                        <Select 
                            options={advertisersList}
                            value={advertiserSelected}
                            onChange={(e)=>{setAdvertiserSelected(e)}}
                            menuPortalTarget={document.body} 
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999}),
                                    option: styles => ({...styles,fontSize:"13px"}),
                                    control : provided => ({
                                        ...provided,
                                        minHeight:"5.2vh",
                                        height:"15%",
                                    }),
                                    valueContainer: provided => ({
                                    ...provided,
                                    fontSize:"13px"
                                    })
                            }}/>
                    </div>
                </div>

                <div style={{display:'flex',marginTop:"5%",marginLeft:'2%'}}>
                    <div style={{transform:'scale(0.9)'}}><Button variant='contained' color="warning" onClick={()=>{showAdvertiserRequest(1,limit,true)}}>Search</Button></div>
                    <div style={{transform:'scale(0.9)'}}><Button variant='outlined' onClick={(e)=>{setAdvertiserSelected({label:"All",value:null});refreshComponent(e);}}>Reset</Button></div>
                </div>

                <div style={{borderBottom:"0.001px solid rgb(120,120,120)",display:'flex',marginLeft:"2%",marginRight:"2%",marginTop:"2%"}}></div>

             
                <div style={{display:"block",margin:"2%",height:"75%"}}> 
                        {showLoader && 
                            <div className="loaderWrapper">  
                                <div className="loader">  
                                    <CircularProgress color="warning"/> 
                                </div>
                            </div>
                        }
                        {!showLoader && 
                            <div style={{textAlign: "right"}}>
                                <label style={{paddingLeft:"20px",paddingRight:"20px"}}>LIMIT</label>
                                <NativeSelect
                                    value={limit}
                                    inputProps={{
                                        name: 'LIMIT',
                                        id: 'uncontrolled-native',
                                    }}
                                    color="warning"
                                    onChange={handleLimitChange}
                                    >
                                    <option>50</option>
                                    <option>100</option>
                                </NativeSelect>
                            </div>}
                        {!showLoader && showData()}
                        {!showLoader &&
                            <div className="row">
                                <Pagination count={Math.ceil(totalResults/limit)} page={page} onChange={handlePageChange} />
                            </div>
                            }
                </div>
                
                {showPopupNewAdvertiser && 
                    <NewAdvertiserPopup
                        handleClose={togglePopup}
                        />
                }

                {showResponsePopup && 
                    <div className="popup-box">
                        <div className="box-response">
                        <div className="popupHeader" >{"Error"}</div>
                        <div style={{borderBottom:"2px solid orange"}}></div>
                            <div className="popupData">
                            {responsePopup}
                            </div>
                        <div className="close-icon-response" onClick={()=>{setShowResponsePopup(false)}}>X</div>
                        </div>
                    </div>
                }
            
            </div>            
        </div>
    )
}

export default AdvertiserPage;
