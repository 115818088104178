import React from 'react';
import somethingLooksFishyImage from '../img/somethingLooksFishy.png'

const SomeErrorOccured = (props) => {
    return (
        <div style={{display:'flex',width:'100%',flexDirection:'column',alignItems:'center'}}>
            <img src={somethingLooksFishyImage} width={'20%'} alt=""></img>
            <div style={{fontSize:'16px',fontWeight:'600',marginTop:'15px'}}>Something looks fishy.</div>
            <div style={{fontSize:'12px',color:'rgb(50,50,50)',marginTop:'15px'}}>{props.error ? props.error : 'Some error occured.'}</div>
        </div>
    )
}

export default SomeErrorOccured