import { Close } from '@mui/icons-material'
import React, { useState } from 'react'
import { CssTextField, ReactSelectStyles } from '../../data/constants'
import ReactSelect from 'react-select';
import { Button, CircularProgress } from '@mui/material';
import * as Constants from '../../data/constants';
import axios from 'axios';
import { getLocalStorageValue, zeroPad } from '../../util/util';
import { Store } from 'react-notifications-component';
const MissingOfferComponent = (props) => {
    const [missingData, setMissingData] = useState({})
    const [requestLoader, setRequestLoader] = useState(false)
    var missingDataFields = [
        {label:'External Offer Id',value:'external_offer_id',type:'textfield'},
        {label:'Offer Title',value:'title',type:'textfield'},
        {label:'Package Name',value:'package_name',type:'textfield'},
        {label:'Advertiser',value:'advertiser',type:'select',options:props.advertiserOptions ? props.advertiserOptions.advertiserlist : []},
        {label:'Conversions',value:'conversions',type:'textfield',validation:'number'},
        {label:'Revenue',value:'conversions_verified',type:'textfield',validation:'number'},
        {label:'Approved Revenue',value:'manager_approved_revenue',validation:'number',type:'textfield'},
    ]
    var monthSelected = props.monthSelected

    const getFromToMonth = () => {
        var date_temp = new Date(monthSelected[0]).toLocaleString('en-US',{year:'numeric',month:'numeric'}).split(',')[0].split('/')
        var fromMonth = date_temp[1]+'-'+zeroPad(date_temp[0],2)
        if(monthSelected[1]){
            date_temp = new Date(monthSelected[1]).toLocaleString('en-US',{year:'numeric',month:'numeric'}).split(',')[0].split('/')
            var toMonth = date_temp[1]+'-'+zeroPad(date_temp[0],2)
        }
        return {fromMonth,toMonth}
    }
    const addOfferToBilling = async() => {
        var {fromMonth,toMonth} = getFromToMonth()
        if(fromMonth===toMonth){
            setRequestLoader(true)
            await axios({
                method: 'POST',
                url:"".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"invoice/adv-data/add-offer"),
                headers: {
                    "Content-Type":"application/json",
                    Authorization:getLocalStorageValue('adminPanelLoginData').token
                },
                data:{...missingData,
                    month:fromMonth,
                    panel_offer_id:process.env.REACT_APP_FEATURE_MONITORING_OFFER_ID,
                    panel_advertiser:process.env.REACT_APP_FEATURE_MONITORING_AVERTISER_ID}
            })
            .then(async(resp) => {
                Store.addNotification({
                    title: "Success!",
                    message: 'Offer has been added to Advertiser Billing Table.',
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                    duration: 3000,
                    onScreen: true
                    },
                });
                props.setAddMissingOfferPopup(false);
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    Store.addNotification({
                        title: "Error!",
                        message: error.response.data.result,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                        duration: 3000,
                        onScreen: true
                        }
                    });
                } 
                else if (error.request) {
                    console.log(error.request);
                } 
                else {
                    console.log('Error', error.message);
                }
            }) 
            setRequestLoader(false)
        }
        else{
            Store.addNotification({
                title: "Warning!",
                message: "You cannot update when you have selected different month range.",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true
                }
            });
        }
    }

    const updateMissingData = (value,item) => {
        setMissingData({...missingData,[item.value]:value})
    }

    const buttonDisabled = () => {
        var disabled = false
        for(var index in missingDataFields){
            var item = missingDataFields[index]
            if(!missingData[item.value]){
                disabled = true
                break;
            }
        }
        return disabled
    }
    return (
        <div style={{position:'fixed',top:0,left:0,height:'100vh',width:'100vw',display:'flex',justifyContent:'center',alignItems:'center',zIndex:'999',background:'rgba(0,0,0,0.2)',backdropFilter:'blur(1px)'}} onClick={()=>{props.setAddMissingOfferPopup(false);}}>
            <div style={{minHeight:'50vh',width:'60vw',background:'white',border:'1px solid rgb(220,220,220)'}}  onClick={(e)=>{e.stopPropagation();}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',padding:'10px'}}>
                    <div>Add Missing Offer</div>
                    <div onClick={()=>{props.setAddMissingOfferPopup(false);}}><Close/></div>
                </div>
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',border:'1px solid rgb(220,220,220)'}}/>
                
                <div style={{margin:'20px'}}>
                    {missingDataFields.map((dataFieldItem,dataFieldIndex)=>{
                        return <div style={{display:'flex',alignItems:'center',margin:'10px'}} key={dataFieldIndex}>
                                <div style={{width:'200px',fontWeight:'500'}}>
                                    {dataFieldItem.label}
                                </div>
                                <div style={{minWidth:'200px'}}>
                                    {dataFieldItem.type==='textfield'
                                    &&
                                    <CssTextField type={dataFieldItem.validation ? dataFieldItem.validation : ''} size='small' fullWidth onChange={(e)=>{updateMissingData(e.target.value,dataFieldItem)}}/>}
                                    {dataFieldItem.type==='select'
                                    &&
                                    <ReactSelect 
                                        isClearable
                                        options={dataFieldItem.options}
                                        // value={filterData[dataFieldItem.value] ? filterData[dataFieldItem.value] : ''}
                                        isMulti={false}
                                        onChange={(e)=>{updateMissingData(e.value,dataFieldItem)}}
                                        menuPortalTarget={document.body} 
                                        styles={{...ReactSelectStyles}}/>
                                    }
                                </div>
                            </div>
                    })}
                    <Button disabled={buttonDisabled()} onClick={()=>{addOfferToBilling()}} sx={{...Constants.CONTAINEDBUTTON,marginLeft:'10px',marginTop:'20px',minWidth:'200px'}}>
                        {requestLoader
                        &&
                        <CircularProgress size={'15px'} sx={{color:'white'}}/>
                        }
                        {!requestLoader && '+ Add Offer'}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default MissingOfferComponent