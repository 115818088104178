import React from "react";
import "../../css/pages/affiliate.css";
import PropTypes from 'prop-types';
import { NavLink } from "react-router-dom";

import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import * as Constants from '../../data/constants';
import { Store } from 'react-notifications-component';
import { getLocalStorageValue, sorting } from "../../util/util";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import axios from 'axios';
import { increase_brightness } from "../../components/customization/customization";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function useForceUpdate(){
    const [value, setValue] = React.useState(0); // integer state
    if(false){
      console.log(value)
    }
    return () => setValue(value => value + 1); // update the state to force render
  }

const EditAffiliatePage = props => {

    const forceUpdate = useForceUpdate()

    const affiliateId = window.location.href.split("/")[window.location.href.split("/").length-1];

    const [tabValue, setTabValue] = React.useState(0);
    const [manualUseState, setManualUseState] = React.useState(false);

    const [email, setEmail] = React.useState("");
    const [newPassword, setNewPassword] = React.useState("");
    // const [username, setUsername] = React.useState("");
    const [companyName, setCompanyName] = React.useState("");
    // const [deviceID, setDeviceId] = React.useState("");
    const [contactPerson, setContactPerson] = React.useState("");
    const [contactMobile, setContactMobile] = React.useState("");
    const [contactEmail, setContactEmail] = React.useState("");
    const [managerID, setManagerID] = React.useState(null);
    const [affiliateStatus, setAffiliateStatus] = React.useState("active");
    const [skype, setSkype] = React.useState("");
    const [note, setNote] = React.useState("");
    // const [site, setSite] = React.useState("");
    // const [tags, setTags] = React.useState("");
    const [address, setAddress] = React.useState({country:"India"});
      
    const [saveButtonDisabled, setSaveButtonDisabled] = React.useState(true); 
    const [requiredString, setRequireString] = React.useState("");
    const [managerList, setManagerList] = React.useState([]);

    const [showResponsePopup, setShowResponsePopup] = React.useState(false);
    const [responsePopup, setResponsePopup] = React.useState(false);

    const [responsePopupError, setResponsePopupError] = React.useState(false);
    
    // const [localPostback, setLocalPostback] = React.useState(false);
    const [globalPostback, setGlobalPostback] = React.useState(false);
    const [editedGlobalPostback, setEditedGlobalPostback] = React.useState(false);
    const [globalPostbackFetchedBlank, setGlobalPostbackFetchedBlank] = React.useState(false);
    const [apiKey, setApiKey] = React.useState(false);
    
    const [disableAddButton, setDisableAddButton] = React.useState(false);
    
    const [advertisersList, setAdvertisersList] = React.useState([]);
    const [disabledAdvertisers, setDisabledAdvertisers] = React.useState([]);
    const [disabledAdvertisersChanged, setDisabledAdvertisersChanged] = React.useState(false);
    const [pendingDisableAdvertiser, setPendingDisableAdvertisers] = React.useState([]);
    const [pendingEnableAdvertiser, setPendingEnableAdvertisers] = React.useState([]);
    const [disableOfferType, setDisableOfferType] = React.useState([]);
    const [tags, setTags] = React.useState([]);
    const [tagsOptions, setTagsOptions] = React.useState([]);

    const handleTabValue = (event, newValue) => {
        setTabValue(newValue);
    };

    const showAffifliateRequest = async() => {
      await axios({
          method: 'GET',
          url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.AFFILIATES),
          headers:{
              Authorization:getLocalStorageValue('adminPanelLoginData').token
          },
          params:{
            id:affiliateId,
          }
      }
      )
      .then((resp) => {
          var responseResult = resp.data.result

          for (var item in responseResult){
              if (responseResult[item].id.toString() === affiliateId){
                setCompanyName(responseResult[item].company)
                setContactEmail(responseResult[item].contact_email ? responseResult[item].contact_email : "")
                setContactMobile(responseResult[item].contact_number ? responseResult[item].contact_number : "")
                setContactPerson(responseResult[item].contact_person ? responseResult[item].contact_person : "")
                if (responseResult[item].manager_name){
                  setManagerID({label:responseResult[item].manager_name.split(") ")[1],value:responseResult[item].manager_name.split(")")[0].split("(")[1]})
                }
                setNote(responseResult[item].note ? responseResult[item].note : "")
                setSkype(responseResult[item].skype_id ? responseResult[item].skype_id : "")
                setAffiliateStatus(responseResult[item].affiliate_status ? responseResult[item].affiliate_status : "active")
                // setSite(responseResult[item].website)
                setEmail(responseResult[item].email)
                setAddress(responseResult[item].address ? responseResult[item].address : {country:"India"})
                var tagsList = []
                for(var index in responseResult[item].tags){
                  tagsList.push({label:responseResult[item].tags[index],value:responseResult[item].tags[index]})
                }
                setTags(tagsList)
                }}
      })
      .catch(error => {
          console.error(error);
          setDisableAddButton(false)
          setResponsePopupError("getAffiliate")
          if (error.response) {
            // Request made and server responded
            setResponsePopup(error.response.data.result);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else {
            // Something happened in setting up the request that triggered an Error
            setResponsePopup(JSON.stringify({error:error.message}));
            console.log('Error', error.message);
          }
          setShowResponsePopup(true)
      });
    };
    const viewDisabledAdvertiser = async(advertiserDict) => {
        
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"viewdisabledadvertiser"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
            affiliate_id:affiliateId,
            }
        }
        )
        .then((resp) => {
          var tempResponse = resp.data.result
          var tempDisabledAdvertiser = []
          for(var index in tempResponse){
            tempDisabledAdvertiser.push({
                label : advertiserDict[tempResponse[index]],
                value : tempResponse[index].toString()
            })
          }
          setDisabledAdvertisers(tempDisabledAdvertiser)

        })
        .catch(error => {
            console.error(error);
            setDisableAddButton(false)
            setResponsePopupError("getAffiliate")
            if (error.response) {
            // Request made and server responded
            setResponsePopup(error.response.data.result);
            console.log(error.response.status);
            console.log(error.response.headers);
            } else {
            // Something happened in setting up the request that triggered an Error
            setResponsePopup(JSON.stringify({error:error.message}));
            console.log('Error', error.message);
            }
            setShowResponsePopup(true)
        });
    }
    const changeDisableAdvertiser = async() => {
        var statusDict = {
            enable : pendingEnableAdvertiser,
            disable : pendingDisableAdvertiser,
        }
        Object.keys(statusDict).forEach(async(status)=>{
            var tempAdvertiserIds = ""
            for(var index in statusDict[status]){
            tempAdvertiserIds+=statusDict[status][index] + ","
            }
            tempAdvertiserIds = tempAdvertiserIds.slice(0,-1)
            if(tempAdvertiserIds){
                await axios({
                    method: 'POST',
                    url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"disabledadvertiser"),
                    headers:{
                        Authorization:getLocalStorageValue('adminPanelLoginData').token,
                        "Content-Type":"application/json",
                    },
                    data:{
                        affiliate_id:affiliateId,
                        advertiser_id:tempAdvertiserIds,
                        status:status
                    }
                })
                .then((resp) => {
                    if(status==='enable'){
                        setPendingEnableAdvertisers([]);
                    }
                    if(status==='disable'){
                        setPendingDisableAdvertisers([]);
                    }
                })
                .catch(error => {
                    console.error(error);
                    setDisableAddButton(false)
                    setResponsePopupError("getAffiliate")
                    if (error.response) {
                    // Request made and server responded
                    setResponsePopup(error.response.data.result);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    setResponsePopup(JSON.stringify({error:error.message}));
                    console.log('Error', error.message);
                    }
                    setShowResponsePopup(true)
                });
            }
        })
    }
    const viewDisabledOfferTypes = async() => {
        
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"affiliate/viewofferfilteration"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
            affiliate_id:affiliateId,
            }
        }
        )
        .then((resp) => {
            if(typeof resp.data.result === 'object'){
              setDisableOfferType(resp.data.result)
            }
            else{
              setDisableOfferType([])
            }
        })
        .catch(error => {
            console.error(error);
            setDisableAddButton(false)
            setResponsePopupError("getAffiliate")
            if (error.response) {
            // Request made and server responded
            setResponsePopup(error.response.data.result);
            console.log(error.response.status);
            console.log(error.response.headers);
            } else {
            // Something happened in setting up the request that triggered an Error
            setResponsePopup(JSON.stringify({error:error.message}));
            console.log('Error', error.message);
            }
            setShowResponsePopup(true)
        });
    }
    const changeDisabledOfferTypes = async() => {

      await axios({
          method: 'POST',
          url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"affiliate/offerfilteration"),
          headers:{
              Authorization:getLocalStorageValue('adminPanelLoginData').token,
              "Content-Type":"application/json",
          },
          data:{
            affiliate_id : affiliateId,
            offerfilter: disableOfferType,
          }
      })
      .then((resp) => {
          
      })
      .catch(error => {
          console.error(error);
          setDisableAddButton(false)
          setResponsePopupError("getAffiliate")
          if (error.response) {
          // Request made and server responded
          setResponsePopup(error.response.data.result);
          console.log(error.response.status);
          console.log(error.response.headers);
          } else {
          // Something happened in setting up the request that triggered an Error
          setResponsePopup(JSON.stringify({error:error.message}));
          console.log('Error', error.message);
          }
          setShowResponsePopup(true)
      });
    }

    const changePassword = async() => {
      await axios({
          method: 'POST',
          url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"affiliate/changepassword"),
          headers:{
              Authorization:getLocalStorageValue('adminPanelLoginData').token,
              "Content-Type":"application/json",
          },
          data:{
            email : email,
            new_password: newPassword,
          }
      })
      .then((resp) => {
          
      })
      .catch(error => {
        setDisableAddButton(false)
        setResponsePopupError(true)
        if (error.response) {
          // Request made and server responded
          Store.addNotification({
            title: "Error!",
            message: error.response.data.result,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          setResponsePopup(error.response.data.result);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else {
          // Something happened in setting up the request that triggered an Error
          setResponsePopup(JSON.stringify({error:error.message}));
          console.log('Error', error.message);
        }
        setShowResponsePopup(true)
      });
    }
  const editAffiliateRequest = async() => {
    setDisableAddButton(true)
    var tagList = []
    for(var index in tags){
      tagList.push(tags[index].value)
    }
    await axios({
      method: 'POST',
      url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.AFFILIATE,"/",affiliateId,"/",Constants.CHANGE),
      headers:{
          Authorization:getLocalStorageValue('adminPanelLoginData').token,
          "Content-Type":"application/json",
      },
      data:{
        "email":email,
        // "username": username,
        "company_name": companyName,
        // "device_id": deviceID,
        "address": address,
        "affiliate_status": affiliateStatus ? affiliateStatus.toLowerCase() : "active",
        "contact_number" : contactMobile ? contactMobile : null,
        "contact_person" : contactPerson ? contactPerson : null,
        "manager_id": managerID ? managerID.value : null,
        "note": note ? note : null,
        "skype_id" : skype ? skype : null,
        "tags": tagList,  
        sub_id:[],
        }
      })
    .then(async(resp)=>{
      if(resp.data.result && resp.data.result.affiliate)
        { 
          
          setResponsePopupError(false);
          if(newPassword){
            changePassword();
          }
          changeDisabledOfferTypes();

          if(disabledAdvertisersChanged){
            await changeDisableAdvertiser();
          }
          if(editedGlobalPostback){
            setDisableAddButton(false)
            editPostbackRequest();
          }
          else{
            if(updatePostbacks.length>0){
                updateEventPostbacks();
            }
            else{
                Store.addNotification({
                  title: "Success!",
                  message: "Affiliate edited successfully",
                  type: "success",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                    duration: 1000,
                    onScreen: true
                  },
                  onRemoval: (id, removedBy) => {
                    // window.location.href = "/affiliates/management/"
                    setDisableAddButton(false)
                  }
                });
            }
          }
        }
      else{
        setDisableAddButton(false)
        Store.addNotification({
          title: "Error!",
          message: typeof resp.data.result === "string" ? resp.data.result : "Some error occured",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        }
      }
    )
    .catch(function (error) {
      setDisableAddButton(false)
      setResponsePopupError(true)
      if (error.response) {
        // Request made and server responded
        Store.addNotification({
          title: "Error!",
          message: error.response.data.result,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        setResponsePopup(error.response.data.result);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else {
        // Something happened in setting up the request that triggered an Error
        setResponsePopup(JSON.stringify({error:error.message}));
        console.log('Error', error.message);
      }
      setShowResponsePopup(true)
    });
  }

  const editPostbackRequest = async() => {
    var url_end = "change"
    if (globalPostbackFetchedBlank){
      url_end = "add"
    }
    await axios({
      method: 'POST',
      url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"postbacks/",url_end),
      headers:{
          Authorization:getLocalStorageValue('adminPanelLoginData').token,
          "Content-Type":"application/json",
      },
      data:{
        "affiliate_id" : affiliateId, 
        "postback_url" : globalPostback
        }
      })
    .then((resp)=>{
        if(updatePostbacks.length>0){
            updateEventPostbacks();
        }
        else{
            if(resp.data.result && typeof resp.data.result !== ("string"))
            { 
                Store.addNotification({
                title: "Success!",
                message: "Affilate added and Postback added successfully",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 1000,
                    onScreen: true
                },
                onRemoval: (id, removedBy) => {
                    // window.location.href = "/affiliates/management/"
                    setDisableAddButton(false)
                }
                });}
            else{
                setDisableAddButton(false)
                Store.addNotification({
                title: "Error!",
                message: typeof resp.data.result === "string" ? resp.data.result : "Some error occured",
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
                });
                }
            }
        }
    )
    .catch(function (error) {
      setDisableAddButton(false)
      setResponsePopupError(true)
      if (error.response) {
        // Request made and server responded
        setResponsePopup(error.response.data.result);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else {
        // Something happened in setting up the request that triggered an Error
        setResponsePopup(JSON.stringify({error:error.message}));
        console.log('Error', error.message);
      }
      setShowResponsePopup(true)
  
    });
  }

  const getManagers = async() => {
    await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"manager","/","details"),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
    })
    .then(resp => {
      let managerlist = []
      for (var item in resp.data.result){
            managerlist.push({label:resp.data.result[item][Object.keys(resp.data.result[item])[0]],value:Object.keys(resp.data.result[item])[0]})
          }
          sorting(managerlist,'label',false,true)
          setManagerList(managerlist)
    })
    .catch(err => {
        console.error(err);
    })
  };

  const getTags = async() => {
    await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"affiliate/tags/details"),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
    })
    .then(resp => {
      let managerlist = []
      for (var item in resp.data.result){
            managerlist.push({label:resp.data.result[item][Object.keys(resp.data.result[item])[0]],value:Object.keys(resp.data.result[item])[0]})
          }
          sorting(managerlist,'label',false,true)
          setTagsOptions(managerlist)
    })
    .catch(err => {
        console.error(err);
    })
  };
  const getAdvertisers = async() => {
    await axios({
      method: 'GET',
      url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.ADVERTISER,"/","details"),
      headers:{
          Authorization:getLocalStorageValue('adminPanelLoginData').token
      },
    })
    .then(resp => {
        let advertiserlist = []
        var advertiserDict = {}
        for (var item in resp.data.result){
            advertiserDict = {...advertiserDict,[Object.keys(resp.data.result[item])[0]] : resp.data.result[item][Object.keys(resp.data.result[item])[0]] + " - " + Object.keys(resp.data.result[item])[0]}
            advertiserlist.push({label:resp.data.result[item][Object.keys(resp.data.result[item])[0]] + " - " + Object.keys(resp.data.result[item])[0],value:Object.keys(resp.data.result[item])[0]})
        }
        sorting(advertiserlist,'label',false,true)
        setAdvertisersList(advertiserlist)
        viewDisabledAdvertiser(advertiserDict);

    })
    .catch(err => {
        console.error(err);
    })
  };
  const getApiKey = async() => {
    await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"apikey","/",affiliateId,"/","view"),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
    })
    .then(resp => {
      if(!resp.data.result.includes(" ")){
        setApiKey(resp.data.result);
      }
    })
    .catch(err => {
        console.error(err);
    })
  };

  const handleGenerateApi = async() => {
    var url_end = "change"
    if (!apiKey){
      url_end = "add"
    }
    await axios({
      method: 'POST',
      url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"apikey/",url_end),
      headers:{
          Authorization:getLocalStorageValue('adminPanelLoginData').token,
          "Content-Type":"application/json",
      },
      data:{
        "affiliate_id" : affiliateId, 
        }
      })
    .then((resp)=>{
        setDisableAddButton(false)
        setApiKey(resp.data.result.api_key)
      }
    )
    .catch(function (error) {
      setDisableAddButton(false)
      setResponsePopupError(true)
      if (error.response) {
        // Request made and server responded
        setResponsePopup(error.response.data.result);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else {
        // Something happened in setting up the request that triggered an Error
        setResponsePopup(JSON.stringify({error:error.message}));
        console.log('Error', error.message);
      }
  
    });
  }
  const getPostback = async() => {
    await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.POSTBACKS),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
        params:{
          affiliate_id : affiliateId
        }
    })
    .then(resp => {
      if(resp.data.result[0].global_postback.length===0){
        setGlobalPostbackFetchedBlank(true)
      }
      setGlobalPostback(resp.data.result[0].global_postback[0])
    //   setLocalPostback(resp.data.result[0].local_postback[0])
    })
    .catch(err => {
        console.error(err);
    })
  };

  var [eventPostbacks,setEventPostbacks] = React.useState([]);
  
    
  const getEventPostbacks = async() => {
      axios({
          method: 'GET',
          url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"eventpostbacks"),
          headers:{
              Authorization:getLocalStorageValue('adminPanelLoginData').token
          },
          params:{
              affiliate_id:affiliateId
          }
      })
      .then(resp => {
            var tempOffersList = ""
            for(var index in resp.data.result){
                var offerid = resp.data.result[index].offer_id + ","
                if(!tempOffersList.includes(offerid)){
                    tempOffersList+=offerid
                }
            }
            setEventPostbacks(resp.data.result)
            getGoalList(tempOffersList);

      })
      .catch(err => {
          console.error(err);
      }) 
  };  

  var [goalList,setGoalList] = React.useState([]);
  var [selectGoalList,setSelectGoalList] = React.useState([{label:"Any",value:'any'}]);

  const getGoalList = async(offerids) => {
      axios({
          method: 'GET',
          url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"offergoals"),
          headers:{
              Authorization:getLocalStorageValue('adminPanelLoginData').token
          },
          params:{
            offer_ids:offerids.slice(0,offerids.length-1)
          }
      })
      .then(resp => {
          setGoalList(resp.data.result)
          if(Object.keys(resp.data.result).length===1){
            var tempGoalList = [{label:'Any',value:'any'}]
            var offerid = addOfferId
            for(var index in resp.data.result[offerid]){
                tempGoalList.push({label:resp.data.result[offerid][index][Object.keys(resp.data.result[offerid][index])[0]] ,value:Object.keys(resp.data.result[offerid][index])[0]});
            }
            setSelectGoalList(tempGoalList)
          }
      })
      .catch(err => {
          console.error(err);
      }) 
  };  


  var [updatePostbacks,setUpdatePostbacks] = React.useState([])
  const updateEventPostbacks = async() => {
      axios({
          method: 'POST',
          url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"eventpostbacks/update"),
          headers:{
              Authorization:getLocalStorageValue('adminPanelLoginData').token,
              "Content-type":'application/json'
          },
          data:updatePostbacks
      })
      .then(resp => {
          Store.addNotification({
              title: "Success!",
              message: "Affiliate Edited Successfully!",
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: true
              },
              onRemoval: (id, removedBy) => {
                // window.location.href = "/affiliates/management/"
                setDisableAddButton(false)
              }
            });
      })
      .catch(err => {
          console.error(err);
          if(err.response && err.response.data.result){
              Store.addNotification({
                  title: "Error!",
                  message: err.response.data.result,
                  type: "warning",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                    duration: 2000,
                    onScreen: true
                  }
                });
          }
      }) 
  };  

  if (!manualUseState){
    setManualUseState(true);
    showAffifliateRequest();
    getManagers();
    getPostback();
    getApiKey();
    getEventPostbacks();
    getAdvertisers();
    getTags();
    viewDisabledOfferTypes();
  }

  const handleNewPassword = (e) => {
    var lowerCaseLetters = /[a-z]/g;
    var upperCaseLetters = /[A-Z]/g;
    var specialCharacter = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    var number = /[0-9]/g;
    var required_string_list = []
    if (e.target.value.match(specialCharacter) 
        && e.target.value.match(number) 
        && e.target.value.match(lowerCaseLetters) 
        && e.target.value.match(upperCaseLetters)
        && e.target.value.length>=8
        && e.target.value.length<=30
        ){  
            setRequireString("")
            setNewPassword(e.target.value)
        }

    if(e.target.value.length<8){
        required_string_list.push("Less than 8 characters")
    }
    else if(e.target.value.length>30){
        required_string_list.push("More than 30 characters")
    }
    else {
        if(!e.target.value.match(specialCharacter)){
            required_string_list.push("Special Character")
        }
        if(!e.target.value.match(number)){
            required_string_list.push("Number")
        }
        if(!e.target.value.match(lowerCaseLetters)){
            required_string_list.push("Lower case alphabet")
        }
        if(!e.target.value.match(upperCaseLetters)){
            required_string_list.push("Upper case alphabet")
        }
    }
    if (required_string_list.length!==0){
        var requiredString = ""
        for (var item in required_string_list){
            requiredString += required_string_list[item] + ", "
        }
        requiredString = requiredString.slice(0,(requiredString.length-2))
        if (requiredString!=="Less than 8 characters"){
            requiredString+=" is missing"
        }
        setRequireString(requiredString)
        setNewPassword(e.target.value)
    }
  }

  const specialCharacter = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  const number = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
  const email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const skype_regex = /[a-zA-Z][a-zA-Z0-9.,\-_]{5,31}/;
  const website_regex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;

  // var lowerCaseLetters = /[a-z]/g;
  // var upperCaseLetters = /[A-Z]/g;

  const changeCountryValue = (e) => {
    setAddress({...address ,country:e.value})
  }

  const countriesOptions = Constants.COUNTRIES_LIST.map((item,index)=>{
    return (
      {label:item,value:item}
    )
  });

  if ((companyName ? companyName !== "" : false)
    && (companyName ? !companyName.match(specialCharacter) : false)
    //   && email !== "" 
    //   && newPassword !== "" 
      && (contactPerson ? contactPerson!=="" : false)
      && (contactMobile === "" || contactMobile.match(number))
      && (contactEmail === "" || contactEmail.match(email_regex))
      && (!globalPostback || globalPostback === [] || (typeof globalPostback==='string' && globalPostback.match(website_regex)))
      ){
    if (saveButtonDisabled===true){
        setSaveButtonDisabled(false)
        }
    }
    else{
        if (saveButtonDisabled===false){
        setSaveButtonDisabled(true)
        }
    }
  
  const handleSaveAndContinueAffiliateButton = () => {
    handleTabValue("continue",(tabValue<3 ? tabValue+1 : 0))
  }
  const handleSaveAffiliateButton = () => {
    editAffiliateRequest()
  }

  const disableAdvertiserChanged = (list) => {
    var tempDisableAdvertisers = pendingDisableAdvertiser
    var tempEnableAdvertisers = pendingEnableAdvertiser
    if(list.length>0){
      if(disabledAdvertisers.length>0){
        if(list.length >= disabledAdvertisers.length){
          if(!tempDisableAdvertisers.includes(list[list.length-1])){
            tempDisableAdvertisers.push(parseInt(list[list.length-1].value))
          }
          if(tempEnableAdvertisers.includes(parseInt(list[list.length-1].value))){
            tempEnableAdvertisers = tempEnableAdvertisers.filter(e=>e!==parseInt(list[list.length-1].value))
          }
        }
        else{
          for(var index in disabledAdvertisers){
            var foundFlag = true;
            for(var index2 in list){
              if(disabledAdvertisers[index].value===list[index2].value){
                foundFlag = false;
                break;
              }
            }
            if(foundFlag){
              if(!tempEnableAdvertisers.includes(parseInt(disabledAdvertisers[index].value))){
                tempEnableAdvertisers.push(parseInt(disabledAdvertisers[index].value))
                if(tempDisableAdvertisers.includes(parseInt(disabledAdvertisers[index].value))){
                    var tempList = []
                    for(var removeIndex in tempDisableAdvertisers){
                        if(tempDisableAdvertisers[removeIndex]!==parseInt(disabledAdvertisers[index].value))
                        tempList.push(tempDisableAdvertisers[removeIndex])
                    }
                    tempDisableAdvertisers = tempList
                }
              }
              break;
            }
          }
        }
      }
      else{
        tempDisableAdvertisers.push(parseInt(list[list.length-1].value))
        if(tempEnableAdvertisers.includes(parseInt(list[list.length-1].value))){
          tempEnableAdvertisers = tempEnableAdvertisers.filter(e=>e!==parseInt(list[list.length-1].value))
        }
      }
    }
    else{
      for(index in disabledAdvertisers){
        tempEnableAdvertisers.push(parseInt(disabledAdvertisers[index].value))
        if(tempDisableAdvertisers.includes(parseInt(disabledAdvertisers[index].value))){
          tempList = []
          for(removeIndex in tempDisableAdvertisers){
              if(tempDisableAdvertisers[removeIndex]!==parseInt(disabledAdvertisers[index].value))
              tempList.push(tempDisableAdvertisers[removeIndex])
          }
          tempDisableAdvertisers = tempList
        }
      }
    
    }
    setPendingDisableAdvertisers(tempDisableAdvertisers)
    setPendingEnableAdvertisers(tempEnableAdvertisers)
    setDisabledAdvertisers(list)
    setDisabledAdvertisersChanged(true)
  }
  const refreshComponent = useForceUpdate()

  const changeOfferType = (e,value) => {
    var tempDisableOfferType = disableOfferType
    if(!e.target.checked){
      tempDisableOfferType.push(value)
    }
    else{
      tempDisableOfferType = tempDisableOfferType.filter(e => e!==value)
    }
    setDisableOfferType(tempDisableOfferType);
    refreshComponent(e);
    console.log(tempDisableOfferType)
  }

  const general = () => {
    return(
      <div style={{height:"100%",overflowY:"auto",marginLeft:"1%",marginTop:"2%"}}>
        
        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>EMAIL *</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" disabled value={email ? email : "NOT AVAILABLE RIGHT NOW"} onChange={(e)=>{setEmail(e.target.value)}}/></div>
          {(email ? !email.match(email_regex)? <div style={{fontSize:"11px",display:"flex",alignItems:"center",color:"red"}}> Email id is not in correct form.</div> : null  : null)}
        </div>
        
        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>PASSWORD *</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" value={newPassword} onChange={handleNewPassword}/></div>
          <label style={{fontSize:"12px",color:"red",marginTop:"1%"}}>{requiredString!=="" ? requiredString : ""}</label>
        </div>
       
        <div>
              
        </div>
        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Company Name *</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" value={companyName ? companyName : ""}  onChange={(e)=>{setCompanyName(e.target.value)}}/></div>
          {(companyName ? companyName.match(specialCharacter) ? <div style={{fontSize:"11px",display:"flex",alignItems:"center",color:"red"}}> Special characters are not allowed in company name.</div> : null  : null)}
        </div>

        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Contact Person Name * </div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" value={contactPerson ? contactPerson : ""}  onChange={(e)=>{setContactPerson(e.target.value)}}/></div>
          {(contactPerson ? contactPerson.match(specialCharacter) || contactPerson.match(number) ? <div style={{fontSize:"11px",display:"flex",alignItems:"center",color:"red"}}> Special characters and numbers are not allowed in contact person name.</div> : null  : null)}
       </div>
        {/* <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Username *</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" onChange={(e)=>{setUsername(e.target.value)}}/></div>
        </div> */}
        <div style={{display:"flex",marginTop:"4px",marginBottom:"4px"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Affiliate Status</div>
          <div style={{width:"32%",height:"50%",marginLeft:"4%"}}>
           <select style={{width:"100%",height:"30px",border: "1px solid rgb(225,225,225)",paddingLeft:"3%"}} value={affiliateStatus} onChange={(e)=>{setAffiliateStatus(e.target.value)}}>
              <option value={"active"}>Active</option>
              <option value={"notactive"}>Not Active</option>
              <option value={"banned"}>Banned</option>
              <option value={"moderation"}>On Moderation</option>
            </select>
          </div>
        </div>
        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Manager</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}>
            {!managerID && <Select options={managerList}
              defaultValue={managerID ? {label:managerID.label,value:managerID.value} : null}
              onChange={(e)=>{setManagerID(e)}}
              menuPortalTarget={document.body} 
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
              option: styles => ({...styles,fontSize:"12px"}) 
               }}/>
            }
            
            {managerID && <Select options={managerList}
              defaultValue={managerID ? {label:managerID.label,value:managerID.value} : null}
              onChange={(e)=>{setManagerID(e)}}
              menuPortalTarget={document.body} 
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                        option: styles => ({...styles,fontSize:"12px"}) 
                      }}/>
            }
          </div>
        </div>
        <div style={{display:"flex",marginTop:'10px'}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Disabled Advertiser</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}>
              <Select
                isMulti
                placeholder='Disabled Advertisers'
                options={advertisersList}
                value={disabledAdvertisers}
                onChange={(e)=>{disableAdvertiserChanged(e)}}
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                            control: (base,state) => ({
                                ...base,
                                // maxHeight: 100,
                                minHeight: 30,
                                overflowY: "auto",
                                border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid grey',
                                boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                '&:hover': {
                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                    boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                }
                            }),
                            option: (styles, {isFocused, isSelected}) => ({
                                ...styles,
                                fontSize:'11px',
                                background: isFocused
                                    ? localStorage.getItem('ambientColor')
                                    : isSelected
                                        ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                        : undefined,
                                zIndex: 1
                            }),
                        }}
            />
          </div>
        </div>
        <div style={{display:"flex",marginTop:'10px'}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Enabled Offer Type</div>
          <div style={{transform:"scale(0.8)",width:"40%",display:'flex',alignItems:'center'}}>
            <input id='public' type='checkbox' checked={disableOfferType.includes('public') ? false : true} onChange={(e)=>{changeOfferType(e,'public')}}/>
            <label style={{marginRight:'10px'}} htmlFor="public">Public</label>
            <input id='private' type='checkbox' checked={disableOfferType.includes('private') ? false : true} onChange={(e)=>{changeOfferType(e,'private')}}/>
            <label style={{marginRight:'10px'}} htmlFor="private">Private</label>
            <input id='protected' type='checkbox' checked={disableOfferType.includes('protected') ? false : true} onChange={(e)=>{changeOfferType(e,'protected')}}/>
            <label style={{marginRight:'10px'}} htmlFor="protected">Requested</label>
          </div>
        </div>

        <div style={{display:"flex",marginTop:'10px'}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Tags</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}>
              <CreatableSelect
                isMulti
                placeholder='Tags'
                options={tagsOptions}
                value={tags}
                onChange={(e)=>{setTags(e)}}
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                            control: (base,state) => ({
                                ...base,
                                // maxHeight: 100,
                                minHeight: 30,
                                overflowY: "auto",
                                border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid grey',
                                boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                '&:hover': {
                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                    boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                }
                            }),
                            option: (styles, {isFocused, isSelected}) => ({
                                ...styles,
                                fontSize:'11px',
                                background: isFocused
                                    ? localStorage.getItem('ambientColor')
                                    : isSelected
                                        ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                        : undefined,
                                zIndex: 1
                            }),
                        }}
            />
          </div>
        </div>
        {/* <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Site</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" onChange={(e)=>{setSite(e.target.value)}}/></div>
        </div> */}
        {/* <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Note</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField multiline rows={4} fullWidth size="small" value={note ? note : ""} onChange={(e)=>{setNote(e.target.value)}}/></div>
        </div> */}
      </div>
    )
  }

  const contact = () => {
    return(
      <div style={{height:"100%",overflowY:"auto",marginLeft:"1%",marginTop:"2%"}}>
        
        <div style={{display:"flex"}}>
        <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Skype</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" value={skype ? skype : ""}  onChange={(e)=>{setSkype(e.target.value)}}/></div>
          {(skype ? !skype.match(skype_regex)? <div style={{fontSize:"11px",display:"flex",alignItems:"center",color:"red"}}> Skypeid is incorrect.</div> : null  : null)}
        </div>
        
        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Contact Person Number</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" value={contactMobile ? contactMobile : ""} onChange={(e)=>{setContactMobile(e.target.value)}}/></div>
          {(contactMobile ? !contactMobile.match(number)? <div style={{fontSize:"11px",display:"flex",alignItems:"center",color:"red"}}> Contact number is incorrect.</div> : null  : null)}
        </div>
        {/* <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Contact Person Email</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" value={contactEmail ? contactEmail : ""} onChange={(e)=>{setContactEmail(e.target.value)}}/></div>
          {(contactEmail ? !contactEmail.match(email_regex)? <div style={{fontSize:"11px",display:"flex",alignItems:"center",color:"red"}}> Email id is not in correct form.</div> : null  : null)}
        </div> */}
        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Address 1</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" multiline rows={2} value={address ? address.address1 ? address.address1 : "" : ""} onChange={(e)=>{setAddress({...address ,address1:e.target.value})}}/></div>
        </div>
        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Address 2</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" multiline rows={2} value={address ? address.address2 ? address.address2 : "" : ""} onChange={(e)=>{setAddress({...address ,address2:e.target.value})}}/></div>
        </div>
        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>City</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" value={address ? address.city ? address.city : "" : ""} onChange={(e)=>{setAddress({...address ,city:e.target.value})}}/></div>
        </div>
        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Country</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}>
            <Select className="selectCountryList" options={countriesOptions}
              defaultValue={address ? address.country ? {label:address.country,value:address.country} : null : null}

              onChange={changeCountryValue}
              menuPortalTarget={document.body} 
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                      option: styles => ({...styles,fontSize:"12px"}) }}/>
          </div>
        </div>
        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Zip Code</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" value={address ? address.zipcode ? address.zipcode : "" : ""} onChange={(e)=>{setAddress({...address ,zipcode:e.target.value})}}/></div>
        </div>
      </div>
    )
  }

    // const postback = () => {
    //     return(
    //     <div style={{height:"100%",overflowY:"auto",marginLeft:"1%",marginTop:"2%"}}>
            
    //         <div style={{display:"flex"}}>
    //         <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Global Postback</div>
    //         <div style={{transform:"scale(0.8)",width:"80%"}}>
    //             <TextField multiline fullWidth size="small" value={globalPostback ? globalPostback : ""} onChange={(e)=>{setGlobalPostback(e.target.value);setEditedGlobalPostback(true)}}/>
    //             {(globalPostback ? (!globalPostback.match(website_regex))? <div style={{fontSize:"11px",display:"flex",alignItems:"center",color:"red"}}> Postback is not in correct form.</div> : null  : null)}
    //         </div>
    //         </div>

    //         <div style={{display:"flex"}}>
    //         <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Local Postback</div>
    //         <div style={{transform:"scale(0.8)",width:"80%"}}>
    //             <TextField multiline fullWidth size="small" value={localPostback ? localPostback : ""} disabled/>
    //         </div>
    //         </div>
            
    //     </div>
    //     )
    // }

    const removePostback = (e,key,value) => {
        if(eventPostbacks.includes(value)){
            value['action'] = 'delete'
            var tempEventPostbacks = eventPostbacks
            tempEventPostbacks = tempEventPostbacks.filter(e => e!==value);
            setEventPostbacks(tempEventPostbacks)
            var tempUpdatePost = updatePostbacks
            var duplicateFlag = false
            for(var index in tempUpdatePost){
                var postback = tempUpdatePost[index]
                if(postback.action === value.action 
                    &&
                    postback.id === value.id 
                    &&
                    postback.postback_url === value.postback_url 
                    &&
                    postback.offer_id === value.offer_id 
                    &&
                    postback.goal_value === value.goal_value 
                    &&
                    postback.payout_status === value.payout_status 
                    &&
                    postback.affiliate_id === value.affiliate_id 
                    )
                {
                    duplicateFlag = true
                }
            }
            if(duplicateFlag){
                tempUpdatePost = tempUpdatePost.filter(e => e!==value);
            }
            else{
                tempUpdatePost.push(value)
            }
            setUpdatePostbacks(tempUpdatePost)
            forceUpdate(e)
        }
    };

    const addEventPostback = (e) => {
        var tempEventPostbacks = eventPostbacks
        if(addOfferId!=="" && addUrl!==""){
            var value = {
                "id": "",
                "postback_url": addUrl,
                "offer_id": addOfferId,
                "goal_value": addGoalValue.value,
                "payout_status": addStatus.value,
                "affiliate_id": affiliateId
            }
            var duplicateFlag = false
            for(var index in tempEventPostbacks){
                var postback = tempEventPostbacks[index]
                if(postback.id === value.id 
                    &&
                    postback.postback_url === value.postback_url 
                    &&
                    postback.offer_id === value.offer_id 
                    &&
                    postback.goal_value === value.goal_value 
                    &&
                    postback.payout_status === value.payout_status 
                    &&
                    postback.affiliate_id === value.affiliate_id 
                    )
                {
                    duplicateFlag = true
                }
            }
            if(!duplicateFlag){
                tempEventPostbacks.push(
                    value
                )
                setEventPostbacks(tempEventPostbacks)
                value['action']='add'
                var tempUpdatePost = updatePostbacks
                tempUpdatePost.push(value)
                setUpdatePostbacks(tempUpdatePost)
            }
            else{
                Store.addNotification({
                    title: "Warning!",
                    message: "Duplicate Event Postback",
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true
                    }
                  });
            }
            forceUpdate(e)
        }   
        else{
            Store.addNotification({
                title: "Warning!",
                message: "Affiliate and Url Cannot be empty!",
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2000,
                  onScreen: true
                }
              });
        }
    }

    const [addUrl, setAddUrl] = React.useState("")
    const [addOfferId, setAddOfferId] = React.useState("")
    const [addStatus, setAddStatus] = React.useState({label:'Any',value:'any'})
    const [addGoalValue, setAddGoalValue] = React.useState({label:'Any',value:'any'})
    const [addGoalTitle, setAddGoalTitle] = React.useState("Any")
    const [enableGoalTitle, setEnableGoalTitle] = React.useState(true)
    const [goalTitlesDict, setGoalTitlesDict] = React.useState({})
    const [showMacrosClicked, setShowMacrosClicked] = React.useState(false)
    var macrosDetails = {
      "{goal_value}": "Goal Value",
      "{currency}": "Currency",
      "{payout}": "Payout",
      "{ip}": "ip",
      "{offer_name}": "offer title",
      "{offer_id}": "offer id",
    }
    const postbacks = () => {
        var tempPostbackDict = {}
        for(var index in eventPostbacks){
            if(!tempPostbackDict[eventPostbacks[index].offer_id]){
                tempPostbackDict[eventPostbacks[index].offer_id] = []
            }
            tempPostbackDict[eventPostbacks[index].offer_id].push(
                eventPostbacks[index]
            )
        }

        var tempGoalDict = goalTitlesDict
        if(Object.keys(goalList).length>0){
            for(var index2 in goalList){
                if(!tempGoalDict[index2]){
                    tempGoalDict[index2] = {}
                }
                for(var index3 in goalList[index2]){
                    if(!tempGoalDict[index2][Object.keys(goalList[index2][index3])[0]]){
                        tempGoalDict[index2][Object.keys(goalList[index2][index3])[0]] = goalList[index2][index3][Object.keys(goalList[index2][index3])[0]]
                    }
                }
            }
        }

        return <div>
            <div style={{marginLeft:'5%',marginRight:'5%',padding:'1%',marginTop:'2%',border:'1px solid rgb(180,180,180)'}}>
                <div style={{fontSize:"19px",fontWeight:'600',color:'rgb(100,100,100)'}}>Add Global Postback
                  
                </div>
                <div style={{display:'flex',padding:'15px'}}>   
                    <TextField multiline InputProps={{ style: { fontSize: "12px" } }} fullWidth size="small" value={globalPostback ? globalPostback : ""} onChange={(e)=>{setGlobalPostback(e.target.value);setEditedGlobalPostback(true)}}/>
                    <div style={{marginLeft:'1%',cursor:'pointer',zIndex:10000}} onClick={()=>{setShowMacrosClicked(!showMacrosClicked)}}>
                        <div style={{fontSize:'13px',color:localStorage.getItem('ambientColor')}}>Show Macros</div>
                        {showMacrosClicked && 
                            <div style={{position:'absolute',paddingTop:'10px',marginLeft:'-5%',fontWeight:'400',color:'black'}}>
                                <div style={{minHeight:'100px',minWidth:'100px',width:'max-content',padding:'10px',background:'white',boxShadow:'0 0 5px 1px grey'}} onClick={(e)=>{e.stopPropagation();}}>
                                    <table>
                                        <tbody>
                                        {Object.keys(macrosDetails).map((item,index)=>{
                                            return <tr style={{fontSize:'12px'}} key={index}>
                                                        <td style={{textAlign:'center',paddingTop:'2px'}}>{item}</td> 
                                                        <td>-</td> 
                                                        <td style={{paddingLeft:'10px'}}>{macrosDetails[item]}</td>
                                                    </tr>
                                        })}
                                        </tbody>
                                    </table>
                                    
                                </div>
                            </div>
                        }
                    </div>
                    {(globalPostback ? (!globalPostback.match(website_regex))? <div style={{fontSize:"11px",display:"flex",alignItems:"center",color:"red"}}> Postback is not in correct form.</div> : null  : null)}
                    
                </div>
                
            </div>
            <div style={{marginLeft:'5%',marginRight:'5%',padding:'1%',marginTop:'2%',border:'1px solid rgb(180,180,180)'}}>
                <div style={{fontSize:"19px",fontWeight:'600',color:'rgb(100,100,100)'}}>Add Event Postback</div>
                <div style={{display:'flex'}}>
                    <table style={{width:'100%',marginTop:'1%'}}>
                        <thead>
                            <tr>
                                {/* <td>ID</td> */}
                                <td style={{background:'rgb(255,184,52)',padding:'5px',fontSize:'14px',maxWidth:'40%'}} width={"25%"}>Offer Id</td>
                                <td style={{background:'rgb(255,184,52)',padding:'5px',fontSize:'14px',maxWidth:'40%'}} width={"25%"}>Url</td>
                                <td style={{background:'rgb(255,184,52)',padding:'5px',fontSize:'14px',maxWidth:'20%'}} width={"16%"}>Status</td>
                                <td style={{background:'rgb(255,184,52)',padding:'5px',fontSize:'14px',maxWidth:'18%'}} width={"15%"}>Goal Title</td>
                                <td style={{background:'rgb(255,184,52)',padding:'5px',fontSize:'14px',maxWidth:'18%'}} width={"15%"}>Goal Value</td>
                                <td style={{background:'rgb(255,184,52)',padding:'5px',fontSize:'14px',maxWidth:'4%'}} width={"4%"}>Add</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                {/* <td>{item2.id}</td> */}
                                <td style={{fontSize:'12px',padding:'5px'}}>
                                    {/* <Select
                                        value={addAffiliate ? addAffiliate : ""}
                                        options={affiliateList}
                                        onChange={(e)=>{setAddAffiliate(e)}}
                                    /> */}
                                    <div><TextField InputProps={{ style: { fontSize: "12px" } }} size='small' fullWidth value={addOfferId ? addOfferId : ""} onChange={(e)=>{setAddOfferId(e.target.value);setEnableGoalTitle(true)}} onKeyDown={(e)=>{if(e.key==="Enter"){setGoalTitlesDict(tempGoalDict);getGoalList(e.target.value+",").then(resp=>{setEnableGoalTitle(false)})}}}/></div>
                                </td>
                                <td style={{fontSize:'12px',padding:'5px'}}>
                                    <div><TextField InputProps={{ style: { fontSize: "12px" } }} size='small' fullWidth value={addUrl ? addUrl : ""} onChange={(e)=>{setAddUrl(e.target.value)}}/></div>
                                </td>
                                <td style={{fontSize:'12px',padding:'5px'}}>
                                    <Select
                                        options={[{label:'Any',value:'any'},{label:'Approved',value:'approved'},{label:'Declined',value:'declined'}]}
                                        value={addStatus}
                                        onChange={(e)=>{setAddStatus(e)}}
                                    />
                                </td>
                                <td style={{fontSize:'12px',padding:'5px'}}>
                                    <Select
                                        isDisabled={enableGoalTitle}
                                        options={selectGoalList}
                                        value={addGoalValue}
                                        onChange={(e)=>{setAddGoalValue(e);setAddGoalTitle(e.value);}}
                                    />
                                </td>
                                <td style={{fontSize:'12px',padding:'5px'}}>
                                    <div><TextField size='small' InputProps={{ style: { fontSize: "12px" } }}  fullWidth value={addGoalTitle} disabled/></div>
                                </td>
                                <td style={{fontSize:'12px',padding:'5px',color:'green',height:'40px',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={addEventPostback}><AddIcon/></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
            </div>
            {Object.keys(tempPostbackDict).map((item,index)=>{
                return <div key={index} style={{marginLeft:'5%',marginRight:'5%',padding:'2%',marginTop:'2%',border:'1px solid rgb(180,180,180)'}}>
                    <div style={{fontSize:"19px",fontWeight:'600',color:'rgb(100,100,100)'}}>{item}</div>
                    <div style={{display:'flex'}}>
                        <table style={{width:'100%',marginTop:"1%"}}>
                            <thead>
                                <tr>
                                    {/* <td>ID</td> */}
                                    <td style={{background:'rgb(255,210,125)',padding:'5px',fontSize:'14px',maxWidth:'40%'}} width={"40%"}>Url</td>
                                    <td style={{background:'rgb(255,210,125)',padding:'5px',fontSize:'14px',maxWidth:'20%'}} width={"20%"}>Status</td>
                                    <td style={{background:'rgb(255,210,125)',padding:'5px',fontSize:'14px',maxWidth:'18%'}} width={"18%"}>Goal Title</td>
                                    <td style={{background:'rgb(255,210,125)',padding:'5px',fontSize:'14px',maxWidth:'18%'}} width={"18%"}>Goal Value</td>
                                    <td style={{background:'rgb(255,210,125)',padding:'5px',fontSize:'14px',maxWidth:'4%'}} width={"4%"}>Remove</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tempPostbackDict[item].map((item2,index2)=>{
                                        return <tr key={index2}>
                                            {/* <td>{item2.id}</td> */}
                                            <td style={{fontSize:'12px',padding:'5px',borderTop:(index2!==0 ? '1px solid rgb(200,200,200)' : '0px solid white')}}>{item2.postback_url}</td>
                                            <td style={{fontSize:'12px',padding:'5px',borderTop:(index2!==0 ? '1px solid rgb(200,200,200)' : '0px solid white')}}>{item2.payout_status}</td>
                                            <td style={{fontSize:'12px',padding:'5px',borderTop:(index2!==0 ? '1px solid rgb(200,200,200)' : '0px solid white')}}>{tempGoalDict[item] ? item2.goal_value==="any" ? "Any" : tempGoalDict[item][item2.goal_value] : "-"}</td>
                                            <td style={{fontSize:'12px',padding:'5px',borderTop:(index2!==0 ? '1px solid rgb(200,200,200)' : '0px solid white')}}>{item2.goal_value}</td>
                                            <td style={{fontSize:'12px',padding:'5px',borderTop:(index2!==0 ? '1px solid rgb(200,200,200)' : '0px solid white'),color:'red',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={(e)=>{removePostback(e,item,item2)}}><RemoveIcon/></td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                </div>
            })}
        </div>
    }


  const apikey = () => {
    return(
      <div style={{height:"100%",overflowY:"auto",marginLeft:"1%",marginTop:"2%"}}>
        
        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>API key</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}>
            <TextField multiline fullWidth size="small" value={apiKey ? apiKey : ""}/>
          </div>
            <Button color="warning" onClick={handleGenerateApi} disabled={apiKey ? true : false}>Generate</Button>
        </div>
        
      </div>
    )
  }
  return (
    <div className="row">
        <div className="columnData" style={{width:"95%",background:"white",height:"80vh"}}>
        <div style={{background:"white",width:"auto",height:"8%"}}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs 
              TabIndicatorProps={{style: {background:'orange',backgroundColor:'orange'}}}
              value={tabValue} 
              onChange={handleTabValue} 
              aria-label="basic tabs example">
              <Tab label={<span className={tabValue===0? 'Active' : 'Inactive'}>General</span>}/>
              <Tab label={<span className={tabValue===1? 'Active' : 'Inactive'}>Contact</span>}/>
              <Tab label={<span className={tabValue===2? 'Active' : 'Inactive'}>Postback</span>}/>
              <Tab label={<span className={tabValue===3? 'Active' : 'Inactive'}>API key</span>}/>
            </Tabs>
          </Box>
          </Box>
          
        </div>
        <div style={{height:"82%",width:"100%",background:"white",overflowY:"auto"}}>
         
          <TabPanel value={tabValue} index={0}>
            {general()}
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {contact()}
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            {postbacks()}
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            {apikey()}
          </TabPanel>
          
        </div>

        <div style={{display:"flex",alignItems:"center",height:"10%",width:"100%"}}>
          <div style={{display:"flex",marginLeft:"15%"}}>
            <NavLink style={{textDecoration:"none",color:"black"}} to={"/affiliates/management/view/"+affiliateId}>
              <Button variant="outlined" color="warning">View</Button>
            </NavLink>
          </div>      
                    
          <div style={{display:"flex",marginLeft:"2%"}}>
            <Button variant="contained" color="warning" onClick={handleSaveAndContinueAffiliateButton}>SAVE AND CONTINUE</Button>
          </div>
          <div style={{marginLeft:"2%"}}>
            <Button variant="contained" color="warning" onClick={handleSaveAffiliateButton} disabled={saveButtonDisabled || disableAddButton}>EDIT</Button>
          </div>
          <div style={{marginLeft:"2%"}}>
                <NavLink style={{textDecoration:"none",color:"black"}} to={"/affiliates/management"}><Button variant="text">Cancel</Button></NavLink>
          </div>
        </div>
      </div>
      {showResponsePopup && 
          <div className="popup-box">
            <div className="box-response">
              <div className="popupHeader" >{"RESULT"}</div>
              <div style={{borderBottom:"2px solid orange"}}></div>
                <div className="popupData">
                  {responsePopup}
                </div>
                {
                  !responsePopupError && 
                    <NavLink style={{textDecoration:"none",color:"black"}} to={"/affiliates/management/view/"+affiliateId}>
                      <div className="close-icon-response" onClick={()=>{setShowResponsePopup(false);setResponsePopup(false)}}>X</div>
                    </NavLink>
                  }
                {
                  responsePopupError &&
                    <div className="close-icon-response" onClick={()=>{setShowResponsePopup(false);setResponsePopup(false)}}>X</div>
                }
                {
                  responsePopupError==='getAffiliate' &&
                    <NavLink style={{textDecoration:"none",color:"black"}} to={"/affiliates/management"}>
                      <div className="close-icon-response" onClick={()=>{setShowResponsePopup(false);setResponsePopup(false)}}>X</div>
                    </NavLink>
                }      
              </div>
          </div>
      }
    </div>
  );
};
 
export default EditAffiliatePage;