import React, { useState } from 'react';
import '../../../css/pages/demandsource.css';
import axios from 'axios';
import * as Constants from '../../../data/constants';
import { CircularProgress } from '@mui/material';
import { getLocalStorageValue } from '../../../util/util';

export const DemandOfferPopup = (props) => {
    
    const [useStateManual, setUseStateManual] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [response, setResponse] = useState({parsed_:{},fullData:false});
    
    const getOffer = async(offer_view_type) => {
        setShowLoader(offer_view_type)
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"demandoffer/",props.offer.id),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                offer_view_type
            }
        })
        .then(resp => {
            setShowLoader(false);
            if(offer_view_type==='parsed_data'){
                setResponse({parsed_:resp.data.result,fullData:response.fullData})
            }
            if(offer_view_type==='full_data'){
                setResponse({parsed_:response.parsed_,fullData:resp.data.result})
            }
        })
        .catch(err => {
            console.error(err);
        })
    };
    
    if(!useStateManual){
        setUseStateManual(true);
        getOffer("parsed_data");
    }

    const showObjectParsedData = (key) => {
        if(key==='Caps' || key==='Payouts' || key==='Targeting'){
            return <div className='demandOffersPopupInnerTable'>
                <div>
                    {response.parsed_[key].length>0 && Object.keys(response.parsed_[key][0]).map((innerItemKeys,innerItemKeysIndex)=>{
                        return <div className='demandOffersPopupInnerKeys' style={{borderTop:(innerItemKeysIndex===0 ? '1px solid rgb(220,220,220)' : '')}} key={innerItemKeysIndex}>
                                {innerItemKeys}
                            </div>
                    })}
                </div>
                <div style={{display:'flex',overflow:'auto',maxWidth:'90%'}}>
                    {response.parsed_[key].length>0 && response.parsed_[key].map((items,index)=>{
                        return <div key={index} >
                                    {response.parsed_[key].length>0 && Object.keys(response.parsed_[key][index]).map((innerItemKeys,innerItemKeysIndex)=>{
                                        return <div className='demandOffersPopupInnerValues' style={{borderTop:(innerItemKeysIndex===0 ? '1px solid rgb(220,220,220)' : '')}} key={innerItemKeysIndex}>
                                                {response.parsed_[key][index][innerItemKeys]}
                                            </div>
                                    })}
                                 </div>

                    })}
                </div>
                {response.parsed_[key].length===0 && "-"}
            </div>
        }
        else if(key==='Creatives' || key==='Categories'){
            return <div>
                {response.parsed_[key].map((item,index)=>{
                    return <div key={index}>
                            {item}{index!==response.parsed_[key].length-1 ? ',' : ''}
                        </div>
                })}
                {response.parsed_[key].length===0 && "-"}
            </div>
        }
        else{
            return JSON.stringify(response.parsed_[key])
        }
    }
    return (
        <div className='demandOfferPopupContainer'>
            <div className='demandOfferPopupWrapper'>
                <div className='demandOfferPopupHeader'>
                    {props.offer.title} {"(" + props.offer.offer_id + ")"}
                    <div style={{cursor:'pointer'}} onClick={props.closePopup}> X </div>
                </div>
                <div className='demandOfferPopupData'>
                    <br/>
                    {showLoader==='parsed_data' &&
                        <div style={{minHeight:'80vh',display:'flex',justifyContent:'center',alignItems:'center',minWidth:'100%'}}>
                            <CircularProgress size={45} thickness={4} sx={{
                                                            color: localStorage.getItem('ambientColor'),
                                                            }}/>
                        </div>
                    }
                    {Object.keys(response.parsed_).map((key,keyIndex)=>{
                        return <div className='demandOfferPopupDataFlow' style={keyIndex%2===0 ? {background:'rgb(250,250,250)'} : {background:'rgb(255,255,255)'}} key={keyIndex}>
                            <div className='demandOfferPopupDataKey'>
                                {key}
                            </div>
                            <div className='demandOfferPopupDataValue'>
                                {typeof response.parsed_[key] === 'object' 
                                    ?
                                    showObjectParsedData(key)
                                    :
                                    response.parsed_[key]
                                }
                            </div>
                        </div>
                    })}
                    <br/>
                    <br/>
                    <div className='demandOfferPopupDataFlow' style={{fontSize:'12px',color:'blue',borderBottom:'0px solid transparent',cursor:'pointer'}} onClick={()=>{getOffer("full_data")}}>
                        Click to show original Offer.
                    </div>
                    {showLoader==='full_data' &&
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center',minWidth:'100%'}}>
                            <CircularProgress size={45} thickness={4} sx={{
                                                            color: localStorage.getItem('ambientColor'),
                                                            }}/>
                        </div>
                    }
                    <div className='demandOfferPopupDataFlow' style={{fontSize:'12px',border:'1px solid rgb(220,220,220)',background:'rgb(240,240,240)'}}>
                        {
                            response.fullData &&
                                <pre style={{whiteSpace:'pre-wrap'}}>
                                    {JSON.stringify(response.fullData,undefined,5)}
                                </pre>
                        }
                    </div>
                </div>

            </div>
        </div>
    )
}

export default DemandOfferPopup;
