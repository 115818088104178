import React from 'react'
import {GroupsComponent} from './groups';

export const SettingsPage = () => {
    document.title = "Settings - Admin"

    return (
        
            <div className="row">
                <div className="columnData" style={{width:"95%",height:"auto"}}>
                    <div style={{fontSize:"20px",fontWeight:"600",padding:"15px"}}>
                        Settings
                    </div>
                    <div style={{boxShadow:"0 0 2px 0.5px rgb(150,150,150)"}}></div>
                    
                    <div style={{display:"flex",justifyContent:"space-evenly"}}>
                        <div style={{width:"50%"}}>
                            <GroupsComponent/>
                        </div>
                        <div style={{width:"50%"}}>

                        </div>
                    </div>
                    
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>          
            </div>
            
    )
}

export default SettingsPage;
