import React from 'react'
import { useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { useScrollDirection } from 'react-use-scroll-direction';

import * as Constants from '../../data/constants';
import { getLocalStorageValue, sorting } from '../../util/util';

import Pagination from '@mui/material/Pagination';
import NativeSelect from '@mui/material/NativeSelect';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Select from 'react-select';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
// import DeleteIcon from '@mui/icons-material/Delete';
import LoginIcon from '@mui/icons-material/Login';
import { Store } from 'react-notifications-component';

import '../../css/pages/affiliate.css';

import NewAffiliatePopup from './newAffiliatePopup';

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    if(false){
        console.log(value)
    }
    return () => setValue(value => value + 1); // update the state to force render
  }
export const AffiliatePage = () => {
    document.title = "Affiliates - Admin"
    const refreshComponent = useForceUpdate()

    const [manualUseState, setManualUseState] = React.useState(false);

    const [totalResults,setTotalResults] = useState("-");
    const [affiliateDict,setAffiliateDict] = useState({loaded:false,data:[]})

    const [showLoader, setShowLoader] = React.useState(true);

    const [showPopupNewAffiliate, setShowPopupNewAffiliate] = React.useState(false);
    
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(50);
    // const [offset, setOffset] = React.useState(0);
    
    const [sortingFactor, setSortingFactor] = React.useState("id");
    const [sortingOrder, setSortingOrder] = React.useState(false); //true for asc and false for desc
    const [sortingFactorType, setSortingFactorType] = React.useState(true); //true for int and false for string

    const [threeDotMenuTop, setThreeDotMenuTop] = React.useState(0);
    const [threeDotMenuLeft, setThreeDotMenuLeft] = React.useState(0);
    const [showThreeDotMenu, setShowThreeDotMenu] = React.useState(false);
    const [editAffiliate, setEditAffiliate] = React.useState(false);
    
    const [showResponsePopup, setShowResponsePopup] = React.useState(false);
    const [responsePopup, setResponsePopup] = React.useState(false);
    
    const [direction, setDirection] = React.useState(String)
    const { isScrollingUp, isScrollingDown } = useScrollDirection()
    
    React.useEffect(() => {

      isScrollingDown && setDirection('down')
      isScrollingUp && setDirection('up')
    }, [isScrollingDown, isScrollingUp])
    if(direction){
    }  
    if (isScrollingDown || isScrollingUp){
        if (showThreeDotMenu){
            setShowThreeDotMenu(false);
        }
    }

    var tableRows = []

    const handleLimitChange = (e) =>{
        setLimit(parseInt(e.target.value))
        setPage(1);
        offers_request(1,e.target.value);
    };
    const handlePageChange = (event, value) => {
        setPage(value);
        // setOffset((value-1)*limit);
        offers_request(value,limit);
    };

    const togglePopup = (closeButton=false) => {
        setShowPopupNewAffiliate(!showPopupNewAffiliate);
        }

    const handleCheckSortingKey = (e) => {
        if (["id"].includes(e.target.id)){
            if (sortingFactor===e.target.id){
                setSortingOrder(!sortingOrder);
            }
            else{
                setSortingOrder(true);
            }
            setSortingFactor(e.target.id);
            setSortingFactorType(true);
        }
        else if (["company","affiliate_status","contact_number","skype_id","manager_name","created_at"].includes(e.target.id)){
            if (sortingFactor===e.target.id){
                setSortingOrder(!sortingOrder);
            }
            else{
                setSortingOrder(true);
            }
            setSortingFactor(e.target.id);
            setSortingFactorType(false);       
            }
        else{
            setSortingFactor("id");
            setSortingOrder(true);
            setSortingFactorType(true);   
        }
    }
    
    const [affiliateList,setAffiliateList] = React.useState([{label:"All",value:null}])
    const [affiliateSelected,setAffiliateSelected] = React.useState({label:"All",value:null})

    const getAdvertisers = async() => {
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.AFFILIATE,"/","details"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        })
        .then(resp => {
            let advertiserlist = []
            for (var item in resp.data.result){
                advertiserlist.push({label:resp.data.result[item][Object.keys(resp.data.result[item])[0]] + " - " + Object.keys(resp.data.result[item])[0],value:Object.keys(resp.data.result[item])[0]})
            }
            sorting(advertiserlist,'label',false,true)
            var tempList = affiliateList.concat(advertiserlist)
            setAffiliateList(tempList)
        })
        .catch(err => {
            console.error(err);
        })
    };

    const offers_request = async(manual_page=false,manual_limit=false,searchButton=false) => {
        setShowLoader(true)
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.AFFILIATES),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                limit:manual_limit ? manual_limit : limit,
                page:manual_page ? manual_page : page,
                id : affiliateSelected ? affiliateSelected.value : null,
            }
        })
        .then(resp => {
            if(searchButton){
                setPage(1);
            }
            setAffiliateDict({loaded:true,data:resp})
            setTotalResults(resp.data.count)
            setShowLoader(false);
        })
        .catch(function (error) {
            if (error.response) {
              // Request made and server responded
              setShowResponsePopup(true)
              setResponsePopup(error.response.data.result);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else {
              // Something happened in setting up the request that triggered an Error
              setResponsePopup(JSON.stringify({error:error.message}));
              console.log('Error', error.message);
            }})
    };

    if (!manualUseState){
        setManualUseState(true);
        offers_request();
        getAdvertisers();
    }
    
    const threeDotButtonClicked = (e,item) => {
        if (e.target.id){
            if (showThreeDotMenu){
                setShowThreeDotMenu(false)
            }
            else{
                setShowThreeDotMenu(true)
            }
            var offsets = document.getElementById(e.target.id).getBoundingClientRect();
            var top = offsets.top-10;
            var left = offsets.left-160;
            setThreeDotMenuTop(top)
            setThreeDotMenuLeft(left)
            }
                    
    }
    async function loginAffiliate(id) {
        return await axios({
          method: 'GET',
          url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"login/direct"),
          headers: {
            Authorization:getLocalStorageValue('adminPanelLoginData').token
          },
          
          params: {
            "affiliate_id":id
              }
          })
      .then(resp => {
          var token_dict = JSON.stringify({token:"Token "+resp.data.token})
          window.open("http://"+Constants.AFFILIATE_LOGIN_HOST+"/dashboard/?email="+resp.data.user.email+"&aff_id="+resp.data.user.id+"&companyname="+resp.data.user.company+"&token="+token_dict+"&manager_email="+resp.data.user.manager_email+"&manager="+resp.data.user.manager_name)
        })
      .catch(error => {
            if (error.response) {
                // Request made and server responded
                Store.addNotification({
                    title: "Error!",
                    message: typeof error.response.data.result === "string" ? error.response.data.result : "Some error occured",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                }); 
                console.log(error.response.status);
                console.log(error.response.headers);
            } 
            else {
                // Something happened in setting up the request that triggered an Error
                Store.addNotification({
                    title: "Error!",
                    message: typeof error.message === "string" ? error.message : "Some error occured",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                }); 
                console.log('Error', error.message);
            }
      })
      }
    const showData = () => {
        if (affiliateDict.loaded!==false){
            
            var affiliateData =  affiliateDict.data.data.result
            sorting(affiliateData,sortingFactor,sortingFactorType,sortingOrder);
            
            affiliateData.map((item,index)=>{
                if (true){
                        var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
                        var registrationDate = new Date(item.created_at)
                tableRows.push(
                        <tr key={index} className="dataRow" style={{backgroundColor:"rgb(255,255,255)" }}>
                            <td className="dataColumn">{item.id}</td>
                            <td className="dataColumn">
                                <NavLink style={{textDecoration:"none",color:"blue"}} to={"/affiliates/management/view/"+item.id}>
                                    {item.company}
                                </NavLink>
                            </td>
                            <td className="dataColumn">{item.affiliate_status || "NA"}</td>
                            <td className="dataColumn">{item.contact_person || "NA"}</td>
                            <td className="dataColumn">{item.skype_id || "NA"}</td>
                            <td className="dataColumn">{item.manager_name || "NA"}</td>
                            <td className="dataColumn">{item.payout || "NA"}</td>
                            <td className="dataColumn">{registrationDate.toLocaleDateString("en-US", options)}</td>
                            <td id={"threeDotButton"+index} className="dataColumn threeDotButton" onClick={(e)=>{setEditAffiliate(item.id);threeDotButtonClicked(e);}}>
                                <MoreVertIcon id={"threeDotButton"+index} onClick={(e)=>{setEditAffiliate(item.id);threeDotButtonClicked(e);}}/>
                            </td>
                        </tr>
                )
                    }
                return 1;
            })
            return  <table className="dataTable">
                        <tbody>
                            <tr className="dataRow" style={{backgroundColor:"rgb(250,250,250)"}}>
                                <td className="dataColumnHead" style={{width:"2%"}} id="id" onClick={handleCheckSortingKey}>Id</td>
                                <td className="dataColumnHead" style={{width:"15%"}} id="company" onClick={handleCheckSortingKey}>Company Name</td>
                                <td className="dataColumnHead" style={{width:"7%"}} id="affiliate_status" onClick={handleCheckSortingKey}>Status</td>
                                <td className="dataColumnHead" style={{width:"7%"}} id="contact_number" onClick={handleCheckSortingKey}> Contact Person</td>
                                <td className="dataColumnHead" style={{width:"10%"}} id="skype_id" onClick={handleCheckSortingKey}> Skype Id</td>
                                <td className="dataColumnHead" style={{width:"7%"}} id="manager_name" onClick={handleCheckSortingKey}> Manager</td>
                                <td className="dataColumnHead" style={{width:"7%"}} id="payout"> Payout</td>
                                <td className="dataColumnHead" style={{width:"10%"}} id="created_at" onClick={handleCheckSortingKey}> Created At</td>
                                <td className="dataColumnHead" style={{width:"2%",background:"transparent"}}></td>
                            </tr>
                            {tableRows}
                            
                        </tbody>
                    </table>
            }
    }

    return (
        <div className="row">
            <div className="columnData" style={{width:"95%",height:"auto"}} onClick={()=>{if(showThreeDotMenu){setShowThreeDotMenu(false);}}}>
                {
                    showThreeDotMenu && editAffiliate && 
                    <div className="threeDotMenu" style={{top:threeDotMenuTop,left:threeDotMenuLeft,height:"141px"}}>

                        <div style={{height:"50px"}} className="threeDotMenuItems" onClick={()=>{loginAffiliate(editAffiliate)}}>
                            <LoginIcon style={{color:"rgb(255, 87, 87)",transform:"scale(0.8)"}}/>
                            Login
                        </div>
                        <NavLink style={{textDecoration:"none",color:"black"}} to={"/affiliates/management/edit/" + editAffiliate}>
                            <div style={{height:"50px"}} className="threeDotMenuItems">
                                <ModeEditOutlineIcon style={{color:"rgb(0, 119, 255)",transform:"scale(0.8)"}}/>
                                Edit
                                </div>
                        </NavLink>  
                        {/* <div style={{border:"1px solid grey"}}></div>

                        <div className="threeDotMenuItems" onClick={()=>{setShowResponsePopup(true);setResponsePopup("Feature not available right now!")}}>
                            <DeleteIcon style={{color:"rgb(255, 87, 87)",transform:"scale(0.8)"}}/>
                            Delete
                        </div> */}
                    </div>
                }
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <div style={{marginLeft:"2%",marginTop:"2%",fontSize:"12px"}}>Found {totalResults} Affiliates</div>
                    <div style={{marginRight:"2%",marginTop:"2%",fontSize:"12px",display:'flex',alignItems:'center'}}>
                        <div style={{cursor:'pointer',marginRight:'10px'}} onClick={()=>{localStorage.removeItem('oldAffiliatePage');window.location.reload();}}>Switch to New Design</div>
                        <NavLink style={{textDecoration:"none",color:"black"}} to={"/affiliates/management/add"}>
                            <Button variant="outlined" color="warning">New Affiliate</Button>
                        </NavLink>
                    </div>
                </div>
                <div style={{display:"flex",marginTop:"1%"}}>
                    <div style={{width:"17%",marginLeft:"2%"}}>
                        <div style={{fontSize:"12px",fontWeight:"700",color:"grey",paddingBottom:"2px"}}>Affiliate</div>
                        <Select 
                            options={affiliateList}
                            value={affiliateSelected}
                            onChange={(e)=>{setAffiliateSelected(e)}}
                            menuPortalTarget={document.body} 
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999}),
                                    option: styles => ({...styles,fontSize:"13px"}),
                                    control : provided => ({
                                        ...provided,
                                        minHeight:"5.2vh",
                                        height:"15%",
                                    }),
                                    valueContainer: provided => ({
                                    ...provided,
                                    fontSize:"13px"
                                    })
                            }}/>
                    </div>
                </div>

                <div style={{display:'flex',marginTop:"5%",marginLeft:'2%'}}>
                    <div style={{transform:'scale(0.9)'}}><Button variant='contained' color="warning" onClick={()=>{offers_request(1,limit,true)}}>Search</Button></div>
                    <div style={{transform:'scale(0.9)'}}><Button variant='outlined'  onClick={(e)=>{setAffiliateSelected({label:"All",value:null});refreshComponent(e);}}>Reset</Button></div>
                </div>

                <div style={{borderBottom:"0.001px solid rgb(120,120,120)",display:'flex',marginLeft:"2%",marginRight:"2%",marginTop:"2%"}}></div>

                <div style={{display:"block",margin:"2%",height:"75%"}}> 
                        {showLoader && 
                            <div className="loaderWrapper">  
                                <div className="loader">  
                                    <CircularProgress color="warning"/> 
                                </div>
                            </div>
                        }
                        {!showLoader && 
                            <div style={{textAlign: "right"}}>
                                <label style={{paddingLeft:"20px",paddingRight:"20px"}}>LIMIT</label>
                                <NativeSelect
                                    value={limit}
                                    inputProps={{
                                        name: 'LIMIT',
                                        id: 'uncontrolled-native',
                                    }}
                                    color="warning"
                                    onChange={handleLimitChange}
                                    >
                                    <option>50</option>
                                    <option>100</option>
                                </NativeSelect>
                            </div>}
                        {!showLoader && showData()}
                        {!showLoader &&
                            <div className="row">
                                <Pagination count={Math.ceil(totalResults/limit)} page={page} onChange={handlePageChange} />
                            </div>
                            }
                </div>
                
                {showPopupNewAffiliate && 
                    <NewAffiliatePopup
                        handleClose={togglePopup}
                        />
                }

                {showResponsePopup && 
                    <div className="popup-box">
                        <div className="box-response">
                        <div className="popupHeader" >{"Error"}</div>
                        <div style={{borderBottom:"2px solid orange"}}></div>
                            <div className="popupData">
                            {responsePopup}
                            </div>
                        <div className="close-icon-response" onClick={()=>{setShowResponsePopup(false)}}>X</div>
                        </div>
                    </div>
                }
                
            </div>            
        </div>
    )
}

export default AffiliatePage;
