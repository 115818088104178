import React, { useState } from 'react'
import '../../css/pages/exports.css';
import * as Constants from '../../data/constants';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { getLocalStorageValue } from '../../util/util';

export const DownloadPage = () => {
    const [manualUsestate, setManualUsestate] = useState(false)
    const [showLoader, setShowLoader] = useState(true)

    const downloadDocument = async(filename) => {
        const FileDownload = require('js-file-download');

        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.GETSTATS,"/","export/download"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                filename:filename,
            },
            responseType:'blob',
        })
        .then(resp => {
            setShowLoader(false);
            FileDownload(resp.data, filename)
            setTimeout(function() { window.close(); }, 1000);
            // resp.data.blob()
            // 
        })
        // .then((blob) => {
        //     // Create blob link to download
        //     const url = window.URL.createObjectURL(
        //       new Blob([blob]),
        //     );
        //     const link = document.createElement('a');
        //     link.href = url;
        //     link.setAttribute(
        //       'download',
        //       `FileName.pdf`,
        //     );
        
        //     // Append to html link element page
        //     document.body.appendChild(link);
        
        //     // Start download
        //     link.click();
        
        //     // Clean up and remove the link
        //     link.parentNode.removeChild(link);
        //   })
        .catch(err => {
            console.error(err);
        })
    };
    if(!manualUsestate){
        window.document.title="Export Data"
        var url = new URL(window.location.href)
        if(url.searchParams.get('filename')){
            setManualUsestate(true) 
            downloadDocument(url.searchParams.get('filename'))
        }
    }

    return (
        <div className='containerExport'>
            <div style={{display:'flex',alignItems:'center',minHeight:'60vh',justifyContent:'center',width:'100%'}}>
                {showLoader && 
                    <div style={{display:'block',padding:'2%'}}>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <CircularProgress color='warning' style={{transform:'scale(2)'}}/>
                        </div>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                            Please Wait Your Download is being processed. Please do not close this tab.
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default DownloadPage;