import React, { useEffect, useRef } from 'react'
import ReactApexChart  from 'react-apexcharts';
import { sorting } from '../../../util/util';

const TableToGraph = (props) => {
    const chartRef = useRef();

    useEffect(() => {
            chartRef?.current?.chart.toggleSeries('Clicks');
            chartRef?.current?.chart.toggleSeries('Trafficback');
            chartRef?.current?.chart.toggleSeries('Declined Conversions');
            chartRef?.current?.chart.toggleSeries('Approved Payout');
        });
    var data = props.data
    var statsType = props.statsType
    sorting(data,'key',(statsType==='daily' ? false : true), true)
    var tempSeriesDict = {
            key : {series:{name:statsType,data:[]}},
            clicks : {series:{name:"Clicks",data:[]}},
            trafficback : {series:{name:"Trafficback",data:[]}},
            conversions : {series:{name:"Approved Conversions",data:[]}},
            declined : {series:{name:"Declined Conversions",data:[]}},
            // impressions : {series:{name:"Impressions",data:[]}},
            approved_payout : {series:{name:"Approved Payout",data:[]}},
            approved_revenue : {series:{name:"Approved Revenue",data:[]}},
            total_earnings : {series:{name:"Earnings",data:[]}},
            currency : {notShow:true,series:{name:"Currency",data:[]}},
        }
    var labelList = []
    data.forEach(element => {
        if(statsType==='offer'){
            labelList.push(element.title)
        }
        else if(statsType==='adv' || statsType==='aff'){
            labelList.push(element.company)
        }
        else if(statsType==='adv-manager' || statsType==='aff-manager'){
            labelList.push(element.name)
        }
        Object.keys(tempSeriesDict).forEach(keySeriesDict => {
            tempSeriesDict[keySeriesDict].series.data.push(element[keySeriesDict])        
        })
    });
    var series = []
    var yaxis = []
    Object.keys(tempSeriesDict).forEach(element => {
        if(element!=="key" && !tempSeriesDict[element].notShow){
            series.push(tempSeriesDict[element].series)
            yaxis.push({
                min:Math.min(0),
                max:Math.max(...tempSeriesDict[element].series.data),
                forceNiceScale:true,
                show:false,
                opposite: true,
                tickAmount: 5,
                labels:{
                    formatter: (value) => { 
                        
                        return parseInt(value) 
                    },
                },
            })
        }

    })
    var tempStateLineChart = {
        series,
        options: {
            plotOptions: {
                bar: {
                columnWidth: "30%"
                }
            },
            chart: {
                height: 350,
                width: 850,
                type: 'line',
                dropShadow: {
                    enabled: false,
                    color: '#000',
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2
                },
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false,
                }
                },
            colors: ["#e60049", "#0bb4ff", "#50e991", "#e6d800", "#9b19f5", "#ffa300", "#dc0ab4", ],
            dataLabels: {
                enabled: false,
            },
            tooltip: {
                enabled: true,
               
                x:  (
                        statsType !== 'daily' 
                        ? 
                        {
                            formatter: (seriesName,index) => {return tempSeriesDict['key'].series.data[index.dataPointIndex] + ":  " + labelList[index.dataPointIndex]},
                        } 
                        : 
                        {}
                    )
            },
            // title: {
            //     text: 'Statistics',
            //     align: 'left'
            //     },
            grid:{
                show:true
            },
            markers: {
                size: 4
                },

            stroke:{
                width:2,
                curve: 'smooth',
            },
            xaxis: {
                    crosshairs:{
                        show:false,
                    },  
                type: statsType ==='daily' ? 'datetime' : null,
                categories:tempSeriesDict['key'].series.data,
                forceNiceScale:true,

            },
            yaxis,
            
            legend: {
                position: 'top',
                horizontalAlign: 'right',
                floating: true,
                offsetY: -0,
                offsetX: -5
            }
        },
    };

    var barChartFields = [
        {label:"Clicks",value:'clicks',valueType:'int'},
        {label:"Trafficback",value:'trafficback',valueType:'int'},
        {label:"Approved Conversions",value:'conversions',valueType:'int'},
        {label:"Declined Conversions",value:'declined',valueType:'int'},
        {label:"Approved Payouts",value:'approved_payout',valueType:'float'},
        {label:"Approved Revenue",value:'approved_revenue',valueType:'float'},
        {label:"Earnings",value:'total_earnings',valueType:'float'},
    ]
    const showBarCharts = () => {
        return barChartFields.map((item,index)=> {
            var barChartSeries = tempSeriesDict[item.value].series.data
            var tempStateBarChart = {
                series:barChartSeries,
                options: {
                    chart: {
                        type: 'donut',
                    },
                    plotOptions: {
                        pie: {
                        startAngle: -90,
                        endAngle: 270
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    labels: tempSeriesDict['key'].series.data,
                    fill: {
                        type: 'gradient',
                    },
                    legend: {
                        formatter: function(val, opts) {
                            if(item.valueType==='float'){
                                return val + " - " + opts.w.globals.series[opts.seriesIndex].toFixed(2)
                            }
                            else{
                                return val + " - " + opts.w.globals.series[opts.seriesIndex]
                            }
                        }
                    },
                    title: {
                        text: item.label
                    },

                    tooltip: {
                        enabled: true,
                        y:  (
                                statsType !== 'daily' 
                                ? 
                                {
                                    formatter: (seriesName,index) => {return labelList[index.dataPointIndex]},
                                } 
                                : 
                                {}
                            )
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                        chart: {
                            width: 200,
                            height: 380
                        },
                        legend: {
                            position: 'bottom'
                        }
                        }
                    }]
                },
            };
            return <div key={index} style={{paddingLeft:'20px',paddingTop:'20px',paddingBottom:'20px',paddingRight:'20px'}}>
                        <ReactApexChart className="graphs" options={tempStateBarChart.options} series={tempStateBarChart.series} type="donut" height={350} width={350}/>
                    </div>
        })
    }
    

    return (
        <div style={{paddingBottom:'50px'}}>
            {
                tempStateLineChart.series.length>0 && statsType!=='offer' &&
                <>
                    <div style={{background:'rgb(250,250,250)',marginLeft:"2%",marginRight:'2%',border:'1px solid rgb(200,200,200)'}}>
                        <div style={{height:'30px',display:'flex',alignItems:'center',paddingLeft:'1%',paddingRight:'1%',borderBottom:'1px solid rgb(200,200,200)',background:'rgb(240,240,240)'}}>
                            Line Graph
                        </div>
                        <div style={{height:'50vh',paddingLeft:'2%',paddingRight:'2%'}}>
                            <ReactApexChart ref={chartRef} className="graphs" options={tempStateLineChart.options} series={tempStateLineChart.series} type="line" height={"100%"} width={"100%"}/>
                        </div>
                    </div>
                </>
            }
            <div style={{background:'rgb(250,250,250)',marginLeft:"2%",marginRight:'2%',marginTop:'20px',border:'1px solid rgb(200,200,200)'}}>
                <div style={{height:'30px',display:'flex',alignItems:'center',paddingLeft:'1%',paddingRight:'1%',borderBottom:'1px solid rgb(200,200,200)',background:'rgb(240,240,240)'}}>
                    Pie Charts
                </div>
                <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center'}}> 
                    {showBarCharts()}
                </div>
            </div>   
        </div>
    )
}

export default TableToGraph