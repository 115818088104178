import React from 'react';
import '../../css/pages/viewPage.css';
import axios from 'axios';
import { NavLink } from "react-router-dom";

import * as Constants from '../../data/constants';

import Button from '@mui/material/Button';
import { getLocalStorageValue } from '../../util/util';

export const ViewEmployeePage = () => {

    const employeeid = window.location.href.split("/")[window.location.href.split("/").length-1];
    
    const [manualUseState, setManualUseState] = React.useState(false);
    const [responseResult, setResponseResult] = React.useState("");
    // const [responsePermissionResult, setResponsePermissionResult] = React.useState("");
    const [showPopup, setShowPopup] = React.useState(false);

    const showEmployeeRequest = async() => {
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"employee","/",employeeid,"/view"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        }
        )
        .then((resp) => {
            var response = resp.data.result[0]
            setResponseResult(response)
            
        })
        .catch(err => {
            console.error(err);
            if(err.response){
                if(err.response.data && err.response.data.result){
                    setShowPopup(err.response.data.result)
                }
            }
        })
    };

    // const showEmployeePermissionsRequest = async() => {
    //     await axios({
    //         method: 'GET',
    //         url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"employee","/",employeeid,"/permissions"),
    //         headers:{
    //             Authorization:getLocalStorageValue('adminPanelLoginData').token
    //         },
    //     }
    //     )
    //     .then((resp) => {
    //         var response = resp.data.result[0]
    //         setResponsePermissionResult(response)
            
    //     })
    //     .catch(err => {
    //         console.error(err);
    //     })
    // };
    if (!manualUseState){
        setManualUseState(true);
        showEmployeeRequest();
        // showEmployeePermissionsRequest();
    }
    
    
    return(
        <div style={{height:"100%",minHeight:"80vh",paddingTop:"2%"}}>
            <div style={{display:"flex"}}>
                {showPopup && 
                    <div className="popup-box">
                        <div className="box-response">
                        <div className="popupHeader" >{"Error"}</div>
                        <div style={{borderBottom:"2px solid orange"}}></div>
                            <div className="popupData">
                            {showPopup}
                            </div>
                            <NavLink style={{textDecoration:"none",color:"black"}} to={"/account/employees"}>
                                <div className="close-icon-response" onClick={()=>{setShowPopup(false)}}>X</div>
                            </NavLink>
                        </div>
                    </div>
                }
                <div style={{paddingLeft:"2%",width:"48%",minHeight:"400px"}}>
                    <div className="columnData" style={{width:"100%",minHeight:"400px",height:"auto",marginBottom:"1px",paddingBottom:"3%"}}>
                        <div style={{display:'flex',padding:"5%",paddingTop:"2%",paddingBottom:"0%",justifyContent:"space-between",alignItems:"center"}}>
                            <div style={{paddingLeft:"10%",fontSize:"18px",fontWeight:"700"}}>Employee : { responseResult.first_name + " " + responseResult.last_name}</div>
                            <NavLink style={{textDecoration:"none",color:"black",transform:"scale(0.8)"}} to={"/account/employees/edit/"+employeeid}><Button variant="contained" color="warning">EDIT</Button></NavLink>
                        </div>
                        <div style={{borderBottom:"1px solid black",margin:"2%",marginBottom:"0%"}}></div>
                        <div className="container" style={{display:"block",paddingTop:"2%",marginTop:"0%"}}>
                            <div style={{display:"flex",padding:"1%"}}>
                                <div style={{width:"30%",fontSize:"15px",fontWeight:"600",color:"rgb(50,50,50)"}}>
                                    Employee Id
                                </div>
                                <div style={{width:"70%",fontSize:"14px",fontWeight:"500",color:"rgb(50,50,50)"}}>
                                    {responseResult.id}
                                </div>
                            </div>
                            <div style={{display:"flex",padding:"1%"}}>
                                <div style={{width:"30%",fontSize:"15px",fontWeight:"600",color:"rgb(50,50,50)"}}>
                                    Employee Name
                                </div>
                                <div style={{width:"70%",fontSize:"14px",fontWeight:"500",color:"rgb(50,50,50)"}}>
                                    {responseResult.first_name + " " + responseResult.last_name}
                                </div>
                            </div>
                            <div style={{display:"flex",padding:"1%"}}>
                                <div style={{width:"30%",fontSize:"15px",fontWeight:"600",color:"rgb(50,50,50)"}}>
                                    Employee Type
                                </div>
                                <div style={{width:"70%",fontSize:"14px",fontWeight:"500",color:"rgb(50,50,50)"}}>
                                    {responseResult.employee_type}
                                </div>
                            </div>
                            <div style={{display:"flex",padding:"1%"}}>
                                <div style={{width:"30%",fontSize:"15px",fontWeight:"600",color:"rgb(50,50,50)"}}>
                                    Contact Number
                                </div>
                                <div style={{width:"70%",fontSize:"14px",fontWeight:"500",color:"rgb(50,50,50)"}}>
                                    {responseResult.contact}
                                </div>
                            </div>
                            <div style={{display:"flex",padding:"1%"}}>
                                <div style={{width:"30%",fontSize:"15px",fontWeight:"600",color:"rgb(50,50,50)"}}>
                                    Skype
                                </div>
                                <div style={{width:"70%",fontSize:"14px",fontWeight:"500",color:"rgb(50,50,50)"}}>
                                    {responseResult.skype_id}
                                </div>
                            </div>
                            <div style={{display:"flex",padding:"1%"}}>
                                <div style={{width:"30%",fontSize:"15px",fontWeight:"600",color:"rgb(50,50,50)"}}>
                                    Joined at
                                </div>
                                <div style={{width:"70%",fontSize:"14px",fontWeight:"500",color:"rgb(50,50,50)"}}>
                                    {responseResult.date_joined}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                {/* <div style={{paddingLeft:"2%",width:"48%",minHeight:"400px"}}>
                    <div className="columnData" style={{width:"100%",minHeight:"400px",height:"auto",marginBottom:"1px",paddingBottom:"3%"}}>
                        <div style={{paddingLeft:"2%",marginTop:"2%",fontSize:"15px",fontWeight:"700"}}>Permissions</div>
                        <div className="container">
                            
                        </div>
                    </div>
                </div> */}
                
            </div>
            
        </div>
    )
    };

export default ViewEmployeePage;