import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import * as Constants from '../../../../data/constants';
import '../../../../css/pages/v2/demandoffers.css';
import Button from '@mui/material/Button';
import Select from 'react-select';
import { increase_brightness } from '../../../../components/customization/customization';
import { getAdvertisers, getDemandSourcePlatforms, getManager} from '../../../../apis/dropdown/dropdowns';
import axios from 'axios';
import { capitalize, getLocalStorageValue } from '../../../../util/util';
import Pagination from '@mui/material/Pagination';

import GridViewIcon from '@mui/icons-material/GridView';
import TableRowsIcon from '@mui/icons-material/TableRows';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

import Tooltip from '@mui/material/Tooltip';
import { Store } from 'react-notifications-component';
import { CircularProgress, IconButton } from '@mui/material';


function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    if(false){
        console.log(value)
    }
    return () => setValue(value => value + 1); // update the state to force render
}

const getRequests = async(method,setMethod) => {
    var resp = await method;
    setMethod(resp)
}

const DemandSourceV2 = () => {
    const refreshComponent = useForceUpdate();
    const [manualUseState, setManualUseState] = useState(false);
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);
    const [totalResults, setTotalResults] = useState(50);
    const [offset, setOffset] = useState(1);
   
    const [advertiserList, setAdvertiserList] = useState([])
    const [platformList, setPlatformList] = useState([])
    const [managerList, setManagerList] = useState([])
    
    const [advertiserSelected, setAdvertiserSelected] = useState()
    const [platformSelected, setPlatformSelected] = useState()
    const [managerSelected, setManagerSelected] = useState()
    const [statusSelected, setStatusSelected] = useState()
   
    const [viewType, setViewType] = useState('table')
    
    const [demandSourceList, setDemandSourceList] = useState([])
    
    const [showLoader, setShowLoader] = useState(false);
    const [showMenu, setShowMenu] = useState(false)
    const [changeStatusRequest, setChangeStatusRequest] = useState(false)

    const [isAutomationPage, setIsAutomationPage] = useState(false);

    const showDemandSourceRequest = async(manual_limit,manual_page) => {
        if(manual_limit){
            setLimit(manual_limit)
        }
        if(manual_page){
            setPage(manual_page)
        }
        var requestLimit = manual_limit ? manual_limit : limit
        var requestPage = manual_page ? manual_page : page
        setShowLoader(true)
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.DEMANDSOURCE),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                limit:requestLimit,
                page:requestPage,
                advertiser_id : advertiserSelected ? advertiserSelected.value : null,
                platform : platformSelected ? platformSelected.value : null,
                advertiser_manager_id : managerSelected ? managerSelected.value : null,
                source_status : statusSelected ? statusSelected.value : null,
            }
        }
        )
        .then((resp) => {
            setShowLoader(false);
            setDemandSourceList(resp.data.result);
            setTotalResults(resp.data.count)
            setOffset(requestPage*requestLimit - requestLimit + 1)
        })
        .catch(error => {
            setShowLoader(false);
            if (error.response) {
                // Request made and server responded
                Store.addNotification({
                    title: "Error!",
                    message: typeof error.response.data.result === "string" ? error.response.data.result : "Some error occured",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                }); 
                console.log(error.response.status);
                console.log(error.response.headers);
            } 
            else {
                // Something happened in setting up the request that triggered an Error
                Store.addNotification({
                    title: "Error!",
                    message: typeof error.message === "string" ? error.message : "Some error occured",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                }); 
                console.log('Error', error.message);
              }
        })
    };
    
    const changeDemandSourceStatus = async(e,status,editDemandSourceID,demandSourceIndex) => {
        if(changeStatusRequest){
            Store.addNotification({
                title: "Warning!",
                message: "Status change request already in process. Please wait for some time.",
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2000,
                  onScreen: true
                }
              });
        }
        else{
            setChangeStatusRequest(true)
        }
        await axios({
            method: 'POST',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"demandsource/status/change"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-type" : 'application/json',
            },
            data:{
                "source_status": status,
                "source_id": editDemandSourceID
            }
        })
        .then(resp => {
            setChangeStatusRequest(false)
            Store.addNotification({
                title: "Success!",
                message: resp.data.result,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2000,
                  onScreen: true
                }
              });
            if(resp.data.result === 'Demand source status successfully updated'){
                var tempDemandSourceDict = demandSourceList
                tempDemandSourceDict[demandSourceIndex].status = status
                setDemandSourceList(tempDemandSourceDict);
                refreshComponent(e);
            }
        })
        .catch(error => {
            setChangeStatusRequest(false)
            if (error.response) {
                // Request made and server responded
                Store.addNotification({
                    title: "Error!",
                    message: typeof error.response.data.result === "string" ? error.response.data.result : "Some error occured",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                }); 
                console.log(error.response.status);
                console.log(error.response.headers);
              } else {
                // Something happened in setting up the request that triggered an Error
                Store.addNotification({
                    title: "Error!",
                    message: typeof error.message === "string" ? error.message : "Some error occured",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                }); 
                console.log('Error', error.message);
              }
        })
    };

    const exportDemandSource = async(manual_limit,manual_page) => {

        var requestLimit = manual_limit ? manual_limit : limit
        var requestPage = manual_page ? manual_page : page
        await axios({
            method: 'POST',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"demandsource/export"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-type" : 'application/json',
            },
            params:{
                limit:requestLimit,
                page:requestPage,
                advertiser_id : advertiserSelected ? advertiserSelected.value : null,
                platform : platformSelected ? platformSelected.value : null,
                advertiser_manager_id : managerSelected ? managerSelected.value : null,
                source_status : statusSelected ? statusSelected.value : null,
            }
        })
        .then(resp => {
            setChangeStatusRequest(false)
            Store.addNotification({
                title: "Success!",
                message: resp.data.result,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2000,
                  onScreen: true
                }
              });
        })
        .catch(error => {
            setChangeStatusRequest(false)
            if (error.response) {
                // Request made and server responded
                Store.addNotification({
                    title: "Error!",
                    message: typeof error.response.data.result === "string" ? error.response.data.result : "Some error occured",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                }); 
                console.log(error.response.status);
                console.log(error.response.headers);
              } else {
                // Something happened in setting up the request that triggered an Error
                Store.addNotification({
                    title: "Error!",
                    message: typeof error.message === "string" ? error.message : "Some error occured",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                }); 
                console.log('Error', error.message);
              }
        })
    }
    if(!manualUseState){
        setManualUseState(true);
        document.title="Demand Source"
        if(window.location.href.includes('demandsource/automation')){
            setIsAutomationPage(true);
            document.title="Demand Source - Automation"
        }
        else{
            setIsAutomationPage(false);
        }
        window.scrollTo({top: 0,left: 0,behavior: 'smooth'});
        showDemandSourceRequest();
        getRequests(getAdvertisers(),setAdvertiserList);
        getRequests(getDemandSourcePlatforms(),setPlatformList);
        getRequests(getManager(),setManagerList);
    }

    var filters = [
        {
            label:'Advertiser',
            options:advertiserList,
            selectedValue:advertiserSelected,
            setValueMethod:setAdvertiserSelected
        },
        {
            label:'Advertiser Manager',
            options:managerList,
            selectedValue:managerSelected,
            setValueMethod:setManagerSelected,
        },
        {
            label:'Platform',
            options:platformList,
            selectedValue:platformSelected,
            setValueMethod:setPlatformSelected
        },
        
        {
            label:'Status',
            options:[{label:'All',value:null},{label:'Active',value:1},{label:'Paused',value:0}],
            selectedValue:statusSelected,
            setValueMethod:setStatusSelected
        },
    ]

    var tableHeaders = [
        {label:'Advertiser Id',value:'advertiser_id',width:'10%'},
        {label:'Advertiser',value:'advertiser_name',width:'15%'},
        {label:'Manager',value:'manager_name',width:'10%'},
        {label:'Platform',value:'platform',width:'12%'},
        {label:'Status',value:'status',width:'10%'},
        {label:'Created At',value:'created_at',width:'12.5%'},
        {label:'Last Sync',value:'last_updated',width:'12.5%'},
        {label:'Sync Status',value:'last_response',width:'10%'},
        {label:'Edit',value:'three_dot_menu',width:'3%'},
    ]
    
    var showTableHeaders = () => {
        return <div className='demandOffersTableHeadContainer'>
            {tableHeaders.map((tableHead,tableIndex)=>{
                return <div key={tableIndex} className={"demandOffersTableHead"} style={{width:tableHead.width,justifyContent:(tableHead.value==="advertiser_name" || tableHead.value==="platform" ? 'flex-start' : 'center')}}>
                        {tableHead.label}
                    </div>
            })}
        </div>
    }

    var dateOptions = { year: 'numeric', month: 'short', day: 'numeric' ,hour:'numeric',minute:'numeric',second:'numeric'};
    var statusDropDown = {0:'Paused',1:'Active'}
    
    var showTableValue = () => {

        return demandSourceList.map((demandSourceItem,demandSourceIndex)=>{
            return <div key={demandSourceIndex} className='demandOffersTableValueContainer'>
                {tableHeaders.map((tableHead,tableIndex)=>{
                    return <div key={tableIndex} className={"demandOffersTableValue"} style={{width:tableHead.width,justifyContent:(tableHead.value==="advertiser_name" || tableHead.value==="platform" ? 'flex-start' : 'center')}}>
                            {tableHead.value==='advertiser_id' 
                                &&
                                demandSourceItem['advertiser_name'].includes("(")
                                &&
                                demandSourceItem['advertiser_name'].includes(")")
                                &&
                                demandSourceItem['advertiser_name'].split("(")[1].split(")")[0]
                            }
                            {
                                (tableHead.value==='created_at' || tableHead.value==='last_updated') 
                                &&
                                <div>{demandSourceItem[tableHead.value] ? new Date(demandSourceItem[tableHead.value]).toLocaleDateString("en-US", dateOptions) : '-'}</div>
                            }
                            {
                                tableHead.value==='status' 
                                &&
                                <div style={{ 
                                    color:(demandSourceItem[tableHead.value]===1 ? 'green' : 'orange'),
                                    height:"1%",
                                    background:(demandSourceItem[tableHead.value]===1 ? 'rgb(176, 239, 176)' :'rgb(255, 234, 195)' ),
                                    border: "1px solid "+(demandSourceItem[tableHead.value]===1 ? 'green' : 'orange'),
                                    borderRadius:"5px",
                                    padding:'3px',
                                    paddingLeft:"20%",
                                    paddingRight:"20%",
                                    }}>
                                    {statusDropDown[demandSourceItem[tableHead.value]]}
                                </div>
                            }
                            {
                                tableHead.value==='last_response' 
                                &&
                                <div style={{cursor:'default'}}>
                                    {demandSourceItem[tableHead.value] 
                                        ? 
                                            <Tooltip sx={{zIndex:0}} style={{zIndex:0}} title={JSON.stringify(demandSourceItem[tableHead.value])}>
                                            <div style={{color:demandSourceItem.last_status==='active' ? 'green' : 'red'}}>{demandSourceItem.last_status==="active" ? capitalize("Success") :capitalize(demandSourceItem.last_status)}</div>
                                            </Tooltip>
                                        :
                                            "-"
                                    }
                                </div>
                            }
                            {
                                tableHead.value==='advertiser_name' 
                                &&
                                <NavLink style={{textDecoration:"none",color:"blue"}} to={isAutomationPage ? "/demandsource/automation/edit/?id="+demandSourceItem.id+"&advertisername="+demandSourceItem.advertiser_name+"&platformname="+demandSourceItem.platform : "/demandsource/management/edit/"+demandSourceItem.id}>
                                    {(demandSourceItem['advertiser_name'].includes("(")
                                        &&
                                    demandSourceItem['advertiser_name'].includes(")"))
                                    ? 
                                    demandSourceItem['advertiser_name'].split(")")[1]
                                    :
                                    demandSourceItem['advertiser_name']
                                    }
                                </NavLink>
                            }

                            {
                                tableHead.value==='platform' 
                                &&
                               capitalize(demandSourceItem['platform']) 
                            }
                            {
                                tableHead.value==='manager_name' 
                                &&
                                <Tooltip title={demandSourceItem['manager_name']}>
                                    <div>
                                        {demandSourceItem['manager_name']}
                                    </div>
                                </Tooltip>
                            }
                            {
                                tableHead.value==='three_dot_menu' &&
                                <div>
                                    <MoreHorizIcon sx={{fontSize:'18px',color:localStorage.getItem('ambientColor')}} onClick={(e)=>{e.stopPropagation();setShowMenu(demandSourceItem.id);}}/>
                                    {showMenu===demandSourceItem.id && 
                                    <div className='demandOffersEditMenu'>
                                            <NavLink className='demandOffersEditMenuItems' style={{textDecoration:"none",color:"black"}} to={isAutomationPage ? "/demandsource/automation/edit/?id="+demandSourceItem.id+"&advertisername="+demandSourceItem.advertiser_name+"&platformname="+demandSourceItem.platform : "/demandsource/management/edit/"+demandSourceItem.id}>
                                                <ModeEditOutlineIcon style={{color:"rgb(0, 119, 255)",transform:"scale(0.8)"}}/>
                                                Edit
                                            </NavLink>
                                            <NavLink className='demandOffersEditMenuItems' style={{textDecoration:"none",color:"black"}} to={isAutomationPage || true ? "/demandsource/automation/edit/?id="+demandSourceItem.id+"&advertisername="+demandSourceItem.advertiser_name+"&platformname="+demandSourceItem.platform : "/demandsource/management/edit/"+demandSourceItem.id}>
                                                <SettingsSuggestIcon style={{color:"grey",transform:"scale(0.8)"}}/>
                                                Automation
                                            </NavLink>
                                        {
                                            demandSourceItem.status === 0 &&
                                            <div className='demandOffersEditMenuItems' onClick={(e)=>{changeDemandSourceStatus(e,1,demandSourceItem.id,demandSourceIndex)}}>
                                                <PlayArrowIcon style={{color:"green",transform:"scale(0.8)"}}/>
                                                Active
                                            </div>
                                        }
                                        {
                                            demandSourceItem.status === 1 &&
                                            <div className='demandOffersEditMenuItems' onClick={(e)=>{changeDemandSourceStatus(e,0,demandSourceItem.id,demandSourceIndex)}}>
                                                <PauseIcon style={{color:"orange",transform:"scale(0.8)"}}/>
                                                Pause
                                            </div>
                                        }
                                    </div>
                                    }
                                </div>
                            }
                            {tableHead.value!=='platform' 
                                && 
                                tableHead.value!=='advertiser_name'
                                && 
                                tableHead.value!=='manager_name'
                                && 
                                tableHead.value!=='created_at'
                                && 
                                tableHead.value!=='last_updated'
                                && 
                                tableHead.value!=='status'
                                && 
                                tableHead.value!=='last_response'
                                &&
                                demandSourceItem[tableHead.value]
                                &&
                            JSON.stringify(demandSourceItem[tableHead.value])}
                        </div>
                })}
            </div>
        })
    }

    var showTilesView = () => {

        const showImage = (item) => {
            try{
                return <img src={require('../../../../img/platforms/'+item.platform.toLowerCase()+'.png')} alt='Platform' width={["mocatech","seanear","youmi","mobisummer","linkgoads","hopemobi","swaarm","gazetm","rtb"].includes(item.platform.toLowerCase()) ? "50%" :"100%"} ></img>
            }
            catch{
                return <label style={{color:'grey'}}>{item.platform}</label>
            }
        }
        return <div className='demandOffersTilesViewContainer'>
                {demandSourceList.map((demandSourceItem,demandSourceIndex)=>{
                    return <div className='demandOffersTilesViewWrapper' style={{background:(demandSourceItem.status===0 ? 'rgb(255,241,217)' : 'rgb(245,255,245)'),boxShadow:("0 0 5px 0.001em " + (demandSourceItem.status===0 ? 'rgb(255,172,25)' : 'rgb(100,255,100)'))}} key={demandSourceIndex}>
                            <div style={{display:'flex',justifyContent:'space-between',marginBottom:'20px'}}> 
                                <div style={{maxWidth:'100px',minHeight:'50px'}}>
                                    {showImage(demandSourceItem)}
                                </div>
                                <div style={{width:'30%'}}>
                                    <div style={{ 
                                        color:(demandSourceItem.status===1 ? 'green' : 'orange'),
                                        height:"15px",
                                        background:(demandSourceItem.status===1 ? 'rgb(176, 239, 176)' :'rgb(255, 234, 195)' ),
                                        border: "1px solid "+(demandSourceItem.status===1 ? 'green' : 'orange'),
                                        borderRadius:"5px",
                                        width:'max-content',
                                        padding:'3px',
                                        fontSize:'10px',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        paddingLeft:"20%",
                                        paddingRight:"20%",
                                        }}>
                                        {statusDropDown[demandSourceItem.status]}
                                    </div>
                                </div>
                            </div>
                            <div style={{display:'flex',justifyContent:'space-between',marginBottom:'5px'}}>
                                <div className='demandOffersTilesViewLabel'>Platform :</div>
                                <div className='demandOffersTilesViewValue'>{capitalize(demandSourceItem.platform)}</div>
                            </div>

                            <div style={{display:'flex',justifyContent:'space-between',marginBottom:'5px'}}>
                                <div className='demandOffersTilesViewLabel'>Advertiser :</div>
                                <div className='demandOffersTilesViewValue'>{demandSourceItem.advertiser_name}</div>
                            </div>

                            <div style={{display:'flex',justifyContent:'space-between',marginBottom:'20px'}}>
                                <div className='demandOffersTilesViewLabel'>Created At :</div>
                                <div className='demandOffersTilesViewValue'>{demandSourceItem.created_at ? new Date(demandSourceItem.created_at).toLocaleDateString("en-US", dateOptions) : "-"}</div>
                            </div>
                            
                            <div style={{borderBottom:'1px solid rgb(200,200,200)',marginBottom:'10px'}}/>
                            <div style={{display:'flex',justifyContent:'space-between',marginBottom:'10px'}}>
                                <div className='demandOffersTilesViewLabel'>Last Sync :</div>
                                <div className='demandOffersTilesViewValue'>{demandSourceItem.last_updated ? new Date(demandSourceItem.last_updated).toLocaleDateString("en-US", dateOptions) : "-"}</div>
                            </div>

                            <div style={{display:'flex',justifyContent:'space-between',marginBottom:'10px'}}>
                                <div className='demandOffersTilesViewLabel'>Sync Status :</div>
                                <div className='demandOffersTilesViewValue' style={{display:'flex',justifyContent:(demandSourceItem.last_response ? 'center' : 'flex-start')}}>
                                    <div style={{cursor:'default'}}>
                                        {demandSourceItem.last_response
                                            ? 
                                                <Tooltip sx={{zIndex:0}} style={{zIndex:0}} title={JSON.stringify(demandSourceItem.last_response)}>
                                                    <div style={{color:demandSourceItem.last_status==='active' ? 'green' : 'red'}}>{demandSourceItem.last_status==="active" ? capitalize("Success") :capitalize(demandSourceItem.last_status)}</div>
                                                </Tooltip>
                                            :
                                                "-"
                                        }
                                    </div>
                                </div>
                            </div>
                            <div style={{borderBottom:'1px solid rgb(200,200,200)'}}/>
                            <div style={{display:'flex',justifyContent:'flex-end',marginBottom:'5px'}}>
                                {
                                    <Tooltip sx={{zIndex:0}} style={{zIndex:0}} title={"Edit this demand source"}>
                                        <IconButton>
                                            <NavLink style={{height:'15px',display:'flex',alignItems:'center',textDecoration:"none",color:"black"}} to={isAutomationPage ? "/demandsource/automation/edit/?id="+demandSourceItem.id+"&advertisername="+demandSourceItem.advertiser_name+"&platformname="+demandSourceItem.platform : "/demandsource/management/edit/"+demandSourceItem.id}>
                                                <ModeEditOutlineIcon style={{color:"blue",fontSize:"15px"}}/>
                                            </NavLink>
                                        </IconButton>
                                    </Tooltip>
                                }
                                {
                                    demandSourceItem.status === 1 &&
                                        <Tooltip sx={{zIndex:0}} style={{zIndex:0}} title={"Pause this demand source"}>
                                            <IconButton onClick={(e)=>{changeDemandSourceStatus(e,0,demandSourceItem.id,demandSourceIndex)}}>
                                                <PauseIcon style={{color:"orange",fontSize:"15px"}}/>
                                            </IconButton>
                                        </Tooltip>
                                }
                                {
                                    demandSourceItem.status === 0 &&
                                        <Tooltip sx={{zIndex:0}} style={{zIndex:0}} title={"Enable this demand source"}>
                                            <IconButton onClick={(e)=>{changeDemandSourceStatus(e,1,demandSourceItem.id,demandSourceIndex)}}>
                                                <PlayArrowIcon style={{color:"green",transform:"scale(0.8)"}}/>
                                            </IconButton>
                                        </Tooltip>
                                }
                            </div>
                        </div>
                    })
                }
            </div>
    }
    return (
        <div className='mainContainerV2' id="outside" style={{background:'rgb(254,254,254)'}} onClick={()=>{if(showMenu){setShowMenu(false);}}}>
            <div className='demandOffersTopContainerV2'>
                <div></div>
                
                <div style={{display:'flex',alignItems:'center'}}>
                    {/* <div style={{marginRight:"15px",cursor:'pointer'}} onClick={()=>{localStorage.removeItem('newDemandOfferPage');window.location.reload();}}>Switch to Old Design</div> */}
                    
                    <div style={{paddingLeft:'10px',paddingRight:'10px',color:localStorage.getItem('ambientColor'),cursor:'pointer'}} onClick={()=>{exportDemandSource()}}>
                        Export CSV
                    </div>
                    {
                        !isAutomationPage &&
                        <NavLink style={{textDecoration:"none",color:"black"}} to={"/demandsource/management/add"}>
                            <Button sx={{...Constants.OUTLINEDBUTTON,height:'25px',fontSize:"13px",textTransform:'none',fontWeight:'500',paddingTop:'8px'}}>Add</Button>
                        </NavLink>
                    }
                </div>
            </div>
            <div className='demandOffersSecondContainerV2' style={viewType==='tiles' ? {position:'sticky',top:0,background:'white',zIndex:'10'} : {}}>
                {filters.map((filterItem,filterIndex)=>{
                    return <div key={filterIndex} style={{transform:'scale(0.8)',minWidth:'200px',fontSize:'12px'}}>
                                <div className='selectFieldLabel'>{filterItem.label}</div>
                                <Select
                                        isClearable
                                        options={filterItem.options}
                                        isMulti={filterItem.isMulti}
                                        value={filterItem.selectedValue}
                                        onChange={(e)=>{filterItem.setValueMethod(e)}}
                                        menuPortalTarget={document.body} 
                                        placeholder={"Select Platform"}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 1000000 }),
                                                    control: (base,state) => ({
                                                        ...base,
                                                        // maxHeight: 100,
                                                        minHeight: 30,
                                                        overflowY: "auto",
                                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid grey',
                                                        boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                                        '&:hover': {
                                                            border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                            boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                                        }
                                                    }),
                                                    option: (styles, {isFocused, isSelected}) => ({
                                                        ...styles,
                                                        fontSize:'11px',
                                                        background: isFocused
                                                            ? localStorage.getItem('ambientColor')
                                                            : isSelected
                                                                ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                : undefined,
                                                        zIndex: 1
                                                    }),
                                                }}
                                    />
                            </div>
                })}

                <Button disabled={showLoader ? true : false} sx={{...Constants.CONTAINEDBUTTON,marginLeft:'20px',marginTop:'15px',height:'30px',fontSize:"13px",textTransform:'none',fontWeight:'500',paddingTop:'8px'}}
                    onClick={()=>{showDemandSourceRequest(limit,1)}}
                >
                    {showLoader && <CircularProgress sx={{color:'white'}} size={18}/>}
                    {!showLoader && "Search"}
                </Button>
                <Button sx={{...Constants.OUTLINEDBUTTON,marginLeft:'15px',marginTop:'15px',height:'30px',fontSize:"13px",textTransform:'none',fontWeight:'500',paddingTop:'8px'}}
                    onClick={()=>{setAdvertiserSelected({label:'All',value:null});setPlatformSelected({label:'All',value:null});setStatusSelected({label:'All',value:null});setManagerSelected({label:'All',value:null})}}
                >
                    Reset
                </Button>
            </div>
            <div className='demandOffersThirdContainerV2'>
                <div>
                    {!showLoader && "Showing "+offset+" - "+(offset+limit-1 < totalResults ? offset+limit-1 : totalResults)+" out of "+totalResults}
                </div>
                <div>
                <Tooltip sx={{zIndex:0}} style={{zIndex:0}} title={viewType === 'table' ? "Showing Table View" : "Switch to Table View"}>
                    <IconButton onClick={()=>{setViewType('table');}}>
                        <TableRowsIcon sx={{fontSize:'20px',color:(viewType==='table' ? localStorage.getItem('ambientColor') : 'rgb(200,200,200)')}}/>
                    </IconButton>
                </Tooltip>
                <Tooltip sx={{zIndex:0}} style={{zIndex:0}} title={viewType === 'tiles' ? "Showing Tiles View" : "Switch to Tiles View"}>
                    <IconButton  onClick={()=>{setViewType('tiles')}}>
                        <GridViewIcon sx={{fontSize:'20px',color:(viewType==='tiles' ? localStorage.getItem('ambientColor') : 'rgb(200,200,200)')}}/>
                    </IconButton>
                </Tooltip>
                </div>
            </div>
            {showLoader && 
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',minHeight:'60vh'}}>
                    <CircularProgress sx={{color:localStorage.getItem('ambientColor')}} size={50}/>
                </div>
            }
            {!showLoader && viewType==='table' && showTableHeaders()}
            {!showLoader && viewType==='table' && 
                <div style={{minHeight:'53vh'}}>
                    {showTableValue()}
                </div>
            }
            {!showLoader && viewType==='tiles' && 
                <div style={{minHeight:'53vh'}}>
                    {showTilesView()}
                </div>
            }
            <div style={{display:'flex',alignItems:'center',justifyContent:"space-between",paddingLeft:'20px',paddingRight:'20px',paddingBottom:'20px',position:'sticky',bottom:0,background:'white'}}>
                <div>
                    <label style={{paddingRight:"5px",fontSize:'14px'}}>Limit</label>
                    <select value={limit} style={{outline:'none',border:'none'}} onChange={(e)=>{showDemandSourceRequest(e.target.value,1);setLimit(parseInt(e.target.value));}}>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={75}>75</option>
                        <option value={100}>100</option>
                    </select>
                </div>
                <Pagination count={Math.ceil(totalResults/limit)} page={page} onChange={(event,value)=>{showDemandSourceRequest(limit,value);setPage(value);refreshComponent(event);window.scrollTo({top: 0,left: 0,behavior: 'smooth'});}} />
            </div>
        </div>
    )
}

export default DemandSourceV2