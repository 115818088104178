import { Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react'
import ReactSelect from 'react-select';
import { getManager } from '../../../apis/dropdown/dropdowns';
import { increase_brightness } from '../../../components/customization/customization';
import '../../../css/pages/v2/advertiser.css';
import * as Constants from '../../../data/constants';
import { getLocalStorageValue, getRequests, useForceUpdate } from '../../../util/util';
import { Store } from 'react-notifications-component';
import { NavLink } from 'react-router-dom';
import CopyButton from '../../../components/copyButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Validations from '../../../util/validations';
import Tooltip from '@mui/material/Tooltip';
const NewEditAdvertiserPageV2 = () => {
    const refreshComponent = useForceUpdate();
    if(window.location.href.includes('/edit')){
        var tempAdvertiserId = window.location.href.split('/edit/')[1]
    }
    else{
        tempAdvertiserId = false
    }
    if(tempAdvertiserId){
        document.title = 'Edit Advertiser - '+tempAdvertiserId
    }
    else{
        document.title = 'Add Advertiser'
    }

    const s2sPostback = {s2s_postback:Constants.S2SPOSTBACK}
    const [advertiserId, setAdvertiserId] = useState(tempAdvertiserId)
    const [manualUseEffect, setManualUseEffect] = useState(false)
    const [managerOptions, setManagerOptions] = useState([])
    const [response, setResponse] = useState({...s2sPostback,advertiser_status:{label:'Active',value:'1'}})
    const [buttonLoader, setButtonLoader] = useState(false)

    const getAdvertiserDetails = async() => {
        setButtonLoader(true)
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.ADVERTISERS),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
              id:advertiserId,
            }
        }
        )
        .then((resp) => {
            var responseResult = resp.data.result
            for (var item in responseResult){
                if(responseResult[item].id.toString() === advertiserId){
                    var advertiserDetail = responseResult[item]
                    var advertiserResponse = {...s2sPostback}
                    var tempValidationFields = validationFields
                    for(var keyIndex in advertiserDetail)
                        if(advertiserDetail[keyIndex]){
                            tempValidationFields = checkValidation(keyIndex,advertiserDetail[keyIndex],tempValidationFields)
                            if(keyIndex==='address'){
                                try{
                                    for(var addressIndex in advertiserDetail[keyIndex]){
                                        if(addressIndex==='country'){
                                            advertiserResponse = {...advertiserResponse,[addressIndex]:{label:advertiserDetail[keyIndex][addressIndex],value:advertiserDetail[keyIndex][addressIndex]}}
                                        }
                                        else{
                                            advertiserResponse = {...advertiserResponse,[addressIndex]:advertiserDetail[keyIndex][addressIndex]}
                                        }
                                    }
                                }
                                catch{
                                    console.log('error')
                                }
                            }  
                            else if(keyIndex==='manager_name'){
                                advertiserResponse = {...advertiserResponse,[keyIndex]:{label:advertiserDetail[keyIndex].split(')')[1] + " - " + advertiserDetail[keyIndex].split(')')[0].split("(")[1],value:advertiserDetail[keyIndex].split(')')[0].split("(")[1]}}
                            }
                            else if(keyIndex==='advertiser_status'){
                                advertiserResponse = {...advertiserResponse,[keyIndex]:{label:advertiserDetail[keyIndex]==='2' ? 'Traffic Limited' : advertiserDetail[keyIndex]==='1' ? 'Active' : 'Inactive',value:advertiserDetail[keyIndex]}}
                            }
                            else if(keyIndex==='traffic_limiter'){
                                advertiserResponse = {...advertiserResponse,[keyIndex]:{label:advertiserDetail[keyIndex]===true ? 'Enabled' : 'Disabled',value:advertiserDetail[keyIndex]}}
                            }
                            else{
                                advertiserResponse = {...advertiserResponse,[keyIndex]:advertiserDetail[keyIndex]} 
                            }
                        }
                    }
                }
            setResponse(advertiserResponse)
            setButtonLoader(false)
        })
        .catch(error => {
            console.log(error)
            if (error.response) {
                // Request made and server responded
                console.log(error.response.status);
                console.log(error.response.headers);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        });
    }

    if(!manualUseEffect){
        setManualUseEffect(true);
        getRequests(getManager(),setManagerOptions)
        if(advertiserId){
            getAdvertiserDetails()
        }
    }

    var formData = [
        {
            label:'General',
            width:'75%',
            child:[
                {label:'Advertiser Company',value:'company',type:'textfield',mandatory:true,validation:'companyName'},
                {label:'Manager',value:'manager_name',type:'select',disabled:true,mandatory:false,options:managerOptions,validation:'firstName'},
                {label:'Status',value:'advertiser_status',type:'select',disabled:true,mandatory:true,options:[{label:'Active',value:'1'},{label:'Inactive',value:'0'},{label:"Traffic Limited",value:"2"}]},
                {label:'Traffic Limiter',value:'traffic_limiter',type:'select',disabled:true,options:[{label:'Enabled',value:true},{label:'Disabled',value:false},]},
                {label:'Contact Person Name',value:'contact_person',type:'textfield',mandatory:true},
                {label:'S2S Postback Link',value:'s2s_postback',type:'textfield',disabled:false,copyButton:true,info:['&goal={goal}','&offer_id={offer_id}','&advertiser_id={advertiser_id}']},
                {label:'Contact Person Email',value:'email',type:'textfield',validation:'email'},
                {label:'Contact Person Number',value:'contact_number',type:'textfield',validation:'number'},
                {label:'Website',value:'website',type:'textfield',validation:'website'},
                {label:'Skype',value:'skype_id',type:'textfield',validation:'skype'},
            ]
        },
        {
            label:'Note',
            width:'25%',
            child:[
                {label:'Note',hideLabel:true,value:'note',type:'textarea'},
            ]
        },
        
        {
            label:'Advertiser Address',
            width:'75%',
            child:[
                {label:'Address 1',value:'address1',type:'textfield'},
                {label:'Address 2',value:'address2',type:'textfield'},
                {label:'City',value:'city',type:'textfield'},
                {label:'Country',value:'country',type:'select',options:Constants.COUNTRIES_LIST.map((item,index)=>{return {label:item,value:item}})},
                {label:'Zipcode',value:'zipcode',type:'textfield'},
            ]
        },

    ]
    

  const addEditAdvertiserRequest = async() => {
        setButtonLoader(true)
        var addressList = ['address1','address2','city','country','zipcode']
        var address = {}
        for(var addressIndex in addressList){
            if(response[addressList[addressIndex]]){
                if(addressList[addressIndex]==='country'){
                    address[addressList[addressIndex]] = response[addressList[addressIndex]].value
                }
                else{
                    address[addressList[addressIndex]] = response[addressList[addressIndex]]
                }
            }
        }
        var url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.ADD_ADVERTISER)
        if(advertiserId){
            url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.ADVERTISER,"/",advertiserId,"/",Constants.CHANGE)
        }
        await axios({
            method: 'POST',
            url,
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":"application/json",
            },
            data:{
                "advertiser_status":response.advertiser_status ? response.advertiser_status.value : null,
                "company_name":response.company,
                "contact_person":response.contact_person ? response.contact_person : null,
                "contact_number":response.contact_number ? response.contact_number : null,
                "address": address,
                "email": response.email ? response.email : null,
                "manager_id": response.manager_name ? response.manager_name.value : null,
                "note": response.note ? response.note : null,
                "skype_id": response.skype_id ? response.skype_id : null,
                "website": response.website ? response.website : null,
                "traffic_limiter": response.traffic_limiter ? response.traffic_limiter ? response.traffic_limiter.value : undefined : null,
                }
            })
            .then((resp)=>{
                var responseString = 'added'
                if(advertiserId){
                    responseString = 'edited'
                }
                if(resp.data.result && resp.data.result.advertiser){
                    setAdvertiserId(resp.data.result.advertiser.id)
                    Store.addNotification({
                        title: "Success!",
                        message: "Advertiser "+responseString+" successfully.",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true
                        }
                    });
                }
                else{
                    Store.addNotification({
                        title: "Error!",
                        message: typeof resp.data.result === "string" ? resp.data.result : "Some error occured",
                        type: "warning",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true
                        }
                    });
                }

            })
            .catch(function (error) {
                if (error.response) {
                    if(error.response.data){
                        Store.addNotification({
                            title: "Error!",
                            message: typeof error.response.data.result === "string" ? error.response.data.result : "Some error occured",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 3000,
                                onScreen: true
                            }
                        });
                    }
                    // Request made and server responded
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
        });
        setButtonLoader(false);
    };

    const [disableButton, setDisableButton] = useState(true)
    const [validationFields, setValidationFields] = useState({
        company : false,
        contact_person : false,
        email : true,
        contact_number : true,
        website : true,
        skype_id : true,
    })
    const checkValidation = (key,value,returnPlease) => {
        return Validations(key,value,returnPlease,validationFields,setValidationFields,setDisableButton)
    }

    const [infoHovered, setInfoHovered] = useState(false)

    return (
        <div className='advertiserPageV2'>
            <div style={{fontSize:'18px',fontWeight:600,display:'flex',alignItems:'center'}}>
                {advertiserId ? 'Edit ' : 'Add '} Advertiser
                {buttonLoader && <CircularProgress sx={{color:localStorage.getItem('ambientColor'),marginLeft:'20px'}} size={17}/>}
            </div>
            <div className='advertiserPageDataContainer'>
                {formData.map((item,index)=>{
                    return <div key={index} className='advertiserPageDataWrapperMobile' style={{width:item.width}}>
                                <div className='advertiserPageDataWrapper'>
                                    
                                    <div className='advertiserPageDataHeader'>{item.label}</div>
                                    <div className='advertiserPageGeneralDataContainer'>
                                        {item.child.map((childItem,childIndex) => {
                                            return <div>
                                                <div className='advertiserPageGeneralDataWrapper' key={childIndex}>
                                                    {!childItem.hideLabel && <div className='addEditAdvertiserPageLabel'>{childItem.label}{childItem.mandatory ? '*' : ''}</div>}
                                                        
                                                    {childItem.type==='textfield' && 
                                                    
                                                        <input id={childItem.value} className={(childItem.mandatory && !response[childItem.value]) || (childItem.mandatory && validationFields[childItem.value]===false) || (!childItem.mandatory && response[childItem.value] && validationFields[childItem.value]===false) ? 'addEditAdvertiserPageTextField invalidAddEditAdvertiserPageTextField' : 'addEditAdvertiserPageTextField'} style={ !childItem.hideLabel ? {} : {width:'100%'}} disabled={childItem.disabled || buttonLoader} value={response[childItem.value] ? response[childItem.value] : ''} onChange={(e)=>{checkValidation(childItem.value,e.target.value);setResponse({...response,[childItem.value]:e.target.value})}}/>
                                                    }
                                                    {childItem.type==='select' && 
                                                        <div id={childItem.value} className='addEditAdvertiserPageSelectField' style={{width:'66.5%'}}>
                                                            <ReactSelect
                                                                isDisabled={buttonLoader}
                                                                options={childItem.options}
                                                                value={response[childItem.value]}
                                                                onChange={(e)=>{setResponse({...response,[childItem.value]:e})}}
                                                                menuPortalTarget={document.body} 
                                                                placeholder={"Select "+childItem.label}
                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 1000000 }),
                                                                            control: (base,state) => ({
                                                                                ...base,
                                                                                // maxHeight: 100,
                                                                                minHeight: 30,
                                                                                color:'rgb(200,200,200)',
                                                                                overflowY: "auto",
                                                                                fontSize:12,
                                                                                border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                                                boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                                                                '&:hover': {
                                                                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                                                    boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                                                                }
                                                                            }),
                                                                            placeholder: (defaultStyles) => {
                                                                                return {
                                                                                    ...defaultStyles,
                                                                                    color: 'rgb(150,150,150)',
                                                                                    marginTop:'-2px'
                                                                                }
                                                                            },
                                                                            option: (styles, {isFocused, isSelected}) => ({
                                                                                ...styles,
                                                                                fontSize:'11px',
                                                                                background: isFocused
                                                                                    ? localStorage.getItem('ambientColor')
                                                                                    : isSelected
                                                                                        ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                                        : undefined,
                                                                                zIndex: 1
                                                                            }),
                                                                        }}
                                                            />
                                                        </div>
                                                    }
                                                    {childItem.type==='textarea' && 
                                                        <textarea id={childItem.value} className='addEditAdvertiserPageTextField' style={ !childItem.hideLabel ? {height:'200px'} : {width:'99%',height:'200px'}} value={response[childItem.value] ? response[childItem.value] : ''} onChange={(e)=>{setResponse({...response,[childItem.value]:e.target.value})}} disabled={childItem.disabled}/>
                                                    }
                                                    {childItem.copyButton &&
                                                    <div style={{position:'absolute',right:'-10px',transform:'scale(0.8)',display:'flex',alignItems:'center'}}>
                                                        {childItem.value === 's2s_postback' && Constants.S2SPOSTBACK.includes('https') && 
                                                            <div>
                                                            <Tooltip title='Use HTTP'>
                                                                <input checked={!response.s2s_postback.includes('https')} onChange={(e)=>{if(e.target.checked){setResponse({...response,s2s_postback:response.s2s_postback.replace('https','http')})}else{setResponse({...response,s2s_postback:response.s2s_postback.replace('http','https')})}; refreshComponent(2123);}} type='checkbox'/>
                                                            </Tooltip>
                                                            <br/>
                                                            <label style={{position:'absolute',fontSize:'10px'}}>HTTP</label>
                                                            </div>
                                                        }
                                                        <CopyButton idToCopy={childItem.value}/>
                                                        {childItem.info &&
                                                            <div onMouseEnter={()=>{setInfoHovered(true);}} onMouseLeave={()=>{setInfoHovered(false)}}>
                                                                <InfoOutlinedIcon sx={{color:'rgb(100,100,100)'}}/>
                                                                {infoHovered && 
                                                                    <div style={{position:'absolute',minWidth:'200px',width:'min-content',padding:'10px',background:'white',textAlign:'left',boxShadow:'0 0 5px 1px rgb(200,200,200)'}} onMouseEnter={()=>{setInfoHovered(true);}}>
                                                                        <div style={{marginBottom:'5px'}}>Params: </div>
                                                                        {childItem.info.map((infoItem,infoIndex)=>{
                                                                            return <div style={{fontSize:'14px'}} key={infoIndex}>
                                                                                {infoItem}
                                                                                </div>
                                                                        })}
                                                                    </div>}
                                                            </div>
                                                        }
                                                    </div>
                                                    }
                                                </div>
                                                {childItem.value === 's2s_postback' && Constants.S2SPOSTBACK.includes('https') && 
                                                    <div style={{marginLeft:'25%',marginBottom:'30px'}}>
                                                        <Tooltip title='Use HTTP'>
                                                            <input checked={!response.s2s_postback.includes('https')} onChange={(e)=>{if(e.target.checked){setResponse({...response,s2s_postback:response.s2s_postback.replace('https','http')})}else{setResponse({...response,s2s_postback:response.s2s_postback.replace('http','https')})}; refreshComponent(2123);}} type='checkbox'/>
                                                        </Tooltip>
                                                        <br/>
                                                        <label style={{position:'absolute',fontSize:'10px'}}>Use HTTP</label>
                                                    </div>
                                                }
                                                </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                })}
            </div>
            <div className='advertiserPageButtonPallete'>
                <Button disabled={disableButton || buttonLoader} sx={{...Constants.CONTAINEDBUTTON,fontSize:'15px',paddingLeft:'15px',paddingRight:'15px',textTransform:'none',fontWeight:500,marginLeft:'15px',width:'40px',height:'30px'}} onClick={addEditAdvertiserRequest}>
                    {!buttonLoader && (advertiserId ? 'Edit' : 'Add')}
                    {buttonLoader && <CircularProgress sx={{color:'white'}} size={18}/>}
                </Button>
                <NavLink to={'/advertiser'} style={{textDecoration:'none'}}>
                    <Button sx={{...Constants.OUTLINEDBUTTON,fontSize:'15px',paddingLeft:'15px',paddingRight:'15px',textTransform:'none',fontWeight:500,marginLeft:'15px',height:'30px'}}>Cancel</Button>
                </NavLink>
            </div>
        </div>
    )
}

export default NewEditAdvertiserPageV2