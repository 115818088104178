import React, { useState } from 'react'
import axios from 'axios'
import TableModal from './components/tableModal/tableModal'

const SharedDataPage = () => {
    var dataCode = window.location.pathname.split('/shared')[1]
    const [manualUseEffect, setManualUseEffect] = useState(false)
    document.title = 'Shared Reports'

    var fixTableHeadersMapping = {
        date : [{show:true,label:'Date',value:'key',minWidth:60,width:"10%"},],
        week : [{show:true,label:'Week',value:'key',minWidth:60,width:"10%"},],
        offer : [
                    {show:true,label:'Offer Id',value:'key',minWidth:60,width:"4%"},
                    {show:true,label:'Offer Title',value:'title',minWidth:50,width:"10%"},
                    {show:true,label:'Advertiser',value:'advertiser_id',minWidth:60,width:"5%"},
                    {show:true,label:'External Offer Id',value:'external_offer_id',minWidth:50,width:"5%"},
                ],
        advertiser :   [
                    {show:true,label:'Advertiser Id',value:'key',minWidth:80,width:"4%"},
                    {show:true,label:'Advertiser Name',value:'company',minWidth:50,width:"8%"}
                ],
        "advertiser_manager" : [
                            {show:true,label:'Manager Id',value:'key',minWidth:50,width:"4%"},
                            {show:true,label:'Manager Name',value:'name',minWidth:50,width:"8%"}
                        ],
        affiliate : [
                    {show:true,label:'Affiliate Id',value:'key',minWidth:50,width:"4%"},
                    {show:true,label:'Affiliate Name',value:'company',minWidth:50,width:"8%"}
                ],
        "affiliate_manager" : [
                            {show:true,label:'Manager Id',value:'key',minWidth:50,width:"4%"},
                            {show:true,label:'Manager Name',value:'name',minWidth:50,width:"8%"}
                        ],

        "smartlinks" : [
            {show:true,label:'Smartlink ID',value:'key',minWidth:50,width:"4%"},
            {show:true,label:'Smartlink Name',value:'name',minWidth:50,width:"8%"}
        ],
        "offer-datewise":[
                            {show:true,label:'Offer Id',value:'key',minWidth:60,width:"4%"},
                            {show:true,label:'Offer Title',value:'title',minWidth:50,width:"10%"},
                            {show:true,label:'Advertiser',value:'advertiser_id',minWidth:60,width:"5%"},
                            {show:true,label:'External Offer Id',value:'external_offer_id',minWidth:50,width:"5%"},
                        ]

    }

    var varTableHeaders = [
        {id:'one',show:true,label:'Clicks',value:'clicks',parent:'Traffic',minWidth:70,width:"5%"},
        {id:'two',show:true,label:'Trafficback',value:'trafficback',parent:'Traffic',minWidth:90,width:"6%"},
        {id:'three',show:true,label:'Delivered QTY',value:'conversions',minWidth:70,width:"8%",},
        {id:'four',show:true,label:'Delivered Revenue',value:'approved_revenue',minWidth:70,width:"8%"},
        {id:'five',show:true,label:'Declined QTY',value:'declined',minWidth:70,width:"8%"},
        {id:'six',show:true,label:'Declined Revenue',value:'declined_revenue',minWidth:70,width:"8%"},
        {id:'seven',show:true,label:'CR',value:'cr',minWidth:50,width:"5%"},
        {id:'eight',show:true,label:'EPC',value:'epc',minWidth:50,width:"5%"},
        {id:'nine',show:true,label:'Total QTY',value:'total_qty',minWidth:50,width:"6%"},
        {id:'ten',show:true,label:'Total Revenue',value:'approved_revenue',minWidth:50,width:"6%"},
        {id:'eleven',show:true,label:'Total Payout',value:'approved_payout',minWidth:50,width:"6%"},
        {id:'twelve',show:true,label:'Total Earnings',value:'total_earnings',minWidth:50,width:"6%"},
    ]

    var [tableHeaders,setTableHeaders] = useState([])
    const [loader, setLoader] = useState(false)
    const [response, setResponse] = useState(false)
    const [dateRange, setDateRange] = useState('')

    const getDownloadData = async(allRequired) => {
        var tableType = {
            date : 'Daily',
            week:'Weekly',
            offer:'Offers',
            advertiser:'Advertisers',
            advertiser_manager: 'Advertiser Manager',
            affiliate : 'Affiliates',
            affiliate_manager : 'Affiliates Manager',
            smartlinks : 'Smartlinks',
            'offer-datewise' : 'Day wise Offers'
        }
        setLoader(true)
        return await axios({
            method: 'GET',
            url: "".concat(process.env.REACT_APP_PROTOCOL,process.env.REACT_APP_BACKEND_SERVER_DOMAIN,"/api/report/v1/download?filename=",dataCode),
        })
        .then(resp => {
            setLoader(false)
            var statsType = resp.data.table_name
            var fixTableHeaders = fixTableHeadersMapping[statsType]
            setDateRange({dateRange:resp.data.date_range,timeZone:resp.data.timezone_name,tableType:tableType[statsType],platformName:resp.data.platform})
            var responseList = []
            var response = resp.data.data
            
            if(resp.data.table_name==='offer-datewise'){
                var totalResponseList = []
                for(var responseIndex in response){
                    if(responseIndex!=='date_wise_total'){
                        responseList.push({
                            ...response[responseIndex],
                            key:parseInt(responseIndex)
                        })
                    }
                    if(responseIndex==='date_wise_total'){
                        var tempTotalTotal = {approved_revenue:0,conversions:0}
                        for(var totalIndex in response[responseIndex]){
                            tempTotalTotal['approved_revenue'] += response[responseIndex][totalIndex].approved_revenue
                            tempTotalTotal['conversions'] += response[responseIndex][totalIndex].conversions
                        }
                        totalResponseList.push({
                            ...response[responseIndex],
                            key:'Total',
                            external_offer_id:'-',
                            title:'-',
                            advertiser_id:'-',
                            total:tempTotalTotal
                        })
                    }
                }
                setResponse(responseList)
                var customDateHeadComponent = (tableHead,tableHeadIndex,childItems,tableSortingOrder,setTableSortingOrder) => {
                    return <table style={{width:"100%"}}>
                                <thead>
                                    <tr>
                                        <td colSpan={3}>
                                            {tableHead.label}
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{fontSize:'11px'}}>
                                        <td width={'33%'} style={{cursor:'pointer'}}>Delivered Revenue</td>
                                        <td width={'33%'} style={{cursor:'pointer'}}>Delivered QTY</td>
                                    </tr>
                                </tbody>
                            </table>
                }

                const customDateValueComponent = (value,tableHeadIndex,item,index,tableHead) => {
                    return <table style={{width:"100%",tableLayout:'fixed'}}>
                        <tbody>
                            <tr style={{fontSize:'11px'}}>
                                <td style={{minWidth:'33%',width:'33%',maxWidth:'33%'}} className='overflowText'>
                                    {value.approved_revenue ? value.approved_revenue : '0'}
                                </td>
                                <td style={{minWidth:'33%',width:'33%',maxWidth:'33%'}} className='overflowText'>
                                    {value.conversions ? value.conversions : '0'}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                }
                var tempVarTableHeaders = []

                for(var index in response.date_wise_total){
                    if(!['title','advertiser_id','external_offer_id','total'].includes(index)){
                        tempVarTableHeaders.push(
                            {show:true,label:index,value:index,minWidth:150,width:170,customHeadComponent:customDateHeadComponent,customComponent:customDateValueComponent},
                        )
                    }
                }
                // tempVarTableHeaders = tempVarTableHeaders.reverse();
                if(response[Object.keys(response)[0]].total){
                    tempVarTableHeaders.push(
                        {show:true,label:'Total',value:'total',minWidth:150,width:170,customHeadComponent:customDateHeadComponent,customComponent:customDateValueComponent},
                    )
                }
                tempVarTableHeaders = tempVarTableHeaders.reverse();
                setTableHeaders([...fixTableHeaders,...tempVarTableHeaders])
            }
            else{
                
                varTableHeaders.splice(3, 0, 
                    {id:'threetwo',show:true,label:'Delivered Postback',value:'postback',minWidth:70,width:"8%",},
                );

                setTableHeaders([...fixTableHeaders,...varTableHeaders])
                for(index in response){
                    var item = response[index]
    
                    responseList.push({
                        ...response[index],
                        key:index,
                        cr:(item.clicks>0 ? (((item.conversions + item.declined)/item.clicks)*100).toFixed(2).toString() : '0.00') + ((item.conversions + item.declined)===0 ? " (0:0)" : " (1:" + parseInt(item.clicks/(item.conversions + item.declined)) + ")"),
                        total_earnings:(item.payouts[0].approved_revenue - item.payouts[0].approved_payout),
                        approved_payout:item.payouts[0].approved_payout,
                        approved_revenue:item.payouts[0].approved_revenue,
                        declined_revenue:item.payouts[0].declined_revenue,
                        declined_payout:item.payouts[0].declined_payout,
                        total_qty:parseInt(item.conversions)+parseInt(item.declined),
                        currency:item.payouts[0].currency,
                        epc:item.payouts.length>0 && item.clicks>0 ? (item.payouts[0].approved_revenue/item.clicks).toFixed(3) : 0,
                    })
                    // responseList.push({...response[index],key:index})
                }
            }
            setResponse(responseList)
        })
        .catch(err => {
            console.error(err);
        })
    };
    if(!manualUseEffect){
        setManualUseEffect(true);
        getDownloadData()
    }
    var getImg = (platform) => {
        try{
            var logo = require('./img/companies/'+platform+".png")
            if(logo){
                return <img src={logo} alt=''/>
            }
            else{
                try{
                    logo = require('./img/companies/'+process.env.REACT_APP_COMPANY_NAME.toLowerCase()+".png")
                    return <img src={logo} alt=''/>
                }
                catch{
                    return <div>{platform}</div>
                }
            }
        }
        catch{
            return <div>{platform}</div>

        }
    }
    return (
        <div style={{marginLeft:'2%',marginRight:'2%'}}>
            {!loader &&
                <>
                    <div style={{marginTop:'30px'}}>
                        {getImg(dateRange.platformName)}
                    </div>
                    <div style={{display:'flex',marginTop:'20px',marginBottom:'10px',alignItems:'flex-end'}}>
                        <label style={{fontWeight:'600',fontSize:'18px'}}>Date Range : </label>
                        <label style={{marginLeft:'10px'}}>{dateRange.dateRange}</label>
                    </div>
                    <div style={{display:'flex',marginBottom:'10px',alignItems:'flex-end'}}>
                        <label style={{fontWeight:'600',fontSize:'18px'}}>Time Zone : </label>
                        <label style={{marginLeft:'10px'}}>{dateRange.timeZone}</label>
                    </div>

                    <div style={{display:'flex',marginBottom:'30px',alignItems:'flex-end'}}>
                        <label style={{fontWeight:'600',fontSize:'18px'}}>Data : </label>
                        <label style={{marginLeft:'10px'}}>{dateRange.tableType} Statistics</label>
                    </div>
                </>
            }
            <TableModal 
                stickyColumn={[0]} 
                showLoader={loader} 
                tableHeaders={tableHeaders} 
                tableHeadersStyle={{fontSize:'13px',wordWrap:'break-word',whiteSpace:'normal'}}
                tableValueStyle={{fontSize:'13px'}}
                hideSettingsBar={true}
                setTableHeaders={setTableHeaders} 
                tableValues={response} 
                setTableValues={setResponse} 
                hideTooltip={true} 
                showCustomization={false} 
            />
        </div>
    )
}

export default SharedDataPage