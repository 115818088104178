import axios from "axios";
import * as Constants from '../../data/constants';
import { getLocalStorageValue } from "../../util/util";

var dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric'};

export const getStats = async(dateRange) => {
    var fromDate = new Date(new Date().getTime()-86400000*dateRange).toLocaleDateString("sv-SE", dateOptions)
    var toDate = new Date(new Date().getTime()).toLocaleDateString("sv-SE", dateOptions)
    var timezone = getLocalStorageValue('panelTimezone')
    return await axios({
        method: 'GET',
        // url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.GETSTATS),
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.GETSTATS,"/date/new?order_by=DESC&order_field=date"),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
        params:{
            startDate: fromDate,
            endDate: toDate,
            limit:100,
            page:1,
            currency:localStorage.getItem('panelDefaultCurrency') && localStorage.getItem('panelDefaultCurrency')!=="ALL/USD" ? localStorage.getItem('panelDefaultCurrency').toLowerCase() : undefined,
            utc_offset : timezone.value ? timezone.value.split(timezone.label.split(" (UTC")[0])[timezone.value.split(timezone.label.split(" (UTC")[0]).length-1].split("UTC")[1] : "+0:00",
        }
    })
    .then(resp => {
        var result = []
        for(var index in resp.data.result.data){
            var payout = 0
            var revenue = 0
            var declined = 0
            var earnings = 0
            var conversions = resp.data.result.data[index].conversions
            var clicks = resp.data.result.data[index].clicks
            for(var payoutIndex in resp.data.result.data[index].payouts){
                if(localStorage.getItem('panelDefaultCurrency') && localStorage.getItem('panelDefaultCurrency')!=="ALL/USD" && resp.data.result.data[index].payouts[payoutIndex].currency.toLowerCase()===localStorage.getItem('panelDefaultCurrency').toLowerCase()){
                    payout = resp.data.result.data[index].payouts[payoutIndex].approved_payout
                    revenue = resp.data.result.data[index].payouts[payoutIndex].approved_revenue
                    declined = resp.data.result.data[index].payouts[payoutIndex].declined_revenue
                }
                else{
                    if(resp.data.result.data[index].payouts[payoutIndex].currency.toLowerCase()==='usd'){
                        payout = resp.data.result.data[index].payouts[payoutIndex].approved_payout
                        revenue = resp.data.result.data[index].payouts[payoutIndex].approved_revenue
                        declined = resp.data.result.data[index].payouts[payoutIndex].declined_revenue
                    }
                }
            }
            earnings = revenue - payout
            result.push({date:index,
                            clicks,
                            conversions,
                            payout,
                            revenue,
                            declined,
                            earnings,})
        }
        result.reverse()
        return {result}
    })
    .catch(err => {
        console.error(err);
    })
};


export const getAccountManager = async() => {
    return await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"accountmanager"),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
        params:{
        }
    })
    .then(resp => {
        return resp.data.result
    })
    .catch(err => {
        console.error(err);
    })
};


export const getTopOffers = async(dateRange) => {
    return await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"top/offer"),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
        params:{
            days:dateRange,
        }
    })
    .then(resp => {
        return resp.data.result
    })
    .catch(err => {
        console.error(err);
    })
};

export const getTopAffiliates = async(dateRange) => {
    return await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"top/affiliate"),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
        params:{
            days:dateRange
        }
    })
    .then(resp => {
        return resp.data.result
    })
    .catch(err => {
        console.error(err);
    })
};


export const activeOffersApi = async(dateRange) => {
    return await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"offers/active"),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
    })
    .then(resp => {
        return resp.data.result
    })
    .catch(err => {
        console.error(err);
    })
};

export const activeAffiliatesApi = async(dateRange) => {
    return await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"affiliate/active"),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
        params:{
            days:dateRange
        }
    })
    .then(resp => {
        return resp.data.result
    })
    .catch(err => {
        console.error(err);
    })
};

export const activeAdvertiserApi = async(dateRange) => {
    return await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"activeadvertiser"),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
        params:{
            days:dateRange
        }
    })
    .then(resp => {
        return resp.data.result
    })
    .catch(err => {
        console.error(err);
    })
};

export const runningOfferApi = async(dateRange) => {
    return await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"offers/running"),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
        params:{
            days:dateRange
        }
    })
    .then(resp => {
        return resp.data.result
    })
    .catch(err => {
        console.error(err);
    })
};