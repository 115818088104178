import React, { useCallback, useEffect, useState } from 'react'
import { getElementCoords,getLocalStorageValue, setLocalStorageValue,removeLocalStorageValue, sortingV2 } from '../../util/util'
import { Resizable } from "re-resizable";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import './tableModal.css';
import { Button, CircularProgress } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SettingsIcon from '@mui/icons-material/Settings';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import NoResults from '../../img/noResults.png';
import SomethingLooksFishy from '../../img/somethingLooksFishy.png';
import CustomizeTablePng from '../../img/customizeTable.png';
import * as Constants from '../../data/constants';
import UnderConstruction from '../underConstruction';

import TableRowsIcon from '@mui/icons-material/TableRows';
import GridViewIcon from '@mui/icons-material/GridView';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import CachedIcon from '@mui/icons-material/Cached';

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    if(false){
        console.log(value)
    }
    return () => setValue(value => value + 1); // update the state to force render
  }

const TableModal = (props) => {
    const refreshComponent = useForceUpdate()
    const [tableHeaders, setTableHeaders] = useState([])
    // const [stickyColumnsList, setStickyColumnsList] = useState([1,2])
    const [mouseOverText, setMouseOverText] = useState(false)
    
    const makeStickyColumns = (tableHeadIndex) => {
        var tableColumnSticky = {}
        var leftStickyColumnsList = props.stickyColumn
        var rightStickyColumnList = props.rightStickyColumnList
        if(leftStickyColumnsList.includes(tableHeadIndex)){
            tableColumnSticky['position'] = 'sticky'
            tableColumnSticky['zIndex'] = tableHeaders.length - tableHeadIndex 
            // tableColumnSticky['background'] = 'white'
            if(leftStickyColumnsList.length>1){
                var leftForMultiple = 0
                for(var index in leftStickyColumnsList){
                    var element = leftStickyColumnsList[index]
                    if(element === tableHeadIndex){
                        break;
                    }
                    else{
                        leftForMultiple += tableHeaders[element].width + 2
                    }
                }
                tableColumnSticky['left'] = leftForMultiple
            }
            else{
                tableColumnSticky['left'] = 0
            }                
        }
        if(rightStickyColumnList && rightStickyColumnList.includes(tableHeadIndex)){
            tableColumnSticky['position'] = 'sticky'
            tableColumnSticky['zIndex'] = tableHeaders.length - tableHeadIndex 
            // tableColumnSticky['background'] = 'white'
            if(rightStickyColumnList.length>1){
                leftForMultiple = 0
                for(index in rightStickyColumnList){
                    element = rightStickyColumnList[index]
                    if(element === tableHeadIndex){
                        break;
                    }
                    else{
                        leftForMultiple += tableHeaders[element].width + 2
                    }
                }
                tableColumnSticky['right'] = leftForMultiple
            }
            else{
                tableColumnSticky['right'] = 0
            }                
        }
        return tableColumnSticky;
    }  
      
    const checkDifferentDictionaries = useCallback(
        () => {
          try {
              if(tableHeaders.length===0){
                  return true;
              }
              for(var index in tableHeaders){
                  for(var key in tableHeaders[index]){
                      if(key!=='width'){
                          if(tableHeaders[index][key] !== props.tableHeaders[index][key]){
                              return true;
                          }
                      }
                  }
              }
              return false;
          } catch (error) {
              console.log(error)
              return true;
          }
        },
        [tableHeaders,props.tableHeaders],
    )
    
    const [threeDotMenu, setThreeDotMenu] = useState({})

    const init = useCallback((clicked) => {
        if(clicked && Object.keys(threeDotMenu).length>0){
            setThreeDotMenu({})
        }
        var hamburgerClicked = false
        if(clicked && clicked!=='refreshTable' && clicked.path && ((clicked.path.length===13 && clicked.path[0].className.includes('hamburger')) || (clicked.path.length===14 && clicked.path[1].className.includes('hamburger')))){
            hamburgerClicked = true
        }
        checkDifferentDictionaries()
        if(clicked==='refreshTable' ||(document.getElementById('tableContainer') && (checkDifferentDictionaries() || hamburgerClicked))){
            var tempTableHeaders = []
            if(getLocalStorageValue(window.location.pathname+'TableWidth')){
                var tableHeadersWidthCookie = getLocalStorageValue(window.location.pathname+'TableWidth')
                try{
                    for(var tableheaderIndex in props.tableHeaders){
                        tempTableHeaders.push({...props.tableHeaders[tableheaderIndex],width:tableHeadersWidthCookie[tableheaderIndex].width})
                    }
                }
                catch{
                    removeLocalStorageValue(window.location.pathname+'TableWidth')
                }
            }
            else{
                var parentBoundingWidth = document.getElementById('tableContainer').getBoundingClientRect().width 
                parentBoundingWidth = parentBoundingWidth * 0.96
                if(hamburgerClicked){
                    if(localStorage.getItem('sidebarOpen')==='true'){
                        parentBoundingWidth -= 140
                    }
                    else{
                        parentBoundingWidth += 155
                    }
                }
                var totalWidth = 0
                var showElements = 0
                for(tableheaderIndex in props.tableHeaders){
                    var tableHead  = props.tableHeaders[tableheaderIndex]
                    var tableHeadWidth = 0
                    if(!tableHead.hide){
                        showElements += 1;
                        if(tableHead.width && tableHead.width.toString().includes("%")){
                            tableHeadWidth = (parentBoundingWidth*parseFloat(tableHead.width.split("%")[0]))/100
                        }
                        if(tableHeadWidth < tableHead.minWidth){
                            tableHeadWidth = tableHead.minWidth
                        }
                    }
                    // if(tableHead.hide && tableheaderIndex!==0){
                    //     tempTableHeaders[parseInt(tableheaderIndex)-1].width += tableHeadWidth
                    // }
                    totalWidth += tableHeadWidth
                    tempTableHeaders.push({...tableHead,width:tableHeadWidth})
                }
                if(totalWidth<parentBoundingWidth){
                    var addWidth = (parentBoundingWidth - totalWidth)/showElements
                    var temptemptableheaders = []
                    for(tableheaderIndex in tempTableHeaders){
                        tableHead  = tempTableHeaders[tableheaderIndex]
                        if(!tableHead.hide){
                            tableHead.width += addWidth
                        }
                        temptemptableheaders.push(tableHead)
                    }
                    tempTableHeaders = temptemptableheaders;
                }
            }
            setTableHeaders(tempTableHeaders);
        }
        },
      [checkDifferentDictionaries,props.tableHeaders,threeDotMenu],
    )
    
    useEffect(() => {
        init()
        window.addEventListener('resize',()=>{
            init()
        })
        
        window.addEventListener('click',init)
        return () => {
            window.removeEventListener('click',init)
            window.removeEventListener('resize',()=>{
                init()
            })
        }
    }, [init])
    
    const [tableHeadHover, setTableHeadHover] = useState("")
    const [tableSortingOrder, setTableSortingOrder] = useState({value:'',order:true}) //order : true for asc and false for desc
    
    
    const sortTableValues = (value) => {
        var values = props.tableValues
        sortingV2(values,value,!tableSortingOrder.order)
        props.setTableValues(values)
        setTableSortingOrder({value,order:!tableSortingOrder.order})
        refreshComponent('adsad');
    } 
    const getTableHeaders = () => {
        var customStyle={}
        if(props.tableHeadersStyle){
            customStyle={...props.tableHeadersStyle}
        }
        if(tableHeaders && tableHeaders.length>0){
            return tableHeaders.map((tableHead,tableHeadIndex)=>{
                var tableColumnSticky = makeStickyColumns(tableHeadIndex)
                if(tableHead.value==='checkbox'){
                    return <td className='tableModalHead' onMouseOver={()=>{setTableHeadHover(tableHead.value);refreshComponent();}} onMouseOut={()=>{setTableHeadHover("");refreshComponent();}} id={"tableHeader"+tableHeadIndex} key={"tableHeader"+tableHeadIndex} style={{...tableColumnSticky,maxWidth:tableHead.width,width:tableHead.width,minWidth:tableHead.width,textAlign:tableHead.alignHead}}>
                                <input id='selectAllTableModalCheckbox' type='checkbox' checked={props.selectAll} onChange={(e)=>{if(!e.target.checked){props.setSelectedElements([])};props.setTableSelectAll(e.target.checked);refreshComponent(e)}}/>
                            </td>
                }
                if(!tableHead.hide){
                    return <td className='tableModalHead' onMouseOver={()=>{setTableHeadHover(tableHead.value);refreshComponent();}} onMouseOut={()=>{setTableHeadHover("");refreshComponent();}} id={"tableHeader"+tableHeadIndex} key={"tableHeader"+tableHeadIndex} style={{...customStyle,...tableColumnSticky,maxWidth:tableHead.width,width:tableHead.width,minWidth:tableHead.width,textAlign:tableHead.alignHead}}>
                        <Resizable
                            key={tableHeadIndex}
                            id={tableHead.value}
                            size={{width:tableHead.width}}
                            style={{transition:'all 0.5s linear'}}
                            enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                            // onResizeStart={()=>{
                            //     document.getElementById('tableHeaderRow').scrollLeft = headerScrollPosition
                            // }}
                            // onResize={()=>{
                            //     document.getElementById('customStatsHeaderV2').scrollLeft = headerScrollPosition
                            // }}
                            onResizeStop={(e, direction, ref, d) => {
                                var tempTableHeaders = tableHeaders
                                tempTableHeaders[tableHeadIndex].width = tempTableHeaders[tableHeadIndex].width + d.width
                                setTableHeaders(tempTableHeaders);
                                refreshComponent(e);
                                setLocalStorageValue(window.location.pathname+'TableWidth',tempTableHeaders)
                                }}
                                >
                            {   
                                !tableHead.hideSorting && 
                                <div className={tableHeadHover===tableHead.value || tableSortingOrder.value===tableHead.value ? 'tableModalSortingDiv' : 'hidetableModalSortingDiv tableModalSortingDiv'}
                                    onClick={()=>{sortTableValues(tableHead.value)}}
                                >
                                    <ArrowDropDownIcon sx={{transform:(tableSortingOrder.value===tableHead.value && tableSortingOrder.order ? 'rotate(180deg)' : 'rotate(360deg)'),transition:'0.3s all linear'}}/>
                                </div>
                            }
                            {tableHead.customHeadComponent && tableHead.customHeadComponent(tableHead,tableHeadIndex,props.childItems,tableSortingOrder,setTableSortingOrder)}
                            {
                                !tableHead.customHeadComponent && 
                                <div className='overflowText' style={{...customStyle,paddingLeft:(tableHead.alignHead === 'left' ? '5px' : '0px')}}>{tableHead.label}</div>
                            }
                        </Resizable>
                    </td>
                }
                return null;
            })
        }
    }

    const getMarginTopThreeDotMenu = (height,clickedPosition) => {
        if(clickedPosition + height + 20 > window.innerHeight){
            // console.log(window.innerHeight - clickedPosition - height - 20)
            return (- height + 10)+"px"
        }
        else{
            return -20
        }
        
    }

    const getTableValue = () => {
        var tableValues = []
        tableValues = props.tableValues && typeof props.tableValues ==='object' && props.tableValues.length>0 && props.tableValues.map((item,index)=>{
            return <tr className='tableModalRow' key={index}>
                {tableHeaders.map((tableHead,tableHeadIndex)=>{
                    if(!tableHead.hide){
                        var valueWidth = tableHead.width
                        var tableColumnSticky = makeStickyColumns(tableHeadIndex)
                        var dateOptions = { year: 'numeric', month: 'short', day: 'numeric' ,hour:'numeric',minute:'numeric',second:'numeric'};
                        var showDataValue = item[tableHead.value]
                        if(tableHead.value === 'index'){
                            showDataValue = index + 1
                        }
                        if(tableHead.value==='checkbox'){

                            var tempSelectedElements = props.selectedElements
                            if(props.tableSelectAll && !tempSelectedElements.includes(item[tableHead.whatToSelect])){
                                tempSelectedElements.push(item[tableHead.whatToSelect])
                                props.setSelectedElements(tempSelectedElements)
                                refreshComponent(12345)
                            }
                            const changeCheckboxSelected = () => {
                                if(tempSelectedElements.includes(item[tableHead.whatToSelect])){
                                    props.setTableSelectAll(false);
                                    document.getElementById('selectAllTableModalCheckbox').checked = false
                                    
                                    tempSelectedElements = tempSelectedElements.filter(e => e!==item[tableHead.whatToSelect])
                                }
                                else{
                                    tempSelectedElements.push(item[tableHead.whatToSelect])
                                    if(props.tableValues.length === tempSelectedElements.length){
                                        document.getElementById('selectAllTableModalCheckbox').checked = true
                                        props.setTableSelectAll(false);
                                    }
                                }
                                props.setSelectedElements(tempSelectedElements)
                                refreshComponent(123)
                            }
                            showDataValue = <input type='checkbox' checked={tempSelectedElements.includes(item[tableHead.whatToSelect])} onChange={(e)=>{changeCheckboxSelected(e,item);refreshComponent(e)}}/>
                        }
                        if(tableHead.value === 'created_at' || tableHead.value === 'updated_at' || tableHead.dateTime){
                            if(showDataValue){
                                showDataValue = new Date(showDataValue).toLocaleDateString("en-US", dateOptions)
                            }
                            else{
                                showDataValue = '-'
                            }
                        }
                        if(tableHead.value === 'approvedDeny'){
                            showDataValue = <div style={{display:"flex",justifyContent:"space-evenly"}}>
                                                <div style={{cursor:"pointer",color:"green",transform:"scale(0.8)"}} onClick={()=>{props.offerApprovalRequest(item.id,"approved")}}><DoneIcon size="small"/></div>
                                                <div style={{cursor:"pointer",color:"red",transform:"scale(0.8)"}} onClick={()=>{props.offerApprovalRequest(item.id,"denied")}}><CloseIcon size="small"/></div>
                                            </div>
                        }
                        if(tableHead.link){
                            if(showDataValue.includes("(") && showDataValue.includes(")")){
                                var id = showDataValue.split(")")[0].split("(")[1]
                            }
                            showDataValue = <div 
                                                className='overflowText'
                                                onClick={()=>{window.open(tableHead.link+id,'_blank')}}
                                                style={{textDecoration:'none',color:tableHead.color,cursor:'pointer'}}
                                            >
                                                {showDataValue}
                                            </div>
                        }
                        if(tableHead.customComponent){
                            showDataValue = tableHead.customComponent(showDataValue,tableHeadIndex,item,index,props.childItems)
                        }

                        if(tableHead.threeDotMenu){
                            showDataValue = <div style={{height:'30px',fontSize:'20px',cursor:'pointer',userSelect:'none'}} onClick={(e)=>{e.stopPropagation();if(threeDotMenu.index===index){setThreeDotMenu(false)} else{setThreeDotMenu({index,screenY:e.target.getBoundingClientRect().bottom,screenX:e.target.getBoundingClientRect().right,item})}}}>
                                ...
                                </div>
                        }
                        
                        
                        return <td 
                                    className='tableModalValue overflowText' 
                                    key={"tableHeaderRow"+index+"tableHeaderColumn"+tableHeadIndex}
                                    id={"tableHeaderRow"+index+"tableHeaderColumn"+tableHeadIndex} 
                                    style={{...tableColumnSticky,
                                            textAlign:tableHead.alignValue ? tableHead.alignValue : 'center',
                                            maxWidth:valueWidth,
                                            width:valueWidth,
                                            color:tableHead.color,
                                            minWidth:valueWidth}}
                                    onMouseOver={(e)=>{if(e.target.offsetWidth < e.target.scrollWidth){setMouseOverText({width:valueWidth,row:index,column:tableHeadIndex,value:(tableHead.value === 'created_at' || tableHead.value === 'updated_at' ? showDataValue : item[tableHead.value])});}}}
                                    onMouseOut={(e)=>{setMouseOverText(false);}}
                                    onClick={(e)=>{if(tableHead.onClickEvent){tableHead.onClickEvent(item)}}}
                                    >   
                                    <div className='overflowText' style={{...tableHead.tableValueStyle,paddingLeft:(tableHead.alignHead === 'left' ? '5px' : '0px')}}>
                                        {showDataValue || showDataValue===0 ? showDataValue : "-"} 
                                    </div>
                                </td>
                    }
                    return null;
                })}
            </tr>
        })
        if(props.tableValues && typeof props.tableValues ==='object' && props.tableValues.length===0){
            tableValues = <tr>
                                <td colSpan={tableHeaders.length}>
                                    <div style={{width:'100%',marginLeft:'8%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',marginTop:'50px',paddingBottom:'50px'}}>
                                        <img src={NoResults} width='32%' alt=''/>
                                        <div style={{fontWeight:600,fontSize:'15px'}}>No Results Found.</div>
                                        <div style={{fontSize:'12px',paddingTop:'10px'}}>There are no results.</div>
                                    </div>
                                </td>
                            </tr>
        }
        if(props.tableValues && typeof props.tableValues === 'string'){
            tableValues = <tr>
                                <td colSpan={tableHeaders.length}>
                                    <div style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',marginTop:'50px',paddingBottom:'50px'}}>
                                        <img src={props.tableValues.includes('errorFromAxios') ? SomethingLooksFishy : NoResults} width='28%' alt=''/>
                                        <div style={{fontWeight:600,fontSize:'15px'}}>{props.tableValues.includes('errorFromAxios') ? 'Something looks fishy.' : 'No Results Found.'}</div>
                                        <div style={{fontSize:'12px',paddingTop:'10px'}}>{props.tableValues.includes('errorFromAxios') ? props.tableValues.split('errorFromAxios')[1] : props.tableValues}</div>
                                    </div>
                                </td>
                            </tr>
        }
        return tableValues
    }

    const [gridView, setGridView] = useState(false)

    var showGridView = () => {
        if(props.gridComponent){
            var gridValues = props.tableValues && typeof props.tableValues ==='object' && props.tableValues.length>0 && props.tableValues.map((item,index)=>{
                return props.gridComponent(item,index)
            })

            return <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center'}}>
                {gridValues}
            </div>
        }
        else{
            return <UnderConstruction/> 
        }
                
    }
    const [hideTableSettingsCheck, setHideTableSettingsCheck] = useState(true)
    
    const onDragEnd = (result) => {
        const newItems = Array.from(props.tableHeaders);
        const [removed] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, removed);
        props.setTableHeaders(newItems);
        refreshComponent(123)
    };

    const hideUnhideColumn = (index) => {
        var tempTableHeaders = props.tableHeaders 
        tempTableHeaders[index].hide = !tempTableHeaders[index].hide
        props.setTableHeaders(tempTableHeaders);
        refreshComponent('asdsad');
    }

    useEffect(() => {
        document.addEventListener('keydown',(e)=>{
            if(e.key==='Escape' && !hideTableSettingsCheck){setHideTableSettingsCheck(true);}
        })
        
        return () => {
            document.removeEventListener('keydown',(e)=>{
                if(e.key==='Escape' && !hideTableSettingsCheck){setHideTableSettingsCheck(true);}
            })
        }
    }, [hideTableSettingsCheck])
    
    return (
        <div id='tableContainer' onScroll={()=>{setMouseOverText(false);}}>
            {!props.hideSettingsBar &&
            <div style={{display:'flex',justifyContent:'flex-end',marginRight:'30px',marginBottom:'10px'}}>
                {props.showCustomization && <SettingsIcon sx={{marginRight:'10px',fontSize:'17px'}} onClick={()=>{setHideTableSettingsCheck(!hideTableSettingsCheck)}}/>}
                {!props.showCustomization && <CachedIcon sx={{fontSize:'17px',color:'rgb(50,50,50)',cursor:'pointer',marginRight:'10px'}} onClick={()=>{removeLocalStorageValue(window.location.pathname+'TableWidth');window.location.reload();}}/>}
                
                <TableRowsIcon sx={{marginRight:'10px',fontSize:'17px',color:(gridView ? 'rgb(180,180,180)' : localStorage.getItem('ambientColor'))}} onClick={()=>{setGridView(false);}}/>
                <GridViewIcon sx={{fontSize:'17px',color:(!gridView ? 'rgb(180,180,180)' : localStorage.getItem('ambientColor'))}} onClick={()=>{setGridView(true);}}/>
            </div>}
            {(!tableHeaders || props.showLoader)
                && 
                <div style={{height:'60vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <CircularProgress size={46} sx={{color:localStorage.getItem('ambientColor')}}/>
                </div>
            }
            {Object.keys(threeDotMenu).length>0 &&
                <div className='tableModalThreeDotMenu' style={{position:'absolute',marginTop:getMarginTopThreeDotMenu(tableHeaders[tableHeaders.length-1].height,window.scrollY + threeDotMenu.screenY),top:window.scrollY + threeDotMenu.screenY - 25,left:threeDotMenu.screenX-50,minHeight:tableHeaders[tableHeaders.length-1].height}}>
                    {tableHeaders[tableHeaders.length-1].menuOptions.map((threeDotMenuItem,threeDotMenuIndex)=>{
                        return <div key={threeDotMenuIndex}>{threeDotMenuItem.component(threeDotMenu.item,threeDotMenu.index,props.tableValues,props.setTableValues)}</div>
                    })}
                </div>
                }
            <div className={hideTableSettingsCheck ? 'tableModalSettingsContainer tableModalSettingsContainerHide' : 'tableModalSettingsContainer'} onClick={()=>{setHideTableSettingsCheck(true);}}>
                <div className='tableModalSettingsWrapper'  onClick={(e)=>{e.stopPropagation();}}>
                    <div style={{width:'50%',borderRight:'1px solid rgb(200,200,200)'}}>
                        <img src={CustomizeTablePng} alt='' width='100%'/>
                    </div>
                    <div style={{marginLeft:'40px',width:'50%'}}>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginBottom:'30px'}}>
                            <label style={{fontSize:'22px',fontWeight:'600'}}>Customize Table</label>
                            <div style={{display:'flex',alignItems:'center'}}>
                                <CachedIcon sx={{fontSize:'22px',color:'rgb(50,50,50)',cursor:'pointer',marginRight:'10px'}} onClick={()=>{removeLocalStorageValue(window.location.pathname);removeLocalStorageValue(window.location.pathname+'TableWidth');window.location.reload();setHideTableSettingsCheck(true);}}/>
                                <CloseIcon sx={{fontSize:'22px',color:'rgb(50,50,50)',cursor:'pointer',marginRight:'10px'}} onClick={()=>{setHideTableSettingsCheck(true)}}/>
                            </div>
                        </div>
                        <div style={{maxHeight:'50vh',overflow:"auto"}}>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided) => (
                                    <div key={provided} {...provided.droppableProps} ref={provided.innerRef}>
                                        {props.tableHeaders.map((item, index) => {
                                            if(((props.stickyColumn && !props.stickyColumn.includes(index)) || !props.stickyColumn) && ((props.rightStickyColumnList && !props.rightStickyColumnList.includes(index)) || !props.rightStickyColumnList)){
                                                return <Draggable key={item.value} draggableId={item.value} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div        
                                                                key={item.value}  
                                                                className='tableModalSettingsDragDiv'       
                                                                ref={provided.innerRef}
                                                                snapshot={snapshot}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <div style={{display:'flex',alignItems:'center'}}>
                                                                    <DragIndicatorIcon sx={{marginRight:'10px',fontSize:'12px',color:'rgb(150,150,150)'}}/>
                                                                    {item.label}
                                                                </div>
                                                                <div onClick={()=>{hideUnhideColumn(index)}}>
                                                                    {item.hide ? 
                                                                        <VisibilityOffIcon sx={{fontSize:'18px',cursor:'pointer'}}/>
                                                                    : 
                                                                        <VisibilityIcon  sx={{fontSize:'18px',cursor:'pointer'}}/>
                                                                    }</div>
                                                            </div>
                                                        )}
                                                     </Draggable>
                                            }
                                            /// Locked Columns
                                            // if(item.value!=='threeDotMenu' && ((props.stickyColumn && props.stickyColumn.includes(index)) || (props.rightStickyColumnList && props.rightStickyColumnList.includes(index)))){
                                            //     return <div key={item.value} className='tableModalSettingsDragDiv' style={{cursor:'not-allowed'}}>
                                            //             {item.label}
                                            //         </div>
                                            // }
                                            return null;
                                        }
                                        )}
                                    </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                        <Button sx={{...Constants.CONTAINEDBUTTON,fontSize:'15px',paddingLeft:'15px',paddingRight:'15px',marginTop:'20px',textTransform:'none',fontWeight:500,marginLeft:'15px'}} onClick={()=>{init('refreshTable');setHideTableSettingsCheck(true);setLocalStorageValue(window.location.pathname,props.tableHeaders);}}>Submit</Button>
                    </div>
                </div>
            </div>
            {props.selectedElements && props.selectedElements.length>0 && props.selectedElementsComponent()}
            {
                !props.showLoader && gridView && showGridView()
            }
            {
                !gridView && !props.showLoader &&
                    <>
                        <div style={{width:'100%',position:'sticky',top:((props.selectedElements && props.selectedElements.length>0) ? 50 : -2),zIndex:990}}>
                            <div id={'tableHeaderRow'+(props.id ? props.id : "")} style={{overflowX:'auto',background:'white',transition:'all 0.3s linear'}} onScroll={(e)=>{document.getElementById('tableValueRows'+(props.id ? props.id : "")).scrollTo({left:e.target.scrollLeft})}}>
                                <table>
                                    <tbody>
                                        <tr>
                                            {getTableHeaders()}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='noscrollbar' id={'tableValueRows'+(props.id ? props.id : "")} style={{width:'100%',overflow:'auto',zIndex:0,transition:'all 0.3s linear'}} onScroll={(e)=>{document.getElementById('tableHeaderRow'+(props.id ? props.id : "")).scrollTo({left:e.target.scrollLeft})}}>
                            <table>
                                <tbody>
                                    {getTableValue()}
                                </tbody>
                            </table>
                        </div>
                        {mouseOverText && typeof mouseOverText.value === 'string' && !props.hideTooltip
                            && 
                            <div 
                                className='mouseOverText' 
                                style={{left:getElementCoords(document.getElementById("tableHeaderRow"+mouseOverText.row+"tableHeaderColumn"+mouseOverText.column)).left,top:getElementCoords(document.getElementById("tableHeaderRow"+mouseOverText.row+"tableHeaderColumn"+mouseOverText.column)).top}}>
                                {mouseOverText.value}
                            </div>
                        }
                    </>
            }
        </div>
    )
}

export default TableModal