import React, { useEffect, useState } from 'react';
import './insightAdvertiser.css';
import ReactSelect from 'react-select';
import * as Constants from '../../data/constants';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { getLocalStorageValue, zeroPad, setLocalStorageValue, getRequests } from '../../util/util';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import TableRowsIcon from '@mui/icons-material/TableRows';
import PieChartIcon from '@mui/icons-material/PieChart';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import { getAdvertisers } from '../../apis/dropdown/dropdowns';
import { ScatterBoxLoader } from "react-awesome-loaders";

const InsightAdvertiserPage = () => {
    var todayDate = new Date()
    todayDate.setMonth(todayDate.getMonth() - 1);
    var lastMonthList = todayDate.toISOString().split('T')[0].split('-')
    var lastMonth = lastMonthList[0]+'-'+lastMonthList[1]
    
    const queryParameters = new URLSearchParams(window.location.search)
    lastMonth = [lastMonth,lastMonth]
    var tempMonthSelected = queryParameters.get('monthSelected')
    const [monthSelected, setMonthSelected] = useState(tempMonthSelected ? tempMonthSelected : getLocalStorageValue('billingFilters') && getLocalStorageValue('billingFilters').monthSelected ? getLocalStorageValue('billingFilters').monthSelected : lastMonth)
    var [filterData, setFilterData] = useState({})
    const [manualUseState, setManualUseState] = useState(false)
    const [filterAddClicked, setFilterAddClicked] = useState(false)
    const [dashboardData, setDashboardData] = useState({})
    const [compareWithManual,setCompareWithManual] = useState(getLocalStorageValue('billingFilters') ? getLocalStorageValue('billingFilters').compare_with_manual ? true : true : true)
    const [graphSwitch,setGraphSwitch] = useState({teamAnatomy:true,topAdvertisers:false,topApps:true,redFlagApps:true,redFlagAdvertisers:false})
    const [optionsAdvertiserList,setOptionsAdvertiserList] = useState([])
    const [loader,setLoader] = useState(false)
    const [topPendingType,setTopPendingType] = useState('advertiser_id')

    const setFilterDataMethod = async(e,filterItem) => {
        var tempFilterData = {...filterData,[filterItem.value]:e}
        setFilterData(tempFilterData)
    }
    var date_temp = new Date(monthSelected[0]).toLocaleString('en-US',{year:'numeric',month:'numeric'}).split(',')[0].split('/')
    var fromMonth = date_temp[1]+'-'+zeroPad(date_temp[0],2)
    if(monthSelected[1]){
        date_temp = new Date(monthSelected[1]).toLocaleString('en-US',{year:'numeric',month:'numeric'}).split(',')[0].split('/')
        var toMonth = date_temp[1]+'-'+zeroPad(date_temp[0],2)
    }
    const getRevenueData = async(manual_limit=null,manual_page=null,sortingKeyword=null,sortingOrder=null,group_by='month',year=false,tempFilterData=null) => {
        
        var advertiser_id = tempFilterData.advertiser_id ? tempFilterData.advertiser_id.value : undefined
        if(tempFilterData.team && typeof tempFilterData.team === 'object'){
            var team = tempFilterData.team ? tempFilterData.team.value : undefined
        }
        else if(tempFilterData.team && typeof tempFilterData.team === 'string'){
            team = tempFilterData.team
        }
        if(group_by==='offer_id'){
            var url_suffix = '/api/admin/v1/invoice/adv-data'
        }
        else{
            url_suffix = "/api/admin/v2/invoice/summary"
        }
        return await axios({
            method: 'GET',
            url:"".concat(Constants.PROTOCOL,Constants.HOST,url_suffix),
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params: { 
                advertiser_id,
                team,
                fromMonth,
                toMonth: fromMonth === toMonth ? undefined : toMonth,
                limit: manual_limit ? manual_limit : 10,
                page: manual_page ? manual_page : 1,
                total_row_required:true,
                group_by,
                year: year ? year : undefined,
                sorting_key: sortingKeyword ? sortingKeyword : 'total_revenue',
                sorting_order:sortingOrder ? sortingOrder : false,
            }
        })
        .then(async(resp) => {
            return resp.data
        })
        .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                if(error.response.data){
                }
                console.log(error.response.status);
                console.log(error.response.headers);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
        })
    }
    const requestList = async() => {
        setLoader(true)
        var tempDashboardData = dashboardData
        // tempDashboardData['year_graph'] = await getRevenueData(null,null,null,null,'month',false,filterData)
        tempDashboardData['year_graph'] = await getRevenueData(null,null,'month',true,'month',false,filterData)
        tempDashboardData['team1_graph'] = await getRevenueData(null,null,null,null,'package_name',false,{...filterData,team:'Team1'})
        tempDashboardData['team2_graph'] = await getRevenueData(null,null,null,null,'package_name',false,{...filterData,team:'Team2'})
        tempDashboardData['team3_graph'] = await getRevenueData(null,null,null,null,'package_name',false,{...filterData,team:'Team3'})
        tempDashboardData['top_advertiser_data_graph'] = await getRevenueData(null,null,'manager_approved_revenue',null,'advertiser_id',false,filterData)
        tempDashboardData['top_apps_data_graph'] = await getRevenueData(null,null,'manager_approved_revenue',null,'package_name',false,filterData)
        tempDashboardData['red_flag_advertiser_data_graph'] = await getRevenueData(null,null,compareWithManual ? 'deduction_revenue_manual' : 'deduction_revenue',null,'advertiser_id',false,filterData)
        tempDashboardData['red_flag_apps_data_graph'] = await getRevenueData(null,null,compareWithManual ? 'deduction_revenue_manual' : 'deduction_revenue',null,'package_name',false,filterData)
        tempDashboardData['top_pending_advertiser_id'] = await getRevenueData(null,null,compareWithManual ? 'pending_revenue_manual' : 'pending_revenue',null,'advertiser_id',false,filterData)
        tempDashboardData['top_pending_package_name'] = await getRevenueData(null,null,compareWithManual ? 'pending_revenue_manual' : 'pending_revenue',null,'package_name',false,filterData)
        tempDashboardData['top_pending_offers'] = await getRevenueData(null,null,compareWithManual ? 'pending_revenue_manual' : 'pending_revenue',null,'offer_id',false,filterData)
        setDashboardData(tempDashboardData)
        setLoader(false)
    }
    if(!manualUseState){
        setManualUseState(true)
        requestList()
        getRequests(getAdvertisers(false,'both'),setOptionsAdvertiserList)

    }

    const getSeries = (value,graphType='year_graph') => {
        if(dashboardData[graphType] && dashboardData[graphType]['result']){
            var series = dashboardData[graphType]['result'].map(item=>{
                var returnValue = item[value]
                if(value==='app_name' && !returnValue){
                    returnValue = item['package_name']
                    if(!returnValue){
                        returnValue = 'unknown'
                    }
                }
                return returnValue
            })
        }
        else{
            series = []
        }
        return series
    }
    const state = {
        series: [
            // {
            //     name: "Delivered Quantity",
            //     type:"line",
            //     data: getSeries('conversions')
            //   },
              {
                name: "Validation Percentage",
                type:"line",
                data: compareWithManual ? getSeries('validation_percentage_manual') : getSeries('validation_percentage')
              },
            {
                name: compareWithManual ? 'Manual Revenue' : 'Panel Revenue',
                type:"column",
                data: compareWithManual ? getSeries('conversions_verified') : getSeries('total_revenue')
            },
            {
                name: "Validated Revenue",
                type:"column",
                data: getSeries('manager_approved_revenue')
            },
            {
              name: "Deduction Revenue",
              type:"column",
              data: compareWithManual ? getSeries('deduction_revenue_manual') : getSeries('deduction_revenue')
            },
            {
              name: "Pending Revenue",
              type:"column",
              data: compareWithManual ? getSeries('pending_revenue_manual') : getSeries('pending_revenue')
            },
          ],
        options: {
          plotOptions: {
            bar: {
              columnWidth: "50%"
            }
          },
          tooltip:{
            enabled:true,
            shared:true,
            intersect:false,
          },
          chart: {
            height: 350,
            width: 850,
            type: 'bar',
              dropShadow: {
                enabled: false,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              },
              zoom: {
                enabled: false,
              }
        },
          colors: ['#8a2900','#8B75D7','#50E7A6','#FF6378','#FEBC4A'],
          dataLabels: {
            enabled: false,
          },
          grid:{
            show:false
          },
          markers: {
              size: 4
            },

          stroke:{
            width:2,
            curve: 'smooth',
          },
          xaxis: {
              crosshairs:{
                    show:false,
              },  
              labels: {
                
              },
              // type: ',
              tickAmount: 'dataPoints',
              categories:getSeries('month').map((item)=>{
                return new Date(item).toLocaleDateString('en-US',{month:'long'})
              }),
              forceNiceScale:true,

          },
          yaxis: [
            // { 
            //   min:Math.min(0),
            //   max:Math.max(...getSeries('conversions'))+100,
            //     forceNiceScale:true,
            //     show:false,
            //     opposite: true,
            //     tickAmount: 5,
            //     labels:{
            //         formatter: (value) => { return parseInt(Math.round(value)) },
            //     },
            // },
            { 
                min:Math.min(0),
                max:Math.max(...(compareWithManual ? getSeries('validation_percentage_manual') : getSeries('validation_percentage')),100),
                  forceNiceScale:true,
                  show:false,
                  opposite: true,
                  tickAmount: 5,
                  labels:{
                      formatter: (value) => { return (value ? parseInt(Math.round(value)) : 0 )+'%'},
                  },
              },
              { 
                min:Math.min(0),
                max:Math.max(...getSeries('total_revenue'))+100,
                  forceNiceScale:true,
                  show:false,
                  opposite: true,
                  tickAmount: 5,
                  labels:{
                      formatter: (value) => { return value ? parseInt(Math.round(value)) : 0 },
                  },
              },
              { 
                min:Math.min(0),
                max:Math.max(...getSeries('total_revenue'))+100,
                  forceNiceScale:true,
                  show:false,
                  opposite: true,
                  tickAmount: 5,
                  labels:{
                      formatter: (value) => { return value ? parseInt(Math.round(value)) : 0 },
                  },
              },
              { 
                min:Math.min(0),
                max:Math.max(...getSeries('total_revenue'))+100,
                  forceNiceScale:true,
                  show:false,
                  opposite: true,
                  tickAmount: 5,
                  labels:{
                      formatter: (value) => { return value ? parseInt(Math.round(value)) : 0 },
                  },
              },
              { 
                min:Math.min(0),
                max:Math.max(...getSeries('total_revenue'))+100,
                  forceNiceScale:true,
                  show:false,
                  opposite: true,
                  tickAmount: 5,
                  labels:{
                      formatter: (value) => { return value ? parseInt(Math.round(value)) : 0 },
                  },
              },
        ],
          
          legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: 0,
            offsetX: -5
          }
        },
    };
    var labelsData = [
        {label:'Validated Revenue',value:'manager_approved_revenue'},
        {label:'Deduction Revenue',value:'deduction_revenue'+(compareWithManual ? '_manual' : '')},
        {label:'Pending Revenue',value:'pending_revenue'+(compareWithManual ? '_manual' : '')},
    ]
    const getPieSeries = (graphType) => {
        if(dashboardData[graphType] && dashboardData[graphType]['total_row']){
            var series = labelsData.map(item=>{
                return Math.round(dashboardData[graphType]['total_row'][item.value])
            })
        }
        else{
            series = []
        }
        return series
    }
    const pieState = (graphType='team1_graph',label='Team 1') => {
        return {
            series: getPieSeries(graphType),
            options: {
                labels: labelsData.map(item=>{
                    return item.label
                }),
                chart: {
                    width: 380,
                    type: 'donut',
                },
                plotOptions: {
                    pie: {
                    startAngle: 0,
                    endAngle: 360
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                      return Math.round(val)+'%'
                    },
                    distributed:true,
                    background:{
                        enabled: true,
                        foreColor: '#000',
                        borderColor: '#000',
                        opacity: 0,
                    },
                    dropShadow: {
                        enabled: false,
                        top: 1,
                        left: 1,
                        blur: 1,
                        color: '#000',
                        opacity: 0.45
                    }
                },
                colors: ['#50E7A6','#FF6378','#FEBC4A'],
                fill: {
                    type: 'gradient',
                },
                legend: {
                    show:true,
                    position:'bottom',
                    formatter: function(val, opts) {
                        return val + " - " + opts.w.globals.series[opts.seriesIndex]
                    }
                },
                title: {
                    align:'center',
                    text: label,
                    offsetY: 115,
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                    }
                }]
            },
        }
    };
    const horizontalBarChart  = {
        
        series: [
            {
                name: 'Validated Revenue',
                data: [dashboardData['year_graph'] && dashboardData['year_graph']['total_row'] ? Math.round(dashboardData['year_graph']['total_row'].manager_approved_revenue) : 0]
            }, 
            {
                name: 'Deduction Revenue',
                data: [dashboardData['year_graph'] && dashboardData['year_graph']['total_row'] ? Math.round(dashboardData['year_graph']['total_row'][compareWithManual ? 'deduction_revenue_manual' : 'deduction_revenue']) : 0]
            }, 
            {
                name: 'Pending Revenue',
                data: [dashboardData['year_graph'] && dashboardData['year_graph']['total_row'] ? Math.round(dashboardData['year_graph']['total_row'][compareWithManual ? 'pending_revenue_manual' : 'pending_revenue']) : 0]
            },
        ],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                stackType: '100%',
                toolbar:{
                    show:false
                }
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            colors: ['#50E7A6','#FF6378','#FEBC4A'],
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            xaxis: {
                categories: ['Total Range Data'],
                crosshairs:{
                    show:false,
                }, 
                axisTicks:{
                    show:false
                },
                labels: {
                    show: false,
                }
            },
            yaxis:{
                labels: {
                    show: false,
                }
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            },
            fill: {
                opacity: 1
            },
            grid:{
                show:false
            },
            dataLabels:{
                enabled: true,
                formatter: function (val) {
                    return Math.round(val)+'%'
                },
                distributed:true,
                background:{
                    enabled: true,
                    foreColor: '#000',
                    borderColor: '#000',
                    opacity: 0,
                },
                dropShadow: {
                    enabled: false,
                    top: 1,
                    left: 1,
                    blur: 1,
                    color: '#000',
                    opacity: 0.45
                }
            },
            legend: {
                position: 'top',
                horizontalAlign: 'center',
                offsetX: 40,
                formatter: function(val, opts) {
                    return val + " - " + opts.w.globals.series[opts.seriesIndex]
                }
            }
        },
      
      
      };
    

    const verticalBarChart = (graphType='top_advertiser_data_graph',xaxis='advertiser_id') => {
        if(!dashboardData[graphType]){
            return ''
        }


        var state = {
            series: [{
                    name: 'Validated Revenue',
                    data: getSeries('manager_approved_revenue',graphType)
                }, 
                {
                    name: 'Deduction Revenue',
                    data: compareWithManual ? getSeries('deduction_revenue_manual',graphType) : getSeries('deduction_revenue',graphType)
                }, 
                {
                    name: 'Pending Revenue',
                    data: compareWithManual ? getSeries('pending_revenue_manual',graphType) : getSeries('pending_revenue',graphType)
                },
            ],
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar:{
                        show:false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                    },
                },
                colors: ['#50E7A6','#FF6378','#FEBC4A'],
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                xaxis: {
                    categories: getSeries(xaxis,graphType),
                    crosshairs:{
                        show:false,
                    }, 
                    labels:{
                        rotate: -30,
                        rotateAlways:true,
                        hideOverlappingLabels:false,
                        formatter:(val=>{
                            if(xaxis==='advertiser_id'){
                                return optionsAdvertiserList.advertiserDict[val].split(' - ')[0]
                            }
                            return val
                        })
                    }
                },
                yaxis:{
                    labels: {
                        show: false,
                    }
                },
                tooltip:{
                    enabled:true,
                    shared:true,
                    intersect:false,
                    x: {
                        formatter: (val=>{
                            if(xaxis==='advertiser_id'){
                                return optionsAdvertiserList.advertiserDict[val].split(' - ')[0]
                            }
                            return val
                        }),
                    },
                    y: {
                        formatter: (val=>{
                            return Math.round(val)
                        }),
                    },
                },
                fill: {
                    opacity: 1
                },
                grid:{
                    show:false
                },
                dataLabels:{
                    enabled: false,
                },
                legend: {
                    show:true,
                    position: 'bottom',
                    horizontalAlign: 'center',
                    offsetX: 40,
                }
            },
        }
        
        return <ReactApexChart className="graphs" options={state.options} series={state.series} type="bar" height={"300px"} width={"100%"}/>
      
    };
    useEffect(() => {
        document.addEventListener('click',e=>{
            setFilterAddClicked(false);
        })
        
        return () => {
            document.removeEventListener('click',e=>{
                setFilterAddClicked(false);
            })
        }
    }, [])
    
    const filters = [
        {label:'Team',width:'140px',value:'team',type:'select',options:[{label:'Team 1',value:'Team1'},{label:'Team 2',value:'Team2'},{label:'Team 3',value:'Team3'}]},
        {label:'Advertiser',width:'200px',marginLeft:'40px',value:'advertiser_id',type:'select',options:optionsAdvertiserList.advertiserlist}
    ]

    const teamData = [
        {label:'Team 1',value:'Team1',graphType:'team1_graph'},
        {label:'Team 2',value:'Team2',graphType:'team2_graph'},
        {label:'Team 3',value:'Team3',graphType:'team3_graph'}
    ]

    const teamTableData = [
        {label:'Team Name',value:'team'},
        {type:'integer',label:(compareWithManual ? 'Manual' : 'Total')+' Revenue',value:compareWithManual ? 'conversions_verified' : 'total_revenue'},
        {type:'integer',label:'Validated Revenue',value:'manager_approved_revenue'},
        {type:'integer',label:'Validation Percentage',suffix:'%',value:'validation_percentage'+(compareWithManual ? '_manual' : '')},
        {type:'integer',label:'Deduction Revenue',value:'deduction_revenue'+(compareWithManual ? '_manual' : '')},
        {type:'integer',label:'Pending Revenue',value:'pending_revenue'+(compareWithManual ? '_manual' : '')},
    ]
    
    const advertiserTableData = [
        {label:'Advertiser',value:'advertiser_id',width:'',onClickRedirectTo:'/billing/summary/advertiser?showYTD=True&monthSelected='+fromMonth+'_'+toMonth+'&'},
        {label:(compareWithManual ? 'Manual' : 'Total')+' Revenue',value:compareWithManual ? 'conversions_verified' : 'total_revenue',type:'integer',width:'12%'},
        {label:'Validated Revenue',value:'manager_approved_revenue',type:'integer',width:'15%'},
        {label:'Validation Percentage',suffix:'%',value:'validation_percentage'+(compareWithManual ? '_manual' : ''),type:'integer',width:'15%'},
        {label:'Deduction Revenue',value:'deduction_revenue'+(compareWithManual ? '_manual' : ''),type:'integer',width:'15%'},
        {label:'Pending Revenue',value:'pending_revenue'+(compareWithManual ? '_manual' : ''),type:'integer',width:'15%'},
    ]

    var topPendingTypeDict = {
        'advertiser_id':{
            label:'Advertiser',
            onClickRedirectTo:'/billing/advertiser?showYTD=True&monthSelected='+fromMonth+'_'+toMonth+'&'
        },
        'package_name':{
            label:'App Name',
            onClickRedirectTo:'/billing/package?showYTD=True&monthSelected='+fromMonth+'_'+toMonth+'&'
        },
        'offers':{
            label:'App Name',
            onClickRedirectTo:'/billing/summary/advertiser?monthSelected='+fromMonth+'_'+toMonth+'&'
        },
    }

    var gameChangerTableData = [
        {label:topPendingTypeDict[topPendingType].label,value:topPendingType==='package_name' ? 'app_name' : topPendingType,width:'',onClickRedirectTo:topPendingTypeDict[topPendingType].onClickRedirectTo},
        {label:(compareWithManual ? 'Manual' : 'Total')+' Revenue',value:compareWithManual ? 'conversions_verified' : 'total_revenue',type:'integer',width:'12%'},
        {label:'Validated Revenue',value:'manager_approved_revenue',type:'integer',width:'15%'},
        {label:'Validation Percentage',suffix:'%',value:'validation_percentage'+(compareWithManual ? '_manual' : ''),type:'integer',width:'15%'},
        {label:'Deduction Revenue',value:'deduction_revenue'+(compareWithManual ? '_manual' : ''),type:'integer',width:'15%'},
        {label:'Pending Revenue',value:'pending_revenue'+(compareWithManual ? '_manual' : ''),type:'integer',width:'15%'},
    ]
    var appTableData = [
        {label:'App Name',value:'app_name',onClickRedirectTo:'/billing/package?showYTD=True&monthSelected='+fromMonth+'_'+toMonth+'&'},
        {label:(compareWithManual ? 'Manual' : 'Total')+' Revenue',value:compareWithManual ? 'conversions_verified' : 'total_revenue',type:'integer',width:'12%'},
        {label:'Validated Revenue',value:'manager_approved_revenue',type:'integer',width:'15%'},
        {label:'Validation Percentage',suffix:'%',value:'validation_percentage'+(compareWithManual ? '_manual' : ''),type:'integer',width:'15%'},
        {label:'Deduction Revenue',value:'deduction_revenue'+(compareWithManual ? '_manual' : ''),type:'integer',width:'15%'},
        {label:'Pending Revenue',value:'pending_revenue'+(compareWithManual ? '_manual' : ''),type:'integer',width:'15%'},
    ]
    if(topPendingType==='offers'){
        gameChangerTableData = [
            {label:'Offer Name',value:'offer_id',width:'15%'},
            {label:'Offer Title',value:'offer_name'},
            {label:'Advertiser',value:'advertiser',width:'15%'},
            {label:'App Name',value:'app_name',width:'15%'},
            {label:(compareWithManual ? 'Manual' : 'Total')+' Revenue',value:compareWithManual ? 'conversions_verified' : 'total_revenue',type:'integer',width:'15%'},
            {label:'Pending Revenue',value:'pending_revenue'+(compareWithManual ? '_manual' : ''),type:'integer',width:'15%'},
        ]
    }
    return (
        <div className='insightAdvertiserContainer'>
            <div className='insightAdvertiserWrapper'>
                <div style={{fontSize:'33px'}}>
                    Insights
                </div>
                <div className='insightAdvertiserFilterRow'>
                    {/* <div>Filter By:</div> */}
                    <div style={{display:'flex',alignItems:'center'}}>
                        <div style={{display:'flex',alignItems:'center',marginLeft:'10px'}}>
                            <div>Month:</div>
                            <DatePicker
                                // selected={new Date(monthSelected)}
                                customInput={<input className='monthPicker'/>}
                                selectsRange
                                startDate={new Date(monthSelected[0])}
                                endDate={monthSelected[1] ? new Date(monthSelected[1]) : null}
                                onCalendarClose={()=>{requestList()}}
                                onChange={(date)=>{
                                    setMonthSelected(date);
                                    var oldSessionFilterStorage = getLocalStorageValue('billingFilters') ? getLocalStorageValue('billingFilters') : {}
                                    setLocalStorageValue('billingFilters',{...oldSessionFilterStorage,monthSelected:date});}}
                                showMonthYearPicker={true}
                                dateFormat={'MMMM, yyyy'}
                            />
                        </div>
                        {
                            filters.map((filterItem,filterIndex)=>{
                                if(filterItem.type === 'select'){
                                    return <div key={filterIndex} style={{display:'flex',alignItems:'center',marginLeft:filterItem.marginLeft ? filterItem.marginLeft : 0}}>
                                                <div>{filterItem.label}:</div>
                                                <div style={{width:filterItem.width ? filterItem.width : 100}}>
                                                <ReactSelect 
                                                    isClearable
                                                    options={filterItem.options}
                                                    value={filterData[filterItem.value] ? filterData[filterItem.value] : ''}
                                                    isMulti={filterItem.multiple ? true : false}
                                                    onChange={(e)=>{setFilterDataMethod(e,filterItem)}}
                                                    menuPortalTarget={document.body} 
                                                    styles={{...Constants.BorderLessReactSelectStyles}}/>
                                                </div>
                                            </div>
                                }
                            })
                        }
                    </div>
                    <div>
                        <div style={{color:getLocalStorageValue('ambientColor'),cursor:'pointer'}} onClick={()=>{requestList()}}>
                            {loader &&
                            <CircularProgress size={18} sx={{color:getLocalStorageValue('ambientColor')}}/>}
                            {!loader &&
                            'Apply'}
                        </div>
                    </div>
                    
                </div>
            </div>

            <div style={{paddingRight:'10px',marginTop:'10px'}}>
                <input checked={compareWithManual} id='compareWithManual' type='checkbox' onChange={(e)=>{setCompareWithManual(e.target.checked);}}/>
                <label style={{fontSize:'14px'}} htmlFor='compareWithManual' onChange={(e)=>{setCompareWithManual(e.target.checked);}}>Calculation with Manual Revenue</label>
            </div>
           {
            Object.keys(dashboardData).length===0
            &&
            <div style={{display:'flex',alignItems:'center',justifyContent:'center',height:'600px'}}>
                <ScatterBoxLoader
                    primaryColor={getLocalStorageValue('ambientColor')}
                    // background={theme.colors["background"]}
                />
            </div>
           }
           {dashboardData['year_graph'] && dashboardData['year_graph'].result
                &&
            <div style={{marginTop:'20px'}}>
                <div style={{fontSize:'20px'}}>Yearly Synopsis</div>
                <div style={{display:'flex',justifyContent:'center',marginBottom:'5px',fontSize:'14px'}}>
                    {compareWithManual ? 'Manual Revenue' : 'Panel Revenue'} : {parseInt(dashboardData['year_graph']['total_row'][compareWithManual ? 'conversions_verified' : 'total_revenue'])}
                </div>
                <ReactApexChart options={horizontalBarChart.options} series={horizontalBarChart.series} type="bar" height={100} />    
            </div>
            }
            <div style={{width:'calc(100% - 40px)',padding:'20px'}}>
                {
                    dashboardData['year_graph'] && dashboardData['year_graph'].result
                    &&
                    <ReactApexChart className="graphs" options={state.options} series={state.series} type="bar" height={"300px"} width={"100%"}/>
                }
            </div>
            {
                dashboardData['team1_graph'] && dashboardData['team2_graph'] && dashboardData['team3_graph'] && 
                <div style={{background:'rgb(230,230,230)',border:'1px solid rgb(200,200,200)',borderRadius:'5px',padding:'20px',transition:'all 0.2s linear'}}>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div style={{fontSize:'20px'}}>Team Wise Anatomy</div>
                        <div className='slider'>
                            <Tooltip title={'Switch to Table View'}>
                                <div style={{height:'30px',width:'30px',display:'flex',alignItems:'center',justifyContent:'center'}} onClick={()=>{setGraphSwitch({...graphSwitch,teamAnatomy:false})}}>
                                    <TableRowsIcon sx={{fontSize:'16px',color:'rgb(100,100,100)'}}/>
                                </div>
                            </Tooltip>
                            <div></div>
                            <Tooltip title={'Switch to Graph View'}>
                                <div style={{height:'30px',width:'30px',display:'flex',alignItems:'center',justifyContent:'center'}} onClick={()=>{setGraphSwitch({...graphSwitch,teamAnatomy:true})}}>
                                    <PieChartIcon sx={{fontSize:'16px',color:'rgb(100,100,100)'}}/>
                                </div>
                            </Tooltip>
                            <div className='sliderHover' style={{boxShadow: 'inset 0 0 5px 1px '+getLocalStorageValue('ambientColor'),left:(graphSwitch.teamAnatomy ? 30 : 0)}}></div>
                        </div>
                    </div>
                    {graphSwitch.teamAnatomy
                        &&
                        <div>
                            <div style={{display:'flex',justifyContent:'space-around',flexWrap:'wrap'}}>
                                {teamData.map((teamItem,teamIndex)=>{
                                    return <div style={{display:'flex',alignItems:'center',justifyContent:'center'}} key={teamIndex} onClick={()=>{window.open('/billing/package?team='+teamItem.value,'_blank')}}>
                                        <ReactApexChart options={pieState(teamItem.graphType,teamItem.label).options} series={pieState(teamItem.graphType,teamItem.label).series} type="donut" width={380} />
                                        <div style={{position:'absolute',marginTop:'10px'}}>TR : {dashboardData[teamItem.graphType] && dashboardData[teamItem.graphType]['total_row'] && Math.round(dashboardData[teamItem.graphType]['total_row'][compareWithManual ? 'conversions_verified' : 'total_revenue'])}</div>
                                    </div>
                                })}
                            </div>
                        </div>
                    }
                    {!graphSwitch.teamAnatomy
                        &&
                        <div>
                            <table style={{width:'100%',tableLayout:'fixed'}}>
                                <thead>
                                    <tr>
                                        {teamTableData.map((tableHeaderItem,tableHeaderIndex)=>{
                                            return <td style={{padding:'10px',fontWeight:'600',fontSize:'17px'}} key={tableHeaderIndex}>{tableHeaderItem.label}</td>
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {teamTableData.map((tableHeaderItem,tableHeaderIndex)=>{
                                            var value = dashboardData['year_graph'] && dashboardData['year_graph']['total_row'] ? dashboardData['year_graph']['total_row'][tableHeaderItem.value] : '...'
                                            if(tableHeaderItem.value==='team'){
                                                value = 'Total'
                                            }
                                            if(tableHeaderItem.type==='integer'){
                                                value = Math.round(value)
                                            }
                                            if(tableHeaderItem.suffix){
                                                value+=tableHeaderItem.suffix
                                            }
                                            return <td style={{paddingLeft:'10px',paddingRight:'10px',paddingBottom:'10px',fontWeight:'400'}} key={tableHeaderIndex}>
                                                {value}
                                            </td>
                                        })}
                                    </tr>
                                    {teamData.map((teamItem,teamIndex)=>{
                                        return <tr key={teamIndex}>
                                            {teamTableData.map((tableHeaderItem,tableHeaderIndex)=>{
                                                var value = dashboardData[teamItem.graphType] && dashboardData[teamItem.graphType]['total_row'] ? dashboardData[teamItem.graphType]['total_row'][tableHeaderItem.value] : '...'
                                                if(tableHeaderItem.value==='team'){
                                                    value = teamItem.label
                                                }
                                                if(tableHeaderItem.type==='integer'){
                                                    value = Math.round(value)
                                                }

                                                if(tableHeaderItem.suffix){
                                                    value+=tableHeaderItem.suffix
                                                }
                                                return <td style={{paddingLeft:'10px',paddingRight:'10px',paddingBottom:'5px',fontWeight:'400'}} key={tableHeaderIndex}>
                                                    {value}
                                                </td>
                                            })}
                                        </tr>
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            }
            {
                dashboardData['top_advertiser_data_graph'] && dashboardData['top_apps_data_graph'] && 
                <div className='topAnatomy'>
                    <div className='topItemWrapper'>
                        <div className='topItemHeading'>
                            Top Validated Advertisers
                            <div className='slider'>
                                <Tooltip title={'Switch to Table View'}>
                                    <div style={{height:'30px',width:'30px',display:'flex',alignItems:'center',justifyContent:'center'}} onClick={()=>{setGraphSwitch({...graphSwitch,topAdvertisers:false})}}>
                                        <TableRowsIcon sx={{fontSize:'16px',color:'rgb(100,100,100)'}}/>
                                    </div>
                                </Tooltip>
                                <div></div>
                                <Tooltip title={'Switch to Graph View'}>
                                    <div style={{height:'30px',width:'30px',display:'flex',alignItems:'center',justifyContent:'center'}} onClick={()=>{setGraphSwitch({...graphSwitch,topAdvertisers:true})}}>
                                        <PieChartIcon sx={{fontSize:'16px',color:'rgb(100,100,100)'}}/>
                                    </div>
                                </Tooltip>
                                <div className='sliderHover' style={{boxShadow: 'inset 0 0 5px 1px '+getLocalStorageValue('ambientColor'),left:(graphSwitch.topAdvertisers ? 30 : 0)}}></div>
                            </div>
                        </div>
                        {graphSwitch.topAdvertisers
                        &&
                        verticalBarChart('top_advertiser_data_graph')
                        }
                        {!graphSwitch.topAdvertisers
                        &&
                        <div style={{marginTop:'20px'}}>
                            <table style={{tableLayout:'fixed',width:'100%'}}>
                                <thead>
                                    <tr>
                                        {advertiserTableData.map((item,index)=>{
                                            return <td style={{overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis',fontSize:'17px',fontWeight:600,borderBottom:'1px solid rgb(200,200,200)'}} width={item.width} key={index}>
                                                {item.label}
                                            </td>
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {dashboardData['top_advertiser_data_graph'] && dashboardData['top_advertiser_data_graph']['result'] && dashboardData['top_advertiser_data_graph']['result'].map((valueItem,valueIndex)=>{
                                        return <tr key={valueIndex}>
                                            {advertiserTableData.map((tableHeaderItem,tableHeaderIndex)=>{
                                                var value = valueItem[tableHeaderItem.value]
                                                if(tableHeaderItem.value==='advertiser_id'){
                                                    value = optionsAdvertiserList.advertiserDict[value]
                                                }
                                                if(tableHeaderItem.type==='integer'){
                                                    value = Math.round(value)
                                                }
                                                if(tableHeaderItem.suffix){
                                                    value+=tableHeaderItem.suffix
                                                }
                                                return <td style={{overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}} key={tableHeaderIndex} onClick={()=>{if(tableHeaderItem.onClickRedirectTo){window.open(tableHeaderItem.onClickRedirectTo+'advertiser_id='+valueItem.advertiser_id+'&advertiser_name='+optionsAdvertiserList.advertiserDict[valueItem.advertiser_id])}}}>
                                                    {value}
                                                </td>
                                            })}
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        }
                    </div>

                    <div className='topItemWrapper'>
                        <div className='topItemHeading'>
                            Top Validated Apps
                            <div className='slider'>
                                <Tooltip title={'Switch to Table View'}>
                                    <div style={{height:'30px',width:'30px',display:'flex',alignItems:'center',justifyContent:'center'}} onClick={()=>{setGraphSwitch({...graphSwitch,topApps:false})}}>
                                        <TableRowsIcon sx={{fontSize:'16px',color:'rgb(100,100,100)'}}/>
                                    </div>
                                </Tooltip>
                                <div></div>
                                <Tooltip title={'Switch to Graph View'}>
                                    <div style={{height:'30px',width:'30px',display:'flex',alignItems:'center',justifyContent:'center'}} onClick={()=>{setGraphSwitch({...graphSwitch,topApps:true})}}>
                                        <PieChartIcon sx={{fontSize:'16px',color:'rgb(100,100,100)'}}/>
                                    </div>
                                </Tooltip>
                                <div className='sliderHover' style={{boxShadow: 'inset 0 0 5px 1px '+getLocalStorageValue('ambientColor'),left:(graphSwitch.topApps ? 30 : 0)}}></div>
                            </div>
                        </div>
                        {graphSwitch.topApps
                            &&
                            verticalBarChart('top_apps_data_graph','app_name')
                        }
                        {!graphSwitch.topApps
                            &&
                            <div style={{marginTop:'20px'}}>
                                <table style={{tableLayout:'fixed',width:'100%'}}>
                                    <thead>
                                        <tr>
                                            {appTableData.map((item,index)=>{
                                                return <td style={{overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis',fontSize:'17px',fontWeight:600,borderBottom:'1px solid rgb(200,200,200)'}} width={item.width} key={index}>
                                                    {item.label}
                                                </td>
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dashboardData['top_apps_data_graph'] && dashboardData['top_apps_data_graph']['result'] && dashboardData['top_apps_data_graph']['result'].map((valueItem,valueIndex)=>{
                                            return <tr key={valueIndex}>
                                                {appTableData.map((tableHeaderItem,tableHeaderIndex)=>{
                                                    var value = valueItem[tableHeaderItem.value]
                                                    if(tableHeaderItem.value==='app_name'){
                                                        if(!value){
                                                            value = valueItem['package_name']
                                                        }
                                                    }
                                                    if(tableHeaderItem.type==='integer'){
                                                        value = Math.round(value)
                                                    }

                                                    if(tableHeaderItem.suffix){
                                                        value+=tableHeaderItem.suffix
                                                    }
                                                    return <td style={{overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}} key={tableHeaderIndex} onClick={()=>{if(tableHeaderItem.onClickRedirectTo){window.open(tableHeaderItem.onClickRedirectTo+'package_name='+valueItem.package_name)}}}>
                                                        {value}
                                                    </td>
                                                })}
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </div>
            }
            {
                dashboardData['red_flag_advertiser_data_graph'] && dashboardData['red_flag_apps_data_graph'] && 
                <div className='topAnatomy'>
                    <div className='topItemWrapper'>
                        <div className='topItemHeading'>
                            Red Flag Advertisers
                            <div className='slider'>
                                <Tooltip title={'Switch to Table View'}>
                                    <div style={{height:'30px',width:'30px',display:'flex',alignItems:'center',justifyContent:'center'}} onClick={()=>{setGraphSwitch({...graphSwitch,redFlagAdvertisers:false})}}>
                                        <TableRowsIcon sx={{fontSize:'16px',color:'rgb(100,100,100)'}}/>
                                    </div>
                                </Tooltip>
                                <div></div>
                                <Tooltip title={'Switch to Graph View'}>
                                    <div style={{height:'30px',width:'30px',display:'flex',alignItems:'center',justifyContent:'center'}} onClick={()=>{setGraphSwitch({...graphSwitch,redFlagAdvertisers:true})}}>
                                        <PieChartIcon sx={{fontSize:'16px',color:'rgb(100,100,100)'}}/>
                                    </div>
                                </Tooltip>
                                <div className='sliderHover' style={{boxShadow: 'inset 0 0 5px 1px '+getLocalStorageValue('ambientColor'),left:(graphSwitch.redFlagAdvertisers ? 30 : 0)}}></div>
                            </div>
                        </div>
                        {graphSwitch.redFlagAdvertisers
                        &&
                        verticalBarChart('red_flag_advertiser_data_graph')
                        }
                        {!graphSwitch.redFlagAdvertisers
                        &&
                        <div style={{marginTop:'20px'}}>
                            <table style={{tableLayout:'fixed',width:'100%'}}>
                                <thead>
                                    <tr>
                                        {advertiserTableData.map((item,index)=>{
                                            return <td style={{overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis',fontSize:'17px',fontWeight:600,borderBottom:'1px solid rgb(200,200,200)'}} width={item.width} key={index}>
                                                {item.label}
                                            </td>
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {dashboardData['red_flag_advertiser_data_graph'] && dashboardData['red_flag_advertiser_data_graph']['result'] && dashboardData['red_flag_advertiser_data_graph']['result'].map((valueItem,valueIndex)=>{
                                        return <tr key={valueIndex}>
                                            {advertiserTableData.map((tableHeaderItem,tableHeaderIndex)=>{
                                                var value = valueItem[tableHeaderItem.value]
                                                if(tableHeaderItem.value==='advertiser_id'){
                                                    value = optionsAdvertiserList.advertiserDict[value]
                                                }
                                                if(tableHeaderItem.type==='integer'){
                                                    value = Math.round(value)
                                                }

                                                if(tableHeaderItem.suffix){
                                                    value+=tableHeaderItem.suffix
                                                }
                                                return <td style={{overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}} key={tableHeaderIndex} onClick={()=>{if(tableHeaderItem.onClickRedirectTo){window.open(tableHeaderItem.onClickRedirectTo+'advertiser_id='+valueItem.advertiser_id+'&advertiser_name='+optionsAdvertiserList.advertiserDict[valueItem.advertiser_id])}}}>
                                                    {value}
                                                </td>
                                            })}
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        }
                    </div>

                    <div className='topItemWrapper'>
                        <div className='topItemHeading'>
                            Red Flag Apps
                            <div className='slider'>
                                <Tooltip title={'Switch to Table View'}>
                                    <div style={{height:'30px',width:'30px',display:'flex',alignItems:'center',justifyContent:'center'}} onClick={()=>{setGraphSwitch({...graphSwitch,redFlagApps:false})}}>
                                        <TableRowsIcon sx={{fontSize:'16px',color:'rgb(100,100,100)'}}/>
                                    </div>
                                </Tooltip>
                                <div></div>
                                <Tooltip title={'Switch to Graph View'}>
                                    <div style={{height:'30px',width:'30px',display:'flex',alignItems:'center',justifyContent:'center'}} onClick={()=>{setGraphSwitch({...graphSwitch,redFlagApps:true})}}>
                                        <PieChartIcon sx={{fontSize:'16px',color:'rgb(100,100,100)'}}/>
                                    </div>
                                </Tooltip>
                                <div className='sliderHover' style={{boxShadow: 'inset 0 0 5px 1px '+getLocalStorageValue('ambientColor'),left:(graphSwitch.redFlagApps ? 30 : 0)}}></div>
                            </div>
                        </div>
                        {graphSwitch.redFlagApps
                            &&
                            verticalBarChart('red_flag_apps_data_graph','app_name')
                        }
                        {!graphSwitch.redFlagApps
                            &&
                        <div style={{marginTop:'20px'}}>
                            <table style={{tableLayout:'fixed',width:'100%'}}>
                                <thead>
                                    <tr>
                                        {appTableData.map((item,index)=>{
                                            return <td style={{overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis',fontSize:'17px',fontWeight:600,borderBottom:'1px solid rgb(200,200,200)'}} width={item.width} key={index}>
                                                {item.label}
                                            </td>
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {dashboardData['red_flag_apps_data_graph'] && dashboardData['red_flag_apps_data_graph']['result'] && dashboardData['red_flag_apps_data_graph']['result'].map((valueItem,valueIndex)=>{
                                        return <tr key={valueIndex}>
                                            {appTableData.map((tableHeaderItem,tableHeaderIndex)=>{
                                                var value = valueItem[tableHeaderItem.value]
                                                if(tableHeaderItem.value==='app_name'){
                                                    if(!value){
                                                        value = valueItem['package_name']
                                                    }
                                                }
                                                if(tableHeaderItem.type==='integer'){
                                                    value = Math.round(value)
                                                }

                                                if(tableHeaderItem.suffix){
                                                    value+=tableHeaderItem.suffix
                                                }
                                                return <td style={{overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}} key={tableHeaderIndex} onClick={()=>{if(tableHeaderItem.onClickRedirectTo){window.open(tableHeaderItem.onClickRedirectTo+'package_name='+valueItem.package_name)}}}>
                                                {value}
                                                </td>
                                            })}
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        }
                    </div>
                </div>
            }
            {
                dashboardData['red_flag_advertiser_data_graph'] && dashboardData['red_flag_apps_data_graph'] && 
                <div className='topAnatomy'>
                    <div className='topItemWrapper' style={{width:'100%'}}>
                        <div className='topItemHeading'>
                            <div style={{display:'flex',alignItems:'center'}}>
                                Top Validation Pending 
                                <select onChange={(e)=>{setTopPendingType(e.target.value)}} className='selectPending' style={{color:getLocalStorageValue('ambientColor')}}>
                                    <option value={'advertiser_id'}>Advertiser</option>
                                    <option value={'offers'}>Offers</option>
                                    <option value={'package_name'}>Apps</option>
                                </select>
                            </div>
                        </div>
                        <div style={{marginTop:'20px'}}>
                            <table style={{tableLayout:'fixed',width:'100%'}}>
                                <thead>
                                    <tr>
                                        {gameChangerTableData.map((item,index)=>{
                                            return <td style={{overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis',fontSize:'17px',fontWeight:600,borderBottom:'1px solid rgb(200,200,200)'}} width={item.width} key={index}>
                                                {item.label}
                                            </td>
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {dashboardData['top_pending_'+topPendingType] && dashboardData['top_pending_'+topPendingType]['result'] && dashboardData['top_pending_'+topPendingType]['result'].map((valueItem,valueIndex)=>{
                                        return <tr key={valueIndex}>
                                            {gameChangerTableData.map((tableHeaderItem,tableHeaderIndex)=>{
                                                var value = valueItem[tableHeaderItem.value]
                                                if(tableHeaderItem.value==='advertiser_id' || tableHeaderItem.value==='advertiser'){
                                                    value = optionsAdvertiserList.advertiserDict[value]
                                                }
                                                if(tableHeaderItem.value==='app_name'){
                                                    if(!value){
                                                        value = valueItem['package_name']
                                                    }
                                                    if(!value){
                                                        value = 'Unknown Apps'
                                                    }
                                                }
                                                if(tableHeaderItem.type==='integer'){
                                                    value = Math.round(value)
                                                }

                                                if(tableHeaderItem.suffix){
                                                    value+=tableHeaderItem.suffix
                                                }
                                                return <td style={{overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}} key={tableHeaderIndex}>
                                                    {value}
                                                </td>
                                            })}
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }

            <br/>
        </div>
    )
}

export default InsightAdvertiserPage