import * as React from 'react';
import '../../css/pages/statistics.css';
import {NavLink} from 'react-router-dom';
import {sidebarTabs} from '../../data/sidebarTabs';
import { getLocalStorageValue } from '../../util/util';

export default function StatisticsRouter() {
    const ambientColor = localStorage.getItem('ambientColor') ? localStorage.getItem('ambientColor') : 'rgb(0, 166, 178)'

    const statSubnav = sidebarTabs[1].subNav
    React.useEffect(() => {
        if(document.getElementById('statsRouter')){
            var urlEnd = window.location.pathname
            var childNodes = document.getElementById('statsRouter').childNodes
            
            for(var index in childNodes){
                if(childNodes[index].href){
                    if(childNodes[index].href.includes(urlEnd)){
                        document.getElementById('statsRouter').scrollLeft = childNodes[index].offsetLeft
                    }
                }
            }
        }
        
        return () => {
            
        }
    }, [])
    
    return (
        <div id='statsRouter' className="tabsContainer" style={{overflowX:"auto",position:'relative'}} onScroll={(e)=>{console.log(e.target.scrollLeft)}}> 
            {statSubnav.map((item,index)=>{
                if(
                    !item.requiredPermission
                    || 
                    getLocalStorageValue('adminPanelLoginData').superuser
                    ||
                    (   
                        getLocalStorageValue('adminPanelLoginData').userdata
                        &&
                        getLocalStorageValue('adminPanelLoginData').userdata.permissions 
                        && 
                        Object.keys(getLocalStorageValue('adminPanelLoginData').userdata.permissions).includes(item.requiredPermission)
                    )
                )
                {    
                    return (
                        <NavLink 
                            id={item.link}
                            style={{minWidth:'max-content',padding:'0px'}}
                            activeStyle={{color:ambientColor,borderBottom:"3px solid "+ambientColor}}
                            className="tabPanes"
                            key = {index}
                            to={(window.location.href.includes('?') && window.location.href.includes('statistics') ? item.link+"?"+window.location.href.split("?")[1] : item.link)}                        
                            isActive={(match, location) => {
                                if (match && location){
                                        if(match.url === location.pathname){
                                        return true;
                                    }}

                                return false
                            }}
                        >   
                            <div style={{padding:'12px',}}>
                                {item.title}
                            </div>
                        </NavLink>
                    )
                }
                return null;
                })
            }
        </div>
    );
}
