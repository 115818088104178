import React from 'react'
import { useState } from 'react';
import axios from 'axios';
import * as Constants from '../../data/constants';
import TableModal from '../tableModal/tableModal';
import Pagination from '@mui/material/Pagination';
import {capitalize, getLocalStorageValue} from '../../util/util';
import '../../css/components/v2/zoomedAffiliateInfo.css';

export const AffiliateTickets = (props) => {
    var zoomed = props.zoomed
    const [data,setData] = useState(props.data)
    const [manualUseState,setManualUseState] = useState(false)
    const [query,setQuery] = useState(props.state)
    const [showLoader, setShowLoader] = React.useState(false);
    const [limit, setLimit] = React.useState(100);
    const [page, setPage] = React.useState(1);
    const [totalResults, setTotalResults] = React.useState(1);
    
    const statusCustomComponent = (status) => {
        return <div style={{color:(status==='approved' ? 'green' : status==='denied' ? 'red' : 'black')}}>
            {capitalize(status)}
        </div>
    }

    var tempTableHeaders = [
        {label:'ID',value:'id',show:true,width:'5%',minWidth:50},
        {label:'Affiliate',value:'affiliate',show:true,width:'10%',minWidth:70,alignValue:'left',link:'/affiliates/management/view/',color:localStorage.getItem('ambientColor')},
        {label:'Offer',value:'offer',show:true,width:'20%',minWidth:50,alignValue:'left',link:'/offers/view/',color:'blue'},
        {label:'Message',value:'message',show:true,width:'20%',minWidth:50},
        {label:'Requested At',value:'created_at',show:true,width:'15%',minWidth:100},
        {label:'Approved/Declined At',value:'updated_at',show:true,width:'15%',minWidth:150},
        {label:'Status',value:'status',show:true,width:'10%',minWidth:50,customComponent:statusCustomComponent},
        {label:'Change',value:'approvedDeny',show:true,width:'5%',minWidth:50},
    ]

    const [tableHeaders, setTableHeaders] = useState(tempTableHeaders)
    

    if(!zoomed && manualUseState){
        setManualUseState(false);
    }

    var columnLabelSelector = {
        approved : 'Approved At',
        denied : 'Declined At',
        requested : 'Requested At'
    }

    const approvalRequestApi = async(query="",limit,page) => {
        setShowLoader(true)
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.OFFER_APPROVAL_REQUEST),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                limit,
                page,
                q:query
            },    
        })
        .then(resp => {
            setQuery(query)
            setData(resp.data.result)
            setTotalResults(resp.data.count)
            setShowLoader(false)
        })
        .catch(err => {
            console.error(err);
        })
    };
    
    if (zoomed && !manualUseState){
        setManualUseState(true);
        setQuery(props.state);
        if(props.state==='requested'){
            tempTableHeaders[5]['hide'] = true
        }
        tempTableHeaders[5].label = columnLabelSelector[props.state]
        setTableHeaders(tempTableHeaders);
        approvalRequestApi(props.state,limit,page);
    }

    const offerApprovalRequest = async(id,action="approved") => {
        await axios({
          method: 'POST',
          url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"offerapprovalrequest/change"),
          headers:{
              Authorization:getLocalStorageValue('adminPanelLoginData').token,
              "Content-Type":"application/json",
          },
          data:{
                    "request_status_action" : action,    
                    "offerapprovalrequest_id" : id,
                }
        })
        .then((resp)=>{
            approvalRequestApi(query,limit,1);
        }
        )
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.status);
            console.log(error.response.headers);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
        });
    }
    const [top, setTop] = useState(0)
   
    if(top===0){
        if(document.getElementById('loginAffiliateRightBar')){
            var tempTop = (0 - document.getElementById('loginAffiliateRightBar').getBoundingClientRect().top + 60) + "px !important"
            setTop(tempTop)
        }
    }
    
    return (
        <div className={zoomed ? "zoomedBackgroundV2" : "zoomedOutBackgroundV2"}>
            <div className={zoomed ? "zoomedV2" : "zoomedOut"}>
                {zoomed && props.showData && 
                <div style={{height:'100%'}}> 
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                        
                        <div style={{padding:"1%",fontSize:"15px",fontWeight:"600"}}>
                            Affiliate Requests
                        </div>
                        <div style={{padding:"1%",cursor:"pointer"}} onClick={()=>{props.close();}}>
                            X
                        </div>
                    </div>

                    <div style={{display:"flex",justifyContent:"end",paddingRight:"5%"}}>
                        
                        { [{label:'Requested',value:'requested'},{label:'Approved',value:'approved'},{label:'Declined',value:'denied'}].map((item,index)=>{
                            return <div className={query===item.value ? 'requestedTabActive' : 'requestedTabInactive'} 
                                        style={{cursor:(!showLoader ? 'pointer' : 'not-allowed'),color:(query===item.value ? localStorage.getItem('ambientColor') : 'rgb(150,150,150)')}} 
                                        onClick={()=>{
                                            if(!showLoader){
                                                var tempTableHeaders = tableHeaders;
                                                if(item.value==='requested'){
                                                    tempTableHeaders[5]['hide'] = true
                                                }
                                                else{
                                                    tempTableHeaders[5]['hide'] = false
                                                }
                                                tempTableHeaders[5].label = columnLabelSelector[item.value]
                                                setTableHeaders(tempTableHeaders);
                                                approvalRequestApi(item.value,setShowLoader,setQuery,setData);
                                                setQuery(item.value)
                                            }
                                        }}
                                    >
                                        {item.label}
                                    </div>

                        })}
                    </div>
                    <div style={{overflow:'auto',maxHeight:'80%',padding:'10px',paddingTop:'0px'}}>
                        {
                            <TableModal stickyColumn={[1,2]} showLoader={showLoader} tableHeaders={tableHeaders} setTableHeaders={setTableHeaders} tableValues={data} offerApprovalRequest={offerApprovalRequest} setTableValues={setData}/>
                        }
                    </div>
                                    
                    <div style={{display:'flex',alignItems:'center',justifyContent:"space-between",paddingLeft:'20px',paddingRight:'20px',paddingBottom:'2px',position:'absolute',bottom:'5vh',background:'white',width:'calc(90vw - 40px)'}}>
                        <div>
                            <label style={{paddingRight:"5px",fontSize:'14px'}}>Limit</label>
                            <select value={limit} style={{outline:'none',border:'none'}} onChange={(e)=>{approvalRequestApi(query,e.target.value,1);setLimit(parseInt(e.target.value));}}>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={75}>75</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                        <Pagination count={Math.ceil(totalResults/limit)} page={page} onChange={(event,value)=>{approvalRequestApi(query,limit,value);setPage(value);window.scrollTo({top: 0,left: 0,behavior: 'smooth'});}} />
                    </div>

                </div>
                }
                {!zoomed && 
                    <div>
                    .
                    </div>
                }       
            </div>
        </div>
    )
}
