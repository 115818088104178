import React from "react";
import "../../css/pages/affiliate.css";
import PropTypes from 'prop-types';
import { NavLink } from "react-router-dom";
import {capitalize, getLocalStorageValue} from '../../util/util';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Select from 'react-select';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import * as Constants from '../../data/constants';
import Switch from '@mui/material/Switch';


import axios from 'axios';
import Logo from "../../components/logoUpdater/logo";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function useForceUpdate(){
  const [value, setValue] = React.useState(0); // integer state
  if(false){
    console.log(value)
  }
  return () => setValue(value => value + 1); // update the state to force render
}
const NewEmployeePage = props => {
  const refreshComponent = useForceUpdate()

  const employeeid = window.location.href.split("/")[window.location.href.split("/").length-1];

  const [tabValue, setTabValue] = React.useState(0);
  const [manualUseState, setManualUseState] = React.useState(false);

  const [email, setEmail] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [employeeType, setEmployeeType] = React.useState("");
  const [contactMobile, setContactMobile] = React.useState("");
  const [skype, setSkype] = React.useState("");

  const [saveButtonDisabled, setSaveButtonDisabled] = React.useState(true);
  const [requiredString, setRequireString] = React.useState("");
  
  const [showResponsePopup, setShowResponsePopup] = React.useState(false);
  const [responsePopup, setResponsePopup] = React.useState(false);

  const [responsePopupError, setResponsePopupError] = React.useState(false);

  const [permission_list, setPermission_list] = React.useState([]);
  const [selectedPermission, setSelectedPermission] = React.useState([]);
  const [selectedPermissionCheck, setSelectedPermissionCheck] = React.useState([]);
  const [groupPermissionList,setGroupPermissionList] = React.useState([]);
  const [selectedGroups,setSelectedGroups] = React.useState([]);
  const [sudoUser, setSudoUser] = React.useState(false);
  
  const [disableAddButton, setDisableAddButton] = React.useState(false);
  

  const [showPermissionsList, setShowPermissionsList] = React.useState([]);

  const EMPLOYEE_TYPE  = [
    {value:'general_manager', label:'General Manager'},
    {value:'account_manager', label:'Account Manager'},
    {value:'affiliate_manager', label:'Affiliate Manager'},
    ]

  const handleTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  const showEmployeeRequest = async(group_permissions) => {
      if (!group_permissions){
        group_permissions = groupPermissionList
      }
      await axios({
          method: 'GET',
          url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"employee","/",employeeid,"/view"),
          headers:{
              Authorization:getLocalStorageValue('adminPanelLoginData').token
          },
      }
      )
      .then((resp) => {
          var response = resp.data.result
          if(response.length>0){
            response = response[0]
            setFirstName(response.first_name)
            setLastName(response.last_name)
            setEmail(response.email)
            setSkype(response.skype_id ? response.skype_id : "")
            setContactMobile(response.contact ? response.contact : "")
            setContactMobile(response.contact ? response.contact : "")
            for (var employee_type_index in EMPLOYEE_TYPE){
              if(response.employee_type===EMPLOYEE_TYPE[employee_type_index].value){
                setEmployeeType(EMPLOYEE_TYPE[employee_type_index])
              }
            }
            if(response.groups && response.groups.length>0){
              var temp_group_selected = []
              for (var group_index in response.groups){
                for (var index in group_permissions){
                  if (response.groups[group_index] === group_permissions[index].label){
                    temp_group_selected.push(group_permissions[index])
                  }
                }
              }
              setSelectedGroups(temp_group_selected)
            }
          }
          

      })
      .catch(function (error) {
        setDisableAddButton(false)
        setResponsePopupError("getEmployee")
        if (error.response) {
          // Request made and server responded
          setResponsePopup(error.response.data.result);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else {
          // Something happened in setting up the request that triggered an Error
          setResponsePopup(JSON.stringify({error:error.message}));
          console.log('Error', error.message);
        }
        setShowResponsePopup(true)
      });
  };

  const showEmployeePermissionsRequest = async() => {
      await axios({
          method: 'GET',
          url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"employee","/",employeeid,"/permissions"),
          headers:{
              Authorization:getLocalStorageValue('adminPanelLoginData').token
          },
      }
      )
      .then((resp) => {
          var response = resp.data.result
          var selectedpermission = []
          for (var permission in response){
            selectedpermission.push(permission)
          }
          setSelectedPermission(selectedpermission)
          setSudoUser(false)
      })
      .catch(err => {
          console.error(err);
      })
  };

  const changePassword = async() => {
    await axios({
        method: 'POST',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"employee/changepassword"),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token,
            "Content-Type":"application/json",
        },
        data:{
          email : email,
          new_password: newPassword,
        }
    })
    .then((resp) => {
        
    })
    .catch(error => {
      setDisableAddButton(false)
      setResponsePopupError(true)
      if (error.response) {
        // Request made and server responded
        setResponsePopup(error.response.data.result);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else {
        // Something happened in setting up the request that triggered an Error
        setResponsePopup(JSON.stringify({error:error.message}));
        console.log('Error', error.message);
      }
      setShowResponsePopup(true)
    });
  }
  const editAffiliateRequest = async() => {
    setDisableAddButton(true)
    var tempSelectedGroups = []
    for (var index in selectedGroups){
      tempSelectedGroups.push(selectedGroups[index].value)
    }
    await axios({
      method: 'POST',
      url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"employee/",employeeid,"/change"),
      headers:{
          Authorization:getLocalStorageValue('adminPanelLoginData').token,
          "Content-Type":"application/json",
      },
      data:{
          // "email": email,	
          // "password": newPassword,
          "first_name" : firstName,
          "last_name" : lastName,
          "user_type": employeeType.value,    
          "groups" : tempSelectedGroups,
          "skype_id" : skype ? skype : null,
          "contact" : contactMobile ? contactMobile : null,
          "permissions": selectedPermission  
      }
      })
    .then((resp)=>{
      if(resp.data.result && resp.data.result.employee) 
        { 
          if(newPassword){
            changePassword()
          }
          setDisableAddButton(false)
          setResponsePopupError(false);
          setResponsePopup("Employee edited successfully");}
          
      else{
          setResponsePopupError(true)
          setDisableAddButton(false)
          setResponsePopup(resp.data.result);
        }
      setShowResponsePopup(true)
      }
    )
    .catch(function (error) {
      setDisableAddButton(false)
      setResponsePopupError(true)
      if (error.response) {
        // Request made and server responded
        setResponsePopup(error.response.data.result);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else {
        // Something happened in setting up the request that triggered an Error
        setResponsePopup(JSON.stringify({error:error.message}));
        console.log('Error', error.message);
      }
      setShowResponsePopup(true)

    });
  }

  const getPermission = async() => {
    await axios({
      method: 'GET',
      url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"permissions"),
      headers:{
          Authorization:getLocalStorageValue('adminPanelLoginData').token
      },
    }
    )
    .then((resp) => {
        var data = resp.data.result
        var permission_list = []
        var permission_list_value = []
        for (var item in data){
          if(!permission_list.includes(capitalize(item.split(" add ")[1] || item.split(" change ")[1] || item.split(" delete ")[1] || item.split(" view ")[1])))
          permission_list.push(capitalize(item.split(" add ")[1] || item.split(" change ")[1] || item.split(" delete ")[1] || item.split(" view ")[1]))
          permission_list_value.push(data[item])
        }
        setPermission_list(permission_list)
    })
    .catch(err => {
        console.error(err);
    })    
  };

  const getGroupsPermission = async() => {
    await axios({
      method: 'GET',
      url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"groups"),
      headers:{
          Authorization:getLocalStorageValue('adminPanelLoginData').token
      },
    }
    )
    .then((resp) => {
        var data = resp.data

        var groupsList = []
        for (var item in data.result){
          var permission_list = []
          for (var permission in data.result[item].permissions){
            permission_list.push(data.result[item].permissions[permission])
          }
          groupsList.push({label:data.result[item].name,value:data.result[item].name,permissions:permission_list})
        }
        setGroupPermissionList(groupsList)
        showEmployeeRequest(groupsList);
    })
    .catch(err => {
        console.error(err);
    }) 
  }
  
  if (!manualUseState){
    setManualUseState(true);
    getGroupsPermission();
    getPermission();
    showEmployeePermissionsRequest();
  }

  const handleNewPassword = (e) => {
    var lowerCaseLetters = /[a-z]/g;
    var upperCaseLetters = /[A-Z]/g;
    var specialCharacter = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    var number = /[0-9]/g;
    var required_string_list = []
    if (e.target.value.match(specialCharacter) 
        && e.target.value.match(number) 
        && e.target.value.match(lowerCaseLetters) 
        && e.target.value.match(upperCaseLetters)
        && e.target.value.length>=8
        && e.target.value.length<=30
        ){  
            setRequireString("")
            setNewPassword(e.target.value)
        }

    if(e.target.value.length<8){
        required_string_list.push("Less than 8 characters")
    }
    else if(e.target.value.length>30){
        required_string_list.push("More than 30 characters")
    }
    else {
        if(!e.target.value.match(specialCharacter)){
            required_string_list.push("Special Character")
        }
        if(!e.target.value.match(number)){
            required_string_list.push("Number")
        }
        if(!e.target.value.match(lowerCaseLetters)){
            required_string_list.push("Lower case alphabet")
        }
        if(!e.target.value.match(upperCaseLetters)){
            required_string_list.push("Upper case alphabet")
        }
    }
    if (required_string_list.length!==0){
        var requiredString = ""
        for (var item in required_string_list){
            requiredString += required_string_list[item] + ", "
        }
        requiredString = requiredString.slice(0,(requiredString.length-2))
        if (requiredString!=="Less than 8 characters"){
            requiredString+=" is missing"
        }
        setRequireString(requiredString)
        setNewPassword(e.target.value)
    }
  }

  const specialCharacter = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  const number = /[0-9]/g;
  const email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const skype_regex = /[a-zA-Z][a-zA-Z0-9.,\-_]{5,31}/;
    if (firstName !== "" 
      && !firstName.match(specialCharacter) 
      && (contactMobile === "" || contactMobile.match(number))
      ){
    if (saveButtonDisabled===true){
        setSaveButtonDisabled(false)
    }
    }
    else{
      if (saveButtonDisabled===false){
        setSaveButtonDisabled(true)
    }
  }
  
  const handleSaveAndContinueAffiliateButton = () => {
    handleTabValue("continue",(tabValue<1 ? tabValue+1 : 0))
  }
  const handleSaveAffiliateButton = () => {
    editAffiliateRequest()
  }
  
  const general = () => {
    return(
      <div style={{height:"100%",position:'relative',overflowY:"auto",marginLeft:"1%",marginTop:"2%"}}>
        <div style={{position:'absolute',top:'-0px',right:'5%'}}>
          <Logo page='manager' addText={'photo'} id={employeeid}/>
        </div>
        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>First Name * </div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" value={firstName ? firstName : ""}  onChange={(e)=>{setFirstName(e.target.value)}}/></div>
          {(firstName ? firstName.match(specialCharacter) || firstName.match(number) ? <div style={{fontSize:"11px",display:"flex",alignItems:"center",color:"red"}}>First Name invalid.</div> : null  : null)}
        </div>

        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Last Name * </div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" value={lastName ? lastName : ""}  onChange={(e)=>{setLastName(e.target.value)}}/></div>
          {(lastName ? lastName.match(specialCharacter) || lastName.match(number) ? <div style={{fontSize:"11px",display:"flex",alignItems:"center",color:"red"}}>Last Name invalid.</div> : null  : null)}
        </div>

        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>EMAIL *</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField disabled fullWidth size="small" value={email ? email : "loading"} onChange={(e)=>{setEmail(e.target.value)}}/></div>
          {(email ? !email.match(email_regex)? <div style={{fontSize:"11px",display:"flex",alignItems:"center",color:"red"}}> Email id is not in correct form.</div> : null  : null)}
        </div>

        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>PASSWORD *</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" value={newPassword} onChange={handleNewPassword}/></div>
          <label style={{fontSize:"12px",color:"red",marginTop:"1%"}}>{requiredString!=="" ? requiredString : ""}</label>
        </div>

        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Employee Type *</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}>
          <Select className="selectCountryList" options={EMPLOYEE_TYPE}
              value={employeeType ? employeeType : null}
              onChange={(e)=>{setEmployeeType(e)}}
              menuPortalTarget={document.body} 
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                      option: styles => ({...styles,fontSize:"12px"}) }}/>
          </div>
        </div>
        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Skype</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" value={skype ? skype : ""}  onChange={(e)=>{setSkype(e.target.value)}}/></div>
          {(skype ? !skype.match(skype_regex)? <div style={{fontSize:"11px",display:"flex",alignItems:"center",color:"red"}}> Skypeid is incorrect.</div> : null  : null)}
        </div>

        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Contact Number</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" value={contactMobile ? contactMobile : ""} onChange={(e)=>{setContactMobile(e.target.value)}}/></div>
          {(contactMobile ? !contactMobile.match(number) ? <div style={{fontSize:"11px",display:"flex",alignItems:"center",color:"red"}}> Contact number invalid.</div> : null  : null)}
        </div>
        
      </div>
    )
  }

  const remove_element = (list,permission) => {
    return list.filter(e => e!==permission)
  }


  const permissionDict = {
    permission_head:["permission","permission_head"],
    stats_head:["clicks","installs","aggrigatedstats","declinedpostbackevents","declinedpostbacks","trafficbackpartition","stats_head"],
    offers_head:["offers","offerapprovalrequest","offerdeclined",'offerbookmarks',"offers_head"],
    advertisers_head:["advertiser","advertisers_head"],
    affiliate_head:["affiliate","affiliatepassword","loginaffiliatepanel","affiliateapikey","localpostback","globalpostback","disabledadvertiser","affiliateofferfilteration","affiliate_head"],
    automation_head:["recheckstatus","automation_head"],
    offersync_head:["offersync","demandsource","offersfetched","offersync_head"],
    smartlinks_head:["smartlinks","smartlinks_head"],
    employees_head:["employee",'employeepassword',"accountinfo","login_affiliate","group","user","employees_head"],
    exports_head:["exportstats","exports_head"],
    // others_head:[,"others_head"]
  }
  const allRadioButtonChecked = (e,getItem) =>{
    var tempList = selectedPermission
    if(!tempList){
      tempList = []
    }
    const addItems = (tempList,item,headitem) => {
      if(headitem!=="head" && tempList.includes("add_"+item) && tempList.includes("change_"+item) && tempList.includes("delete_"+item) && tempList.includes("view_"+item)){
        setSudoUser(false)
        tempList = remove_element(tempList,"add_"+item)
        tempList = remove_element(tempList,"change_"+item)
        tempList = remove_element(tempList,"delete_"+item)
        tempList = remove_element(tempList,"view_"+item)
        if(headitem){
          tempList = remove_element(tempList,"add_"+headitem)
          tempList = remove_element(tempList,"change_"+headitem)
          tempList = remove_element(tempList,"delete_"+headitem)
          tempList = remove_element(tempList,"view_"+headitem)
        }
      }
      else{
        tempList.push("add_"+item)
        tempList.push("change_"+item)
        tempList.push("delete_"+item)
        tempList.push("view_"+item)
      }
      return tempList
    }
    if(getItem.includes("_head")){
      var headitem = ""
      if(!tempList.includes("add_"+getItem) && !tempList.includes("change_"+getItem) && !tempList.includes("delete_"+getItem) && !tempList.includes("view_"+getItem)){
        headitem = "head"
      }
      for (var item2 in permissionDict[getItem]){
        tempList = addItems(tempList,permissionDict[getItem][item2],headitem)
      }
    }
    else{
      headitem = ""
      for (var keys in permissionDict){
        if (permissionDict[keys].includes(getItem)){
          headitem = keys
        }
      }
      tempList = addItems(tempList,getItem,headitem)
    }
    setSelectedPermission(tempList)
    setSelectedPermissionCheck(selectedPermissionCheck+getItem) 
  }

  const addPermissionChecked = (e,getItem) =>{
    var tempList = selectedPermission
    if(!tempList){
      tempList = []
    }
    const addItems = (tempList,item,headitem) => {
      if(headitem!=="head" && tempList.includes("add_"+item)){
        setSudoUser(false)
        tempList = remove_element(tempList,"add_"+item)
        if(headitem){
          tempList = remove_element(tempList,"add_"+headitem)
        }
      }
      else{
        tempList.push("add_"+item)
        tempList.push("view_"+item)
      }
      return tempList
    }
    if(getItem.includes("_head")){
      var headitem = ""
      if(!tempList.includes("add_"+getItem)){
        headitem = "head"
      }
      for (var item2 in permissionDict[getItem]){
        tempList = addItems(tempList,permissionDict[getItem][item2],headitem)
      }
    }
    else{
      headitem = ""
      for (var keys in permissionDict){
        if (permissionDict[keys].includes(getItem)){
          headitem = keys
        }
      }
      tempList = addItems(tempList,getItem,headitem)
    }
    setSelectedPermission(tempList)
    setSelectedPermissionCheck(selectedPermissionCheck+getItem) 
    
  }

  const changePermissionChecked = (e,getItem) =>{
    var tempList = selectedPermission
    if(!tempList){
      tempList = []
    }
    const addItems = (tempList,item,headitem) => {
      if(headitem!=="head" && tempList.includes("change_"+item)){
        setSudoUser(false)
        tempList = remove_element(tempList,"change_"+item)
        if(headitem){
          tempList = remove_element(tempList,"change_"+headitem)
        }
      }
      else{
        tempList.push("change_"+item)
        tempList.push("view_"+item)
      }
      return tempList
    }
    if(getItem.includes("_head")){
      var headitem = ""
      if(!tempList.includes("change_"+getItem)){
        headitem = "head"
      }
      for (var item2 in permissionDict[getItem]){
        tempList = addItems(tempList,permissionDict[getItem][item2],headitem)
      }
    }
    else{
      headitem = ""
      for (var keys in permissionDict){
        if (permissionDict[keys].includes(getItem)){
          headitem = keys
        }
      }
      tempList = addItems(tempList,getItem,headitem)
    }
    setSelectedPermission(tempList)
    setSelectedPermissionCheck(selectedPermissionCheck+getItem) 
  }

  // const deletePermissionChecked = (e,item) =>{
  //   var tempList = selectedPermission
  //   var viewitem = "view_"+item
  //   item = "delete_"+item
  //   if(!tempList){
  //     tempList = []
  //   }
  //   if(tempList.includes(item)){
  //     setSudoUser(false)
  //     tempList = remove_element(tempList,item)
  //   }
  //   else{
  //     tempList.push(item)
  //     if (!tempList.includes(viewitem))
  //       {tempList.push(viewitem)}
  //     }
  //   setSelectedPermission(tempList)
  //   setSelectedPermissionCheck(selectedPermissionCheck+item)
  // }

  const viewPermissionChecked = (e,getItem) =>{
    var tempList = selectedPermission
    if(!tempList){
      tempList = []
    }
    const addItems = (tempList,item,headitem) => {
      if(headitem!=="head" && tempList.includes("view_"+item)){
        setSudoUser(false)
        tempList = remove_element(tempList,"change_"+item)
        tempList = remove_element(tempList,"add_"+item)
        tempList = remove_element(tempList,"view_"+item)
        if(headitem){
          tempList = remove_element(tempList,"change_"+headitem)
          tempList = remove_element(tempList,"add_"+headitem)
          tempList = remove_element(tempList,"view_"+headitem)
        }
      }
      else{
        tempList.push("view_"+item)
      }
      return tempList
    }
    if(getItem.includes("_head")){
      var headitem = ""
      if(!tempList.includes("view_"+getItem)){
        headitem = "head"
      }
      for (var item2 in permissionDict[getItem]){
        tempList = addItems(tempList,permissionDict[getItem][item2],headitem)
      }
    }
    else{
      headitem = ""
      for (var keys in permissionDict){
        if (permissionDict[keys].includes(getItem)){
          headitem = keys
        }
      }
      tempList = addItems(tempList,getItem,headitem)
    }
    setSelectedPermission(tempList)
    setSelectedPermissionCheck(selectedPermissionCheck+getItem) 
  }

  const sudoUserChange = (e,sudouser) =>{
    setSudoUser(sudouser)

    if(sudouser){
      var tempList = []
      for (var index in permission_list){
        var item = permission_list[index].replace(" ","").replace(" ","").replace(" ","").toLowerCase()
        tempList.push("add_"+item)
        tempList.push("change_"+item)
        tempList.push("delete_"+item)
        tempList.push("view_"+item)
        setSelectedPermissionCheck(selectedPermissionCheck+item)
      }
      for(var key in permissionDict){
        tempList.push("add_"+key)
        tempList.push("change_"+key)
        tempList.push("delete_"+key)
        tempList.push("view_"+key)
      }
      setSelectedPermission(tempList)
    }
    else{
      setSelectedPermissionCheck("")
      setSelectedPermission([])
    }
  }

  const groupSelected = (e) => {
    var temp_permission_list = []
    for(var index in e){
      for (var permission in e[index].permissions){
        temp_permission_list.push( e[index].permissions[permission])
      }
    }
    setSelectedPermission(temp_permission_list)
    setSelectedGroups(e)
  }

  const permissions = () => {
    return <div style={{overflowY:"auto",height:"65vh",marginLeft:"1%",marginTop:"1%",overflow:"hidden"}}>
          <div style={{display:"flex",height:"8vh",paddingLeft:"5%",paddingRight:"5%",fontSize:"15px",fontWeight:"600"}}>
            <div style={{width:"50%",display:"flex",alignItems:"center"}}>
              Choose Permission Groups
            </div>
            <div style={{width:"50%",display:"flex",justifyContent:"center",transform:"scale(0.7)"}}>
              <Select options={groupPermissionList}
                value={selectedGroups}
                onChange={groupSelected}
                menuPortalTarget={document.body} 
                isMulti
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999}),
                        option: styles => ({...styles,fontSize:"10px"}),
                        container: provided => ({
                          ...provided,
                          width: 3000,
                          height:1,
                        })
                }}/>
            </div>
          </div>
          <div style={{display:"flex",height:"5vh",paddingLeft:"5%",paddingRight:"5%",fontSize:"15px",fontWeight:"600"}}>
            <div style={{display:"flex",width:"55%"}}>Permissions</div>
            <div style={{display:"flex",width:"10%",justifyContent:"center"}}>Allow All</div>
            <div style={{display:"flex",width:"10%",justifyContent:"center"}}>View</div>
            <div style={{display:"flex",width:"10%",justifyContent:"center"}}>Add</div>
            <div style={{display:"flex",width:"10%",justifyContent:"center"}}>Edit</div>
            {/* <div style={{display:"flex",width:"10%",justifyContent:"center"}}>Delete</div> */}
          </div>
          <div style={{display:"block",height:"80%",paddingLeft:"5%",paddingRight:"5%",overflowY:"auto",fontSize:"13px"}}>
            <div style={{display:"flex",paddingTop:"2px"}}>
              <div style={{display:"flex",width:"55%"}}>Sudo User</div>
              <div style={{display:"flex",width:"10%",justifyContent:"center"}}>
                <Switch checked={sudoUser} color="secondary" onChange={(e)=>{sudoUserChange(e,!sudoUser)}} size="small" />
              </div>
            </div>
            <div style={{height:"0.5vh"}}></div>
            <div style={{display:"flex",color:"orange",cursor:"default"}}>
              <div style={{cursor:"pointer"}} onClick={(e)=>{refreshComponent(e);setShowPermissionsList(Object.keys(permissionDict))}}>Show All</div>
              &nbsp;&nbsp;&nbsp;
              <div style={{cursor:"pointer"}} onClick={(e)=>{setShowPermissionsList([])}}>Collapse All</div>
            </div>
            <div style={{height:"1vh"}}></div>
            {
              Object.keys(permissionDict).map((item,index)=>{
                return <>
                  <div style={{display:"flex",paddingBottom:"10px",paddingTop:"5px"}}>
                    <div style={{display:"flex",alignItems:"center",width:"55%"}}  onClick={(e)=>{refreshComponent(e); if(showPermissionsList.includes(item)){setShowPermissionsList(remove_element(showPermissionsList,item))} else {showPermissionsList.push(item)}}}>
                      <div style={showPermissionsList.includes(item) ? {fontSize:"13px",fontWeight:"500",transform:"rotateZ(90deg)",transition:"all 0.3s ease-in-out",cursor:"pointer"} : {fontSize:"13px",fontWeight:"500",transition:"all 0.3s ease-in-out",cursor:"pointer"}}>{">"}</div>
                      &nbsp;&nbsp;{capitalize(item.split("_")[0])}
                      
                    </div>
                    {/* <div style={{display:"flex",width:"10%",justifyContent:"center"}}>
                      <Switch checked={selectedPermission.includes("add_"+item) && selectedPermission.includes("change_"+item)  && selectedPermission.includes("view_"+item)} color="warning" onChange={(e)=>{allRadioButtonChecked(e,item)}} size="small" />
                    </div>
                    <div style={{display:"flex",width:"10%",justifyContent:"center"}}>
                      <Switch checked={selectedPermission.includes("view_"+item)} onChange={(e)=>{viewPermissionChecked(e,item)}} size="small" />
                    </div>
                    <div style={{display:"flex",width:"10%",justifyContent:"center"}}>
                      <Switch checked={selectedPermission.includes("add_"+item)} onChange={(e)=>{addPermissionChecked(e,item)}}  size="small" />
                    </div>
                    <div style={{display:"flex",width:"10%",justifyContent:"center"}}>
                      <Switch checked={selectedPermission.includes("change_"+item)} onChange={(e)=>{changePermissionChecked(e,item)}} size="small" />
                    </div> */}
                  </div>
                  {permission_list.map((item2,index)=>{
                    var item_without_underscore = item2
                    item2 = item2.replace(" ","").replace(" ","").replace(" ","").toLowerCase()
                    if(showPermissionsList.includes(item) && permissionDict[item].includes(item2)){
                      return <div>  
                                <div key={index} style={{display:"flex",alignItems:"center"}}>
                                  <div style={{display:"flex",width:"50%",paddingLeft:'5%'}}>{item_without_underscore}</div>
                                  <div style={{display:"flex",width:"10%",justifyContent:"center"}}>
                                    <Switch disabled={item_without_underscore === 'Login affiliate panel' ? true : false} checked={selectedPermission.includes("add_"+item2) && selectedPermission.includes("change_"+item2) && selectedPermission.includes("delete_"+item2) && selectedPermission.includes("view_"+item2)} color="warning" onChange={(e)=>{allRadioButtonChecked(e,item2)}} size="small" />
                                  </div>
                                  <div style={{display:"flex",width:"10%",justifyContent:"center"}}>
                                    <Switch checked={selectedPermission.includes("view_"+item2)} onChange={(e)=>{viewPermissionChecked(e,item2)}} size="small" />
                                  </div>
                                  <div style={{display:"flex",width:"10%",justifyContent:"center"}}>
                                    <Switch disabled={item_without_underscore === 'Login affiliate panel' ? true : false} checked={selectedPermission.includes("add_"+item2)} onChange={(e)=>{addPermissionChecked(e,item2)}}  size="small" />
                                  </div>
                                  <div style={{display:"flex",width:"10%",justifyContent:"center"}}>
                                    <Switch disabled={item_without_underscore === 'Login affiliate panel' ? true : false} checked={selectedPermission.includes("change_"+item2)} onChange={(e)=>{changePermissionChecked(e,item2)}} size="small" />
                                  </div>
                                  {/* <div style={{display:"flex",width:"10%",justifyContent:"center"}}>
                                    <Switch checked={selectedPermission.includes("delete_"+item)} onChange={(e)=>{deletePermissionChecked(e,item)}}  size="small" />
                                  </div> */}
                    </div>
                        </div>
                    }
                  return null;})}
                  <div style={{height:"0.5vh"}}></div>

                </>
              })
            }

            <div style={{height:"0.5vh"}}></div>

          </div>
      </div>
    
  }

  return (
     <div className="row">
        <div className="columnData" style={{width:"95%",background:"white",height:"85vh"}}>
        <div style={{background:"white",width:"auto",height:"7vh"}}>
          <Tabs 
            TabIndicatorProps={{style: {background:'orange',backgroundColor:'orange'}}}
            value={tabValue} 
            onChange={handleTabValue} 
            aria-label="basic tabs example">
            <Tab label={<span className={tabValue===0? 'Active' : 'Inactive'}>General</span>}/>
            <Tab label={<span className={tabValue===1? 'Active' : 'Inactive'}>Permissions</span>}/>
          </Tabs>
          
        </div>
        <div style={{height:"70vh",width:"100%",background:"white",overflowY:"auto"}}>
         
          <TabPanel value={tabValue} index={0}>
            {general()}
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {permissions()}
          </TabPanel>
          
        </div>

        <div style={{display:"flex",alignItems:"center",height:"7vh",width:"90%"}}>
          <div style={{display:"flex",marginLeft:"20%"}}>
            <NavLink style={{textDecoration:"none",color:"black"}} to={"/account/employees/view/"+employeeid}>
              <Button variant="outlined">View</Button>
            </NavLink>      
          </div>
            <Button style={{marginLeft:"2%"}} variant="contained" color="warning" onClick={handleSaveAndContinueAffiliateButton}>SAVE AND CONTINUE</Button>
          
            <Button style={{marginLeft:"2%"}} variant="contained" color="warning" onClick={handleSaveAffiliateButton} disabled={saveButtonDisabled || disableAddButton}>Edit</Button>
          <div style={{marginLeft:"2%"}}>
            <NavLink style={{textDecoration:"none",color:"black"}} to={"/account/employees"}>
              <Button variant="text">Cancel</Button>
            </NavLink>          
          </div>
            <Button style={{marginLeft:"2%"}} variant="outlined" color="warning" onClick={()=>{showEmployeePermissionsRequest();showEmployeeRequest();}}>Reset</Button>

        </div>
      </div>
      {showResponsePopup && 
          <div className="popup-box">
            <div className="box-response">
              <div className="popupHeader" >{"RESULT"}</div>
              <div style={{borderBottom:"2px solid orange"}}></div>
                <div className="popupData">
                  {responsePopup}
                </div>
                {
                  !responsePopupError && 
                    <NavLink style={{textDecoration:"none",color:"black"}} to={"/account/employees"}>
                      <div className="close-icon-response" onClick={()=>{setShowResponsePopup(false)}}>X</div>
                    </NavLink>
                  }
                {
                  responsePopupError &&
                    <div className="close-icon-response" onClick={()=>{setShowResponsePopup(false)}}>X</div>
                }
                {
                  responsePopupError==="getEmployee" && 
                    <NavLink style={{textDecoration:"none",color:"black"}} to={"/account/employees"}>
                      <div className="close-icon-response" onClick={()=>{setShowResponsePopup(false)}}>X</div>
                    </NavLink>
                  }
            </div>
          </div>
      }
    </div>
  );
};
 
export default NewEmployeePage;