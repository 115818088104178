import { Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react'
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { getAdvertisers, getManager } from '../../../apis/dropdown/dropdowns';
import { increase_brightness } from '../../../components/customization/customization';
import '../../../css/pages/v2/advertiser.css';
import * as Constants from '../../../data/constants';
import { capitalize, getLocalStorageValue, getRequests, sorting, useForceUpdate } from '../../../util/util';
import { Store } from 'react-notifications-component';
import { NavLink } from 'react-router-dom';
import CopyButton from '../../../components/copyButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import Validations from '../../../util/validations';
import { parse } from 'date-fns';
const NewEditAffiliatePageV2 = () => {
    var refreshComponent = useForceUpdate()
    if(window.location.href.includes('/edit')){
        var tempAffiliateId = window.location.href.split('/edit/')[1]
    }
    else{
        tempAffiliateId = false
    }
    if(tempAffiliateId){
        document.title = 'Edit Affiliate - '+tempAffiliateId
    }
    else{
        document.title = 'Add Affiliate'
    }

    var statusDict ={
        active:'Active',
        notactive:'Not Active',
        banned:'Banned',
        moderation:'On Moderation',
    }

    const [affiliateId, setAffiliateId] = useState(tempAffiliateId)
    const [manualUseEffect, setManualUseEffect] = useState(false)
    const [managerOptions, setManagerOptions] = useState([])
    const [advertiserOptions, setAdvertiserOptions] = useState([])
    const [response, setResponse] = useState({affiliate_status:{label:'Active',value:'active'}})
    const [buttonLoader, setButtonLoader] = useState(false)

    const [tagOptions, setTagOptions] = useState([])
    const [changesDone,setChangesDone] = useState({})
    const [backupDisabledAdvertisers, setBackupDisabledAdvertisers] = useState([])
    const getDisabledAdvertiser = async(affiliateResponse,advertiserDict) => {
        return await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"viewdisabledadvertiser"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                affiliate_id:affiliateId,
            }
        }
        )
        .then((resp) => {
            var tempResponse = resp.data.result
            var tempDisabledAdvertiser = []
            var tempDisabledAdvertiserDict = {}
            for(var index in tempResponse){
                tempDisabledAdvertiserDict[tempResponse[index].toString()] = advertiserDict[tempResponse[index]]
                tempDisabledAdvertiser.push({
                    label : advertiserDict[tempResponse[index]],
                    value : tempResponse[index].toString()
                })
            }
            setBackupDisabledAdvertisers(tempDisabledAdvertiserDict)
            return tempDisabledAdvertiser
        })
        .catch(error => {
            return false
        });
    }
    const getTags = async() => {
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"affiliate/tags/details"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        })
        .then(resp => {
            let managerlist = []
            for (var item in resp.data.result){
                managerlist.push({label:resp.data.result[item],value:resp.data.result[item]})
            }
            sorting(managerlist,'label',false,true)
            setTagOptions(managerlist)
        })
        .catch(err => {
            console.error(err);
        })
    };

    const viewDisabledOfferTypes = async(affiliateResponse) => {    
        return await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"affiliate/viewofferfilteration"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                affiliate_id:affiliateId,
            }
        }
        )
        .then((resp) => {
            if(typeof resp.data.result === 'object'){
                return resp.data.result
            }
            else{
                return false
            }
        })
        .catch(error => {
            console.error(error);
            return false
        });
    }
    const getApiKey = async() => {
        return await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"apikey","/",affiliateId,"/","view"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        })
        .then(resp => {
            if(resp.data.result==="No such api key found, please add it before viewing"){
                return ""
            }
            return resp.data.result
        })
        .catch(err => {
            console.error(err);
            return false
        })
    };
    const [globalPostbackFound, setGlobalPostbackFound] = useState(true)
    const getGlobalPostback = async() => {
        return await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.POSTBACKS),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                affiliate_id : affiliateId
            }
        })
        .then(resp => {
            if(resp.data.result[0].global_postback.length>0){
                setGlobalPostbackFound(false)
                return resp.data.result[0].global_postback[0]
            }
        })
        .catch(err => {
            console.error(err);
            return false;
        })
    };
    
    const [goalTitleValueMapping, setGoalTitleValueMapping] = useState({})
    const getGoalList = async(offerids) => {
        axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"offergoals"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                offer_ids:offerids.slice(0,-1)
            }
        })
        .then(resp => {
            var tempMapping = goalTitleValueMapping
            for(var offerid in resp.data.result){
                tempMapping[offerid] = {any:'Any'}
                for(var goalIndex in resp.data.result[offerid]){
                    var goalId = resp.data.result[offerid][goalIndex]
                    tempMapping[offerid] = {...tempMapping[offerid],...goalId}
                }
            }
            setGoalTitleValueMapping(tempMapping)
            refreshComponent(123)
        })
        .catch(err => {
            console.error(err);
        }) 
    };  
    const getEventPostbacks = async() => {
        return axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"eventpostbacks"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                affiliate_id:affiliateId
            }
        })
        .then(resp => {
            var tempOffersList = ""
            for(var index in resp.data.result){
                var offerid = resp.data.result[index].offer_id + ","
                if(!tempOffersList.includes(offerid)){
                    tempOffersList+=offerid
                }
            }
            if(tempOffersList){
                getGoalList(tempOffersList)
            }
            return resp.data.result
        })
        .catch(err => {
            console.error(err);
            return false;
        }) 
    };  

    const getPaymentSystems = async() => {
        return axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"affiliate/payment/system/view"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                affiliate_id:affiliateId
            }
        })
        .then(resp => {
            var tempResponse = resp.data.result
            var result = []
            if(getLocalStorageValue('adminPanelLoginData').panelSettings && getLocalStorageValue('adminPanelLoginData').panelSettings.paymentOptions){
                var paymentOptions = getLocalStorageValue('adminPanelLoginData').panelSettings.paymentOptions
                for(var index in tempResponse){
                    for(var index2 in paymentOptions){
                        if(paymentOptions[index2].title === tempResponse[index].title && paymentOptions[index2].currency === tempResponse[index].currency){
                            var tempDict = paymentOptions[index2]
                            tempDict.fieldsValue = tempResponse[index].fields
                            tempDict.paymentOptionsIndex = parseInt(index2)
                            result.push(tempDict)
                        }
                    }
                }
            }
            if(result.length===0){
                result = [{}]
            }
            setPaymentSystem(result)
        })
        .catch(err => {
            console.error(err);
            return false;
        }) 
    };  
    const getEnabledOffers = async() => {
        return axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"offers/enabled/view"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                affiliate_id:affiliateId
            }
        })
        .then(resp => {
            return resp.data.result
        })
        .catch(err => {
            console.error(err);
            return false;
        }) 
    };  

    const getDisabledOffers = async() => {
        return axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"offers/disabled/view"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                affiliate_id:affiliateId
            }
        })
        .then(resp => {
            return resp.data.result
        })
        .catch(err => {
            console.error(err);
            return false;
        }) 
    };  
    const getAdvertiserDetails = async() => {
        setButtonLoader(true)
        var url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.AFFILIATE,"/",affiliateId,"/view")

        await axios({
            method: 'GET',
            url,
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
        })
        .then(async(resp) => {
            var responseResult = resp.data.result[0]
            var advertiserDetail = responseResult
            var affiliateResponse = {}
            var tempValidationFields = validationFields
            for(var keyIndex in advertiserDetail){
                if(advertiserDetail[keyIndex]){
                    tempValidationFields = checkValidation(keyIndex,advertiserDetail[keyIndex],tempValidationFields)
                    if(keyIndex==='address'){
                        try{
                            for(var addressIndex in advertiserDetail[keyIndex]){
                                if(addressIndex==='country'){
                                    affiliateResponse = {...affiliateResponse,[addressIndex]:{label:advertiserDetail[keyIndex][addressIndex],value:advertiserDetail[keyIndex][addressIndex]}}
                                }
                                else{
                                    affiliateResponse = {...affiliateResponse,[addressIndex]:advertiserDetail[keyIndex][addressIndex]}
                                }
                            }
                        }
                        catch{
                            console.log('error')
                        }
                    }  
                    else if(keyIndex==='manager_name'){
                        affiliateResponse = {...affiliateResponse,[keyIndex]:{label:advertiserDetail[keyIndex].split(')')[1] + " - " + advertiserDetail[keyIndex].split(')')[0].split("(")[1],value:advertiserDetail[keyIndex].split(')')[0].split("(")[1]}}
                    }
                    else if(keyIndex==='affiliate_status'){
                        affiliateResponse = {...affiliateResponse,[keyIndex]:{label:statusDict[advertiserDetail[keyIndex]],value:advertiserDetail[keyIndex]}}
                    }
                    else if(keyIndex==='tags'){
                        var tagsList = []
                        for(var tagIndex in advertiserDetail[keyIndex]){
                            tagsList.push({label:advertiserDetail[keyIndex][tagIndex],value:advertiserDetail[keyIndex][tagIndex]})
                        }
                        affiliateResponse = {...affiliateResponse,[keyIndex]:tagsList}
                    }
                    else{
                        affiliateResponse = {...affiliateResponse,[keyIndex]:advertiserDetail[keyIndex]} 
                    }
                    if(keyIndex==='sub_id'){
                        setSubIdSelected(advertiserDetail.sub_id)
                    }
                }
            }
            
            getRequests(getManager(),setManagerOptions)
            getTags();
            setResponse(affiliateResponse)
            
            var advertiserData = await getAdvertisers(false,'both')
            setAdvertiserOptions(advertiserData.advertiserlist)
            var disabled_advertisers = await getDisabledAdvertiser({},advertiserData.advertiserDict)
            if(disabled_advertisers){
                affiliateResponse = {...affiliateResponse,disabled_advertisers}
            }
            setResponse(affiliateResponse)

            var enable_offer_type = await viewDisabledOfferTypes()
            if(enable_offer_type){
                affiliateResponse = {...affiliateResponse,enable_offer_type}
            }
            setResponse(affiliateResponse)

            var global_postback = await getGlobalPostback()
            if(global_postback){
                affiliateResponse = {...affiliateResponse,global_postback}
            }
            setResponse(affiliateResponse)

            var api_key = await getApiKey()
            if(api_key){
                affiliateResponse = {...affiliateResponse,api_key}
            }
            setResponse(affiliateResponse)

            var event_postback = await getEventPostbacks()
            if(event_postback){
                affiliateResponse = {...affiliateResponse,event_postback}
            }
            setResponse(affiliateResponse)

            var enabled_offers = await getEnabledOffers()
            if(enabled_offers){
                var enabledOffers = []
                for(var offerId in enabled_offers){
                    enabledOffers.push({label:enabled_offers[offerId].id.toString(),value:enabled_offers[offerId].id.toString()})
                }
                affiliateResponse = {...affiliateResponse,enabled_offers:enabledOffers}
            }
            setResponse(affiliateResponse)

            var disabled_offers = await getDisabledOffers()
            if(disabled_offers){
                var disabledOffers = []
                for(offerId in disabled_offers){
                    disabledOffers.push({label:disabled_offers[offerId].id.toString(),value:disabled_offers[offerId].id.toString()})
                }
                affiliateResponse = {...affiliateResponse,disabled_offers:disabledOffers}
            }
            setResponse(affiliateResponse)
        })
        .catch(async(error) => {
            console.log(error)
            if (error.response) {
                // Request made and server responded
                console.log(error.response.status);
                console.log(error.response.headers);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        });
        setButtonLoader(false);
    }

    if(!manualUseEffect){
        setManualUseEffect(true);
        if(!affiliateId){
            getRequests(getManager(),setManagerOptions)
            getRequests(getAdvertisers(),setAdvertiserOptions)
            getTags();
        }
        if(affiliateId){
            getAdvertiserDetails()
            getPaymentSystems()
        }
    }

    var formDataGeneral = [
        {
            label:'General',
            width:'75%',
            child:[
                {label:'Affiliate Company',value:'company',type:'textfield',mandatory:true,validation:'companyName'},
                {label:'Manager',value:'manager_name',type:'select',disabled:true,mandatory:false,options:managerOptions,validation:'firstName'},
                {label:'Email',value:'email',type:'textfield',mandatory:true,disabled:(affiliateId ? true : false)},
                {label:'Password',value:'password',type:'textfield',mandatory:true,hide:(affiliateId ? true : false)},
                {label:'Status',value:'affiliate_status',type:'select',disabled:true,mandatory:false,options:Object.keys(statusDict).map((item,index)=>{return {label:statusDict[item],value:item}})},
                {label:'Contact Person Name',value:'contact_person',type:'textfield',mandatory:true},
                {label:'Contact Person Number',value:'contact_number',type:'textfield'},
                {label:'Skype',value:'skype_id',type:'textfield',validation:'skype'},
                {label:'Commission Percentage*',value:'commision_percenatge',type:'textfield',validation:'commision_percenatge'},
            ]
        },
        {
            label:'Note And Tags',
            width:'25%',
            child:[
                {label:'Note',hideLabel:true,value:'note',type:'textarea'},
                {label:'Tags',hideLabel:true,fullWidth:true,isMulti:true,value:'tags',type:'select',options:tagOptions,isCreatable:true},
            ]
        },
        {
            label:'Affiliate Address',
            width:'75%',
            child:[
                {label:'Address 1',value:'address1',type:'textfield'},
                {label:'Address 2',value:'address2',type:'textfield'},
                {label:'City',value:'city',type:'textfield'},
                {label:'Country',value:'country',type:'select',options:Constants.COUNTRIES_LIST.map((item,index)=>{return {label:item,value:item}})},
                {label:'Zipcode',value:'zipcode',type:'textfield'},
            ]
        },

    ]

    const enableOfferTypeComponent = (childItem) => {
        var offerTypes = [
            {label:'Public',value:'public'},
            {label:'Private',value:'private'},
            {label:'Request',value:'protected'},
        ]
        return <div className='addEditAdvertiserPageCustom' style={{display:'flex',alignItems:'center',border:'none'}}>
            {offerTypes.map((item,index)=>{
                return <div key={index} style={{display:'flex',alignItems:'center',marginRight:'10px'}}>
                            <input type='checkbox' id={item.value} checked={(response[childItem.value] && response[childItem.value].includes(item.value)) ? false : true} onChange={(e)=>{var tempResponse=response;var tempData = tempResponse[childItem.value] ? tempResponse[childItem.value] : []; if(tempData.includes(item.value)){tempResponse[childItem.value] = tempResponse[childItem.value].filter(e => e!==item.value)}else{tempResponse[childItem.value] = [...tempData,item.value]}; setResponse(tempResponse); setChangesDone({...changesDone,offer_filteration:true});refreshComponent(123);}}/>
                            <label htmlFor={item.value}>{item.label}</label>
                        </div>
            })}
        </div>
    }

    const changePostbackData = (value,key,item,index) => {
        if(value.includes(" ")){
            value = value.replaceAll(" ","")
        }
        var tempResponse = response;
        tempResponse['event_postback'][index][key] = value
        if(!tempResponse['event_postback'][index].action){
            tempResponse['event_postback'][index].action = 'edit'
        }
        setResponse(tempResponse)
        setChangesDone({...changesDone,event_postback:true})
        refreshComponent(1)
    }
    
    const eventPostbackComponent = (childItem) => {
        var emptyRowAdd = {
            "id": "",
            "postback_url": '',
            "offer_id": '',
            "goal_value": '',
            "payout_status": 'approved',
            "affiliate_id": '',
            'action':'add',
        }
        var addButton = <div onClick={()=>{var tempData = response[childItem.value] ? response[childItem.value] : [] ;setResponse({...response,[childItem.value]:[...tempData,emptyRowAdd]});}} 
                            style={{width:'20px',height:'17px',color:localStorage.getItem('ambientColor'),fontSize:'15px',paddingBottom:'3px',display:'flex',alignItems:'center',justifyContent:'center',border:'1px solid '+localStorage.getItem('ambientColor'),cursor:'pointer'}}>
                            +
                        </div>
        return <div className='addEditAdvertiserPageCustom' style={{display:'flex',alignItems:'flex-start',border:'none',flexDirection:'column',justifyContent:'flex-start',height:'fit-content'}}>
            {response[childItem.value] && response[childItem.value].length>0 &&
                <table style={{width:'100%'}}>
                    <thead>
                        <tr>
                            <td width={'15%'}>Offer ID</td>
                            <td width={'40%'}>URL</td>
                            <td width={'15%'}>Status</td>
                            <td width={'13%'}>Goal Title</td>
                            <td width={'11%'}>Goal Value</td>
                            <td width={'1%'}></td>
                        </tr>
                    </thead>
                    <tbody>
                        {response[childItem.value].map((item,index)=>{
                            if(item.action!=='delete'){
                                return <tr key={index}>
                                    <td>
                                        <CreatableSelect
                                            value={{label:item.offer_id,value:item.offer_id}}
                                            onChange={(e)=>{changePostbackData(e.value,'offer_id',item,index);if(!goalTitleValueMapping[e.value]){getGoalList(e.value+" ")}}}
                                            menuPortalTarget={document.body} 
                                            placeholder={"Offer Id"}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 1000000 }),
                                                        control: (base,state) => ({
                                                            ...base,
                                                            // maxHeight: 100,
                                                            minHeight: 30,
                                                            transform:'scale(0.9) translate(-7%)',
                                                            color:'rgb(200,200,200)',
                                                            overflowY: "auto",
                                                            fontSize:12,
                                                            border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                            boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                                            '&:hover': {
                                                                border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                                boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                                            }
                                                        }),
                                                        placeholder: (defaultStyles) => {
                                                            return {
                                                                ...defaultStyles,
                                                                color: 'rgb(150,150,150)',
                                                                marginTop:'-2px'
                                                            }
                                                        },
                                                        option: (styles, {isFocused, isSelected}) => ({
                                                            ...styles,
                                                            fontSize:'11px',
                                                            background: isFocused
                                                                ? localStorage.getItem('ambientColor')
                                                                : isSelected
                                                                    ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                    : undefined,
                                                            zIndex: 1
                                                        }),
                                                    }}
                                        />
                                    </td>
                                    <td>
                                        <Constants.CssTextField size='small' placeholder='Postback URL' value={item.postback_url} onChange={(e)=>{changePostbackData(e.target.value,'postback_url',item,index)}} fullWidth sx={{transform:'scale(0.9) translateX(-5%)'}}/>
                                    </td>
                                    <td>
                                        <ReactSelect
                                            value={{label:capitalize(item.payout_status), value:item.payout_status}}
                                            options={[{label:'Any',value:'any'},{label:'Approved',value:'approved'},{label:'Declined',value:'declined'}]}
                                            onChange={(e)=>{changePostbackData(e.value,'payout_status',item,index)}}
                                            menuPortalTarget={document.body} 
                                            placeholder={"Status"}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 1000000 }),
                                                        control: (base,state) => ({
                                                            ...base,
                                                            // maxHeight: 100,
                                                            minHeight: 30,
                                                            transform:'scale(0.9) translate(-7%)',
                                                            color:'rgb(200,200,200)',
                                                            overflowY: "auto",
                                                            fontSize:12,
                                                            border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                            boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                                            '&:hover': {
                                                                border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                                boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                                            }
                                                        }),
                                                        placeholder: (defaultStyles) => {
                                                            return {
                                                                ...defaultStyles,
                                                                color: 'rgb(150,150,150)',
                                                                marginTop:'-2px'
                                                            }
                                                        },
                                                        option: (styles, {isFocused, isSelected}) => ({
                                                            ...styles,
                                                            fontSize:'11px',
                                                            background: isFocused
                                                                ? localStorage.getItem('ambientColor')
                                                                : isSelected
                                                                    ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                    : undefined,
                                                            zIndex: 1
                                                        }),
                                                    }}
                                            />
                                    </td>
                                    <td>
                                        <ReactSelect
                                            value={{label:goalTitleValueMapping[item.offer_id] ? goalTitleValueMapping[item.offer_id][item.goal_value] : capitalize(item.goal_value), value:item.goal_value}}
                                            options={goalTitleValueMapping[item.offer_id] ? Object.keys(goalTitleValueMapping[item.offer_id]).map((goalItem,goalIndex)=>{return {label:goalTitleValueMapping[item.offer_id][goalItem],value:goalItem}}) : [{label:'Any',value:'any'}]}
                                            onChange={(e)=>{changePostbackData(e.value,'goal_value',item,index)}}
                                            menuPortalTarget={document.body} 
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 1000000 }),
                                                        control: (base,state) => ({
                                                            ...base,
                                                            // maxHeight: 100,
                                                            minHeight: 30,
                                                            transform:'scale(0.9) translate(-7%)',
                                                            color:'rgb(200,200,200)',
                                                            overflowY: "auto",
                                                            fontSize:12,
                                                            border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                            boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                                            '&:hover': {
                                                                border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                                boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                                            }
                                                        }),
                                                        placeholder: (defaultStyles) => {
                                                            return {
                                                                ...defaultStyles,
                                                                color: 'rgb(150,150,150)',
                                                                marginTop:'-2px'
                                                            }
                                                        },
                                                        option: (styles, {isFocused, isSelected}) => ({
                                                            ...styles,
                                                            fontSize:'11px',
                                                            background: isFocused
                                                                ? localStorage.getItem('ambientColor')
                                                                : isSelected
                                                                    ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                    : undefined,
                                                            zIndex: 1
                                                        }),
                                                    }}
                                            />
                                    </td>
                                    <td>
                                        <Constants.CssTextField size='small' disabled value={item.goal_value} fullWidth sx={{transform:'scale(0.9) translateX(-5%)'}}/>
                                    </td>
                                    <td style={{position:'relative',textAlign:'center',}} onClick={()=>{changePostbackData('delete','action',item,index);}}>
                                        <DeleteIcon sx={{position:'absolute',fontSize:"15px",marginTop:'-10px',marginLeft:'10px',color:'rgb(150,150,150)','&:hover':{color:'black'}}}/>
                                    </td>
                                </tr>
                            }
                            return null;
                        })}
                    </tbody>
                </table>
            }
            {addButton}
        </div>
    }

    const [generateButtonLoader, setGenerateButtonLoader] = useState(false)
    const handleGenerateApi = async() => {
        setGenerateButtonLoader(true)
        var url_end = "change"
        if (!response.api_key){
          url_end = "add"
        }
        await axios({
            method: 'POST',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"apikey/",url_end),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":"application/json",
            },
            data:{
                "affiliate_id" : affiliateId, 
                }
        })
        .then((resp)=>{
            setResponse({...response,api_key:resp.data.result.api_key})
        })
        .catch(function (error) {
          
        });
        setGenerateButtonLoader(false)
    }
    const apiKeyComponent = (childItem) => {
        if(affiliateId){
            return <div style={{display:'flex',width:'100%',position:'relative',marginLeft:'calc(5% + 3px)'}}>
                        <input id={childItem.value} 
                                className={(childItem.mandatory && !response[childItem.value]) || (childItem.mandatory && validationFields[childItem.value]===false) || (!childItem.mandatory && response[childItem.value] && validationFields[childItem.value]===false) ? 'addEditAdvertiserPageTextField invalidAddEditAdvertiserPageTextField' : 'addEditAdvertiserPageTextField'} 
                                style={ !childItem.hideLabel ? {} : {width:'100%'}} 
                                disabled={childItem.disabled || buttonLoader} value={response[childItem.value] ? response[childItem.value] : ''} onChange={(e)=>{checkValidation(childItem.value,e.target.value);setResponse({...response,[childItem.value]:e.target.value})}}/>
                        
                            <Button sx={{...Constants.OUTLINEDBUTTON,transform:'scale(0.7)',color:localStorage.getItem('ambientColor')}} onClick={handleGenerateApi}>
                                {generateButtonLoader &&
                                <CircularProgress sx={{color:localStorage.getItem('ambientColor')}} size={16}/>
                                }
                                {!generateButtonLoader && response.api_key && "Change"}
                                {!generateButtonLoader && !response.api_key && "Generate"}
                            </Button>
                        
                    </div>
        }
    }
    const [subIdSelected, setSubIdSelected] = useState([])

    const getSubs = () => {
        if(subIdSelected.length>0){
            return <div>
                        {subIdSelected.map((item,index)=>{
                                return <div key={index}>
                                    <div style={{display:'flex',alignItems:'center',marginBottom:'5px'}}>
                                    <ReactSelect
                                        options={[{label:'Enabled',value:'enabled'},{label:'Disabled',value:'disabled'}]}
                                        value={{label:capitalize(item.type),value:item.type}}
                                        onChange={(e)=>{
                                                        var tempSubIdSelected = subIdSelected;
                                                        tempSubIdSelected[index].type = e.value;
                                                        setSubIdSelected(tempSubIdSelected)
                                                        refreshComponent(e);
                                                            }}
                                        menuPortalTarget={document.body} 
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                    control: (base,state) => ({
                                                        ...base,
                                                        minWidth:120,
                                                        marginRight:10,
                                                        // maxHeight: 100,
                                                        minHeight: 30,
                                                        fontSize:'10px',
                                                        // transform:'scale(0.8) translateX(-11.5%)',
                                                        overflowY: "auto",
                                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                        boxShadow: 0,
                                                        '&:hover': {
                                                            border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                            boxShadow: 0,
                                                        }
                                                    }),
                                                    option: (styles, {isFocused, isSelected}) => ({
                                                        ...styles,
                                                        fontSize:'11px',
                                                        background: isFocused
                                                            ? localStorage.getItem('ambientColor')
                                                            : isSelected
                                                                ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                : undefined,
                                                        zIndex: 1
                                                    }),
                                                }}
                                            />
                                        <ReactSelect
                                            options={[{label:'sub1',value:'sub1'},{label:'sub2',value:'sub2'},{label:'sub3',value:'sub3'},{label:'sub4',value:'sub4'},{label:'sub5',value:'sub5'},{label:'sub6',value:'sub6'},{label:'sub7',value:'sub7'},{label:'sub8',value:'sub8'}]}
                                            value={{label:item.field,value:item.field}}
                                            onChange={(e)=>{
                                                            var tempSubIdSelected = subIdSelected;
                                                            tempSubIdSelected[index].field = e.value;
                                                            setSubIdSelected(tempSubIdSelected)
                                                            refreshComponent(e);
                                                                }}
                                            menuPortalTarget={document.body} 
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                        control: (base,state) => ({
                                                            ...base,
                                                            minWidth:100,
                                                            // maxHeight: 100,
                                                            minHeight: 30,
                                                            fontSize:'10px',
                                                            // transform:'scale(0.8) translateX(-11.5%)',
                                                            overflowY: "auto",
                                                            border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                            boxShadow: 0,
                                                            '&:hover': {
                                                                border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                                boxShadow: 0,
                                                            }
                                                        }),
                                                        option: (styles, {isFocused, isSelected}) => ({
                                                            ...styles,
                                                            fontSize:'11px',
                                                            background: isFocused
                                                                ? localStorage.getItem('ambientColor')
                                                                : isSelected
                                                                    ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                    : undefined,
                                                            zIndex: 1
                                                        }),
                                                    }}
                                            />
                                        <Constants.CssTextField value={item.value} placeholder="value" size='small' sx={{marginLeft:'10px','& .MuiOutlinedInput-root':{fontSize:'13px'}}} onChange={(e)=>{var tempSubIdSelected = subIdSelected;
                                                            tempSubIdSelected[index].value = e.target.value;
                                                            setSubIdSelected(tempSubIdSelected)
                                                            refreshComponent(e);
                                                            }}/>
                                        <DeleteIcon className="payoutIconsV2" sx={{fontSize:"18px",marginLeft:'10px',color:'rgb(150,150,150)','&:hover':{color:'black'}}} onClick={(e)=>{var tempSubIdSelected = subIdSelected;tempSubIdSelected.splice(index,1);setSubIdSelected(tempSubIdSelected);refreshComponent(e);}}/>
                                        
                                    </div>
                                    {index===subIdSelected.length-1 &&
                                        <div className='addButtonV2' onClick={(e)=>{
                                                        setSubIdSelected([...subIdSelected,{
                                                            "type": 'enabled',
                                                            "field": 'sub1',
                                                            "value": "",
                                                            }]);
                                                            refreshComponent(e);
                                                        }}>
                                        +
                                        </div>
                                    }
                                </div>
                            })
                        }
                </div>
        }
        else{
            return <div className='addButtonV2' onClick={(e)=>{
                                    setSubIdSelected([...subIdSelected,{
                                        "type": 'enabled',
                                        "field": 'sub1',
                                        "value": "",
                                        }]);
                                    refreshComponent(e);
                                    }}>
                    +
                    </div>
        }
    }
    var formDataSecurity = [
        {
            label:'Affiliate Password And Permissions',
            width:'60%',
            child:[
                {label:'Affiliate Account Password',value:'password',type:'textfield',mandatory:false,hide:(affiliateId ? false : true)},
                {label:'Disabled Advertisers',value:'disabled_advertisers',type:'select',isMulti:true,disabled:true,mandatory:false,options:advertiserOptions},
                {label:'Enabled Offer Type',value:'enable_offer_type',customComponent:enableOfferTypeComponent},      
                {label:'Enabled Offers',value:'enabled_offers',type:'select',isMulti:true,disabled:false,isCreatable:true},      
                {label:'Disabled Offers',value:'disabled_offers',type:'select',isMulti:true,disabled:false,isCreatable:true},      
                {label:'Cutback',value:'cutback_value',type:'textfield',hide:(affiliateId ? false : true)},      
                {label:'Api Key',value:'api_key',disabled:true,customComponent:apiKeyComponent,hide:(affiliateId ? false : true)},      
            ]
        },

        {
            label:'Sub',
            width:'40%',
            child:[
                {label:'',hideLabel:true,value:'sub_id',customComponent:getSubs},
            ]
        },
        {
            label:'Postbacks',
            width:'100%',
            child:[
                {label:'Global Postback',value:'global_postback',type:'textfield',mandatory:false},
                {label:'Event Postback',value:'event_postback',customComponent:eventPostbackComponent},
            ]
        },

    ]
    const changeDisabledOfferTypes = async(temp_affiliate_id) => {
        return await axios({
            method: 'POST',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"affiliate/offerfilteration"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":"application/json",
            },
            data:{
              affiliate_id:temp_affiliate_id,
              offerfilter: response.enable_offer_type ? response.enable_offer_type : [],
            }
        })
        .then((resp) => {
            return resp.data
        })
        .catch(error => {
            console.error(error);
            if(error.response && error.response.data){
                Store.addNotification({
                    title: "Error!",
                    message: typeof error.response.data.result === "string" ? error.response.data.result : "Some error occured",
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                });
            }
            return {error:error}
        });
    }
    const updateEventPostbacks = async(affiliate_id) => {
        var eventPostbacks = response.event_postback
        var updatePostbacks = []
        for(var index in eventPostbacks){
            var item = eventPostbacks[index];
            if(item.action && item.offer_id && ((item.action==='delete' && item.id) || item.action!=='delete')){
                if(!item.affiliate_id){
                    item.affiliate_id = affiliate_id
                }
                updatePostbacks.push(item)
            }
        }

        return await axios({
            method: 'POST',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"eventpostbacks/update"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-type":'application/json'
            },
            data:updatePostbacks
        })
        .then(resp => {
            return resp.data
        })
        .catch(err => {
            console.error(err);
            if(err.response && err.response.data.result){
                Store.addNotification({
                    title: "Error!",
                    message: err.response.data.result,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true
                    }
                  });
            }
            return false
        }) 
    };  

    const updateGlobalPostbacks = async(affiliate_id) => {
        var globalPostback = response.global_postback
        if(globalPostback){
            var url_end = 'change'
            if(globalPostbackFound){
                url_end = 'add'
            }
            return await axios({
                method: 'POST',
                url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"postbacks/",url_end),
                headers:{
                    Authorization:getLocalStorageValue('adminPanelLoginData').token,
                    "Content-Type":"application/json",
                },
                data:{
                    "affiliate_id" : affiliate_id, 
                    "postback_url" : globalPostback
                    }
                })
            .then(resp => {
                setGlobalPostbackFound(false)
                return resp.data
            })
            .catch(err => {
                console.error(err);
                if(err.response && err.response.data.result){
                    Store.addNotification({
                        title: "Error!",
                        message: err.response.data.result,
                        type: "warning",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 2000,
                          onScreen: true
                        }
                      });
                }
                return false
            }) 
        }
        else{
            return {result:true}
        }
    };  
    const changePassword = async() => {
        if(response.password){
            return await axios({
                method: 'POST',
                url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"affiliate/changepassword"),
                headers:{
                    Authorization:getLocalStorageValue('adminPanelLoginData').token,
                    "Content-Type":"application/json",
                },
                data:{
                    email : response.email,
                    new_password: response.password,
                }
            })
            .then((resp) => {
                return true
            })
            .catch(err => {
                if(err.response && err.response.data.result){
                    Store.addNotification({
                        title: "Error!",
                        message: err.response.data.result,
                        type: "warning",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 2000,
                          onScreen: true
                        }
                      });
                }
                return false
            });
        }
        else{
            return true;
        }
    }

    const changeDisableAdvertiser = async(temp_affiliate_id) => {
        var pendingEnableAdvertiser = []
        var pendingDisableAdvertiser = []

        var updatedDisabledAdvertiserList = []
        for(var index in response.disabled_advertisers){
            var advertiserId = response.disabled_advertisers[index].value
            updatedDisabledAdvertiserList.push(advertiserId)
        }
        var loopList = [...Object.keys(backupDisabledAdvertisers),...updatedDisabledAdvertiserList]

        for(index in loopList){
            var item = loopList[index]
            if(Object.keys(backupDisabledAdvertisers).includes(item) && !updatedDisabledAdvertiserList.includes(item)){
                if(!pendingEnableAdvertiser.includes(item)){
                    pendingEnableAdvertiser.push(item)
                }
            }
            else if(!Object.keys(backupDisabledAdvertisers).includes(item) && updatedDisabledAdvertiserList.includes(item)){
                if(!pendingDisableAdvertiser.includes(item)){
                    pendingDisableAdvertiser.push(item)
                }
            }
        }
        var statusDict = {
            enable : pendingEnableAdvertiser,
            disable : pendingDisableAdvertiser,
        }
        var statusResp = true;
        for(var status in statusDict){
            var tempAdvertiserIds = ""
            for(index in statusDict[status]){
                tempAdvertiserIds+=statusDict[status][index] + ","
            }
            tempAdvertiserIds = tempAdvertiserIds.slice(0,-1)
            if(tempAdvertiserIds && statusResp){
                statusResp = await axios({
                    method: 'POST',
                    url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"disabledadvertiser"),
                    headers:{
                        Authorization:getLocalStorageValue('adminPanelLoginData').token,
                        "Content-Type":"application/json",
                    },
                    data:{
                        affiliate_id:temp_affiliate_id,
                        advertiser_id:tempAdvertiserIds,
                        status:status
                    }
                })
                .then((resp) => {
                    var responseDict = {}
                    for(var index in response.disabled_advertisers){
                        responseDict[response.disabled_advertisers[index].value] = response.disabled_advertisers[index].label
                    }
                    setBackupDisabledAdvertisers(responseDict)
                    return true
                })
                .catch(err => {
                    console.error(err);
                    if(err.response && err.response.data.result){
                        Store.addNotification({
                            title: "Error!",
                            message: err.response.data.result,
                            type: "warning",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                              duration: 2000,
                              onScreen: true
                            }
                          });
                    }
                    return false
                });
            }
        }
        return statusResp
    }
    
    const changeEnabledOffers = async(temp_affiliate_id) => {
        var offer_ids = []
        if(response.enabled_offers && response.enabled_offers.length>0){
            offer_ids = response.enabled_offers.map((item,index)=> {return item.value})
        }
        return await axios({
            method: 'POST',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"offers/enabled/edit"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":"application/json",
            },
            data:{
                affiliate_id : temp_affiliate_id,
                offer_id : offer_ids
            }
        })
        .then((resp) => {
            return resp.data
        })
        .catch(err => {
            if(err.response && err.response.data.result){
                Store.addNotification({
                    title: "Error!",
                    message: err.response.data.result,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                    });
            }
            return false
        });
    }

    const changeDisabledOffers = async(temp_affiliate_id) => {
        var offer_ids = []
        if(response.disabled_offers && response.disabled_offers.length>0){
            offer_ids = response.disabled_offers.map((item,index)=> {return item.value})
        }
        return await axios({
            method: 'POST',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"offers/disabled/edit"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":"application/json",
            },
            data:{
                affiliate_id : temp_affiliate_id,
                offer_id : offer_ids
            }
        })
        .then((resp) => {
            return resp.data
        })
        .catch(err => {
            if(err.response && err.response.data.result){
                Store.addNotification({
                    title: "Error!",
                    message: err.response.data.result,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                    });
            }
            return false
        });
    }

    const [paymentSystem, setPaymentSystem] = useState([{}])

    const changePaymentSystem = async(temp_affiliate_id) => {
        var affiliate_payment_data = []
        var falseCheck = false
        for(var index in paymentSystem){
            var item = paymentSystem[index]
            if(item.title){
                for(var fieldIndex in item.fields){
                    var fieldItem = item.fields[fieldIndex]
                    if(fieldItem.required && !item.fieldsValue[fieldItem.title]){
                        Store.addNotification({
                            title: "Error!",
                            message: fieldItem.title + " is a mandatory field in payments sections.",
                            type: "warning",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 2000,
                                onScreen: true
                            }
                            });
                            falseCheck = true
                    }
                }
                affiliate_payment_data.push({...item,fields:item.fieldsValue})
            }
        }
        if(falseCheck){
            return {result:false}
        }
        if(temp_affiliate_id){
            return await axios({
                method: 'POST',
                url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"affiliate/payment/system/add"),
                headers:{
                    Authorization:getLocalStorageValue('adminPanelLoginData').token,
                    "Content-Type":"application/json",
                },
                data:{
                    "affiliate_payment_data": affiliate_payment_data,
                    "affiliate_id" : temp_affiliate_id
                }
            })
            .then((resp) => {
                return resp.data
            })
            .catch(err => {
                if(err.response && err.response.data.result){
                    Store.addNotification({
                        title: "Error!",
                        message: err.response.data.result,
                        type: "warning",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                        });
                }
                return false
            });
        }
        return {result:true}
    }

    const addEditAdvertiserRequest = async() => {
        // setButtonLoader(true)
        var addressList = ['address1','address2','city','country','zipcode']
        var address = {}
        for(var addressIndex in addressList){
            if(response[addressList[addressIndex]]){
                if(addressList[addressIndex]==='country'){
                    address[addressList[addressIndex]] = response[addressList[addressIndex]].value
                }
                else{
                    address[addressList[addressIndex]] = response[addressList[addressIndex]]
                }
            }
        }
        var url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.ADD_AFFILIATE)
        if(affiliateId){
            url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.AFFILIATE,"/",affiliateId,"/",Constants.CHANGE)
        }
        var tags = []
        for(var tagIndex in response.tags){
            tags.push(response.tags[tagIndex].value)
        }

        if(!affiliateId){
            var paymentSystemResult = await changePaymentSystem()
        }
        else{
            paymentSystemResult = {result:true}
        }
        if(paymentSystemResult.result){
            await axios({
                method: 'POST',
                url,
                headers:{
                    Authorization:getLocalStorageValue('adminPanelLoginData').token,
                    "Content-Type":"application/json",
                },
                data:{
                    "affiliate_status":response.affiliate_status ? response.affiliate_status.value : null,
                    "company_name":response.company,
                    "contact_person":response.contact_person ? response.contact_person : null,
                    "contact_number":response.contact_number ? response.contact_number : null,
                    "address": address,
                    "email": response.email ? response.email : null,
                    "manager_id": response.manager_name ? response.manager_name.value : null,
                    "note": response.note ? response.note : null,
                    "skype_id": response.skype_id ? response.skype_id : null,
                    "commision_percenatge": response.commision_percenatge ? parseFloat(response.commision_percenatge).toFixed(2) : null,
                    "tags": tags,  
                    password: affiliateId ? undefined : response.password,  
                    sub_id:subIdSelected ? subIdSelected : [],
                    cutback_value:response.cutback_value ? parseInt(response.cutback_value) : null,
    
                }})
            .then(async (resp)=>{
                    var responseString = 'added'
                    if(resp.data.result === 'affiliate already present'){
                        throw Object.assign(
                            new Error(resp.data.result),
                            { code: 401 }
                            );
                        
                    }
                    if(affiliateId){
                        var temp_affiliate_id = affiliateId
                        responseString = 'edited'
                        var changePasswordResp = await changePassword()
                    }
                    else{
                        changePasswordResp = true
                    }
                    if(resp.data.result.affiliate.id){
                        temp_affiliate_id = resp.data.result.affiliate.id
                    }
                    if(changePasswordResp && changesDone.offer_filteration){
                        var offerFilterationApi = await changeDisabledOfferTypes(temp_affiliate_id);
                    }
                    else{
                        offerFilterationApi = {result:true}
                    }
    
                    if(offerFilterationApi.result && changesDone.disabled_advertisers){
                        var disabledAdvertiser = await changeDisableAdvertiser(temp_affiliate_id);
                    }
                    else{
                        disabledAdvertiser = true
                    }
                    if(disabledAdvertiser && changesDone.event_postback){
                        var eventPostback = await updateEventPostbacks(temp_affiliate_id)
                    }
                    else{
                        eventPostback = {result:true}
                    }
    
                    if(eventPostback.result && changesDone.enabled_offers){
                        var enabledOffers = await changeEnabledOffers(temp_affiliate_id)
                    }
                    else{
                        enabledOffers = {result:true}
                    }
    
                    if(enabledOffers.result && changesDone.disabled_offers){
                        var disabledOffers = await changeDisabledOffers(temp_affiliate_id)
                    }
                    else{
                        disabledOffers = {result:true}
                    }
    
                    if(disabledOffers.result && changesDone.paymentSystem){
                        var paymentSystemResult = await changePaymentSystem(temp_affiliate_id)
                    }
                    else{
                        paymentSystemResult = {result:true}
                    }
    
                    if(paymentSystemResult.result && changesDone.global_postback){
                        var global_postback =  await updateGlobalPostbacks(temp_affiliate_id)
                    }
                    else{
                        global_postback = {result : true}
                    }
    
                    if(global_postback.result && paymentSystemResult.result && resp.data.result){
                        setChangesDone({})
                        if(resp.data.result.affiliate){
                            window.history.pushState('Edit Affiliate - '+temp_affiliate_id, 'Edit Affiliate - '+temp_affiliate_id, '/affiliates/management/edit/'+temp_affiliate_id);
                            setAffiliateId(resp.data.result.affiliate.id)
                            Store.addNotification({
                                title: "Success!",
                                message: "Affiliate "+responseString+" successfully.",
                                type: "success",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animate__animated", "animate__fadeIn"],
                                animationOut: ["animate__animated", "animate__fadeOut"],
                                dismiss: {
                                    duration: 3000,
                                    onScreen: true
                                }
                            });
                        }
                        else{
                            Store.addNotification({
                                title: "Error!",
                                message: typeof resp.data.result === "string" ? resp.data.result : "Some error occured",
                                type: "warning",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animate__animated", "animate__fadeIn"],
                                animationOut: ["animate__animated", "animate__fadeOut"],
                                dismiss: {
                                    duration: 3000,
                                    onScreen: true
                                }
                            });
                        }
                    }
    
                })
            .catch(function (error) {
                    console.log(error)
                    if (error.response) {
                        if(error.response.data){
                            Store.addNotification({
                                title: "Error!",
                                message: typeof error.response.data.result === "string" ? error.response.data.result : "Some error occured",
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animate__animated", "animate__fadeIn"],
                                animationOut: ["animate__animated", "animate__fadeOut"],
                                dismiss: {
                                    duration: 3000,
                                    onScreen: true
                                }
                            });
                        }
                        // Request made and server responded
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        Store.addNotification({
                            title: "Error!",
                            message: typeof error.message === "string" ? error.message : "Some error occured",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 3000,
                                onScreen: true
                            }
                        });
                    }
            });
        }
        setButtonLoader(false);

    };

    const [disableButton, setDisableButton] = useState(true)
    const [validationFields, setValidationFields] = useState({
        company : false,
        contact_person : false,
        email : false,
        cutback_value : true,
        contact_number : true,
        website : true,
        skype_id : true,
        password : affiliateId ? true : false,
        global_postback : true,
        commision_percenatge : false,
    })
    const checkValidation = (key,value,returnPlease) => {
        return Validations(key,value,returnPlease,validationFields,setValidationFields,setDisableButton)
    }
    const createContent = (e,childItem) => {
        if(childItem.value==='enabled_offers' || childItem.value==='disabled_offers'){
            var changePreviousKey = (childItem.value==='enabled_offers' ? 'disabled_offers' : 'enabled_offers')
            var tempDisabledAffiliates = response[changePreviousKey]
            if(e.length>0){
                for(var index in tempDisabledAffiliates){
                    var item = tempDisabledAffiliates[index]
                    if(e[e.length-1].value === item.value){
                        tempDisabledAffiliates.splice(index,1)
                    }
                }  
            }
            setResponse({...response,[childItem.value]:e,[tempDisabledAffiliates]:tempDisabledAffiliates})

        }
        else{
            setResponse({...response,[childItem.value]:e})
        }
    }
    const getFormData = (formData) => {
        return formData.map((item,index)=>{
            return <div key={index} className='advertiserPageDataWrapperMobile' style={{width:item.width}}>
                        <div className='advertiserPageDataWrapper'>
                            <div className='advertiserPageDataHeader'>{item.label}</div>
                            <div className='advertiserPageGeneralDataContainer'>
                                {item.child.map((childItem,childIndex) => {
                                    if(!childItem.hide){
                                        return <div className='advertiserPageGeneralDataWrapper' key={childIndex}>
                                                {!childItem.hideLabel && <div className='addEditAdvertiserPageLabel'>{childItem.label}{childItem.mandatory ? '*' : ''}</div>}
                                                {childItem.type==='textfield' && 
                                                    <input id={childItem.value} className={(childItem.mandatory && !response[childItem.value]) || (childItem.mandatory && validationFields[childItem.value]===false) || (!childItem.mandatory && response[childItem.value] && validationFields[childItem.value]===false) ? 'addEditAdvertiserPageTextField invalidAddEditAdvertiserPageTextField' : 'addEditAdvertiserPageTextField'} style={ !childItem.hideLabel ? {} : {width:'100%'}} disabled={childItem.disabled || buttonLoader} value={response[childItem.value] ? response[childItem.value] : ''} onChange={(e)=>{checkValidation(childItem.value,e.target.value);setResponse({...response,[childItem.value]:e.target.value});setChangesDone({...changesDone,[childItem.value]:true});}}/>
                                                }
                                                {childItem.type==='select' && 
                                                    <div id={childItem.value} className='addEditAdvertiserPageSelectField' style={{width:(childItem.fullWidth ? '91%' : '66.5%')}}>
                                                        {childItem.isCreatable && 
                                                        <CreatableSelect
                                                            isDisabled={buttonLoader}
                                                            options={childItem.options}
                                                            isMulti={childItem.isMulti}
                                                            value={response[childItem.value]}
                                                            onChange={(e)=>{createContent(e,childItem);setChangesDone({...changesDone,[childItem.value]:true})}}
                                                            menuPortalTarget={document.body} 
                                                            placeholder={"Select "+childItem.label}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 1000000 }),
                                                                        control: (base,state) => ({
                                                                            ...base,
                                                                            // maxHeight: 100,
                                                                            minHeight: 30,
                                                                            color:'rgb(200,200,200)',
                                                                            overflowY: "auto",
                                                                            fontSize:12,
                                                                            border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                                            boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                                                            '&:hover': {
                                                                                border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                                                boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                                                            }
                                                                        }),
                                                                        placeholder: (defaultStyles) => {
                                                                            return {
                                                                                ...defaultStyles,
                                                                                color: 'rgb(150,150,150)',
                                                                                marginTop:'-2px'
                                                                            }
                                                                        },
                                                                        option: (styles, {isFocused, isSelected}) => ({
                                                                            ...styles,
                                                                            fontSize:'11px',
                                                                            background: isFocused
                                                                                ? localStorage.getItem('ambientColor')
                                                                                : isSelected
                                                                                    ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                                    : undefined,
                                                                            zIndex: 1
                                                                        }),
                                                                    }}
                                                        />
                                                        }
                                                        {!childItem.isCreatable && 
                                                            <ReactSelect
                                                                isDisabled={buttonLoader}
                                                                options={childItem.options}
                                                                isMulti={childItem.isMulti}
                                                                value={response[childItem.value]}
                                                                onChange={(e)=>{setResponse({...response,[childItem.value]:e});setChangesDone({...changesDone,[childItem.value]:true})}}
                                                                menuPortalTarget={document.body} 
                                                                placeholder={"Select "+childItem.label}
                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 1000000 }),
                                                                            control: (base,state) => ({
                                                                                ...base,
                                                                                // maxHeight: 100,
                                                                                minHeight: 30,
                                                                                color:'rgb(200,200,200)',
                                                                                overflowY: "auto",
                                                                                fontSize:12,
                                                                                border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                                                boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                                                                '&:hover': {
                                                                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                                                    boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                                                                }
                                                                            }),
                                                                            placeholder: (defaultStyles) => {
                                                                                return {
                                                                                    ...defaultStyles,
                                                                                    color: 'rgb(150,150,150)',
                                                                                    marginTop:'-2px'
                                                                                }
                                                                            },
                                                                            option: (styles, {isFocused, isSelected}) => ({
                                                                                ...styles,
                                                                                fontSize:'11px',
                                                                                background: isFocused
                                                                                    ? localStorage.getItem('ambientColor')
                                                                                    : isSelected
                                                                                        ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                                        : undefined,
                                                                                zIndex: 1
                                                                            }),
                                                                        }}
                                                            />}
                                                    </div>
                                                }
                                                {childItem.type==='textarea' && 
                                                    <textarea placeholder={childItem.label} id={childItem.value} className='addEditAdvertiserPageTextField' style={ !childItem.hideLabel ? {height:'200px'} : {width:'99%',height:'200px'}} value={response[childItem.value] ? response[childItem.value] : ''} onChange={(e)=>{setResponse({...response,[childItem.value]:e.target.value})}} disabled={childItem.disabled}/>
                                                }
                                                {childItem.copyButton &&
                                                    <div style={{position:'absolute',right:'10px',transform:'scale(0.8)',display:'flex',alignItems:'center'}}>
                                                        <CopyButton idToCopy={childItem.value}/>
                                                        {childItem.info &&
                                                            <div onMouseEnter={()=>{setInfoHovered(true);}} onMouseLeave={()=>{setInfoHovered(false)}}>
                                                                <InfoOutlinedIcon sx={{color:'rgb(100,100,100)'}}/>
                                                                {infoHovered && 
                                                                    <div style={{position:'absolute',width:'100px',padding:'10px',background:'white',textAlign:'center',boxShadow:'0 0 5px 1px rgb(200,200,200)'}} onMouseEnter={()=>{setInfoHovered(true);}}>
                                                                        <div style={{marginBottom:'5px'}}>Params: </div>
                                                                        {childItem.info.map((infoItem,infoIndex)=>{
                                                                            return <div style={{fontSize:'14px'}} key={infoIndex}>
                                                                                {infoItem}
                                                                                </div>
                                                                        })}
                                                                    </div>}
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {childItem.customComponent && childItem.customComponent(childItem)}
                                            </div>
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
                    </div>
        })
    }
    const [infoHovered, setInfoHovered] = useState(false)
    const [activeTab, setActiveTab] = useState('general')
    const paymentData = () => {
        var panelSettings = getLocalStorageValue('adminPanelLoginData').panelSettings ? getLocalStorageValue('adminPanelLoginData').panelSettings : []
        if(panelSettings.paymentOptions){
            var paymentOptions = panelSettings.paymentOptions
            paymentOptions = paymentOptions.map((item,index)=>{
                return {label:item.title + ' (' +item.currency+')',value:index}
            })
        }
        return <div style={{width:'100%',minHeight:'65vh'}} onClick={()=>{setChangesDone({...changesDone,paymentSystem:true})}}>
                    <br/>
                    {paymentSystem.map((paymentItem,paymentIndex)=>{
                        return <div key={paymentIndex} style={{borderBottom:'1px solid rgb(220,220,220)',paddingBottom:'20px',marginLeft:'10%',marginRight:'10%',marginBottom:'20px',position:'relative'}}>
                            <div style={{display:'flex',justifyContent:'flex-end'}}>
                                <div style={{cursor:'pointer'}} onClick={()=>{if(paymentSystem.length===1){setPaymentSystem([{}])} else{var tempPaymentSystem = paymentSystem; tempPaymentSystem.splice(paymentIndex,1); setPaymentSystem(tempPaymentSystem)}; refreshComponent(12);}}>
                                    X
                                </div>
                            </div>
                            <div style={{display:'flex',alignItems:'center',width:'100%'}}>
                                <div style={{width:'30%'}}>Payment System : </div>
                                <div style={{width:'63%'}}>
                                    <ReactSelect
                                        isDisabled={buttonLoader}
                                        options={paymentOptions}
                                        isClearable
                                        value={paymentItem.title ? {label:paymentItem.title + ' (' +paymentItem.currency+')',value:paymentItem.paymentOptionsIndex} : false}
                                        onChange={(e)=>{var tempPaymentSystem = paymentSystem; tempPaymentSystem[paymentIndex] = {...panelSettings.paymentOptions[e.value],fieldsValue:{}}; setPaymentSystem(tempPaymentSystem); refreshComponent(12);}}
                                        menuPortalTarget={document.body} 
                                        placeholder={"Select Payment System..."}
                                        styles={{
                                                    menuPortal: base => ({ ...base, zIndex: 1000000 }),
                                                    control: (base,state) => ({
                                                        ...base,
                                                        // maxHeight: 100,
                                                        minHeight: 30,
                                                        color:'rgb(200,200,200)',
                                                        overflowY: "auto",
                                                        fontSize:12,
                                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                        boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                                        '&:hover': {
                                                            border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                            boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                                        }
                                                    }),
                                                    placeholder: (defaultStyles) => {
                                                        return {
                                                            ...defaultStyles,
                                                            color: 'rgb(150,150,150)',
                                                            marginTop:'-2px'
                                                        }
                                                    },
                                                    option: (styles, {isFocused, isSelected}) => ({
                                                        ...styles,
                                                        fontSize:'11px',
                                                        background: isFocused
                                                            ? localStorage.getItem('ambientColor')
                                                            : isSelected
                                                                ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                                : undefined,
                                                        zIndex: 1
                                                    }),
                                                }}
                                    />
                                </div>
                            </div>
                            {
                                paymentItem.fields && paymentItem.fields.map((item,index)=>{
                                    return <div key={index} style={{display:'flex',alignItems:'center',width:'100%',marginTop:'10px'}}>
                                                <div  style={{width:'30%',wordBreak:'break-all'}}>{item.title}{item.required ? '*' : ''} : </div>
                                                <div style={{width:'70%'}}>
                                                    <Constants.CssTextField size='small' value={paymentItem.fieldsValue[item.title]} fullWidth placeholder={'Enter ' + item.title} sx={{transform:'scale(0.9)',transformOrigin:'left'}} onChange={(e)=>{var tempPaymentSystem = paymentSystem; tempPaymentSystem[paymentIndex]['fieldsValue'][item.title] = e.target.value;setPaymentSystem(tempPaymentSystem); refreshComponent(12);}}/>
                                                </div>
                                            </div>
                                })
                            }
                        </div>
                    })}
                    <Button sx={{...Constants.CONTAINEDBUTTON,marginLeft:'10%',transform:'scale(0.8)',transformOrigin:'left',textTransform:'capitalize'}} onClick={()=>{setPaymentSystem([...paymentSystem,{}])}}> + Add Payment System</Button>
                </div>
    }

    return (
        <div className='advertiserPageV2'>
            <div style={{fontSize:'18px',fontWeight:600,display:'flex',alignItems:'center'}}>
                {affiliateId ? 'Edit ' : 'Add '} Affiliate
                {buttonLoader && <CircularProgress sx={{color:localStorage.getItem('ambientColor'),marginLeft:'20px'}} size={17}/>}
            </div>
            <div className='advertiserPageV2TabsContainer'>
                <div className='advertiserPageV2Tabs' style={{color:(activeTab==='general' ? localStorage.getItem('ambientColor') : 'black')}} onClick={()=>{setActiveTab('general')}}>General</div>
                <div className='advertiserPageV2Tabs' style={{color:(activeTab==='security' ? localStorage.getItem('ambientColor') : 'black')}} onClick={()=>{setActiveTab('security')}}>Security</div>
                <div className='advertiserPageV2Tabs' style={{color:(activeTab==='payments' ? localStorage.getItem('ambientColor') : 'black')}} onClick={()=>{setActiveTab('payments')}}>Payments</div>
            </div>
            
            <div className='advertiserPageDataContainer'>
                {
                    activeTab==='general' && getFormData(formDataGeneral)
                }
                {
                    activeTab==='security' && getFormData(formDataSecurity)
                }
                {
                    activeTab==='payments' && paymentData()
                }
            </div>
            <div className='advertiserPageButtonPallete'>
                <Button disabled={disableButton || buttonLoader} sx={{...Constants.CONTAINEDBUTTON,fontSize:'15px',paddingLeft:'15px',paddingRight:'15px',textTransform:'none',fontWeight:500,marginLeft:'15px',width:'40px',height:'30px'}} onClick={addEditAdvertiserRequest}>
                    {!buttonLoader && (affiliateId ? 'Edit' : 'Add')}
                    {buttonLoader && <CircularProgress sx={{color:'white'}} size={18}/>}
                </Button>
                <NavLink to={'/affiliates/management'} style={{textDecoration:'none'}}>
                    <Button sx={{...Constants.OUTLINEDBUTTON,fontSize:'15px',paddingLeft:'15px',paddingRight:'15px',textTransform:'none',fontWeight:500,marginLeft:'15px',height:'30px'}}>Cancel</Button>
                </NavLink>
            </div>
        </div>
    )
}

export default NewEditAffiliatePageV2