import React, { useEffect } from "react";
import "../../css/arrangeColumns.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ListItem from "./ListItem";

function useForceUpdate(){
    const [value, setValue] = React.useState(0); // integer state
    if(false){
        console.log(value)
    }
    return () => setValue(value => value + 1); // update the state to force render
}

const ArrangeColumns = props => {
    const refreshComponent = useForceUpdate()

    var [items, setItems] = React.useState(props.editColumnsList);

    const onDragEnd = (result) => {
        
        const newItems = Array.from(items);
        const [removed] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, removed);
        setItems(newItems);
        props.setEditColumnsList(newItems)
    };

    const changeItemShow = (e,item,index) => {
        if(!items[index]['show']){
            if(props.setReloadPage){
                props.setReloadPage(true);
            }
        }
        items[index]['show'] = !items[index]['show']
        refreshComponent(e)
    }
    useEffect(() => {
        document.addEventListener('keydown',(e)=>{
            if(e.key==='Escape'){props.handleClose()}
        })
        
        return () => {
            document.removeEventListener('keydown',(e)=>{
                if(e.key==='Escape'){props.handleClose()}
            })
        }
    }, [props])
    return (
        <div className="PopupColumncontainer">
            <div className="wrapper">
                <div style={{display:"flex",height:"8%",alignItems:'center'}}>
                    <div style={{width:"95%",display:'flex',justifyContent:'flex-start',paddingLeft:"2%",fontSize:"15px",fontWeight:"500"}}>Table Settings - Arrange Columns</div>
                    <div style={{width:"5%",display:'flex',justifyContent:'center',alignItems:"center"}} className="closeIcon" onClick={props.handleClose}>X</div>
                </div>
                <div style={{borderBottom:"0.001px solid rgb(150,150,150)",marginLeft:"2%",marginRight:"7%"}}></div>
                <div style={{width:"100%",height:"85%",overflowY:"auto"}}>
                    <div style={{padding:"10px",paddingTop:"3%",paddingLeft:"5%",width:"33%"}}>
                        
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {items.map((item, index) => (
                                        !item.fixed &&
                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <ListItem
                                                        provided={provided}
                                                        snapshot={snapshot}
                                                        item={item}
                                                        changeItemShow={(e)=>{changeItemShow(e,item,index)}}
                                                    />
                                        )}
                                    </Draggable>
                                    ))}
                                </div>
                                )}
                            </Droppable>
                        </DragDropContext>

                    </div>
                </div>
                
                {/* <div className="popupHeader">{props.headers}</div>
                <div className="popupData">
                    {props.content}
                </div>
                <div className="close-icon" onClick={props.handleClose}>X</div> */}
            </div>
        </div>
    );
};
 
export default ArrangeColumns;