import React from "react";
import "../../css/pages/advertiser.css";
import PropTypes from 'prop-types';
import { NavLink } from "react-router-dom";
import Select from 'react-select'

import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import * as Constants from '../../data/constants';

import axios from 'axios';
import { Store } from 'react-notifications-component';
import {getLocalStorageValue, sorting} from '../../util/util';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const EditAdvertiserPage = props => {
  const advertiserId = window.location.href.split("/")[window.location.href.split("/").length-1];

  const [tabValue, setTabValue] = React.useState(0);
  const [manualUseState, setManualUseState] = React.useState(false);

  const [companyName, setCompanyName] = React.useState(null);
  const [contactPerson, setContactPerson] = React.useState(null);
  const [contactMobile, setContactMobile] = React.useState("");
  const [managerID, setManagerID] = React.useState(null);
  const [skype, setSkype] = React.useState(null);
  const [site, setSite] = React.useState(null);
  const [email, setEmailID] = React.useState("");
  const [address, setAddress] = React.useState(null);
  const [saveButtonDisabled, setSaveButtonDisabled] = React.useState(true);
  const [note, setNote] = React.useState("");
 
  const [showResponsePopup, setShowResponsePopup] = React.useState(false);
  const [responsePopup, setResponsePopup] = React.useState(false);

  const [responsePopupError, setResponsePopupError] = React.useState(false);
  const [managerList, setManagerList] = React.useState([]);

  const [disableAddButton, setDisableAddButton] = React.useState(false);

  const handleTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  const showAdvertiserRequest = async() => {
    await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.ADVERTISERS),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
        params:{
          id:advertiserId,
        }
    }
    )
    .then((resp) => {
        var responseResult = resp.data.result
        
        for (var item in responseResult){
          if (responseResult[item].id.toString() === advertiserId){
            setCompanyName(responseResult[item].company)
            setContactPerson(responseResult[item].contact_person)
            setManagerID({label:(responseResult[item].manager_name ? responseResult[item].manager_name.split(") ")[1] : ""),value:(responseResult[item].manager_name ? responseResult[item].manager_name.split(")")[0].split("(")[1] : "")})
            setSkype(responseResult[item].skype_id)
            setSite(responseResult[item].website)
            setEmailID(responseResult[item].email) 
            setAddress(responseResult[item].address)
            setContactMobile(responseResult[item].contact_number)
            setNote(responseResult[item].note)
          }}
    })
    .catch(error => {
      setDisableAddButton(false)
      if (error.response) {
        // Request made and server responded
        setResponsePopupError('getAdvertiser')
        setResponsePopup(error.response.data.result);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else {
        // Something happened in setting up the request that triggered an Error
        setResponsePopup(JSON.stringify({error:error.message}));
        console.log('Error', error.message);
      }
      setShowResponsePopup(true)
    });
};

  const editAdvertiserRequest = async() => {
    setDisableAddButton(true)

    await axios({
      method: 'POST',
      url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.ADVERTISER,"/",advertiserId,"/",Constants.CHANGE),
      headers:{
          Authorization:getLocalStorageValue('adminPanelLoginData').token,
          "Content-Type":"application/json",
      },
      data:{
        "company_name":companyName,
        "contact_person":contactPerson ? contactPerson : null,
        "contact_number":contactMobile ? contactMobile : null,
        "address": address,
        "email": email ? email : null,
        "manager_id": managerID ? managerID.value : null,
        "note": note ? note : null,
        "skype_id": skype ? skype : null,
        "website": site ? site : null,
      }
      })
    .then((resp)=>{
      if(resp.data.result && resp.data.result.advertiser)
        { 
          setResponsePopupError(false)
          // setResponsePopup("Advertiser edited successfully");
          Store.addNotification({
              title: "Success!",
              message: "Advertiser edited successfully",
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 1000,
                onScreen: true
              },
              onRemoval: (id, removedBy) => {
                window.location.href = "/advertiser/"
                setDisableAddButton(false)
              }
            });
           

        }
      else{
        Store.addNotification({
          title: "Error!",
          message: typeof resp.data.result === "string" ? resp.data.result : "Some error occured",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        setDisableAddButton(false);
          // setResponsePopupError(true)
          // setResponsePopup(resp.data.result);
        }
      // setShowResponsePopup(true)
      }
    )
    .catch(function (error) {
      setDisableAddButton(false)
      if (error.response) {
        // Request made and server responded
        setResponsePopupError(true)
        setResponsePopup(error.response.data.result);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else {
        // Something happened in setting up the request that triggered an Error
        setResponsePopup(JSON.stringify({error:error.message}));
        console.log('Error', error.message);
      }
      setShowResponsePopup(true)

    });
  }

  const getManagers = async() => {
    await axios({
        method: 'GET',
        url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,"manager","/","details"),
        headers:{
            Authorization:getLocalStorageValue('adminPanelLoginData').token
        },
    })
    .then(resp => {
        let managerlist = []
        for (var item in resp.data.result){
            managerlist.push({label:resp.data.result[item][Object.keys(resp.data.result[item])[0]],value:Object.keys(resp.data.result[item])[0]})
        }
        sorting(managerlist,'label',false,true)
        setManagerList(managerlist)
    })
    .catch(err => {
        console.error(err);
    })
};

  if (!manualUseState){
    setManualUseState(true);
    getManagers();
    showAdvertiserRequest();
  }

  const specialCharacter = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  const number = /[0-9]/g;
  const email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const skype_regex = /[a-zA-Z][a-zA-Z0-9.,\-_]{5,31}/;
  const website_regex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;

  const changeCountryValue = (e) => {
    setAddress({...address ,country:e.value})
  }
  
  const countriesOptions = Constants.COUNTRIES_LIST.map((item,index)=>{
    return (
      {label:item,value:item}
    )
  });

  if (companyName !== "" 
      && (companyName && !companyName.match(specialCharacter))
      && contactPerson !== ""
      && (contactPerson === "" || (contactPerson && !contactPerson.match(specialCharacter)))
      && (contactPerson === "" || (contactPerson && !contactPerson.match(number)))
      && (email===null || email === "" || (email && email.match(email_regex)))
      && (site === null || site === ""|| site.match(website_regex))
      ){
    if (saveButtonDisabled===true){
      setSaveButtonDisabled(false)
    }
  }
  else{
    if (saveButtonDisabled===false){
      setSaveButtonDisabled(true)
    }
  }
  
  const handleSaveAdvertiserButton = () => {
    editAdvertiserRequest()
  }
  
  const handleSaveAndContinueAdvertiserButton = () => {
    handleTabValue("continue",(tabValue<1 ? tabValue+1 : 0))
  }
  const general = () => {
    return(
      <div style={{height:"100%",overflowY:"auto",marginLeft:"1%",marginTop:"2%"}}>
        
        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Company *</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" value={companyName ? companyName : ""} onChange={(e)=>{setCompanyName(e.target.value)}}/></div>
          {(companyName ? companyName.match(specialCharacter) ? <div style={{fontSize:"11px",display:"flex",alignItems:"center",color:"red"}}> Special characters are not allowed in company name.</div> : null  : null)}
        </div>
        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Contact Person *</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" value={contactPerson ? contactPerson : ""} onChange={(e)=>{setContactPerson(e.target.value)}}/></div>
          {(contactPerson ? contactPerson.match(specialCharacter) || contactPerson.match(number) ? <div style={{fontSize:"11px",display:"flex",alignItems:"center",color:"red"}}> Special characters and numbers are not allowed in contact person name.</div> : null  : null)}
        </div>
        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Manager</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}>
            {!managerID && <Select options={managerList}
              defaultValue={managerID ? {label:managerID.label,value:managerID.value} : null}
              onChange={(e)=>{setManagerID(e)}}
              menuPortalTarget={document.body} 
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
              option: styles => ({...styles,fontSize:"12px"}) 
               }}/>
            }
            {managerID && <Select options={managerList}
              defaultValue={managerID ? {label:managerID.label,value:managerID.value} : null}
              onChange={(e)=>{setManagerID(e)}}
              menuPortalTarget={document.body} 
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                        option: styles => ({...styles,fontSize:"12px"}) 
                      }}/>
            }
          </div>
          </div>
        <div style={{display:"flex"}}>
        <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Website</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" value={site ? site : ""} onChange={(e)=>{setSite(e.target.value)}}/></div>
          {(site ? (!site.match(website_regex))? <div style={{fontSize:"11px",display:"flex",alignItems:"center",color:"red"}}> Website is not in correct form.</div> : null  : null)}
        </div>
        <div style={{display:"flex"}}>
        <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Email Id</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" value={email ? email : ""} onChange={(e)=>{setEmailID(e.target.value)}}/></div>
          {(email ? !email.match(email_regex)? <div style={{fontSize:"11px",display:"flex",alignItems:"center",color:"red"}}> Emailid is not in correct form.</div> : null  : null)}
        </div>

        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Contact Person Number</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" value={contactMobile ? contactMobile : ""} onChange={(e)=>{setContactMobile(e.target.value)}}/></div>
          {(contactMobile ? !contactMobile.match(number)? <div style={{fontSize:"11px",display:"flex",alignItems:"center",color:"red"}}> Contact number is incorrect.</div> : null  : null)}
        </div>
        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Note</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField multiline rows={4} fullWidth size="small" value={note ? note : ""} onChange={(e)=>{setNote(e.target.value)}}/></div>
        </div>
      </div>
    )
  }

  const contact = () => {
    return(
      <div style={{height:"100%",overflowY:"auto",marginLeft:"1%",marginTop:"2%"}}>
        
        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Skype</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" value={skype ? skype : ""}  onChange={(e)=>{setSkype(e.target.value)}}/></div>
          {(skype ? !skype.match(skype_regex)? <div style={{fontSize:"11px",display:"flex",alignItems:"center",color:"red"}}> Skypeid is incorrect.</div> : null  : null)}

        </div>
        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Address 1</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" multiline rows={2} value={address ? address.address1 ? address.address1 : "" : ""} onChange={(e)=>{setAddress({...address ,address1:e.target.value})}}/></div>
        </div>
        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Address 2</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" multiline rows={2} value={address ? address.address2 ? address.address2 : "" : ""} onChange={(e)=>{setAddress({...address ,address2:e.target.value})}}/></div>
        </div>
        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>City</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" value={address ? address.city ? address.city : "" : ""} onChange={(e)=>{setAddress({...address ,city:e.target.value})}}/></div>
        </div>
        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Country</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}>
            <Select className="selectCountryList" options={countriesOptions}
              defaultValue={address ? address.country ? {label:address.country,value:address.country} : null : null}

              onChange={changeCountryValue}
              menuPortalTarget={document.body} 
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                      option: styles => ({...styles,fontSize:"12px"}) }}/>
          </div>
        </div>
        <div style={{display:"flex"}}>
          <div style={{justifyContent:"right",alignItems:"center",display:"flex",fontSize:"13px",fontWeight:"600",color:"rgb(100,100,100)",width:"20%"}}>Zip Code</div>
          <div style={{transform:"scale(0.8)",width:"40%"}}><TextField fullWidth size="small" value={address ? address.zipcode ? address.zipcode : "" : ""} onChange={(e)=>{setAddress({...address ,zipcode:e.target.value})}}/></div>
        </div>

      </div>
    )
  }

  return (
    <div className="row">
        <div className="columnData" style={{width:"95%",background:"white",height:"80vh"}}>
        <div style={{background:"white",width:"auto",height:"7%"}}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs 
              TabIndicatorProps={{style: {background:'orange',backgroundColor:'orange'}}}
              value={tabValue} 
              onChange={handleTabValue} 
              aria-label="basic tabs example">
              <Tab label={<span className={tabValue===0? 'Active' : 'Inactive'}>General</span>}/>
              <Tab label={<span className={tabValue===1? 'Active' : 'Inactive'}>Contact</span>}/>
            </Tabs>
          </Box>
          </Box>
          
        </div>
        <div style={{height:"82%",width:"100%",background:"white",overflowY:"auto"}}>
         
          <TabPanel value={tabValue} index={0}>
            {general()}
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {contact()}
          </TabPanel>
        </div>

        <div style={{display:"flex",alignItems:"center",height:"7%",width:"90%"}}>
          <div style={{display:"flex",marginLeft:"20%"}}>
            <NavLink style={{textDecoration:"none",color:"black"}} to={"/advertiser/view/"+advertiserId}>

              <Button variant="outlined" color="warning" onClick={handleSaveAndContinueAdvertiserButton}>View</Button>
            </NavLink>
          </div>
          <div style={{display:"flex",marginLeft:"2%"}}>
            <Button variant="contained" color="warning" onClick={handleSaveAndContinueAdvertiserButton}>SAVE AND CONTINUE</Button>
          </div>
          <div style={{marginLeft:"2%"}}>
            <Button variant="contained" color="warning" onClick={handleSaveAdvertiserButton} disabled={saveButtonDisabled || disableAddButton}>EDIT</Button>
          </div>
          <div style={{marginLeft:"2%"}}>
                <NavLink style={{textDecoration:"none",color:"black"}} to={"/advertiser"}><Button variant="text">Cancel</Button></NavLink>
          </div>
        </div>
      </div>
      {showResponsePopup && 
          <div className="popup-box">
            <div className="box-response">
              <div className="popupHeader" >{"RESULT"}</div>
              <div style={{borderBottom:"2px solid orange"}}></div>
                <div className="popupData">
                  {responsePopup}
                </div>
                {
                  !responsePopupError && 
                    <NavLink style={{textDecoration:"none",color:"black"}} to={"/advertiser/view/"+advertiserId}>
                      <div className="close-icon-response" onClick={()=>{setShowResponsePopup(false)}}>X</div>
                    </NavLink>
                  }
                {
                  responsePopupError &&
                    <div className="close-icon-response" onClick={()=>{setShowResponsePopup(false)}}>X</div>
                }
                {
                  responsePopupError==='getAdvertiser' && 
                    <NavLink style={{textDecoration:"none",color:"black"}} to={"/advertiser"}>
                      <div className="close-icon-response" onClick={()=>{setShowResponsePopup(false)}}>X</div>
                    </NavLink>
                }
            </div>
          </div>
      }
    </div>
  );
};
 
export default EditAdvertiserPage;