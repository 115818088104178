import axios from 'axios'
import React, { useEffect, useState } from 'react'
import * as Constants from '../../../data/constants';
import { getLocalStorageValue, sortingV2 } from '../../../util/util';
import TableModel from '../../../components/tableModel/tablemodel';
import { Button, CircularProgress, Tooltip } from '@mui/material';

const TrafficLimitedAdvertiser = () => {
    const [responseData, setResponseData] = useState([])
    const [loader, setLoader] = useState(false)
    const [showReasonPopup, setShowReasonPopup] = useState(false)
    const getTrafficLimitedAdvertiserList = async() => {
        setLoader(true)
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'advertiser/trafficlimited'),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":"application/json",
            },
        })
        .then(async(first_resp)=>{
            var date = new Date();
            var dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric'};
            var startDate = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString("sv-SE", dateOptions);
            var endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString("sv-SE", dateOptions); 
            await axios({
                method: 'GET',
                url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'statistics/advertiser/new'),
                headers:{
                    Authorization:getLocalStorageValue('adminPanelLoginData').token,
                    "Content-Type":"application/json",
                },
                params:{
                    startDate,
                    endDate,
                    limit:50,
                    advertiser_id:Object.keys(first_resp.data.result).join(','),
                    page:1,
                    order_by:'DESC',
                    order_field:'approved_revenue'
                }
            })
            .then(async(resp)=>{
                if(resp.data && resp.data.result.data){
                    var response = []
                    for(var adv_id in resp.data.result.data){
                        var adv_data = resp.data.result.data[adv_id]
                        var approved_revenue = Math.round(adv_data.payouts[0].approved_revenue)
                        var other_data = first_resp.data.result[adv_id]
                        var limiter_status = first_resp.data.result[adv_id].limiter_status
                        if(approved_revenue<=500){
                            var cap = 500
                        }
                        else if(approved_revenue<=2000){
                            cap = 2000
                        }
                        else if(approved_revenue<=5000){
                            cap = 5000
                        }
                        else if(approved_revenue<=10000){
                            cap = 10000
                        }
                        else if(approved_revenue<=20000){
                            cap = 20000
                        }
                        else if(approved_revenue<=30000){
                            cap = 30000
                        }
                        else{
                            cap = 400000
                        }
                        if(limiter_status){
                            if(other_data.last_approved_cap===500){
                                cap = 2000
                            }
                            if(other_data.last_approved_cap===2000){
                                cap = 5000
                            }
                            if(other_data.last_approved_cap===5000){
                                cap = 10000
                            }
                            if(other_data.last_approved_cap===10000){
                                cap = 20000
                            }
                            if(other_data.last_approved_cap===20000){
                                cap = 30000
                            }
                            if(other_data.last_approved_cap===30000){
                                cap = 40000
                            }
                        }
                        var cap_percent = Math.round((approved_revenue/cap)*100)
                        response.push({...adv_data,cap_percent,cap,approved_revenue,adv_id,...other_data})
                    }
                    sortingV2(response,'approved_revenue',false)
                    setResponseData(response)
                }
            })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else {
                    console.log('Error', error.message);
                }        
            });
        })
        .catch(function (error) {
            if (error.response) {
              console.log(error.response.status);
              console.log(error.response.headers);
            } else {
              console.log('Error', error.message);
            }        
        });
        setLoader(false)
    }
    String.prototype.rsplit = function(sep, maxsplit) {
        var split = this.split(sep);
        return maxsplit ? [ split.slice(0, -maxsplit).join(sep) ].concat(split.slice(-maxsplit)) : split;
    }
    const upgradeLimit = async(valueItem) => {
        setShowReasonPopup({...showReasonPopup,loader:true})
        var date = new Date();
        var dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric'};
        var startDate = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString("sv-SE", dateOptions);
        var year_month = startDate.rsplit('-',1)[0]
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'advertiser/approvealloffers'),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token,
                "Content-Type":"application/json",
            },
            params:{
                adv_id:valueItem.adv_id,
                adv_name:valueItem.company,
                cap : valueItem.cap,
                year_month,
                reason : showReasonPopup.reason,
            }
        })
        .then(async(first_resp)=>{
            getTrafficLimitedAdvertiserList()
            setShowReasonPopup(false)
        })
        .catch(function (error) {
            if (error.response) {
              console.log(error.response.status);
              console.log(error.response.headers);
            } else {
              console.log('Error', error.message);
            }        
        });
    }
    useEffect(() => {
        getTrafficLimitedAdvertiserList()
        
        return () => {
            
        }
    }, [])
    
    const advertiserNameComponent = (valueItem) => {
        return valueItem.company + ' - ' + valueItem.adv_id 
    }

    var statusDictColor = {
        '0':{
            color:'orange',
            backgroundColor:'rgb(255, 234, 195)',
            label:'Paused',
        },
        '1':{
            color:'rgb(0, 150, 0)',
            backgroundColor:'rgb(217, 255, 200)',
            label:'Active'
        },
        '2':{
            color:'red',
            backgroundColor:'rgb(255, 200, 200)',
            label:'Traffic Limited'
        }
        
    }
    const statusComponent = (valueItem,item) => {
        var value = valueItem[item.value]
        return <div style={{minWidth:'70px',textAlign:'center',width:'max-content',wordWrap:'break-word',color:(statusDictColor[value] ? statusDictColor[value].color : 'black'),border:'1px solid '+(statusDictColor[value] ? statusDictColor[value].color : 'black'),padding:'2px',backgroundColor:(statusDictColor[value] ? statusDictColor[value].backgroundColor : 'white'),borderRadius:'5px',paddingTop:'5px',paddingBottom:'5px'}}>
                    {statusDictColor[value] ? statusDictColor[value].label : value}
                </div>
    }
    const capPercentComponent = (valueItem,item) => {
        var percentColors = [
            { pct: 0, color: { r: 0x00, g: 0xff, b: 0 } },
            { pct: 50, color: { r: 0xff, g: 0xff, b: 0 } },
            { pct: 100, color: { r: 0xff, g: 0x00, b: 0 } }
        ]
        
        var getColorForPercentage = function(pct) {
            for (var i = 1; i < percentColors.length - 1; i++) {
                if (pct < percentColors[i].pct) {
                    break;
                }
            }
            var lower = percentColors[i - 1];
            var upper = percentColors[i];
            var range = upper.pct - lower.pct;
            var rangePct = (pct - lower.pct) / range;
            var pctLower = 1 - rangePct;
            var pctUpper = rangePct;
            var color = {
                r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
                g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
                b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
            };
            return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
            // or output as hex if preferred
        };
        var totalWidth = 100
        var width = (totalWidth * valueItem[item.value])/100
        if(width>100){
            width=100
        }
        return <Tooltip title={valueItem[item.value]}>
            <div style={{display:'flex',alignItems:'flex-end'}}>
                <div style={{height:'10px',position:'relative',width:totalWidth+'%',borderRadius:'7px',border:'1px solid rgb(200,200,200)'}}>
                    <div style={{background:getColorForPercentage(valueItem[item.value]),width:width+'%',height:'10px',borderRadius:'7px'}}>
                        
                        {/* <div style={{position:'absolute',right:0,top:-10}}>
                            {valueItem[item.value]}
                        </div> */}
                    </div>
                </div>
            </div>
        </Tooltip>
    }
    const emptyValueComponent = (valueItem,item) => {
        if(!valueItem[item.value]){
            return '-'
        }
        return valueItem[item.value]
    }

    const validationPercentageComponent = (valueItem,item,index,valueIndex,tableValues) => {
        var percent = valueItem[item.value] ? parseInt(valueItem[item.value]) : 0.00
        if (percent<25){
            var color = '#ff0000'
            var highlightColor = '#ffcccc'
        }
        else if (percent<50){
            color = '#ff6200'
            highlightColor = '#ffe6cc'
        }
        else if (percent<75){
            color = '#6f9400'
            highlightColor = '#ebffc2'
        }
        else{
            color = '#0f8500'
            highlightColor = '#d2ffcc'
        }

        return <div style={{color,fontWeight:500,background:highlightColor,marginLeft:(valueItem.advertiser_id==='Total' ? '5px' : '0px'),padding:'5px',width:'max-content',borderRadius:'10px'}}>
            {percent ? percent.toString()+'%' : '0%'}
        </div>
    }
    const intValueComponent = (valueItem,item) => {
        if(!valueItem[item.value]){
            return '0'
        }
        return Math.round(valueItem[item.value])
    }
    
    const approveCapComponent = (valueItem,item) => {
        var limiter_status = valueItem.limiter_status
        return <Tooltip title={limiter_status===0 ? 'Upgrade cap limit' : 'You can upgrate only when cap percentage is above 90%.'}>
            <div style={{cursor:limiter_status===0 ? 'pointer' : 'not-allowed',color:limiter_status===0 ? getLocalStorageValue('ambientColor') : 'grey'}} onClick={()=>{if(limiter_status===0){item.setShowReasonPopup(valueItem)}}}>
                Increase Limit
            </div>
        </Tooltip>
    }

    function subtractMonths(date, months) {
        date.setMonth(date.getMonth() - months);
        return date;
    }
    const date = new Date();
    if(date.getDate()>15){
        var label = subtractMonths(new Date(),6).toLocaleString('default', { month: 'long' })
        label+=', '+subtractMonths(new Date(),5).toLocaleString('default', { month: 'long' })
        label+=', '+subtractMonths(new Date(),4).toLocaleString('default', { month: 'long' })
    }
    else{
        label = subtractMonths(new Date(),5).toLocaleString('default', { month: 'long' })
        label+=', '+subtractMonths(new Date(),4).toLocaleString('default', { month: 'long' })
        label+=', '+subtractMonths(new Date(),3).toLocaleString('default', { month: 'long' })
    }
    console.log(date.getDate())
    const tempTableHeaders = [
        {label:'Advertiser',value:'adv_id',width:90,locked:true,showTooltip:true,hide:false,customComponent:advertiserNameComponent},
        {label:'Status',value:'status',width:90,customComponent:statusComponent},
        {label:'Cap',value:'cap',width:90},
        {label:'Revenue',value:'approved_revenue',width:90},
        {label:'Percentage',value:'cap_percent',width:70,customComponent:capPercentComponent},
        {label:'Updated At',value:'last_approved_time',width:110,customComponent:emptyValueComponent},
        {label:label+' Data',width:180,
            child:[
                {label:'Validation Percentage',value:'validation_percentage',width:50,customComponent:validationPercentageComponent},
                {label:'Revenue',value:'total_revenue',width:50,customComponent:intValueComponent},
            ]},
        {label:'Last Approved Cap',value:'last_approved_cap',width:110,customComponent:emptyValueComponent},
        {label:'Last Approved By',value:'last_approved_by',width:130,customComponent:emptyValueComponent},
        {label:'Last Approved Message',value:'last_approved_message',width:150,customComponent:emptyValueComponent},
        {label:'Approve',value:'approve',width:150,customComponent:approveCapComponent,setShowReasonPopup},
    ]
    return (
        <div>
            <div style={{position:'sticky',left:0,width:'100%',zIndex:100}}>
                <div style={{fontSize:'24px',fontWeight:'700',padding:'10px',paddingLeft:'20px'}}>Traffic Limiter - Advertisers</div>
                <div>

                </div>
            </div>
            {
                showReasonPopup
                &&
                <div style={{position:'fixed',top:0,left:0,height:'100vh',width:'100vw',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <div style={{minHeight:'20vh',boxShadow:'0 0 5px 1px rgb(200,200,200)',width:'40vw',background:'white',border:'1px solid rgb(200,200,200)'}}>
                        <div style={{borderBottom:'1px solid rgb(200,200,200)',height:'50px',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <div style={{paddingLeft:'10px'}}>Reason For Upgrading Limit</div>
                            <div style={{paddingRight:'10px'}} onClick={()=>{setShowReasonPopup(false)}}>X</div>
                        </div>
                        <div style={{display:'flex',flexDirection:'column',alignItems:'center',minHeight:'10vh',justifyContent:'center',padding:'15px'}}>
                            <Constants.CssTextField fullWidth onChange={e=>{setShowReasonPopup({...showReasonPopup,reason:e.target.value})}}/>
                            <Button style={{...Constants.CONTAINEDBUTTON,marginTop:'20px'}} onClick={()=>{upgradeLimit(showReasonPopup)}}>
                                {showReasonPopup.loader &&
                                <CircularProgress size={14} sx={{color:'white'}}/>
                                }
                                {
                                    !showReasonPopup.loader &&
                                    'Submit'  
                                }
                            </Button>
                        </div>
                    </div>
                </div>
            }
            {
                loader
                &&
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'30vh'}}>
                    <CircularProgress size={50} sx={{color:getLocalStorageValue('ambientColor')}}/>
                </div>
            }
            {
                !loader
                &&
                <TableModel
                    tableHeaders={tempTableHeaders}
                    // setTableHeaders={setTableHeaders}
                    tableValues={responseData}
                    setTableValues={setResponseData}
                    style={{background:'rgb(245,245,245)'}}
                    tableValueRow={'rest'}
                    setTableValueRow={()=>{console.log('test')}}
                />
            }
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    )
}

export default TrafficLimitedAdvertiser