import { Button, TextField } from '@mui/material';
import React from 'react';
import axios from 'axios';
import * as Constants from '../../data/constants';
import CircularProgress from '@mui/material/CircularProgress';
import { getLocalStorageValue } from '../../util/util';

export const TestingLinks = () => {
    const [showLoader, setShowLoader] = React.useState(false);
    const [testLink, setTestLink] = React.useState("");
    const [testLinkResult, setTestLinkResult] = React.useState({});

    var website_regex = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/;

    const testLinkRequest = async() => {
        setShowLoader(true)
        await axios({
            method: 'GET',
            url: "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.AFFILIATE,"/postbacktesting"),
            headers:{
                Authorization:getLocalStorageValue('adminPanelLoginData').token
            },
            params:{
                url:testLink,
            }
        })
        .then(resp => {
            setTestLinkResult({response:resp.data.result})
            setShowLoader(false);
        })
        .catch(err => {

            setShowLoader(false);
            console.error(err);
            setTestLinkResult({error:err.response.data})
        })
    };

    const handlePressEnter = (e) => {
        if (e.key === 'Enter') {
            testLinkRequest()
          }
    }
    
    const showTestLinkResult = () => {
        var resp = []
        for (var item in testLinkResult.response){
            resp.push(
                                <tr style={{fontSize:"13px"}}>
                                    <td style={{padding:"2px",fontWeight:"500"}}>
                                        {item}
                                    </td>

                                    <td>
                                        {testLinkResult.response[item]}
                                    </td>
                                </tr>
                            
               
            )
        }
        return resp
    }
    return (
    <div className="row">
        <div className="columnData" style={{width:"95%",height:"auto",minHeight:"60vh",maxHeight:"100vh"}}>
           <div style={{padding:"2%",fontSize:"16px",fontWeight:"600",color:"rgb(40,40,40)"}}>
                Testing Links
           </div>
           <div style={{width:'96%',marginLeft:"2%",marginRight:"2%",borderBottom:"1px solid rgb(200,200,200)"}}>
           </div>
           <div style={{display:"flex",width:"96%",marginLeft:"2%",marginRight:"2%",paddingTop:"2%"}}>
               <div style={{transform:"scale(0.9)",width:"60%"}}>
                    <TextField fullWidth size="small" placeholder='Affiliate outer link' onChange={(e)=>{setTestLink(e.target.value)}} onKeyDown={handlePressEnter}/>
                    {(testLink ? !testLink.match(website_regex)? <div style={{fontSize:"11px",display:"flex",alignItems:"center",color:"red"}}>Url format invalid.</div> : null  : null)}

               </div>
               <div style={{transform:"scale(0.9)"}}>
                   <Button  variant="outlined" color="warning" onClick={testLinkRequest} disabled={testLink.match(website_regex) && !showLoader ? false : true}>Run</Button>
               </div>
           </div>
           <div style={{fontSize:"13px",paddingTop:"5px",paddingLeft:"5%",color:"rgb(80,80,80)"}}>
               This tool emulates the conversion and helps you to check if affiliates postback works.
           </div>

           {showLoader && 
                <div style={{display:"flex",justifyContent:'center',alignItems:"center",minHeight:"30vh"}}>  
                    <CircularProgress color="warning"/> 
                </div>
                        }
            {!showLoader && testLinkResult.response && 
                <div style={{display:"flex",justifyContent:'center',alignItems:"center",minHeight:"30vh",marginBottom:"5%"}}>  

                    <div style={{width:"82%",marginLeft:"6%",marginRight:"6%",marginTop:"2%",padding:"2%",backgroundColor:"rgb(199,255,199)",boxShadow:"0 0 5px 1px green",borderRadius:"10px"}}>
                        <div style={{fontWeight:"600",fontSize:"18px"}}>Result</div> 
                        <div style={{paddingTop:"2%",paddingBottom:"2%"}}>

                        <table>
                            <tbody>
                            {showTestLinkResult()}   
                                
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
            }
            {!showLoader && testLinkResult.error && 
                <div className="loaderWrapper">  
                    <div className="loader">  

                        <div style={{width:"82%",marginLeft:"6%",marginRight:"6%",marginTop:"2%",padding:"2%",backgroundColor:"rgb(255,199,199)",boxShadow:"0 0 5px 1px red",borderRadius:"10px"}}>
                            <div style={{fontWeight:"600",fontSize:"18px"}}>Error</div> 
                            <div style={{paddingTop:"2%",paddingBottom:"2%"}}>
                                {testLinkResult.error.result}   
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
    )
}
