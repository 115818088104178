const specialCharacter = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
const number = /^\d+$/;
const email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const skype_regex = /[a-zA-Z][a-zA-Z0-9.,\-_]{5,31}/;
const website_regex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;

const Validations = (key,value,returnPlease,validationFields,setValidationFields,setDisableButton) => {
    var validationCheck = false;
        if(key==='first_name' || key==='last_name'){
            if(value===''){
                validationCheck = false
            }
            else{
                validationCheck = !value.match(specialCharacter) 
            }
        }
        else if(key==='company'){
            if(value===''){
                validationCheck = false
            }
            else{
                validationCheck = true
                // validationCheck = !value.match(specialCharacter) 
            }
        }
        else if(key==='contact_person'){
            if(value===''){
                validationCheck = false
            }
            else{
                validationCheck = true
            }
        }
        else if(key==='email' && value!==''){
            validationCheck = value.match(email_regex)
        }
        else if(key==='contact_number' && value!==''){
            validationCheck = value.match(number)
        }
        else if((key==='password') && value!==''){
            if(value.length>=8){
                validationCheck = true
            }
        }
        else if(key==='website' && value!==''){
            validationCheck = value.match(website_regex)
        } 
        else if(key==='skype_id' && value!==''){
            validationCheck = value.match(skype_regex)
        }
        else if(key==='commision_percenatge' && value!==''){
            if(value){
                value = value.toString()
            }
            validationCheck = value.match(/^\d+\.?\d*$/)
        }
        else{
            validationCheck = true
        }
        var tempValidationFields = returnPlease ? returnPlease :validationFields
        if(tempValidationFields[key] || tempValidationFields[key]===false){
            tempValidationFields = {...tempValidationFields,[key]:validationCheck ? true : false}
        }
        setValidationFields(tempValidationFields)
        var disableButton = false
        for(var index in tempValidationFields){
            if(!tempValidationFields[index]){
                disableButton = true
                break;
            }
        }
        setDisableButton(disableButton)
        if(returnPlease){
            return tempValidationFields
        }
}

export default Validations