
import CheckLogin from './components/checkLogin';
import './css/main.css';
import React, { useState } from 'react';
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { increase_brightness } from './components/customization/customization';
import Cookies from 'universal-cookie';
import SharedDataPage from './sharedData';
import axios from 'axios';
import * as Constants from './data/constants';
import { getLocalStorageValue, removeLocalStorageValue } from './util/util';
import ActivateUser from './pages/activateUser';
import ServerMaintainance from './serverMaintainance';

function App() {
    const [telegramBot, setTelegramBot] = useState(false)

    if(window.location.pathname.includes('shared')){
        return <SharedDataPage/>
    }

    if(window.location.pathname.includes('/serverMaintainance')){
        return <ServerMaintainance/>
    }
    const cookies = new Cookies();
    cookies.set('nightmode',false)
    if(!cookies.get('newSidebar')){
        cookies.set('newSidebar',true)
    }

    /// update CSS
    var ambientColor = "rgb(0, 166, 178)"
    if(localStorage.getItem('ambientColor')){
        ambientColor = localStorage.getItem('ambientColor')
    }
    else{
        localStorage.setItem('ambientColor', "rgb(0, 166, 178)")
    }
    var r = document.querySelector(':root');
    var reverse = cookies.get('nightmode')==='false' ? false : true
    if(!reverse){
        r.style.setProperty('--container-shadow-color', increase_brightness(ambientColor,-10));
        r.style.setProperty('--container-shadow-color-hover', ambientColor);
        r.style.setProperty('--container-shadow-color-hover-lighter', increase_brightness(ambientColor,10));    
        r.style.setProperty('--container-shadow-color-hover-more-lighter', increase_brightness(ambientColor,95));    
        r.style.setProperty('--top-bg-color', ambientColor);
        r.style.setProperty('--hamburger-icon-hover-color', increase_brightness(ambientColor,-10));
        r.style.setProperty('--menu-item-hover-color', 'white');
        r.style.setProperty('--menu-item-hover-font-color', 'black');
        r.style.setProperty('--font-color', 'white');
        r.style.setProperty('--sidebar-bg-color', ambientColor);
    }
    else{
        var backgroundColor = 'rgb(252,252,252)'
        r.style.setProperty('--container-shadow-color', increase_brightness(backgroundColor,-10));
        r.style.setProperty('--container-shadow-color-hover', backgroundColor);
        r.style.setProperty('--container-shadow-color-hover-lighter', increase_brightness(backgroundColor,10));     
        r.style.setProperty('--container-shadow-color-hover-more-lighter', increase_brightness(backgroundColor,0));    
        r.style.setProperty('--top-bg-color', backgroundColor);
        r.style.setProperty('--hamburger-icon-hover-color', increase_brightness(backgroundColor,-10));
        r.style.setProperty('--menu-item-hover-color', ambientColor);
        r.style.setProperty('--menu-item-hover-font-color', 'white');
        r.style.setProperty('--font-color', 'rgb(50,50,50)');
        r.style.setProperty('--sidebar-bg-color', backgroundColor);
    }
    const sendToTelegram = async(res_url,response) => {
        if(!telegramBot && process.env.REACT_APP_COMPANY_NAME!=='APPANALYTICS-LOCAL' && process.env.REACT_APP_COMPANY_NAME!=='APPANALYTICS-TEST' && !res_url.includes('/login')){
            setTelegramBot(true)
            var url = "https://api.telegram.org/bot"+process.env.REACT_APP_TELEGRAM_BOT_TOKEN+"/sendMessage"
            var text = process.env.REACT_APP_COMPANY_NAME+' Frontend Error\n\n_Could not save error for following request_\n\nURL: '+res_url+'\n\nError Response: '+response
            
            await axios({
                method: 'GET',
                url,
                params:{
                    parse_mode : 'markdown',
                    chat_id: process.env.REACT_APP_TELEGRAM_CHAT_ID,
                    text: text
                }  
            }
            )
            .then((resp) => {
                
            })
            .catch(function (error) {
                console.log(error)
            });
        }
    }
    const loggingFailedRequest = async(res,tempResponse) => {
        var duration = res.config.time.endTime - res.config.time.startTime;
       
        if(res.response){
            res = res.response
            if(res.status===403){
                if(res.data && (res.data.detail === 'authentication failed' || res.data.detail === "access_token invalid")){
                    removeLocalStorageValue('adminPanelLoginData')
                    window.location = '/login'
                    window.location.reload();
                }
            }
        }
        var responseData = ''
        try{
            responseData = JSON.stringify(res.data).slice(0,100)
        }
        catch{
            responseData = JSON.stringify(res.data)
        }
        var data = {
            method:res.config.method,
            url:res.config.url,
            header:res.config.headers ? res.config.headers : {},
            params:res.config.params ? res.config.params : {},
            data:res.config.data ? res.config.data : {},
            duration,
            response:tempResponse ? tempResponse : responseData ,
            response_code:res.status,
            comment:{}
        }
        var url = "".concat(Constants.PROTOCOL,Constants.HOST,'/api/failedrequest');
        if(!res.config.url.includes('/api/failedrequest')
            &&
            res.config.url.includes(Constants.HOST)
            &&
            !res.config.url.includes('/generate/url/password')
            &&
          res.config.url !== "https://api.telegram.org/bot"+process.env.REACT_APP_TELEGRAM_BOT_TOKEN+"/sendMessage"
            ){
            await axios({
                method: 'POST',
                url,
                headers:{
                    Authorization:getLocalStorageValue('adminPanelLoginData').token,
                    'Content-type':'application/json'
                },
                data
            }
            )
            .then((resp) => {
                
            })
            .catch(function (error) {
                sendToTelegram(res.config.url,data.response )
            });
        }
    }
    
    axios.interceptors.request.use(
        function (req) {
        req.time = { startTime: new Date() };
        return req;
        },
        (err) => {
        return Promise.reject(err);
        }
    );
    axios.interceptors.response.use(
      function (res) {
        res.config.time.endTime = new Date();
        res.duration = res.config.time.endTime - res.config.time.startTime;
        if(res.duration>60000){
            loggingFailedRequest(res,'High Duration')
        }
        // if(res.status.toString().startsWith('5')){
        //     window.location.href='/serverMaintainance'
        // }
        return res;
      },
      (err) => {
        if(err.config){
            err.config.time.endTime = new Date();
        }
        if (err.response) {
            loggingFailedRequest(err)
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
        }
        else{
            if(err.config && err.config.url.includes(process.env.REACT_APP_BACKEND_SERVER_DOMAIN) && err.message!=='Request aborted'){
                // window.location.href='/serverMaintainance'
            }
        }
        
        return Promise.reject(err);
      }
    );

    if(window.location.pathname.includes('/activate')){
        return <>
            <ReactNotifications />
            <ActivateUser/>
        </>
    }

    if(window.location.pathname.includes('/forgot')){
        return <>
            <ReactNotifications />
            <ActivateUser forgot={true}/>
        </>
    }
    return (
        <>
            <ReactNotifications />
            <CheckLogin/>
            <div className='footerShow'>
            <b>Powered by &nbsp;
            <a className='footerLink' target="_blank" rel="noopener noreferrer" href="http://www.appanalytics.in">
                Appanalytics.in
            </a>&nbsp; 2022
            </b>
            </div>
        </>
    );
  }

export default App;
