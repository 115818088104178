import React, { useEffect, useState } from 'react'
import axios from 'axios';
import * as Constants from '../../data/constants';
import '../../css/downloadStats.css'
import { Button } from '@mui/material';
import { getLocalStorageValue, setLocalStorageValue, sorting } from '../../util/util';
import { Store } from 'react-notifications-component';
import CreatableSelect from 'react-select/creatable';
import { increase_brightness } from '../../components/customization/customization';

function useForceUpdate(){
    const [value, setValue] = React.useState(0); // integer state
    if(false){
      console.log(value)
    }
    return () => setValue(value => value + 1); // update the state to force render
  }

export const DownloadStats = (props) => {
    const updateComponent = useForceUpdate();
    const [manualUseState, setManualUseState] = useState(false)
    // const [index, setIndex] = useState(0)
    const [selectedColumns, setSelectedColumns] = useState([])
    const [selectedColumnsData, setSelectedColumnsData] = useState([])
    const [downloadClicked, setDownloadClicked] = useState(false)

    var typesKeys = {
        "Slice Fields":[],
        "Traffic":[ {label:"Clicks",value:"clicks",columnId:5},
                    {label:"Impressions",value:"impressions",columnId:6},
                    {label:"Trafficback",value:"trafficback",columnId:7}
                    ],
        "Conversions":[
                        {label:"Delivered QTY",value:"conversions",columnId:8},
                        {label:"Delivered Postback",value:"postback",columnId:19},
                        {label:"Declined QTY",value:"declined",columnId:9},
                        {label:"Delivered Revenue",value:"approved_revenue",columnId:10},
                        {label:"Declined Revenue",value:"declined_revenue",columnId:11},
                        {label:"Delivered Payout",value:"approved_payout",columnId:12},
                        {label:"Total Qty",value:"total_qty",columnId:13},
                        {label:"Total Revenue",value:"total_revenue",columnId:14},
                        {label:"Total Payout",value:"total_payout",columnId:15},
                    ],
        "Other Fields":[{label:"CR",value:"CR",columnId:17},
                        {label:"Earnings",value:"earnings",columnId:18},]
    }

    if(props.type){
        var type = props.type
        if(type==="Offer"){
            typesKeys['Slice Fields'].push({label:"Offer Id",value:"offer_id",columnId:1});
            typesKeys['Slice Fields'].push({label:"Advertiser ID",value:"advertiser_id",columnId:2});
            typesKeys['Slice Fields'].push({label:"Offer Title",value:"title",columnId:3});
        }
        else if(type==="Daily"){
            typesKeys['Slice Fields'].push({label:"Date",value:"date",columnId:1})
        }
        else if(type==="Advertiser"){
            typesKeys['Slice Fields'].push({label:"Advertiser ID",value:"advertiser_id",columnId:1})
            typesKeys['Slice Fields'].push({label:"Advertiser Name",value:"company",columnId:2})
        }
        else if(type==="AdvertiserManager"){
            typesKeys['Slice Fields'].push({label:"Advertiser Manager ID",value:"advertiser_manager_id",columnId:1})
            typesKeys['Slice Fields'].push({label:"Advertiser Manager Name",value:"name",columnId:2})
        }
        else if(type==="Affiliate"){
            typesKeys['Slice Fields'].push({label:"Affiliate ID",value:"affiliate_id",columnId:1})
            typesKeys['Slice Fields'].push({label:"Affiliate Name",value:"company",columnId:2})
        }
        else if(type==="AffiliateManager"){
            typesKeys['Slice Fields'].push({label:"Affiliate Manager ID",value:"affiliate_manager_id",columnId:1})
            typesKeys['Slice Fields'].push({label:"Affiliate Manager Name",value:"name",columnId:2})
        }
        else if(type==="Goal"){
            typesKeys['Slice Fields'].push({label:"Goal ID",value:"goal_id",columnId:1})
            typesKeys['Slice Fields'].push({label:"Goal Title",value:"goal_title",columnId:2})
            delete typesKeys['Traffic']
            typesKeys['Conversions'].splice(1,1)
            typesKeys['Other Fields'] = [{label:"Earnings",value:"earnings",columnId:18}]
        }
        else if(type==='Smartlink'){
            typesKeys['Slice Fields'].push({label:"Smartlink ID",value:"smartlink_id",columnId:1})
            typesKeys['Slice Fields'].push({label:"Name",value:"name",columnId:2})
        }
        else{
            typesKeys['Slice Fields'].push({label:type.toUpperCase(),value:type,columnId:1})
        }
    }
    if(props.type==='Postback'){
        typesKeys = {
            "Slice Fields":[
                {label:"Date",value:"created_at",columnId:0},
                {label:"Offer",value:"offer_id",columnId:1},
                {label:"Click ID",value:"click_id",columnId:2},
            ],
            "Other Fields":[ 
                    {label:"Affiliate ID",value:"affiliate_id",columnId:4},
                    {label:"Affiliate Manager ID",value:"affiliate_manager_id",columnId:5},
                    {label:"Advertiser ID",value:"advertiser_id",columnId:6},
                    {label:"Advertiser Manager ID",value:"advertiser_manager_id",columnId:7},
                    {label:"Currency",value:"currency",columnId:8},
                    {label:"Goal Value",value:"goal_value",columnId:9},
                    {label:"Payout",value:"payout",columnId:10},
                    {label:"Revenue",value:"revenue",columnId:10},
                    {label:"Payout Status",value:"payout_status",columnId:11},
                    {label:"Reason",value:"declined_reason",columnId:12},
                    {label:"IP",value:"ip",columnId:13},
                    {label:"User Agent",value:"user_agent",columnId:14},
                    {label:"Sub1",value:"sub1",columnId:15},
                    {label:"Sub2",value:"sub2",columnId:16},
                    {label:"Sub3",value:"sub3",columnId:17},
                    {label:"Sub4",value:"sub4",columnId:18},
                    {label:"Sub5",value:"sub5",columnId:19},
                    {label:"Sub6",value:"sub6",columnId:20},
                    {label:"Sub7",value:"sub7",columnId:21},
                    {label:"Sub8",value:"sub8",columnId:22},
                        ],
            
        }
    }

    if(props.type==='DaywiseOffer'){
        typesKeys = {
            "Slice Fields":[
                {label:"Offer",value:"id",columnId:1},
                {label:"Offer Title",value:"title",columnId:2},
                {label:"Advertiser ID",value:"advertiser_id",columnId:4},
                {label:"External Offer ID",value:"external_offer_id",columnId:5},
            ],
            "Other Fields":[ 
                    {label:"Delivered QTY",value:"conversions",columnId:6},
                    {label:"Delivered Revenue",value:"approved_revenue",columnId:7},
                        ],
            
        }
    }
    if(props.datelist){
        var datelist = props.datelist
    }
    // if(props.limit){
    //     var limit = props.limit
    // }
    // if(props.page){
    //     var page = props.page
    // }
    if(props.order_field){
        var order_field = props.order_field
    }
    if(props.order_by){
        var order_by = props.order_by
    }
    if(props.offer_id){
        var offer_id = props.offer_id
    }
    if(props.advertiser_id){
        var advertiserSelected = props.advertiser_id
    }
    if(props.advertiser_manager_id){
        var advertiserManagerSelected = props.advertiser_manager_id
    }
    if(props.affiliate_id){
        var affiliateSelected = props.affiliate_id
    }
    if(props.affiliate_manager_id){
        var affiliateManagerSelected = props.affiliate_manager_id
    }

    const [disableButton, setDisableButton] = useState(false)
    const exportDataRequest = async() => {
        setDisableButton(true);
        var dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric'};
        var startDate = new Date(datelist[0]).toLocaleDateString("sv-SE", dateOptions)
        var endDate = new Date(datelist[1]).toLocaleDateString("sv-SE", dateOptions)

        if(props.external_offer_id && props.external_offer_id.length>0){
            var external_offer_id = ""
            for(var index in props.external_offer_id){
                external_offer_id+=props.external_offer_id[index].value + ","
            }
            external_offer_id = external_offer_id.slice(0,external_offer_id.length-1)
        }
        
        if(props.smartlink_id && props.smartlink_id.length>0){
            var smartlink_id = ""
            for(index in props.smartlink_id){
                smartlink_id+=props.smartlink_id[index].value + ","
            }
            smartlink_id = smartlink_id.slice(0,smartlink_id.length-1)
        }
        if(affiliateSelected && affiliateSelected.length>0){
            var affiliate_id = ""
            for(index in affiliateSelected){
                affiliate_id+=affiliateSelected[index].value + ","
            }
            affiliate_id = affiliate_id.slice(0,affiliate_id.length-1)
        }
        if(advertiserSelected && advertiserSelected.length>0){
            var advertiser_id = ""
            for(index in advertiserSelected){
                advertiser_id+=advertiserSelected[index].value + ","
            }
            advertiser_id = advertiser_id.slice(0,advertiser_id.length-1)
        }
        if(advertiserManagerSelected && advertiserManagerSelected.length>0){
            var advertiser_manager_id = ""
            for(index in advertiserManagerSelected){
                advertiser_manager_id+=advertiserManagerSelected[index].value + ","
            }
            advertiser_manager_id = advertiser_manager_id.slice(0,advertiser_manager_id.length-1)
        }
        if(affiliateManagerSelected && affiliateManagerSelected.length>0){
            var affiliate_manager_id = ""
            for(index in affiliateManagerSelected){
                affiliate_manager_id+=affiliateManagerSelected[index].value + ","
            }
            affiliate_manager_id = affiliate_manager_id.slice(0,affiliate_manager_id.length-1)
        }
        if(props.goal_value && props.goal_value.length>0){
            var goal_value = ""
            for(index in props.goal_value){
                goal_value+=props.goal_value[index].value + ","
            }
            goal_value = goal_value.slice(0,-1)
        }
        if(props.conversionType){
            var statusType = props.conversionType.value 
        }
        var url = "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,Constants.GETSTATS,"/","export")
        if(props.type==="Goal"){
            url+="/goal"
        }
        if(props.type==='Postback'){
            url =  "".concat(Constants.PROTOCOL,Constants.HOST,Constants.API_USER_PATH,'statistics/conversion/export')
        }
        console.log(props.show_conversion_stats)
        await axios({
            method: 'POST',
            url,
            headers: {
                "Content-Type":"application/json",
                Authorization:getLocalStorageValue('adminPanelLoginData').token
              },
            data: {
                startDate,
                endDate,
                columns_requested:selectedColumns,
                stats_type:"Stats-"+type,
                order_by,
                order_field,
                offer_id,
                goal_id:props.goal_id,
                type : statusType ? statusType : null,
                external_offer_id:external_offer_id ? external_offer_id : null,
                advertiser_id:props.offerSourceSelected ? advertiserSelected.value : advertiser_id ? advertiser_id : null,
                advertiser_manager_id:advertiser_manager_id ? advertiser_manager_id : null,
                affiliate_id:affiliate_id ? affiliate_id : null,
                affiliate_manager_id:affiliate_manager_id ? affiliate_manager_id : null,
                currency:props.currency ? props.currency : null,
                goal_value:goal_value ? goal_value : null,
                converted_stats: props.show_conversion_stats ? '1' : undefined,
                smartlink_id:smartlink_id ? smartlink_id : null,
                offer_source:props.offerSourceSelected ? 'manual' : null,
                utc_offset : props.timezone ? props.timezone.value ? props.timezone.value.split(props.timezone.label.split(" (UTC")[0])[props.timezone.value.split(props.timezone.label.split(" (UTC")[0]).length-1].split("UTC")[1] : "+0:00" : null,
              }
        })
        .then(resp => {
            Store.addNotification({
                title: "Success!",
                message: resp.data.result,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2000,
                  onScreen: true
                },
              });
            })
        .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                Store.addNotification({
                    title: "Error!",
                    message: error.response.data.result,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2000,
                      onScreen: true
                    }
                  });
    
                } else if (error.request) {
                console.log(error.request);
                } else {
                console.log('Error', error.message);
                }
        })
        setDisableButton(false);
    };
    
    if(!manualUseState){
        setManualUseState(true);
    }
    
    const selectColumns = (e,item) => {
        var tempSelectedColumnsList = selectedColumnsData
        var tempSelectedColumnsWithoutDataList = []
        var foundObject = false
        for(var index in tempSelectedColumnsList){
            if(tempSelectedColumnsList[index].columnId === item.columnId){
                var temptemp = []
                for(var index3 in tempSelectedColumnsList){
                    var e_index = tempSelectedColumnsList[index3]
                    if(e_index.columnId!==item.columnId){
                        temptemp.push(e_index)
                    }
                }
                tempSelectedColumnsList = temptemp
                foundObject = true;
                break;
            }
        }
        if(!foundObject){
            tempSelectedColumnsList.push(item)
        }
        sorting(tempSelectedColumnsList,'columnId',true,true)
        setSelectedColumnsData(tempSelectedColumnsList)
        for(var index2 in tempSelectedColumnsList){
            tempSelectedColumnsWithoutDataList.push(tempSelectedColumnsList[index2].value)
        }
        setSelectedColumns(tempSelectedColumnsWithoutDataList)
        updateComponent(e);
    }

    const selectFieldColumns = (e,field) => {
        var tempSelectedColumnsList = selectedColumnsData
        var tempSelectedColumnsWithoutDataList = []
        for(var index in typesKeys){
            if(index===field){
                tempSelectedColumnsList = [...tempSelectedColumnsList,...typesKeys[index]]
            }
        }
        sorting(tempSelectedColumnsList,'columnId',true,true)
        setSelectedColumnsData(tempSelectedColumnsList)
        for(var index2 in tempSelectedColumnsList){
            tempSelectedColumnsWithoutDataList.push(tempSelectedColumnsList[index2].value)
        }
        setSelectedColumns(tempSelectedColumnsWithoutDataList)
        updateComponent(e);
    }

    const unselectFieldColumns = (e,field) => {
        var tempSelectedColumnsList = selectedColumnsData
        var tempSelectedColumnsWithoutDataList = []
        var temptemp = []
        for (var index2 in tempSelectedColumnsList){
            var foundObject = false
            for(var index in typesKeys){
                if(index===field){
                    for(var index3 in typesKeys[index]){
                        if(typesKeys[index][index3].value===tempSelectedColumnsList[index2].value){
                            foundObject = true
                        }
                    }
                }
            }
            if(!foundObject){
                temptemp.push(tempSelectedColumnsList[index2])
            }
        }
        tempSelectedColumnsList = temptemp
        sorting(tempSelectedColumnsList,'columnId',true,true)
        setSelectedColumnsData(tempSelectedColumnsList)
        for(index2 in tempSelectedColumnsList){
            tempSelectedColumnsWithoutDataList.push(tempSelectedColumnsList[index2].value)
        }
        setSelectedColumns(tempSelectedColumnsWithoutDataList)
        updateComponent(e);
    }
    
    const selectAllColumns = (e) => {
        var tempSelectedColumnsList = []
        var tempSelectedColumnsWithoutDataList = []

        for(var index in typesKeys){
            tempSelectedColumnsList = [...tempSelectedColumnsList,...typesKeys[index]]
        }

        sorting(tempSelectedColumnsList,'columnId',true,true)
        setSelectedColumnsData(tempSelectedColumnsList)
        for(var index2 in tempSelectedColumnsList){
            tempSelectedColumnsWithoutDataList.push(tempSelectedColumnsList[index2].value)
        }
        setSelectedColumns(tempSelectedColumnsWithoutDataList)
        updateComponent(e);
    }

    const [manualUseEffect, setManualUseEffect] = useState(false)
    const [presetOptions, setPresetOptions] = useState([])

    if(!manualUseEffect){
        setManualUseEffect(true);
        var tempPresetOptions = []
        if(getLocalStorageValue(window.location.pathname+'/export-preset')){
            tempPresetOptions = getLocalStorageValue(window.location.pathname+'/export-preset')
        }
        setPresetOptions(tempPresetOptions)
    }
    const [presetSelected, setPresetSelected] = useState('')
    const handlePresetSelect = (e) => {
        setPresetSelected(e)
        if(e.selectedColumns){
            setSelectedColumns(e.selectedColumns)
        }
    }
    const [presetAdded, setPresetAdded] = useState(false)
    const handleAddPreset = (e) => {
        var tempPresetOptions = []
        if(getLocalStorageValue(window.location.pathname+'/export-preset')){
            tempPresetOptions = getLocalStorageValue(window.location.pathname+'/export-preset')
        }
        var foundFlag = false
        for(var index in tempPresetOptions){
            if(presetSelected.value===tempPresetOptions[index].value){
                foundFlag = true
                tempPresetOptions[index]['selectedColumns'] = selectedColumns
            } 
        }
        if(!foundFlag){
            var tempPresetSelected = presetSelected
            delete presetSelected['__isNew__']
            tempPresetOptions.push({...tempPresetSelected,selectedColumns})
        }
        setLocalStorageValue(window.location.pathname+'/export-preset',tempPresetOptions)
        setPresetAdded(true);
    }
    useEffect(() => {
        document.addEventListener('keydown',(e)=>{
            if(e.key==='Escape'){props.handleClose()}
        })
        
        return () => {
            document.removeEventListener('keydown',(e)=>{
                if(e.key==='Escape'){props.handleClose()}
            })
        }
    }, [props])
    return ( 
        <div className="PopupColumncontainer">
            <div className="wrapper">
                <div style={{display:"flex",height:"8%",alignItems:'center'}}>
                    <div style={{width:"95%",display:'flex',justifyContent:'flex-start',paddingLeft:"2%",fontSize:"15px",fontWeight:"500"}}>Export Data</div>
                    <div style={{width:"5%",display:'flex',justifyContent:'center',alignItems:"center"}} className="closeIcon" onClick={props.handleClose}>X</div>
                </div>
                <div style={{borderBottom:"0.001px solid rgb(150,150,150)",marginLeft:"2%",marginRight:"7%"}}></div>
                <div style={{width:"100%",height:"85%",overflowY:"auto"}}>
                    <div style={{padding:"2%"}}>
                        <div style={{marginTop:"2%",marginLeft:"1%",display:'flex',width:'98%',alignItems:'center'}}>
                            
                        <div style={{height:'8vh',display:'flex',width:'70%',alignItems:'center',transform:'scale(0.9) translateX(-5%)'}}>
                            <div style={{width:'60%'}}>
                                <CreatableSelect
                                    options={presetOptions}
                                    value={presetSelected}
                                    onChange={handlePresetSelect}
                                    menuPortalTarget={document.body} 
                                    placeholder={"Select preset"}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 1000000 }),
                                                control: (base,state) => ({
                                                    ...base,
                                                    // maxHeight: 100,
                                                    minHeight: 30,
                                                    color:'rgb(200,200,200)',
                                                    overflowY: "auto",
                                                    fontSize:14,
                                                    border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid rgb(200,200,200)',
                                                    boxShadow: state.isFocused ? '0 0 3px 1px '+localStorage.getItem('ambientColor') : 0,
                                                    '&:hover': {
                                                        border: state.isFocused ? '1px solid '+localStorage.getItem('ambientColor') : '1px solid '+localStorage.getItem('ambientColor'),
                                                        boxShadow: state.isFocused ? '0 0 1px 1px '+localStorage.getItem('ambientColor') : '0 0 1px 1px '+localStorage.getItem('ambientColor'),
                                                    }
                                                }),
                                                placeholder: (defaultStyles) => {
                                                    return {
                                                        ...defaultStyles,
                                                        color: 'rgb(150,150,150)',
                                                        marginTop:'-2px'
                                                    }
                                                },
                                                option: (styles, {isFocused, isSelected}) => ({
                                                    ...styles,
                                                    fontSize:'11px',
                                                    background: isFocused
                                                        ? localStorage.getItem('ambientColor')
                                                        : isSelected
                                                            ? increase_brightness(localStorage.getItem('ambientColor'),10)
                                                            : undefined,
                                                    zIndex: 1
                                                }),
                                            }}
                                    />
                            </div>
                            <Button disabled={presetAdded || selectedColumns.length===0 || !presetSelected} sx={{...Constants.OUTLINEDBUTTON,fontSize:'14px',height:'35px',width:'40%',paddingLeft:'15px',borderRadius:'0px',marginLeft:'15px',paddingRight:'15px',textTransform:'none',fontWeight:500}} onClick={handleAddPreset}>
                                {presetSelected && !presetSelected.__isNew__ && '+Update Preset'}
                                {(!presetSelected || presetSelected.__isNew__) && '+Add Preset'}
                            </Button>
                        </div>
                        <div style={{width:'30%',display:'flex',alignItems:'center',justifyContent:'flex-end',fontSize:'10px',}}>
                                <div style={{color:'orange',cursor:'pointer'}} onClick={selectAllColumns}>Select All</div> &nbsp;&nbsp;
                                <div style={{color:'orange',cursor:'pointer'}} onClick={(e)=>{setSelectedColumns([]);setSelectedColumnsData([]);updateComponent(e);}}>Unselect All</div>
                        </div>
                        </div>
                        <div style={{marginTop:"2%",marginLeft:"1%"}}>
                            {
                                Object.keys(typesKeys).map((item,index)=>{
                                    return <div key={index} style={{marginTop:"2%"}}>
                                        <div style={{display:'flex',fontSize:'10px',marginBottom:"5px",alignItems:'center'}}>
                                            <div style={{fontSize:"13px",fontWeight:"500",color:'rgb(150,150,150)'}}>
                                                {item}
                                            </div>&nbsp;&nbsp;
                                            <div style={{color:'orange',cursor:'pointer'}} onClick={(e)=>{selectFieldColumns(e,item)}}>Select All</div> &nbsp;&nbsp;
                                            <div style={{color:'orange',cursor:'pointer'}} onClick={(e)=>{unselectFieldColumns(e,item);}}>Unselect All</div>
                                        </div>
                                        <div style={{display:"flex",flexWrap:'wrap'}}>
                                            {typesKeys[item].map((item2,index2)=>{
                                                return <div key={index2} className={
                                                    selectedColumns.includes(item2.value) 
                                                    ? 
                                                    "columnTilesSelected"
                                                    :
                                                    'columnTiles'    
                                                }
                                                            onClick={(e)=>{selectColumns(e,item2);setDownloadClicked(false)}}
                                                            > 
                                                        {item2.label}
                                                        </div>
                                            })}
                                        </div>
                                        </div>
                                })
                            }
                        </div>
                    </div>
                    <div style={{display:"flex",marginLeft:"2%"}}><Button onClick={()=>{exportDataRequest().then((resp)=>{props.handleClose();})}} color='warning' variant="contained" style={{transform:"scale(0.8)"}} disabled={downloadClicked || disableButton}>Download</Button></div>
                </div>
            </div>
        </div>
    )
}

export default DownloadStats;
